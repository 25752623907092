import { Box, Container } from '@material-ui/core';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { theme } from 'styles';
import { NewNetworkPageStates } from '../../states';
import TemplateComponent from './TemplateComponent';

export default function Step1() {
  return (
    <Container>
      <Box marginBottom={2}>
        <FormRow>
          <AtomicTextFieldV2
            required
            state={NewNetworkPageStates.fields.formNetworkName}
            label="Network Name"
          />
        </FormRow>
        <Line color={theme.palette.primary.light} height={2} />
        <FormRow>
          <TemplateComponent />
        </FormRow>
      </Box>
    </Container>
  );
}
