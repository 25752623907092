import { Box, Button } from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewConsolidationStates } from '../../states';

export default function ClearFiltersButton() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.bookings);
  const setFilteredBookings = useSetRecoilState(NewConsolidationStates.step1.filteredBookings);
  const setFilterDestination = useSetRecoilState(NewConsolidationStates.filters.filterDestination);
  const setFilterCargoReadyDate = useSetRecoilState(
    NewConsolidationStates.filters.filterCargoReadyDate
  );
  const setFilterPol = useSetRecoilState(NewConsolidationStates.filters.filterPol);
  const setFilterETD = useSetRecoilState(NewConsolidationStates.filters.filterEtd);
  const setFilterEqptType = useSetRecoilState(NewConsolidationStates.filters.filterEqptType);
  const setFilterDeliveryType = useSetRecoilState(
    NewConsolidationStates.filters.filterDeliveryType
  );
  const setFilterSupplier = useSetRecoilState(NewConsolidationStates.filters.filterSupplier);
  const setFilters = useSetRecoilState(NewConsolidationStates.filters.filtersLength);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        color="secondary"
        onClick={() => {
          setFilterDestination(null);
          setFilterCargoReadyDate(null);
          setFilterPol(null);
          setFilterETD(null);
          setFilterEqptType(null);
          setFilterDeliveryType(null);
          setFilterSupplier(null);
          setFilteredBookings([...bookings]);
          setFilters(0);
        }}
      >
        Clear Filters
      </Button>
    </Box>
  );
}
