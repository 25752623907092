import { IconButton, InputAdornment, Popover, TextField } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { fmtTimeOfDay, parseTimeOfDay, TimeOfDay } from 'types/TimeOfDay';
import { range } from 'types/Number';

const classes = makeCss({
  popoverBody: {
    ...theme.typography.body1,
    overflow: 'hidden',
    padding: theme.spacing(0, 1),
  },
  timePicker: {
    display: 'flex',
    position: 'relative',
  },
  selectedRow: {
    zIndex: 0,
    height: 24,
    background: 'rgba(0,0,0,.25)',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    position: 'absolute',
    top: 3 * 24,
  },
  wheel: {
    zIndex: 1,
    padding: theme.spacing(0, 0.5),
    outline: 'none !important', // Don't show outline on hover
    '&:before': {
      content: "''",
      backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: '85%',
      left: 0,
    },
    '&:after': {
      content: "''",
      backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      position: 'absolute',
      top: '85%',
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  btn: {
    color: '#333',
    width: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    userSelect: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    whiteSpace: 'pre',
    '&:hover': {
      backgroundColor: theme.palette.grayscale.light,
    },
  },
  btnActive: {
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
});

interface Props {
  value: TimeOfDay | null;
  onValue(v: TimeOfDay | null): void;

  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: string | null;
}

function toHour12(h: number | null | undefined): number {
  const hour = Math.max(0, Math.min(23, h || 0));
  if (hour === 0) {
    return 12;
  }
  if (hour > 12) {
    return hour - 12;
  }
  return hour;
}

export default function FormInputTime({ value, onValue, label, required, disabled, error }: Props) {
  const ref = React.useRef<HTMLInputElement>(null);
  const fmtText = fmtTimeOfDay(value);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [showPicker, setShowPicker] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (ref.current && !isFocused) {
      ref.current.value = fmtText;
    }
  }, [fmtText, isFocused]);

  return (
    <>
      <TextField
        variant="outlined"
        margin="dense"
        fullWidth
        inputRef={ref}
        label={label}
        required={required}
        disabled={disabled}
        error={!!error}
        helperText={error}
        onChange={(e) => {
          const text = e.currentTarget.value;
          const v = parseTimeOfDay(text);
          onValue(v);
        }}
        onFocus={(e) => {
          setIsFocused(true);
        }}
        onBlur={(e) => {
          setIsFocused(false);
        }}
        onKeyDown={(e) => {
          const elm = ref.current;
          if (!elm) return;
          switch (e.code) {
            case 'ArrowUp':
            case 'ArrowDown': {
              e.preventDefault();
              e.stopPropagation();
              const text = elm.value;
              const startPos = elm.selectionStart || 0;
              const editHours = startPos <= text.indexOf(':');
              const editAmPm =
                startPos > text.indexOf(':') && startPos > text.indexOf(' ', text.indexOf(':'));
              const editMinutes = !editHours && !editAmPm;
              let { hour, minute } = parseTimeOfDay(text);
              const hour12 = toHour12(hour);
              const isAM = hour < 12;
              if (editHours) {
                if (e.code === 'ArrowUp') {
                  hour += 1;
                } else {
                  hour -= 1;
                }
                if (hour < 0) {
                  hour = 23;
                }
                if (hour > 23) {
                  hour = 0;
                }
              }
              if (editMinutes) {
                const minuteStep = 1; // OMS-290 wants 1 minute increments
                const minuteFlat = minute - (minute % minuteStep);
                if (e.code === 'ArrowUp') {
                  minute = Math.max(0, minuteFlat + minuteStep);
                  while (minute >= 60) {
                    minute -= 60;
                  }
                } else {
                  if (minute > minuteFlat) {
                    minute = minuteFlat;
                  } else {
                    minute = minuteFlat - minuteStep;
                    while (minute < 0) {
                      minute += 60;
                    }
                  }
                }
              }
              if (editAmPm) {
                if (isAM) {
                  hour = hour12 >= 12 ? 12 : hour12 + 12;
                } else {
                  hour = hour12 >= 12 ? 0 : hour12;
                }
              }
              const val = { hour, minute };
              onValue(val);
              const valText = fmtTimeOfDay(val);
              elm.value = valText;
              if (editHours) {
                elm.setSelectionRange(0, valText.indexOf(':'), 'forward');
              }
              if (editMinutes) {
                elm.setSelectionRange(
                  valText.indexOf(':') + 1,
                  valText.indexOf(':') + 3,
                  'forward'
                );
              }
              if (editAmPm) {
                elm.setSelectionRange(valText.length - 2, valText.length, 'forward');
              }
              break;
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" tabIndex={-1} onClick={() => setShowPicker((show) => !show)}>
                <AccessTimeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showPicker && ref.current && (
        <Popover
          open={showPicker}
          anchorEl={ref.current}
          onClose={() => {
            setShowPicker(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            className={classes.popoverBody}
            onMouseDown={(e) => {
              e.preventDefault(); // Prevent blur
            }}
          >
            <TimePicker value={value} onValue={onValue} />
          </div>
        </Popover>
      )}
    </>
  );
}

interface Option<T> {
  value: T;
  label: string;
}

const hourOptions: Option<number>[] = range(1, 13).map((hour) => {
  return { value: hour, label: `${hour}`.padStart(2, ' ') };
});

const minuteOptions: Option<number>[] = range(0, 60, 1 /*OMS-290 wants inc by 1*/).map((min) => {
  return { value: min, label: `${min}`.padStart(2, '0') };
});

const amPmOptions: Option<'am' | 'pm'>[] = [
  { value: 'am', label: 'AM' },
  { value: 'pm', label: 'PM' },
];

const TimePicker: React.FC<{
  value: TimeOfDay | null;
  onValue(v: TimeOfDay | null): void;
}> = ({ value, onValue }) => {
  const refRoot = React.useRef<HTMLDivElement>(null);
  const hour12 = toHour12(value?.hour);
  const isAM = !value || value.hour < 12;

  React.useEffect(() => {
    const root = refRoot.current;
    if (root) {
      root.focus();
    }
  }, []);

  return (
    <div className={classes.timePicker}>
      <div className={classes.selectedRow} />

      <Wheel
        options={hourOptions}
        loopOptions
        value={hour12}
        onValue={(hour) => {
          onValue({
            hour: isAM ? (hour >= 12 ? 0 : hour) : hour >= 12 ? 12 : hour + 12,
            minute: value?.minute || 0,
          });
        }}
      />

      <Wheel
        options={minuteOptions}
        loopOptions
        value={value?.minute || 0}
        onValue={(minute) => {
          onValue({ hour: value?.hour || 0, minute });
        }}
      />

      <Wheel
        options={amPmOptions}
        value={isAM ? 'am' : 'pm'}
        onValue={(amPm) => {
          if (amPm === 'am') {
            onValue({
              hour: hour12 >= 12 ? 0 : hour12,
              minute: value?.minute || 0,
            });
          } else {
            onValue({
              hour: hour12 >= 12 ? 12 : 12 + hour12,
              minute: value?.minute || 0,
            });
          }
        }}
      />
    </div>
  );
};

const nOptionsBeforeAfterSelection = 3;

function Wheel<T>({
  value,
  onValue,
  options,
  loopOptions,
}: {
  value: T;
  onValue(v: T): void;
  options: Option<T>[];
  loopOptions?: boolean;
}) {
  const refRoot = React.useRef<HTMLDivElement>(null);

  let optionI = 0;
  for (let i = 0; i < options.length; i++) {
    if (value === options[i].value) {
      optionI = i;
      break;
    }
  }

  const optionsRendered: (Option<T> | null)[] = [];
  for (let i = nOptionsBeforeAfterSelection - 1; i >= 0; i--) {
    const currI = optionI - i - 1;
    optionsRendered.push(getOption(currI));
  }
  optionsRendered.push(options[optionI]);
  for (let i = 0; i < nOptionsBeforeAfterSelection; i++) {
    const currI = optionI + i + 1;
    optionsRendered.push(getOption(currI));
  }

  function getOption(newI: number) {
    if (loopOptions) {
      newI = newI % options.length;
      while (newI < 0) {
        newI += options.length;
      }
    }
    return options[newI] || null;
  }

  function shiftOption(inc: number) {
    const option = getOption(optionI + inc);
    if (option) {
      onValue(option.value);
    }
  }

  return (
    <div
      ref={refRoot}
      className={classes.wheel}
      onWheel={(e) => {
        if (e.deltaY > 0) {
          shiftOption(1);
        }
        if (e.deltaY < 0) {
          shiftOption(-1);
        }
      }}
      onKeyDown={(e) => {
        if (e.code === 'ArrowUp') {
          shiftOption(-1);
        }
        if (e.code === 'ArrowDown') {
          shiftOption(1);
        }
      }}
      tabIndex={-1} // Needed so we can focus this element and can capture the keyDown events
      onMouseEnter={(e) => {
        const root = refRoot.current;
        if (root) {
          root.focus(); // Needed so we can capture the keyDown events
        }
      }}
    >
      {optionsRendered.map((option, i) => {
        if (!option) {
          return <div key={'filler-' + i} style={{ width: 32, height: 24 }} />;
        }
        return (
          <SelectBtn
            key={option.label}
            label={option.label}
            active={option.value === value}
            onClick={() => {
              onValue(option.value);
            }}
          />
        );
      })}
    </div>
  );
}

const SelectBtn: React.FC<{ label: string; active: boolean; onClick(): void }> = ({
  label,
  active,
  onClick,
}) => {
  return (
    <div className={clsx(classes.btn, active && classes.btnActive)} onClick={onClick}>
      {label}
    </div>
  );
};
