import { gql } from '@apollo/client/core';
import { GqlUpdateContactMutation, GqlUpdateContactMutationVariables } from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const UpdateContactMutation = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      success
      message
      pleaseProvideCredentials
    }
  }
`;

export const updateContact = wrapMutation<
  GqlUpdateContactMutation,
  GqlUpdateContactMutationVariables
>({
  mutation: UpdateContactMutation,
  errorMessage(data, error) {
    if (!data?.updateContact.success || error) {
      if (data?.updateContact.pleaseProvideCredentials) {
        return null; // Don't treat this like a normal error, instead display the setup login dialog.
      }
      return data?.updateContact.message || 'Unexpected Error';
    }
  },
});

export const MyProfileContactsMutation = gql`
  query MyProfileContacts {
    userContext {
      activeContact {
        profile {
          contacts {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const AdminPagesContactFragment = gql`
  fragment AdminPagesContact on Contact {
    id
    firstName
    lastName
    title
    email
    phoneMain
    extension
    phoneAlternative
    phoneCell
    isUserEnabled
    isMainContact
    needsUpdate
    notificationCodes
    location {
      id
      name
    }
    role {
      id
      name
      notificationCodes
    }
  }
`;
