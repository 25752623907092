import { AppWideStates } from 'app/states';
import { HighlightMatches } from 'components/HighlightMatches';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { makeCss, theme } from 'styles';
import { formatNumberDecimalPlaces } from 'types/Number';
import { FormInputAutocomplete } from './FormInputAutocomplete';

const classes = makeCss({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  volumeTag: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 0.5),
    background: theme.palette.grayscale.main,
    fontSize: '.8em',
  },
});

interface Props {
  value: string | null;
  onValue(value: string | null): void;
  showMinMax?: boolean;
  disabled?: boolean;
}

export const FormInputContainerType: React.FC<Props> = (props) => {
  const containerTypes = useRecoilValue(AppWideStates.network.containerTypes);

  return (
    <FormInputAutocomplete
      value={props.value}
      onValue={props.onValue}
      label={'Equipment Type'}
      options={containerTypes.codes}
      disabled={props.disabled}
      displayResolver={(value) => {
        return value;
      }}
      renderOption={(value, query) => {
        if (!props.showMinMax) {
          if (value.toLowerCase().trim() === query) {
            return value;
          }
          return <HighlightMatches str={value} searchStr={query} />;
        }
        const containerType = containerTypes.byCode.get(value);
        return (
          <div className={classes.option}>
            <div>
              <HighlightMatches str={value} searchStr={query} />
            </div>
            {containerType && (
              <div className={classes.volumeTag}>
                {formatNumberDecimalPlaces(containerType.ruleMinVolumeM3, 0, 1)} -{' '}
                {formatNumberDecimalPlaces(containerType.ruleMaxVolumeM3, 0, 1)} CBM
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
