import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlContainer, PermissionCode, VolumeUnit, WeightUnit } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { ConsolidationPageStates, Containers } from '../states';
import EquipmentAssignmentDialog from './equipment-assignment-dialog';

const columns: UWLTableColumn<Containers>[] = [
  { id: 'isHot', label: '', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containerNumber', label: 'Container', type: 'link', whiteSpace: 'nowrap' },
  { id: 'containerType', label: 'Container Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'sealNumber', label: 'Seal', type: 'string', whiteSpace: 'nowrap' },
  { id: 'volume', label: 'Volume', type: 'volume', unitField: 'volumeUnit' },
  { id: 'weight', label: 'Weight', type: 'weight', unitField: 'weightUnit' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
  { id: 'deliveryAta', label: 'DEST DLV ATA', type: 'date' },
];
const columnsAlt: UWLTableColumn<Containers>[] = [
  { id: 'containerNumber', label: 'Container', type: 'link', whiteSpace: 'nowrap' },
  { id: 'containerType', label: 'Container Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'sealNumber', label: 'Seal', type: 'string', whiteSpace: 'nowrap' },
  { id: 'volume', label: 'Volume', type: 'volume', unitField: 'volumeUnit' },
  { id: 'weight', label: 'Weight', type: 'weight', unitField: 'weightUnit' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
  { id: 'deliveryAta', label: 'DEST DLV ATA', type: 'date' },
];

interface Props {
  consolidationId: string;
}
export default function ConsolidationEquipment(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [open, setOpen] = React.useState(false);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [containers, setContainers] = React.useState<Containers[]>([]);

  React.useEffect(() => {
    if (consolidation) {
      setContainers(
        consolidation.containers.map((container): Containers => {
          return {
            id: container.id,
            isHot: container.isHot,
            containerNumber: {
              to: '/equipment/' + container.id,
              value: container.containerNumber ?? '',
            },
            containerType: container.containerType,
            sealNumber: container.sealNumber ?? '',
            volume: container.volumeM3 ?? 0,
            weight: container.weightKg ?? 0,
            volumeUnit: VolumeUnit.Cbm,
            weightUnit: WeightUnit.Kg,
            terminalReceivedDate: container.terminalReceivedDate,
            deliveryAta: container.deliveryAta,
          };
        })
      );
    }
  }, [consolidation]);

  return (
    <>
      <Panel
        title="Equipment"
        topRight={
          !consolidation?.cancelledDate &&
          userContext?.permissionCodes.has(PermissionCode.ConsolidationContainerUpdate) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        <Box height="100%" paddingX={2} paddingBottom={2}>
          <UWLTable
            rowId="id"
            columns={hasHotContainers(consolidation?.containers ?? []) ? columns : columnsAlt}
            rows={containers}
            emptyMessage="- No Containers -"
            renderCell={{
              isHot(row) {
                return row.isHot ? (
                  <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
                ) : null;
              },
              containerNumber(row) {
                return (
                  <>
                    {row.containerNumber.value ? (
                      <Link to={row.containerNumber.to}>{row.containerNumber.value}</Link>
                    ) : (
                      <Box>Not Assigned</Box>
                    )}
                  </>
                );
              },
            }}
          />
        </Box>
      </Panel>
      <EquipmentAssignmentDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function hasHotContainers(containers: GqlContainer[]) {
  return Boolean(containers.find((container) => container.isHot));
}
