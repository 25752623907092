import * as React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    marginRight: theme.spacing(1),
  },
  body: {
    flex: 1,
  },
});

interface Props {
  item: any;
}

export const Prepend: React.FC<Props> = (props) => {
  return (
    <div className={classes.root}>
      <div className={classes.item}>{props.item}</div>
      <div className={classes.body}>{props.children}</div>
    </div>
  );
};
