import { Button } from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CancelConsolDialog } from './CancelConsolDialog';
import { ConsolidationPageStates, useCanCancelConsol } from './states';

interface Props {}

export const CancelConsolButton: React.FC<Props> = (props) => {
  const canCancel = useCanCancelConsol();
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowCancelDialog(false);
  }, [consolidation?.id]);

  if (!canCancel) {
    return null;
  }

  if (!consolidation) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          setShowCancelDialog(true);
        }}
      >
        Cancel Consol
      </Button>
      {showCancelDialog && (
        <CancelConsolDialog
          consolidationId={consolidation.id}
          onClose={() => {
            setShowCancelDialog(false);
          }}
        />
      )}
    </>
  );
};
