import { Box, Typography } from '@material-ui/core';
import { PermissionCode } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { SwitchListGroup } from 'components/SwitchListGroup';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  // This is the list of all permissions that can be toggled
  permissionsToDisplay: PermissionCode[];
  // This is the list of all permissions that should be on
  permissionsSet: PermissionCode[];
  // This is a callback to perform logic regarding the permission to toggle.
  // 'perm' is the permission that was toggled.
  // 'checked' is true if the permission needs to be switched on (Added to set list) and false if it needs to be switched off (Removed from set list)
  onPermissionToggle: (perm: PermissionCode, checked: boolean) => void;

  // when true, all switches will be disabled and you will receive no onPermissionToggle events
  disabled?: boolean;
}

export default function PermissionsComponent(props: Props) {
  const permissions = useRecoilValue(AppWideStates.permissions);

  const groupsRendered: React.ReactNode[] = [];
  for (const groupName of permissions.groupOrder) {
    const perms = (permissions.byGroup.get(groupName) || []).filter((perm) =>
      props.permissionsToDisplay.includes(perm.code)
    );
    if (perms.length === 0) {
      continue;
    }
    groupsRendered.push(
      <SwitchListGroup
        key={groupName}
        title={groupName}
        possibleValues={perms.map((perm) => {
          return {
            key: perm.code,
            value: perm.code,
            label: perm.name,
            tooltip: perm.description,
          };
        })}
        values={props.permissionsSet}
        onToggle={props.onPermissionToggle}
        disabled={props.disabled}
        defaultExpanded
      />
    );
  }

  return (
    <Box width="100%">
      <Typography variant="h2">Permissions</Typography>
      <hr />
      <div>{groupsRendered.length === 0 ? '- none -' : groupsRendered}</div>
    </Box>
  );
}
