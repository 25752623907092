import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import {
  NotificationCode,
  DashboardFeatureCode,
  PermissionCode,
  SystemPermissionCode,
  useProfileTypeUpdateMutation,
} from 'api/GQL_Types';
import { ProfileTypeQuery } from 'api/queries/profileTypeQueries';
import { auth } from 'app';
import { DashboardFeatureSwitches } from 'app/admin/components/DashboardFeatureSwitches';
import LineItem from 'components/LineItem';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import NotificationCodeSwitches from '../../../components/NotificationCodeSwitches';
import PermissionsComponent from '../../../components/PermissionsComponent';
import { ProfileTypePageStates } from '../states';
import EditProfileTypeDetailsDialog, {
  ProfileTypeDetailsDialog_Data,
} from './EditProfileTypeDetailsDialog';

const ALL_PermissionCodes = Object.values(PermissionCode);
const ALL_NotificationCodes = Object.values(NotificationCode);
const ALL_DashboardFeatureCode = Object.values(DashboardFeatureCode);

interface DetailsProps extends RouteComponentProps {}

export default function ProfileTypeDetails(props: DetailsProps) {
  const { userContext } = auth.useAuthState();
  const profileType = useRecoilValue(ProfileTypePageStates.profileType);
  const setProfileTypeDetails = useSetRecoilState(ProfileTypeDetailsDialog_Data);

  const [activePermissionCodes, setActivePermissionCodes] = useRecoilState(
    ProfileTypePageStates.formPermissionCodes
  );
  const [activeNotificationCodes, setActiveNotificationCodes] = useRecoilState(
    ProfileTypePageStates.formNotificationCodes
  );
  const [dashFeatureCodes, setDashFeatureCodes] = useRecoilState(
    ProfileTypePageStates.formDashboardFeatures
  );
  const { enqueueSnackbar } = useSnackbar();

  const [updateProfileType, { loading: waitingOnProfileUpdate }] = useProfileTypeUpdateMutation({
    refetchQueries: () => [
      {
        query: ProfileTypeQuery,
        variables: { profileTypeId: profileType?.id },
      },
    ],
    onCompleted(data) {
      enqueueSnackbar('Profile Type Template Updated.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  if (!profileType) {
    return null;
  }

  const canEdit = profileType.isTemplate
    ? userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesUpdate)
    : userContext?.permissionCodes.has(PermissionCode.ProfileTypeUpdate);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Profile Details</Typography>
        </Box>
        {canEdit && (
          <Button
            variant="contained"
            color="secondary"
            style={{ width: '200px' }}
            onClick={() => {
              setProfileTypeDetails({
                id: profileType.id,
                name: profileType.name,
                description: profileType.description,
                isTemplate: profileType.isTemplate,
              });
            }}
            disabled={waitingOnProfileUpdate}
          >
            EDIT
          </Button>
        )}
      </Box>
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={profileType.name} label="Template Name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={profileType.description} label="Description" />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="flex-end" marginTop={3} marginBottom="-40px">
        {canEdit && (
          <Button
            variant="contained"
            color="secondary"
            style={{ width: '300px' }}
            onClick={() => {
              updateProfileType({
                variables: {
                  input: {
                    profileType: {
                      id: profileType.id,
                      permissionCodes: activePermissionCodes,
                      notificationCodes: activeNotificationCodes,
                      dashboardFeatureCodes: dashFeatureCodes,
                    },
                  },
                },
              });
            }}
            disabled={waitingOnProfileUpdate}
          >
            Update Permissions & Settings
          </Button>
        )}
      </Box>

      <Box padding={3}>
        <PermissionsComponent
          permissionsToDisplay={ALL_PermissionCodes}
          permissionsSet={activePermissionCodes}
          onPermissionToggle={(perm, checked) => {
            let newPermissions = [...activePermissionCodes];
            if (checked) {
              newPermissions.push(perm);
            } else {
              newPermissions = activePermissionCodes.filter((p) => p !== perm);
            }
            setActivePermissionCodes(newPermissions);
          }}
          disabled={waitingOnProfileUpdate || !canEdit}
        />

        <NotificationCodeSwitches
          options={ALL_NotificationCodes}
          values={activeNotificationCodes}
          onValues={setActiveNotificationCodes}
          disabled={waitingOnProfileUpdate || !canEdit}
        />

        <DashboardFeatureSwitches
          options={ALL_DashboardFeatureCode}
          values={dashFeatureCodes}
          onValues={setDashFeatureCodes}
          disabled={waitingOnProfileUpdate || !canEdit}
        />
      </Box>
      <EditProfileTypeDetailsDialog />
    </>
  );
}
