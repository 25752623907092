import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NotificationCode, useMyAccountQuery, useUpdateAccountMutation } from 'api/GQL_Types';
import { useSnackbar } from 'notistack';
import { accountDetailsState, useSetupAccountDetailsData } from './states';
import { useNotificationCodeNameLookup } from 'app/states';
import { FormInputMultiSelect } from 'components/form/FormInputMultiSelect';
import EditPasswordDialog from './EditPasswordDialog';
import CompositePanel from 'components/CompositePanel';
import { FormItem } from 'components/form/FormItem';
import { FormInputText } from 'components/form/FormInputText';
import { theme } from 'styles';

interface Props extends RouteComponentProps {}

export default function MyAccount(props: Props) {
  const setupAccountDetailsData = useSetupAccountDetailsData();
  const setOpenDialog = useSetRecoilState(accountDetailsState.editPasswordDialog_Open);
  const [profileContacts, setProfileContacts] = useRecoilState(accountDetailsState.profileContacts);
  const firstName = useRecoilValue(accountDetailsState.firstName);
  const lastName = useRecoilValue(accountDetailsState.lastName);
  const title = useRecoilValue(accountDetailsState.title);
  const phoneMain = useRecoilValue(accountDetailsState.phoneMain);
  const extension = useRecoilValue(accountDetailsState.extension);
  const phoneCell = useRecoilValue(accountDetailsState.phoneCell);
  const phoneAlternative = useRecoilValue(accountDetailsState.phoneAlternative);
  const email = useRecoilValue(accountDetailsState.email);
  const notificationCodeNameLookup = useNotificationCodeNameLookup();
  const { enqueueSnackbar } = useSnackbar();

  useMyAccountQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setupAccountDetailsData(data);
    },
    onError(error) {
      enqueueSnackbar('Failed to retrieve account information: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const [updateAccount] = useUpdateAccountMutation({
    onCompleted(data) {
      if (data.updateAccount.success) {
        enqueueSnackbar('Account Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.updateAccount.message || 'Failed to update account.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to update account: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h2">My Account</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateAccount({
              variables: {
                input: {
                  user: {
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    phoneMain: phoneMain,
                    extension: extension,
                    phoneCell: phoneCell,
                    phoneAlternative: phoneAlternative,
                    contacts: profileContacts.map((pc) => {
                      return {
                        id: pc.id,
                        notificationCodes: pc.notificationCodes,
                      };
                    }),
                  },
                },
              },
            });
          }}
          style={{ width: '150px' }}
        >
          Save
        </Button>
      </Box>

      <CompositePanel>
        <Grid container justifyContent="center" style={{ padding: theme.spacing(0, 2, 2, 2) }}>
          <Grid item md={6} xs={12}>
            <Typography variant="h3" color="primary" style={{ margin: theme.spacing(2, 1.5) }}>
              My Contact Information
            </Typography>
            <FormRow>
              <AtomicTextFieldV2 label="First Name" state={accountDetailsState?.firstName} />
              <AtomicTextFieldV2 label="Last Name" state={accountDetailsState.lastName} />
            </FormRow>
            <FormRow>
              <AtomicTextFieldV2 label="Title" state={accountDetailsState.title} />
            </FormRow>
            <FormRow>
              <FormItem>
                <FormInputText disabled label="Email" value={email} onValue={() => {}} />
                <Typography
                  variant="subtitle2"
                  style={{
                    marginTop: theme.spacing(1),
                    fontWeight: 'bold',
                  }}
                >
                  Contact your customer service representative if you want to change your email
                  address.
                </Typography>
              </FormItem>
              <FormItem>
                <FormInputText
                  disabled
                  label="Password"
                  value="****"
                  onValue={() => {}}
                ></FormInputText>
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: theme.spacing(1.5) }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  Change Password
                </Button>
              </FormItem>
            </FormRow>
            <FormRow>
              <AtomicPhoneNumberV2 label="Main Phone" state={accountDetailsState.phoneMain} />
              <AtomicTextFieldV2 label="Extension" state={accountDetailsState.extension} />
            </FormRow>
            <FormRow>
              <AtomicPhoneNumberV2 label="Cell Phone" state={accountDetailsState.phoneCell} />
              <AtomicPhoneNumberV2
                label="Alternative Phone"
                state={accountDetailsState.phoneAlternative}
              />
            </FormRow>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h3" color="primary" style={{ margin: theme.spacing(2) }}>
              Notifications
            </Typography>
            {profileContacts.map((profileContact) => {
              return (
                <div
                  style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}
                  key={profileContact.id}
                >
                  <div style={{ marginBottom: theme.spacing(2) }}>
                    <Typography variant="h4">
                      {profileContact.profile.network.name}: {profileContact.profile.name}
                    </Typography>
                  </div>
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <FormInputMultiSelect
                      label="Notifications"
                      options={profileContact.role.notificationCodes.map((nc) => {
                        return { id: nc, label: notificationCodeNameLookup(nc) };
                      })}
                      value={profileContact.notificationCodes}
                      onValue={(codes: NotificationCode[]) => {
                        setProfileContacts(
                          profileContacts.map((p) => {
                            if (p.id === profileContact.id) {
                              return { ...p, notificationCodes: codes };
                            } else {
                              return p;
                            }
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </CompositePanel>
      <EditPasswordDialog />
    </Box>
  );
}
