import { RuleFieldType } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import FormInputSelect from 'components/form/FormInputSelect';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  options: RuleFieldType[];
  value: RuleFieldType;
  onValue(value: RuleFieldType): void;
}

export const RuleFieldInput: React.FC<Props> = ({ options, value, onValue }) => {
  const ruleFieldTypes = useRecoilValue(AppWideStates.ruleFieldTypes);
  return (
    <FormInputSelect
      label="Field"
      options={options.map((o) => {
        return {
          id: o + '',
          label: ruleFieldTypes.byCode.get(o)?.name || o + '',
        };
      })}
      value={value}
      onValue={(v) => {
        if (options.includes(v as any)) {
          onValue(v as RuleFieldType);
        }
      }}
    />
  );
};
