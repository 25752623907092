import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import * as React from 'react';

interface Props {
  label: string;
  value: boolean;
  onValue(v: boolean): void;
  disabled?: boolean;
  dense?: boolean;
  indeterminate?: boolean;
  implicitChecked?: boolean;
}

export default function FormInputCheckbox({
  label,
  value,
  onValue,
  disabled,
  dense,
  indeterminate,
  implicitChecked,
}: Props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          style={dense ? { padding: 2 } : {}}
          checked={value}
          onChange={(e) => onValue(e.currentTarget.checked)}
          color="primary"
          disabled={disabled}
          indeterminate={indeterminate || implicitChecked}
          indeterminateIcon={implicitChecked ? <CheckBoxIcon /> : undefined}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
}
