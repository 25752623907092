import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import {
  DashboardFeatureCode,
  OrderStatus,
  PartyType,
  useDashboardPOsLazyQuery,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import CompositePanel from 'components/CompositePanel';
import ErrorMessage from 'components/ErrorMessage';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { DashboardPageStates, PoTable } from '../states';
import POButton from './POButton';
import POButtonsPopover from './POButtonsPopover';

export default function DashboardPOButtons() {
  const userContext = useRecoilValue(userContextAtom);
  const [openPos, setOpenPos] = useRecoilState(DashboardPageStates.po.openPos);
  const [expiredPos, setExpiredPos] = useRecoilState(DashboardPageStates.po.expiredPos);
  const [criticalPos, setCriticalPos] = useRecoilState(DashboardPageStates.po.criticalPos);
  const [selectedStatus, setSelectedStatus] = React.useState<OrderStatus | null>(null);
  const [popoverLabel, setPopoverLabel] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const dashboardInitialLoadStats = useRecoilValue(DashboardPageStates.dashboardInitialLoadStats);

  const [getPOs, { loading, error }] = useDashboardPOsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const dashPos = data.openOrders.map((dashPo): PoTable => {
        return {
          id: dashPo.orderLine.id,
          lineStatus: dashPo.orderLine.lineStatus,
          po: {
            id: dashPo.orderLine.purchaseOrder.id,
            poNumber: dashPo.orderLine.purchaseOrder.poNumber,
          },
          poLink: {
            to: '/purchase-orders/' + dashPo.orderLine.purchaseOrder.id,
            value: dashPo.orderLine.purchaseOrder.poNumber,
          },
          itemNumber: dashPo.orderLine.itemNumber,
          shipToLocationName: dashPo.orderLine.shipToLocation?.name ?? '',
          qty: dashPo.orderLine.totalQty ?? 0,
          vendor: relatedPartyNameOrVarious(
            dashPo.orderLine.purchaseOrder.relatedParties,
            PartyType.Supplier
          ),
          expectedCargoReadyDate: dashPo.orderLine.expectedCargoReadyDate,
          indcDate: dashPo.orderLine.indcDate,
          poValue: dashPo.orderLine.unitPrice,
          poValueCurrencyCode: dashPo.orderLine.unitPriceCurrencyCode,
        };
      });
      const open = dashPos.filter((po) => po.lineStatus === OrderStatus.Open);
      const expired = dashPos.filter((po) => po.lineStatus === OrderStatus.Expired);
      const late = dashPos.filter((po) => po.lineStatus === OrderStatus.Critical);

      // don't stomp over pre-loaded data from other tabs
      if (open.length > 0) {
        setOpenPos(open);
      }
      if (expired.length > 0) {
        setExpiredPos(expired);
      }
      if (late.length > 0) {
        setCriticalPos(late);
      }
    },
  });

  return (
    <CompositePanel>
      <Box margin={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          color="primary"
          style={{ minWidth: 'fit-content', letterSpacing: '0.5px' }}
        >
          <Box component="span" marginRight={2}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} color={theme.palette.indigoBlue.main} />
          </Box>
          Purchase Orders
        </Typography>
        <ErrorMessage error={dashboardInitialLoadStats.loadingError} />
      </Box>
      <Box display="flex" padding={1} marginBottom={1} flexGrow={1}>
        <Box display="flex" justifyContent="space-around" width={'100%'} padding={0}>
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusOpen) && (
            <POButton
              color={theme.palette.dodgerBlue.main}
              label="Open PO's"
              value={dashboardInitialLoadStats.nPosOpen}
              matIcon="assignment_turned_in"
              onClick={(e: any) => {
                setPopoverLabel('Open Purchase Orders');
                setAnchorEl(e.currentTarget);
                getPOs({ variables: { status: OrderStatus.Open } });
                setSelectedStatus(OrderStatus.Open);
              }}
            />
          )}
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusCritical) && (
            <POButton
              color={theme.palette.secondary.main}
              matIcon="assignment_late"
              label="Critical PO's"
              value={dashboardInitialLoadStats.nPosCritical}
              onClick={(e: any) => {
                setPopoverLabel('Critical Purchase Orders');
                setAnchorEl(e.currentTarget);
                getPOs({ variables: { status: OrderStatus.Critical } });
                setSelectedStatus(OrderStatus.Critical);
              }}
            />
          )}
          {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.PoStatusExpired) && (
            <POButton
              color={theme.palette.orange.main}
              matIcon="assignment_late"
              label="Expired PO's"
              value={dashboardInitialLoadStats.nPosExpired}
              onClick={(e: any) => {
                setPopoverLabel('Expired Purchase Orders');
                setAnchorEl(e.currentTarget);
                getPOs({ variables: { status: OrderStatus.Expired } });
                setSelectedStatus(OrderStatus.Expired);
              }}
            />
          )}
        </Box>
        <POButtonsPopover
          open={selectedStatus !== null}
          anchorEl={anchorEl}
          onClose={() => {
            setSelectedStatus(null);
          }}
          isLoading={loading}
          error={error ? 'Failed to load POs' : null}
          rows={
            selectedStatus === OrderStatus.Open
              ? openPos
              : selectedStatus === OrderStatus.Critical
              ? criticalPos
              : selectedStatus === OrderStatus.Expired
              ? expiredPos
              : []
          }
          title={popoverLabel}
        />
      </Box>
    </CompositePanel>
  );
}
