import { gql } from '@apollo/client/core';
import {
  GqlNewApiWebhookMutation,
  GqlNewApiWebhookMutationVariables,
  GqlRevokeApiWebhookMutation,
  GqlRevokeApiWebhookMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const ApiWebhooksQuery = gql`
  query ApiWebhooks {
    apiWebhooks {
      id
      url
      createDate
      lastSent
      lastFailure
      revokeDate
      nRecentFailures
      secret
    }
  }
`;

export const RevokeApiWebhook = gql`
  mutation RevokeApiWebhook($input: RevokeApiWebhookInput!) {
    revokeApiWebhook(input: $input) {
      success
      message
    }
  }
`;

export const revokeApiWebhook = wrapMutation<
  GqlRevokeApiWebhookMutation,
  GqlRevokeApiWebhookMutationVariables
>({
  mutation: RevokeApiWebhook,
  errorMessage(data, error) {
    if (!data?.revokeApiWebhook.success || error) {
      return data?.revokeApiWebhook.message || 'Unexpected Error';
    }
  },
});

export const NewApiWebhookMutation = gql`
  mutation NewApiWebhook($input: NewApiWebhookInput!) {
    newApiWebhook(input: $input) {
      success
      message
    }
  }
`;

export const newApiWebhook = wrapMutation<
  GqlNewApiWebhookMutation,
  GqlNewApiWebhookMutationVariables
>({
  mutation: NewApiWebhookMutation,
  errorMessage(data, error) {
    if (!data?.newApiWebhook.success || error) {
      return data?.newApiWebhook.message || 'Unexpected Error';
    }
  },
});
