import { Box } from '@material-ui/core';
import { AppWideStates } from 'app/states';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import { UtilizationChart } from 'components/UtilizationChart';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { WarningIconWithMessages } from 'components/WarningIconWithMessages';
import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  calcCombinedContainerSize,
  containerUtilizationWarnings,
  NetworkContainerType,
} from 'types/NetworkContainerType';
import { UWLTableColumn } from 'types/UWLTable';
import { NewConsolidationStates } from '../../states';

interface ContainerCapacityTV {
  id: string;
  referenceNumber: { to: string; value: string };
  weight: number;
  volume: number;
}

const containerCapHeaders: UWLTableColumn<ContainerCapacityTV>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link' },
  { id: 'weight', label: 'Weight', type: 'weight', unit: 'KG' },
  { id: 'volume', label: 'Volume', type: 'volume', unit: 'CBM' },
];

export default function EquipmentUtilization() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);
  const equipment = useRecoilValue(NewConsolidationStates.step1.equipmentList);
  const containerTypes = useRecoilValue(AppWideStates.network.containerTypes);
  const [bookingWCapacity, setBookingWCapacity] = React.useState(0);
  const [bookingVCapacity, setBookingVCapacity] = React.useState(0);

  const containerCapacityMockTable: ContainerCapacityTV[] = bookings.map((booking) => {
    return {
      id: booking.id,
      referenceNumber: booking.referenceNumber,
      weight: booking.weight,
      volume: booking.volume,
    };
  });

  const eqps: NetworkContainerType[] = [];
  for (const eqp of equipment) {
    for (let i = 0; i < eqp.qty; i++) {
      const containerType = containerTypes.byCode.get(eqp.containerType);
      if (containerType) {
        eqps.push(containerType);
      }
    }
  }

  const typesSize = calcCombinedContainerSize(eqps);

  const warnings = containerUtilizationWarnings(
    bookingWCapacity,
    bookingVCapacity,
    eqps,
    bookings.length
  );

  React.useEffect(() => {
    if (bookings) {
      setBookingWCapacity(bookings.reduce((value, booking) => value + booking.weight, 0));
      setBookingVCapacity(bookings.reduce((value, booking) => value + booking.volume, 0));
    }
  }, [bookings]);

  return (
    <Panel
      title="Container Utilization"
      titleStickToTop
      titleDecorator={<WarningIconWithMessages messages={warnings} />}
    >
      <RemainingHeightLayout
        top={
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" justifyContent="space-around">
              <UtilizationChart
                current={bookingWCapacity}
                min={typesSize.weightKg.min}
                max={typesSize.weightKg.max ? typesSize.weightKg.max : typesSize.weightKg.capacity}
                percentLabel="Weight"
                currentLabel="Booking Weight"
                maxLabel="Container Weight Capacity"
                minLabel="Container Minimum Weight"
                unitType="kg"
                grayOutChart={equipment.length === 0} // OMS-209
              />
              <UtilizationChart
                current={bookingVCapacity}
                min={typesSize.volumeM3.min}
                max={typesSize.volumeM3.max ? typesSize.volumeM3.max : typesSize.volumeM3.capacity}
                percentLabel="Volume"
                currentLabel="Booking Volume"
                maxLabel="Container Volume Capacity"
                minLabel="Container Minimum Volume"
                unitType="cbm"
                grayOutChart={equipment.length === 0} // OMS-209
              />
            </Box>
          </Box>
        }
        bottom={
          <PanelBodyTable>
            <UWLTable
              rowId="id"
              columns={containerCapHeaders}
              rows={containerCapacityMockTable}
              emptyMessage="- No Bookings -"
            />
          </PanelBodyTable>
        }
        bottomMinHeight={130}
      />
    </Panel>
  );
}
