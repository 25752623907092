import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PoBrowser from './PoBrowser';
import PoUploads from './uploads';
import PoPage from './page';
import { FlexGrowRouter } from 'components/StyledComponents';

interface PurchaseOrderProps extends RouteComponentProps {}
function PurchaseOrders(props: PurchaseOrderProps) {
  return (
    <FlexGrowRouter>
      <PoBrowser path="/" />
      <PoUploads path="/uploads/*" />
      <PoPage path="/:poId" />
    </FlexGrowRouter>
  );
}

export default PurchaseOrders;
