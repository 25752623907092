import { GqlExceptionsQuery } from 'api/GQL_Types';
import { DelayedUnloadException, DelayedUnloadException_toRow } from './DelayedUnloadException';
import {
  EmptyNotReturnedException,
  EmptyNotReturnedException_toRow,
} from './EmptyNotReturnedException';
import {
  HotShipmentException,
  HotShipmentException_toFilteredRows,
  HotShipmentException_toRow,
} from './HotShipmentException';
import {
  HotPOException,
  HotPOExceptionGrouped,
  HotPOException_toGroupedRows,
  HotPOException_toRow,
} from './HotPOException';
import { HotBookingException, HotBookingException_toRow } from './HotBookingException';
import {
  LateBookingException,
  LateBookingExceptionGrouped,
  LateBookingException_toGroupedRows,
  LateBookingException_toRow,
} from './LateBookingException';
import {
  MissingDocumentDisplay,
  MissingDocumentsException_toRow,
} from './MissingDocumentsException';
import { PendingOutgateException, PendingOutgateException_toRow } from './PendingOutgateException';
import {
  PodEtaException,
  PodEtaExceptionGrouped,
  PodEtaException_toRow,
  PodEtaException_toGroupedRow,
} from './PodEtaException';
import {
  PolEtdException,
  PolEtdExceptionGrouped,
  PolEtdException_toGroupedRow,
  PolEtdException_toRow,
} from './PolEtdException';
import { LateShipmentException, LateShipmentException_toRows } from './LateShipmentException';

export type ExceptionQueryResult = GqlExceptionsQuery['exceptions'][number];

export interface DashExceptions {
  hotBookings: HotBookingException[];
  hotShipments: HotShipmentException[];
  hotShipmentsFiltered: HotShipmentException[];
  hotPurchaseOrders: HotPOException[];
  hotPurchaseOrdersGrouped: HotPOExceptionGrouped[];
  missingDocuments: MissingDocumentDisplay[];
  pendingOutgate: PendingOutgateException[];
  emptyNotReturned: EmptyNotReturnedException[];
  bookingLate: LateBookingException[];
  bookingLateGrouped: LateBookingExceptionGrouped[];
  delayedUnload: DelayedUnloadException[];
  podEta: PodEtaException[];
  podEtaGrouped: PodEtaExceptionGrouped[];
  polEtd: PolEtdException[];
  polEtdGrouped: PolEtdExceptionGrouped[];
  lateShipment: LateShipmentException[];
}

export function emptyState(): DashExceptions {
  return {
    hotBookings: [],
    hotShipments: [],
    hotShipmentsFiltered: [],
    hotPurchaseOrders: [],
    hotPurchaseOrdersGrouped: [],
    missingDocuments: [],
    pendingOutgate: [],
    emptyNotReturned: [],
    bookingLate: [],
    bookingLateGrouped: [],
    delayedUnload: [],
    podEta: [],
    podEtaGrouped: [],
    polEtd: [],
    polEtdGrouped: [],
    lateShipment: [],
  };
}

export function initDashExceptions(data: GqlExceptionsQuery): DashExceptions {
  const state = emptyState();

  for (const result of data.exceptions) {
    let hotBooking = HotBookingException_toRow(result);
    if (hotBooking) {
      state.hotBookings.push(hotBooking);
    }
    let hotShipments = HotShipmentException_toRow(result);
    if (hotShipments) {
      state.hotShipments.push(...hotShipments);
    }
    let hotPo = HotPOException_toRow(result);
    if (hotPo) {
      state.hotPurchaseOrders.push(hotPo);
    }
    let missingDoc = MissingDocumentsException_toRow(result);
    if (missingDoc) {
      state.missingDocuments.push(missingDoc);
    }
    let pendingOutgate = PendingOutgateException_toRow(result);
    if (pendingOutgate) {
      state.pendingOutgate.push(pendingOutgate);
    }
    let emptyNotReturned = EmptyNotReturnedException_toRow(result);
    if (emptyNotReturned) {
      state.emptyNotReturned.push(emptyNotReturned);
    }
    let bookingLate = LateBookingException_toRow(result);
    if (bookingLate) {
      state.bookingLate.push(bookingLate);
    }
    let delayedUnload = DelayedUnloadException_toRow(result);
    if (delayedUnload) {
      state.delayedUnload.push(delayedUnload);
    }
    let podEta = PodEtaException_toRow(result);
    let podEtaGrouped = PodEtaException_toGroupedRow(result);
    if (podEta) {
      state.podEta.push(...podEta);
    }
    if (podEtaGrouped) {
      state.podEtaGrouped.push(podEtaGrouped);
    }
    let polEtd = PolEtdException_toRow(result);
    let polEtdGrouped = PolEtdException_toGroupedRow(result);
    if (polEtd) {
      state.polEtd.push(...polEtd);
    }
    if (polEtdGrouped) {
      state.polEtdGrouped.push(polEtdGrouped);
    }
    let lateShipments = LateShipmentException_toRows(result);
    if (lateShipments) {
      state.lateShipment.push(...lateShipments);
    }
  }

  state.bookingLateGrouped = LateBookingException_toGroupedRows(state.bookingLate);
  state.hotPurchaseOrdersGrouped = HotPOException_toGroupedRows(state.hotPurchaseOrders);
  state.hotShipmentsFiltered = HotShipmentException_toFilteredRows(state.hotShipments);

  return state;
}
