import { Fab } from '@material-ui/core';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(4.5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

interface Props {
  onClick(): void;
}

export const FloatingAddButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <div className={classes.root}>
      <Fab color="primary" aria-label="add" variant="extended" onClick={onClick}>
        <i className={'material-icons ' + classes.icon}>{'add'}</i>
        {children}
      </Fab>
    </div>
  );
};
