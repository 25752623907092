import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const PurchaseOrdersQuery = gql`
  query PurchaseOrders {
    purchaseOrders {
      id
      poNumber
      poDate
      expectedCargoReadyDate
      revisedCargoReadyDate
      firstShipDate
      lastShipDate
      shipToLocation {
        id
        name
        addressLine1
        addressLine2
        city
        state
        country
        postalCode
      }
      poStatus
      relatedParties {
        id
        partyType
        party {
          id
          name
        }
      }
      orderLines {
        id
        itemNumber
        itemDescription
        unitPrice
        unitPriceCurrencyCode
        expectedCargoReadyDate
        revisedCargoReadyDate
        shipToLocation {
          id
          name
          addressLine1
          addressLine2
          city
          state
          country
          postalCode
        }
        firstShipDate
        lastShipDate
        openOrder {
          balanceQty
          orderQty
          isHot
        }
      }
    }
  }
`;

export const PurchaseOrderQuery = gql`
  query PurchaseOrder($poId: ID!) {
    purchaseOrder(purchaseOrderId: $poId) {
      id
      poNumber
      altPoNumber
      indcDate
      poDate
      poType
      poStatus
      customerPoNumber
      expectedCargoReadyDate
      revisedCargoReadyDate
      firstShipDate
      lastShipDate
      bookByDate
      shipToLocation {
        id
        name
        addressLine1
        addressLine2
        city
        state
        country
        code
        postalCode
      }
      htsCode
      originCountry
      customerCancelShipDate
      incoTerm
      customerStartShipDate
      pol {
        ...Port
      }
      pod {
        ...Port
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          profileCode
        }
      }
      orderLines {
        id
        lineNumber
        lineStatus
        bookByDate
        expectedCargoReadyDate
        revisedCargoReadyDate
        totalQty
        outerQty
        innerQty
        indcDate
        weight
        weightUnit
        weightKg
        volume
        volumeUnit
        volumeM3
        unitPrice
        unitPriceCurrencyCode
        firstShipDate
        lastShipDate
        itemNumber
        itemDescription
        itemHtsCode
        itemStyle
        itemSize
        itemColor
        itemMaterial
        itemDepartment
        itemCategoryCode
        itemLength
        itemWidth
        itemHeight
        itemDimsUnit
        itemWeight
        itemWeightUnit
        itemVolume
        itemVolumeUnit
        itemDuty
        itemDutyCurrencyCode
        openOrder {
          approvedQty
          balanceQty
          shippedQty
          isHot
          hotMarkedBy
          hotMarkedTimestamp
        }
        shipToLocation {
          id
          name
          code
          addressLine1
          addressLine2
          city
          state
          country
          postalCode
        }
      }
      poHistory {
        id
        timestamp
        recordType
        message
        byUserName
        byPoUploadFileName
      }
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
  ${PortFragment}
`;

export const SetPoRelatedPartiesMutation = gql`
  mutation SetPoRelatedParties($input: SetPoRelatedPartiesInput!) {
    setPoRelatedParties(input: $input) {
      success
      message
    }
  }
`;

export const MarkPoLineAsHotMutation = gql`
  mutation MarkPoLineAsHot($input: MarkPoLineAsHotInput!) {
    markPoLineAsHot(input: $input) {
      success
      message
      openOrder {
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
    }
  }
`;

export const MarkPoAsHotMutation = gql`
  mutation MarkPoAsHot($input: MarkPoAsHotInput!) {
    markPoAsHot(input: $input) {
      success
      message
      purchaseOrder {
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
    }
  }
`;
