import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Switch from '@material-ui/core/Switch';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import { setReportScheduled } from 'api/queries/reportQueries';
import * as React from 'react';
import { Report } from 'types/Report';

const ReportScheduledSwitch: React.FC<{
  id: string;
  scheduled: boolean;
  onReport(report: Report): void;
}> = (props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [saved, setSaved] = React.useState(false);
  const [savedTimeout, setSavedTimeout] = React.useState<any>();

  async function setScheduled(scheduled: boolean) {
    setIsSaving(true);
    setError(null);
    try {
      const report = await setReportScheduled(props.id, scheduled);
      props.onReport(report);
      setSaved(true);
      setSavedTimeout(setTimeout(() => setSaved(false), 1000));
    } catch (err) {
      setError(err + '');
    } finally {
      setIsSaving(false);
    }
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(savedTimeout);
    };
  }, []);

  return (
    <Box display="flex" alignItems="center">
      <Switch
        checked={isSaving ? !props.scheduled : props.scheduled}
        onChange={(e) => setScheduled(e.target.checked)}
        color="secondary"
        disabled={isSaving}
      />
      <Fade in={saved}>
        <DoneIcon />
      </Fade>
      <Fade in={!!error}>
        <WarningIcon />
      </Fade>
    </Box>
  );
};

export default ReportScheduledSwitch;
