import { Button } from '@material-ui/core';
import React from 'react';
import { makeCss } from 'styles';

const classes = makeCss({
  root: {
    visibility: 'hidden',
  },
});

interface Props {}

/**
 * Used to maintain height when hiding/showing buttons.
 */
export const ButtonSpacer: React.FC<Props> = () => {
  return (
    <Button className={classes.root}>
      {'1' /* Need to render at least one char to keep the height. */}
    </Button>
  );
};
