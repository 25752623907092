import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrap: {
    paddingRight: theme.spacing(1),
  },
});

interface Props {
  fileExtension: string;
  displayExtension?: boolean;
}

export const FileExtensionIcon: React.FC<Props> = ({ fileExtension, displayExtension }) => {
  fileExtension = fileExtension.replace(/[^a-z0-9]/gi, '').toUpperCase();
  const icon = renderIcon(fileExtension);

  if (displayExtension) {
    return (
      <div className={classes.root}>
        <div className={classes.iconWrap}>{icon}</div> {fileExtension}
      </div>
    );
  }

  return icon;
};

function renderIcon(fileExtension: string) {
  switch (fileExtension) {
    case 'PDF':
      return (
        <span className="material-icons" style={{ color: '#dd090a' }}>
          <FontAwesomeIcon icon={faFilePdf} />
        </span>
      );

    case 'XLS':
    case 'XLSX':
    case 'CSV':
      return (
        <span className="material-icons" style={{ color: '#129d00' }}>
          <FontAwesomeIcon icon={faFileExcel} />
        </span>
      );

    case 'DOC':
    case 'DOCX':
      return (
        <span className="material-icons" style={{ color: '#426ffe' }}>
          <FontAwesomeIcon icon={faFileWord} />
        </span>
      );

    case 'JPG':
    case 'JPEG':
    case 'PNG':
    case 'GIF':
    case 'BMP':
    case 'TIF':
      return (
        <span className="material-icons" style={{ color: '#00c79e' }}>
          <FontAwesomeIcon icon={faFileImage} />
        </span>
      );
  }

  return (
    <span className="material-icons" style={{ color: '#426ffe' }}>
      <FontAwesomeIcon icon={faFile} />
    </span>
  );
}
