import { gqlClient } from 'api/ApolloClient';
import {
  GqlMarkPoLineAsHotMutation,
  GqlMarkPoLineAsHotMutationVariables,
  MarkPoLineAsHotDocument,
  PermissionCode,
} from 'api/GQL_Types';
import { auth } from 'app';
import { HotState, HotToggleSwitch } from 'components/HotToggleSwitch';
import * as React from 'react';

interface Props {
  orderLineId: string;
  initialState: HotState;

  small?: boolean;
  showDetailsOnLeft?: boolean;
  showDetailsOnRight?: boolean;
}

export const HotPoLineSwitch: React.FC<Props> = ({
  orderLineId,
  initialState,
  small,
  showDetailsOnLeft,
  showDetailsOnRight,
}) => {
  const { userContext } = auth.useAuthState();

  return (
    <HotToggleSwitch
      initialState={initialState}
      save={(hot) => markPoLineAsHot(orderLineId, hot)}
      canMarkHot={!!userContext?.permissionCodes.has(PermissionCode.PoMarkAsHot)}
      small={small}
      showDetailsOnLeft={showDetailsOnLeft}
      showDetailsOnRight={showDetailsOnRight}
    />
  );
};

async function markPoLineAsHot(orderLineId: string, hot: boolean): Promise<HotState> {
  const res = await gqlClient.mutate<
    GqlMarkPoLineAsHotMutation,
    GqlMarkPoLineAsHotMutationVariables
  >({
    mutation: MarkPoLineAsHotDocument,
    variables: {
      input: {
        orderLineId,
        hot,
      },
    },
  });
  const oo = res.data?.markPoLineAsHot.openOrder;
  if (res.data?.markPoLineAsHot.success && oo) {
    return oo;
  }
  throw new Error('Failed to mark PO line as hot. ' + (res.data?.markPoLineAsHot.message || ''));
}
