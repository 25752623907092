import { Popover, PopoverActions } from '@material-ui/core';
import React from 'react';

interface Props {
  popoverId: string;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  children: any;
  anchorOriginVertical?: 'bottom' | 'center' | 'top' | number;
  anchorOriginHorizontal?: 'left' | 'center' | 'right' | number;
  action?:
    | ((instance: PopoverActions | null) => void)
    | React.RefObject<PopoverActions>
    | null
    | undefined;
}

export default function UniversalPopover(props: Props) {
  const id = props.open ? props.popoverId : undefined;

  return (
    <Popover
      id={id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      action={props.action ?? undefined}
      anchorOrigin={{
        vertical: props.anchorOriginVertical ?? 'top',
        horizontal: props.anchorOriginHorizontal ?? 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {props.children}
    </Popover>
  );
}
