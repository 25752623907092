import {
  GqlAvailableConsolidationBookingListQuery,
  GqlRelatedParty,
  PartyType,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { mapMoveType, mapShipmentStatus } from 'types/OMSEnums';
import { formatNumber } from 'types/Number';

/**
 * Used on create consol and adding bookings to existing consolidations.
 */
export interface ConsolableBookingRow {
  id: string;
  referenceNumber: { to: string; value: string };
  bookingDate: Date | null;
  weight: number;
  volume: number;
  volumeUnit: VolumeUnit | null | undefined;
  weightUnit: WeightUnit | null | undefined;
  bookingStatus: string;
  cargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  pod: string;
  finalDestination: { id: string; name: string } | null;
  finalDestinationName: string;
  supplierName: string;
  moveType: string;
  containers: string;
  equipmentTypes: string[];
  relatedParties: GqlRelatedParty[];
}

export function availableConsolBookingsToRows(
  data: GqlAvailableConsolidationBookingListQuery
): ConsolableBookingRow[] {
  let bookingsList: ConsolableBookingRow[] = [];
  for (const booking of data.availableConsolidationBookings) {
    let containers = '';
    let equipmentTypes: string[] = [];
    let weight = 0;
    let volume = 0;
    let volumeUnit = undefined;
    let weightUnit = undefined;
    if (booking.packLines) {
      [weight, volume] = booking.packLines.reduce(
        (count, currentValue) => {
          return [count[0] + currentValue.weightKg, count[1] + currentValue.volumeM3];
        },
        [0, 0]
      );
      volumeUnit = VolumeUnit.Cbm;
      weightUnit = WeightUnit.Kg;
    }

    if (booking.containers) {
      const nContainersByType = new Map<string, number>();
      booking.containers.forEach((container) => {
        const n = nContainersByType.get(container.containerType) || 0;
        nContainersByType.set(container.containerType, n + 1);
      });
      const lines: string[] = [];
      nContainersByType.forEach((nContainers, type) => {
        lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
        equipmentTypes.push(type);
      });
      containers = lines.join('\n');
    }

    bookingsList.push({
      id: booking.id,
      referenceNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
      bookingDate: booking.createDate,
      weight: weight,
      volume: volume,
      volumeUnit: volumeUnit,
      weightUnit: weightUnit,
      bookingStatus: mapShipmentStatus(booking.status),
      cargoReadyDate: booking.cargoReadyDate,
      pol: booking.logistics.pol ? booking.logistics.pol.code : '',
      polEtd: booking.logistics.polEtd,
      pod: booking.logistics.pod ? booking.logistics.pod.code : '',
      finalDestination: booking.logistics.finalDestination
        ? {
            id: booking.logistics.finalDestination.id,
            name: booking.logistics.finalDestination.name,
          }
        : null,
      finalDestinationName: booking.logistics.finalDestination?.name ?? '',
      supplierName: relatedPartyNameOrVarious(booking.relatedParties, PartyType.Supplier),
      moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
      containers: containers,
      equipmentTypes,
      relatedParties: booking.relatedParties as GqlRelatedParty[],
    });
  }
  return bookingsList;
}

export const consolableBookingColumns: UWLTableColumn<ConsolableBookingRow>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'weight', label: 'Wgt', type: 'weight', unitField: 'weightUnit' },
  { id: 'volume', label: 'CBM', type: 'volume', unitField: 'volumeUnit' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'finalDestinationName', label: 'Final Destination', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string', whiteSpace: 'nowrap' },
  { id: 'containers', label: 'Eqpt Type', type: 'string', whiteSpace: 'nowrap' },
];
