import { Box, Hidden } from '@material-ui/core';
import Panel from 'components/Panel';
import { Line } from 'components/StyledComponents';
import MBLDetailsSection from './DetailsSection';
import MBLDetailsHeader from './MBLDetailsHeader';
import PortDetails from './PortDetails';

export default function MBLDetailsPanel() {
  return (
    <Panel
      title="MBL Details"
      topRight={
        <Hidden smDown>
          <MBLDetailsHeader />
        </Hidden>
      }
    >
      <Box margin={2}>
        <Hidden mdUp>
          <MBLDetailsHeader />
        </Hidden>

        <Box marginTop={3}>
          <PortDetails />
        </Box>
        <Line height={1} />
        <MBLDetailsSection />
      </Box>
    </Panel>
  );
}
