import * as React from 'react';
import { DatasetFilterType } from '..';
import FormInputSelect from '../../form/FormInputSelect';

export const SelectFilter: DatasetFilterType<string | null> = {
  deserializeValue(filterable, str) {
    if (typeof str === 'string') {
      return str;
    }
    return '';
  },
  serializeValue(filterable, value) {
    return value;
  },

  defaultValue(filterable) {
    return '';
  },

  validate(filterable, value) {
    if (filterable.noDefault && isNothingSelected(value)) {
      return 'Make a selection for ' + filterable.label;
    }
    return null;
  },

  RenderInput(props) {
    const options = props.filterable.options || [];
    const noDefault = !!props.filterable.noDefault;

    return (
      <FormInputSelect
        options={options.map((o) => ({ id: o.value, label: o.label }))}
        value={props.value ? props.value + '' : null}
        onValue={(val) => {
          if (noDefault && isNothingSelected(val)) {
            return;
          }
          props.onValue(val);
        }}
        label=""
        nullOptionLabel={noDefault ? undefined : 'ALL'}
        disabled={props.disabled}
        notFullWidth // OMS-273
      />
    );
  },
};

function isNothingSelected(value: unknown): boolean {
  return typeof value !== 'string' || value.trim() === '';
}
