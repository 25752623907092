import React from 'react';
import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { useRecoilValue } from 'recoil';
import { BookingPageStates } from '../../states';
import { confirmBookingValidationState } from './states';

export default function ConfirmationSection() {
  const validation = useRecoilValue(confirmBookingValidationState);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6}>
        <AtomicTextFieldV2
          state={BookingPageStates.editLogistics.confirmationNumber}
          label="Confirmation"
          required={validation.confirmationNumberError}
          helperText={validation.confirmationNumberError ? 'Field required' : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <AtomicDatePickerV2
          state={BookingPageStates.editLogistics.confirmationDate}
          label="Confirmation Date"
          error={validation.confirmationDateError ? 'Date required' : undefined}
        />
      </Grid>
    </Grid>
  );
}
