import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';

interface Props {}

const LoadingProgress: React.FC<Props> = (props) => {
  return (
    <Box position={'relative'} padding={1} height={100}>
      <Box
        position={'absolute'}
        top={'50%'}
        left={'50%'}
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default LoadingProgress;
