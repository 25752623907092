import { RouteComponentProps } from '@reach/router';
import AdminDashboard from 'app/admin/Dashboard';
import ProfilesTypes from 'app/admin/profile-types';
import Profiles from 'app/admin/profiles';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import Networks from './networks';

interface AdminProps extends RouteComponentProps {}
function Admin(props: AdminProps) {
  return (
    <FlexGrowRouter>
      <AdminDashboard path="/" />
      <ProfilesTypes path="profile-types/*" />
      <Profiles path="profiles/*" />
      <Networks path="networks/*" />
    </FlexGrowRouter>
  );
}

export default Admin;
