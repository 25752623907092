import React from 'react';
import { makeCss } from 'styles';

const classes = makeCss({
  highlight: {
    textDecoration: 'underline',
  },
});

interface HiglightPart {
  highlight: boolean;
  str: string;
}

function getHighlights(searchStr: string, str: string): HiglightPart[] {
  if (searchStr.length === 0) {
    return [
      {
        highlight: false,
        str,
      },
    ];
  }
  const strLC = str.toLowerCase();
  searchStr = searchStr.toLowerCase();

  let startIndex = 0;
  let index;
  let indices = [];
  while ((index = strLC.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStr.length;
  }

  if (indices.length === 0) {
    return [
      {
        highlight: false,
        str,
      },
    ];
  }

  const parts: HiglightPart[] = [];

  for (let i = 0; i < indices.length; i++) {
    if (i === 0 && indices[i] !== 0) {
      parts.push({
        highlight: false,
        str: str.substring(0, indices[i]),
      });
    }

    parts.push({
      highlight: true,
      str: str.substring(indices[i], indices[i] + searchStr.length),
    });

    if (i + 1 < indices.length) {
      parts.push({
        highlight: false,
        str: str.substring(indices[i] + searchStr.length, indices[i + 1]),
      });
    } else {
      parts.push({
        highlight: false,
        str: str.substring(indices[i] + searchStr.length),
      });
    }
  }

  return parts;
}

interface Props {
  str: string;
  searchStr: string;
  highlightClassName?: string;
}

export const HighlightMatches: React.FC<Props> = ({ str, searchStr, highlightClassName }) => {
  const parts = getHighlights(searchStr, str);

  const hlClass = highlightClassName || classes.highlight;

  return (
    <span>
      {parts.map((part, index) => (
        <span key={index} className={part.highlight ? hlClass : undefined}>
          {part.str}
        </span>
      ))}
    </span>
  );
};
