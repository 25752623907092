import { GqlNetworkDocumentTypeRuleInput, RuleFieldType } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { RuleCardDayField } from './components/RuleCardDayField';
import { RuleCardGroup } from './components/RuleCardGroup';

const ALL_dateFields: RuleFieldType[] = Object.values(RuleFieldType);

interface Props {}

export const RulesMissingDocument: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);
  const documentTypes = useRecoilValue(AppWideStates.network.documentTypes);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Missing Document"
      bools={rulesForm.documentTypes.map((dt) => dt.missingEnabled)}
    >
      {rulesForm.documentTypes.map((dt) => {
        function updateDocument(
          updater: (old: GqlNetworkDocumentTypeRuleInput) => GqlNetworkDocumentTypeRuleInput
        ) {
          if (!rulesForm) return;
          setRulesForm({
            ...rulesForm,
            documentTypes: rulesForm.documentTypes.map((dt2) => {
              if (dt2.documentType === dt.documentType) {
                return updater(dt);
              }
              return dt2;
            }),
          });
        }
        return (
          <RuleCardDayField
            key={dt.documentType}
            name={`Missing ${dt.documentType} - ${documentTypes.byCode.get(dt.documentType)?.name}`}
            description="Consider this document missing after # days of the selected field."
            enabled={dt.missingEnabled}
            onEnabled={(enabled) => {
              updateDocument((dt2) => ({ ...dt2, missingEnabled: enabled }));
            }}
            days={dt.missingDays}
            onDays={(v) => {
              updateDocument((dt2) => ({ ...dt2, missingDays: v }));
            }}
            fieldOptions={ALL_dateFields}
            field={dt.missingField}
            onField={(v) => {
              updateDocument((dt2) => ({ ...dt2, missingField: v }));
            }}
          />
        );
      })}
    </RuleCardGroup>
  );
};
