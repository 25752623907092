import { RuleFieldType } from 'api/GQL_Types';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { RuleCardDay } from './components/RuleCardDay';
import { RuleCardDayField } from './components/RuleCardDayField';
import { RuleCardGroup } from './components/RuleCardGroup';

const delayedUnloadFieldOptions = [
  RuleFieldType.ContainerDetentionLastFreeDate,
  RuleFieldType.ContainerDeliveryAta,
  RuleFieldType.ContainerPortOutGate,
  RuleFieldType.ContainerRampOutGate,
];

interface Props {}

export const RulesMilestone: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Milestone"
      bools={[
        rulesForm.milestoneLateOnWaterEnabled,
        rulesForm.milestoneLateAtDischargeEnabled,
        rulesForm.milestoneLateAtRampEnabled,
        rulesForm.milestoneLateDeliveryEnabled,
        rulesForm.milestonePendingOutgateEnabled,
        rulesForm.milestoneDelayedUnloadEnabled,
        rulesForm.milestoneEmptyNotReturnedEnabled,
      ]}
    >
      <RuleCardDay
        name="Late On the Water"
        description="Days after ETD."
        enabled={rulesForm.milestoneLateOnWaterEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestoneLateOnWaterEnabled: enabled })
        }
        days={rulesForm.milestoneLateOnWaterDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneLateOnWaterDays: days })}
      />
      <RuleCardDay
        name="Late At Discharge"
        description="Days after discharge ETA."
        enabled={rulesForm.milestoneLateAtDischargeEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestoneLateAtDischargeEnabled: enabled })
        }
        days={rulesForm.milestoneLateAtDischargeDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneLateAtDischargeDays: days })}
      />
      <RuleCardDay
        name="Late At Ramp"
        description="Days after rail ramp ETA."
        enabled={rulesForm.milestoneLateAtRampEnabled}
        onEnabled={(enabled) => setRulesForm({ ...rulesForm, milestoneLateAtRampEnabled: enabled })}
        days={rulesForm.milestoneLateAtRampDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneLateAtRampDays: days })}
      />
      <RuleCardDay
        name="Late Delivery"
        description="Days after destination ETA."
        enabled={rulesForm.milestoneLateDeliveryEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestoneLateDeliveryEnabled: enabled })
        }
        days={rulesForm.milestoneLateDeliveryDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneLateDeliveryDays: days })}
      />
      <RuleCardDay
        name="Pending Outgate"
        description="Days after Demurrage last free day before considered pending outgate."
        enabled={rulesForm.milestonePendingOutgateEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestonePendingOutgateEnabled: enabled })
        }
        days={rulesForm.milestonePendingOutgateDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestonePendingOutgateDays: days })}
      />
      <RuleCardDayField
        notYetSupported
        name="Delayed Unload"
        description="Days after the selected field."
        enabled={rulesForm.milestoneDelayedUnloadEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestoneDelayedUnloadEnabled: enabled })
        }
        days={rulesForm.milestoneDelayedUnloadDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneDelayedUnloadDays: days })}
        fieldOptions={delayedUnloadFieldOptions}
        field={rulesForm.milestoneDelayedUnloadField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, milestoneDelayedUnloadField: v });
        }}
      />
      <RuleCardDay
        name="Empty Not Returned"
        description="Days after Detention last free day before considered not returned."
        enabled={rulesForm.milestoneEmptyNotReturnedEnabled}
        onEnabled={(enabled) =>
          setRulesForm({ ...rulesForm, milestoneEmptyNotReturnedEnabled: enabled })
        }
        days={rulesForm.milestoneEmptyNotReturnedDays}
        onDays={(days) => setRulesForm({ ...rulesForm, milestoneEmptyNotReturnedDays: days })}
      />
    </RuleCardGroup>
  );
};
