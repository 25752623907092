import { Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  label?: string;
  line1?: any;
  line2?: any;
}

export default function MultiLineDetail(props: Props) {
  return (
    <div style={{ marginLeft: '8px' }}>
      <Typography variant="h4">{props.label}</Typography>
      <div>
        <Typography variant="body1">{props.line1}</Typography>
      </div>
      <div>
        <Typography variant="body1">{props.line2}</Typography>
      </div>
    </div>
  );
}
