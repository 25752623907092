import { Box, Button, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { RecoilState, useSetRecoilState } from 'recoil';
import { theme } from 'styles';

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export interface Label {
  label: string;
  value: string;
}

interface Props {
  list: Label[];
  hasChanged?: boolean;
  defaultValue: any;
  formState: RecoilState<any>;
}

export default function TextFieldWarnDecorator(props: Props) {
  const setField = useSetRecoilState(props.formState);

  return (
    <>
      {new Set(props.list.map((l) => l.value)).size > 1 && (
        <Box display="flex" justifyContent="space-between" marginRight={'24px'}>
          <Typography variant="body1" style={{ color: theme.palette.orange.main }}>
            <i>Multiple values found.</i>
          </Typography>
          <CustomTooltip
            title={props.list.map((value, index) => (
              <Typography key={index}>
                <span style={{ fontWeight: 800, marginRight: theme.spacing(1) }}>
                  {value.label}:
                </span>{' '}
                {value.value || 'Not Set'}
              </Typography>
            ))}
            interactive
          >
            <ErrorIcon style={{ width: 20, height: 20, color: theme.palette.orange.main }} />
          </CustomTooltip>
        </Box>
      )}
      {props.hasChanged && (
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingRight="16px">
          <Box>
            <Typography variant="body1" style={{ color: theme.palette.success.main }}>
              <b>This field will be updated.</b>
            </Typography>
          </Box>
          <Box>
            <Button
              size="small"
              onClick={() => {
                setField(props.defaultValue);
              }}
              style={{ color: theme.palette.primary.main }}
            >
              Undo
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
