import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import NewConsolidation from './new';
import ConsolidationDetails from './details';

interface ConsolidationProps extends RouteComponentProps {}
export default function Consolidations(props: ConsolidationProps) {
  return (
    <FlexGrowRouter>
      <NewConsolidation path="/new" />
      <ConsolidationDetails path="/:consolidationId" />
    </FlexGrowRouter>
  );
}
