import React from 'react';
import {
  ButtonBase,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { mapSearchType } from 'types/OMSEnums';
import { HeaderStates } from './states';
import { auth } from 'app';

interface Props {}

export default function HeaderQueryDropdown(props: Props) {
  const { userContext } = auth.useAuthState();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const [searchType, setSearchType] = useRecoilState(HeaderStates.searchType);
  const setSearchText = useSetRecoilState(HeaderStates.searchText);
  const setSearchResult = useSetRecoilState(HeaderStates.searchResult);

  return (
    <>
      <ButtonBase
        ref={anchorRef}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={() => {
          setOpen(true);
        }}
        style={{
          borderRadius: 0,
          border: 0,
          width: '200px',
          padding: theme.spacing(0, 1, 0, 2),
          display: 'flex',
        }}
      >
        <Typography variant="h3" style={{ textAlign: 'left', flex: 1 }}>
          <p>{mapSearchType(searchType)}</p>
        </Typography>
        <ArrowDropDownIcon />
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              width: '164px',
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <MenuList id="split-button-menu-2">
                  {userContext?.headerSearchTypes.map((code) => {
                    return (
                      <MenuItem
                        key={code}
                        value={code}
                        onClick={(e) => {
                          setSearchType(code);
                          setSearchText('');
                          setSearchResult(null);
                          setOpen(false);
                        }}
                      >
                        {mapSearchType(code)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
