import { Currency, GqlHblQuery, GqlHouseBill } from 'api/GQL_Types';
import { Document } from 'app/components/DocumentsPanel';
import { HotState } from 'components/HotToggleSwitch';
import { Remark } from 'components/Remarks';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';

export interface HblPageData extends GqlHouseBill {
  hot: HotState;
  allDocuments: Document[];
  equipmentRows: EquipmentRow[];
  packLineRows: HblPackLine[];
  deliveryAta: Date | null | undefined;
}

export const HblPageStates = {
  hbl: newAtom<HblPageData | null>(null),
  allRemarks: newAtom<Remark[]>([]),
};

export interface HblPackLine {
  id: string;
  poId: string;
  poNumber: { to: string; value: string };
  itemNumber: string;
  description: string;
  lastShipDate: Date | null | undefined;
  requestedDeliveryDate: Date | null | undefined;
  shipToLocation: string;
  unitPrice: number;
  unitPriceCurrencyCode: Currency;
  htsCode: string;
  shippedQty: number;
  shippedCartons: number;
  volumeM3: number;
  weightKg: number;
}

export interface EquipmentRow {
  id: string;
  isHot: boolean;
  containerNumber: { to: string; value: string };
  containerType: string;
  sealNumber: string;
  volumeM3: number;
  weightKg: number;
  shipFromFactoryDate: Date | null | undefined;
  terminalReceivedDate: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export function useSetupHblPage() {
  const setHbl = useSetRecoilState(HblPageStates.hbl);

  return (init: GqlHblQuery | undefined) => {
    const hbl = init?.hbl;
    if (!hbl) {
      setHbl(null);
      return;
    }

    const hotHbl: HotState = {
      isHot: hbl.booking.isHot,
      hotMarkedBy: hbl.booking.hotMarkedBy,
      hotMarkedTimestamp: hbl.booking.hotMarkedTimestamp,
    };

    let bookingDocuments: Document[] = [];
    let hblDocuments: Document[] = [];
    for (const doc of hbl.documents) {
      hblDocuments.push({
        id: doc.id,
        bookingId: '',
        bookingNumber: '',
        documentType: doc.documentType,
        fileName: doc.fileName,
        importFileName: doc.importFileName,
        fileType: doc.fileType,
        fileSize: doc.fileSize,
        uploadDate: doc.uploadDate,
        description: doc.description || '',
      });
    }
    for (const doc of hbl.booking.documents) {
      bookingDocuments.push({
        id: doc.id,
        bookingId: hbl.booking.id,
        bookingNumber: hbl.booking.referenceNumber,
        documentType: doc.documentType,
        fileName: doc.fileName,
        importFileName: doc.importFileName,
        fileType: doc.fileType,
        fileSize: doc.fileSize,
        uploadDate: doc.uploadDate,
        description: doc.description || '',
      });
    }

    const equipmentRows: EquipmentRow[] = [];
    for (const cnt of hbl.booking.containers) {
      equipmentRows.push({
        id: cnt.id,
        isHot: cnt.isHot,
        containerNumber: cnt.containerNumber
          ? {
              to: '/equipment/' + cnt.id,
              value: cnt.containerNumber,
            }
          : { to: '', value: 'Not Assigned' },
        containerType: cnt.containerType,
        sealNumber: cnt.sealNumber || '',
        volumeM3: cnt.volumeM3 || 0,
        weightKg: cnt.weightKg || 0,
        shipFromFactoryDate: cnt.shipFromFactoryDate,
        terminalReceivedDate: cnt.terminalReceivedDate,
        deliveryAta: cnt.deliveryAta,
      });
    }
    // Also checking containers via packLines for consolidated bookings - OMS-276
    for (const pl of hbl.booking.packLines) {
      for (const vp of pl.vanPositions) {
        const cnt = vp.container;
        let row: EquipmentRow | undefined = equipmentRows.find((r) => r.id === cnt.id);
        if (!row) {
          row = {
            id: cnt.id,
            isHot: cnt.isHot,
            containerNumber: cnt.containerNumber
              ? {
                  to: '/equipment/' + cnt.id,
                  value: cnt.containerNumber,
                }
              : { to: '', value: 'Not Assigned' },
            containerType: cnt.containerType,
            sealNumber: cnt.sealNumber || '',
            volumeM3: 0,
            weightKg: 0,
            shipFromFactoryDate: cnt.shipFromFactoryDate,
            terminalReceivedDate: cnt.terminalReceivedDate,
            deliveryAta: cnt.deliveryAta,
          };
          equipmentRows.push(row);
        }
        row.volumeM3 += vp.volumeM3 || 0;
        row.weightKg += vp.weightKg || 0;
      }
    }

    let deliveryAta = hbl?.booking.logistics.deliveryAta;
    if (hbl.booking.consolidation?.id) {
      // Consolidations should show the last Delivery ATA of it's own containers - OMS-306
      deliveryAta = null;
      for (const eqp of equipmentRows) {
        if (eqp.deliveryAta) {
          if (!deliveryAta || deliveryAta < eqp.deliveryAta) {
            deliveryAta = eqp.deliveryAta;
          }
        } else {
          deliveryAta = null; // we should not show an ATA b/c one container is not delivered
          break;
        }
      }
    }

    const pageData: HblPageData = {
      ...(hbl as any),
      hot: hotHbl,
      allDocuments: [...bookingDocuments, ...hblDocuments],
      equipmentRows,
      packLineRows: hbl.booking.packLines.map((line): HblPackLine => {
        return {
          id: line.id,
          poId: line.orderLine.purchaseOrder.id,
          poNumber: {
            to: '/purchase-orders/' + line.orderLine.purchaseOrder.id,
            value: line.orderLine.purchaseOrder.poNumber,
          },
          itemNumber: line.orderLine.itemNumber,
          description: line.orderLine.itemDescription,
          lastShipDate: line.orderLine.lastShipDate,
          requestedDeliveryDate: line.requestedDeliveryDate,
          shipToLocation: line.orderLine?.shipToLocation?.name || '',
          unitPrice: line.orderLine.unitPrice || 0,
          unitPriceCurrencyCode: line.orderLine.unitPriceCurrencyCode,
          htsCode: line.orderLine.itemHtsCode || '',
          shippedQty: line.shippedQty,
          shippedCartons: line.shippedCartons,
          volumeM3: line.volumeM3,
          weightKg: line.weightKg,
        };
      }),
      deliveryAta,
    };

    setHbl(pageData);
  };
}
