import { GqlApiToken, GqlApiTokensQuery, GqlApiWebhook, GqlApiWebhooksQuery } from 'api/GQL_Types';
import { newAtom } from 'lib/RecoilUtils';
import { useSetRecoilState } from 'recoil';

export type ApiToken = GqlApiToken;
export type ApiWebhook = GqlApiWebhook;

export const ApiPageStates = {
  apiTokens: newAtom<ApiToken[]>([]),
};

export const ApiWebhookPageStates = {
  apiWebhooks: newAtom<ApiWebhook[]>([]),
};

export function useSetApiPageState() {
  const setApiTokens = useSetRecoilState(ApiPageStates.apiTokens);

  return (data: GqlApiTokensQuery | null) => {
    if (!data?.apiTokens) {
      setApiTokens([]);
      return;
    }

    const api = {
      apiTokens: data.apiTokens ? data.apiTokens.map(({ __typename, ...at }) => at) : [],
    };

    setApiTokens(api.apiTokens);
  };
}

export function useSetApiWebhookPageState() {
  const setApiWebhooks = useSetRecoilState(ApiWebhookPageStates.apiWebhooks);

  return (data: GqlApiWebhooksQuery | null) => {
    if (!data?.apiWebhooks) {
      setApiWebhooks([]);
      return;
    }

    const apiWebhooks = {
      apiWebhooks: data.apiWebhooks ? data.apiWebhooks.map(({ __typename, ...at }) => at) : [],
    };

    setApiWebhooks(apiWebhooks.apiWebhooks);
  };
}
