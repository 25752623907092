import { DocumentEventCode } from 'api/GQL_Types';
import { DateRangeValue } from 'components/form/FormInputDateRange';
import { SearchResult } from 'components/form/FormInputSearch';
import { newAtom } from 'lib/RecoilUtils';

export const DocumentBrowserStates = {
  dateField: newAtom<DocumentEventCode>(DocumentEventCode.UploadDate),
  dateRange: newAtom<DateRangeValue | null>(null),
  documentType: newAtom<string | null>(null),
  shipper: newAtom<string>(''),
  destination: newAtom<string>(''),
  poNumber: newAtom<SearchResult | null>(null),
  itemNumber: newAtom<SearchResult | null>(null),
  newDocuments: newAtom<boolean>(true),

  loading: newAtom<boolean>(false),
  error: newAtom<string | null>(null),
  documents: newAtom<DocumentBrowserRow[]>([]),
};

export interface DocumentBrowserRow {
  id: string;
  bookingId: string | null;
  bookingNumber: string | null;
  importFileName: string;
  fileType: string;
  documentType: string;
  uploadDate: Date | null;
  shipperName: string | null;
  hblId: string | null;
  hblNumber: string | null;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  deliveryLocation: string | null;
}
