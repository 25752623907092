import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import Loading from 'app/Loading';
import { useLocationTypeNameLookup } from 'app/states';
import LineItem from 'components/LineItem';
import SectionTitle from 'components/SectionTitle';
import { Line } from 'components/StyledComponents';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { theme } from 'styles';
import { ProfilePageStates } from '../states';
import EditProfileDetailsDialog, {
  EditProfileDetailsDialog_Open,
} from './EditProfileDetailsDialog';
import { ReviewProfileDialog_Open } from './ReviewProfileDialog';

const Wrapper = styled.div`
  margin: '16px';
`;

const Thumb = styled.div`
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
`;

interface Props extends RouteComponentProps {}

export default function ProfileDetails(props: Props) {
  const { userContext } = auth.useAuthState();
  const profile = useRecoilValue(ProfilePageStates.profile);
  const setOpenEditDialog = useSetRecoilState(EditProfileDetailsDialog_Open);
  const setOpenReviewDialog = useSetRecoilState(ReviewProfileDialog_Open);
  const locationTypeNameLookup = useLocationTypeNameLookup();

  if (!profile) {
    return <Loading />;
  }

  const canEdit = userContext?.permissionCodes.has(PermissionCode.ProfileUpdate);

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginRight={1}>
        <Typography variant="h2">Profile Details</Typography>
        {canEdit &&
          (profile.needsUpdate ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenReviewDialog(true);
              }}
            >
              Review Profile
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenEditDialog(true);
              }}
            >
              Edit
            </Button>
          ))}
      </Box>
      <Line height={2} color={theme.palette.primary.main} />

      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {profile.logo ? (
              <Thumb key={profile.name}>
                <Box style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                  <img
                    alt={profile.name}
                    src={profile.logo.url}
                    style={{
                      display: 'block',
                      maxWidth: '275px',
                      minWidth: '60px',
                      height: '60px',
                    }}
                  />
                </Box>
              </Thumb>
            ) : (
              <Box height="40px" />
            )}
            <LineItem value={profile.profileCode} label="Profile Code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={profile.network.name} label="Network" />
            <LineItem value={profile.profileType?.name} label="Profile Type" />
          </Grid>
        </Grid>
      </Box>
      <SectionTitle title="Main Location" />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={profile.mainLocation?.name} label="Location Name" />
            <LineItem value={profile.mainLocation?.addressLine1} label="Address 1" />
            <LineItem value={profile.mainLocation?.city} label="City" />
            <LineItem value={profile.mainLocation?.postalCode} label="Postal Code" />
            <LineItem
              value={(profile.mainLocation?.locationTypes || [])
                .map(locationTypeNameLookup)
                .join(', ')}
              label="Location Types"
            />
            <LineItem value={profile.mainLocation?.code} label="Location Code" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={profile.mainLocation?.relatedPort?.code} label="UNLOCO" />
            <LineItem value={profile.mainLocation?.addressLine2} label="Address 2" />
            <LineItem value={profile.mainLocation?.state} label="State" />
            <LineItem value={profile.mainLocation?.country} label="Country" />
            <LineItem value={profile.mainLocation?.timeZone} label="Time Zone" />
            <LineItem value={profile.mainLocation?.companyPhone} label="Company Phone" />
            <LineItem value={profile.mainLocation?.companyUrl} label="Company URL" />
          </Grid>
        </Grid>
      </Box>
      <SectionTitle title="Main Contact" />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={profile.mainContact?.firstName} label="First Name" />
            <LineItem value={profile.mainContact?.title} label="Title" />
            <LineItem value={profile.mainContact?.phoneMain} label="Phone (Main)" />
            <LineItem value={profile.mainContact?.phoneAlternative} label="Phone (Alt)" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={profile.mainContact?.lastName} label="Last Name" />
            <LineItem value={profile.mainContact?.email} label="Email" />
            <LineItem value={profile.mainContact?.extension} label="Extension" />
            <LineItem value={profile.mainContact?.phoneCell} label="Phone (Cell)" />
          </Grid>
        </Grid>
      </Box>

      <EditProfileDetailsDialog />
    </Wrapper>
  );
}
