import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Button } from '@material-ui/core';
import { navigate } from '@reach/router';
import {
  GqlNewSystemDocumentTypeMutation,
  GqlNewSystemDocumentTypeMutationVariables,
} from 'api/GQL_Types';
import { newSystemDocumentType, SystemDocumentTypesQuery } from 'api/queries/documentTypeQueries';
import ErrorMessage from 'components/ErrorMessage';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  onClose(): void;
}

export const AddDocumentTypeDialog: React.FC<Props> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: SystemDocumentTypesQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const newSystemDocumentTypeAction = useAsyncAction<
    GqlNewSystemDocumentTypeMutationVariables,
    GqlNewSystemDocumentTypeMutation
  >((input) => newSystemDocumentType(input, { refetchQueries }), {
    onData(data) {
      onClose();
      enqueueSnackbar(data.newSystemDocumentType.message || 'Document Code Added', {
        variant: 'success',
      });
      const newCode = data.newSystemDocumentType.systemDocumentType?.code;
      if (newCode) {
        navigate(`/sys-admin/document-types/${code}`);
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create document type: ' + error, { variant: 'error' });
    },
  });

  const codeNormalized = code.replace(/\s+/g, '').toUpperCase();

  const codeError: string | null =
    codeNormalized.length === 0 || codeNormalized.length === 3 || codeNormalized.length === 4
      ? null
      : 'Code should be 3 or 4 characters long';

  return (
    <UniversalDialog
      open
      title="New Document Type"
      small
      setClose={() => {
        onClose();
      }}
    >
      <FormRow noMargin>
        <FormItem maxWidth={120}>
          <FormInputText
            label="Code"
            required
            value={code}
            onValue={setCode}
            onBlur={() => {
              setCode(codeNormalized);
            }}
            error={!!codeError}
            disabled={newSystemDocumentTypeAction.waiting}
          />
        </FormItem>
        <FormItem>
          <FormInputText
            label="Description"
            required
            value={name}
            onValue={setName}
            disabled={newSystemDocumentTypeAction.waiting}
          />
        </FormItem>
      </FormRow>
      <ErrorMessage error={codeError} />
      <UniversalDialogFooter error={newSystemDocumentTypeAction.error}>
        <Button
          variant="contained"
          color="primary"
          disabled={
            codeNormalized.length === 0 ||
            name.trim().length === 0 ||
            newSystemDocumentTypeAction.waiting
          }
          onClick={() => {
            newSystemDocumentTypeAction.act({
              input: {
                code: codeNormalized,
                name: name,
              },
            });
          }}
        >
          Save
        </Button>
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};
