import React from 'react';
import { PermissionCode, useNewConsolidationRemarkMutation } from 'api/GQL_Types';
import { ConsolidationQuery } from 'api/queries/consolidationQueries';
import { userContextAtom } from 'app';
import Remarks from 'components/Remarks';
import { useRecoilValue } from 'recoil';
import { ConsolidationPageStates } from '../states';
import { useSnackbar } from 'notistack';

export default function ConsolidationRemarks() {
  const userContext = useRecoilValue(userContextAtom);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const allRemarks = useRecoilValue(ConsolidationPageStates.allRemarks);
  const { enqueueSnackbar } = useSnackbar();

  const [saveConsolidationRemark] = useNewConsolidationRemarkMutation({
    onCompleted: (data) => {
      if (data.newConsolidationRemark) {
        enqueueSnackbar('Consolidation Remark Added.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: ConsolidationQuery,
        variables: { consolId: consolidation?.id ?? '' },
        fetchPolicy: 'network-only',
      },
    ],
  });

  return (
    <>
      {userContext?.permissionCodes.has(PermissionCode.ConsolidationRemarkRead) && (
        <Remarks
          title="Remarks"
          allowComments={userContext?.permissionCodes.has(PermissionCode.ConsolidationRemarkCreate)}
          remarks={allRemarks}
          makeRemark={(text) => {
            saveConsolidationRemark({
              variables: {
                input: {
                  consolidationId: consolidation?.id ?? '',
                  remark: {
                    text: text,
                  },
                },
              },
            });
          }}
        />
      )}
    </>
  );
}
