import { IconButton, InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { FormInputText } from 'components/form/FormInputText';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  value: string;
  alwaysVisible?: boolean;
}

export const SecretReadOnlyField: React.FC<Props> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [valueVisible, setValueVisible] = React.useState<boolean>(false);

  return (
    <FormInputText
      disabled
      label=""
      value={props.alwaysVisible || valueVisible ? props.value : '**********'}
      onValue={() => {}}
      endAdornment={
        <InputAdornment position="end" style={{ marginRight: '-8px' }}>
          {!props.alwaysVisible && (
            <IconButton
              title="Toggle Visibility"
              onClick={() => {
                setValueVisible(!valueVisible);
              }}
            >
              {!valueVisible && <VisibilityIcon />}
              {valueVisible && <VisibilityOffIcon />}
            </IconButton>
          )}
          <IconButton
            title="Copy"
            onClick={() => {
              navigator.clipboard.writeText(props.value);
              enqueueSnackbar('Copied to clipboard!', { variant: 'success' });
            }}
          >
            <i className="material-icons">{'content_copy'}</i>
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
