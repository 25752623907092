import { Box, Button, Container } from '@material-ui/core';
import { useUpdateUserMutation } from 'api/GQL_Types';
import { UserQuery } from 'api/queries/userQueries';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { ManageUserPageData, ManageUserPageStates } from '../states';

export const editUserDetailsDialog_Open = newAtom(false);
export const UserDetailsDialog_Data = newAtom<ManageUserPageData | null>(null);

const userDetailsState = {
  firstName: newAtom(''),
  lastName: newAtom(''),
  email: newAtom(''),
  title: newAtom(''),
  phoneMain: newAtom(''),
  extension: newAtom(''),
  phoneCell: newAtom(''),
};

export default function EditUserDetailsDialog() {
  const [openEditDialog, setOpenEditDialog] = useRecoilState(editUserDetailsDialog_Open);
  const user = useRecoilValue(UserDetailsDialog_Data);

  const setFirstName = useSetRecoilState(userDetailsState.firstName);
  const setLastName = useSetRecoilState(userDetailsState.lastName);
  const setEmail = useSetRecoilState(userDetailsState.email);
  const setTitle = useSetRecoilState(userDetailsState.title);
  const setPhoneMain = useSetRecoilState(userDetailsState.phoneMain);
  const setExtension = useSetRecoilState(userDetailsState.extension);
  const setPhoneCell = useSetRecoilState(userDetailsState.phoneCell);

  React.useEffect(() => {
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
    setTitle(user?.title || '');
    setPhoneMain(user?.phoneMain || '');
    setExtension(user?.extension || '');
    setPhoneCell(user?.phoneCell || '');
  }, [user, openEditDialog]);

  return (
    <UniversalDialog
      open={openEditDialog}
      title="Edit User Details"
      setClose={() => {
        setOpenEditDialog(false);
      }}
    >
      <Line color={theme.palette.primary.main} height={2} />
      <Container>
        <Box width={700} marginBottom={2}>
          <FormRow>
            <AtomicTextFieldV2 label="First Name" state={userDetailsState.firstName} />
            <AtomicTextFieldV2 label="Last Name" state={userDetailsState.lastName} />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2 label="Email" state={userDetailsState.email} />
            <AtomicTextFieldV2 label="Title" state={userDetailsState.title} />
          </FormRow>
          <FormRow>
            <AtomicPhoneNumberV2 label="Main Phone" state={userDetailsState.phoneMain} />
            <AtomicTextFieldV2 label="Extension" state={userDetailsState.extension} />
          </FormRow>
          <FormRow>
            <AtomicPhoneNumberV2 label="Cell Phone" state={userDetailsState.phoneCell} />
            <FormItem>{/* Empty space */}</FormItem>
          </FormRow>
        </Box>
      </Container>
      <Line height={1} />
      <Footer />
    </UniversalDialog>
  );
}

function Footer() {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <CancelButton />
      <UpdateUserDetails />
    </Box>
  );
}

function UpdateUserDetails() {
  const userId = useRecoilValue(ManageUserPageStates.user)?.id ?? '';
  const setOpenEditDialog = useSetRecoilState(editUserDetailsDialog_Open);
  const user = useRecoilValue(UserDetailsDialog_Data);
  const firstName = useRecoilValue(userDetailsState.firstName);
  const lastName = useRecoilValue(userDetailsState.lastName);
  const email = useRecoilValue(userDetailsState.email);
  const title = useRecoilValue(userDetailsState.title);
  const phoneMain = useRecoilValue(userDetailsState.phoneMain);
  const extension = useRecoilValue(userDetailsState.extension);
  const phoneCell = useRecoilValue(userDetailsState.phoneCell);
  const { enqueueSnackbar } = useSnackbar();

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: () => [
      {
        query: UserQuery,
        variables: { userId: userId },
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted(data) {
      if (data.updateUser.success) {
        setOpenEditDialog(false);
        enqueueSnackbar('User Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        if (!user) return;
        updateUser({
          variables: {
            input: {
              user: {
                id: user.id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                title: title,
                phoneMain: phoneMain,
                extension: extension,
                phoneCell: phoneCell,
                defaultContactId: user.defaultContact.id,
              },
            },
          },
        });
      }}
      style={{ marginRight: '12px' }}
    >
      Update User
    </Button>
  );
}

function CancelButton() {
  const setOpenEditDialog = useSetRecoilState(editUserDetailsDialog_Open);

  return (
    <Button
      variant="contained"
      color="default"
      size="large"
      onClick={() => {
        setOpenEditDialog(false);
      }}
      style={{ height: '40px', marginRight: '12px' }}
    >
      Cancel
    </Button>
  );
}
