import { Box, Button } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { gqlClient } from 'api/ApolloClient';
import {
  GqlCancelImportMutationVariables,
  GqlConfirmImportMutationVariables,
  ImportFileStatus,
  PermissionCode,
  usePoImportFieldGroupsQuery,
  usePoImportFileQuery,
} from 'api/GQL_Types';
import { CancelImportMutation, ConfirmImportMutation } from 'api/queries/poUploadQueries';
import { auth } from 'app';
import { EditFields } from 'components/EditFields';
import ErrorMessage from 'components/ErrorMessage';
import FakeProgressBar from 'components/FakeProgressBar';
import Panel from 'components/Panel';
import SearchBar from 'components/SearchBar';
import UniversalDialog from 'components/UniversalDialog';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { theme } from 'styles';
import { CancelUploadDialog } from './CancelUploadDialog';
import { ConfirmUploadDialog } from './ConfirmUploadDialog';
import { FileSummary } from './FileSummary';
import { reviewUploadStore } from './reviewUploadStore';
import { TableCell } from './TableCell';
import { TableHeaderCell } from './TableHeaderCell';

interface Props extends RouteComponentProps {
  poFileId?: string;
}

export default function ReviewUploadPage(props: Props) {
  const { userContext } = auth.useAuthState();
  const [loadingView, setLoadingView] = React.useState<boolean>(true);
  const [showConfirmCancel, setShowConfirmCancel] = React.useState<boolean>(false);

  const actionConfirmImport = useAsyncAction(confirmImport);
  const actionCancelImport = useAsyncAction(cancelImport);

  const tableState = reviewUploadStore.use();
  const [searchField, setSearchField] = React.useState<string>('');

  const { data, loading } = usePoImportFileQuery({
    variables: { fileId: props.poFileId ?? '' },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (!loading && data && data.poImportFile === null) {
      navigate('/purchase-orders/uploads');
      return;
    }
    reviewUploadStore.setup(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { data: allOmsMappings } = usePoImportFieldGroupsQuery();
  const poImportFieldGroups = allOmsMappings?.poImportFieldGroups ?? [];

  const keysAlreadyMapped = tableState.status.headers
    .filter((h) => h.mapping)
    .map((h) => h.mapping?.key || '');

  const rowFilterQuery = searchField.toLowerCase().trim();
  let tableRows = tableState.tableRows;
  if (rowFilterQuery.length > 0) {
    tableRows = tableRows.filter((row) => row.rowFilterIndex.indexOf(rowFilterQuery) >= 0);
  }

  const allowedToMapFields = !!userContext?.permissionCodes.has(PermissionCode.PoImportMapFields);

  const showUploadButton =
    tableState.status.fileStatus === ImportFileStatus.Pending &&
    userContext?.permissionCodes.has(PermissionCode.PoImportConfirm);

  return (
    <Panel
      title="Review Upload"
      topRight={
        <Box display="flex" alignItems="center" width={'50%'}>
          <Box bgcolor="#F8F8F8" padding={1} flexGrow={1}>
            <SearchBar
              placeholder="Search by PO#, SKU #, etc."
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
          <Box>
            <EditFields
              displayables={tableState.displayables}
              fields={tableState.fields}
              defaultFieldSelection={tableState.defaultFieldSelection}
              onChange={reviewUploadStore.setSelectedFields}
              disabled={loadingView}
            />
          </Box>
        </Box>
      }
    >
      {loadingView ? (
        <Box margin={2}>
          <FakeProgressBar
            start={loadingView}
            finished={!loading}
            onFinished={() => {
              setLoadingView(false);
            }}
            loadingText="Loading File..."
            finishText="Finishing Up..."
          />
        </Box>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box paddingX={3} paddingBottom={1.5}>
            <FileSummary fileStatusData={tableState.status} />
          </Box>

          <div
            style={{
              flex: '1 1 200px',
              padding: theme.spacing(0, 3),
              minHeight: 0, // NOTE: Don't remove this. This is a silly hack that tells flexbox to not not let this div overflow the parent
            }}
          >
            <>
              <UWLTable
                rowId="id"
                columns={tableState.tableColumns}
                rows={tableRows}
                isLoading={loadingView}
                emptyMessage="No Purchase Orders"
                virtualize="single-line-cells"
                renderHeaders={(col) => {
                  const header = tableState.headersByColId.get(col.id);
                  if (!header) {
                    return <div>{col.label}</div>;
                  }
                  return (
                    <TableHeaderCell
                      header={header}
                      poImportFieldGroups={poImportFieldGroups}
                      keysAlreadyMapped={keysAlreadyMapped}
                      fileStatusData={tableState.status}
                      setFileStatusData={reviewUploadStore.setFileStatusData}
                      allowedToMapFields={allowedToMapFields}
                    />
                  );
                }}
                renderCells={(col, row) => {
                  const cellValue = row[col.id];
                  const header = tableState.headersByColId.get(col.id);
                  let errors: string[] | undefined;
                  if (header) {
                    errors = tableState.cellErrors.get(`${header.headerIndex}-${row.id}`);
                  }
                  return <TableCell cellValue={cellValue} errors={errors} />;
                }}
              />
            </>
          </div>
          <div style={{ padding: theme.spacing(0, 3, 1, 3) }}>
            <hr />
            <Box padding={1}>
              <Box display="flex" justifyContent="flex-end" flex="row">
                {userContext?.permissionCodes.has(PermissionCode.PoImportCancel) && (
                  <Button
                    color="primary"
                    style={{ marginRight: '16px' }}
                    onClick={() => {
                      setShowConfirmCancel(true);
                    }}
                  >
                    CANCEL
                  </Button>
                )}
                {showUploadButton ? (
                  <Button
                    onClick={() => {
                      actionConfirmImport.act({ fileId: props.poFileId || '' });
                    }}
                    variant="contained"
                    color="primary"
                    disabled={tableState.preventingUploadErrorMessage !== null}
                  >
                    CONTINUE UPLOAD
                  </Button>
                ) : null}
              </Box>
              {showUploadButton && tableState.preventingUploadErrorMessage && (
                <div style={{ textAlign: 'right' }}>
                  <ErrorMessage
                    error={
                      'Cannot continue upload until this warning is resolved: ' +
                      tableState.preventingUploadErrorMessage
                    }
                  />
                </div>
              )}
            </Box>
          </div>
        </div>
      )}

      <ConfirmUploadDialog
        open={actionConfirmImport.called}
        onClose={actionConfirmImport.reset}
        finished={actionConfirmImport.finished}
        error={actionConfirmImport.error}
      />

      <CancelUploadDialog
        open={actionCancelImport.called}
        onClose={actionCancelImport.reset}
        finished={actionCancelImport.finished}
        error={actionCancelImport.error}
      />

      <UniversalDialog
        open={showConfirmCancel}
        title="Are you sure you want to cancel?"
        setClose={() => {
          setShowConfirmCancel(false);
        }}
      >
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              actionCancelImport.act({ fileId: props.poFileId || '' });
              setShowConfirmCancel(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            style={{ height: '40px', marginLeft: theme.spacing(3) }}
            onClick={() => {
              setShowConfirmCancel(false);
            }}
          >
            No
          </Button>
        </Box>
      </UniversalDialog>
    </Panel>
  );
}

async function confirmImport(variables: GqlConfirmImportMutationVariables): Promise<void> {
  const res = await gqlClient.mutate({
    mutation: ConfirmImportMutation,
    variables,
  });
  if (!res.data.confirmImport.success) {
    throw new Error(res.data.confirmImport.message || 'Unexpected Error');
  }
}

async function cancelImport(variables: GqlCancelImportMutationVariables): Promise<void> {
  const res = await gqlClient.mutate({
    mutation: CancelImportMutation,
    variables,
  });
  if (!res.data.cancelImport.success) {
    throw new Error(res.data.cancelImport.message || 'Unexpected Error');
  }
}
