import { formatWeight } from 'types/UnitOfMeasure';

export interface NetworkContainerType {
  code: string;
  name: string;
  capacityVolumeM3: number;
  capacityWeightKg: number;
  ruleMinVolumeM3: number;
  ruleMaxVolumeM3: number;
  ruleMinWeightKg: number;
  ruleMaxWeightKg: number;
}

export interface CombinedContainerTypesSize {
  weightKg: {
    min: number;
    max: number;
    capacity: number;
  };

  volumeM3: {
    min: number;
    max: number;
    capacity: number;
  };
}

export const emptyCombinedContainerTypesSize = Object.freeze(calcCombinedContainerSize([]));

export function calcCombinedContainerSize(
  types: NetworkContainerType[]
): CombinedContainerTypesSize {
  const summary: CombinedContainerTypesSize = {
    weightKg: {
      min: 0,
      max: 0,
      capacity: 0,
    },
    volumeM3: {
      min: 0,
      max: 0,
      capacity: 0,
    },
  };

  for (const eqp of types) {
    summary.weightKg.min += eqp.ruleMinWeightKg;
    summary.weightKg.max += eqp.ruleMaxWeightKg;
    summary.weightKg.capacity += eqp.capacityWeightKg;

    summary.volumeM3.min += eqp.ruleMinVolumeM3;
    summary.volumeM3.max += eqp.ruleMaxVolumeM3;
    summary.volumeM3.capacity += eqp.capacityVolumeM3;
  }

  return summary;
}

export function containerUtilizationWarnings(
  w: number,
  v: number,
  types: NetworkContainerType[],
  nLinesOrBookings: number
): string[] {
  if (nLinesOrBookings === 0 && types.length === 0) {
    return [];
  }
  if (types.length === 0) {
    return ['No equipment selected.']; // OMS-257
  }

  const typesSize = calcCombinedContainerSize(types);

  // The wording comes from OMS-224
  const msgs: string[] = [];
  if (w < typesSize.weightKg.min) {
    msgs.push(
      `Booked weight is ${formatWeight(typesSize.weightKg.min - w, 'KG')} under min weight.`
    );
  }
  if (w > typesSize.weightKg.max) {
    msgs.push(
      `Booked weight is ${formatWeight(w - typesSize.weightKg.max, 'KG')} over max weight.`
    );
  }
  if (v < typesSize.volumeM3.min) {
    msgs.push(
      `Booked volume is ${formatWeight(typesSize.volumeM3.min - v, 'CBM')} under min volume.`
    );
  }
  if (v > typesSize.volumeM3.max) {
    msgs.push(
      `Booked volume is ${formatWeight(v - typesSize.volumeM3.max, 'CBM')} over max volume.`
    );
  }
  return msgs;
}
