import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode, useUpdateNetworkRulesMutation } from 'api/GQL_Types';
import { auth } from 'app';
import { Line } from 'components/StyledComponents';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { RulesBillOfLading } from './RulesBillOfLading';
import { RulesBooking } from './RulesBooking';
import { RulesConsolidation } from './RulesConsolidation';
import { RulesMilestone } from './RulesMilestone';
import { RulesMissingDocument } from './RulesMissingDocument';
import { RulesPO } from './RulesPO';
import { RulesShipment } from './RulesShipment';

interface Props extends RouteComponentProps {}
export default function NetworkRulesComponent(props: Props) {
  const { userContext } = auth.useAuthState();
  const canEdit = !!userContext?.permissionCodes.has(PermissionCode.NetworkUpdate);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Network Rules</Typography>
          <Typography variant="body1">List of rules in this network</Typography>
        </Box>
        {canEdit && <SaveRules />}
      </Box>
      <Line height={1} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <RulesPO />
          <RulesBooking />
          <RulesConsolidation />
          <RulesShipment />
          <RulesBillOfLading />
        </Grid>
        <Grid item xs={12} md={6}>
          <RulesMilestone />
          <RulesMissingDocument />
        </Grid>
      </Grid>
    </>
  );
}

const SaveRules: React.FC = () => {
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const rulesForm = useRecoilValue(NetworkAdminPageStates.rulesForm);
  const { enqueueSnackbar } = useSnackbar();
  const [updateRules, { loading }] = useUpdateNetworkRulesMutation({
    onCompleted(data) {
      if (data.updateNetworkRules?.success) {
        enqueueSnackbar('Rules Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.updateNetworkRules?.message || 'Failed to update network rules.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar(error + '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={() => {
        if (!network || !rulesForm) {
          return;
        }
        updateRules({
          variables: {
            input: {
              networkId: network.id,
              rules: rulesForm,
            },
          },
        });
      }}
    >
      Update Rules
    </Button>
  );
};
