import { SearchType } from 'api/GQL_Types';
import { newAtom } from 'lib/RecoilUtils';

interface SearchPageData {
  search: {
    type: SearchType;
    term: string;
    to: string | null;
  } | null;
  poCount: number;
  purchaseOrders: QuickSearchPoResult[];
  containerCount: number;
  containers: QuickSearchContainerResult[];
  bookingCount: number;
  bookings: QuickSearchBookingResult[];
  shipmentCount: number;
  shipments: QuickSearchShipmentResult[];
}

export const SearchPageStates = {
  data: newAtom<SearchPageData>({
    search: null,
    poCount: 0,
    purchaseOrders: [],
    containerCount: 0,
    containers: [],
    bookingCount: 0,
    bookings: [],
    shipmentCount: 0,
    shipments: [],
  }),
};

export interface QuickSearchPoResult {
  id: string;
  orderLineId: string;
  poNumber: string;
  poDate: Date | null | undefined;
  supplierName: string | null;
  itemNumber: string | null;
  cargoReadyDate: Date | null | undefined;
  firstShipDate: Date | null | undefined;
  lastShipDate: Date | null | undefined;
  shipToLocationName: string | null;
}

export interface QuickSearchContainerResult {
  containerId: string;
  orderLineId: string;
  containerNumber: string | null;
  railRamp: string | null;
  railRampEta: Date | null | undefined;
  railRampAta: Date | null | undefined;
  deliveryLocation: string | null;
  finalEta: Date | null | undefined;
  finalAta: Date | null | undefined;
}

export interface QuickSearchBookingResult {
  bookingId: string;
  orderLineId: string;
  bookingNumber: string;
  mblId: string | null;
  mblNumber: string | null;
  cargoReadyDate: Date | null | undefined;
  shipToLocationName: string | null;
  carrierName: string | null;
  polCode: string | null;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  podCode: string | null;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
}

export interface QuickSearchShipmentResult {
  bookingId: string;
  orderLineId: string;
  bookingNumber: string;
  mblId: string | null;
  mblNumber: string | null;
  cargoReadyDate: Date | null | undefined;
  shipToLocationName: string | null;
  carrierName: string | null;
  polCode: string | null;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  podCode: string | null;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
}
