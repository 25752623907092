import { navigate } from '@reach/router';
import { useProfileTypeDeleteMutation } from 'api/GQL_Types';
import { ProfileTypeTemplatesQuery } from 'api/queries/profileTypeQueries';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { ProfileTypePageStates } from '../states';
import { ProfileTypeDetailsDialog_Data } from './EditProfileTypeDetailsDialog';

export const removeConfirmationDialog_Open = newAtom(false);

export default function RemoveConfirmationDialog() {
  const [openDeleteDialog, setOpenDeleteDialog] = useRecoilState(removeConfirmationDialog_Open);

  const setDetailsDialog = useSetRecoilState(ProfileTypeDetailsDialog_Data);
  const profileType = useRecoilValue(ProfileTypePageStates.profileType);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState<string | null>(null);

  const [deleteProfileType, { loading }] = useProfileTypeDeleteMutation({
    refetchQueries: () => [
      {
        query: ProfileTypeTemplatesQuery,
      },
    ],
    onCompleted(data) {
      if (data.deleteProfileType.success) {
        setOpenDeleteDialog(false);
        setDetailsDialog(null);
        navigate('admin/profile-types');
        enqueueSnackbar('Profile Type Template Deleted!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        setError(data.deleteProfileType.message || 'Failed to delete profile type.');
      }
    },
    onError(error) {
      setError('Failed to delete profile type. ' + error);
    },
  });

  return (
    <DeleteConfirmationDialog
      open={openDeleteDialog}
      onClose={() => {
        setOpenDeleteDialog(false);
      }}
      title="Profile Type Template Removal"
      message="Are you sure you want to remove this profile type template?"
      deleteBtnText="Delete Profile Type"
      error={error}
      waiting={loading}
      onClickDelete={() => {
        deleteProfileType({
          variables: {
            input: {
              profileTypeId: profileType?.id || '',
            },
          },
        });
      }}
    />
  );
}
