import { Box } from '@material-ui/core';

import GlobalSearchBar from './SearchBar';
import QueryDropdown from './QueryDropdown';

export default function GlobalUpdateSearch() {
  return (
    <Box margin={3} display="flex" height={40}>
      <QueryDropdown />
      <GlobalSearchBar />
    </Box>
  );
}
