import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Button } from '@material-ui/core';
import { GqlLocationInput, LocationType } from 'api/GQL_Types';
import { newProfileLocation, updateLocation } from 'api/queries/locationQueries';
import { ProfileQuery } from 'api/queries/profileQueries';
import { LocationForm } from 'app/admin/components/LocationForm';
import {
  useLocationFormState,
  useSetupLocationForm,
} from 'app/admin/components/LocationForm/states';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { ProfilePageLocation, ProfilePageStates } from '../states';

// Sets the Dialog to open/close
export const ProfileLocationsDialog_Open = newAtom(false);

//data coming in from the location cards. If location is null, it will be treated as a new location
export const LocationDialog_Data = newAtom<ProfilePageLocation | null>(null);

export default function ProfileLocationsDialog() {
  const [openDialog, setOpenDialog] = useRecoilState(ProfileLocationsDialog_Open);
  const location = useRecoilValue(LocationDialog_Data);
  const profile = useRecoilValue(ProfilePageStates.profile);

  const setupLocationForm = useSetupLocationForm();

  React.useEffect(() => {
    if (!openDialog) return; // don't clear fields as the close dialog animation is running

    const otherProfileLocationTypes: LocationType[] = [];
    const locationMol = profile?.locationList;
    if (locationMol) {
      locationMol.forEach((loc) => {
        for (const lt of loc.locationTypes) {
          otherProfileLocationTypes.push(lt);
        }
      });
    }

    setupLocationForm(location, otherProfileLocationTypes);
  }, [openDialog, location, profile]);

  return (
    <UniversalDialog
      open={openDialog}
      title={location ? 'Edit Location' : 'New Location'}
      subTitle={
        location
          ? 'Edit the location associated with the Profile'
          : 'Create a new location for this profile'
      }
      large
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <Line height={2} color={theme.palette.primary.main} />
      <LocationForm />
      <Footer />
    </UniversalDialog>
  );
}

function Footer() {
  const [openDialog, setOpenDialog] = useRecoilState(ProfileLocationsDialog_Open);
  const profile = useRecoilValue(ProfilePageStates.profile);
  const profileId = profile?.id || '';
  const location = useRecoilValue(LocationDialog_Data);

  const locFormState = useLocationFormState();

  const { enqueueSnackbar } = useSnackbar();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ProfileQuery,
      variables: { profileId: profileId },
      fetchPolicy: 'network-only',
    },
  ];

  const saveLocationAction = useAsyncAction<GqlLocationInput, void>(
    async (location) => {
      // TODO
      if (location.id) {
        await updateLocation({ input: { location } }, { refetchQueries });
      } else {
        await newProfileLocation({ input: { profileId, location } }, { refetchQueries });
      }
    },
    {
      onData(data) {
        setOpenDialog(false);
        enqueueSnackbar('Location Saved.', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to save location: ' + error, { variant: 'error' });
      },
    }
  );

  React.useEffect(() => {
    saveLocationAction.reset();
  }, [profileId, location?.id || '', openDialog]);

  return (
    <UniversalDialogFooter error={saveLocationAction.error}>
      <Button
        variant="contained"
        color="default"
        size="large"
        disabled={saveLocationAction.waiting}
        onClick={() => {
          setOpenDialog(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={saveLocationAction.waiting || !locFormState.name.trim() || !locFormState.country}
        onClick={() => {
          saveLocationAction.act({
            id: location?.id,
            name: locFormState.name,
            nameAliases: locFormState.nameAliases,
            addressLine1: locFormState.addressLine1,
            addressLine2: locFormState.addressLine2,
            city: locFormState.city,
            state: locFormState.state,
            postalCode: locFormState.postalCode,
            country: locFormState.country,
            timeZone: locFormState.timeZone,
            code: locFormState.code,
            codeAliases: locFormState.codeAliases,
            relatedPortCode: locFormState.relatedPortCode?.code,
            companyPhone: locFormState.companyPhone,
            companyUrl: locFormState.companyUrl,
            locationTypes: locFormState.locationTypes,
          });
        }}
      >
        {location ? 'Update Location' : 'Create Location'}
      </Button>
    </UniversalDialogFooter>
  );
}
