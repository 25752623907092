import { Box, Button, Container, Typography } from '@material-ui/core';
import UniversalDialog from 'components/UniversalDialog';
import { theme } from 'styles';
import { Line } from './StyledComponents';

interface Props {
  open: boolean;
  title: string;
  message: string;
  onClose(): void;
}

export const MessageDialog: React.FC<Props> = ({ open, title, message, onClose }) => {
  return (
    <UniversalDialog open={open} setClose={onClose}>
      <Container>
        <Box width={500} marginBottom={2}>
          <Box display="flex" flexDirection="column" textAlign="center">
            <Typography variant="h1" style={{ marginBottom: theme.spacing(1) }}>
              {title}
            </Typography>
            <Typography variant="body1">{message}</Typography>
          </Box>
        </Box>
      </Container>
      <Line height={1} />
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="default"
          size="large"
          style={{ marginRight: '12px' }}
          onClick={onClose}
        >
          OK
        </Button>
      </Box>
    </UniversalDialog>
  );
};
