import { GqlPortFragment } from 'api/GQL_Types';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import React from 'react';
import { DatasetFilterType } from '..';

const defaultMaxWidth = 250;

export const PortFilter: DatasetFilterType<GqlPortFragment | null> = {
  deserializeValue(filterable, jsonString) {
    try {
      const val = JSON.parse(jsonString as any);
      return {
        code: val.code,
        countryCode: val.countryCode,
        countryName: val.countryName,
        latitude: val.latitude,
        longitude: val.longitude,
        timeZone: val.timeZone,
        name: val.name,
        stateCode: val.stateCode,
        stateName: val.stateName,
      };
    } catch (err) {
      return null;
    }
  },
  serializeValue(filterable, value) {
    if (value) {
      return JSON.stringify({
        code: value.code,
        countryCode: value.countryCode,
        countryName: value.countryName,
        latitude: value.latitude,
        longitude: value.longitude,
        timeZone: value.timeZone,
        name: value.name,
        stateCode: value.stateCode,
        stateName: value.stateName,
      });
    }
    return null;
  },

  defaultValue(filterable) {
    return null;
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput(props) {
    const [maxWidth, setMaxWidth] = React.useState<number>(defaultMaxWidth);
    const [desiredWidth, setDesiredWidth] = React.useState<number>(defaultMaxWidth);

    React.useEffect(() => {
      if (props.value) {
        setMaxWidth(Math.max(defaultMaxWidth, desiredWidth)); // OMS-288
      } else {
        setMaxWidth(defaultMaxWidth);
      }
    }, [props.value, desiredWidth]);

    return (
      <div style={{ maxWidth }}>
        <FormInputPortAny
          label={props.filterable.label}
          value={props.value ? props.value : null}
          onValue={(val) => {
            props.onValue(val);
          }}
          onDesiredWidth={setDesiredWidth}
        />
      </div>
    );
  },
};
