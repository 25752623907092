import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import MblDetails from './details';

interface MasterBillProps extends RouteComponentProps {
  masterBillId?: string;
}
function MasterBills(props: MasterBillProps) {
  return (
    <Router>
      <MblDetails path="/:mblId" />
    </Router>
  );
}

export default MasterBills;
