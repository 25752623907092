import { Box } from '@material-ui/core';
import { GqlConsolidation, useShippedConsolidationListQuery } from 'api/GQL_Types';
import { PanelBase, PanelHeader } from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import SearchBar from 'components/SearchBar';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'lib/RecoilUtils';
import { UWLTableColumn } from 'types/UWLTable';
import { formatDate } from 'types/Date';
import { mapShipmentStatus } from 'types/OMSEnums';
import { formatNumber } from 'types/Number';
import { shippedListSelectedTab } from '.';
import { BookingConsolidationToggler } from '../BookingConsolidationToggler';

const searchQueryAtom = newAtom('');
const consolidatedBookingListAtom = newAtom<TableRow[]>([]);

const filteredBookingListState = selector<TableRow[]>({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(searchQueryAtom);
    const bookings = get(consolidatedBookingListAtom);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return bookings;
    } else {
      return bookings.filter((row) => row.filterKey.includes(lowerField));
    }
  },
});

const columns: UWLTableColumn<TableRow>[] = [
  { id: 'referenceNumber', label: 'Consol #', type: 'link' },
  { id: 'createDate', label: 'Consol Date', type: 'date' },
  { id: 'status', label: 'Consol Status', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'deliveryLocationName', label: 'Final Destination', type: 'string' },
  { id: 'commercialInvoice', label: 'Commercial Invoice Value', type: 'currency' },
  { id: 'containers', label: 'Equipment (QTY)', type: 'string', whiteSpace: 'pre-wrap' },
];

interface TableRow {
  id: string;
  referenceNumber: { to: string; value: string };
  createDate: Date | null;
  status: string;
  pol: string;
  polEtd: Date | null | undefined;
  pod: string;
  containers: string; // Type and qty
  commercialInvoice: number;
  deliveryLocationName: string;

  filterKey: string; // The text rows can be filtered by with the quick search
}

interface Props {}
export default function ConsolidatedBookingBrowser(props: Props) {
  const setConsolidations = useSetRecoilState(consolidatedBookingListAtom);
  const [searchField, setSearchField] = useRecoilState(searchQueryAtom);
  const filteredBookings = useRecoilValue(filteredBookingListState);

  const { loading, error } = useShippedConsolidationListQuery({
    fetchPolicy: 'no-cache',
    onCompleted(consolidationsData) {
      setConsolidations(
        consolidationsData.shipments
          .map((consolidation): TableRow => {
            let containers = '';
            if (consolidation.containers) {
              const nContainersByType = new Map<string, number>();
              consolidation.containers.forEach((container) => {
                const n = nContainersByType.get(container.containerType) || 0;
                nContainersByType.set(container.containerType, n + 1);
              });
              const lines: string[] = [];
              nContainersByType.forEach((nContainers, type) => {
                lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
              });
              containers = lines.join('\n');
            }

            return {
              id: consolidation.id,
              referenceNumber: {
                to: '/consolidations/' + consolidation.id,
                value: consolidation.referenceNumber,
              },
              createDate: consolidation.createDate,
              status: mapShipmentStatus(consolidation.status),
              pol: consolidation.logistics.pol ? consolidation.logistics.pol.code : '',
              polEtd: consolidation.logistics.polEtd,
              pod: consolidation.logistics.pod ? consolidation.logistics.pod.code : '',
              containers: containers,
              commercialInvoice: (consolidation as GqlConsolidation).bookings.reduce(
                (totalValue, booking) => {
                  if (booking.containers.find((container) => !container.containerNumber)) {
                    // Container unassigned so use packline prices
                    return booking.packLines.reduce((packLineValue, packLine) => {
                      return packLineValue + (packLine.linePrice ?? 0);
                    }, totalValue);
                  } else {
                    return booking.containers.reduce((containerValue, container) => {
                      return container.vanPositions.reduce((vanValue, position) => {
                        return vanValue + (position.commercialInvoiceTotal ?? 0);
                      }, containerValue);
                    }, totalValue);
                  }
                },
                0
              ),
              deliveryLocationName: consolidation.logistics.deliveryLocation?.name ?? '',
              filterKey: '', // will get set in the next pass
            };
          })
          .map((row) => {
            return {
              ...row,
              filterKey: [
                // Include all the text they can search by
                row.referenceNumber.value,
                row.status,
                row.containers,
                row.deliveryLocationName,
                row.pod,
                row.pol,
                formatDate(row.createDate),
                formatDate(row.polEtd),
              ]
                .join('||||')
                .toLowerCase(),
            };
          })
      );
    },
  });

  const defaultColumnIds = [
    'referenceNumber',
    'createDate',
    'status',
    'pol',
    'polEtd',
    'pod',
    'containers',
    'commercialInvoice',
    'deliveryLocationName',
  ];

  return (
    <PanelBase>
      <RemainingHeightLayout
        top={
          <div>
            <PanelHeader
              title="Shipped Consolidated Bookings"
              titleDecorator={
                <TableExportWindowGroup
                  label="Consolidations"
                  rows={filteredBookings}
                  columns={columns}
                />
              }
              topRight={
                <Box width="25%" bgcolor="#F8F8F8" padding={1}>
                  <SearchBar
                    placeholder="Search by Consol #"
                    field={searchField}
                    updateField={setSearchField}
                  />
                </Box>
              }
            />
            <BookingConsolidationToggler state={shippedListSelectedTab} />
          </div>
        }
        bottomMinHeight={300}
        bottom={
          <PanelBodyTable>
            <UWLTable
              rowId="id"
              columns={columns}
              columnsDisplay={defaultColumnIds}
              isLoading={loading}
              error={error ? error + '' : null}
              rows={filteredBookings}
              emptyMessage="- No Consolidations Available -"
              virtualize="single-line-cells"
            />
          </PanelBodyTable>
        }
      />
    </PanelBase>
  );
}
