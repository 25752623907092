import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { theme } from 'styles';

interface Props extends RouteComponentProps {
  title: string;
  subTitle: string;
  searchBar?: {
    placeholder: string;
    value: string;
    onValue(value: string): void;
  };
}

export const ManagePageTabHeader: React.FC<Props> = ({ title, subTitle, searchBar }) => {
  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1">{subTitle}</Typography>
        </Box>
        {searchBar && (
          <Box
            width="25%"
            bgcolor={theme.palette.background.default}
            padding={1}
            alignSelf="center"
          >
            <SearchBar
              placeholder={searchBar.placeholder}
              field={searchBar.value}
              updateField={searchBar.onValue}
            />
          </Box>
        )}
      </Box>
      <Line height={1} />
    </div>
  );
};
