import { GqlPort } from 'api/GQL_Types';
import clsx from 'clsx';
import * as React from 'react';
import { makeCss } from '../styles';
import { portToString } from '../types/Port';

const fallbackIcon = 'linear-gradient(-45deg, #aaa, #ddd)';

export const portFlagSizes = {
  small: {
    width: 42,
    height: 22,
  },
  large: {
    width: 60,
    height: 40,
  },
};

const classes = makeCss({
  icon: {
    userSelect: 'none',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: fallbackIcon,
  },
  iconLarge: portFlagSizes.large,
  iconSmall: portFlagSizes.small,
});

interface Props {
  port: GqlPort;
  size: keyof typeof portFlagSizes;
  className?: string;
  noTitle?: boolean;
}

export const PortFlagIcon: React.FC<Props> = ({ port, size, className, noTitle }) => {
  const countryCode = port.countryCode || port.code[0] + port.code[1];

  return (
    <div
      title={noTitle ? undefined : portToString(port)}
      className={clsx(
        classes.icon,
        {
          [classes.iconSmall]: size === 'small',
          [classes.iconLarge]: size === 'large',
        },
        className
      )}
      style={{
        backgroundImage: `url(/flags/${countryCode.toLowerCase()}.svg),${fallbackIcon}`,
      }}
    />
  );
};
