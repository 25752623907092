import { GqlPortFragment } from 'api/GQL_Types';

export function abbrvCountryName(country: string) {
  if (typeof country !== 'string') {
    return '';
  }
  return country
    .trim()
    .replace(/^united states$/i, 'USA')
    .replace(/^korea, republic of$/i, 'Korea')
    .replace(/([ ,])International Apt([ ,])/i, '$1Intl Apt$2');
}

export function portToString(port: GqlPortFragment): string {
  if (port.countryCode === 'US' && port.stateCode) {
    return `${port.name}, ${port.stateCode}`;
  }
  let str = port.name;
  if (port.countryName) {
    str += ', ' + abbrvCountryName(port.countryName);
  }
  return str;
}

export function portToStringMaybe(port: GqlPortFragment | null | undefined): string {
  return port ? portToString(port) : '';
}
