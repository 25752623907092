import { Grid } from '@material-ui/core';
import { PaymentType, ReleaseType } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { HighlightMatches } from 'components/HighlightMatches';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeCss, theme } from 'styles';
import { mapPaymentType, mapReleaseType } from 'types/OMSEnums';
import { BookingPerHbl, ConsolidationPageStates } from '../../states';
import { confirmConsolidationValidationState } from './states';

const classes = makeCss({
  row: {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid transparent',
    '&:hover': {
      background: theme.palette.grayscale.light,
    },
    '&:last-child': {
      borderBottom: '1px solid #ccc',
    },
  },
});

const ALL_ReleaseTypes = Object.values(ReleaseType);
const ALL_PaymentTypes = Object.values(PaymentType);

export const HBLsInput: React.FC<{}> = () => {
  const [hblPerBooking, setHblPerBooking] = useRecoilState(
    ConsolidationPageStates.editLogistics.hblPerBooking
  );
  const networkRules = useRecoilValue(AppWideStates.network.rules);
  const validation = useRecoilValue(confirmConsolidationValidationState);

  function updateItem(bookingId: string, update: (orig: BookingPerHbl) => BookingPerHbl) {
    setHblPerBooking(
      hblPerBooking.map((item) => {
        return bookingId === item.bookingId ? update(item) : item;
      })
    );
  }

  const dfltPaymentType = networkRules.bolHblPaymentTypeEnabled
    ? networkRules.bolHblPaymentTypeDefault
    : null;
  const dfltReleaseType = networkRules.bolHblReleaseTypeEnabled
    ? networkRules.bolHblReleaseTypeDefault
    : null;

  return (
    <div>
      {hblPerBooking.map((row) => {
        const hblValid = validation.hbls[row.bookingId] || null;

        return (
          <div key={row.bookingId} className={classes.row}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '40px',
                      paddingLeft: theme.spacing(1),
                    }}
                  >
                    <span
                      style={{
                        color: '#383838',
                        fontWeight: 800,
                        marginRight: theme.spacing(1),
                      }}
                    >
                      Booking:
                    </span>
                    {row.bookingReferenceNumber}
                  </div>
                </FormItem>
              </Grid>
              <Grid item xs={4}>
                <FormItem>
                  <FormInputText
                    label="HBL"
                    value={row.hblReferenceNumber}
                    onValue={(hblReferenceNumber) => {
                      updateItem(row.bookingId, (item) => ({
                        ...item,
                        hblReferenceNumber,
                        hblPaymentType: hblReferenceNumber
                          ? item.hblPaymentType || dfltPaymentType
                          : null,
                        hblReleaseType: hblReferenceNumber
                          ? item.hblReleaseType || dfltReleaseType
                          : null,
                      }));
                    }}
                  />
                </FormItem>
              </Grid>
              <Grid item xs={4}>
                <FormRow noMargin>
                  <FormItem>
                    <AutocompleteInputCell
                      label="HBL Payment Type"
                      value={row.hblPaymentType}
                      onValue={(hblPaymentType) => {
                        updateItem(row.bookingId, (item) => ({
                          ...item,
                          hblPaymentType,
                        }));
                      }}
                      options={ALL_PaymentTypes}
                      displayResolver={mapPaymentType}
                      disabled={!Boolean(row.hblReferenceNumber)}
                      required={hblValid?.paymentTypeError}
                      warning={hblValid?.paymentTypeWarning}
                    />
                  </FormItem>
                  <FormItem>
                    <AutocompleteInputCell
                      label="HBL Release Type"
                      value={row.hblReleaseType}
                      onValue={(hblReleaseType) => {
                        updateItem(row.bookingId, (item) => ({
                          ...item,
                          hblReleaseType,
                        }));
                      }}
                      options={ALL_ReleaseTypes}
                      displayResolver={mapReleaseType}
                      disabled={!Boolean(row.hblReferenceNumber)}
                      required={hblValid?.releaseTypeError}
                      warning={hblValid?.releaseTypeWarning}
                    />
                  </FormItem>
                </FormRow>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

function AutocompleteInputCell<T>({
  label,
  value,
  onValue,
  options,
  displayResolver,
  disabled,
  required,
  warning,
}: {
  label: string;
  value: T | null;
  onValue(v: T | null): void;
  options: T[];
  displayResolver: (value: T) => string;
  disabled: boolean;
  required: boolean;
  warning: boolean;
}) {
  return (
    <div>
      <FormInputAutocomplete<T>
        label={label}
        value={value}
        onValue={onValue}
        options={options}
        displayResolver={displayResolver}
        disabled={disabled}
        localFilter={(query, value) => {
          return displayResolver(value).trim().toLowerCase().includes(query.trim().toLowerCase());
        }}
        renderOption={(value, query) => {
          const str = displayResolver(value);
          if (str.toLowerCase().trim() === query) {
            return str;
          }
          return <HighlightMatches str={str} searchStr={query} />;
        }}
        required={required}
        warning={warning}
      />
    </div>
  );
}
