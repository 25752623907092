import { Box, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { AppWideStates } from 'app/states';
import { ExpanderSection } from 'components/ExpanderSection';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { AvailableProfileTemplateType } from '../../states';

interface Props {
  selected: boolean;
  setSelected(selected: boolean): void;
  template: AvailableProfileTemplateType;
}

export const SelectableTemplateCard: React.FC<Props> = ({ selected, setSelected, template }) => {
  const permissions = useRecoilValue(AppWideStates.permissions);

  const permissionGroupsRendered: React.ReactNode[] = [];
  for (const groupName of permissions.groupOrder) {
    const perms = (permissions.byGroup.get(groupName) || []).filter((perm) =>
      template.permissionCodes.includes(perm.code)
    );
    if (perms.length === 0) {
      continue;
    }
    permissionGroupsRendered.push(
      <ExpanderSection key={groupName} title={groupName} summary={`(${perms.length})`}>
        {perms.map((permission) => {
          return (
            <div key={permission.code} title={permission.description}>
              <Typography variant="body1">{permission.name}</Typography>
            </div>
          );
        })}
      </ExpanderSection>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={2}
      marginBottom={2}
      bgcolor="#F8F8F8"
      width="100%"
      height="100%"
      border={
        '1px solid ' + (selected ? theme.palette.primary.main : 'transparent') // This ensures the border width is always accounted for so it doesn't change the layout when you toggle
      }
      borderRadius={theme.shape.borderRadius}
    >
      <Box display="flex" width="100%" justifyContent="space-between" paddingBottom={1}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" style={{ color: selected ? undefined : '#777' }}>
            {template.name}
          </Typography>
          <Typography variant="subtitle2">
            List of permissions and roles that are connected to this system template
          </Typography>
        </Box>
        <div style={{ marginTop: '-10px' }}>
          <Switch color="primary" checked={selected} onChange={(e) => setSelected(!selected)} />
        </div>
      </Box>

      <ExpanderSection title="Permissions" summary={`(${template.permissionCodes.length})`}>
        {permissionGroupsRendered.length === 0 ? '- none -' : permissionGroupsRendered}
      </ExpanderSection>

      <ExpanderSection title="Roles" summary={`(${template.defaultRoles.length})`}>
        {template.defaultRoles.map((role, roleIndex: number) => {
          return (
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={1}
              key={template.id + roleIndex}
            >
              <Typography variant="h4">{role.name}</Typography>
              <Typography variant="body1">{role.description}</Typography>
            </Box>
          );
        })}
      </ExpanderSection>
    </Box>
  );
};
