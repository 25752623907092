import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  GqlUpdateSystemDocumentTypeInput,
  RuleFieldType,
  useSystemDocumentTypeLazyQuery,
} from 'api/GQL_Types';
import {
  deactivateSystemDocumentType,
  newSystemDocumentType,
  SystemDocumentTypesQuery,
  updateSystemDocumentType,
} from 'api/queries/documentTypeQueries';
import { AppWideStates } from 'app/states';
import { DeleteButton } from 'components/DeleteButton';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import ErrorMessage from 'components/ErrorMessage';
import { FormInputNumber } from 'components/form/FormInputNumber';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { PanelBase, PanelBody } from 'components/Panel';
import { SwitchWithLabel } from 'components/SwitchWithLabel';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';

const ALL_dateFields: RuleFieldType[] = Object.values(RuleFieldType);

interface NetworkSetting {
  id: string;
  name: string;
  enabled: boolean;
}

interface Props extends RouteComponentProps {
  code?: string;
}

export const ManageDocumentTypePage: React.FC<Props> = (props) => {
  const [loadedCode, setLoadedCode] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string>('');
  const [enabledByDefault, setEnabledByDefault] = React.useState<boolean>(false);
  const [ruleMissingEnabled, setRuleMissingEnabled] = React.useState<boolean>(false);
  const [ruleMissingDays, setRuleMissingDays] = React.useState<number>(0);
  const [ruleMissingField, setRuleMissingField] = React.useState<RuleFieldType>(
    RuleFieldType.PoDate
  );
  const [networks, setNetworks] = React.useState<NetworkSetting[]>([]);
  const [showDeactivateConfirm, setShowDeactivateConfirm] = React.useState<boolean>(false);

  const ruleFieldTypes = useRecoilValue(AppWideStates.ruleFieldTypes);

  const [loadPage, { data, loading: isLoading, error }] = useSystemDocumentTypeLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      code: props.code || '',
    },
  });

  React.useEffect(() => {
    const dtData = data?.systemDocumentTypes.find((dt) => dt.code === props.code);
    if (!data || !dtData) {
      setLoadedCode(null);
      setName('');
      setRuleMissingEnabled(false);
      setRuleMissingDays(0);
      setRuleMissingField(RuleFieldType.PoDate);
      setNetworks([]);
      return;
    }
    setLoadedCode(dtData.code);
    setName(dtData.name);
    setEnabledByDefault(dtData.activeOnNewNetworks);
    setRuleMissingEnabled(dtData.ruleMissingEnabled);
    setRuleMissingDays(dtData.ruleMissingDays);
    setRuleMissingField(dtData.ruleMissingField);
    setNetworks(
      data.allNetworks.map((n) => {
        let enabled: boolean = false;
        for (const dt of n.documentTypes) {
          if (dt.code === dtData.code && dt.active) {
            enabled = true;
            break;
          }
        }
        return {
          id: n.id,
          name: n.name,
          enabled,
        };
      })
    );
  }, [props.code, data]);

  React.useEffect(() => {
    loadPage();
  }, [props.code]);

  const isDeactivated = !!data?.systemDocumentTypes.find((dt) => dt.code === props.code)
    ?.deleteDate;
  let loadingError: string | null = error ? 'Failed to get document type: ' + error : null;

  if (!isLoading && !loadingError) {
    if (!data?.systemDocumentTypes.find((dt) => dt.code === props.code)) {
      loadingError = 'Document type not found: ' + props.code;
    }
  }

  function goToListPage() {
    navigate(`/sys-admin/document-types`);
  }

  const { enqueueSnackbar } = useSnackbar();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: SystemDocumentTypesQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const deactivateAction = useAsyncAction<string, null>(
    async (code) => {
      await deactivateSystemDocumentType({ input: { code } }, { refetchQueries });
      return null;
    },
    {
      onData(data) {
        enqueueSnackbar('Document Type Deactivated!', { variant: 'success' });
        setShowDeactivateConfirm(false);
        loadPage();
      },
      onError(error) {
        enqueueSnackbar('Failed to deactivate document type: ' + error, { variant: 'error' });
      },
    }
  );

  const reactivateAction = useAsyncAction<{ code: string; name: string }, null>(
    async (input) => {
      await newSystemDocumentType({ input }, { refetchQueries });
      return null;
    },
    {
      onData(data) {
        enqueueSnackbar('Reactivated', { variant: 'success' });
        loadPage();
      },
      onError(error) {
        enqueueSnackbar('Failed to reactivate document type: ' + error, { variant: 'error' });
      },
    }
  );

  const saveAction = useAsyncAction<GqlUpdateSystemDocumentTypeInput, null>(
    async (input) => {
      await updateSystemDocumentType({ input }, { refetchQueries });
      return null;
    },
    {
      onData(data) {
        enqueueSnackbar('Saved!', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to save: ' + error, { variant: 'error' });
      },
    }
  );

  return (
    <div
      style={{
        padding: theme.spacing(2, 3, 0, 3),
        height: '100%',
      }}
    >
      <div>
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Box display="flex">
              <Typography variant="h2">Document Type: {props.code}</Typography>
              {isDeactivated && (
                <Typography variant="h2" style={{ marginLeft: theme.spacing(2) }} color="error">
                  Deactivated
                </Typography>
              )}
            </Box>
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                goToListPage();
              }}
            >
              <i className="material-icons">{'keyboard_backspace'}</i> Back to Document Types
            </Button>
          </Box>
          {isDeactivated ? (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={isLoading || !!loadingError || !loadedCode}
                onClick={() => {
                  if (!loadedCode) return;
                  reactivateAction.act({ code: loadedCode, name });
                }}
              >
                Reactivate
              </Button>
            </div>
          ) : (
            <div>
              <DeleteButton
                size="large"
                disabled={isLoading || !!loadingError}
                onClick={() => {
                  setShowDeactivateConfirm(true);
                }}
              >
                Deactivate
              </DeleteButton>{' '}
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading || !!loadingError || !loadedCode || name.trim().length === 0}
                onClick={() => {
                  if (!loadedCode) return;
                  saveAction.act({
                    code: loadedCode,
                    name: name,
                    activeOnNewNetworks: enabledByDefault,
                    ruleMissingEnabled,
                    ruleMissingDays,
                    ruleMissingField,
                    networks: networks.map((n) => {
                      return {
                        id: n.id,
                        active: n.enabled,
                      };
                    }),
                  });
                }}
              >
                Save
              </Button>
            </div>
          )}
        </Box>
      </div>

      <PanelBase>
        <PanelBody>
          <div style={{ padding: theme.spacing(2), minHeight: 400 }}>
            {isLoading ? (
              <div>Loading...</div>
            ) : loadingError ? (
              <div>
                <ErrorMessage error={loadingError} />
                <br />
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToListPage();
                  }}
                >
                  Back to Document Types
                </Button>
              </div>
            ) : (
              <Grid container justifyContent="center">
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h3"
                    color="primary"
                    style={isDeactivated ? { color: theme.palette.text.disabled } : undefined}
                  >
                    Details
                  </Typography>
                  <FormRow>
                    <FormItem>
                      <div
                        style={isDeactivated ? { color: theme.palette.text.disabled } : undefined}
                      >
                        Code: <b>{loadedCode}</b>
                      </div>
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem noMarginY>
                      <FormInputText
                        label="Description"
                        required
                        value={name}
                        onValue={setName}
                        disabled={isDeactivated}
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem noMarginY>
                      <SwitchWithLabel
                        label="Active on networks by default"
                        checked={enabledByDefault}
                        onChange={setEnabledByDefault}
                        disabled={isDeactivated}
                      />
                    </FormItem>
                  </FormRow>
                  <br />
                  <br />
                  <Typography
                    variant="h3"
                    color="primary"
                    style={isDeactivated ? { color: theme.palette.text.disabled } : undefined}
                  >
                    Rule Defaults
                  </Typography>

                  <FormRow>
                    <FormItem>
                      <Typography
                        variant="h4"
                        style={isDeactivated ? { color: theme.palette.text.disabled } : undefined}
                      >
                        Missing Document
                      </Typography>
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem maxWidth={120} noMarginY>
                      <FormInputNumber
                        label="Days"
                        value={ruleMissingDays}
                        onValue={(v) => {
                          setRuleMissingDays(Math.abs(v || 0));
                        }}
                        endAdornment="days"
                        selectAllOnFocus
                        maximumFractionDigits={0}
                        disabled={isDeactivated}
                      />
                    </FormItem>
                    <FormItem noMarginY>
                      <FormInputSelect
                        label="Field"
                        options={ALL_dateFields.map((o) => {
                          return {
                            id: o + '',
                            label: ruleFieldTypes.byCode.get(o)?.name || o + '',
                          };
                        })}
                        value={ruleMissingField}
                        onValue={(v) => {
                          if (ALL_dateFields.includes(v as any)) {
                            setRuleMissingField(v as RuleFieldType);
                          }
                        }}
                        disabled={isDeactivated}
                      />
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem noMarginY>
                      <SwitchWithLabel
                        label="Enabled by default"
                        checked={ruleMissingEnabled}
                        onChange={setRuleMissingEnabled}
                        disabled={isDeactivated}
                      />
                    </FormItem>
                  </FormRow>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="h3"
                    color="primary"
                    style={isDeactivated ? { color: theme.palette.text.disabled } : undefined}
                  >
                    Networks
                  </Typography>

                  <div style={{ margin: theme.spacing(1.5, 0, 0, 1.5) }}>
                    {networks.map((network) => {
                      return (
                        <SwitchWithLabel
                          key={network.id}
                          label={network.name}
                          checked={network.enabled}
                          onChange={(enabled) => {
                            setNetworks((nwrks) =>
                              nwrks.map((n) => {
                                if (n.id === network.id) {
                                  return { ...n, enabled };
                                }
                                return n;
                              })
                            );
                          }}
                          disabled={isDeactivated}
                        />
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </PanelBody>
      </PanelBase>

      {showDeactivateConfirm && loadedCode && (
        <DeleteConfirmationDialog
          open
          onClose={() => {
            setShowDeactivateConfirm(false);
          }}
          title={`Deactivate Document Type: ${loadedCode}`}
          message="Are you sure you want to deactivate this document type? It will be deactivated on ALL networks."
          deleteBtnText="Deactivate"
          onClickDelete={() => {
            if (!loadedCode) return;
            deactivateAction.act(loadedCode);
          }}
          error={deactivateAction.error}
          waiting={deactivateAction.waiting}
        />
      )}
    </div>
  );
};
