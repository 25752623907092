import { Button, Link } from '@material-ui/core';
import { Link as RouterLink } from '@reach/router';
import { downloadDocuments } from 'api/download';
import { FileExtensionIcon } from 'components/FileExtensionIcon';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { formatNumberOfBytes } from 'types/Byte';
import { UWLTableColumn } from 'types/UWLTable';

export interface Document {
  id: string;
  bookingId?: string;
  bookingNumber?: string;
  hblId?: string;
  hblNumber?: string;
  documentType: string;
  fileName: string;
  importFileName: string;
  fileType: string;
  fileSize: number;
  uploadDate: Date | null;
  description: string;
}

const columns: UWLTableColumn<Document>[] = [
  { id: 'bookingNumber', label: 'Booking #', type: 'string' },
  { id: 'hblNumber', label: 'HBL #', type: 'string' },
  { id: 'documentType', label: 'Document Type', type: 'string' },
  { id: 'importFileName', label: 'File Name', type: 'string' },
  { id: 'fileType', label: 'File Type', type: 'string' },
  { id: 'fileSize', label: 'File Size', type: 'number' },
  { id: 'uploadDate', label: 'Upload Date', type: 'date' },
  { id: 'description', label: 'Description', type: 'string' },
];

interface Props {
  documents: Document[];
  showBookingNumber?: boolean;
  showHblNumber?: boolean;
  isLoading?: boolean;
  error?: string | null;
  onUploadClicked?: () => void;
}

export const DocumentsPanel: React.FC<Props> = ({
  documents,
  showBookingNumber,
  showHblNumber,
  isLoading,
  error,
  onUploadClicked,
}) => {
  const columnsDisplay: (keyof Document)[] = [];
  if (showBookingNumber) columnsDisplay.push('bookingNumber');
  if (showHblNumber) columnsDisplay.push('hblNumber');
  columnsDisplay.push('documentType');
  columnsDisplay.push('importFileName');
  columnsDisplay.push('fileType');
  columnsDisplay.push('fileSize');
  columnsDisplay.push('uploadDate');
  columnsDisplay.push('description');

  return (
    <Panel
      title="Documents"
      topRight={
        onUploadClicked && (
          <Button variant="contained" color="secondary" onClick={onUploadClicked}>
            Upload New
          </Button>
        )
      }
    >
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          columnsDisplay={columnsDisplay}
          rows={documents}
          isLoading={isLoading}
          error={error}
          emptyMessage="No Documents to Show"
          renderCell={{
            bookingNumber(row) {
              return (
                <>
                  {row.bookingNumber ? (
                    <RouterLink to={'/bookings/' + row.bookingId}>{row.bookingNumber}</RouterLink>
                  ) : (
                    <span />
                  )}
                </>
              );
            },
            hblNumber(row) {
              return (
                <>
                  {row.hblNumber ? (
                    <RouterLink to={'/hbl/' + row.hblId}>{row.hblNumber}</RouterLink>
                  ) : (
                    <span />
                  )}
                </>
              );
            },
            importFileName(row) {
              return (
                <Button
                  component={Link}
                  onClick={() => {
                    downloadDocuments([row.id]);
                  }}
                  style={{ padding: 0, color: '#3a81b9' }}
                >
                  {row.importFileName}
                </Button>
              );
            },
            fileType(row) {
              return <FileExtensionIcon fileExtension={row.fileType} displayExtension />;
            },
            fileSize(row) {
              return formatNumberOfBytes(row.fileSize);
            },
          }}
        />
      </PanelBodyTable>
    </Panel>
  );
};
