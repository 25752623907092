import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, List, Typography } from '@material-ui/core';
import { DashboardFeatureCode, useExceptionsQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import CompositePanel from 'components/CompositePanel';
import ErrorMessage from 'components/ErrorMessage';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { DashboardPageStates } from '../states';
import { initDashExceptions } from './exceptions';
import { EmptyNotReturnedExceptionCmpt } from './exceptions/EmptyNotReturnedException';
import { HotShipmentExceptionCmpt } from './exceptions/HotShipmentException';
import { HotPOExceptionCmpt } from './exceptions/HotPOException';
import { HotBookingExceptionCmpt } from './exceptions/HotBookingException';
import { LateBookingExceptionCmpt } from './exceptions/LateBookingException';
import { MissingDocumentsExceptionCmpt } from './exceptions/MissingDocumentsException';
import { PendingOutgateExceptionCmpt } from './exceptions/PendingOutgateException';
import { PodEtaExceptionCmpt } from './exceptions/PodEtaException';
import { PolEtdExceptionCmpt } from './exceptions/PolEtdException';
import { useDivClientWidthHeight } from 'lib/useDivClientWidthHeight';
import { columnWidth } from '../components/AlertIconButton';
import { LateShipmentExceptionCmpt } from './exceptions/LateShipmentException';

export default function DashboardExceptions() {
  const setExceptions = useSetRecoilState(DashboardPageStates.exceptions);

  const { error } = useExceptionsQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setExceptions(initDashExceptions(data));
    },
  });

  return (
    <CompositePanel>
      <Box margin={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h2"
          color="primary"
          style={{ minWidth: 'fit-content', letterSpacing: '0.5px' }}
        >
          <Box component="span" marginRight={2}>
            <FontAwesomeIcon icon={faExclamationTriangle} color={theme.palette.indigoBlue.main} />
          </Box>
          Alerts
          <ErrorMessage error={error ? error + '' : null} />
        </Typography>
      </Box>

      <Box display="flex" paddingLeft={1} paddingRight={1} paddingBottom={2}>
        <ExceptionItemList />
      </Box>
    </CompositePanel>
  );
}

const ExceptionItemList: React.FC = () => {
  const userContext = useRecoilValue(userContextAtom);
  const { ref: rootRef, width: divWidth } = useDivClientWidthHeight();
  const setColumnWidth = useSetRecoilState(columnWidth);

  React.useEffect(() => {
    if (divWidth < 390) {
      setColumnWidth('100%');
    } else if (divWidth < 620) {
      setColumnWidth('50%');
    } else {
      setColumnWidth('33%');
    }
  }, [divWidth]);

  return (
    <div ref={rootRef}>
      <List style={{ padding: 0 }}>
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotPos) && (
          <HotPOExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotBookings) && (
          <HotBookingExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionHotShipments) && (
          <HotShipmentExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionLateBooking) && (
          <LateBookingExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionPolEtd) && (
          <PolEtdExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionLateShipment) && (
          <LateShipmentExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionPodEta) && (
          <PodEtaExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionPendingOutgate) && (
          <PendingOutgateExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionMissingDocuments) && (
          <MissingDocumentsExceptionCmpt />
        )}
        {userContext?.dashboardFeatureCodes.has(DashboardFeatureCode.ExceptionEmptyNotReturned) && (
          <EmptyNotReturnedExceptionCmpt />
        )}
      </List>
    </div>
  );
};
