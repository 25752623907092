import { Box, Button, Grid, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { navigate } from '@reach/router';
import {
  GqlGlobalUpdateContainerInput,
  GqlGlobalUpdateCustomsInput,
  GqlGlobalUpdateLogisticsInput,
  useGlobalUpdateMMutation,
} from 'api/GQL_Types';
import { StatusDialog } from 'components/StatusDialog';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DateAtomType } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { formatDate } from 'types/Date';
import { GUStates } from '../states';
import { useSnackbar } from 'notistack';

export default function GlobalUpdateStep2Header() {
  const confirmedBookingDate = useRecoilValue(GUStates.form.confirmedDate);
  const bookingConfirmationNumber = useRecoilValue(GUStates.form.confirmationNumber);
  const polEtd = useRecoilValue(GUStates.form.polEtd);
  const polAtd = useRecoilValue(GUStates.form.polAtd);
  const transhipEta = useRecoilValue(GUStates.form.transhipEta);
  const transhipAta = useRecoilValue(GUStates.form.transhipAta);
  const transhipEtd = useRecoilValue(GUStates.form.transhipEtd);
  const transhipAtd = useRecoilValue(GUStates.form.transhipAtd);
  const podEta = useRecoilValue(GUStates.form.podEta);
  const podAta = useRecoilValue(GUStates.form.podAta);
  const rampEta = useRecoilValue(GUStates.form.rampEta);
  const rampAta = useRecoilValue(GUStates.form.rampAta);
  const rampOutGateDate = useRecoilValue(GUStates.form.rampOutGateDate);
  const portOutGateDate = useRecoilValue(GUStates.form.portOutGateDate);
  const deliveryEta = useRecoilValue(GUStates.form.deliveryEta);
  const deliveryAta = useRecoilValue(GUStates.form.deliveryAta);
  const emptyReturnDate = useRecoilValue(GUStates.form.emptyReturnDate);
  const emptyNotify = useRecoilValue(GUStates.form.emptyNotify);
  const emptyPickup = useRecoilValue(GUStates.form.emptyPickup);
  const demurrageLfd = useRecoilValue(GUStates.form.demurrageLfd);
  const detentionLfd = useRecoilValue(GUStates.form.detentionLfd);
  const consolidatorLfd = useRecoilValue(GUStates.form.consolidatorLfd);
  const customsBrokerRef = useRecoilValue(GUStates.form.customsBrokerRef);
  const isfResponseNo = useRecoilValue(GUStates.form.isfResponseNo);
  const isfFilingDate = useRecoilValue(GUStates.form.isfFilingDate);
  const isfCutoffDate = useRecoilValue(GUStates.form.isfCutoffDate);
  const entryNumber = useRecoilValue(GUStates.form.entryNumber);
  const entryDate = useRecoilValue(GUStates.form.entryDate);
  const entryValue = useRecoilValue(GUStates.form.entryValue);
  const mpf = useRecoilValue(GUStates.form.mpf);
  const hmf = useRecoilValue(GUStates.form.hmf);
  const duty = useRecoilValue(GUStates.form.duty);
  const customsExamType = useRecoilValue(GUStates.form.customsExamType);
  const customsExamNotifyDate = useRecoilValue(GUStates.form.customsExamNotifyDate);
  const customsExamFee = useRecoilValue(GUStates.form.customsExamFee);
  const customsReleaseDate = useRecoilValue(GUStates.form.customsReleaseDate);
  const fdaExamType = useRecoilValue(GUStates.form.fdaExamType);
  const fdaExamNotifyDate = useRecoilValue(GUStates.form.fdaExamNotifyDate);
  const fdaExamFee = useRecoilValue(GUStates.form.fdaExamFee);
  const fdaReleaseDate = useRecoilValue(GUStates.form.fdaReleaseDate);
  const entryFeeTotal = useRecoilValue(GUStates.form.entryFeeTotal);

  const initConfirmedBookingDate = useRecoilValue(GUStates.initialState.initConfirmedDate);
  const initBookingConfirmationNumber = useRecoilValue(
    GUStates.initialState.initConfirmationNumber
  );
  const initPolEtd = useRecoilValue(GUStates.initialState.initPolEtd);
  const initPolAtd = useRecoilValue(GUStates.initialState.initPolAtd);
  const initTranshipEta = useRecoilValue(GUStates.initialState.initTranshipEta);
  const initTranshipAta = useRecoilValue(GUStates.initialState.initTranshipAta);
  const initTranshipEtd = useRecoilValue(GUStates.initialState.initTranshipEtd);
  const initTranshipAtd = useRecoilValue(GUStates.initialState.initTranshipAtd);
  const initPodEta = useRecoilValue(GUStates.initialState.initPodEta);
  const initPodAta = useRecoilValue(GUStates.initialState.initPodAta);
  const initRampEta = useRecoilValue(GUStates.initialState.initRampEta);
  const initRampAta = useRecoilValue(GUStates.initialState.initRampAta);
  const initRampOutGateDate = useRecoilValue(GUStates.initialState.initRampOutGateDate);
  const initPortOutGateDate = useRecoilValue(GUStates.initialState.initPortOutGateDate);
  const initDeliveryEta = useRecoilValue(GUStates.initialState.initDeliveryEta);
  const initDeliveryAta = useRecoilValue(GUStates.initialState.initDeliveryAta);
  const initEmptyReturnDate = useRecoilValue(GUStates.initialState.initEmptyReturnDate);
  const initEmptyNotify = useRecoilValue(GUStates.initialState.initEmptyNotify);
  const initEmptyPickup = useRecoilValue(GUStates.initialState.initEmptyPickup);
  const initDemurrageLfd = useRecoilValue(GUStates.initialState.initDemurrageLfd);
  const initDetentionLfd = useRecoilValue(GUStates.initialState.initDetentionLfd);
  const initConsolidatorLfd = useRecoilValue(GUStates.initialState.initConsolidatorLfd);
  const initCustomsBrokerRef = useRecoilValue(GUStates.initialState.initCustomsBrokerRef);
  const initIsfResponseNo = useRecoilValue(GUStates.initialState.initIsfResponseNo);
  const initIsfFilingDate = useRecoilValue(GUStates.initialState.initIsfFilingDate);
  const initIsfCutoffDate = useRecoilValue(GUStates.initialState.initIsfCutoffDate);
  const initEntryNumber = useRecoilValue(GUStates.initialState.initEntryNumber);
  const initEntryDate = useRecoilValue(GUStates.initialState.initEntryDate);
  const initEntryValue = useRecoilValue(GUStates.initialState.initEntryValue);
  const initMpf = useRecoilValue(GUStates.initialState.initMpf);
  const initHmf = useRecoilValue(GUStates.initialState.initHmf);
  const initDuty = useRecoilValue(GUStates.initialState.initDuty);
  const initCustomsExamType = useRecoilValue(GUStates.initialState.initCustomsExamType);
  const initCustomsExamNotifyDate = useRecoilValue(GUStates.initialState.initCustomsExamNotifyDate);
  const initCustomsExamFee = useRecoilValue(GUStates.initialState.initCustomsExamFee);
  const initCustomsReleaseDate = useRecoilValue(GUStates.initialState.initCustomsReleaseDate);
  const initFdaExamType = useRecoilValue(GUStates.initialState.initFdaExamType);
  const initFdaExamNotifyDate = useRecoilValue(GUStates.initialState.initFdaExamNotifyDate);
  const initFdaExamFee = useRecoilValue(GUStates.initialState.initFdaExamFee);
  const initFdaReleaseDate = useRecoilValue(GUStates.initialState.initFdaReleaseDate);
  const initEntryFeeTotal = useRecoilValue(GUStates.initialState.initEntryFeeTotal);

  const confirmedBookingDateChanged = confirmedBookingDate !== initConfirmedBookingDate;
  const bookingConfirmationNumberChanged =
    bookingConfirmationNumber !== initBookingConfirmationNumber;
  const polEtdChanged = polEtd !== initPolEtd;
  const polAtdChanged = polAtd !== initPolAtd;
  const transhipEtaChanged = transhipEta !== initTranshipEta;
  const transhipAtaChanged = transhipAta !== initTranshipAta;
  const transhipEtdChanged = transhipEtd !== initTranshipEtd;
  const transhipAtdChanged = transhipAtd !== initTranshipAtd;
  const podEtaChanged = podEta !== initPodEta;
  const podAtaChanged = podAta !== initPodAta;
  const rampEtaChanged = rampEta !== initRampEta;
  const rampAtaChanged = rampAta !== initRampAta;
  const rampOutGateDateChanged = rampOutGateDate !== initRampOutGateDate;
  const portOutGateDateChanged = portOutGateDate !== initPortOutGateDate;
  const deliveryEtaChanged = deliveryEta !== initDeliveryEta;
  const deliveryAtaChanged = deliveryAta !== initDeliveryAta;
  const emptyReturnDateChanged = emptyReturnDate !== initEmptyReturnDate;
  const emptyNotifyChanged = emptyNotify !== initEmptyNotify;
  const emptyPickupChanged = emptyPickup !== initEmptyPickup;
  const demurrageLfdChanged = demurrageLfd !== initDemurrageLfd;
  const detentionLfdChanged = detentionLfd !== initDetentionLfd;
  const consolidatorLfdChanged = consolidatorLfd !== initConsolidatorLfd;
  const customsBrokerRefChanged = customsBrokerRef !== initCustomsBrokerRef;
  const isfResponseNoChanged = isfResponseNo !== initIsfResponseNo;
  const isfFilingDateChanged = isfFilingDate !== initIsfFilingDate;
  const isfCutoffDateChanged = isfCutoffDate !== initIsfCutoffDate;
  const entryNumberChanged = entryNumber !== initEntryNumber;
  const entryDateChanged = entryDate !== initEntryDate;
  const entryValueChanged = entryValue !== initEntryValue;
  const mpfChanged = mpf !== initMpf;
  const hmfChanged = hmf !== initHmf;
  const dutyChanged = duty !== initDuty;
  const customsExamTypeChanged = customsExamType !== initCustomsExamType;
  const customsExamNotifyDateChanged = customsExamNotifyDate !== initCustomsExamNotifyDate;
  const customsExamFeeChanged = customsExamFee !== initCustomsExamFee;
  const customsReleaseDateChanged = customsReleaseDate !== initCustomsReleaseDate;
  const fdaExamTypeChanged = fdaExamType !== initFdaExamType;
  const fdaExamNotifyDateChanged = fdaExamNotifyDate !== initFdaExamNotifyDate;
  const fdaExamFeeChanged = fdaExamFee !== initFdaExamFee;
  const fdaReleaseDateChanged = fdaReleaseDate !== initFdaReleaseDate;
  const entryFeeTotalChanged = entryFeeTotal !== initEntryFeeTotal;

  const allRecordsData = useRecoilValue(GUStates.recordData);
  const selectedResults = useRecoilValue(GUStates.selectedGlobalUpdateRecords);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [callGlobalUpdate, { data }] = useGlobalUpdateMMutation({
    onError(error) {
      enqueueSnackbar('Failed to update: ' + error, { variant: 'error' });
    },
  });

  React.useEffect(() => {
    if (data) {
      if (data.globalUpdate?.success) {
        setOpenDialog(false);
        setOpenSuccessDialog(true);
      } else {
        enqueueSnackbar('Failed to update: ' + data.globalUpdate?.message, { variant: 'error' });
      }
    }
  }, [data]);

  if (!selectedResults) {
    return null;
  }

  return (
    <Box
      paddingX={2}
      paddingY={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h2" color="primary" style={{ minWidth: 'fit-content' }}>
        Global Update
      </Typography>
      <Box display="flex">
        <Box marginRight={3}>
          <Button
            variant="outlined"
            color="default"
            onClick={() => {
              navigate('/admin/global-updates');
            }}
          >
            Back
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenDialog(true);
          }}
          disabled={
            !confirmedBookingDateChanged &&
            !bookingConfirmationNumberChanged &&
            !polEtdChanged &&
            !polAtdChanged &&
            !transhipEtaChanged &&
            !transhipAtaChanged &&
            !transhipEtdChanged &&
            !transhipAtdChanged &&
            !podEtaChanged &&
            !podAtaChanged &&
            !rampEtaChanged &&
            !rampAtaChanged &&
            !rampOutGateDateChanged &&
            !portOutGateDateChanged &&
            !deliveryEtaChanged &&
            !deliveryAtaChanged &&
            !emptyReturnDateChanged &&
            !emptyNotifyChanged &&
            !emptyPickupChanged &&
            !demurrageLfdChanged &&
            !detentionLfdChanged &&
            !consolidatorLfdChanged &&
            !customsBrokerRefChanged &&
            !isfResponseNoChanged &&
            !isfFilingDateChanged &&
            !isfCutoffDateChanged &&
            !entryNumberChanged &&
            !entryDateChanged &&
            !entryValueChanged &&
            !mpfChanged &&
            !hmfChanged &&
            !dutyChanged &&
            !customsExamTypeChanged &&
            !customsExamNotifyDateChanged &&
            !customsExamFeeChanged &&
            !customsReleaseDateChanged &&
            !fdaExamTypeChanged &&
            !fdaExamNotifyDateChanged &&
            !fdaExamFeeChanged &&
            !fdaReleaseDateChanged &&
            !entryFeeTotalChanged
          }
        >
          Update Records ({selectedResults.length})
        </Button>
      </Box>
      <UniversalDialog
        open={openDialog}
        small
        setClose={() => {
          setOpenDialog(false);
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box>
            <ErrorIcon style={{ width: 90, height: 90, color: '#FFD758' }} />
          </Box>
          <Typography variant="h1" style={{ marginBottom: theme.spacing(1) }}>
            ARE YOU SURE YOU WANT TO UPDATE?
          </Typography>
          <Typography variant="h4" style={{ marginBottom: theme.spacing(1) }}>
            The following fields will be updated in{' '}
            <b style={{ fontSize: '25px' }}>({selectedResults.length})</b> records:
          </Typography>
          <UpdatedString
            label="Confirmation Number"
            changed={bookingConfirmationNumberChanged}
            value={bookingConfirmationNumber}
          />
          <UpdatedDate
            label="Confirmation Date"
            changed={confirmedBookingDateChanged}
            value={confirmedBookingDate}
          />
          <UpdatedDate label="POL ETD" changed={polEtdChanged} value={polEtd} />
          <UpdatedDate label="POL ATD" changed={polAtdChanged} value={polAtd} />
          <UpdatedDate label="Transship ETA" changed={transhipEtaChanged} value={transhipEta} />
          <UpdatedDate label="Transship ATA" changed={transhipAtaChanged} value={transhipAta} />
          <UpdatedDate label="Transship ETD" changed={transhipEtdChanged} value={transhipEtd} />
          <UpdatedDate label="Transship ATD" changed={transhipAtdChanged} value={transhipAtd} />
          <UpdatedDate label="POD ETA" changed={podEtaChanged} value={podEta} />
          <UpdatedDate label="POD ATA" changed={podAtaChanged} value={podAta} />
          <UpdatedDate label="Rail Ramp ETA" changed={rampEtaChanged} value={rampEta} />
          <UpdatedDate label="Rail Ramp ATA" changed={rampAtaChanged} value={rampAta} />
          <UpdatedDate
            label="Ramp Out Gate Date"
            changed={rampOutGateDateChanged}
            value={rampOutGateDate}
          />
          <UpdatedDate
            label="Port Out Gate Date"
            changed={portOutGateDateChanged}
            value={portOutGateDate}
          />
          <UpdatedDate label="Delivery ETA" changed={deliveryEtaChanged} value={deliveryEta} />
          <UpdatedDate label="Delivery ATA" changed={deliveryAtaChanged} value={deliveryAta} />
          <UpdatedDate
            label="Empty Return Date"
            changed={emptyReturnDateChanged}
            value={emptyReturnDate}
          />
          <UpdatedDate label="Empty Notify" changed={emptyNotifyChanged} value={emptyNotify} />
          <UpdatedDate label="Empty Pickup" changed={emptyPickupChanged} value={emptyPickup} />
          <UpdatedDate label="Demurrage LFD" changed={demurrageLfdChanged} value={demurrageLfd} />
          <UpdatedDate label="Detention LFD" changed={detentionLfdChanged} value={detentionLfd} />
          <UpdatedDate
            label="Consolidator LFD"
            changed={consolidatorLfdChanged}
            value={consolidatorLfd}
          />

          <UpdatedString
            label="Customs Broker Ref"
            changed={customsBrokerRefChanged}
            value={customsBrokerRef}
          />
          <UpdatedString
            label="ISF Response No."
            changed={isfResponseNoChanged}
            value={isfResponseNo}
          />
          <UpdatedDate
            label="ISF Filing Date"
            changed={isfFilingDateChanged}
            value={isfFilingDate}
          />
          <UpdatedDate
            label="ISF Cutoff Date"
            changed={isfCutoffDateChanged}
            value={isfCutoffDate}
          />
          <UpdatedString label="Entry Number" changed={entryNumberChanged} value={entryNumber} />
          <UpdatedDate label="Entry Date" changed={entryDateChanged} value={entryDate} />
          <UpdatedNumber label="Entry Value" changed={entryValueChanged} value={entryValue} />
          <UpdatedNumber label="MPF" changed={mpfChanged} value={mpf} />
          <UpdatedNumber label="HMF" changed={hmfChanged} value={hmf} />
          <UpdatedNumber label="Duty" changed={dutyChanged} value={duty} />
          <UpdatedString
            label="Customs Exam Type"
            changed={customsExamTypeChanged}
            value={customsExamType}
          />
          <UpdatedDate
            label="Customs Exam Notify Date"
            changed={customsExamNotifyDateChanged}
            value={customsExamNotifyDate}
          />
          <UpdatedNumber
            label="Customs Exam Fee"
            changed={customsExamFeeChanged}
            value={customsExamFee}
          />
          <UpdatedDate
            label="Customs Release Date"
            changed={customsReleaseDateChanged}
            value={customsReleaseDate}
          />
          <UpdatedString label="FDA Exam Type" changed={fdaExamTypeChanged} value={fdaExamType} />
          <UpdatedDate
            label="FDA Exam Notify Date"
            changed={fdaExamNotifyDateChanged}
            value={fdaExamNotifyDate}
          />
          <UpdatedNumber label="FDA Exam Fee" changed={fdaExamFeeChanged} value={fdaExamFee} />
          <UpdatedDate
            label="FDA Release Date"
            changed={fdaReleaseDateChanged}
            value={fdaReleaseDate}
          />
          <UpdatedNumber
            label="Entry Fee Total"
            changed={entryFeeTotalChanged}
            value={entryFeeTotal}
          />
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '24px' }}
          >
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="default"
                fullWidth
                onClick={() => {
                  setOpenDialog(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  const logisticIds = [];
                  for (const logistic of allRecordsData.logistics) {
                    logisticIds.push(logistic.logisticsId);
                  }
                  const containerIds = [];
                  for (const record of selectedResults) {
                    if (record.containerId) {
                      containerIds.push(record.containerId);
                    }
                  }

                  const customsIds = allRecordsData.customs.map((c) => c.customsId);
                  const allBolIds = [];
                  for (const r of selectedResults) {
                    if (r.hblId) {
                      allBolIds.push(r.hblId);
                    }
                    if (r.mblId) {
                      allBolIds.push(r.mblId);
                    }
                  }

                  const bolIds = Array.from(new Set([...allBolIds])) as string[];

                  // NOTE - it's important you only send up things that actually changed so the backend knows what not to change
                  const logistics: GqlGlobalUpdateLogisticsInput = {
                    logisticsIds: logisticIds,
                  };
                  if (bookingConfirmationNumberChanged) {
                    logistics.confirmationNumber = bookingConfirmationNumber;
                  }
                  if (confirmedBookingDateChanged) {
                    logistics.confirmationDate = confirmedBookingDate;
                  }
                  if (polEtdChanged) {
                    logistics.polEtd = polEtd;
                  }
                  if (polAtdChanged) {
                    logistics.polAtd = polAtd;
                  }
                  if (transhipEtaChanged) {
                    logistics.transitPortEta = transhipEta;
                  }
                  if (transhipAtaChanged) {
                    logistics.transitPortAta = transhipAta;
                  }
                  if (transhipEtdChanged) {
                    logistics.transitPortEtd = transhipEtd;
                  }
                  if (transhipAtdChanged) {
                    logistics.transitPortAtd = transhipAtd;
                  }
                  if (podEtaChanged) {
                    logistics.podEta = podEta;
                  }
                  if (podAtaChanged) {
                    logistics.podAta = podAta;
                  }
                  if (isfCutoffDateChanged) {
                    logistics.isfCutoffDate = isfCutoffDate;
                  }

                  const containers: GqlGlobalUpdateContainerInput = {
                    containerIds: containerIds,
                  };
                  if (rampEtaChanged) {
                    containers.rampEta = rampEta;
                  }
                  if (rampAtaChanged) {
                    containers.rampAta = rampAta;
                  }
                  if (rampOutGateDateChanged) {
                    containers.rampOutGateDate = rampOutGateDate;
                  }
                  if (portOutGateDateChanged) {
                    containers.portOutGateDate = portOutGateDate;
                  }
                  if (deliveryEtaChanged) {
                    containers.deliveryEta = deliveryEta;
                  }
                  if (deliveryAtaChanged) {
                    containers.deliveryAta = deliveryAta;
                  }
                  if (emptyNotifyChanged) {
                    containers.emptyNotifyDate = emptyNotify;
                  }
                  if (emptyPickupChanged) {
                    containers.emptyPickupDate = emptyPickup;
                  }
                  if (emptyReturnDateChanged) {
                    containers.emptyReturnedDate = emptyReturnDate;
                  }
                  if (demurrageLfdChanged) {
                    containers.demurrageLastFreeDayDate = demurrageLfd;
                  }
                  if (detentionLfdChanged) {
                    containers.detentionLastFreeDayDate = detentionLfd;
                  }
                  if (consolidatorLfdChanged) {
                    containers.consolidatorLastFreeDayDate = consolidatorLfd;
                  }

                  const customs: GqlGlobalUpdateCustomsInput = {
                    customsIds: customsIds,
                    bolIds: bolIds,
                  };
                  if (customsBrokerRefChanged) {
                    customs.customsBrokerRef = customsBrokerRef;
                  }
                  if (isfResponseNoChanged) {
                    customs.isfResponseNumber = isfResponseNo;
                  }
                  if (isfFilingDateChanged) {
                    customs.isfFilingDate = isfFilingDate;
                  }
                  if (entryNumberChanged) {
                    customs.entryNumber = entryNumber;
                  }
                  if (entryDateChanged) {
                    customs.entryDate = entryDate;
                  }
                  if (entryValueChanged) {
                    customs.entryValue = entryValue;
                  }
                  if (mpfChanged) {
                    customs.mpf = mpf;
                  }
                  if (hmfChanged) {
                    customs.hmf = hmf;
                  }
                  if (dutyChanged) {
                    customs.duty = duty;
                  }
                  if (customsExamTypeChanged) {
                    customs.customsExamType = customsExamType;
                  }
                  if (customsExamNotifyDateChanged) {
                    customs.customsExamNotifyDate = customsExamNotifyDate;
                  }
                  if (customsExamFeeChanged) {
                    customs.customsExamFee = customsExamFee;
                  }
                  if (customsReleaseDateChanged) {
                    customs.customsReleaseDate = customsReleaseDate;
                  }
                  if (fdaExamTypeChanged) {
                    customs.fdaExamType = fdaExamType;
                  }
                  if (fdaExamNotifyDateChanged) {
                    customs.fdaExamNotifyDate = fdaExamNotifyDate;
                  }
                  if (fdaExamFeeChanged) {
                    customs.fdaExamFee = fdaExamFee;
                  }
                  if (fdaReleaseDateChanged) {
                    customs.fdaReleaseDate = fdaReleaseDate;
                  }
                  if (entryFeeTotalChanged) {
                    customs.entryFeeTotal = entryFeeTotal;
                  }

                  callGlobalUpdate({
                    variables: {
                      input: {
                        logistics: logistics,
                        containers: containers,
                        customs: customs,
                      },
                    },
                  });
                }}
                variant="contained"
                color="primary"
                fullWidth
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </UniversalDialog>
      <StatusDialog
        onClose={() => {
          setOpenSuccessDialog(false);
        }}
        finished={
          openSuccessDialog
            ? {
                title: `${selectedResults.length} RECORDS SUCCESSFULLY UPDATED`,

                buttons: (
                  <Button
                    style={{ width: '50%' }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      setOpenSuccessDialog(false);
                      navigate('/admin/global-updates');
                    }}
                  >
                    Ok
                  </Button>
                ),
              }
            : null
        }
      />
    </Box>
  );
}

function UpdatedDate(props: { label: string; changed: boolean; value: DateAtomType }) {
  return (
    <>
      {props.changed && (
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          {props.label}: <b>{formatDate(props.value)}</b>
        </Typography>
      )}
    </>
  );
}

function UpdatedString(props: { label: string; changed: boolean; value: string }) {
  return (
    <>
      {props.changed && (
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          {props.label}: <b>{props.value}</b>
        </Typography>
      )}
    </>
  );
}

function UpdatedNumber(props: { label: string; changed: boolean; value: number | null }) {
  return (
    <>
      {props.changed && (
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          {props.label}: <b>{props.value}</b>
        </Typography>
      )}
    </>
  );
}
