import { Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import LineItem from 'components/LineItem';
import { useRecoilValue } from 'recoil';
import { mapMoveType } from 'types/OMSEnums';
import { MblPageStates } from '../states';

export default function MBLDetailsSection() {
  const { userContext } = auth.useAuthState();
  const mbl = useRecoilValue(MblPageStates.mbl);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <LineItem label="Carrier" value={mbl?.shipment.logistics.carrier?.name} />
        <LineItem label="Vessel" value={mbl?.shipment.logistics.motherVessel?.name} />
        <LineItem label="Voyage" value={mbl?.shipment.logistics.motherVoyage?.name} />
        <LineItem label="Release Type" value={mbl?.releaseType} />
        <LineItem label="Contract Type" value={mbl?.shipment.logistics.contractType} />
        <LineItem
          label="Delivery Type"
          value={
            mbl?.shipment.logistics.moveType ? mapMoveType(mbl.shipment.logistics.moveType) : ''
          }
        />
      </Grid>
      <Grid item xs>
        {mbl?.shipment.id && (
          <LineItem
            label="Consolidation #"
            value={mbl?.shipment.referenceNumber}
            customValue={(value: any) => {
              return userContext?.permissionCodes.has(PermissionCode.ConsolidationRead) ? (
                <Link
                  to={'/consolidations/' + mbl?.shipment.id}
                  state={{ consolidationId: mbl?.shipment.id }}
                >
                  {value}
                </Link>
              ) : (
                <Typography variant="body1">{value}</Typography>
              );
            }}
          />
        )}
        <LineItem label="Payment Type" value={mbl?.paymentType} />
        <LineItem label="Contract" value={mbl?.shipment.logistics.contractNumber} />
        <LineItem label="Container Count" value={mbl?.containerCount} />
        <LineItem label="Total Pieces" value={mbl?.totalPieces} />
        <LineItem label="Total Ctns" value={mbl?.totalCartons} />
      </Grid>
    </Grid>
  );
}
