import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { PartyType, RelatedProfileCondition } from 'api/GQL_Types';
import { FormInputCountry } from 'components/form/FormInputCountry';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormInputText } from 'components/form/FormInputText';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { mapRelatedProfileCondition } from 'types/OMSEnums';
import { NetworkAdminPageStates } from '../states';
import { RelatedProfileRule, partyTypeOptions } from './states';

interface Props {
  rule: RelatedProfileRule;
  onChange(rule: RelatedProfileRule): void;
  onRemove(): void;
  disabled: boolean;
}

export const RelationRule: React.FC<Props> = (props) => {
  const profiles = useRecoilValue(NetworkAdminPageStates.network)?.connectedProfiles || [];

  const profileOptions = profiles.map((p) => ({ id: p.id, label: p.name }));

  const showPortInput =
    props.rule.condition === RelatedProfileCondition.Pol ||
    props.rule.condition === RelatedProfileCondition.PodOrRamp;

  const showCountryInput =
    props.rule.condition === RelatedProfileCondition.PolCountryCode ||
    props.rule.condition === RelatedProfileCondition.PodOrRampCountryCode ||
    props.rule.condition === RelatedProfileCondition.FinalDestinationCountryCode;

  const showCategoryInput = props.rule.condition === RelatedProfileCondition.Category;
  const showDepartmentInput = props.rule.condition === RelatedProfileCondition.Department;
  const showMaterialInput = props.rule.condition === RelatedProfileCondition.Material;

  const conditionOptions = props.rule.partyType ? getPartyTypeConditions(props.rule.partyType) : [];

  const isValidOption = props.rule.condition && conditionOptions.includes(props.rule.condition);

  React.useEffect(() => {
    const condition = conditionOptions.find((o) => o === props.rule.condition) || null;
    props.onChange({ ...props.rule, condition });
  }, [isValidOption]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: theme.spacing(0, 0, 1.5, 0) }}>
      Set the
      <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
        <FormInputSelect
          label="Type"
          value={props.rule.partyType}
          onValue={(partyType: any) => {
            props.onChange({ ...props.rule, partyType });
          }}
          options={partyTypeOptions}
          disabled={props.disabled}
        />
      </div>
      to
      <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
        <FormInputSelect
          label="Profile"
          value={props.rule.profileId}
          onValue={(profileId) => props.onChange({ ...props.rule, profileId })}
          options={profileOptions}
          disabled={!props.rule.partyType || props.disabled}
        />
      </div>
      <div style={{ width: 300, margin: theme.spacing(0, 1) }}>
        <FormInputSelect
          label="Condition"
          value={isValidOption ? props.rule.condition : null}
          onValue={(condition: any) => condition && props.onChange({ ...props.rule, condition })}
          options={conditionOptions.map((type) => ({
            id: type,
            label: mapRelatedProfileCondition(type),
          }))}
          disabled={!props.rule.profileId || props.disabled}
        />
      </div>
      {showPortInput && <span> is </span>}
      {showPortInput && (
        <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
          <FormInputPortAny
            label="Port"
            value={props.rule.port}
            onValue={(port) => props.onChange({ ...props.rule, port })}
            disabled={props.disabled}
          />
        </div>
      )}
      {showCountryInput && <span> is </span>}
      {showCountryInput && (
        <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
          <FormInputCountry
            label="Country"
            value={props.rule.country}
            onValue={(country) => props.onChange({ ...props.rule, country })}
            disabled={props.disabled}
          />
        </div>
      )}
      {showCategoryInput && <span> is </span>}
      {showCategoryInput && (
        <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
          <FormInputText
            label="Category"
            value={props.rule.category ? props.rule.category : ''}
            onValue={(category) => props.onChange({ ...props.rule, category })}
            disabled={props.disabled}
          ></FormInputText>
        </div>
      )}
      {showDepartmentInput && <span> is </span>}
      {showDepartmentInput && (
        <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
          <FormInputText
            label="Department"
            value={props.rule.department ? props.rule.department : ''}
            onValue={(department) => props.onChange({ ...props.rule, department })}
            disabled={props.disabled}
          ></FormInputText>
        </div>
      )}
      {showMaterialInput && <span> is </span>}
      {showMaterialInput && (
        <div style={{ width: 200, margin: theme.spacing(0, 1) }}>
          <FormInputText
            label="Material"
            value={props.rule.material ? props.rule.material : ''}
            onValue={(material) => props.onChange({ ...props.rule, material })}
            disabled={props.disabled}
          ></FormInputText>
        </div>
      )}
      <IconButton
        aria-label="delete"
        style={{ width: '10px', height: '10px' }}
        onClick={() => {
          props.onRemove();
        }}
        disabled={props.disabled}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

function getPartyTypeConditions(party: PartyType): RelatedProfileCondition[] {
  switch (party) {
    case PartyType.DestinationAgent:
      return [
        RelatedProfileCondition.Always,
        RelatedProfileCondition.Default,
        RelatedProfileCondition.FinalDestinationCountryCode,
        RelatedProfileCondition.PodOrRampCountryCode,
        RelatedProfileCondition.PodOrRamp,
      ];
    case PartyType.OriginAgent:
      return [
        RelatedProfileCondition.Always,
        RelatedProfileCondition.Default,
        RelatedProfileCondition.PolCountryCode,
        RelatedProfileCondition.Pol,
      ];
    case PartyType.Buyer:
    case PartyType.BuyingAgent:
      return [
        RelatedProfileCondition.Always,
        RelatedProfileCondition.Default,
        RelatedProfileCondition.Category,
        RelatedProfileCondition.Department,
        RelatedProfileCondition.Material,
      ];
    case PartyType.Admin:
    case PartyType.Consignee:
    case PartyType.Customer:
    case PartyType.Manufacturer:
    case PartyType.Notify:
    case PartyType.Related:
    case PartyType.Shipper:
    case PartyType.Supplier:
    case PartyType.Warehouse:
      return [RelatedProfileCondition.Always, RelatedProfileCondition.Default];
  }
}
