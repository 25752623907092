import { Button, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { NewBookingPageStates } from '../../states';

export default function RemoveButton() {
  const allLines = useRecoilValue(NewBookingPageStates.step1.allLines);
  const [selectedLines, setSelectedLines] = useRecoilState(
    NewBookingPageStates.step1.selectedLines
  );
  const setAvailableLines = useSetRecoilState(NewBookingPageStates.step1.availableLines);
  const [selectedLinesChecked, setSelectedLinesChecked] = useRecoilState(
    NewBookingPageStates.step1.selectedLinesChecked
  );

  function removeFromBooking() {
    const newSelectedLines = selectedLines.filter(
      (line) => !selectedLinesChecked.includes(line.id)
    );
    const selectedIds = newSelectedLines.map((line) => line.id);
    setAvailableLines(allLines.filter((line) => !selectedIds.includes(line.id)));
    setSelectedLines(newSelectedLines);
    setSelectedLinesChecked([]);
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      disabled={selectedLinesChecked.length === 0}
      onClick={removeFromBooking}
      style={{
        display: 'flex',
        marginRight: theme.spacing(3),
        width: '224px',
        justifyContent: 'space-evenly',
      }}
    >
      <ExpandMoreIcon style={{ color: '#FFFFFF', marginRight: '8px' }} />
      <Typography variant="h3" color="textSecondary">
        REMOVE
      </Typography>
    </Button>
  );
}
