import { Dataset, DatasetFilter } from 'types/Dataset';

export interface PresetReport {
  id: string;
  name: string;
  description: string;

  dataset: Dataset;

  filters: DatasetFilter[];
}

export function getPresetReports(datasets: Dataset[]): PresetReport[] {
  const presets: PresetReport[] = [];

  function setupReport(setupFn: (dataset: Dataset) => PresetReport, datasetId: string) {
    const dataset = datasets.find((d) => d.id === datasetId);
    // if they don't have permission, it wont be in the dataset list
    if (dataset) {
      presets.push(setupFn(dataset));
    }
  }

  // doing it this weird way so we can maintain a defined order
  setupReport(setupCargoSKUReport, 'report_sku');
  setupReport(setupInTransitByContainer, 'report_in_transit_by_container');
  setupReport(setupPoStatus, 'report_po_status');
  setupReport(setupContainerLastMile, 'report_sku');
  setupReport(setupSupplierOnTime, 'report_supplier_on_time');

  return presets;
}

function setupCargoSKUReport(dataset: Dataset): PresetReport {
  const filters: DatasetFilter[] = [];
  for (const filterable of dataset.filterables) {
    if (filterable.field === 'shipment_status') {
      const values: string[] = [];
      for (const opt of filterable.options || []) {
        values.push(opt.value);
      }
      filters.push({
        field: filterable.field,
        value: JSON.stringify(values),
        applied: true,
      });
    }
  }
  return {
    id: 'cargo-sku-report',
    name: 'Cargo SKU Report',
    description: dataset.description,
    dataset,
    filters,
  };
}

function setupInTransitByContainer(dataset: Dataset): PresetReport {
  return {
    id: 'in-transit-by-container',
    name: dataset.name,
    description: dataset.description,
    dataset,
    filters: [],
  };
}

function setupPoStatus(dataset: Dataset): PresetReport {
  return {
    id: 'po-status-report',
    name: dataset.name,
    description: dataset.description,
    dataset,
    filters: [
      {
        field: 'po_booking_status',
        value: null, // null indicates ALL for this filter type
        applied: true,
      },
    ],
  };
}

function setupContainerLastMile(dataset: Dataset): PresetReport {
  return {
    id: 'container-last-mile',
    name: 'Container Last Mile by PO/SKU',
    description: dataset.description,
    dataset,
    filters: [
      {
        field: 'pod_eta',
        value: null,
        applied: true,
      },
      {
        field: 'has_empty_returned',
        value: 'false',
        applied: true,
      },
    ],
  };
}

function setupSupplierOnTime(dataset: Dataset): PresetReport {
  return {
    id: 'supplier-on-time',
    name: dataset.name,
    description: dataset.description,
    dataset,
    filters: [
      {
        field: 'date',
        value: '{"type":"qtd","from":null,"to":null}',
        applied: true,
      },
      {
        field: 'milestone_1',
        value: 'po_exp_crd',
        applied: true,
      },
      {
        field: 'milestone_2',
        value: 'booking_crd',
        applied: true,
      },
    ],
  };
}
