import React from 'react';
import { InputItem, InputRow } from './InputLayout';
import { RuleCard } from './RuleCard';
import { RuleNumDaysInput } from './RuleNumDaysInput';

interface Props {
  name: string;
  description: string;
  enabled: boolean;
  onEnabled(enabled: boolean): void;
  days: number;
  onDays(days: number): void;
  daysPlusOrMinus?: boolean;
  notYetSupported?: boolean;
}

export const RuleCardDay: React.FC<Props> = ({
  name,
  description,
  enabled,
  onEnabled,
  days,
  onDays,
  daysPlusOrMinus,
  notYetSupported,
}) => {
  return (
    <RuleCard
      name={name}
      description={description}
      enabled={enabled}
      onEnabled={onEnabled}
      notYetSupported={notYetSupported}
    >
      <InputRow>
        <InputItem small>
          <RuleNumDaysInput value={days} onValue={onDays} plusOrMinus={daysPlusOrMinus} />
        </InputItem>
      </InputRow>
    </RuleCard>
  );
};
