import { RuleFieldType } from 'api/GQL_Types';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { RuleCardDayField } from './components/RuleCardDayField';
import { RuleCardGroup } from './components/RuleCardGroup';

const ALL_poDateFields: RuleFieldType[] = [
  RuleFieldType.PoDate,
  RuleFieldType.PoExpectedCrd,
  RuleFieldType.PoRevisedCrd,
  RuleFieldType.PoIndc,
  RuleFieldType.PoFirstShip,
  RuleFieldType.PoLastShip,
  RuleFieldType.PoBookBy,
];

interface Props {}

export const RulesPO: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Purchase Order"
      bools={[
        rulesForm.poExpiredEnabled,
        rulesForm.poFirstShipEnabled,
        rulesForm.poLastShipEnabled,
        rulesForm.poBookByEnabled,
        rulesForm.poCriticalEnabled,
      ]}
    >
      <RuleCardDayField
        name="Expired PO"
        description="Days after selected field, PO is considered Expired"
        enabled={rulesForm.poExpiredEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, poExpiredEnabled: enabled });
        }}
        days={rulesForm.poExpiredDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, poExpiredDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.poExpiredField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, poExpiredField: v });
        }}
      />
      <RuleCardDayField
        name="First Ship Window"
        description="Days before the selected field, the ship window begins."
        enabled={rulesForm.poFirstShipEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, poFirstShipEnabled: enabled });
        }}
        days={rulesForm.poFirstShipDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, poFirstShipDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.poFirstShipField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, poFirstShipField: v });
        }}
      />
      <RuleCardDayField
        name="Last Ship Window"
        description="Days after the selected field, the ship window ends."
        enabled={rulesForm.poLastShipEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, poLastShipEnabled: enabled });
        }}
        days={rulesForm.poLastShipDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, poLastShipDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.poLastShipField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, poLastShipField: v });
        }}
      />
      <RuleCardDayField
        name="Booked by Date"
        description="Days after the selected field, the po should be booked."
        enabled={rulesForm.poBookByEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, poBookByEnabled: enabled });
        }}
        days={rulesForm.poBookByDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, poBookByDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.poBookByField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, poBookByField: v });
        }}
      />
      <RuleCardDayField
        name="Critical PO"
        description="Days after the selected field and the PO is not booked, consider the PO Critical."
        enabled={rulesForm.poCriticalEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, poCriticalEnabled: enabled });
        }}
        days={rulesForm.poCriticalDays}
        onDays={(v) => {
          setRulesForm({ ...rulesForm, poCriticalDays: v });
        }}
        fieldOptions={ALL_poDateFields}
        field={rulesForm.poCriticalField}
        onField={(v) => {
          setRulesForm({ ...rulesForm, poCriticalField: v });
        }}
      />
    </RuleCardGroup>
  );
};
