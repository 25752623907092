import { FormInputTextWithAliases } from 'components/form/FormInputTextWithAliases';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  textState: RecoilState<string>;
  aliasesState: RecoilState<string[]>;

  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export const AtomicTextWithAliasesV2: React.FC<Props> = (props) => {
  const [text, setText] = useRecoilState(props.textState);
  const [aliases, setAliases] = useRecoilState(props.aliasesState);

  return (
    <FormItem>
      <FormInputTextWithAliases
        text={text}
        onText={setText}
        aliases={aliases}
        onAliases={setAliases}
        label={props.label}
        error={props.error}
        disabled={props.disabled}
        required={props.required}
      />
    </FormItem>
  );
};
