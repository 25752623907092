import { Grid } from '@material-ui/core';
import { GUStates } from 'app/global-update/states';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';
import GlobalUpdateStep2TransportationSection from '../GlobalUpdateStep2TransportationSection';

export default function GlobalUpdateStep2TransportationTransshipment() {
  const initTranshipEta = useRecoilValue(GUStates.initialState.initTranshipEta);
  const transhipEta = useRecoilValue(GUStates.form.transhipEta);
  const transhipEtaLabels = useRecoilValue(GUStates.form.transhipEtaLabels);

  const initTranshipAta = useRecoilValue(GUStates.initialState.initTranshipAta);
  const transhipAta = useRecoilValue(GUStates.form.transhipAta);
  const transhipAtaLabels = useRecoilValue(GUStates.form.transhipAtaLabels);

  const initTranshipEtd = useRecoilValue(GUStates.initialState.initTranshipEtd);
  const transhipEtd = useRecoilValue(GUStates.form.transhipEtd);
  const transhipEtdLabels = useRecoilValue(GUStates.form.transhipEtdLabels);

  const initTranshipAtd = useRecoilValue(GUStates.initialState.initTranshipAtd);
  const transhipAtd = useRecoilValue(GUStates.form.transhipAtd);
  const transhipAtdLabels = useRecoilValue(GUStates.form.transhipAtdLabels);

  return (
    <GlobalUpdateStep2TransportationSection title="Transshipment">
      <Grid container>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.transhipEta} label="Transship ETA" />
          <TextFieldWarnDecorator
            list={transhipEtaLabels}
            hasChanged={transhipEta !== initTranshipEta}
            defaultValue={initTranshipEta}
            formState={GUStates.form.transhipEta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.transhipAta} label="Transship ATA" />
          <TextFieldWarnDecorator
            list={transhipAtaLabels}
            hasChanged={transhipAta !== initTranshipAta}
            defaultValue={initTranshipAta}
            formState={GUStates.form.transhipAta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.transhipEtd} label="Transship ETD" />
          <TextFieldWarnDecorator
            list={transhipEtdLabels}
            hasChanged={transhipEtd !== initTranshipEtd}
            defaultValue={initTranshipEtd}
            formState={GUStates.form.transhipEtd}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.transhipAtd} label="Transship ATD" />
          <TextFieldWarnDecorator
            list={transhipAtdLabels}
            hasChanged={transhipAtd !== initTranshipAtd}
            defaultValue={initTranshipAtd}
            formState={GUStates.form.transhipAtd}
          />
        </Grid>
      </Grid>
    </GlobalUpdateStep2TransportationSection>
  );
}
