import { gqlClient } from 'api/ApolloClient';
import {
  GqlConsolidation,
  GqlSetConsolidationRelatedPartiesMutation,
  GqlSetConsolidationRelatedPartiesMutationVariables,
} from 'api/GQL_Types';
import { SetConsolidationRelatedPartiesMutation } from 'api/queries/consolidationQueries';
import {
  OverrideRelatedPartiesDialog,
  OverrideRelatedPartyValue,
} from 'components/OverrideRelatedPartiesDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  openState: RecoilState<boolean>;
  data: GqlConsolidation;
  onSaved(): void;
}

export const ConsolidationPartyOverrideDialog: React.FC<Props> = ({ openState, data, onSaved }) => {
  const [open, setOpen] = useRecoilState(openState);
  const action = useAsyncAction(setConsolidationRelatedParties);

  const [value, setValue] = React.useState<OverrideRelatedPartyValue[]>([]);

  React.useEffect(() => {
    if (!open) return;
    setValue(
      data.relatedParties.map((rp) => {
        return {
          id: rp.id,
          profileId: rp.party.id,
          partyType: rp.partyType,
        };
      })
    );
  }, [open, data]);

  if (!open) {
    return null;
  }

  return (
    <OverrideRelatedPartiesDialog
      onClose={() => setOpen(false)}
      isSaving={action.waiting}
      saveError={action.error}
      value={value}
      onValue={(value) => {
        setValue(value); // so the value doesn't reset while saving
        action
          .act({
            input: {
              shipmentId: data.id,
              parties: value.map((v) => {
                return {
                  profileId: v.profileId,
                  partyType: v.partyType,
                };
              }),
            },
          })
          .then((res) => {
            if (res.type === 'data') {
              onSaved();
              setOpen(false);
            }
          });
      }}
    />
  );
};

async function setConsolidationRelatedParties(
  variables: GqlSetConsolidationRelatedPartiesMutationVariables
): Promise<void> {
  const res = await gqlClient.mutate<GqlSetConsolidationRelatedPartiesMutation>({
    mutation: SetConsolidationRelatedPartiesMutation,
    variables,
  });
  if (!res.data?.setConsolidationRelatedParties.success) {
    throw new Error(res.data?.setConsolidationRelatedParties.message || 'Unexpected Error');
  }
}
