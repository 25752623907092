import { gql } from '@apollo/client/core';
import { GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables } from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const UpdateRoleMutation = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      success
      message
    }
  }
`;

export const updateRole = wrapMutation<GqlUpdateRoleMutation, GqlUpdateRoleMutationVariables>({
  mutation: UpdateRoleMutation,
  errorMessage(data, error) {
    if (!data?.updateRole.success || error) {
      return data?.updateRole.message || 'Unexpected Error';
    }
  },
});

export const DeleteRoleMutation = gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      success
      message
    }
  }
`;
