import { GqlPortFragment, LocationType } from 'api/GQL_Types';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';

export interface LocationFormInit {
  id: string;
  name: string;
  nameAliases: string[];
  code: string;
  codeAliases: string[];
  locationTypes: LocationType[];
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  timeZone: string;
  relatedPort: GqlPortFragment | null;
  companyPhone: string;
  companyUrl: string;
}

export const LocationFormStates = {
  location: newAtom<LocationFormInit | null>(null),
  otherProfileLocationTypes: newAtom<LocationType[]>([]),

  name: newAtom<string>(''),
  nameAliases: newAtom<string[]>([]),
  code: newAtom<string>(''),
  codeAliases: newAtom<string[]>([]),
  locationTypes: newAtom<LocationType[]>([]),
  addressLine1: newAtom<string>(''),
  addressLine2: newAtom<string>(''),
  city: newAtom<string>(''),
  state: newAtom<string>(''),
  postalCode: newAtom<string>(''),
  country: newAtom<string | null>(null),
  timeZone: newAtom<string>(''),
  hasSetTimeZone: newAtom<boolean>(false),
  relatedPortCode: newAtom<GqlPortFragment | null>(null),
  companyPhone: newAtom<string>(''),
  companyUrl: newAtom<string>(''),
};

export function useSetupLocationForm() {
  const setLocation = useSetRecoilState(LocationFormStates.location);
  const setOtherProfileLocationTypes = useSetRecoilState(
    LocationFormStates.otherProfileLocationTypes
  );

  const setLocationName = useSetRecoilState(LocationFormStates.name);
  const setLocationNameAliases = useSetRecoilState(LocationFormStates.nameAliases);
  const setLocationCode = useSetRecoilState(LocationFormStates.code);
  const setLocationCodeAliases = useSetRecoilState(LocationFormStates.codeAliases);
  const setLocationLocationTypes = useSetRecoilState(LocationFormStates.locationTypes);
  const setLocationAddressLine1 = useSetRecoilState(LocationFormStates.addressLine1);
  const setLocationAddressLine2 = useSetRecoilState(LocationFormStates.addressLine2);
  const setLocationCity = useSetRecoilState(LocationFormStates.city);
  const setLocationState = useSetRecoilState(LocationFormStates.state);
  const setLocationPostalCode = useSetRecoilState(LocationFormStates.postalCode);
  const setLocationCountry = useSetRecoilState(LocationFormStates.country);
  const setLocationTimeZone = useSetRecoilState(LocationFormStates.timeZone);
  const setLocationHasSetTimeZone = useSetRecoilState(LocationFormStates.hasSetTimeZone);
  const setLocationRelatedPortCode = useSetRecoilState(LocationFormStates.relatedPortCode);
  const setLocationCompanyPhone = useSetRecoilState(LocationFormStates.companyPhone);
  const setLocationCompanyUrl = useSetRecoilState(LocationFormStates.companyUrl);

  return (
    location: LocationFormInit | null = null,
    otherProfileLocationTypes: LocationType[] = []
  ) => {
    setLocation(location);
    setOtherProfileLocationTypes(otherProfileLocationTypes);

    setLocationName(location?.name || '');
    setLocationNameAliases(location?.nameAliases || []);
    setLocationCode(location?.code || '');
    setLocationCodeAliases(location?.codeAliases || []);
    setLocationLocationTypes(location?.locationTypes || []);
    setLocationAddressLine1(location?.addressLine1 || '');
    setLocationAddressLine2(location?.addressLine2 || '');
    setLocationCity(location?.city || '');
    setLocationState(location?.state || '');
    setLocationPostalCode(location?.postalCode || '');
    setLocationCountry(location?.country || '');
    setLocationTimeZone(location?.timeZone || '');
    setLocationHasSetTimeZone(location?.timeZone ? true : false);
    setLocationRelatedPortCode(location?.relatedPort || null);
    setLocationCompanyPhone(location?.companyPhone || '');
    setLocationCompanyUrl(location?.companyUrl || '');
  };
}

export function useLocationFormState() {
  return {
    location: useRecoilValue(LocationFormStates.location),
    otherProfileLocationTypes: useRecoilValue(LocationFormStates.otherProfileLocationTypes),

    name: useRecoilValue(LocationFormStates.name),
    nameAliases: useRecoilValue(LocationFormStates.nameAliases),
    code: useRecoilValue(LocationFormStates.code),
    codeAliases: useRecoilValue(LocationFormStates.codeAliases),
    locationTypes: useRecoilValue(LocationFormStates.locationTypes),
    addressLine1: useRecoilValue(LocationFormStates.addressLine1),
    addressLine2: useRecoilValue(LocationFormStates.addressLine2),
    city: useRecoilValue(LocationFormStates.city),
    state: useRecoilValue(LocationFormStates.state),
    postalCode: useRecoilValue(LocationFormStates.postalCode),
    country: useRecoilValue(LocationFormStates.country),
    timeZone: useRecoilValue(LocationFormStates.timeZone),
    relatedPortCode: useRecoilValue(LocationFormStates.relatedPortCode),
    companyPhone: useRecoilValue(LocationFormStates.companyPhone),
    companyUrl: useRecoilValue(LocationFormStates.companyUrl),
  };
}

export const locationTypeWarning = selector<string | undefined>({
  key: 'locationTypeWarning',
  get: ({ get }) => {
    const location = get(LocationFormStates.location);
    const otherProfileLocationTypes = get(LocationFormStates.otherProfileLocationTypes);
    const locationTypes = get(LocationFormStates.locationTypes);

    let primaryWarning = false;
    let customsWarning = false;

    if (locationTypes.includes(LocationType.Primary)) {
      if (otherProfileLocationTypes.includes(LocationType.Primary)) {
        if (location?.locationTypes.includes(LocationType.Primary)) {
          // ok b/c they already are setup as the Primary address
        } else {
          primaryWarning = true;
        }
      }
    }
    if (locationTypes.includes(LocationType.Customs)) {
      if (otherProfileLocationTypes.includes(LocationType.Customs)) {
        if (location?.locationTypes.includes(LocationType.Customs)) {
          // ok b/c they already are setup as the Customs address
        } else {
          customsWarning = true;
        }
      }
    }

    if (primaryWarning && customsWarning) {
      return 'This location will become the new primary location/customs address of records, replacing the previous one, if saved.';
    } else if (primaryWarning) {
      return 'This location will become the new primary location, replacing the previous one, if saved.';
    } else if (customsWarning) {
      return 'This location will become the new customs address of records replacing, the previous one, if saved.';
    }

    return undefined;
  },
});
