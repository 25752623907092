import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { SearchResultGroup } from './SearchResultGroup';
import { QuickSearchBookingResult, SearchPageStates } from './states';

const columns: UWLTableColumn<QuickSearchBookingResult>[] = [
  { id: 'bookingNumber', label: 'Booking Number', type: 'string' },
  { id: 'mblNumber', label: 'MBL Number', type: 'string' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship To Loc', type: 'string' },
  { id: 'carrierName', label: 'Carrier Name', type: 'string' },
  { id: 'polCode', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'podCode', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
];

interface Props {}

export default function SearchResultBookings(props: Props) {
  const { userContext } = auth.useAuthState();
  const searchPageData = useRecoilValue(SearchPageStates.data);

  return (
    <SearchResultGroup title="Bookings" count={searchPageData.bookingCount}>
      <UWLTable
        rowId="bookingId"
        rows={searchPageData.bookings}
        columns={columns}
        renderCell={{
          bookingNumber(row) {
            return <Link to={'/bookings/' + row.bookingId}>{row.bookingNumber}</Link>;
          },
          mblNumber(row) {
            if (userContext?.permissionCodes.has(PermissionCode.MblRead)) {
              return <Link to={'/mbl/' + row.mblId}>{row.mblNumber}</Link>;
            } else {
              return <>{row.mblNumber}</>;
            }
          },
        }}
      />
    </SearchResultGroup>
  );
}
