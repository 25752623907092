const env = {
  REACT_APP_BUILD_ENV: process.env.REACT_APP_BUILD_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_SSO_ISSUER: process.env.REACT_APP_SSO_ISSUER,
  REACT_APP_SSO_CLIENT_ID: process.env.REACT_APP_SSO_CLIENT_ID,
  REACT_APP_SSO_SCOPES: process.env.REACT_APP_SSO_SCOPES,
  REACT_APP_SSO_SIGNOUT_URL: process.env.REACT_APP_SSO_SIGNOUT_URL,
};

function assertEnv(key: keyof typeof env): string {
  const val = env[key];
  if (typeof val !== 'string') {
    throw new Error('Missing env var: ' + key);
  }
  return val;
}

function assertEnvEnum<T extends string>(key: keyof typeof env, oneOf: T[]): T {
  const val = assertEnv(key);
  if (oneOf.includes(val as T)) {
    return val as T;
  }
  throw new Error('Env var: ' + key + ' must be one of: ' + oneOf.join(', '));
}

export const API_URL = assertEnv('REACT_APP_API_URL');
export const BUILD_ENV = assertEnvEnum('REACT_APP_BUILD_ENV', [
  'local',
  'dev',
  'staging',
  'production',
]);
export const GRAPHQL_URL = API_URL + '/graphql/'; // the trailing slash is needed b/c ariadne wants it and will redirect away if it doesn't get it

// When changing this also update `py-oms/oms/api/routes/edi/documentation/index.html` since it wants users to be logged in
export const AUTH_LOCAL_STORAGE_KEY = 'oms-auth';

export const SSO_ISSUER = assertEnv('REACT_APP_SSO_ISSUER');
export const SSO_CLIENT_ID = assertEnv('REACT_APP_SSO_CLIENT_ID');
export const SSO_SCOPES = assertEnv('REACT_APP_SSO_SCOPES');
export const SSO_SIGNOUT_URL: string = env.REACT_APP_SSO_SIGNOUT_URL
  ? env.REACT_APP_SSO_SIGNOUT_URL
  : SSO_ISSUER + '/signout';
export const BASE_URL = assertEnv('REACT_APP_BASE_URL');
