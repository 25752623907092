import { Box } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlBooking, PartyType, useShippedListQuery } from 'api/GQL_Types';
import { auth } from 'app';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import { HotState } from 'components/HotToggleSwitch';
import { PanelBase, PanelHeader } from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { Prepend } from 'components/Prepend';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import SearchBar from 'components/SearchBar';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'lib/RecoilUtils';
import { portToStringMaybe } from 'types/Port';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { compareDates, formatDate } from 'types/Date';
import { mapMoveType, mapShipmentStatus } from 'types/OMSEnums';
import { formatNumber } from 'types/Number';
import { shippedListSelectedTab } from '.';
import { BookingConsolidationToggler } from '../BookingConsolidationToggler';

export const shippedSearchState = newAtom('');
export const shippedListState = newAtom<TableRow[]>([]);

export const filteredShippedListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(shippedSearchState);
    const bookings = get(shippedListState);

    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return bookings;
    } else {
      return bookings.filter((row) => row.filterKey.includes(lowerField));
    }
  },
});

const columns: UWLTableColumn<TableRow>[] = [
  { id: 'booking', label: 'Booking', type: 'string', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'bookingStatus', label: 'Status', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'polAtd', label: 'ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'podEta', label: 'ETA', type: 'date' },
  { id: 'podAta', label: 'ATA', type: 'date' },
  { id: 'moveType', label: 'Delivery Type', type: 'string' },
  { id: 'containers', label: 'Equipment (QTY)', type: 'string', whiteSpace: 'pre-wrap' },
  { id: 'deliveryLocationName', label: 'Delivery Loc', type: 'string' },
  { id: 'deliveryEta', label: 'ETA Final Destination', type: 'date' },
  { id: 'deliveryAta', label: 'ATA Final Destination', type: 'date' },
];

const defaultColumnIds = [
  'booking',
  'supplierName',
  'bookingDate',
  'bookingStatus',
  'pol',
  'polEtd',
  'polAtd',
  'pod',
  'podEta',
  'podAta',
  'moveType',
  'containers',
  'deliveryLocationName',
  'deliveryEta',
  'deliveryAta',
];

const factoryColumnIds = [
  'booking',
  'bookingDate',
  'bookingStatus',
  'pol',
  'moveType',
  'containers',
  'polEtd',
  'polAtd',
  'pod',
  'podEta',
  'podAta',
  'deliveryLocationName',
  'deliveryEta',
  'deliveryAta',
];

interface TableRow {
  id: string;
  booking: string;
  supplierName: string;
  bookingDate: Date | null;
  bookingStatus: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  moveType: string;
  containers: string;
  deliveryLocationName: string;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
  hot: HotState;

  filterKey: string; // The text rows can be filtered by with the quick search
}

export interface Props {}
export default function ShippedBookingBrowser(props: Props) {
  const { userContext } = auth.useAuthState();
  const [searchField, setSearchField] = useRecoilState(shippedSearchState);
  const setShippedList = useSetRecoilState(shippedListState);
  const filteredBookings = useRecoilValue(filteredShippedListState);

  const { loading, error } = useShippedListQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      let bookingsList: TableRow[] = [];

      for (let booking of data.shipments) {
        booking = booking as GqlBooking;
        let containers = '';
        if (booking.containers) {
          const nContainersByType = new Map<string, number>();
          booking.containers.forEach((container) => {
            const n = nContainersByType.get(container.containerType) || 0;
            nContainersByType.set(container.containerType, n + 1);
          });
          const lines: string[] = [];
          nContainersByType.forEach((nContainers, type) => {
            lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
          });
          containers = lines.join(',\n');
        }

        bookingsList.push({
          id: booking.id,
          booking: booking.referenceNumber,
          supplierName: relatedPartyNameOrVarious(booking.relatedParties, PartyType.Supplier),
          bookingDate: booking.createDate,
          bookingStatus: mapShipmentStatus(booking.status),
          cargoReadyDate: booking.cargoReadyDate,
          revisedCargoReadyDate: booking.revisedCargoReadyDate,
          pol: portToStringMaybe(booking.logistics.pol),
          polEtd: booking.logistics.polEtd,
          polAtd: booking.logistics.polAtd,
          pod: portToStringMaybe(booking.logistics.pod),
          podEta: booking.logistics.podEta,
          podAta: booking.logistics.podAta,
          moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
          containers: containers,
          deliveryLocationName: booking.logistics.deliveryLocation?.name ?? '',
          deliveryEta: booking.logistics.deliveryEta,
          deliveryAta: booking.logistics.deliveryAta,
          hot: {
            isHot: booking.isHot,
            hotMarkedBy: booking.hotMarkedBy,
            hotMarkedTimestamp: booking.hotMarkedTimestamp,
          },
          filterKey: '', // will get set in the next pass
        });
      }

      bookingsList = bookingsList.map((row) => {
        return {
          ...row,
          filterKey: [
            // Include all the text they can search by
            row.booking,
            row.bookingStatus,
            row.containers,
            row.deliveryLocationName,
            row.moveType,
            row.pod,
            row.pol,
            row.supplierName,
            row.hot,
            formatDate(row.bookingDate),
            formatDate(row.deliveryAta),
            formatDate(row.deliveryEta),
            formatDate(row.podAta),
            formatDate(row.polAtd),
            formatDate(row.polEtd),
          ]
            .join('||||')
            .toLowerCase(),
        };
      });

      bookingsList.sort(function (a, b) {
        return compareDates(b.polAtd, a.polAtd);
      });

      setShippedList(bookingsList);
    },
  });

  const columnsDisplay =
    userContext?.activeContact?.role?.name === 'Factory' // TODO FIXME HACK
      ? factoryColumnIds
      : defaultColumnIds;

  return (
    <PanelBase>
      <RemainingHeightLayout
        top={
          <div>
            <PanelHeader
              title="Shipped Bookings"
              titleDecorator={
                <TableExportWindowGroup
                  label="Bookings"
                  rows={filteredBookings}
                  columns={columnsDisplay.reduce((list, cid) => {
                    return list.concat(columns.filter((c) => c.id === cid));
                  }, [] as UWLTableColumn<TableRow>[])}
                />
              }
              topRight={
                <Box width="25%" bgcolor="#F8F8F8" padding={1}>
                  <SearchBar
                    placeholder="Search by Booking #"
                    field={searchField}
                    updateField={setSearchField}
                  />
                </Box>
              }
            />
            <BookingConsolidationToggler state={shippedListSelectedTab} />
          </div>
        }
        bottomMinHeight={300}
        bottom={
          <PanelBodyTable>
            <UWLTable
              rowId="id"
              columns={columns}
              columnsDisplay={columnsDisplay}
              isLoading={loading}
              error={error ? error + '' : null}
              rows={filteredBookings}
              emptyMessage="- No Bookings Available -"
              virtualize="single-line-cells"
              renderCell={{
                booking(row) {
                  return (
                    <Prepend
                      item={<HotBookingSwitch bookingId={row.id} initialState={row.hot} small />}
                    >
                      <Link to={'/bookings/' + row.id}>{row.booking}</Link>
                    </Prepend>
                  );
                },
              }}
            />
          </PanelBodyTable>
        }
      />
    </PanelBase>
  );
}
