import React from 'react';
import { makeCss } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  top: {
    overflow: 'hidden',
  },
  bottom: {
    display: 'flex',
    overflow: 'hidden',
    minHeight: 0, // NOTE: Don't remove this. This is a silly hack that tells flexbox to not not let this div overflow the panel when the children have their own scroll area.
  },
});

interface Props {
  top: React.ReactNode;
  bottom: React.ReactNode;
  bottomMinHeight: number;
}

export const RemainingHeightLayout: React.FC<Props> = (props) => {
  return (
    <div className={classes.root}>
      <div className={classes.top}>{props.top}</div>
      <div className={classes.bottom} style={{ flex: `1 1 ${props.bottomMinHeight}px` }}>
        {props.bottom}
      </div>
    </div>
  );
};
