import { GqlContainer } from 'api/GQL_Types';
import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { ItemPosition, OpenLineItem } from 'types/VanPosition';
import { BottomHalf } from './ContainerPackingBottomHalf';
import { TopHalf } from './ContainerPackingTopHalf';

const topHalfColumns: UWLTableColumn<ItemPosition>[] = [
  { id: 'packLineId', label: 'Item', type: 'string', whiteSpace: 'nowrap' },
  { id: 'piecesQty', label: 'Qty', type: 'number', align: 'left' },
  { id: 'cartonsQty', label: 'Ctns', type: 'number', align: 'left' },
  { id: 'volumeM3', label: 'Volume', type: 'number', align: 'left' },
  { id: 'weightKg', label: 'Weight', type: 'number', align: 'left' },
  { id: 'vanCode', label: 'VAN Pos', type: 'string' },
];

interface Props {
  allLines: OpenLineItem[];
  containers: GqlContainer[];
  selectedContainerId: string;
  selectedLines: ItemPosition[];
  setSelectedLines: SetterOrUpdater<ItemPosition[]>;
  bottomHalfColumns: UWLTableColumn<OpenLineItem>[];
}

export const ContainerPacking: React.FC<Props> = ({
  allLines,
  containers,
  selectedContainerId,
  selectedLines,
  setSelectedLines,
  bottomHalfColumns,
}) => {
  const [selectedLinesChecked, setSelectedLinesChecked] = React.useState<string[]>([]);
  const [availableLinesChecked, setAvailableLinesChecked] = React.useState<string[]>([]);
  const totalQty: any = {};
  const totalCtns: any = {};

  if (!containers || !selectedContainerId) {
    return null;
  }

  let topHalfIds = selectedLines.map((line) => {
    return line.packLineId;
  });

  let availableLines: OpenLineItem[] = allLines.filter(
    (line) => !topHalfIds.includes(line.packLineId)
  );

  for (const line of availableLines) {
    totalQty[line.packLineId] = line.shippedQty;
    totalCtns[line.packLineId] = line.shippedCtns;
  }

  for (const container of containers) {
    if (container.id === selectedContainerId) {
      for (const line of selectedLines) {
        if (line.packLineId) {
          totalQty[line.packLineId] -= line.piecesQty ? line.piecesQty : 0;
          totalCtns[line.packLineId] -= line.cartonsQty ? line.cartonsQty : 0;
        }
      }
    } else {
      for (const pos of container.vanPositions) {
        totalQty[pos.packLine.id] -= pos.piecesQty;
        totalCtns[pos.packLine.id] -= pos.cartonsQty;
      }
    }
  }

  for (const line of availableLines) {
    if (totalCtns[line.packLineId]) {
      line.balanceCtns = totalCtns[line.packLineId];
    }

    line.balanceQty = totalQty[line.packLineId];
  }

  availableLines = availableLines.filter((line) => line.balanceQty > 0 || line.balanceCtns > 0);

  return (
    <div>
      <TopHalf
        selectedLines={selectedLines}
        setSelectedLines={setSelectedLines}
        selectedLinesChecked={selectedLinesChecked}
        setSelectedLinesChecked={setSelectedLinesChecked}
        columns={topHalfColumns}
      />

      <BottomHalf
        availableLines={availableLines}
        selectedLinesChecked={selectedLinesChecked}
        availableLinesChecked={availableLinesChecked}
        setAvailableLinesChecked={setAvailableLinesChecked}
        columns={bottomHalfColumns}
        onClickAdd={() => {
          const newSelectedLines = [...selectedLines];

          for (const lineId of availableLinesChecked) {
            const line = availableLines.find((line) => line.packLineId === lineId);
            if (line) {
              const alreadyExists = newSelectedLines.find((line) => line.packLineId === lineId);
              if (!alreadyExists) {
                newSelectedLines.push({
                  packLineId: line.packLineId,
                  poNumber: line.poNumber,
                  itemNumber: line.itemNumber,
                  piecesQty: line.balanceQty,
                  cartonsQty: line.balanceCtns,
                  volumeM3: line.volumeM3,
                  weightKg: line.weightKg,
                  vanCode: null,
                });
              }
            }
          }

          setSelectedLines(newSelectedLines);
          setAvailableLinesChecked([]);
        }}
        onClickRemove={() => {
          setSelectedLines(
            selectedLines.filter((line) => {
              return !selectedLinesChecked.includes(line.packLineId);
            })
          );
          setSelectedLinesChecked([]);
        }}
      />
    </div>
  );
};
