import { Box, Typography } from '@material-ui/core';
import { ContainersInTransitDisplay } from 'app/dashboard/states';
import React from 'react';
import { theme } from 'styles';
import { InTransitTablePopover } from '../InTransitTablePopover';

interface Props {
  label: string;
  data: ContainersInTransitDisplay[];
  rowHeight: number;
}

export const LeftLabelBox: React.FC<Props> = (props) => {
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const [openPopover, setOpenPopover2] = React.useState(false);

  return (
    <div
      ref={refAnchor}
      style={{
        display: 'flex',
        padding: '0 24px',
        alignItems: 'center',
        height: props.rowHeight + 'px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.grayscale.light,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ cursor: 'pointer' }}
        onClick={(e) => setOpenPopover2(true)}
      >
        <Typography variant="h4">{props.label}</Typography>
        <Typography variant="h2" color="textPrimary">
          {props.data.length}
        </Typography>
      </Box>
      <InTransitTablePopover
        title={'Total ' + props.label}
        emptyMessage={'No Shipments ' + props.label}
        containers={props.data}
        anchorEl={refAnchor.current}
        open={openPopover}
        onClose={() => setOpenPopover2(false)}
      />
    </div>
  );
};
