import React from 'react';
import { RouteComponentProps } from '@reach/router';
import DocumentsBrowser from './browser';
import { FlexGrowRouter } from 'components/StyledComponents';

interface DocumentsProps extends RouteComponentProps {}

function Documents(props: DocumentsProps) {
  return (
    <FlexGrowRouter>
      <DocumentsBrowser path="/" />
    </FlexGrowRouter>
  );
}

export default Documents;
