import { FormInputNumber } from 'components/form/FormInputNumber';
import React from 'react';

interface Props {
  value: number;
  onValue(value: number): void;
  plusOrMinus?: boolean;
}

export const RuleNumDaysInput: React.FC<Props> = ({ value, onValue, plusOrMinus }) => {
  return (
    <FormInputNumber
      label="Days"
      value={value}
      onValue={(v) => {
        if (plusOrMinus) {
          onValue(Math.abs(v || 0));
        } else {
          onValue(v || 0);
        }
      }}
      startAdornment={plusOrMinus ? '+/-' : undefined}
      endAdornment="days"
      selectAllOnFocus
      maximumFractionDigits={0}
    />
  );
};
