import { GqlAdminPagesContactFragment, NotificationCode } from 'api/GQL_Types';

export interface AdminPagesContact {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phoneMain: string;
  extension: string;
  phoneAlternative: string;
  phoneCell: string;
  isUserEnabled: boolean;
  isMainContact: boolean;
  needsUpdate: boolean;
  location: { id: string; name: string } | null;
  role: { id: string; name: string; notificationCodes: NotificationCode[] } | null;
  notificationCodes: NotificationCode[] | null;
}

export function fromGQL_AdminPagesContact(
  contact: GqlAdminPagesContactFragment
): AdminPagesContact {
  return {
    id: contact.id,
    firstName: contact.firstName || '',
    lastName: contact.lastName || '',
    title: contact.title || '',
    email: contact.email || '',
    phoneMain: contact.phoneMain || '',
    extension: contact.extension || '',
    phoneAlternative: contact.phoneAlternative || '',
    phoneCell: contact.phoneCell || '',
    isUserEnabled: contact.isUserEnabled,
    isMainContact: contact.isMainContact,
    needsUpdate: contact.needsUpdate,
    location: contact.location
      ? { id: contact.location.id, name: contact.location.name || '' }
      : null,
    role: contact.role
      ? {
          id: contact.role.id,
          name: contact.role.name || '',
          notificationCodes: contact.role.notificationCodes,
        }
      : null,
    notificationCodes: contact.notificationCodes || null,
  };
}
