import { AppWideStates } from 'app/states';
import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import { HighlightMatches } from 'components/HighlightMatches';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Country } from 'types/Country';

interface Props {
  value: string | null;
  onValue(v: string | null): void;

  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const FormInputCountry: React.FC<Props> = ({
  value,
  onValue,
  label,
  disabled,
  required,
}) => {
  const countriesData = useRecoilValue(AppWideStates.countries);
  return (
    <FormInputAutocomplete<Country>
      options={countriesData.all}
      label={label}
      value={value ? countriesData.byCode.get(value) || { code: value, name: value } : null}
      onValue={(opt) => {
        onValue(opt?.code || null);
      }}
      disabled={disabled}
      required={required}
      displayResolver={(opt) => `${opt.code} - ${opt.name}`}
      renderOption={(opt, query) => {
        const value = `${opt.code} - ${opt.name}`;
        if (value.toLowerCase().trim() === query) {
          return value;
        }
        return <HighlightMatches str={value} searchStr={query} />;
      }}
      localFilter={(query, opt) => {
        const value = `${opt.code} - ${opt.name}`.trim().toLowerCase();
        return value.includes(query);
      }}
    />
  );
};
