import { gql } from '@apollo/client/core';
import {
  GqlSetActiveUserContactMutation,
  GqlSetActiveUserContactMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const UserContextQuery = gql`
  query UserContext {
    userContext {
      user {
        id
        firstName
        lastName
        title
        email
        phoneMain
        extension
        phoneCell
        phoneAlternative
        profileContacts {
          id
          profile {
            id
            name
            network {
              id
              name
            }
          }
        }
        systemPermissionCodes
      }
      activeContact {
        id
        profile {
          id
          name
          network {
            id
            name
            controllingClient {
              id
              name
            }
          }
        }
        role {
          id
          name
          permissionCodes
          notificationCodes
          dashboardFeatureCodes
        }
      }
    }
  }
`;

export const AllUsersQuery = gql`
  query AllUsers {
    users {
      id
      email
      fullName
      title
      isActive
      systemPermissionCodes
      defaultContact {
        profile {
          network {
            name
          }
        }
      }
      profileContacts {
        isUserEnabled
        profile {
          name
          network {
            name
          }
        }
      }
    }
  }
`;

export const UserQuery = gql`
  query User($userId: ID!) {
    user(userId: $userId) {
      id
      fullName
      firstName
      lastName
      title
      email
      phoneMain
      extension
      phoneCell
      phoneAlternative
      isActive
      systemPermissionCodes
      defaultContact {
        id
        firstName
        lastName
        title
        email
        phoneMain
        phoneAlternative
        extension
        phoneCell
        isMainContact
        isUserEnabled
        profile {
          id
          name
          network {
            id
            name
          }
        }
        location {
          id
          name
        }
      }
      profileContacts {
        id
        firstName
        lastName
        title
        email
        phoneMain
        extension
        phoneCell
        phoneAlternative
        isMainContact
        isUserEnabled
        profile {
          id
          name
          roles {
            id
            name
          }
          network {
            id
            name
            connectedProfiles {
              id
              name
              profileCode
            }
          }
        }
        location {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;

export const MyAccountQuery = gql`
  query MyAccount {
    userContext {
      user {
        id
        firstName
        lastName
        title
        email
        phoneMain
        extension
        phoneCell
        phoneAlternative
        profileContacts {
          id
          notificationCodes
          profile {
            id
            name
            network {
              id
              name
            }
          }
          role {
            id
            name
            notificationCodes
          }
        }
      }
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      message
      user {
        id
        defaultContact {
          id
          firstName
          lastName
          profile {
            id
            name
          }
        }
      }
    }
  }
`;

export const SetActiveUserContactMutation = gql`
  mutation SetActiveUserContact($input: SetActiveUserContactInput!) {
    setActiveUserContact(input: $input) {
      success
      message
    }
  }
`;

export const setActiveUserContact = wrapMutation<
  GqlSetActiveUserContactMutation,
  GqlSetActiveUserContactMutationVariables
>({
  mutation: SetActiveUserContactMutation,
  errorMessage(data, error) {
    if (!data?.setActiveUserContact.success || error) {
      return data?.setActiveUserContact.message || 'Unexpected Error';
    }
  },
});

export const UpdateAccountMutation = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      success
      message
      user {
        id
        firstName
        lastName
        title
        phoneMain
        extension
        phoneCell
        phoneAlternative
      }
    }
  }
`;

export const UpdatePasswordMutation = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
      message
    }
  }
`;
