import { Box, Grid } from '@material-ui/core';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import React from 'react';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey } from 'lib/RecoilUtils';
import { ProfilePageStates } from '../states';
import LocationCard from './LocationCard';
import { LocationDialog_Data, ProfileLocationsDialog_Open } from './ProfileLocationsDialog';
import { profileLocationSearchState } from './ProfileLocationsHeader';
import {
  ReviewProfileLocationDialog_Data,
  ReviewProfileLocationDialog_Open,
} from './ReviewProfileLocationDialog';

export const filteredProfileLocationListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(profileLocationSearchState);
    const profile = get(ProfilePageStates.profile);
    const lowerField = searchField.toLowerCase();

    const locations = profile?.locationList || [];

    if (searchField === '') {
      return locations;
    } else {
      return locations.filter((location) => location.name.toLowerCase().includes(lowerField));
    }
  },
});

export default function ProfileLocationCards() {
  const { userContext } = auth.useAuthState();
  const setEditDialog = useSetRecoilState(ProfileLocationsDialog_Open);
  const setOpenReviewDialog = useSetRecoilState(ReviewProfileLocationDialog_Open);
  const filteredLocations = useRecoilValue(filteredProfileLocationListState);
  const searchField = useRecoilValue(profileLocationSearchState);

  const setDialogLocation = useSetRecoilState(LocationDialog_Data);
  const setReviewProfileLocationDialogData = useSetRecoilState(ReviewProfileLocationDialog_Data);

  return (
    <Box display="flex" width="100%" flexWrap="wrap">
      {filteredLocations.length === 0 && (
        <div>
          {searchField.length > 0 ? (
            <div>No locations matching search: "{searchField}"</div>
          ) : (
            <div>- none -</div>
          )}
        </div>
      )}
      <Grid container spacing={3}>
        {filteredLocations.map((location) => {
          return (
            <Grid key={location.id} item xs={12} sm={6} md={4} xl={3}>
              <LocationCard
                location={location}
                canManage={!!userContext?.permissionCodes.has(PermissionCode.ProfileLocationUpdate)}
                onManageClicked={(location) => {
                  if (location.needsUpdate) {
                    setReviewProfileLocationDialogData(location);
                    setOpenReviewDialog(true);
                  } else {
                    setEditDialog(true);
                    setDialogLocation(location);
                  }
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
