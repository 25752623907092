import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import { DynamicReportPage } from './dynamic';
import { ReportsPage } from './list';
import { ReportPage } from './report';

interface Props extends RouteComponentProps {}

function ReportModule(props: Props) {
  return (
    <FlexGrowRouter>
      <DynamicReportPage path="/dynamic" />
      <ReportPage path="/:id/:step" />
      <ReportPage path="/:id" />
      <ReportsPage path="/" />
    </FlexGrowRouter>
  );
}

export default ReportModule;
