import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    padding: theme.spacing(1, 2),
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});

interface Props {}

/**
 * Used to enforce consistent spacing on on the table portion of a panel
 */
export const PanelBodyTable: React.FC<Props> = (props) => {
  return <div className={classes.root}>{props.children}</div>;
};
