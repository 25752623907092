import React from 'react';
import { Button } from '@material-ui/core';
import { GqlUpdateBookingLogisticsInput, useUpdateBookingLogisticsMutation } from 'api/GQL_Types';
import { useRecoilValue } from 'recoil';
import { BookingQuery } from 'api/queries/bookingQueries';
import { VesselsQuery } from 'api/queries/logisticsQueries';
import { useSnackbar } from 'notistack';
import { BookingPageStates } from '../../states';
import { confirmBookingValidationState } from './states';

interface Props {
  onClick: Function;
}

export default function EditLogisticsSaveButton(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const booking = useRecoilValue(BookingPageStates.booking);
  const confirmationNumber = useRecoilValue(BookingPageStates.editLogistics.confirmationNumber);
  const confirmationDate = useRecoilValue(BookingPageStates.editLogistics.confirmationDate);
  const carrier = useRecoilValue(BookingPageStates.editLogistics.carrier);
  const carrierConfirmationNumber = useRecoilValue(
    BookingPageStates.editLogistics.carrierConfirmationNumber
  );
  const contract = useRecoilValue(BookingPageStates.editLogistics.contract);
  const contractType = useRecoilValue(BookingPageStates.editLogistics.contractType);

  //Hbl Mbl
  const hblNumber = useRecoilValue(BookingPageStates.editLogistics.hbl);
  const hblPaymentType = useRecoilValue(BookingPageStates.editLogistics.hblPaymentType);
  const hblReleaseType = useRecoilValue(BookingPageStates.editLogistics.hblReleaseType);
  const mblNumber = useRecoilValue(BookingPageStates.editLogistics.mbl);
  const mblPaymentType = useRecoilValue(BookingPageStates.editLogistics.mblPaymentType);
  const mblReleaseType = useRecoilValue(BookingPageStates.editLogistics.mblReleaseType);

  const cfsCutoff = useRecoilValue(BookingPageStates.editLogistics.cfsCutoff);
  const cfsReceived = useRecoilValue(BookingPageStates.editLogistics.cfsReceived);
  const cyCutoff = useRecoilValue(BookingPageStates.editLogistics.cyCutoff);
  const vgmCutoff = useRecoilValue(BookingPageStates.editLogistics.vgmCutoff);
  const siCutoff = useRecoilValue(BookingPageStates.editLogistics.siCutoff);
  const isfCutoff = useRecoilValue(BookingPageStates.editLogistics.isfCutoff);

  const pol = useRecoilValue(BookingPageStates.editLogistics.pol);
  const polEtd = useRecoilValue(BookingPageStates.editLogistics.polEtd);
  const polAtd = useRecoilValue(BookingPageStates.editLogistics.polAtd);

  const transitPort = useRecoilValue(BookingPageStates.editLogistics.transitPort);
  const transitPortEta = useRecoilValue(BookingPageStates.editLogistics.transitPortEta);
  const transitPortAta = useRecoilValue(BookingPageStates.editLogistics.transitPortAta);
  const transitPortEtd = useRecoilValue(BookingPageStates.editLogistics.transitPortEtd);
  const transitPortAtd = useRecoilValue(BookingPageStates.editLogistics.transitPortAtd);

  const pod = useRecoilValue(BookingPageStates.editLogistics.pod);
  const podEta = useRecoilValue(BookingPageStates.editLogistics.podEta);
  const ramp = useRecoilValue(BookingPageStates.editLogistics.ramp);
  const rampEta = useRecoilValue(BookingPageStates.editLogistics.rampEta);
  const finalDestination = useRecoilValue(BookingPageStates.editLogistics.finalDestination);
  const deliveryEta = useRecoilValue(BookingPageStates.editLogistics.deliveryEta);

  const motherVessel = useRecoilValue(BookingPageStates.editLogistics.motherVessel);
  const motherVoyage = useRecoilValue(BookingPageStates.editLogistics.motherVoyage);
  const feederVessel = useRecoilValue(BookingPageStates.editLogistics.feederVessel);
  const feederVoyage = useRecoilValue(BookingPageStates.editLogistics.feederVoyage);

  const validation = useRecoilValue(confirmBookingValidationState);

  const [updateLogistics] = useUpdateBookingLogisticsMutation({
    onCompleted: (data) => {
      if (data.updateBookingLogistics.success) {
        enqueueSnackbar('Booking Updated', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(data.updateBookingLogistics.message || 'Failed to save logistics.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to save logistics. ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id ?? '' },
        fetchPolicy: 'network-only',
      },
      {
        query: VesselsQuery,
        fetchPolicy: 'network-only',
      },
    ],
  });

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={
        (!Boolean(motherVessel) && Boolean(motherVoyage)) ||
        (Boolean(motherVessel) && !Boolean(motherVoyage)) ||
        (!Boolean(feederVessel) && Boolean(feederVoyage)) ||
        (Boolean(feederVessel) && !Boolean(feederVoyage)) ||
        !validation.isAllowedToSave
      }
      onClick={() => {
        // GraphQL will not set IS_SET property if the value is undefined and there are fields that
        // should have the ability to be cleared out (null value). See OMS-329
        const input: GqlUpdateBookingLogisticsInput = {
          bookingId: booking?.id ?? '',
          logistics: {
            id: booking?.logistics.id,
            confirmationNumber: confirmationNumber,
            confirmationDate: confirmationDate || null,
            carrierId: carrier ? carrier.id : null,
            carrierConfirmationNumber: carrierConfirmationNumber,
            contractNumber: contract,
            contractType: contractType,
            cfsCutoffDate: cfsCutoff || null,
            cfsReceivedDate: cfsReceived || null,
            cyCutoffDate: cyCutoff || null,
            vgmCutoffDate: vgmCutoff || null,
            siCutoffDate: siCutoff || null,
            isfCutoffDate: isfCutoff || null,
            polCode: pol ? pol.code : null,
            polEtd: polEtd || null,
            polAtd: polAtd || null,
            transitPortCode: transitPort ? transitPort.code : null,
            transitPortEta: transitPortEta || null,
            transitPortAta: transitPortAta || null,
            transitPortEtd: transitPortEtd || null,
            transitPortAtd: transitPortAtd || null,
            podCode: pod ? pod.code : null,
            podEta: podEta || null,
            rampCode: ramp ? ramp.code : null,
            rampEta: rampEta || null,
            finalDestinationId: finalDestination ? finalDestination.id : null,
            deliveryEta: deliveryEta || null,
          },
          hbl: hblNumber
            ? {
                id: booking?.hbl?.id,
                referenceNumber: hblNumber,
                releaseType: hblReleaseType,
                paymentType: hblPaymentType,
              }
            : undefined,
          mbl: mblNumber
            ? {
                id: booking?.mbl?.id,
                referenceNumber: mblNumber,
                releaseType: mblReleaseType,
                paymentType: mblPaymentType,
              }
            : undefined,
          motherVessel: {
            // always send up an id, even if it's null. This way the backend can know when to remove it - OMS-104
            id: motherVessel?.id,
            name: motherVessel?.name,
          },
          motherVoyage: {
            id: motherVoyage?.id,
            name: motherVoyage?.name,
          },
          feederVessel: {
            id: feederVessel?.id,
            name: feederVessel?.name,
          },
          feederVoyage: {
            id: feederVoyage?.id,
            name: feederVoyage?.name,
          },
        };

        updateLogistics({
          variables: {
            input: input,
          },
        });

        props.onClick();
      }}
    >
      Save
    </Button>
  );
}
