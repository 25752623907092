import {
  GqlBooking,
  GqlContainer,
  GqlDashboardBookingSummary,
  GqlPackLine,
  GqlShipmentsInTransitQuery,
  OrderStatus,
  PartyType,
  ShipmentInTransitMilestone,
  ShipmentInTransitStatus,
} from 'api/GQL_Types';
import { DateRangeValue } from 'components/form/FormInputDateRange';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { portToStringMaybe } from 'types/Port';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { mapShipmentStatus } from 'types/OMSEnums';
import { NewBookingNotificationDisplay } from './alerts/buttons/NewBookingsButton';
import { NewConsolidationNotificationDisplay } from './alerts/buttons/NewConsolidationsButton';
import { NewDocumentNotificationDisplay } from './alerts/buttons/NewDocumentsButton';
import {
  DashExceptions,
  emptyState as DashExceptionsEmptyState,
} from './dashboard-exceptions/exceptions';

export interface PoTable {
  id: string;
  lineStatus: OrderStatus;
  po: { id: string; poNumber: string };
  poLink: TableLink;
  itemNumber: string;
  shipToLocationName: string;
  qty: number;
  vendor: String;
  expectedCargoReadyDate: Date | null | undefined;
  indcDate: Date | null | undefined;
  poValue: number | null | undefined;
  poValueCurrencyCode: string | null | undefined;
}

export interface ShipmentStatuses {
  early: SitContainer[];
  onTime: SitContainer[];
  late: SitContainer[];
}

export interface Milestones {
  onWater: ShipmentStatuses;
  atDischarge: ShipmentStatuses;
  atRamp: ShipmentStatuses;
  delivered: ShipmentStatuses;
  totals: ShipmentStatuses;
}

export interface TableLink {
  to: string;
  value: string;
}

export interface DashboardContainer {
  id: string;
  shipmentId: string;
  equipmentNumber: TableLink;
  equipmentType: string;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  pos: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
  qty: number;
}

export interface DashboardSkuItem {
  id: string;
  pos: TableLink;
  sku: string;
  lineNumber: number;
  shippedQty: number;
  booking: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface DashboardBooking {
  id: string;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface DashboardInvoiceItem {
  id: string;
  cost: number;
  pos: TableLink;
  sku: string;
  lineNumber: number;
  shippedQty: number;
  booking: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  equipment: TableLink[];
  shipper: string;
  origin: string;
  destination: string;
}

export interface ContainersInTransitDisplay {
  id: string;
  unread: boolean;
  consignee: string;
  bl: TableLink;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  shipToLocation: string;
  equipment: TableLink;
  invoice: number;
  carrier: string;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  ramp: string;
  rampEta: Date | null | undefined;
  rampAta: Date | null | undefined;
  deliveryLocation: string;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export interface ShipmentInTransit {
  container: SitContainer;
  isDueToArrive: boolean;
  milestone: ShipmentInTransitMilestone;
  status: ShipmentInTransitStatus;
}

export interface SitContainer {
  id: string;
  containerNumber?: string | null;
  ramp: string;
  rampEta: Date | null | undefined;
  rampAta: Date | null | undefined;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
  vanPositions: SitContainerVanPosition[];
  shipment: SitContainerShipment;
  consigneeName: string;
}

export interface SitContainerVanPosition {
  commercialInvoiceTotal?: number | null;
  packLine: SitContainerVanPositionPackLine;
}

export interface SitContainerVanPositionPackLine {
  booking: SitContainerVanPositionPackLineBooking;
}

export interface SitContainerVanPositionPackLineBooking {
  hbl?: SitContainerShipmentBillOfLading | null;
}

export interface SitContainerShipment {
  booking: SitContainerShipmentBooking;
  consolidation: SitContainerShipmentConsolidation;
  logistics: SitContainerShipmentLogistics;
}

export interface SitContainerShipmentBooking {
  cargoReadyDate: Date | null;
  revisedCargoReadyDate?: Date | null;
  hbl: SitContainerShipmentBillOfLading;
  mbl: SitContainerShipmentBillOfLading;
}

export interface SitContainerShipmentConsolidation {
  mbl: SitContainerShipmentBillOfLading;
}

export interface SitContainerShipmentRelatedParty {
  partyType: PartyType;
  party: SitContainerShipmentRpParty;
}

export interface SitContainerShipmentRpParty {
  name: string;
}

export interface SitContainerShipmentLogistics {
  pol?: string;
  polEtd?: Date | null;
  polAtd?: Date | null;
  pod?: string;
  podEta?: Date | null;
  podAta?: Date | null;
  finalDestination: SitContainerShipmentLogisticsFinalDestination;
  carrier: SitContainerShipmentLogisticsCarrier;
  deliveryLocation: SitContainerShipmentLogisticsDeliveryLocation;
}

export interface SitContainerShipmentLogisticsFinalDestination {
  name: string | undefined;
}

export interface SitContainerShipmentLogisticsCarrier {
  name: string | undefined;
}

export interface SitContainerShipmentLogisticsDeliveryLocation {
  name: string | undefined;
}

export interface SitContainerShipmentBillOfLading {
  id: string | undefined;
  referenceNumber: string | undefined;
}

export const DashboardPageStates = {
  po: {
    openPos: newAtom<PoTable[]>([]),
    expiredPos: newAtom<PoTable[]>([]),
    criticalPos: newAtom<PoTable[]>([]),
  },
  inTransit: newAtom<ShipmentInTransit[]>([]),
  exceptions: newAtom<DashExceptions>(DashExceptionsEmptyState()),
  booking: {
    bookingSummaries: newAtom<GqlDashboardBookingSummary[]>([]),
    bookingSummariesVersionTimestamp: newAtom<number>(0), // hack to trigger a useEffect
    trimmedWeeks: newAtom<GqlDashboardBookingSummary[]>([]),
    dateRange: newAtom<DateRangeValue>({
      type: 'plus-minus-n-days',
      nDays: 30,
    }),
  },
  notifications: {
    newBookings: newAtom<NewBookingNotificationDisplay[]>([]),
    newDocuments: newAtom<NewDocumentNotificationDisplay[]>([]),
    newConsolidations: newAtom<NewConsolidationNotificationDisplay[]>([]),
  },
  dashboardInitialLoadStats: newAtom<{
    isLoading: boolean;
    loadingError: string | null;

    nPosOpen: number;
    nPosCritical: number;
    nPosExpired: number;
  }>({
    isLoading: true,
    loadingError: null,

    nPosOpen: 0,
    nPosCritical: 0,
    nPosExpired: 0,
  }),
};

interface ExtTableLink extends TableLink {
  containerType: string;
  qty: number;
}

export function useInTransitData() {
  const setInTransit = useSetRecoilState(DashboardPageStates.inTransit);

  return (init: GqlShipmentsInTransitQuery) => {
    let shipmentsInTransit: ShipmentInTransit[] = [];

    for (const sit of init.shipmentsInTransit) {
      const consigneeNames = new Set<string>();
      for (const vp of sit.container.vanPositions) {
        for (const rp of vp.packLine.booking.relatedParties) {
          if (rp.partyType === PartyType.Consignee) {
            consigneeNames.add(rp.party.name);
          }
        }
      }

      let newShipmentInTransit: ShipmentInTransit = {
        container: {
          id: sit.container.id,
          containerNumber: sit.container.containerNumber,
          ramp: portToStringMaybe(sit.container.ramp),
          rampEta: sit.container.rampEta,
          rampAta: sit.container.rampAta,
          deliveryEta: sit.container.deliveryEta,
          deliveryAta: sit.container.deliveryAta,
          vanPositions: sit.container.vanPositions,
          consigneeName:
            consigneeNames.size === 0
              ? ''
              : consigneeNames.size === 1
              ? Array.from(consigneeNames)[0]
              : 'Various',
          shipment: {
            booking: {
              cargoReadyDate:
                sit.container.shipment.__typename === 'Booking'
                  ? sit.container.shipment.cargoReadyDate
                  : null,
              revisedCargoReadyDate:
                sit.container.shipment.__typename === 'Booking'
                  ? sit.container.shipment.revisedCargoReadyDate
                  : null,
              hbl: {
                id:
                  sit.container.shipment.__typename === 'Booking'
                    ? sit.container.shipment.hbl?.id
                    : '',
                referenceNumber:
                  sit.container.shipment.__typename === 'Booking'
                    ? sit.container.shipment.hbl?.referenceNumber
                    : '',
              },
              mbl: {
                id:
                  sit.container.shipment.__typename === 'Booking'
                    ? sit.container.shipment.mbl?.id
                    : '',
                referenceNumber:
                  sit.container.shipment.__typename === 'Booking'
                    ? sit.container.shipment.mbl?.referenceNumber
                    : '',
              },
            },
            consolidation: {
              mbl: {
                id:
                  sit.container.shipment.__typename === 'Consolidation'
                    ? sit.container.shipment.mbl?.id
                    : '',
                referenceNumber:
                  sit.container.shipment.__typename === 'Consolidation'
                    ? sit.container.shipment.mbl?.referenceNumber
                    : '',
              },
            },
            logistics: {
              pol: portToStringMaybe(sit.container.shipment.logistics.pol),
              polEtd: sit.container.shipment.logistics.polEtd,
              polAtd: sit.container.shipment.logistics.polAtd,
              pod: portToStringMaybe(sit.container.shipment.logistics.pod),
              podEta: sit.container.shipment.logistics.podEta,
              podAta: sit.container.shipment.logistics.podAta,
              finalDestination: {
                name: sit.container.shipment.logistics.finalDestination?.name,
              },
              carrier: {
                name: sit.container.shipment.logistics.carrier?.name,
              },
              deliveryLocation: {
                name: sit.container.shipment.logistics.deliveryLocation?.name,
              },
            },
          },
        },
        isDueToArrive: sit.isDueToArrive,
        milestone: sit.milestone,
        status: sit.status,
      };

      shipmentsInTransit.push(newShipmentInTransit);
    }

    setInTransit(shipmentsInTransit);
  };
}

export const transformBookingToDashboardBookingDisplay = (
  bookings: GqlBooking[]
): DashboardBooking[] => {
  const data: DashboardBooking[] = [];

  for (const booking of bookings) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];
    for (const container of booking.containers) {
      if (container.containerNumber) {
        assigned.push({
          to: '/equipment/' + container.id,
          value: container.containerNumber ?? '',
          qty: 1,
          containerType: container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${container.containerType} (QTY: ${1})`,
            containerType: container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];
    data.push({
      id: booking.id,
      bookingNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
      bookingDate: booking.createDate,
      status: mapShipmentStatus(booking.status),
      cargoReadyDate: booking.cargoReadyDate,
      revisedCargoReadyDate: booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper: relatedPartyNameOrVarious(booking.relatedParties, PartyType.Shipper),
      origin: booking.logistics.pol?.name ?? '',
      destination: booking.logistics.pod?.name ?? '',
    });
  }
  return data;
};

export const transformContainerToDashboardContainerDisplay = (
  containers: GqlContainer[]
): DashboardContainer[] => {
  let assigned: DashboardContainer[] = [];
  const unassigned: DashboardContainer[] = [];
  // TODO Sort and find unassigned ones

  for (const container of containers) {
    if (container.containerNumber) {
      assigned.push({
        id: container.id,
        shipmentId: container.shipment.id,
        equipmentNumber: {
          to: `/equipment/${container.id}`,
          value: container.containerNumber ?? '',
        },
        equipmentType: container.containerType,
        bookingNumber: {
          to: `/bookings/${container.shipment.id}`,
          value: container.shipment.referenceNumber,
        },
        bookingDate: container.shipment.createDate,
        status: mapShipmentStatus(container.shipment.status),
        cargoReadyDate: (container.shipment as GqlBooking).cargoReadyDate,
        revisedCargoReadyDate: (container.shipment as GqlBooking).revisedCargoReadyDate,
        pos: container.vanPositions.map((vp) => {
          return {
            to: `/purchase-orders/${vp.packLine.orderLine.purchaseOrder.id}`,
            value: vp.packLine.orderLine.purchaseOrder.poNumber,
          };
        }),
        shipper: relatedPartyNameOrVarious(container.shipment.relatedParties, PartyType.Shipper),
        origin: container.shipment.logistics.pol?.name ?? '',
        destination: container.shipment.logistics.pod?.name ?? '',
        qty: 1,
      });
    } else {
      unassigned.push({
        id: container.id,
        shipmentId: container.shipment.id,
        equipmentNumber: {
          to: ``,
          value: 'QTY: ',
        },
        equipmentType: container.containerType,
        bookingNumber: {
          to: `/bookings/${container.shipment.id}`,
          value: container.shipment.referenceNumber,
        },
        bookingDate: container.shipment.createDate,
        status: mapShipmentStatus(container.shipment.status),
        cargoReadyDate: (container.shipment as GqlBooking).cargoReadyDate,
        revisedCargoReadyDate: (container.shipment as GqlBooking).revisedCargoReadyDate,
        pos: container.vanPositions.map((vp) => {
          return {
            to: `/purchase-orders/${vp.packLine.orderLine.purchaseOrder.id}`,
            value: vp.packLine.orderLine.purchaseOrder.poNumber,
          };
        }),
        shipper: relatedPartyNameOrVarious(container.shipment.relatedParties, PartyType.Shipper),
        origin: container.shipment.logistics.pol?.name ?? '',
        destination: container.shipment.logistics.pod?.name ?? '',
        qty: 1,
      });
    }
  }

  const uniques: DashboardContainer[] = [];

  for (const unassignedContainer of unassigned) {
    const dataFound = uniques.find(
      (container) =>
        container.shipmentId === unassignedContainer.shipmentId &&
        container.equipmentType === unassignedContainer.equipmentType
    );
    if (!dataFound) {
      uniques.push(unassignedContainer);
    } else {
      dataFound.qty++;
    }
  }

  for (const unique of uniques) {
    unique.equipmentNumber.value = 'QTY: ' + unique.qty;
  }

  assigned = assigned.concat(uniques);

  assigned = assigned.sort((a, b) => {
    const aDate = a.bookingDate?.getTime() || 0;
    const bDate = b.bookingDate?.getTime() || 0;
    if (aDate < bDate) {
      return -1;
    } else if (aDate > bDate) {
      return 1;
    } else {
      return 0;
    }
  });

  return assigned;
};

export const transformPackLineToSkuItemsDisplay = (
  packLines: GqlPackLine[]
): DashboardSkuItem[] => {
  const dialogData: DashboardSkuItem[] = [];
  for (const packline of packLines) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];

    for (const van of packline.vanPositions) {
      if (van.container.containerNumber) {
        assigned.push({
          to: '/equipment/' + van.container.id,
          value: van.container.containerNumber ?? '',
          qty: 1,
          containerType: van.container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === van.container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${van.container.containerType} (QTY: ${1})`,
            containerType: van.container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${van.container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];

    dialogData.push({
      id: packline.id,
      pos: {
        to: `/purchase-orders/${packline.orderLine.purchaseOrder.id}`,
        value: packline.orderLine.purchaseOrder.poNumber,
      },
      sku: packline.orderLine.itemNumber,
      lineNumber: packline.lineNumber,
      shippedQty: packline.shippedQty,
      booking: { to: `/bookings/${packline.booking.id}`, value: packline.booking.referenceNumber },
      bookingDate: packline.booking.createDate,
      status: mapShipmentStatus(packline.booking.status),
      cargoReadyDate: packline.booking.cargoReadyDate,
      revisedCargoReadyDate: packline.booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper: relatedPartyNameOrVarious(packline.booking.relatedParties, PartyType.Shipper),
      origin: packline.booking.logistics.pol?.name ?? '',
      destination: packline.booking.logistics.pod?.name ?? '',
    });
  }

  return dialogData;
};

export const transformPackLineToInvoiceItemDisplay = (
  packLines: GqlPackLine[]
): DashboardInvoiceItem[] => {
  const data: DashboardInvoiceItem[] = [];

  for (const packLine of packLines) {
    const assigned: ExtTableLink[] = [];
    const unassigned: ExtTableLink[] = [];
    for (const van of packLine.vanPositions) {
      if (van.container.containerNumber) {
        assigned.push({
          to: '/equipment/' + van.container.id,
          value: van.container.containerNumber ?? '',
          qty: 1,
          containerType: van.container.containerType,
        });
      } else {
        const found = unassigned.find((e) => e.containerType === van.container.containerType);
        if (!found) {
          unassigned.push({
            to: '',
            value: `${van.container.containerType} (QTY: ${1})`,
            containerType: van.container.containerType,
            qty: 1,
          });
        } else {
          found.qty++;
          found.value = `${van.container.containerType} (QTY: ${found.qty})`;
        }
      }
    }

    const equipment: ExtTableLink[] = [...assigned, ...unassigned];
    data.push({
      id: packLine.id,
      cost: packLine.linePrice ?? 0,
      pos: {
        to: `/purchase-orders/${packLine.orderLine.purchaseOrder.id}`,
        value: packLine.orderLine.purchaseOrder.poNumber,
      },
      sku: packLine.orderLine.itemNumber,
      lineNumber: packLine.lineNumber,
      shippedQty: packLine.shippedQty,
      booking: { to: `/bookings/${packLine.booking.id}`, value: packLine.booking.referenceNumber },
      bookingDate: packLine.booking.createDate,
      status: mapShipmentStatus(packLine.booking.status),
      cargoReadyDate: packLine.booking.cargoReadyDate,
      revisedCargoReadyDate: packLine.booking.revisedCargoReadyDate,
      equipment: equipment,
      shipper: relatedPartyNameOrVarious(packLine.booking.relatedParties, PartyType.Shipper),
      origin: packLine.booking.logistics.pol?.name ?? '',
      destination: packLine.booking.logistics.pod?.name ?? '',
    });
  }
  return data;
};

export const transformContainerToShipmentInTransitDisplay = (
  containers: SitContainer[]
): ContainersInTransitDisplay[] => {
  const displayArray: ContainersInTransitDisplay[] = containers.map(
    (container): ContainersInTransitDisplay => {
      const logistics = container.shipment.logistics;
      const shipmentConsolidation = container.shipment.consolidation;
      const shipmentBooking = container.shipment.booking;

      let containerHbls: TableLink[] = [];
      for (const vp of container.vanPositions) {
        const referenceNumber = vp.packLine.booking.hbl?.referenceNumber;
        if (referenceNumber) {
          let index = containerHbls.findIndex((x) => x.value === referenceNumber);
          if (index === -1) {
            containerHbls.push({
              to: `/hbl/${vp.packLine.booking.hbl?.id ?? ''}`,
              value: vp.packLine.booking.hbl?.referenceNumber ?? '',
            });
          }
        }
      }

      let blValue: TableLink = { to: '', value: '' };

      if (containerHbls.length > 1) {
        blValue = {
          to: '',
          value: 'Various',
        };
      } else if (containerHbls.length === 1) {
        blValue = containerHbls[0];
      } else if (containerHbls.length === 0 && shipmentConsolidation.mbl) {
        blValue = {
          to: `/mbl/${shipmentConsolidation.mbl?.id ?? ''}`,
          value: shipmentConsolidation.mbl?.referenceNumber ?? '',
        };
      } else if (containerHbls.length === 0 && shipmentBooking.mbl) {
        blValue = {
          to: `/mbl/${shipmentConsolidation.mbl?.id ?? ''}`,
          value: shipmentConsolidation.mbl?.referenceNumber ?? '',
        };
      }

      return {
        unread: true,
        id: container.id,
        consignee: container.consigneeName,
        bl: blValue,
        cargoReadyDate: shipmentBooking.cargoReadyDate,
        revisedCargoReadyDate: shipmentBooking.revisedCargoReadyDate,
        shipToLocation: logistics.finalDestination?.name ?? '',
        equipment: {
          to: `/equipment/${container.id ?? ''}`,
          value: container.containerNumber ?? '',
        },
        invoice: container.vanPositions.reduce(
          (previousValue, position) => previousValue + (position.commercialInvoiceTotal ?? 0),
          0
        ),
        carrier: logistics.carrier?.name ?? '',
        pol: logistics.pol ?? '',
        polEtd: logistics.polEtd,
        polAtd: logistics.polAtd,
        pod: logistics.pod ?? '',
        podEta: logistics.podEta,
        podAta: logistics.podAta,
        ramp: container.ramp,
        rampEta: container.rampEta,
        rampAta: container.rampAta,
        deliveryLocation: logistics.finalDestination?.name ?? '',
        deliveryEta: container.deliveryEta,
        deliveryAta: container.deliveryAta,
      };
    }
  );

  return displayArray;
};
