import React from 'react';
import { Box, Typography } from '@material-ui/core';
import EquipmentFormFields from './EquipmentFormFields';

export default function ContainerInformation() {
  return (
    <Box display="flex" marginY={1} flexDirection="column">
      <Typography variant="h3" color="secondary">
        Container Information
      </Typography>
      <Box margin="8px 24px">
        <EquipmentFormFields />
      </Box>
    </Box>
  );
}
