import * as React from 'react';

export function useDivDimensions(): {
  divEl: (node: any) => void;
  width: number;
  height: number;
} {
  const [divDimensions, setDivDimensions] = React.useState<[number, number]>([0, 0]);

  const divEl: (node: any) => void = React.useCallback((node) => {
    if (node !== null) {
      setDivDimensions([node.getBoundingClientRect().width, node.getBoundingClientRect().height]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { divEl, width: divDimensions[0], height: divDimensions[1] };
}
