import { Link } from '@reach/router';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { SearchResultGroup } from './SearchResultGroup';
import { QuickSearchPoResult, SearchPageStates } from './states';

const columns: UWLTableColumn<QuickSearchPoResult>[] = [
  { id: 'poNumber', label: 'PO Number', type: 'string' },
  { id: 'poDate', label: 'PO Date', type: 'date' },
  { id: 'supplierName', label: 'Supplier Name', type: 'string' },
  { id: 'itemNumber', label: 'Item Number', type: 'string' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'firstShipDate', label: 'Fist Ship Date', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship Date', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship To Loc', type: 'string' },
];

interface Props {}

export default function SearchResultPurchaseOrders(props: Props) {
  const searchPageData = useRecoilValue(SearchPageStates.data);

  return (
    <SearchResultGroup title="Purchase Orders" count={searchPageData.poCount}>
      <UWLTable
        rowId="orderLineId"
        rows={searchPageData.purchaseOrders}
        columns={columns}
        renderCell={{
          poNumber(row) {
            return <Link to={'/purchase-orders/' + row.id}>{row.poNumber}</Link>;
          },
        }}
      />
    </SearchResultGroup>
  );
}
