import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeCss, theme } from 'styles';
import clsx from 'clsx';

const classes = makeCss({
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: 40,
    overflow: 'hidden',
  },
  rowStripe: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grayscale.light,
    },
  },
  value: {
    lineHeight: 1.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    color: '#000',
  },
});

export interface Props<T> {
  label?: string;
  value?: T | null;
  decorator?: (value: T) => any;
  customLabel?: () => void;
  customValue?: (value?: T) => void;
  noUnderline?: boolean;
  justifyContent?: string;
  noSpacing?: boolean;
  disableStripe?: boolean;
}

export default function LineItem<T = any>(props: Props<T>) {
  return (
    <Box
      className={clsx(classes.lineItem, props.disableStripe ? '' : classes.rowStripe)}
      justifyContent={props.justifyContent ? props.justifyContent : 'space-between'}
      borderBottom={props.noUnderline ? undefined : '1px solid' + theme.palette.grayscale.main}
      paddingBottom={props.noUnderline ? undefined : 1}
      paddingTop={1}
      color="inherit"
    >
      {props.customLabel && !props.label ? (
        props.customLabel()
      ) : (
        <Typography
          color="inherit"
          style={{ padding: props.noSpacing ? '0px' : '8px' }}
          variant="h4"
          align="left"
        >
          {props.label}
        </Typography>
      )}

      {props.customValue && props.value ? (
        props.customValue(props.value)
      ) : (
        <Box display="flex" alignItems="center" marginRight={1}>
          <Typography variant="body1" className={classes.value} align="right">
            {props.decorator && props.value ? props.decorator(props.value) : props.value}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
