import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputText } from 'components/form/FormInputText';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { NetworkAdminPageStates, NetworkContainerType } from '../states';

interface Props {
  canEdit: boolean;
}

const columns: UWLTableColumn<NetworkContainerType>[] = [
  { id: 'code', label: 'Code', type: 'string', whiteSpace: 'nowrap' },
  { id: 'name', label: 'Name', type: 'string', whiteSpace: 'nowrap' },
  { id: 'ruleMinVolumeM3', label: 'Min Volume', type: 'volume', align: 'left' },
  { id: 'ruleMaxVolumeM3', label: 'Max Volume', type: 'volume', align: 'left' },
  { id: 'capacityVolumeM3', label: 'Volume Capacity', type: 'volume', align: 'left' },
  { id: 'ruleMinWeightKg', label: 'Min Weight', type: 'weight', align: 'left' },
  { id: 'ruleMaxWeightKg', label: 'Max Weight', type: 'weight', align: 'left' },
  { id: 'capacityWeightKg', label: 'Weight Capacity', type: 'weight', align: 'left' },
];

export const EquipmentTypeTableForm: React.FC<Props> = ({ canEdit }) => {
  const [containerTypes, setContainerTypes] = useRecoilState(
    NetworkAdminPageStates.containerTypeForm
  );

  function updateContainerType(
    code: string,
    updater: (old: NetworkContainerType) => NetworkContainerType
  ) {
    setContainerTypes(
      containerTypes.map((ct) => {
        if (ct.code === code) {
          return updater(ct);
        }
        return ct;
      })
    );
  }

  return (
    <UWLTable
      rowId="code"
      columns={columns}
      rows={containerTypes}
      emptyMessage="- No Equipment Types -"
      renderCell={{
        name(row) {
          return (
            <div style={{ marginTop: 1 }}>
              <FormInputText
                value={row.name}
                onValue={(name) => updateContainerType(row.code, (ct) => ({ ...ct, name }))}
                size="xs"
                disabled={!canEdit}
              />
            </div>
          );
        },
        capacityWeightKg(row) {
          return (
            <WeightInput
              value={row.capacityWeightKg}
              onValue={(capacityWeightKg) =>
                updateContainerType(row.code, (ct) => ({ ...ct, capacityWeightKg }))
              }
              disabled={!canEdit}
            />
          );
        },
        capacityVolumeM3(row) {
          return (
            <VolumeInput
              value={row.capacityVolumeM3}
              onValue={(capacityVolumeM3) =>
                updateContainerType(row.code, (ct) => ({ ...ct, capacityVolumeM3 }))
              }
              disabled={!canEdit}
            />
          );
        },
        ruleMinVolumeM3(row) {
          return (
            <VolumeInput
              value={row.ruleMinVolumeM3}
              onValue={(ruleMinVolumeM3) =>
                updateContainerType(row.code, (ct) => ({ ...ct, ruleMinVolumeM3 }))
              }
              disabled={!canEdit}
            />
          );
        },
        ruleMaxVolumeM3(row) {
          return (
            <VolumeInput
              value={row.ruleMaxVolumeM3}
              onValue={(ruleMaxVolumeM3) =>
                updateContainerType(row.code, (ct) => ({ ...ct, ruleMaxVolumeM3 }))
              }
              disabled={!canEdit}
            />
          );
        },
        ruleMinWeightKg(row) {
          return (
            <WeightInput
              value={row.ruleMinWeightKg}
              onValue={(ruleMinWeightKg) =>
                updateContainerType(row.code, (ct) => ({ ...ct, ruleMinWeightKg }))
              }
              disabled={!canEdit}
            />
          );
        },
        ruleMaxWeightKg(row) {
          return (
            <WeightInput
              value={row.ruleMaxWeightKg}
              onValue={(ruleMaxWeightKg) =>
                updateContainerType(row.code, (ct) => ({ ...ct, ruleMaxWeightKg }))
              }
              disabled={!canEdit}
            />
          );
        },
      }}
      rowAction={
        canEdit
          ? (row) => {
              return (
                <IconButton
                  aria-label="delete"
                  style={{ width: '10px', height: '10px' }}
                  onClick={() =>
                    setContainerTypes(containerTypes.filter((ct) => ct.code !== row.code))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              );
            }
          : undefined
      }
    />
  );
};

const WeightInput: React.FC<{
  value: number;
  onValue(value: number): void;
  disabled?: boolean;
}> = ({ value, onValue, disabled }) => {
  return (
    <div style={{ marginTop: 1, width: 120 }}>
      <FormInputNumber
        value={value}
        onValue={onValue}
        size="xs"
        minimumFractionDigits={0}
        maximumFractionDigits={2}
        endAdornment="kg"
        selectAllOnFocus
        step={1000}
        disabled={disabled}
      />
    </div>
  );
};

const VolumeInput: React.FC<{
  value: number;
  onValue(value: number): void;
  disabled?: boolean;
}> = ({ value, onValue, disabled }) => {
  return (
    <div style={{ marginTop: 1, width: 120 }}>
      <FormInputNumber
        value={value}
        onValue={onValue}
        size="xs"
        minimumFractionDigits={0}
        maximumFractionDigits={2}
        endAdornment="cbm"
        selectAllOnFocus
        step={1}
        disabled={disabled}
      />
    </div>
  );
};
