import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { auth } from 'app';
import { GRAPHQL_URL } from 'env';
import { getAuthorizationHeader } from './Auth';
import { customScalarLink } from './customScalarLink';

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const logoutLink = onError((res) => {
  if (isNotAuthenticatedError(res)) {
    auth.goToSignIn();
  }
});

function isNotAuthenticatedError(res: ErrorResponse): boolean {
  if (res.networkError && (res.networkError as any).statusCode === 401) {
    return true;
  }
  if (res.graphQLErrors && Array.isArray(res.graphQLErrors)) {
    for (const err of res.graphQLErrors) {
      if (err && err.not_authenticated === true) {
        return true;
      }
    }
  }
  return false;
}

export const gqlClient = new ApolloClient({
  connectToDevTools: true,
  link: from([
    authLink,
    customScalarLink,
    logoutLink,

    // createUploadLink must be last
    createUploadLink({ uri: GRAPHQL_URL }),
  ]),
  cache: new InMemoryCache({}),
});
