import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    width: '-webkit-fill-available',
    margin: theme.spacing(1, 3, 1, 0),
    flexGrow: 1,
  },
  noMarginY: {
    marginTop: 0,
    marginBottom: 0,
  },
});

interface Props {
  maxWidth?: number;
  noMarginY?: boolean;
}

export const FormItem: React.FC<Props> = ({ maxWidth, noMarginY, children }) => {
  return (
    <div
      className={clsx(classes.root, noMarginY && classes.noMarginY)}
      style={maxWidth ? { maxWidth } : undefined}
    >
      {children}
    </div>
  );
};
