import { Box, Button, IconButton, MenuItem, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu/Menu';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import VertDots from '@material-ui/icons/MoreVert';
import {
  GqlContainer,
  GqlVanPosition,
  PermissionCode,
  ShipmentStatus,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { ConsolidationPageStates } from 'app/consolidations/details/states';
import UniversalDialog from 'components/UniversalDialog';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatDate } from 'types/Date';
import { mapVanCode } from 'types/OMSEnums';

interface ExtendedVanPosition {
  id: string;
  line: string;
  po: string;
  itemNumber: string;
  description: string;
  shippedQty: number;
  shippedCtns: number;
  vanCode: string;
  volume: number;
  weight: number;
  volumeUnit: VolumeUnit | null | undefined;
  weightUnit: WeightUnit | null | undefined;
}

const columns: UWLTableColumn<ExtendedVanPosition>[] = [
  { id: 'line', label: 'Line', type: 'string' },
  { id: 'po', label: 'PO', type: 'string' },
  { id: 'itemNumber', label: 'Item/SKU', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'shippedCtns', label: 'Shipped CTNS', type: 'number' },
  { id: 'vanCode', label: 'Van Position', type: 'string' },
  { id: 'volume', label: 'Volume', type: 'volume', unitField: 'volumeUnit' },
  { id: 'weight', label: 'Weight', type: 'weight', unitField: 'weightUnit' },
];

interface Props {
  container: GqlContainer;
  onContainerSelected: (container: GqlContainer) => void;
  onContainerDelete: (container: GqlContainer) => void;
}
export default function ContainerItemDropdown(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [open, setOpen] = React.useState<boolean>(false);
  const [vanPositions, setVanPositions] = React.useState<ExtendedVanPosition[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteContainer, setDeleteContainer] = React.useState(false);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    const newVanPositions: ExtendedVanPosition[] = [];
    const positions: GqlVanPosition[] = props.container?.vanPositions ?? [];
    for (const pos of positions) {
      if (pos?.packLine) {
        newVanPositions.push({
          id: pos.id,
          line: pos.packLine?.orderLine?.lineNumber,
          po: pos.packLine?.orderLine?.purchaseOrder?.poNumber,
          itemNumber: pos.packLine?.orderLine?.itemNumber,
          description: pos.packLine?.orderLine?.itemDescription,
          shippedQty: pos.piecesQty,
          shippedCtns: pos.cartonsQty,
          vanCode: pos.vanCode ? mapVanCode(pos.vanCode) : '',
          volume: pos.volumeM3 ?? 0,
          weight: pos.weightKg ?? 0,
          volumeUnit: VolumeUnit.Cbm,
          weightUnit: WeightUnit.Kg,
        });
      }
    }
    setVanPositions(newVanPositions);
  }, [open, props.container]);

  function hasPerm(perm: PermissionCode): boolean {
    return !!userContext?.permissionCodes.has(perm);
  }

  const canEdit = hasPerm(PermissionCode.ConsolidationContainerUpdate); // Later there will be additional network rules determining when a container is no longer editable

  const canDelete =
    hasPerm(PermissionCode.ConsolidationContainerDelete) &&
    consolidation?.status !== ShipmentStatus.Shipped;

  const showMoreActions = canEdit || canDelete;

  return (
    <Box display="flex" flexDirection="column" marginBottom={3}>
      <Box display="flex" justifyContent="space-between" bgcolor="#043c5f" alignItems="center">
        {open ? (
          <KeyboardArrowUpIcon
            onClick={(e) => {
              setOpen(false);
            }}
            htmlColor="#FFFFFF"
            fontSize="large"
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={(e) => {
              setOpen(true);
            }}
            htmlColor="#FFFFFF"
            fontSize="large"
            style={{ cursor: 'pointer' }}
          />
        )}

        <Box display="flex" alignItems="center">
          <Box
            color="#2dd1dc"
            fontSize="h4.fontSize"
            fontFamily="h3.fontFamily"
            fontWeight={800}
            marginRight={1}
          >
            CONTAINER:
          </Box>
          <Typography variant="body1" color="textSecondary">
            {props.container.containerNumber ? props.container.containerNumber : 'Not Assigned'}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            color="#2dd1dc"
            fontSize="h4.fontSize"
            fontFamily="h3.fontFamily"
            fontWeight={800}
            marginRight={1}
          >
            EQUIPMENT TYPE:
          </Box>
          <Typography variant="body1" color="textSecondary">
            {props.container.containerType ? props.container.containerType : 'Not Assigned'}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            color="#2dd1dc"
            fontSize="h4.fontSize"
            fontFamily="h3.fontFamily"
            fontWeight={800}
            marginRight={1}
          >
            SEAL:
          </Box>
          <Typography variant="body1" color="textSecondary">
            {props.container.sealNumber ? props.container.sealNumber : 'Not Assigned'}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            color="#2dd1dc"
            fontSize="h4.fontSize"
            fontFamily="h3.fontFamily"
            fontWeight={800}
            marginRight={1}
          >
            SHIP FROM CFS:
          </Box>
          <Typography variant="body1" color="textSecondary">
            {props.container.shipFromCfsDate
              ? formatDate(props.container.shipFromCfsDate)
              : 'Not Assigned'}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            color="#2dd1dc"
            fontSize="h4.fontSize"
            fontFamily="h3.fontFamily"
            fontWeight={800}
            marginRight={1}
          >
            TERMINAL REC'D:
          </Box>
          <Typography variant="body1" color="textSecondary">
            {props.container.terminalReceivedDate
              ? formatDate(props.container.terminalReceivedDate)
              : 'Not assigned'}
          </Typography>
        </Box>

        {showMoreActions ? (
          <IconButton aria-label="more" style={{ color: '#fff' }} onClick={handleClick}>
            <VertDots />
          </IconButton>
        ) : (
          <Box width={2} />
        )}

        <Menu
          id="container-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl) && showMoreActions}
          onClose={() => {
            setAnchorEl(null);
          }}
          style={{ minWidth: '125px' }}
        >
          {canEdit && (
            <MenuItem
              onClick={() => {
                props.onContainerSelected(props.container);
                setAnchorEl(null);
              }}
            >
              Edit
              {/* see #OMS-53 the intent is to keep the language simple for international users
                {Boolean(props.container.containerNumber) ? 'Edit' : 'Assign'} */}
            </MenuItem>
          )}
          {canDelete && (
            <MenuItem
              onClick={() => {
                setDeleteContainer(true);
                setAnchorEl(null);
              }}
              style={{ color: '#dd090a' }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
        <UniversalDialog
          open={deleteContainer}
          setClose={() => {
            setDeleteContainer(false);
          }}
          removeHR
        >
          <Box textAlign="center" width={300}>
            <Box
              component="span"
              color="#dd090a"
              fontSize={52}
              className="material-icons"
              marginBottom={2}
            >
              error
            </Box>
            <Typography variant="h2">Are you sure?</Typography>
            <Typography variant="body1">
              Are you sure you want to delete this container? This processes cannot be undone
            </Typography>
            <Box display="flex" color="#fff" margin={2} justifyContent="center">
              <Button
                size="large"
                variant="contained"
                color="inherit"
                onClick={() => {
                  setDeleteContainer(false);
                }}
              >
                Cancel
              </Button>

              <Button
                size="large"
                variant="contained"
                color="inherit"
                style={{ marginLeft: '24px', background: '#dd090a' }}
                onClick={() => {
                  setDeleteContainer(false);
                  props.onContainerDelete(props.container);
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </UniversalDialog>
      </Box>
      {open && (
        <Box border="1px solid #BBB">
          {props.container.vanPositions && props.container.vanPositions.length > 0 ? (
            <Box display="flex" justifyContent="center" padding={3} overflow="auto">
              <UWLTable
                rowId="id"
                columns={columns}
                rows={vanPositions}
                emptyMessage="No Items Available"
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" padding={3}>
              No items aded to this container
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
