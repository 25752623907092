import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { SearchType, useUploadBookingDocumentMutation } from 'api/GQL_Types';
import { BookingQuery } from 'api/queries/bookingQueries';
import { AtomicDocumentType } from 'app/components/AtomicDocumentType';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import ErrorMessage from 'components/ErrorMessage';
import FakeProgressBar from 'components/FakeProgressBar';
import { FormInputSearch, SearchResult } from 'components/form/FormInputSearch';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import styled from 'styled-components';
import { FormItem } from './form/FormItem';
import { FormRow } from './form/FormRow';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#ccc';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const UploadDocumentDialog_Data = newAtom<{
  // If no bookingId is provided, present the user with an input to enter the booking id
  bookingId?: string | null;
} | null>(null);

const formState = {
  uploading: newAtom<boolean>(false),
  error: newAtom<string | null>(null),

  booking: newAtom<SearchResult | null>(null),
  documentType: newAtom<string | null>(null),
  description: newAtom<string>(''),
  selectedFiles: newAtom<File[]>([]),
};

interface Props {}

export const UploadDocumentDialog: React.FC<Props> = (props) => {
  const [dialog, setDialog] = useRecoilState(UploadDocumentDialog_Data);
  const setDocumentType = useSetRecoilState(formState.documentType);
  const setDescription = useSetRecoilState(formState.description);
  const [booking, setBooking] = useRecoilState(formState.booking);
  const [filesToUpload, setFilesToUpload] = useRecoilState(formState.selectedFiles);

  const uploading = useRecoilValue(formState.uploading);
  const error = useRecoilValue(formState.error);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  React.useEffect(() => {
    setFilesToUpload(acceptedFiles);
  }, [acceptedFiles]);

  React.useEffect(() => {
    setBooking(
      dialog?.bookingId
        ? {
            id: dialog.bookingId,
            value: '', // doesn't matter b/c the input is not shown if a bookingId is provided
          }
        : null
    );
    setDocumentType(null);
    setDescription('');
    setFilesToUpload([]);
  }, [dialog]);

  if (!dialog) {
    return null;
  }

  if (uploading) {
    return (
      <UniversalDialog
        open
        small
        title="Upload Document"
        setClose={() => {
          setDialog(null);
        }}
      >
        <Box padding={3} textAlign="center">
          <Box marginBottom={3}>
            <Typography variant="h1">UPLOADING...</Typography>
          </Box>
          <Box marginBottom={2}>
            <FakeProgressBar start={true} finished={false} onFinished={() => {}} />
          </Box>
          <Typography variant="body1">Upload time depends on file size.</Typography>
        </Box>
      </UniversalDialog>
    );
  }

  return (
    <UniversalDialog
      open
      small
      title="Upload Document"
      setClose={() => {
        setDialog(null);
      }}
    >
      {!dialog?.bookingId && (
        <FormRow>
          <FormItem>
            <FormInputSearch
              searchType={SearchType.BookingByHblMbl}
              value={booking}
              onValue={setBooking}
              required
            />
          </FormItem>
        </FormRow>
      )}
      <FormRow>
        <AtomicDocumentType state={formState.documentType} required activeOnly />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2
          state={formState.description}
          label="Document Description"
          placeholder="Add a description for the document..."
          multiline
          rows={2}
        />
      </FormRow>

      <FormRow>
        <Box display="flex" flexGrow={1} width={1} style={{ margin: '16px 16px 0 0' }}>
          {filesToUpload.length > 0 ? (
            filesToUpload.map((file) => {
              return (
                <Grid
                  container
                  key={file.name}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4">{file.name}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const newFiles = filesToUpload.filter((fileInList) => fileInList !== file);
                        setFilesToUpload(newFiles);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
              <input {...getInputProps()} />
              <CloudUploadIcon style={{ fontSize: 50, color: '#0288d1' }} />
              <Box fontSize="34px" fontWeight={300}>
                Drag file to upload
              </Box>
              <Box margin={1} fontSize="24px" display="flex" width="50%">
                <hr
                  style={{
                    borderColor: '#EDEDED',
                    width: '33%',
                    height: 0,
                    alignSelf: 'center',
                  }}
                />
                or
                <hr
                  style={{
                    borderColor: '#EDEDED',
                    width: '33%',
                    height: 0,
                    alignSelf: 'center',
                  }}
                />
              </Box>
              <Button variant="contained" color="secondary" onClick={open} size="large">
                <Typography variant="h4" color="textSecondary">
                  Browse
                </Typography>
              </Button>
            </Container>
          )}
        </Box>
      </FormRow>

      <Box marginTop={5}>
        <hr style={{ border: '0.5px solid #ededed' }} />
        <ErrorMessage error={error} />
        <UploadBtn />
      </Box>
    </UniversalDialog>
  );
};

const UploadBtn: React.FC = (props) => {
  const setDialog = useSetRecoilState(UploadDocumentDialog_Data);
  const booking = useRecoilValue(formState.booking);
  const selectedDocumentType = useRecoilValue(formState.documentType);
  const documentDescription = useRecoilValue(formState.description);
  const selectedFiles = useRecoilValue(formState.selectedFiles);
  const setUploading = useSetRecoilState(formState.uploading);
  const setError = useSetRecoilState(formState.error);

  const [uploadBookingDocument] = useUploadBookingDocumentMutation({
    onCompleted(data) {
      setUploading(false);
      if (data.uploadBookingDocument.success) {
        setDialog(null);
      } else {
        setError(data.uploadBookingDocument.message || 'Upload Failed');
      }
    },
    onError(error) {
      setUploading(false);
      setError(error + '');
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id ?? '' },
        fetchPolicy: 'network-only',
      },
    ],
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={!booking || selectedFiles.length === 0 || !selectedDocumentType}
        onClick={() => {
          if (booking?.id && selectedFiles.length > 0) {
            setUploading(true);
            setError(null);
            uploadBookingDocument({
              variables: {
                input: {
                  bookingId: booking.id,
                  document: {
                    documentType: selectedDocumentType,
                    description: documentDescription,
                  },
                  file: selectedFiles[0],
                },
              },
            });
          }
        }}
      >
        Upload
      </Button>
    </Box>
  );
};
