import { Tab, Tabs, Typography } from '@material-ui/core';
import { DashboardFeatureCode } from 'api/GQL_Types';
import { auth } from 'app';
import React from 'react';
import { theme } from 'styles';
import DashboardBookingsDetails from './dashboard-bookings-details';
import DashboardBookingsSummary from './dashboard-bookings-summary';

interface Props {}

export default function BookingTabs(props: Props) {
  const { userContext } = auth.useAuthState();
  const [value, setValue] = React.useState<0 | 1>(0);

  const showChartBookingsByWeek = !!userContext?.dashboardFeatureCodes.has(
    DashboardFeatureCode.ChartBookingsByWeek
  );

  return (
    <>
      <Tabs
        value={value}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        variant="fullWidth"
      >
        <Tab
          label={<Typography variant="h3">Booking summary</Typography>}
          style={{ borderBottom: '2px solid #888' }}
        />
        <Tab
          label={<Typography variant="h3">Booking Details</Typography>}
          style={{ borderBottom: '2px solid #888' }}
        />
      </Tabs>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          minHeight: 0, // NOTE: Don't remove this. This is a silly hack that tells flexbox to not not let this div overflow the panel when the children have their own scroll area.
          flex: `1 1 100px`,
          padding: theme.spacing(0, 1.5),
        }}
      >
        {value === 0 && showChartBookingsByWeek && <DashboardBookingsSummary />}
        {value === 1 && <DashboardBookingsDetails />}
      </div>
    </>
  );
}
