import { PaymentType, ReleaseType } from 'api/GQL_Types';
import FormInputSelect from 'components/form/FormInputSelect';
import React from 'react';
import { useRecoilState } from 'recoil';
import { mapPaymentType, mapReleaseType, mapReleaseTypeString } from 'types/OMSEnums';
import { NetworkAdminPageStates } from '../states';
import { InputItem, InputRow } from './components/InputLayout';
import { RuleCard } from './components/RuleCard';
import { RuleCardGroup } from './components/RuleCardGroup';

interface Props {}

export const RulesBillOfLading: React.FC<Props> = (props) => {
  const [rulesForm, setRulesForm] = useRecoilState(NetworkAdminPageStates.rulesForm);

  if (!rulesForm) {
    return <div></div>;
  }

  return (
    <RuleCardGroup
      title="Bill of Lading"
      bools={[
        rulesForm.bolHblPaymentTypeEnabled,
        rulesForm.bolHblReleaseTypeEnabled,
        rulesForm.bolMblPaymentTypeEnabled,
        rulesForm.bolMblReleaseTypeEnabled,
      ]}
    >
      <RuleCard
        name="HBL Payment Type"
        description="Set the default payment type and what to do if the field is empty."
        enabled={rulesForm.bolHblPaymentTypeEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bolHblPaymentTypeEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem>
            <FormInputSelect
              label="Default Payment Type"
              options={[
                {
                  id: mapPaymentType(PaymentType.Collect),
                  label: mapPaymentType(PaymentType.Collect),
                },
                {
                  id: mapPaymentType(PaymentType.Prepaid),
                  label: mapPaymentType(PaymentType.Prepaid),
                },
                {
                  id: 'NONE',
                  label: 'None',
                },
              ]}
              value={
                rulesForm.bolHblPaymentTypeDefault
                  ? mapPaymentType(rulesForm.bolHblPaymentTypeDefault)
                  : 'NONE'
              }
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolHblPaymentTypeDefault:
                    v === 'NONE' ? null : PaymentType[v as keyof typeof PaymentType],
                });
              }}
            />
          </InputItem>
          <InputItem>
            <FormInputSelect
              label="When Field Is Empty"
              options={[
                { id: 'warning', label: 'Warning' },
                { id: 'hard-stop', label: 'Hard Stop' },
              ]}
              value={rulesForm.bolHblPaymentTypeHardStop ? 'hard-stop' : 'warning'}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolHblPaymentTypeHardStop: v === 'hard-stop',
                });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
      <RuleCard
        name="HBL Release Type"
        description="Set the default release type and what to do if the field is empty."
        enabled={rulesForm.bolHblReleaseTypeEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bolHblReleaseTypeEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem>
            <FormInputSelect
              label="Default Release Type"
              options={[
                {
                  id: ReleaseType.Obo,
                  label: mapReleaseType(ReleaseType.Obo),
                },
                {
                  id: ReleaseType.Tlx,
                  label: mapReleaseType(ReleaseType.Tlx),
                },
                {
                  id: 'NONE',
                  label: 'None',
                },
              ]}
              value={rulesForm.bolHblReleaseTypeDefault ?? 'NONE'}
              onValue={(v) => {
                let releaseType = !v || v === 'NONE' ? null : mapReleaseTypeString(v);
                setRulesForm({
                  ...rulesForm,
                  bolHblReleaseTypeDefault: releaseType ? releaseType : null,
                });
              }}
            />
          </InputItem>
          <InputItem>
            <FormInputSelect
              label="When Field Is Empty"
              options={[
                { id: 'warning', label: 'Warning' },
                { id: 'hard-stop', label: 'Hard Stop' },
              ]}
              value={rulesForm.bolHblReleaseTypeHardStop ? 'hard-stop' : 'warning'}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolHblReleaseTypeHardStop: v === 'hard-stop',
                });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
      <RuleCard
        name="MBL Payment Type"
        description="Set the default payment type and what to do if the field is empty."
        enabled={rulesForm.bolMblPaymentTypeEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bolMblPaymentTypeEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem>
            <FormInputSelect
              label="Default Payment Type"
              options={[
                {
                  id: mapPaymentType(PaymentType.Collect),
                  label: mapPaymentType(PaymentType.Collect),
                },
                {
                  id: mapPaymentType(PaymentType.Prepaid),
                  label: mapPaymentType(PaymentType.Prepaid),
                },
                {
                  id: 'NONE',
                  label: 'None',
                },
              ]}
              value={
                rulesForm.bolMblPaymentTypeDefault
                  ? mapPaymentType(rulesForm.bolMblPaymentTypeDefault)
                  : 'NONE'
              }
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolMblPaymentTypeDefault:
                    v === 'NONE' ? null : PaymentType[v as keyof typeof PaymentType],
                });
              }}
            />
          </InputItem>
          <InputItem>
            <FormInputSelect
              label="When Field Is Empty"
              options={[
                { id: 'warning', label: 'Warning' },
                { id: 'hard-stop', label: 'Hard Stop' },
              ]}
              value={rulesForm.bolMblPaymentTypeHardStop ? 'hard-stop' : 'warning'}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolMblPaymentTypeHardStop: v === 'hard-stop',
                });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
      <RuleCard
        name="MBL Release Type"
        description="Set the default release type and what to do if the field is empty."
        enabled={rulesForm.bolMblReleaseTypeEnabled}
        onEnabled={(enabled) => {
          setRulesForm({ ...rulesForm, bolMblReleaseTypeEnabled: enabled });
        }}
      >
        <InputRow>
          <InputItem>
            <FormInputSelect
              label="Default Release Type"
              options={[
                {
                  id: ReleaseType.Obo,
                  label: mapReleaseType(ReleaseType.Obo),
                },
                {
                  id: ReleaseType.Tlx,
                  label: mapReleaseType(ReleaseType.Tlx),
                },
                {
                  id: 'NONE',
                  label: 'None',
                },
              ]}
              value={rulesForm.bolMblReleaseTypeDefault ?? 'NONE'}
              onValue={(v) => {
                let releaseType = !v || v === 'NONE' ? null : mapReleaseTypeString(v);
                setRulesForm({
                  ...rulesForm,
                  bolMblReleaseTypeDefault: releaseType ? releaseType : null,
                });
              }}
            />
          </InputItem>
          <InputItem>
            <FormInputSelect
              label="When Field Is Empty"
              options={[
                { id: 'warning', label: 'Warning' },
                { id: 'hard-stop', label: 'Hard Stop' },
              ]}
              value={rulesForm.bolMblReleaseTypeHardStop ? 'hard-stop' : 'warning'}
              onValue={(v) => {
                setRulesForm({
                  ...rulesForm,
                  bolMblReleaseTypeHardStop: v === 'hard-stop',
                });
              }}
            />
          </InputItem>
        </InputRow>
      </RuleCard>
    </RuleCardGroup>
  );
};
