import { FormInputCountry } from 'components/form/FormInputCountry';
import { FormItem } from 'components/form/FormItem';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  state: RecoilState<string | null>;

  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export default function AtomicCountryV2(props: Props) {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputCountry
        value={state}
        onValue={setState}
        label={props.label}
        disabled={props.disabled}
        required={props.required}
      />
    </FormItem>
  );
}
