import React from 'react';
import { Box, InputBase, InputAdornment } from '@material-ui/core';

interface Props {
  placeholder?: string;
  field?: string;
  updateField?: (value: string) => void;
  inputRef?: React.Ref<any>;
}

export default function SearchBar(props: Props) {
  return (
    <Box display="flex">
      <Box display="inline-flex" width="100%">
        <InputBase
          id={props.placeholder}
          type={'text'}
          placeholder={props.placeholder ? props.placeholder : 'Search'}
          value={props.field}
          inputRef={props.inputRef}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <span className="material-icons" style={{ color: '#043C5F' }}>
                search
              </span>
            </InputAdornment>
          }
          onChange={(e) => {
            if (props.updateField) {
              props.updateField(e.target.value);
            }
          }}
        />
      </Box>
    </Box>
  );
}
