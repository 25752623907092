import { formatNumberDecimalPlaces } from 'types/Number';

export function formatWeight(weight: number, unit: string): string {
  return formatNumberDecimalPlaces(weight, 2, 2) + ' ' + unit;
}

export function formatVolume(volume: number, unit: string): string {
  return formatNumberDecimalPlaces(volume, 2, 2) + ' ' + unit;
}

export function formatDistance(distance: number, unit: string): string {
  return formatNumberDecimalPlaces(distance, 2, 2) + ' ' + unit;
}
