import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue } from 'recoil';
import BookingBrowser from './BookingBrowser';
import ConsolidatedBookingBrowser from './ConsolidatedBookingBrowser';
import { newAtom } from 'lib/RecoilUtils';

export const bookingListSelectedTab = newAtom<'Bookings' | 'Consolidations'>('Bookings');

interface Props extends RouteComponentProps {}
export const BookingList = (props: Props) => {
  const userContext = useRecoilValue(userContextAtom);
  const value = useRecoilValue(bookingListSelectedTab);

  return (
    <>
      {value === 'Bookings' && <BookingBrowser />}
      {value === 'Consolidations' &&
        userContext?.permissionCodes.has(PermissionCode.ConsolidationRead) && (
          <ConsolidatedBookingBrowser />
        )}
    </>
  );
};
