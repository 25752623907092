import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { AtomicDocumentType } from 'app/components/AtomicDocumentType';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilState } from 'recoil';
import { theme } from 'styles';
import { DocumentBrowserStates } from '../states';
import ApplyFiltersButton from './ApplyFiltersButton';
import DateRange from './DateRange';
import ItemAutoComplete from './ItemAutoComplete';
import PoAutoComplete from './POAutoComplete';

export default function Filters() {
  const [newDocuments, setNewDocuments] = useRecoilState(DocumentBrowserStates.newDocuments);

  return (
    <Panel title="Filters">
      <Grid container>
        <Grid item xs={12} lg={6}>
          <DateRange />
          <FormRow>
            <AtomicTextFieldV2 label="Shipper" state={DocumentBrowserStates.shipper} />
            <AtomicTextFieldV2 label="Destination" state={DocumentBrowserStates.destination} />
          </FormRow>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormRow>
            <AtomicDocumentType
              state={DocumentBrowserStates.documentType}
              nullOptionLabel="- all -"
            />
          </FormRow>
          <FormRow>
            <PoAutoComplete />
            <ItemAutoComplete />
          </FormRow>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        marginLeft={2}
        marginBottom={2}
        marginRight={4.5}
      >
        <FormControlLabel
          style={{ margin: '0 0 0 -4px' }}
          control={
            <Checkbox
              style={{ padding: theme.spacing(0, 1, 0, 0) }}
              color="primary"
              checked={newDocuments}
              onChange={(e) => {
                setNewDocuments(e.target.checked);
              }}
            />
          }
          label={<Typography variant="h4">New Documents</Typography>}
        />
        <ApplyFiltersButton />
      </Box>
    </Panel>
  );
}
