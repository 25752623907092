import { IconButton, ListItem } from '@material-ui/core';
import React from 'react';
import { sidenavStyle } from './navstyles';
import clsx from 'clsx';

interface Props {
  open: boolean;
  onChange: Function;
}

export default function DrawerBtn(props: Props) {
  const classes = sidenavStyle();

  return (
    <ListItem dense>
      <IconButton
        className={clsx(classes.selected, classes.drawerBtn)}
        onClick={(e) => props.onChange(!props.open)}
      >
        <i className="material-icons">
          {props.open ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
        </i>
      </IconButton>
    </ListItem>
  );
}
