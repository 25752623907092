import Box from '@material-ui/core/Box';
import Panel from 'components/Panel';
import React from 'react';
import { PoHistoryEntry } from './PoHistoryEntry';
import { PoPageData } from './states';

interface Props {
  data: PoPageData;
}

export const PoHistory: React.FC<Props> = (props) => {
  return (
    <Panel title="PO History">
      <Box padding={2}>
        {props.data.historyEntries.length === 0 && <div>- none - </div>}
        {props.data.historyEntries.map((entry) => {
          return <PoHistoryEntry key={entry.id} entry={entry} />;
        })}
      </Box>
    </Panel>
  );
};
