import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  snackbarKey: string | number;
}

export const SnackbarCloseAction: React.FC<Props> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      size="small"
      onClick={() => {
        closeSnackbar(snackbarKey);
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};
