import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1.5),
  },
  noMargin: {
    margin: 0,
  },
});

interface Props {
  noMargin?: boolean;
}

export const FormRow: React.FC<Props> = (props) => {
  return (
    <div className={clsx(classes.root, props.noMargin && classes.noMargin)}>{props.children}</div>
  );
};
