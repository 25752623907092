import { AdminPagesContact } from './states';

export function contactFilter(searchField: string) {
  const lowerField = searchField.toLowerCase();

  return (contact: AdminPagesContact): boolean => {
    const fullName = contact.firstName + ' ' + contact.lastName;
    return (
      contact.firstName.toLowerCase().includes(lowerField) ||
      contact.lastName.toLowerCase().includes(lowerField) ||
      contact.email.toLowerCase().includes(lowerField) ||
      fullName.toLowerCase().includes(lowerField)
    );
  };
}
