import { gql } from '@apollo/client/core';
import {
  GqlAssignConsolidationContainerMutation,
  GqlAssignConsolidationContainerMutationVariables,
  GqlAttachConsolidationBookingsMutation,
  GqlAttachConsolidationBookingsMutationVariables,
  GqlCancelConsolidationMutation,
  GqlCancelConsolidationMutationVariables,
  GqlDetachConsolidationBookingMutation,
  GqlDetachConsolidationBookingMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';
import { PortFragment } from './portQueries';

export const ConsolidationListQuery = gql`
  query ConsolidationList {
    shipments(
      shipment_filter: {
        type: CONSOLIDATION
        statuses: [BOOKED, PENDING_ASSIGNED, CONFIRMED, CONSOLIDATED, ASSIGNED, CONSOL]
      }
    ) {
      id
      referenceNumber
      status
      createDate
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volumeM3
        weightKg
        isHot
      }
      ... on Consolidation {
        bookings {
          packLines {
            linePrice
          }
          containers {
            id
            sequential
            containerNumber
            containerType
            volumeM3
            weightKg
            isHot
            vanPositions {
              commercialInvoiceTotal
            }
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
        }
      }
    }
  }
`;

export const ShippedConsolidationListQuery = gql`
  query ShippedConsolidationList {
    shipments(shipment_filter: { type: CONSOLIDATION, statuses: [SHIPPED] }) {
      id
      referenceNumber
      status
      createDate
      ... on Booking {
        cargoReadyDate
        revisedCargoReadyDate
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        moveType
        transportationMode
        carrier {
          id
          name
        }
        carrierConfirmationNumber
        pickupLocation {
          id
          name
        }
        deliveryLocation {
          id
          name
        }
        cyCutoffDate
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      containers {
        id
        sequential
        containerNumber
        containerType
        volumeM3
        weightKg
        isHot
      }

      ... on Consolidation {
        bookings {
          packLines {
            linePrice
          }
          containers {
            id
            sequential
            containerNumber
            containerType
            volumeM3
            weightKg
            isHot
            vanPositions {
              commercialInvoiceTotal
            }
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
        }
      }
    }
  }
`;

export const AvailableConsolidationBookingListQuery = gql`
  query AvailableConsolidationBookingList {
    availableConsolidationBookings {
      id
      referenceNumber
      status
      createDate
      cargoReadyDate
      logistics {
        id
        moveType
        finalDestination {
          id
          name
        }
        pod {
          ...Port
        }
        pol {
          ...Port
        }
        polEtd
        polAtd
      }
      packLines {
        weightKg
        volumeM3
      }
      containers {
        id
        containerType
        volumeM3
        weightKg
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
        }
      }
    }
    userContext {
      activeContact {
        profile {
          locations {
            id
            name
            locationTypes
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const ConsolidationQuery = gql`
  query Consolidation($consolId: ID!) {
    consolidation(consolId: $consolId) {
      id
      referenceNumber
      status
      createDate
      cancelledDate
      cancelledBy
      bookingParty {
        id
        firstName
        lastName
      }
      mbl {
        id
        referenceNumber
        releaseType
        paymentType
      }
      bookings {
        id
        referenceNumber
        status
        createDate
        cargoReadyDate
        packLines {
          id
          requestedDeliveryDate
          shippedQty
          shippedCartons
          volumeM3
          weightKg
          orderLine {
            id
            lineNumber
            unitPrice
            unitPriceCurrencyCode
            itemNumber
            itemDescription
            purchaseOrder {
              id
              poNumber
              htsCode
            }
            indcDate
            lastShipDate
            shipToLocation {
              id
              name
            }
          }
          booking {
            referenceNumber
            relatedParties {
              partyType
              party {
                name
              }
            }
          }
          vanPositions {
            container {
              id
              containerNumber
              terminalReceivedDate
            }
          }
        }
        logistics {
          id
          moveType
          finalDestination {
            name
          }
          pol {
            code
          }
          polEtd
          pod {
            code
          }
        }
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              name
            }
          }
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
        relatedParties {
          id
          partyType
          party {
            id
            name
          }
        }
        hbl {
          id
          referenceNumber
          releaseType
          paymentType
          documents {
            id
            documentType
            importFileName
            fileName
            fileType
            fileSize
            uploadDate
            description
          }
        }
        mbl {
          id
          referenceNumber
          documents {
            id
            documentType
            importFileName
            fileName
            fileType
            fileSize
            uploadDate
            description
          }
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            name
          }
        }
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            name
          }
        }
      }
      logistics {
        id
        confirmationNumber
        confirmationDate
        carrierConfirmationNumber
        moveType
        transportationMode
        cfsLocation {
          name
        }
        deliveryLocation {
          name
        }
        carrier {
          name
        }
        contractType
        contractNumber
        cyCutoffDate
        cfsCutoffDate
        cfsOpenDate
        vgmCutoffDate
        siCutoffDate
        isfCutoffDate
        motherVessel {
          id
          name
          voyages {
            id
            name
          }
        }
        motherVoyage {
          id
          name
        }
        feederVessel {
          id
          name
          voyages {
            id
            name
          }
        }
        feederVoyage {
          id
          name
        }
        transitPort {
          ...Port
        }
        transitPortEta
        transitPortAta
        transitPortEtd
        transitPortAtd
        pol {
          ...Port
        }
        polEtd
        polAtd
        pod {
          ...Port
        }
        podEta
        ramp {
          ...Port
        }
        rampEta
        finalDestination {
          id
          name
        }
        deliveryEta
        deliveryAta
      }
      containers {
        id
        containerNumber
        containerType
        sealNumber
        volumeM3
        weightKg
        cntrConsolidationDate
        shipFromCfsDate
        shipFromFactoryDate
        terminalReceivedDate
        vanPositions {
          id
          packLine {
            id
            orderLine {
              lineNumber
              itemNumber
              itemDescription
              purchaseOrder {
                poNumber
              }
            }
          }
          volumeM3
          weightKg
          piecesQty
          cartonsQty
          vanCode
        }
        isHot
        deliveryAta
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
    }
  }
  ${PortFragment}
`;

export const NewConsolidationContainerMutation = gql`
  mutation NewConsolidationContainers($input: NewConsolidationContainersInput!) {
    newConsolidationContainers(input: $input) {
      success
    }
  }
`;

export const AssignConsolidationContainerMutation = gql`
  mutation AssignConsolidationContainer($input: AssignConsolidationContainerInput!) {
    assignConsolidationContainer(input: $input) {
      success
      message
    }
  }
`;

export const assignConsolidationContainer = wrapMutation<
  GqlAssignConsolidationContainerMutation,
  GqlAssignConsolidationContainerMutationVariables
>({
  mutation: AssignConsolidationContainerMutation,
  errorMessage(data, error) {
    if (!data?.assignConsolidationContainer.success || error) {
      return data?.assignConsolidationContainer.message || 'Unexpected Error';
    }
  },
});

export const DeleteContainerMutation = gql`
  mutation DeleteContainer($input: DeleteContainerInput!) {
    deleteContainer(input: $input) {
      success
    }
  }
`;

export const UpdateConsolidationLogisticsMutation = gql`
  mutation UpdateConsolidationLogistics($input: UpdateConsolidationLogisticsInput!) {
    updateConsolidationLogistics(input: $input) {
      success
      message
    }
  }
`;

export const SaveConsolidationMutation = gql`
  mutation SaveConsolidation($input: NewConsolidationInput!) {
    newConsolidation(input: $input) {
      success
      message
      consolidation {
        id
        referenceNumber
      }
    }
  }
`;

export const SetConsolidationRelatedPartiesMutation = gql`
  mutation SetConsolidationRelatedParties($input: SetConsolidationRelatedPartiesInput!) {
    setConsolidationRelatedParties(input: $input) {
      success
      message
    }
  }
`;

export const NewConsolidationRemarkMutation = gql`
  mutation NewConsolidationRemark($input: NewConsolidationRemarkInput!) {
    newConsolidationRemark(input: $input) {
      success
      message
      remark {
        id
        text
        createDate
        createdBy {
          firstName
          lastName
          title
          profile {
            name
          }
        }
      }
    }
  }
`;

export const DetachConsolidationBookingMutation = gql`
  mutation DetachConsolidationBooking($input: DetachConsolidationBookingInput!) {
    detachConsolidationBooking(input: $input) {
      success
      message
      consolidation {
        bookings {
          id
        }
      }
    }
  }
`;

export const detachConsolidationBooking = wrapMutation<
  GqlDetachConsolidationBookingMutation,
  GqlDetachConsolidationBookingMutationVariables
>({
  mutation: DetachConsolidationBookingMutation,
  errorMessage(data, error) {
    if (!data?.detachConsolidationBooking.success || error) {
      return data?.detachConsolidationBooking.message || 'Unexpected Error';
    }
  },
});

export const AttachConsolidationBookingsMutation = gql`
  mutation AttachConsolidationBookings($input: AttachConsolidationBookingsInput!) {
    attachConsolidationBookings(input: $input) {
      success
      message
    }
  }
`;

export const attachConsolidationBookings = wrapMutation<
  GqlAttachConsolidationBookingsMutation,
  GqlAttachConsolidationBookingsMutationVariables
>({
  mutation: AttachConsolidationBookingsMutation,
  errorMessage(data, error) {
    if (!data?.attachConsolidationBookings.success || error) {
      return data?.attachConsolidationBookings.message || 'Unexpected Error';
    }
  },
});

export const CancelConsolidationMutation = gql`
  mutation CancelConsolidation($input: CancelConsolidationInput!) {
    cancelConsolidation(input: $input) {
      success
      message
    }
  }
`;

export const cancelConsolidation = wrapMutation<
  GqlCancelConsolidationMutation,
  GqlCancelConsolidationMutationVariables
>({
  mutation: CancelConsolidationMutation,
  errorMessage(data, error) {
    if (!data?.cancelConsolidation.success || error) {
      return data?.cancelConsolidation.message || 'Unexpected Error';
    }
  },
});
