import { Box } from '@material-ui/core';
import React from 'react';
import { XYPlot, YAxis, ChartLabel, MarkSeries } from 'react-vis';

interface Props {
  containerHeight: number;
  tickValues: number[];
  yData: any[];
}

export default function RangeXYPlot(props: Props) {
  return (
    <Box>
      <XYPlot
        width={80}
        height={props.containerHeight < 0 ? 0 : props.containerHeight}
        xType="ordinal"
        margin={{ left: 70, right: 0, top: 30, bottom: 80 }}
      >
        <YAxis
          tickValues={props.tickValues}
          left={20}
          style={{ fill: '#505058', fontWeight: 600, fontSize: 16 }}
        />
        <ChartLabel
          text="Total Containers"
          className="alt-y-label"
          xPercent={0.3}
          yPercent={0.25}
          size={30}
          style={{
            fontWeight: 600,
            transform: 'rotate(-90)',
            textAnchor: 'middle',
            fontSize: 50,
          }}
        />
        <MarkSeries style={{ display: 'none' }} data={props.yData} />
      </XYPlot>
    </Box>
  );
}
