import { Grid } from '@material-ui/core';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewNetworkPageStates } from '../../states';
import { SelectableTemplateCard } from './SelectableTemplateCard';

export default function TemplateComponent() {
  const [selected, setSelected] = useRecoilState<string[]>(
    NewNetworkPageStates.fields.selectedProfileTypes
  );
  const templates = useRecoilValue(NewNetworkPageStates.availableProfileTypeTemplates);

  return (
    <Grid container spacing={2}>
      {templates.map((template) => {
        return (
          <Grid key={template.id} item xs={12} sm={4}>
            <SelectableTemplateCard
              selected={selected && selected?.includes(template.id)}
              setSelected={() => {
                let selectedList: string[] = [...selected];
                if (selected && selected.includes(template.id)) {
                  selectedList.splice(selectedList.indexOf(template.id), 1);
                } else {
                  selectedList.push(template.id);
                }
                setSelected(selectedList);
              }}
              template={template}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
