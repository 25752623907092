import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Weekday } from '../../types/Weekday';

const StyledButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1.2rem',
      padding: 0,
      minWidth: 'auto',
      width: '2rem',
      height: '2rem',
      borderRadius: '100%',
      '&:not($textPrimary)': {
        color: '#ccc',
      },
    },
    textPrimary: {
      fontWeight: 'bold',
    },
  })
)(Button);

interface Props {
  value: Weekday[];
  onValue(v: Weekday[]): void;
  disabled?: boolean;
  error?: string | null;
}

export default function FormInputWeekDays({ value, onValue, disabled, error }: Props) {
  function Day({ d, day }: { d: string; day: Weekday }) {
    const isChecked = value.indexOf(day) >= 0;
    return (
      <StyledButton
        color={isChecked ? 'primary' : 'default'}
        onClick={(e) => {
          if (isChecked) {
            onValue(value.filter((d) => d !== day));
          } else {
            onValue(value.concat([day]));
          }
        }}
        disabled={disabled}
      >
        {d}
      </StyledButton>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: 40,
        }}
      >
        <Day d="S" day="sun" />
        <Day d="M" day="mon" />
        <Day d="T" day="tue" />
        <Day d="W" day="wed" />
        <Day d="T" day="thu" />
        <Day d="F" day="fri" />
        <Day d="S" day="sat" />
      </div>
      {error && (
        <FormHelperText error variant="outlined">
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
