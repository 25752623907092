import { formatNumber, isNumber } from 'types/Number';

export function formatNumberOfBytes(nBytes: number): string {
  if (!isNumber(nBytes)) {
    return nBytes + '';
  }
  nBytes = Math.floor(Math.abs(nBytes));
  if (nBytes === 0) {
    return '0 B';
  }
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.min(sizes.length - 1, Math.floor(Math.log(nBytes) / Math.log(1024)));
  return formatNumber(Math.round(nBytes / Math.pow(1024, i))) + ' ' + sizes[i];
}
