import { Box, Button } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useRecoilValue } from 'recoil';

import { GUStates } from '../../states';

export default function SelectRecordsBtn() {
  const selectedResults = useRecoilValue(GUStates.selectedGlobalUpdateRecords);

  return (
    <Box marginTop={3}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={!selectedResults || selectedResults.length === 0}
        onClick={() => {
          navigate('admin/global-updates/update');
        }}
      >
        Select Records ({selectedResults ? selectedResults.length : 0})
      </Button>
    </Box>
  );
}
