import { gql } from '@apollo/client/core';
import {
  GqlUpdateRelatedProfileRulesMutation,
  GqlUpdateRelatedProfileRulesMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';
import { AdminPagesContactFragment } from './contactQueries';
import { PortFragment } from './portQueries';

export const AllNetworksQuery = gql`
  query AllNetworks {
    allNetworks {
      id
      name
      connectedProfiles {
        id
        name
        isControllingClient
      }
    }
  }
`;

export const RelatedProfileRuleFragment = gql`
  fragment RelatedProfileRule on RelatedProfileRule {
    whenProfileId
    whenPartyType
    whenCondition
    whenPort {
      ...Port
    }
    whenCountryCode
    whenCategoryCode
    whenDepartment
    whenMaterial
    setPartyType
    setProfileId
  }
  ${PortFragment}
`;

export const NetworkQuery = gql`
  query Network {
    network {
      id
      name
      connectedProfiles {
        id
        name
        profileType {
          id
          name
        }
        isControllingClient
        logo {
          url
        }
        needsUpdate
        locations {
          id
          name
          code
          nameAliases
          codeAliases
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
          timeZone
          companyPhone
          companyUrl
          relatedPort {
            code
            name
          }
          locationTypes
        }
        contacts {
          ...AdminPagesContact
        }
        roles {
          id
          name
          description
          isDefaultRole
          notificationCodes
        }
        network {
          id
          name
        }
      }
      profileTypes {
        id
        name
        description
        isTemplate
        defaultRoles {
          id
          name
          description
          isDefaultRole
          permissionCodes
        }
        permissionCodes
      }
      items {
        id
        itemNumber
        description
        htsCode
        supplier {
          name
        }
      }
      rules {
        poExpiredEnabled
        poExpiredDays
        poExpiredField

        poFirstShipEnabled
        poFirstShipDays
        poFirstShipField

        poLastShipEnabled
        poLastShipDays
        poLastShipField

        poBookByEnabled
        poBookByDays
        poBookByField

        poCriticalEnabled
        poCriticalDays
        poCriticalField

        bookingLateEnabled
        bookingLateDays
        bookingLateField

        bookingPendingConfEnabled
        bookingPendingConfDays

        bookingQtyToleranceEnabled
        bookingQtyTolerancePercentOver
        bookingQtyTolerancePercentUnder
        bookingQtyToleranceHardStop

        bookingContainerCapacityToleranceEnabled
        bookingContainerCapacityToleranceHardStop

        consolConsolableMoveTypesEnabled
        consolConsolableMoveTypes

        shipmentPolEtdChangeEnabled
        shipmentPolEtdChangeDays

        shipmentPodEtaChangeEnabled
        shipmentPodEtaChangeDays

        shipmentRevisedCrdChangeEnabled
        shipmentRevisedCrdChangeDays

        shipmentCriticalEnabled
        shipmentCriticalDays
        shipmentCriticalField1
        shipmentCriticalField2

        shipmentLateEnabled
        shipmentLateDays

        milestoneLateOnWaterEnabled
        milestoneLateOnWaterDays

        milestoneLateAtDischargeEnabled
        milestoneLateAtDischargeDays

        milestoneLateAtRampEnabled
        milestoneLateAtRampDays

        milestoneLateDeliveryEnabled
        milestoneLateDeliveryDays

        milestonePendingOutgateEnabled
        milestonePendingOutgateDays

        milestoneDelayedUnloadEnabled
        milestoneDelayedUnloadDays
        milestoneDelayedUnloadField

        milestoneEmptyNotReturnedEnabled
        milestoneEmptyNotReturnedDays

        documentTypes {
          documentType
          missingEnabled
          missingDays
          missingField
        }

        bolHblPaymentTypeEnabled
        bolHblPaymentTypeDefault
        bolHblPaymentTypeHardStop
        bolHblReleaseTypeEnabled
        bolHblReleaseTypeDefault
        bolHblReleaseTypeHardStop
        bolMblPaymentTypeEnabled
        bolMblPaymentTypeDefault
        bolMblPaymentTypeHardStop
        bolMblReleaseTypeEnabled
        bolMblReleaseTypeDefault
        bolMblReleaseTypeHardStop
      }
      containerTypes {
        code
        name
        capacityVolumeM3
        capacityWeightKg
        ruleMinVolumeM3
        ruleMaxVolumeM3
        ruleMinWeightKg
        ruleMaxWeightKg
      }
      relatedProfileRules {
        ...RelatedProfileRule
      }
    }
  }
  ${AdminPagesContactFragment}
  ${RelatedProfileRuleFragment}
`;

export const NetworkProfilesQuery = gql`
  query NetworkProfiles {
    network {
      connectedProfiles {
        id
        name
      }
    }
  }
`;

export const NewNetworkMutation = gql`
  mutation NewNetwork($input: NewNetworkInput!) {
    newNetwork(input: $input) {
      success
      message
      network {
        id
        connectedProfiles {
          id
          name
        }
      }
    }
  }
`;

export const UpdateNetworkRulesMutation = gql`
  mutation UpdateNetworkRules($input: UpdateNetworkRulesInput!) {
    updateNetworkRules(input: $input) {
      success
      message
    }
  }
`;

export const UpdateNetworkContainerTypes = gql`
  mutation UpdateNetworkContainerTypes($input: UpdateNetworkContainerTypesInput!) {
    updateNetworkContainerTypes(input: $input) {
      success
      message
    }
  }
`;

export const NewNetworkConnectedProfileMutation = gql`
  mutation NewNetworkConnectedProfile($input: NewNetworkConnectedProfileInput!) {
    newNetworkConnectedProfile(input: $input) {
      success
      message
      profile {
        id
        name
      }
    }
  }
`;

export const NewNetworkProfileTypeMutation = gql`
  mutation NewNetworkProfileType($input: NewNetworkProfileTypeInput!) {
    newNetworkProfileType(input: $input) {
      success
      message
      profileType {
        id
      }
    }
  }
`;

export const UpdateRelatedProfileRulesMutation = gql`
  mutation updateRelatedProfileRules($input: UpdateRelatedProfileRulesInput!) {
    updateRelatedProfileRules(input: $input) {
      success
      message
      relatedProfileRules {
        ...RelatedProfileRule
      }
    }
  }
  ${RelatedProfileRuleFragment}
`;

export const updateRelatedProfileRules = wrapMutation<
  GqlUpdateRelatedProfileRulesMutation,
  GqlUpdateRelatedProfileRulesMutationVariables
>({
  mutation: UpdateRelatedProfileRulesMutation,
  errorMessage(data, error) {
    if (!data?.updateRelatedProfileRules.success || error) {
      return data?.updateRelatedProfileRules.message || 'Unexpected Error';
    }
  },
});
