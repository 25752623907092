import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import { Line } from 'components/StyledComponents';
import React from 'react';
import AddProfileLocationBtn from './AddProfileLocationBtn';
import ProfileLocationCards from './LocationCards';
import ProfileLocationsDialog from './ProfileLocationsDialog';
import ProfileLocationsHeader from './ProfileLocationsHeader';
import ReviewProfileLocationDialog from './ReviewProfileLocationDialog';

interface Props extends RouteComponentProps {}

export default function ProfileLocations(props: Props) {
  const { userContext } = auth.useAuthState();

  return (
    <div>
      <ProfileLocationsHeader />
      <Line height={1} />
      <ProfileLocationCards />
      {userContext?.permissionCodes.has(PermissionCode.ProfileLocationCreate) && (
        <Box position="absolute" bottom={50} right={40}>
          <AddProfileLocationBtn />
        </Box>
      )}
      <ProfileLocationsDialog />
      <ReviewProfileLocationDialog />
    </div>
  );
}
