import { gqlClient } from 'api/ApolloClient';
import { GqlPortFragment } from 'api/GQL_Types';
import { SearchPortsQuery } from 'api/queries/portQueries';
import { FormInputAutocomplete } from 'components/form/FormInputAutocomplete';
import { PortFlagIcon } from 'components/PortFlagIcon';
import useAsyncLoader from 'lib/useAsyncLoader';
import useIsMountedRef from 'lib/useIsMountedRef';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { portToString } from 'types/Port';

const classes = makeCss({
  portItemLabel: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.common.black,
  },
  portCode: {
    width: '3em',
    display: 'inline-block',
    fontWeight: 'bold',
    fontFamily: 'monospace',
  },
});

interface Props {
  value: GqlPortFragment | null;
  onValue(v: GqlPortFragment | null): void;

  label?: string;
  required?: boolean;
  disabled?: boolean;

  onDesiredWidth?: (inputWidth: number) => void;
}

export const FormInputPortAny: React.FC<Props> = ({
  value,
  onValue,
  label,
  required,
  disabled,
  onDesiredWidth,
}) => {
  const [filterQuery, setFilterQuery] = React.useState<string>('');
  const [isGoingToSearch, setIsGoingToSearch] = React.useState<boolean>(false);
  const isMountedRef = useIsMountedRef();
  const query = filterQuery.trim().toLowerCase();
  const isQueryLongEnough = query.length >= 2;

  const portLoader = useAsyncLoader<GqlPortFragment[]>([], () =>
    isQueryLongEnough
      ? gqlClient
          .query({
            query: SearchPortsQuery,
            variables: { query },
          })
          .then((data) => data.data.searchPorts)
      : []
  );

  React.useEffect(() => {
    setIsGoingToSearch(true);
    const handle = setTimeout(() => {
      portLoader.loadUpdate().then(() => {
        if (isMountedRef.current) {
          setIsGoingToSearch(false);
        }
      });
    }, 500);
    return () => clearTimeout(handle);
  }, [query]);

  return (
    <FormInputAutocomplete
      options={portLoader.data}
      label={label}
      value={value}
      onValue={onValue}
      waiting={isQueryLongEnough ? portLoader.waiting || isGoingToSearch : false}
      error={isQueryLongEnough ? portLoader.error : null}
      noValueMessage={isQueryLongEnough ? null : 'Search for a port'}
      onFilterQuery={setFilterQuery}
      displayResolver={(port) => `${port.code} - ${portToString(port)}`}
      localFilter={(query, port) => {
        const src = `${port.code} - ${portToString(port)}`.toLowerCase();
        return src.includes(query); // Filter to what appears in the drop down list - See OMS-287
      }}
      required={required}
      disabled={disabled}
      onDesiredWidth={onDesiredWidth}
      renderOption={(port) => {
        return (
          <>
            <PortFlagIcon port={port} size="small" />
            <div className={classes.portItemLabel}>
              <span className={classes.portCode}>{port.code}</span> - {portToString(port)}
            </div>
          </>
        );
      }}
    />
  );
};
