import React from 'react';
import UniversalPopover from 'components/UniversalPopover';
import VanPositionGridSelector from 'app/components/VanPositionGridSelector';
import { SetterOrUpdater } from 'recoil';
import { TextField } from '@material-ui/core';
import { ItemPosition } from 'types/VanPosition';

interface Props {
  row: ItemPosition;
  selectedLines: ItemPosition[];
  setSelectedLines: SetterOrUpdater<ItemPosition[]>;
}

export default function VanCodeCell(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TextField
        label=""
        variant="outlined"
        margin="dense"
        value={props.row.vanCode ? props.row.vanCode : ''}
        onClick={handleClick}
        style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0' }}
        InputLabelProps={{
          shrink: true, // See #OMS-54
        }}
        InputProps={{
          inputProps: {
            style: { paddingTop: 4, paddingBottom: 4 },
          },
        }}
      />
      <UniversalPopover
        popoverId={'position-select-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <VanPositionGridSelector
          selectedCode={props.row.vanCode}
          onPositionSelected={(vanCode) => {
            const newItems: ItemPosition[] = [];
            for (const currentItem of props.selectedLines) {
              if (currentItem.packLineId === props.row.packLineId) {
                newItems.push({ ...props.row, vanCode: vanCode });
              } else {
                newItems.push(currentItem);
              }
            }
            props.setSelectedLines(newItems);
            handleClose();
          }}
        />
      </UniversalPopover>
    </div>
  );
}
