import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { HistoryRecordType } from 'api/GQL_Types';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';
import { formatDateTime } from 'types/Date';
import { PoPageDataHistoryEntry } from './states';

const classes = makeCss({
  root: {
    padding: theme.spacing(1, 0),

    '&$isClosed:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& + &': {
      borderTop: '1px solid #eee',
    },
  },
  isClosed: {},
  header: {
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props {
  entry: PoPageDataHistoryEntry;
}

export const PoHistoryEntry: React.FC<Props> = ({ entry }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <div className={clsx(classes.root, !open && classes.isClosed)}>
      <div
        className={classes.header}
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        <div style={{ flex: 1 }}>
          <b>{humanizeHistoryRecordType(entry.recordType)}</b>
        </div>
        <div style={{ padding: theme.spacing(0, 1) }}>
          <i>{formatDateTime(entry.timestamp)}</i>
        </div>
        {open ? (
          <KeyboardArrowUpIcon fontSize="medium" style={{ cursor: 'pointer' }} />
        ) : (
          <KeyboardArrowDownIcon fontSize="medium" style={{ cursor: 'pointer' }} />
        )}
      </div>

      {open && (
        <Box padding={1}>
          <Box padding={1} bgcolor="#ededed" border="1px solid #CCC">
            <div>
              <Typography variant="h5" color="textPrimary" style={{ whiteSpace: 'pre-wrap' }}>
                {entry.message}
              </Typography>
            </div>
            <br />
            <div>
              Performed by <i>{entry.byUserName || 'the system'}</i>{' '}
              {entry.byPoUploadFileName && (
                <span>
                  with <i>{entry.byPoUploadFileName}</i> file upload.
                </span>
              )}
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

function humanizeHistoryRecordType(type: HistoryRecordType): string {
  switch (type) {
    case 'NEW_PO':
      return 'New PO';
    case 'NEW_OL':
      return 'Added Order Line';
    case 'UPDATED_PO_FIELD':
      return 'Updated PO Header';
    case 'UPDATED_OL_FIELD':
      return 'Updated Order Line';
  }
  return type;
}
