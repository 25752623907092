import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { makeCss, theme } from 'styles';
import { FormInputText } from './FormInputText';

const classes = makeCss({
  aliasRow: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
  aliasesLabel: {
    color: '#383838',
    fontWeight: 800,
    marginRight: theme.spacing(0.5),
    fontSize: '0.75em',
    paddingTop: theme.spacing(0.5),
  },
  alias: {
    display: 'flex',
    background: '#ccc',
    margin: theme.spacing(0.25),
    borderRadius: theme.shape.borderRadius,
    cursor: 'default',
    userSelect: 'none',
  },
  aliasName: {
    padding: theme.spacing(0, 0, 0, 1),
    height: '100%',
    paddingBottom: '2px',
  },
  aliasRemoveBtn: {
    fontSize: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5),
    height: '100%',
  },
});

export interface Props {
  text: string;
  onText(text: string): void;
  aliases: string[];
  onAliases(aliases: string[]): void;

  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export const FormInputTextWithAliases: React.FC<Props> = (props) => {
  const refAddButton = React.useRef<HTMLButtonElement>(null);
  const refAddAliasInput = React.useRef<HTMLInputElement>(null);
  const [showAliasInput, setShowAliasInput] = React.useState<boolean>(false);
  const [newAliasText, setNewAliasText] = React.useState<string>('');

  React.useEffect(() => {
    let timeoutHandle: any;
    if (showAliasInput) {
      timeoutHandle = setTimeout(() => {
        if (refAddAliasInput.current) {
          refAddAliasInput.current.focus();
        }
      }, 100);
    }
    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [showAliasInput]);

  return (
    <div>
      <FormInputText
        value={props.text}
        onValue={props.onText}
        label={props.label}
        error={props.error}
        disabled={props.disabled}
        required={props.required}
        endAdornment={
          <Button
            ref={refAddButton}
            color="primary"
            size="small"
            onClick={() => {
              setShowAliasInput(true);
            }}
            disableRipple // Causes issues when the add alias is closed via enter key
          >
            + Alias
          </Button>
        }
      />

      {props.aliases.length > 0 && (
        <div className={classes.aliasRow}>
          <div className={classes.aliasesLabel}>
            {props.aliases.length === 1 ? 'Alias:' : 'Aliases:'}
          </div>

          {props.aliases.map((alias, i) => {
            return (
              <div key={i} className={classes.alias}>
                <div className={classes.aliasName}>{alias}</div>
                <div
                  className={classes.aliasRemoveBtn}
                  onClick={() => {
                    props.onAliases(props.aliases.filter((a) => a !== alias));
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <Popover
        open={showAliasInput}
        anchorEl={refAddButton.current}
        onClose={() => {
          setShowAliasInput(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div style={{ padding: theme.spacing(0.25, 1, 0.5, 1) }}>
          <TextField
            label="Add Alias"
            variant="outlined"
            margin="dense"
            inputRef={refAddAliasInput}
            value={newAliasText}
            onChange={(e) => setNewAliasText(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                if (refAddAliasInput.current) {
                  refAddAliasInput.current.blur();
                }
                addAlias();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Add alias"
                    onClick={addAlias}
                    disabled={newAliasText.trim() === ''}
                    edge="end"
                    size="small"
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { paddingRight: '10px' },
            }}
          />
        </div>
      </Popover>
    </div>
  );

  function addAlias() {
    const newAlias = newAliasText.trim();
    props.onAliases(Array.from(new Set([...props.aliases, newAlias])));
    setNewAliasText('');
    setShowAliasInput(false);
  }
};
