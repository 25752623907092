import { useDivClientWidthHeight } from 'lib/useDivClientWidthHeight';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { SwitchWithLabel } from './SwitchWithLabel';

const classes = makeCss({
  root: {
    width: '100%',
    columnGap: theme.spacing(3),
  },
});

export interface Props<T> {
  possibleValues: { key: string; value: T; label: string; tooltip?: string }[];
  values: T[];
  onToggle(value: T, checked: boolean): void;

  // when true, all switches will be disabled and you will receive no onToggle events
  disabled?: boolean;

  // How many pixels do your switches need? default: 300
  minSwitchWidthPx?: number;
}

export function SwitchList<T>({
  possibleValues,
  values,
  onToggle,
  disabled,
  minSwitchWidthPx,
}: Props<T>) {
  const { ref, width } = useDivClientWidthHeight();

  minSwitchWidthPx = minSwitchWidthPx ?? 300;
  const nColumns = Math.max(Math.floor(width / minSwitchWidthPx), 1);

  return (
    <div ref={ref} className={classes.root} style={{ columns: nColumns }}>
      {possibleValues.map((item) => {
        return (
          <SwitchWithLabel
            key={item.key}
            checked={values.includes(item.value)}
            onChange={(checked) => {
              onToggle(item.value, checked);
            }}
            label={item.label}
            tooltip={item.tooltip}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
}
