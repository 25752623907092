import { Box } from "@material-ui/core";
import { ShipmentStatuses, transformContainerToShipmentInTransitDisplay } from "app/dashboard/states";
import { theme } from "styles";
import { CenterLabelBox } from "./CenterLabelBox";

interface Props {
  title: string;
  milestone: ShipmentStatuses;
  height: number;
}

export default function MilestoneDisplay(props: Props) {
  let earlyTotal = countContainers(props.milestone.early);
  let onTimeTotal = countContainers(props.milestone.onTime);
  let lateTotal = countContainers(props.milestone.late);

  function countContainers(containers: any) {
    let total = 0;
    if (containers) {
      total += containers.length;
    }
    return total;
  }

  return (
    <Box display="flex" flexGrow={1} flexDirection="row">
      <CenterLabelBox
        rowHeight={props.height}
        color={theme.palette.indigoBlue.main}
        data={transformContainerToShipmentInTransitDisplay(props.milestone.early)}
        popoverLabel={props.title}
      >
        {earlyTotal}
      </CenterLabelBox>
      <CenterLabelBox
        rowHeight={props.height}
        color={theme.palette.blueGreen.main}
        data={transformContainerToShipmentInTransitDisplay(props.milestone.onTime)}
        popoverLabel={props.title}
      >
        {onTimeTotal}
      </CenterLabelBox>
      <CenterLabelBox
        rowHeight={props.height}
        color={theme.palette.orange.main}
        data={transformContainerToShipmentInTransitDisplay(props.milestone.late)}
        popoverLabel={props.title}
      >
        {lateTotal}
      </CenterLabelBox>
    </Box>
  );
}