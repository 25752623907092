import { Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Location, navigate, RouteComponentProps } from '@reach/router';
import { AuthState, UserContext, UWLAuth } from 'api/Auth';
import ErrorMessage from 'components/ErrorMessage';
import { Line, StretchFitRouter } from 'components/StyledComponents';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { theme, useGlobalStyles } from 'styles';
import Layout, { SysAdminLayout } from './layout';
import Loading from './Loading';
import NewWindowTable from './NewWindowTable';
import NotFound from './NotFound';
import { useSetupApiConstants } from './states';

export const auth = new UWLAuth();
export const userContextAtom = newAtom<UserContext | null>(null);

function App() {
  useGlobalStyles();
  useSetupApiConstants();
  const authState = auth.useAuthState();
  const setUserContext = useSetRecoilState(userContextAtom);

  React.useEffect(() => {
    auth.setupOnPageMount();
  }, []);

  React.useEffect(() => {
    setUserContext(authState.userContext);
  }, [authState.userContext]);

  if (authState.isWaitingForAuth) {
    return <Loading />;
  }

  if (authState.userContext) {
    return (
      <Location>
        {({ location }) => {
          checkRoutes(location.pathname);
          return <AuthenticatedRouter />;
        }}
      </Location>
    );
  }

  return <AuthError authState={authState} />;
}

interface Props extends RouteComponentProps {}
function AuthenticatedRouter(props: Props) {
  return (
    <StretchFitRouter>
      <NotFound default path="/not-found" />
      <SysAdminLayout path="/sys-admin/*" />
      <NewWindowTable path="/:tableId/table-view" />
      <Layout path="/*" />
    </StretchFitRouter>
  );
}

function checkRoutes(path: string) {
  if (
    path.startsWith('/') ||
    path.startsWith('/admin') ||
    path.startsWith('/purchase-orders') ||
    path.startsWith('/bookings') ||
    path.startsWith('/consolidations') ||
    path.startsWith('/equipment') ||
    path.startsWith('/hbl') ||
    path.startsWith('/mbl')
  ) {
    return;
  } else {
    navigate('/');
  }
}

const AuthError: React.FC<{ authState: AuthState }> = ({ authState }) => {
  return (
    <div>
      <div style={{ margin: '30px auto', maxWidth: '400px' }}>
        <Paper>
          <div style={{ padding: theme.spacing(2) }}>
            <Typography variant="h2">Sign In Error</Typography>
            <Line height={1} />
            <ErrorMessage error={authState.ssoError} />
            <ErrorMessage error={authState.userContextLoadError} />
            <ErrorMessage
              error={
                !authState.ssoError && !authState.userContextLoadError
                  ? 'Your login is not attached to an active contact in OMS.'
                  : null
              }
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                auth.signout();
              }}
            >
              Sign In Again
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default App;
