import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ReviewUploadPage from './ReviewUploadPage';
import UploadPOListPage from './UploadedPOListPage';
import UploadPOPage from './UploadPOPage';
import { FlexGrowRouter } from 'components/StyledComponents';

interface PoUploadProps extends RouteComponentProps {}
function PoUploads(props: PoUploadProps) {
  return (
    <FlexGrowRouter>
      <UploadPOListPage path="/" />
      <UploadPOPage path="/new/*" />
      <ReviewUploadPage path="/:poFileId/*" />
    </FlexGrowRouter>
  );
}

export default PoUploads;
