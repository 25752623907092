import { PartyType } from 'api/GQL_Types';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { ExceptionQueryResult } from '.';
import { TableLink } from '../../states';

export interface DelayedUnloadException {
  id: string;
  bookingNumber: TableLink;
  vendorName: string;
  expectedCargoReadyDate: Date | null | undefined;
  poNumber: TableLink[];
  cargoReadyDate: Date | null | undefined;
  destination: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  deliveryEta: Date | null | undefined;
}

export function DelayedUnloadException_toRow(
  exception: ExceptionQueryResult
): DelayedUnloadException | null {
  if (exception.__typename !== 'DelayedArrivalException') {
    return null;
  }
  const booking = exception.booking;

  return {
    id: booking.id,
    bookingNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
    vendorName: relatedPartyNameOrVarious(booking.relatedParties, PartyType.Supplier),
    expectedCargoReadyDate: booking.packLines
      .map((pl: any) => {
        return pl.orderLine.expectedCargoReadyDate;
      })
      .filter((d) => d)[0],
    poNumber: booking.packLines.map((pl: any) => {
      return {
        to: '/purchase-orders/' + pl.orderLine.purchaseOrder.id,
        value: pl.orderLine.purchaseOrder.poNumber,
      };
    }),
    cargoReadyDate: booking.cargoReadyDate,
    destination: booking.logistics.finalDestination?.name || '',
    polEtd: booking.logistics.polEtd,
    polAtd: booking.logistics.polAtd,
    podEta: booking.logistics.podEta,
    podAta: booking.logistics.podAta,
    deliveryEta: booking.logistics.deliveryEta,
  };
}
