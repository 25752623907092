import { Box, Typography } from '@material-ui/core';
import { NotificationCode } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { SwitchListGroup } from 'components/SwitchListGroup';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  options: NotificationCode[];
  values: NotificationCode[];
  onValues: (codes: NotificationCode[]) => void;

  // when true, all switches will be disabled and you will receive no onValues events
  disabled?: boolean;
}

export default function NotificationCodeSwitches(props: Props) {
  const notificationCodes = useRecoilValue(AppWideStates.notificationCodes);

  const groupsRendered: React.ReactNode[] = [];
  for (const groupName of notificationCodes.groupOrder) {
    const items = (notificationCodes.byGroup.get(groupName) || []).filter((perm) =>
      props.options.includes(perm.code)
    );
    if (items.length === 0) {
      continue;
    }
    groupsRendered.push(
      <SwitchListGroup
        key={groupName}
        title={groupName}
        possibleValues={items.map((notificationCode) => {
          return {
            key: notificationCode.code,
            value: notificationCode.code,
            label: notificationCode.name,
            tooltip: notificationCode.description,
          };
        })}
        values={props.values}
        onToggle={(code, checked) => {
          let newCodes = [...props.values];
          if (checked) {
            newCodes.push(code);
          } else {
            newCodes = newCodes.filter((a) => a !== code);
          }
          props.onValues(newCodes);
        }}
        disabled={props.disabled}
        defaultExpanded
      />
    );
  }

  return (
    <Box width="100%">
      <Typography variant="h2">Notifications</Typography>
      <hr />
      <div>{groupsRendered.length === 0 ? '- none -' : groupsRendered}</div>
    </Box>
  );
}
