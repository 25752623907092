import { IconButton, InputAdornment } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import clsx from 'clsx';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { toAPI_Date as toIsoDateNoTZ } from 'types/Date';
import { formatDate } from 'types/Date';
import { FormInputDate } from './FormInputDate';
import { FormInputText } from './FormInputText';

const classes = makeCss({
  popoverRoot: {
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  dateInputsRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  presetButton: {
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#F0F0F0',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    textAlign: 'center',
    '& + &': {
      marginTop: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    '&$active': {
      backgroundColor: '#3a81b9',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#3d88c2',
      },
    },
  },
  active: {},
  input: {
    '&:hover $clearButtonDirty, & .Mui-focused $clearButtonDirty': {
      visibility: 'visible',
    },
  },
  clearButtonDirty: {},
  clearButton: {
    padding: '2px',
    visibility: 'hidden',
  },
});

type DateRangeValueType =
  | 'range'
  | 'today'
  | 'yesterday'
  | 'mtd'
  | 'last-month'
  | 'past-3-months'
  | 'next-n-days'
  | 'plus-minus-n-days'
  | 'q1'
  | 'q2'
  | 'q3'
  | 'q4'
  | 'ytd'
  | 'last-n-days'
  | 'next-week'
  | 'next-month';

export interface DateRangeValue {
  type: DateRangeValueType;
  from?: Date | null;
  to?: Date | null;
  nDays?: number | null;
}

export function calculateDateRange(value: DateRangeValue): { from: Date; to: Date } {
  const now = new Date();
  switch (value.type) {
    case 'today':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      };
    case 'yesterday':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
      };
    case 'mtd':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), 1),
        to: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      };
    case 'last-month':
      return {
        from: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        to: new Date(now.getFullYear(), now.getMonth(), 0),
      };
    case 'next-month':
      return {
        from: new Date(now.getFullYear(), now.getMonth() + 1, 1),
        to: new Date(now.getFullYear(), now.getMonth() + 2, 0),
      };
    case 'past-3-months':
      return {
        from: new Date(now.getFullYear(), now.getMonth() - 3, 1),
        to: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      };
    case 'next-n-days':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate() + (value.nDays || 0)),
      };
    case 'next-week':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14),
      };
    case 'last-n-days':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - (value.nDays || 0)),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      };
    case 'plus-minus-n-days':
      return {
        from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - (value.nDays || 0)),
        to: new Date(now.getFullYear(), now.getMonth(), now.getDate() + (value.nDays || 0)),
      };
    case 'q1':
      return {
        from: new Date(now.getFullYear(), 0, 1),
        to: new Date(now.getFullYear(), 3, 0),
      };
    case 'q2':
      return {
        from: new Date(now.getFullYear(), 3, 1),
        to: new Date(now.getFullYear(), 6, 0),
      };
    case 'q3':
      return {
        from: new Date(now.getFullYear(), 6, 1),
        to: new Date(now.getFullYear(), 9, 0),
      };
    case 'q4':
      return {
        from: new Date(now.getFullYear(), 9, 1),
        to: new Date(now.getFullYear(), 12, 0),
      };
    case 'ytd':
      return {
        from: new Date(now.getFullYear(), 0, 1),
        to: new Date(now.getFullYear() + 1, 0, 0),
      };
    case 'range':
  }
  return {
    to: value.to || new Date(),
    from: value.from || new Date(),
  };
}

export interface DateRangePreset {
  type: DateRangeValueType;
  label: string;
  nDays?: number | null;
}

export const presetsPast: DateRangePreset[] = [
  { type: 'ytd', label: 'This Year' },
  { type: 'mtd', label: 'This Month' },
  { type: 'q1', label: 'First Quarter' },
  { type: 'q2', label: 'Second Quarter' },
  { type: 'q3', label: 'Third Quarter' },
  { type: 'q4', label: 'Fourth Quarter' },
];

export const presetsFuture: DateRangePreset[] = [
  { type: 'plus-minus-n-days', label: '+/- 30 Days', nDays: 30 },
  { type: 'next-n-days', label: 'Next 30 Days', nDays: 30 },
  { type: 'next-n-days', label: 'Next 90 Days', nDays: 90 },
  { type: 'ytd', label: 'This Year' },
  { type: 'mtd', label: 'This Month' },
];

export const presetsDashboard: DateRangePreset[] = [
  { type: 'plus-minus-n-days', label: '+/- 30 Days', nDays: 30 },
  { type: 'last-n-days', label: 'Last Month (-30d)', nDays: 30 },
  { type: 'last-n-days', label: 'Last Week (-7d)', nDays: 7 },
  { type: 'today', label: 'Today' },
  { type: 'next-n-days', label: 'This Week (+7d)', nDays: 7 },
  { type: 'next-week', label: 'Next Week (8-14d)' },
  { type: 'next-n-days', label: 'Next Month (+30d)', nDays: 30 },
];

interface Props {
  value: DateRangeValue | null;
  onValue(v: DateRangeValue | null): void;

  label?: string;

  presets: DateRangePreset[];

  clearable?: boolean;
}

export const FormInputDateRange: React.FC<Props> = ({
  value,
  onValue,
  label,
  presets,
  clearable,
}) => {
  const anchorRef = React.useRef<HTMLInputElement>(null);
  const [showPopover, setShowPopover] = React.useState<boolean>(false);

  const dateRange = value ? calculateDateRange(value) : null;

  const valueAsString = dateRange
    ? `${formatDate(dateRange.from)} - ${formatDate(dateRange.to)}`
    : '';

  return (
    <>
      <FormInputText
        className={classes.input}
        inputRef={anchorRef}
        value={valueAsString}
        onValue={() => {}}
        onKeyDown={(e) => {
          switch (e.code) {
            case 'Enter':
              setShowPopover(true);
          }
        }}
        onClick={(e) => {
          setShowPopover(true);
        }}
        placeholder="mm/dd/yyyy - mm/dd/yyyy"
        label={label}
        readOnly
        // required={required}
        // disabled={disabled}
        // error={!!error}
        // helperText={error || ''}
        endAdornment={
          <InputAdornment position="end" style={{ marginRight: theme.spacing(-1) }}>
            {clearable && (
              <IconButton
                title="Clear"
                className={clsx(classes.clearButton, {
                  [classes.clearButtonDirty]: !!value,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  onValue(null);
                }}
                size="small"
                tabIndex={-1}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              // disabled={disabled}
              size="small"
              onClick={(e) => {
                setShowPopover(!showPopover);
              }}
            >
              <EventIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      <Popover
        open={showPopover}
        anchorEl={anchorRef.current}
        onClose={(e) => {
          setShowPopover(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.popoverRoot}>
          <div className={classes.dateInputsRow}>
            <FormInputDate
              label="From"
              value={dateRange?.from || null}
              onValue={(d) => {
                if (!d) {
                  onValue(null);
                } else if (!dateRange) {
                  onValue({ type: 'range', from: d, to: new Date(d.getTime()) });
                } else if (toIsoDateNoTZ(dateRange.to) < toIsoDateNoTZ(d)) {
                  onValue({ type: 'range', from: d, to: new Date(d.getTime()) });
                } else {
                  onValue({ type: 'range', from: d, to: dateRange.to });
                }
              }}
            />
            <div style={{ width: theme.spacing(2) }} />
            <FormInputDate
              label="To"
              value={dateRange?.to || null}
              onValue={(d) => {
                if (!d) {
                  onValue(null);
                } else if (!dateRange) {
                  onValue({ type: 'range', from: new Date(d.getTime()), to: d });
                } else if (toIsoDateNoTZ(dateRange.from) > toIsoDateNoTZ(d)) {
                  onValue({ type: 'range', from: new Date(d.getTime()), to: d });
                } else {
                  onValue({ type: 'range', from: dateRange.from, to: d });
                }
              }}
            />
          </div>
          <div>
            {presets.map((preset) => {
              return (
                <PresetButton
                  key={`${preset.type}-${preset.nDays || 0}`}
                  active={value?.type === preset.type && value?.nDays === preset.nDays}
                  label={preset.label}
                  onClick={() => onValue({ type: preset.type, nDays: preset.nDays })}
                />
              );
            })}
          </div>
        </div>
      </Popover>
    </>
  );
};

const PresetButton: React.FC<{ active: boolean; label: string; onClick(): void }> = ({
  active,
  label,
  onClick,
}) => {
  return (
    <div className={clsx(classes.presetButton, active && classes.active)} onClick={onClick}>
      {label}
    </div>
  );
};
