import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import { BookingList } from './booking-list';
import BookingDetails from './details';
import NewBooking from './new';
import { ShippedList } from './shipped-list';

interface BookingProps extends RouteComponentProps {}

export default function Bookings(props: BookingProps) {
  return (
    <FlexGrowRouter>
      <BookingList path="/" />
      <NewBooking path="/new" />
      <BookingDetails path="/:bookingId" />
      <ShippedList path="/shipped" />
    </FlexGrowRouter>
  );
}
