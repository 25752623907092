import { FormInputDate } from 'components/form/FormInputDate';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  state: RecoilState<Date | null | undefined>;
  label: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

export default function AtomicDatePickerV2(props: Props) {
  const [field, setField] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputDate
        value={field || null}
        onValue={setField}
        label={props.label}
        disabled={props.disabled}
        required={props.required}
        error={props.error}
        minDate={props.minDate}
        maxDate={props.maxDate}
        clearable
      />
    </FormItem>
  );
}
