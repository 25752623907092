import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Button } from '@material-ui/core';
import {
  GqlAssignConsolidationContainerMutation,
  GqlAssignConsolidationContainerMutationVariables,
  GqlUpdateContainerMutation,
  GqlUpdateContainerMutationVariables,
  GqlVanPositionInput,
} from 'api/GQL_Types';
import { assignConsolidationContainer, ConsolidationQuery } from 'api/queries/consolidationQueries';
import { updateContainer } from 'api/queries/containerQueries';
import { ConsolidationPageStates } from 'app/consolidations/details/states';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemPosition } from 'types/VanPosition';

export default function SubmitFooter() {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [selectedContainer, setSelectedContainer] = useRecoilState(
    ConsolidationPageStates.containerAssignment.selectedContainer
  );
  // TODO Make this all of the pack lines
  const selectedItems = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.selectedConsolidationPackLines
  );
  const containerNumber = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.containerNumber
  );
  const containerType = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.containerType
  );
  const sealNumber = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.sealNumber
  );
  const shipFromFactory = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.shipFromFactory
  );
  const shipFromCfs = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.shipFromCfs
  );
  const terminalReceived = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.terminalReceived
  );
  const cntrConsolidationDate = useRecoilValue(
    ConsolidationPageStates.containerAssignment.editContainer.cntrConsolidationDate
  );

  const { enqueueSnackbar } = useSnackbar();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ConsolidationQuery,
      variables: { consolId: consolidation?.id },
    },
  ];

  const updateContainerAction = useAsyncAction<
    GqlUpdateContainerMutationVariables,
    GqlUpdateContainerMutation
  >((input) => updateContainer(input, { refetchQueries }), {
    onData(data) {
      setSelectedContainer(null);
      enqueueSnackbar('Container Updated!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to update container: ' + error, { variant: 'error' });
    },
  });

  const assignConsolidationContainerAction = useAsyncAction<
    GqlAssignConsolidationContainerMutationVariables,
    GqlAssignConsolidationContainerMutation
  >((input) => assignConsolidationContainer(input, { refetchQueries }), {
    onData(data) {
      setSelectedContainer(null);
      enqueueSnackbar('Container Assigned!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to assign container: ' + error, { variant: 'error' });
    },
  });

  return (
    <UniversalDialogFooter
      error={updateContainerAction.error || assignConsolidationContainerAction.error}
    >
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => {
          setSelectedContainer(null);
        }}
        disabled={updateContainerAction.waiting || assignConsolidationContainerAction.waiting}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={
          hasIncompleteItems(selectedItems) ||
          updateContainerAction.waiting ||
          assignConsolidationContainerAction.waiting
        }
        onClick={() => {
          const vanPos: GqlVanPositionInput[] = [];
          for (const itemPos of selectedItems) {
            vanPos.push({
              piecesQty: itemPos.piecesQty,
              cartonsQty: itemPos.cartonsQty,
              volumeM3: itemPos.volumeM3,
              weightKg: itemPos.weightKg,
              vanCode: itemPos.vanCode,
              packLineId: itemPos.packLineId,
            });
          }
          if (selectedContainer?.containerNumber) {
            updateContainerAction.act({
              input: {
                container: {
                  id: selectedContainer?.id,
                  containerNumber: containerNumber,
                  containerType: containerType,
                  sealNumber: sealNumber,
                  shipFromFactoryDate: shipFromFactory,
                  shipFromCfsDate: shipFromCfs,
                  terminalReceivedDate: terminalReceived,
                  cntrConsolidationDate: cntrConsolidationDate,
                },
                vanPositions: vanPos,
              },
            });
          } else {
            assignConsolidationContainerAction.act({
              input: {
                consolidationId: consolidation?.id || '',
                container: {
                  id: selectedContainer?.id,
                  containerNumber: containerNumber,
                  containerType: containerType,
                  sealNumber: sealNumber,
                  shipFromCfsDate: shipFromCfs,
                  shipFromFactoryDate: shipFromFactory,
                  terminalReceivedDate: terminalReceived,
                  cntrConsolidationDate: cntrConsolidationDate,
                },
                vanPositions: vanPos,
              },
            });
          }
        }}
      >
        Save
      </Button>
    </UniversalDialogFooter>
  );
}

function hasIncompleteItems(selectedItems: ItemPosition[]) {
  for (const item of selectedItems) {
    if (!item.packLineId) {
      return true;
    }
  }
  return false;
}
