import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { BookingPageStates, ContainerRow } from '../states';
import EquipmentAssignmentDialog from './equipment-assignment-dialog';

const columns: UWLTableColumn<ContainerRow>[] = [
  { id: 'container', label: 'Container', type: 'link' },
  { id: 'containerType', label: 'Container Type', type: 'string' },
  { id: 'sealNumber', label: 'Seal', type: 'string' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: 'CBM' },
  { id: 'weightKG', label: 'Weight', type: 'weight', unit: 'KG' },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
];

interface Props {
  bookingId: string;
}
export default function BookingEquipment(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const [open, setOpen] = React.useState(false);
  const booking = useRecoilValue(BookingPageStates.booking);

  return (
    <>
      <Panel
        title="Equipment"
        topRight={
          userContext?.permissionCodes.has(PermissionCode.BookingContainerUpdate) && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(true);
              }}
              disabled={booking?.consolidation?.id ? true : false}
            >
              Edit
            </Button>
          )
        }
      >
        <PanelBodyTable>
          <UWLTable
            rowId="id"
            columns={columns}
            rows={booking?.containerRows ?? []}
            emptyMessage="No Containers Available"
            renderCell={{
              container(row) {
                return (
                  <span>
                    {row.isHot && (
                      <FontAwesomeIcon
                        icon={faFire}
                        style={{ color: 'orange', marginRight: theme.spacing(1) }}
                        size="lg"
                      />
                    )}
                    {row.container ? (
                      <Link to={row.container.to}>{row.container.value}</Link>
                    ) : (
                      <span>Not Assigned</span>
                    )}
                  </span>
                );
              },
            }}
          />
        </PanelBodyTable>
      </Panel>
      <EquipmentAssignmentDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}
