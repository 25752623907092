import {
  ContainerMode,
  ContractType,
  GqlCarrier,
  GqlLocation,
  GqlPort,
  GqlRelatedParty,
  IncoTerm,
  MoveType,
  TransportMode,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { EquipmentSelection } from 'components/EquipmentSelectionPanel';
import { DateAtomType, genKey, newAtom } from 'lib/RecoilUtils';
import { selector, useSetRecoilState } from 'recoil';
import {
  calcCombinedContainerSize,
  CombinedContainerTypesSize,
  containerUtilizationWarnings,
  NetworkContainerType,
} from 'types/NetworkContainerType';

export interface OpenOrderLine {
  id: string;
  poId: string;
  orderNumber: string;
  itemNumber: string;
  lineNumber: string;
  orderQty: number;
  balanceQty: number;
  shippedQty: number;
  shippedCartons: number;
  weight: number;
  weightUnit: WeightUnit;
  weightKg: number;
  volume: number;
  volumeUnit: VolumeUnit;
  volumeM3: number;
  expectedCargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  firstShipDate: Date | null | undefined;
  lastShipDate: Date | null | undefined;
  requestedDeliveryDate: Date | null | undefined; //check
  shipToLocationName: string;
  supplierName: string;
}

export interface PackLine extends OpenOrderLine {
  relatedParties: GqlRelatedParty[];
}

export const NewBookingPageStates = {
  activeStep: newAtom<0 | 1>(0),

  step1: {
    allLines: newAtom<OpenOrderLine[]>([]),

    selectedLines: newAtom<OpenOrderLine[]>([]),
    selectedLinesChecked: newAtom<string[]>([]),

    availableLines: newAtom<OpenOrderLine[]>([]),
    availableLinesChecked: newAtom<string[]>([]),
  },

  step2: {
    // Step 2 form fields
    formBookingParty: newAtom<string>(''),
    formSupplier: newAtom<GqlRelatedParty | null>(null),
    formConsignee: newAtom<GqlRelatedParty | null>(null),
    formManufacturer: newAtom<GqlRelatedParty | null>(null),
    formTransportationMode: newAtom<TransportMode | null>(TransportMode.Sea),
    formContainerMode: newAtom<ContainerMode | null>(ContainerMode.Fcl),
    formIncoTerm: newAtom<IncoTerm | null>(null),
    formMoveType: newAtom<MoveType | null>(MoveType.CyCy),
    formPickupLocation: newAtom<GqlLocation | null>(null),
    formDeliveryLocation: newAtom<GqlLocation | null>(null),
    formCargoReadyDate: newAtom<DateAtomType>(null),
    formCarrier: newAtom<GqlCarrier | null>(null),
    formContract: newAtom<string>(''),
    formContractType: newAtom<ContractType | null>(ContractType.Nvo),
    formEtd: newAtom<DateAtomType>(null),
    formEta: newAtom<DateAtomType>(null),
    formPol: newAtom<GqlPort | null>(null),
    formPod: newAtom<GqlPort | null>(null),

    equipmentList: newAtom<EquipmentSelection[]>([]),

    bookingRemark: newAtom(''),

    packLines: newAtom<PackLine[]>([]),
  },

  newBooking: newAtom<{
    bookingId: string;
    referenceNumber: string;
  } | null>(null),
};

export function useSetupStep1() {
  const setAllLines = useSetRecoilState(NewBookingPageStates.step1.allLines);
  const setSelectedLines = useSetRecoilState(NewBookingPageStates.step1.selectedLines);
  const setSelectedLinesChecked = useSetRecoilState(
    NewBookingPageStates.step1.selectedLinesChecked
  );
  const setAvailableLines = useSetRecoilState(NewBookingPageStates.step1.availableLines);
  const setAvailableLinesChecked = useSetRecoilState(
    NewBookingPageStates.step1.availableLinesChecked
  );

  return (openOrders: OpenOrderLine[]) => {
    setAllLines(openOrders);
    setSelectedLines([]);
    setSelectedLinesChecked([]);
    setAvailableLines(openOrders);
    setAvailableLinesChecked([]);
  };
}

export interface Step1Validation {
  canGoToStep2: boolean;
  whyCantGoToStep2?: string;
}

export const step1Validation = selector<Step1Validation>({
  key: genKey(),
  get: ({ get }) => {
    const selectedLines = get(NewBookingPageStates.step1.selectedLines);
    const networkRules = get(AppWideStates.network.rules);

    if (networkRules.bookingQtyToleranceEnabled && networkRules.bookingQtyToleranceHardStop) {
      for (const line of selectedLines) {
        let qtyDiff = line.shippedQty / line.orderQty;
        if (qtyDiff - 1 > networkRules.bookingQtyTolerancePercentOver) {
          return {
            canGoToStep2: false,
            whyCantGoToStep2: 'Over Tolerance',
          };
        } else if (1 - qtyDiff > networkRules.bookingQtyTolerancePercentUnder) {
          return {
            canGoToStep2: false,
            whyCantGoToStep2: 'Under Tolerance',
          };
        }
      }
    }

    return { canGoToStep2: true };
  },
});

export interface Step2Validation {
  canCreate: boolean;
  whyCantCreate?: string;

  bookedWeight: number;
  bookedVolume: number;
  eqpTypesSize: CombinedContainerTypesSize;
  utilizationWarnings: string[];
}

export const step2Validation = selector<Step2Validation>({
  key: genKey(),
  get: ({ get }) => {
    const rules = get(AppWideStates.network.rules);
    const containerTypes = get(AppWideStates.network.containerTypes);
    const cargoReadyDate = get(NewBookingPageStates.step2.formCargoReadyDate);
    const equipmentList = get(NewBookingPageStates.step2.equipmentList);
    const packLines = get(NewBookingPageStates.step2.packLines);
    const consignee = get(NewBookingPageStates.step2.formConsignee);
    const supplier = get(NewBookingPageStates.step2.formSupplier);
    const manufacturer = get(NewBookingPageStates.step2.formManufacturer);

    const eqps: NetworkContainerType[] = [];
    for (const eqp of equipmentList) {
      for (let i = 0; i < eqp.qty; i++) {
        const containerType = containerTypes.byCode.get(eqp.containerType);
        if (containerType) {
          eqps.push(containerType);
        }
      }
    }
    const eqpTypesSize = calcCombinedContainerSize(eqps);

    let bookedWeight = 0;
    let bookedVolume = 0;
    for (const pl of packLines) {
      bookedWeight += pl.weight;
      bookedVolume += pl.volume;
    }

    let utilizationWarnings: string[] = [];

    if (rules.bookingContainerCapacityToleranceEnabled) {
      utilizationWarnings = containerUtilizationWarnings(
        bookedWeight,
        bookedVolume,
        eqps,
        packLines.length
      );
    }

    const validation: Step2Validation = {
      canCreate: true,
      bookedWeight,
      bookedVolume,
      eqpTypesSize,
      utilizationWarnings,
    };

    if (!cargoReadyDate) {
      validation.canCreate = false;
      validation.whyCantCreate = 'Missing Cargo Ready Date';
      return validation;
    }

    if (!consignee) {
      validation.canCreate = false;
      validation.whyCantCreate = 'Missing Consignee';
      return validation;
    }
    if (!supplier) {
      validation.canCreate = false;
      validation.whyCantCreate = 'Missing Supplier';
      return validation;
    }
    if (!manufacturer) {
      validation.canCreate = false;
      validation.whyCantCreate = 'Missing Manufacturer';
      return validation;
    }

    if (
      rules.bookingContainerCapacityToleranceEnabled &&
      rules.bookingContainerCapacityToleranceHardStop &&
      utilizationWarnings.length > 0
    ) {
      validation.canCreate = false;
      validation.whyCantCreate = 'Container Utilization is out of range';
      return validation;
    }

    return validation;
  },
});

export const step2ForceLCL = selector<boolean>({
  key: genKey(),
  get: ({ get }) => {
    const moveType = get(NewBookingPageStates.step2.formMoveType);
    const transportMode = get(NewBookingPageStates.step2.formTransportationMode);

    // See OMS-155

    switch (moveType) {
      case MoveType.CfsCfs:
      case MoveType.DoorCfs:
      case MoveType.CfsDoor:
      case MoveType.CfsCy:
        return true;
    }

    switch (transportMode) {
      case TransportMode.Air:
        return true;
    }

    return false;
  },
});
