import { DocumentEventCode } from 'api/GQL_Types';
import { FormInputDateRange, presetsPast } from 'components/form/FormInputDateRange';
import FormInputSelect from 'components/form/FormInputSelect';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import React from 'react';
import { useRecoilState } from 'recoil';
import { DocumentBrowserStates } from '../states';

const options: { id: string; label: string }[] = [
  { id: DocumentEventCode.UploadDate, label: 'Upload Date' },
  { id: DocumentEventCode.PolEtd, label: 'Load Port ETD' },
  { id: DocumentEventCode.PodEta, label: 'Discharge Port ETA' },
];

export default function DateRange() {
  const [dateField, setDateField] = useRecoilState(DocumentBrowserStates.dateField);
  const [dateRange, setDateRange] = useRecoilState(DocumentBrowserStates.dateRange);

  return (
    <FormRow>
      <FormItem>
        <FormInputSelect
          label="Date Range Field"
          options={options}
          value={dateField}
          onValue={(v) => {
            switch (v) {
              case DocumentEventCode.UploadDate:
              case DocumentEventCode.PolEtd:
              case DocumentEventCode.PodEta:
                setDateField(v);
            }
          }}
        />
      </FormItem>
      <FormItem>
        <FormInputDateRange
          label="Date Range"
          value={dateRange}
          onValue={setDateRange}
          presets={presetsPast}
          clearable
        />
      </FormItem>
    </FormRow>
  );
}
