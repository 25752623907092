import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Close';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { FormInputContainerType } from './form/FormInputContainerType';
import { FormItem } from './form/FormItem';

export interface EquipmentSelection {
  containerType: string;
  qty: number;
}

const columns: UWLTableColumn<EquipmentSelection>[] = [
  { id: 'containerType', label: 'Equipment Type', type: 'string' },
  { id: 'qty', label: 'Equipment Qty', type: 'number', align: 'left' },
];

interface Props {
  value: EquipmentSelection[];
  onValue(value: EquipmentSelection[]): void;

  showMinMax?: boolean;

  disableAdd?: boolean;
  disableRemove?: boolean;
}

export const EquipmentSelectionPanel: React.FC<Props> = (props) => {
  const [equipmentQty, setEquipmentQty] = React.useState<number>(0);
  const [equipmentType, setEquipmentType] = React.useState<string | null>(null);

  return (
    <Panel title="Equipment Selection">
      <Box display="flex" flexDirection="column" height="100%">
        <Box flexGrow={1} style={{ margin: theme.spacing(1, 2, 2, 2), overflow: 'auto' }}>
          <UWLTable
            rowId="containerType"
            rows={props.value}
            columns={columns}
            renderCell={{
              containerType(row) {
                return <Typography variant="body1">{row.containerType}</Typography>;
              },
            }}
            rowAction={(row) => {
              if (props.disableRemove) {
                return null;
              }
              return (
                <IconButton
                  aria-label="delete"
                  style={{ width: '10px', height: '10px' }}
                  onClick={() => {
                    props.onValue(
                      props.value.filter((item) => item.containerType !== row.containerType)
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              );
            }}
          />
        </Box>
        <Box marginX={2} marginBottom={1}>
          <Box marginBottom={1}>
            <Typography
              variant="h3"
              color="secondary"
              style={{ color: props.disableAdd ? 'rgba(0, 0, 0, 0.38)' : undefined }}
            >
              Add Equipment
            </Typography>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <FormItem>
                <FormInputContainerType
                  value={equipmentType}
                  onValue={setEquipmentType}
                  showMinMax={props.showMinMax}
                  disabled={props.disableAdd}
                />
              </FormItem>
            </Grid>

            <Grid item xs={5}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box marginRight={2}>
                  <Button
                    disabled={props.disableAdd || equipmentQty === 0 || !equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty - 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <RemoveCircleIcon
                      color={!equipmentQty || !equipmentType ? 'disabled' : 'secondary'}
                      fontSize="medium"
                    />
                  </Button>
                </Box>
                <Box>
                  <TextField
                    required
                    id="qty"
                    disabled={props.disableAdd || !equipmentType}
                    InputProps={{ inputProps: { min: 0 } }}
                    label="Qty"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={equipmentQty}
                    onChange={(e) => {
                      parseInt(e.target.value) >= 0
                        ? setEquipmentQty(+e.target.value)
                        : setEquipmentQty(0);
                    }}
                  />
                </Box>
                <Box marginLeft={2}>
                  <Button
                    disabled={props.disableAdd || !equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty + 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <AddCircleIcon
                      color={!equipmentType ? 'disabled' : 'secondary'}
                      fontSize="medium"
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '96px' }}
                  onClick={() => {
                    if (!equipmentType) return;
                    let found = false;
                    const list = props.value.map((eqp) => {
                      if (eqp.containerType === equipmentType) {
                        found = true;
                        return {
                          ...eqp,
                          qty: eqp.qty + equipmentQty,
                        };
                      }
                      return eqp;
                    });
                    if (!found) {
                      list.push({ containerType: equipmentType, qty: equipmentQty });
                    }
                    props.onValue(list);
                    setEquipmentType(null);
                    setEquipmentQty(0);
                  }}
                  disabled={props.disableAdd || equipmentQty < 1}
                >
                  <Typography variant="h3" color="textSecondary">
                    ADD
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Panel>
  );
};
