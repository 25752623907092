import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';
import GlobalUpdateStep2TransportationSection from '../GlobalUpdateStep2TransportationSection';
import { GUStates } from 'app/global-update/states';

export default function GlobalUpdateStep2TransportationEquipmentReturn() {
  const initEmptyNotify = useRecoilValue(GUStates.initialState.initEmptyNotify);
  const emptyNotify = useRecoilValue(GUStates.form.emptyNotify);
  const emptyNotifyLabels = useRecoilValue(GUStates.form.emptyNotifyLabels);

  const initEmptyPickup = useRecoilValue(GUStates.initialState.initEmptyPickup);
  const emptyPickup = useRecoilValue(GUStates.form.emptyPickup);
  const emptyPickupLabels = useRecoilValue(GUStates.form.emptyPickupLabels);

  const initEmptyReturnDate = useRecoilValue(GUStates.initialState.initEmptyReturnDate);
  const emptyReturnDate = useRecoilValue(GUStates.form.emptyReturnDate);
  const emptyReturnDateLabels = useRecoilValue(GUStates.form.emptyReturnDateLabels);

  return (
    <GlobalUpdateStep2TransportationSection title="Equipment Return">
      <Grid container>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.emptyNotify} label="Empty Notify" />
          <TextFieldWarnDecorator
            list={emptyNotifyLabels}
            hasChanged={emptyNotify !== initEmptyNotify}
            defaultValue={initEmptyNotify}
            formState={GUStates.form.emptyNotify}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.emptyPickup} label="Empty Pickup" />
          <TextFieldWarnDecorator
            list={emptyPickupLabels}
            hasChanged={emptyPickup !== initEmptyPickup}
            defaultValue={initEmptyPickup}
            formState={GUStates.form.emptyPickup}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.emptyReturnDate} label="Empty Return" />
          <TextFieldWarnDecorator
            list={emptyReturnDateLabels}
            hasChanged={emptyReturnDate !== initEmptyReturnDate}
            defaultValue={initEmptyReturnDate}
            formState={GUStates.form.emptyReturnDate}
          />
        </Grid>
      </Grid>
    </GlobalUpdateStep2TransportationSection>
  );
}
