import { NotifierHook } from './NotifierHook';

interface State {
  width: number;
  height: number;
}

let state: State = {
  width: 0,
  height: 0,
};

const notifier = NotifierHook();

function update() {
  state.width = window.innerWidth;
  state.height = window.innerHeight;
  notifier.notify();
}

window.addEventListener('resize', update);

setTimeout(update, 10);

export function useWindowSize(): State {
  notifier.use();

  return state;
}
