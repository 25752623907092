import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import React from 'react';
import { theme } from 'styles';

interface Props {
  on: boolean;
}

export const CheckOnOffIcon: React.FC<Props> = (props) => {
  if (props.on) {
    return <CheckCircleRoundedIcon style={{ color: theme.palette.blueGreen.main }} />;
  } else {
    return <HighlightOffRoundedIcon color="disabled" />;
  }
};
