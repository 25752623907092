import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiDownloadDynamicReport } from 'api/queries/reportQueries';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { DatasetRequest } from 'types/Dataset';

interface Props {
  req: DatasetRequest;
  disabled: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  btnLabel?: string;
}

export default function DownloadDynamicReportButton({ req, disabled, color, btnLabel }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = React.useRef(null);

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  async function downloadDynamicReport(file_type: 'xlsx' | 'pdf') {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      await apiDownloadDynamicReport(file_type, req);
    } catch (err) {
      enqueueSnackbar('Failed to download report: ' + err, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color={color || 'secondary'}
        size="large"
        ref={anchorRef}
        onClick={() => {
          downloadDynamicReport('xlsx');
        }}
        disabled={disabled || isDownloading}
      >
        {isDownloading ? (
          <>
            Downloading... <CircularProgress size={20} />
          </>
        ) : (
          <>{btnLabel || 'Preview Report'}</>
        )}
      </Button>
    </div>
  );
}
