import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import React from 'react';
import { HotPoSwitch } from '../../components/HotPoSwitch';
import { PoPartyOverride } from './PoPartyOverride';
import { PoPageData } from './states';

interface Props {
  data: PoPageData;
  reloadPoPageData(): void;
}

export const Title: React.FC<Props> = ({ data, reloadPoPageData }) => {
  const { userContext } = auth.useAuthState();
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Box margin={2} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="primary">
          Purchase Order: {data?.poNumber || '...'}
        </Typography>
        <HotPoSwitch purchaseOrderId={data.id} initialState={data.hot} showDetailsOnRight={true} />
      </Box>

      {userContext?.permissionCodes.has(PermissionCode.PoRelatedPartiesOverride) && data && (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setOpen(true);
            }}
          >
            Po Party Override
          </Button>
          {open && (
            <PoPartyOverride
              data={data}
              onClose={() => setOpen(false)}
              reloadPoPageData={reloadPoPageData}
            />
          )}
        </>
      )}
    </Box>
  );
};
