import { InternalRefetchQueriesInclude } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import {
  GqlAttachConsolidationBookingsMutation,
  GqlAttachConsolidationBookingsMutationVariables,
  useAvailableConsolidationBookingListQuery,
} from 'api/GQL_Types';
import { attachConsolidationBookings, ConsolidationQuery } from 'api/queries/consolidationQueries';
import {
  availableConsolBookingsToRows,
  consolableBookingColumns,
  ConsolableBookingRow,
} from 'app/consolidations/states';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { ConsolidationPageStates } from '../states';

interface Props {
  onClose(): void;
}

export const AddBookingDialog: React.FC<Props> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<ConsolableBookingRow[]>([]);
  const [bookingsChecked, setBookingsChecked] = React.useState<string[]>([]);

  const { loading, error } = useAvailableConsolidationBookingListQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setRows(availableConsolBookingsToRows(data));
    },
  });

  React.useEffect(() => {
    setBookingsChecked([]);
    setShowConfirm(false);
  }, [rows.length]);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ConsolidationQuery,
      variables: { consolId: consolidation?.id ?? '' },
      fetchPolicy: 'network-only',
    },
  ];

  const attachConsolidationBookingsAction = useAsyncAction<
    GqlAttachConsolidationBookingsMutationVariables,
    GqlAttachConsolidationBookingsMutation
  >((input) => attachConsolidationBookings(input, { refetchQueries }), {
    onData(data) {
      onClose();
      enqueueSnackbar(data.attachConsolidationBookings.message, { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to add booking(s): ' + error, { variant: 'error' });
    },
  });

  if (showConfirm && bookingsChecked.length > 0) {
    const selectedRows = rows.filter((row) => bookingsChecked.includes(row.id));

    return (
      <UniversalDialog
        open
        title="Add Booking"
        setClose={() => {
          onClose();
        }}
      >
        <Typography variant="body1">
          Add the following {selectedRows.length === 1 ? 'booking' : 'bookings'} to this
          consolidation?
        </Typography>

        <ul>
          {selectedRows.map((row) => {
            return (
              <li key={row.id}>
                <Typography variant="body1">
                  <b>{row.referenceNumber.value}</b>
                </Typography>
              </li>
            );
          })}
        </ul>

        <UniversalDialogFooter error={attachConsolidationBookingsAction.error}>
          <Button
            variant="contained"
            color="default"
            size="large"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={bookingsChecked.length === 0 || attachConsolidationBookingsAction.waiting}
            onClick={() => {
              if (!consolidation) return;
              attachConsolidationBookingsAction.act({
                input: {
                  consolidationId: consolidation.id,
                  bookingShipmentIds: bookingsChecked,
                },
              });
            }}
          >
            Add
          </Button>
        </UniversalDialogFooter>
      </UniversalDialog>
    );
  }

  return (
    <UniversalDialog
      open
      title="Add Booking"
      setClose={() => {
        onClose();
      }}
    >
      <Typography variant="body1">Select bookings to add to the consolidation</Typography>
      <div style={{ height: '200px', marginTop: theme.spacing(2) }}>
        <UWLTable
          rowId="id"
          columns={consolableBookingColumns}
          rows={rows}
          emptyMessage="- No Bookings -"
          virtualize="single-line-cells"
          isLoading={loading}
          error={error ? 'Failed to load bookings.' : null}
          checkboxes={{
            checked: bookingsChecked,
            setChecked: setBookingsChecked,
          }}
        />
      </div>
      <UniversalDialogFooter error={attachConsolidationBookingsAction.error}>
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={bookingsChecked.length === 0 || attachConsolidationBookingsAction.waiting}
          onClick={() => {
            setShowConfirm(true);
          }}
        >
          Next
        </Button>
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};
