import { RouteComponentProps } from '@reach/router';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { parseISO } from 'date-fns/esm';
import React from 'react';

interface Props extends RouteComponentProps {}
export default function NewWindowTable(props: Props) {
  const [data, setData] = React.useState<{ columns: any[]; data: any[] }>({
    columns: [],
    data: [],
  });

  React.useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem('table-data-window') ?? '{}');

    if (
      localStorageData &&
      Array.isArray(localStorageData.columns) &&
      Array.isArray(localStorageData.data)
    ) {
      const columns = localStorageData.columns;
      const rows = localStorageData.data;
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].type === 'date') {
          for (let j = 0; j < rows.length; j++) {
            rows[j][columns[i].id] = parseISO(rows[j][columns[i].id]);
          }
        }
      }
    }

    setData(localStorageData);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', padding: '12px' }}>
      <UWLTable
        rowId="id"
        rows={[...data.data]}
        columns={[...data.columns]}
        emptyMessage="No Data"
        virtualize="single-line-cells"
      />
    </div>
  );
}

export function openInNewTableWindow(tableName: string, columns: any[], data: any[]) {
  localStorage.setItem(
    'table-data-window',
    JSON.stringify({
      columns: columns,
      data: data,
    })
  );
  window.open('/' + tableName + '/table-view', '', 'fullscreen=yes,resizable=no');
}
