import { Box, Grid } from '@material-ui/core';
import { ContainerMode, GqlCarrier, LocationType, MoveType, useCarriersQuery } from 'api/GQL_Types';
import { NewLocationOnTheFlyDialog } from 'app/admin/components/NewLocationOnTheFlyDialog';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicRadioGroupV2 from 'components/atomic/AtomicRadioGroupV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { mapContainerMode, mapMoveType } from 'types/OMSEnums';
import { LocationOption, NewConsolidationStates } from '../states';

export default function ConsolidationFields() {
  const [pod, setPod] = useRecoilState(NewConsolidationStates.step2.formPod);
  const [pol, setPol] = useRecoilState(NewConsolidationStates.step2.formPol);
  const setCFSLocation = useSetRecoilState(NewConsolidationStates.step2.formCFSLocation);
  const [cfsLocationOptions, setCfsLocationOptions] = useRecoilState(
    NewConsolidationStates.step2.formCFSLocationOptions
  );
  const deliveryLocationOptions = useRecoilValue(
    NewConsolidationStates.step2.formDeliveryLocationOptions
  );
  const [newCFSLocationDialogInit, setNewCFSLocationDialogInit] = React.useState<{
    name: string;
    locationTypes: LocationType[];
  } | null>(null);

  const { data: carriersData } = useCarriersQuery();

  return (
    <Panel title="Consolidation Fields">
      <form noValidate autoComplete="off">
        <Box paddingLeft={3} paddingY={2}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formBookingParty}
                label="Booking Party"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formConsigneeField}
                label="Consignee"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formSupplierField}
                label="Supplier"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicTextFieldV2
                disabled
                state={NewConsolidationStates.step2.formManufacturerField}
                label="Manufacturer"
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <AtomicAutocompleteV2<MoveType>
                state={NewConsolidationStates.step2.formMoveType}
                label={'Move Type'}
                optionsList={Object.values(MoveType)}
                displayResolver={(value: MoveType) => {
                  return mapMoveType(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicRadioGroupV2<ContainerMode>
                state={NewConsolidationStates.step2.formContainerMode}
                label="Container Mode"
                options={Object.values(ContainerMode).filter((mode) => mode !== ContainerMode.Bcn)}
                displayResolver={(value: ContainerMode) => {
                  return mapContainerMode(value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicAutocompleteV2<LocationOption>
                state={NewConsolidationStates.step2.formCFSLocation}
                label="CFS Location"
                optionsList={cfsLocationOptions}
                onAddNew={(text) => {
                  setNewCFSLocationDialogInit({
                    name: text,
                    locationTypes: [LocationType.Cfs],
                  });
                }}
                isOptionNew={(value) => !!value.isNew}
                displayResolver={(value) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormRow noMargin>
                <AtomicDatePickerV2
                  state={NewConsolidationStates.step2.formCfsOpenDate}
                  required
                  label="CFS Open Date"
                />
                <AtomicDatePickerV2
                  state={NewConsolidationStates.step2.formCfsCutoffDate}
                  required
                  label="CFS Cutoff Date"
                />
              </FormRow>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <AtomicAutocompleteV2<GqlCarrier>
                state={NewConsolidationStates.step2.formCarrier}
                label={'Carrier'}
                optionsList={carriersData ? carriersData.carriers : []}
                displayResolver={(value) => value.name}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormItem>
                <FormInputPortAny label="Port of Load" value={pol} onValue={setPol} />
              </FormItem>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicDatePickerV2 state={NewConsolidationStates.step2.formEtd} label="ETD" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}></Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormItem>
                <FormInputPortAny label="Port of Discharge" value={pod} onValue={setPod} />
              </FormItem>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicDatePickerV2 state={NewConsolidationStates.step2.formEta} label="ETA" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <AtomicAutocompleteV2<LocationOption>
                state={NewConsolidationStates.step2.formDeliveryLocation}
                label="Delivery Location"
                optionsList={deliveryLocationOptions}
                displayResolver={(value) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
      {newCFSLocationDialogInit && (
        <NewLocationOnTheFlyDialog
          initialState={newCFSLocationDialogInit}
          onClose={() => {
            setNewCFSLocationDialogInit(null);
          }}
          onCreated={({ id, name }) => {
            const newLoc: LocationOption = { id, name, isNew: true };
            setCFSLocation(newLoc);
            setCfsLocationOptions([newLoc, ...cfsLocationOptions.filter((l) => !!l.id)]);
          }}
        />
      )}
    </Panel>
  );
}
