import { Typography } from '@material-ui/core';
import { ContainersInTransitDisplay } from 'app/dashboard/states';
import React from 'react';
import { theme } from 'styles';
import { InTransitTablePopover } from '../InTransitTablePopover';

interface Props {
  children?: any;
  popoverLabel: string;
  data: ContainersInTransitDisplay[];
  color: string;
  delivery?: boolean;
  rowHeight: number;
}

export const CenterLabelBox = (props: Props) => {
  const [openPopover, setOpenPopover] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={refAnchor}
        onClick={(e) => {
          setOpenPopover(true);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: props.color,
          height: props.rowHeight + 'px',
          minWidth: !props.delivery ? '33.3%' : '100%',
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          cursor: 'pointer',
        }}
      >
        <Typography variant="h2" color="textSecondary">
          {props.children}
        </Typography>
      </div>

      <InTransitTablePopover
        title={props.popoverLabel}
        emptyMessage={'No Shipments ' + props.popoverLabel}
        containers={props.data}
        anchorEl={refAnchor.current}
        open={openPopover}
        onClose={() => setOpenPopover(false)}
      />
    </>
  );
};
