import { Box, Typography } from '@material-ui/core';
import { DashboardFeatureCode } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { SwitchListGroup } from 'components/SwitchListGroup';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  options: DashboardFeatureCode[];
  values: DashboardFeatureCode[];
  onValues: (codes: DashboardFeatureCode[]) => void;

  // when true, all switches will be disabled
  disabled?: boolean;
}

export const DashboardFeatureSwitches: React.FC<Props> = (props) => {
  const dashboardFeatures = useRecoilValue(AppWideStates.dashboardFeatureCodes);

  const groupsRendered: React.ReactNode[] = [];
  for (const groupName of dashboardFeatures.groupOrder) {
    const items = (dashboardFeatures.byGroup.get(groupName) || []).filter((feature) =>
      props.options.includes(feature.code)
    );
    if (items.length === 0) {
      continue;
    }
    groupsRendered.push(
      <SwitchListGroup
        key={groupName}
        title={groupName}
        possibleValues={items.map((feature) => {
          return {
            key: feature.code,
            value: feature.code,
            label: feature.name,
            tooltip: feature.description,
          };
        })}
        values={props.values}
        onToggle={(feature, checked) => {
          let newCodes = [...props.values];
          if (checked) {
            newCodes.push(feature);
          } else {
            newCodes = newCodes.filter((a) => a !== feature);
          }
          props.onValues(newCodes);
        }}
        disabled={props.disabled}
        defaultExpanded
      />
    );
  }

  return (
    <Box width="100%">
      <Typography variant="h2">Dashboard Features</Typography>
      <hr />
      <div>{groupsRendered.length === 0 ? '- none -' : groupsRendered}</div>
    </Box>
  );
};
