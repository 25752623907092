import {
  ContractType,
  Currency,
  GqlBooking,
  GqlCarrier,
  GqlContainer,
  GqlLocation,
  GqlPort,
  IncoTerm,
  PaymentType,
  ReleaseType,
  VolumeUnit,
  WeightUnit,
} from 'api/GQL_Types';
import { HotState } from 'components/HotToggleSwitch';
import { Remark } from 'components/Remarks';
import { VesselOption, VoyageOption } from 'components/VesselVoyageSelector';
import { DateAtomType, newAtom } from 'lib/RecoilUtils';
import { ItemPosition } from 'types/VanPosition';

export interface BookingPageData extends GqlBooking {
  hot: HotState;
  containerRows: ContainerRow[];
}

export interface ContainerRow {
  id: string;
  isHot: boolean;
  container: { to: string; value: string };
  containerType: string;
  sealNumber: string;
  volumeM3: number;
  weightKG: number;
  shipFromFactoryDate: Date | null | undefined;
  terminalReceivedDate: Date | null | undefined;
}

export interface LineItems {
  id: string;
  poId: string;
  poNumber: { to: string; value: string };
  itemNumber: string;
  description: string;
  lastShipDate: Date | null | undefined;
  requestedDeliveryDate: Date | null | undefined;
  shipToLocation: string;
  unitPrice: number;
  unitPriceCurrencyCode: Currency;
  htsCode: string;
  shippedQty: number;
  shippedCartons: number;
  volume: number | null | undefined;
  weight: number | null | undefined;
  volumeUnit: VolumeUnit | null | undefined;
  weightUnit: WeightUnit | null | undefined;
}

export const BookingPageStates = {
  booking: newAtom<BookingPageData | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  editBooking: {
    revisedCargoReadyDate: newAtom<DateAtomType>(null),
    incoTerm: newAtom<IncoTerm | null>(null),
  },
  editLogistics: {
    confirmationNumber: newAtom<string>(''),
    confirmationDate: newAtom<DateAtomType>(null),
    carrier: newAtom<GqlCarrier | null>(null),
    carrierConfirmationNumber: newAtom<string>(''),
    mbl: newAtom<string>(''),
    mblPaymentType: newAtom<PaymentType | null>(null),
    mblReleaseType: newAtom<ReleaseType | null>(null),
    hbl: newAtom<string>(''),
    hblPaymentType: newAtom<PaymentType | null>(null),
    hblReleaseType: newAtom<ReleaseType | null>(null),
    contractType: newAtom<ContractType | null>(null),
    contract: newAtom<string>(''),
    cyCutoff: newAtom<DateAtomType>(null),
    cfsCutoff: newAtom<DateAtomType>(null),
    cfsReceived: newAtom<DateAtomType>(null),
    vgmCutoff: newAtom<DateAtomType>(null),
    siCutoff: newAtom<DateAtomType>(null),
    isfCutoff: newAtom<DateAtomType>(null),
    motherVessel: newAtom<VesselOption | null>(null),
    motherVoyage: newAtom<VoyageOption | null>(null),
    voyage: newAtom<string>(''),
    feederVessel: newAtom<VesselOption | null>(null),
    feederVoyage: newAtom<VoyageOption | null>(null),
    transitPort: newAtom<GqlPort | null>(null),
    transitPortEtd: newAtom<DateAtomType>(null),
    transitPortAtd: newAtom<DateAtomType>(null),
    transitPortEta: newAtom<DateAtomType>(null),
    transitPortAta: newAtom<DateAtomType>(null),
    pol: newAtom<GqlPort | null>(null),
    polEtd: newAtom<DateAtomType>(null),
    polAtd: newAtom<DateAtomType>(null),
    pod: newAtom<GqlPort | null>(null),
    podEta: newAtom<DateAtomType>(null),
    ramp: newAtom<GqlPort | null>(null),
    rampEta: newAtom<DateAtomType>(null),
    finalDestination: newAtom<GqlLocation | null>(null),
    deliveryEta: newAtom<DateAtomType>(null),
  },
  containerAssignment: {
    selectedContainerType: newAtom<string | null>(null),
    selectedContainer: newAtom<GqlContainer | null>(null),
    editContainer: {
      containerNumber: newAtom<string>(''),
      containerType: newAtom<string | null>(null),
      sealNumber: newAtom<string>(''),
      shipFromFactory: newAtom<DateAtomType>(null),
      terminalReceived: newAtom<DateAtomType>(null),
      selectedBookingPackLines: newAtom<ItemPosition[]>([]),
    },
  },
};
