import { format, isValid } from 'date-fns';

export function isValidDate(date: any): date is Date {
  return isValid(date);
}

export function isDate(v: any): v is Date {
  if (v instanceof Date) {
    const time = v.getTime();
    if (
      // eslint-disable-next-line
      time === time // check that it's not NaN (NOTE: IE doesn't support Number.isNan)
    ) {
      return true;
    }
  }
  return false;
}

export function fromAPI_Timestamp(v: unknown): Date | null {
  if (typeof v === 'string') {
    if (
      v.length === 10 // yyyy-mm-dd
    ) {
      v = new Date(v + 'T00:00:00Z');
    } else if (/[0-9]$/.test(v)) {
      v = new Date(v + 'Z');
    } else {
      v = new Date(v);
    }
  }
  if (isDate(v)) {
    return v;
  }
  return null;
}

export function fromAPI_DateTime(v: unknown): Date | null {
  const date = fromAPI_Timestamp(v);
  if (date) {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }
  return null;
}

export function fromAPI_Date(v: unknown): Date | null {
  const date = fromAPI_DateTime(v);
  if (date) {
    date.setHours(0, 0, 0, 0);
    return date;
  }
  return null;
}

function pad2(n: number): string {
  return (n + '').padStart(2, '0');
}

export function toAPI_Date(d: Date) {
  // Not using .toISOString() b/c it converts the datetime to UTC
  return `${d.getFullYear()}-${pad2(d.getMonth() + 1)}-${pad2(d.getDate())}`;
}

export function toAPI_DateTime(d: Date) {
  // Not using .toISOString() b/c it converts the datetime to UTC
  return `${toAPI_Date(d)}T${pad2(d.getHours())}:${pad2(d.getMinutes())}:${pad2(d.getSeconds())}`;
}

export function toAPI_Timestamp(d: Date) {
  return d.toISOString();
}

function parseMonth(src: string): number | null {
  switch (src[0]) {
    case 'j':
      if (src[1] === 'a') {
        return 1;
      } else if (src.indexOf('l') >= 0) {
        return 7;
      } else {
        return 6;
      }
    case 'f':
      return 2;
    case 'm':
      if (src.indexOf('y') >= 0) {
        return 5;
      } else {
        return 3;
      }
    case 'a':
      return 8;
    case 's':
      return 9;
    case 'o':
      return 10;
    case 'n':
      return 11;
    case 'd':
      return 12;
  }
  return null;
}

function makeDate(year: number, month: number, day: number): Date {
  return new Date(year, month - 1, day);
}

const parseDateFormats: {
  regex: RegExp;
  parse(m: RegExpExecArray, year: number, month: number, day: number): Date | null;
}[] = [
  {
    regex: /^([0-9][0-9][0-9][0-9]) ([0-9][0-9]?) ([0-9][0-9]?)$/,
    parse(m, year, month, day) {
      year = parseInt(m[1], 10) || 0;
      month = parseInt(m[2], 10) || 0;
      day = parseInt(m[3], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([0-9][0-9]?) ([0-9][0-9]?)$/,
    parse(m, year, month, day) {
      month = parseInt(m[1], 10) || 0;
      day = parseInt(m[2], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([0-9][0-9]?) ([0-9][0-9]?) ([0-9][0-9][0-9][0-9])$/,
    parse(m, year, month, day) {
      month = parseInt(m[1], 10) || 0;
      day = parseInt(m[2], 10) || 0;
      year = parseInt(m[3], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([0-9][0-9]?) ([0-9][0-9]?) ([0-9][0-9])$/,
    parse(m, year, month, day) {
      month = parseInt(m[1], 10) || 0;
      day = parseInt(m[2], 10) || 0;
      year = 2000 + parseInt(m[3], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([a-z]+) ?([0-9][0-9]?)$/,
    parse(m, year, month, day) {
      const pMonth = parseMonth(m[1]);
      if (!pMonth) {
        return null;
      }
      month = pMonth;
      day = parseInt(m[2], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([a-z]+) ?([0-9][0-9]?) ([0-9][0-9][0-9][0-9])$/,
    parse(m, year, month, day) {
      const pMonth = parseMonth(m[1]);
      if (!pMonth) {
        return null;
      }
      month = pMonth;
      day = parseInt(m[2], 10) || 0;
      year = parseInt(m[3], 10) || 0;
      return makeDate(year, month, day);
    },
  },
  {
    regex: /^([a-z]+) ?([0-9][0-9]?) ([0-9][0-9])$/,
    parse(m, year, month, day) {
      const pMonth = parseMonth(m[1]);
      if (!pMonth) {
        return null;
      }
      month = pMonth;
      day = parseInt(m[2], 10) || 0;
      year = 2000 + parseInt(m[3], 10) || 0;
      return makeDate(year, month, day);
    },
  },
];

export function parseDate(src?: string | null): Date | null {
  if (typeof src !== 'string') {
    if (isDate(src)) {
      return src;
    }
    return null;
  }
  src = src
    .toLocaleLowerCase()
    .replace(/[^a-z0-9]+/g, ' ')
    .trim();

  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  for (const f of parseDateFormats) {
    const m = f.regex.exec(src);
    if (m) {
      return f.parse(m, year, month, day);
    }
  }
  return null;
}

/**
 * The default date format across the system.
 */
export function formatDate(date: Date | null | undefined): string {
  if (isValidDate(date)) {
    return format(date, 'MM/dd/yyyy');
  }
  return '';
}

/**
 * The default date+time format across the system.
 */
export function formatDateTime(date: Date | null | undefined): string {
  if (isValidDate(date)) {
    return format(date, 'MM/dd/yyyy hh:mm a');
  }
  return '';
}

export function formatTime(date: Date | null | undefined, formatString: string): string {
  if (isValidDate(date)) {
    return format(date, formatString);
  }
  return '';
}

export function compareDates(a: Date | null | undefined, b: Date | null | undefined): number {
  const aDate = isValidDate(a) ? a.getTime() : 0;
  const bDate = isValidDate(b) ? b.getTime() : 0;
  return aDate - bDate;
}

export function isDateBetween(date: Date | null | undefined, from: Date, to: Date): boolean {
  if (!date) {
    return false;
  }

  // Clone dates so so we don't mutate the original when we .setHours() on them
  date = new Date(date);
  from = new Date(from);
  to = new Date(to);

  date.setHours(0, 0, 0, 0);
  from.setHours(0, 0, 0, 0);
  to.setHours(0, 0, 0, 0);

  if (date >= from && date <= to) {
    return true;
  }

  return false;
}
