import { Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { gqlClient } from 'api/ApolloClient';
import { PurchaseOrderQuery } from 'api/queries/poQueries';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import useAsyncLoader from 'lib/useAsyncLoader';
import React from 'react';
import { theme } from 'styles';
import { PoDetails } from './PoDetails';
import { PoHistory } from './PoHistory';
import { PoOrderLines } from './PoOrderLines';
import { fromGqlPurchaseOrderQuery, PoPageData } from './states';
import { Title } from './Title';
import { Box } from '@material-ui/core';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';

const PoTabs = withStyles({
  root: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: '4px',
  },
})(Tabs);

interface PoPageProps extends RouteComponentProps {
  poId?: string;
}

export default function PoPage(props: PoPageProps) {
  const [tabShown, setTabShown] = React.useState(0);
  const { userContext } = auth.useAuthState();

  const purchaseOrderLoader = useAsyncLoader<PoPageData | null>(null, () =>
    gqlClient
      .query({
        query: PurchaseOrderQuery,
        variables: { poId: props.poId || '' },
        fetchPolicy: 'no-cache',
      })
      .then((data) => {
        return fromGqlPurchaseOrderQuery(data.data);
      })
  );

  React.useEffect(() => {
    purchaseOrderLoader.load();
  }, [props.poId]);

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <Box overflow={'hidden'}>
        {purchaseOrderLoader.data ? (
          <Title
            data={purchaseOrderLoader.data}
            reloadPoPageData={() => purchaseOrderLoader.loadUpdate()}
          />
        ) : null}
        {purchaseOrderLoader.waiting ? (
          <Loading />
        ) : purchaseOrderLoader.error ? (
          <ErrorMessage error={purchaseOrderLoader.error + ''} />
        ) : !purchaseOrderLoader.data ? (
          <div>PO Not Found</div>
        ) : (
          <>
            {userContext?.permissionCodes.has(PermissionCode.PoHistory) && (
              <PoTabs
                value={tabShown}
                onChange={(e, v) => {
                  setTabShown(v);
                }}
                variant="fullWidth"
              >
                <Tab
                  label={<Typography variant="h3">Details</Typography>}
                  style={{ borderBottom: '4px solid #888' }}
                />
                <Tab
                  label={<Typography variant="h3">History</Typography>}
                  style={{ borderBottom: '4px solid #888' }}
                />
              </PoTabs>
            )}
            <div style={{ paddingTop: theme.spacing(1) }}>
              {tabShown === 0 && <PoDetails data={purchaseOrderLoader.data} />}
              {tabShown === 1 && <PoHistory data={purchaseOrderLoader.data} />}
            </div>
          </>
        )}
      </Box>
      <Box flex="1 1 300px" overflow="auto" display="flex">
        {purchaseOrderLoader.data && tabShown === 0 && (
          <PoOrderLines data={purchaseOrderLoader.data} />
        )}
      </Box>
    </Box>
  );
}
