import { gql } from '@apollo/client/core';
import {
  GqlNewProfileRoleMutation,
  GqlNewProfileContactMutation,
  GqlNewProfileContactMutationVariables,
  GqlNewProfileRoleMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';
import { AdminPagesContactFragment } from './contactQueries';

export const ProfileQuery = gql`
  query Profile($profileId: ID!) {
    profile(profileId: $profileId) {
      id
      name
      profileCode
      nameAliases
      profileCodeAliases
      isControllingClient
      logo {
        id
        imageType
        fileName
        fileType
        fileSize
        uploadDate
        url
      }
      needsUpdate
      network {
        id
        name
        connectedProfiles {
          id
          name
          isControllingClient
        }
        profileTypes {
          id
          name
        }
      }
      profileType {
        id
        name
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
      }
      locations {
        id
        name
        code
        nameAliases
        codeAliases
        addressLine1
        addressLine2
        city
        state
        postalCode
        relatedPort {
          code
          name
        }
        country
        timeZone
        companyPhone
        companyUrl
        needsUpdate
        locationTypes
      }
      contacts {
        ...AdminPagesContact
      }
      roles {
        id
        name
        description
        isDefaultRole
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
        documentTypesCanView
      }
      relatedProfileRulesText
    }
  }
  ${AdminPagesContactFragment}
`;

export const NewProfileContactMutation = gql`
  mutation NewProfileContact($input: NewProfileContactInput!) {
    newProfileContact(input: $input) {
      success
      message
      pleaseProvideCredentials
    }
  }
`;

export const newProfileContact = wrapMutation<
  GqlNewProfileContactMutation,
  GqlNewProfileContactMutationVariables
>({
  mutation: NewProfileContactMutation,
  errorMessage(data, error) {
    if (!data?.newProfileContact.success || error) {
      if (data?.newProfileContact.pleaseProvideCredentials) {
        return null; // Don't treat this like a normal error, instead display the setup login dialog.
      }
      return data?.newProfileContact.message || 'Unexpected Error';
    }
  },
});

export const NewProfileRoleMutation = gql`
  mutation NewProfileRole($input: NewProfileRoleInput!) {
    newProfileRole(input: $input) {
      success
      message
    }
  }
`;

export const newProfileRole = wrapMutation<
  GqlNewProfileRoleMutation,
  GqlNewProfileRoleMutationVariables
>({
  mutation: NewProfileRoleMutation,
  errorMessage(data, error) {
    if (!data?.newProfileRole.success || error) {
      return data?.newProfileRole.message || 'Unexpected Error';
    }
  },
});

export const UpdateProfileMutation = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      message
    }
  }
`;

export const MergeProfileMutation = gql`
  mutation MergeProfile($input: MergeProfileInput!) {
    mergeProfile(input: $input) {
      success
      message
    }
  }
`;
