import { gql } from '@apollo/client/core';
import { Box, Grid, Typography } from '@material-ui/core';
import { DashboardFeatureCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { Milestones, transformContainerToShipmentInTransitDisplay } from 'app/dashboard/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { LeftLabelBox } from './LeftLabelBox';
import MilestoneDisplay from './MilestoneDisplay';

export const DownloadReportMutation = gql`
  mutation DownloadReportGT($report: ReportConfigInput!) {
    downloadReport(report: $report) {
      success
      reportId
    }
  }
`;

interface Props {
  data: Milestones;
}

export default function BarGraphs(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const height = 40;

  return (
    <Box display="flex" flexDirection="column" padding="0 32px">
      <Grid container wrap="nowrap">
        <Grid item xs={3}>
          <Box display="flex" flexGrow={1} flexDirection="column">
            {userContext?.dashboardFeatureCodes.has(
              DashboardFeatureCode.ShipmentsInTransitOnWater
            ) && (
              <LeftLabelBox
                rowHeight={height}
                data={transformContainerToShipmentInTransitDisplay([
                  ...props.data.onWater.early,
                  ...props.data.onWater.onTime,
                  ...props.data.onWater.late,
                ])}
                label="On Water"
              />
            )}
            {userContext?.dashboardFeatureCodes.has(
              DashboardFeatureCode.ShipmentsInTransitAtDischarge
            ) && (
              <LeftLabelBox
                rowHeight={height}
                data={transformContainerToShipmentInTransitDisplay([
                  ...props.data.atDischarge.early,
                  ...props.data.atDischarge.onTime,
                  ...props.data.atDischarge.late,
                ])}
                label="At Discharge"
              />
            )}
            {userContext?.dashboardFeatureCodes.has(
              DashboardFeatureCode.ShipmentsInTransitAtRamp
            ) && (
              <LeftLabelBox
                rowHeight={height}
                data={transformContainerToShipmentInTransitDisplay([
                  ...props.data.atRamp.early,
                  ...props.data.atRamp.onTime,
                  ...props.data.atRamp.late,
                ])}
                label="At Ramp"
              />
            )}
            {userContext?.dashboardFeatureCodes.has(
              DashboardFeatureCode.ShipmentsInTransitDelivered
            ) && (
              <LeftLabelBox
                rowHeight={height}
                data={transformContainerToShipmentInTransitDisplay([
                  ...props.data.delivered.early,
                  ...props.data.delivered.onTime,
                  ...props.data.delivered.late,
                ])}
                label="Delivered"
              />
            )}
            <LeftLabelBox
              rowHeight={height}
              data={transformContainerToShipmentInTransitDisplay([
                ...props.data.totals.early,
                ...props.data.totals.onTime,
                ...props.data.totals.late,
              ])}
              label="Totals"
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          {userContext?.dashboardFeatureCodes.has(
            DashboardFeatureCode.ShipmentsInTransitOnWater
          ) && <MilestoneDisplay title="On Water" milestone={props.data.onWater} height={height} />}
          {userContext?.dashboardFeatureCodes.has(
            DashboardFeatureCode.ShipmentsInTransitAtDischarge
          ) && (
            <MilestoneDisplay
              title="At Discharge"
              milestone={props.data.atDischarge}
              height={height}
            />
          )}
          {userContext?.dashboardFeatureCodes.has(
            DashboardFeatureCode.ShipmentsInTransitAtRamp
          ) && <MilestoneDisplay title="At Ramp" milestone={props.data.atRamp} height={height} />}
          {userContext?.dashboardFeatureCodes.has(
            DashboardFeatureCode.ShipmentsInTransitDelivered
          ) && (
            <MilestoneDisplay title="Delivered" milestone={props.data.delivered} height={height} />
          )}
          <MilestoneDisplay title="Totals" milestone={props.data.totals} height={height} />
        </Grid>
      </Grid>
      <Box marginTop={1}>
        <Grid container>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '12px' }}
          >
            <StatusLabel label="Total" color={theme.palette.background.default} />
          </Grid>

          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <StatusLabel label="Early" color={theme.palette.indigoBlue.main} />
          </Grid>

          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <StatusLabel label="On Time" color={theme.palette.blueGreen.main} />
          </Grid>

          <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <StatusLabel label="Late" color={theme.palette.orange.main} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

interface StatusLabelProps {
  label: string;
  color: string;
}

function StatusLabel(props: StatusLabelProps) {
  return (
    <Box display="flex" alignItems="center">
      <Box width={12} height={12} bgcolor={props.color} marginRight={1} borderRadius={8}></Box>
      <Typography variant="h4">{props.label}</Typography>
    </Box>
  );
}
