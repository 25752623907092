import { GqlNotificationsQuery, useMarkNotificationsAsReadMutation } from 'api/GQL_Types';
import { useSetRecoilState } from 'recoil';
import { DashboardPageStates } from '../states';

export type NotificationResult = GqlNotificationsQuery['notifications'][number];

export interface BaseAlert {
  id: string;
  unread: boolean;
}

export function useMarkNotificationsAsRead() {
  const setNewDocuments = useSetRecoilState(DashboardPageStates.notifications.newDocuments);
  const setNewBookings = useSetRecoilState(DashboardPageStates.notifications.newBookings);

  const [markAsReadMut] = useMarkNotificationsAsReadMutation({});

  return (notificationIds: string[]) => {
    markAsReadMut({ variables: { input: { notificationIds } } });

    for (const setter of [setNewDocuments, setNewBookings] as any[]) {
      setter((curr: BaseAlert[]) => {
        return curr.map((alert) => {
          if (notificationIds.includes(alert.id)) {
            return {
              ...alert,
              unread: false,
            };
          }
          return alert;
        });
      });
    }
  };
}
