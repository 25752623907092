import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { HblPackLine, HblPageStates } from './states';

const columns: UWLTableColumn<HblPackLine>[] = [
  { id: 'poNumber', label: 'PO', type: 'link' },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date' },
  { id: 'requestedDeliveryDate', label: 'Req Del Date', type: 'date' },
  { id: 'shipToLocation', label: 'Ship To Loc', type: 'string' },
  {
    id: 'unitPrice',
    label: 'Unit Cost',
    type: 'currency',
    currencyField: 'unitPriceCurrencyCode',
  },
  { id: 'htsCode', label: 'HTS Code', type: 'string' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'shippedCartons', label: 'Shipped CTNS', type: 'number' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: 'CBM' },
  { id: 'weightKg', label: 'Weight', type: 'weight', unit: 'KG' },
];

interface Props {}

export const HBLPackLines: React.FC<Props> = () => {
  const hbl = useRecoilValue(HblPageStates.hbl);

  return (
    <Panel title="Pack Lines">
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          rows={hbl?.packLineRows || []}
          emptyMessage="No Pack Lines"
          noWrapAllCells
        />
      </PanelBodyTable>
    </Panel>
  );
};
