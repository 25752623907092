import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FilterInputs } from 'components/dataset/FilterInputs';
import * as React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';

interface Props {}

export default function Step3(props: Props) {
  const { values, validation } = reportFormStore.use();

  const filterables = reportCategoriesStore.useFilterables(values.datasetId || '');

  return (
    <div>
      <Box overflow="auto" maxWidth="100%">
        <FilterInputs
          filterables={filterables}
          value={values.filters}
          onValue={(filters) => reportFormStore.put({ filters })}
        />
      </Box>

      {validation.filters && (
        <FormHelperText error variant="outlined">
          {validation.filters}
        </FormHelperText>
      )}
    </div>
  );
}
