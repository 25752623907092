import { faAddressBook, faAddressCard, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import { ListCard } from 'components/ListCard';
import React from 'react';
import { theme } from 'styles';

interface Props {
  accentColor: string;
  name: string;
  nRoles: number;
  nContacts: number;
  nLocations: number;
  needsUpdate?: boolean;

  // Callback for manage button
  onManageClicked?: () => void;
  // Callback for remove button
  onRemoveClicked?: () => void;
  // List of React Nodes to render on the item.
  extraDisplays?: React.ReactNode[];
}

export default function ProfileListItem(props: Props) {
  return (
    <Box marginBottom={2}>
      <ListCard accentColor={props.accentColor}>
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          height="100%"
          width="100%"
          padding={2}
          alignItems="center"
        >
          <Box
            display="flex"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" height="100%" alignItems="center" width="25%">
              {props.needsUpdate && (
                <Box marginRight={1}>
                  <Tooltip
                    title={
                      <Typography color="textSecondary">
                        Profile was auto generated.{' '}
                        {props.onManageClicked
                          ? 'Click the manage button to merge with existing profile or make it a new one.'
                          : null}
                      </Typography>
                    }
                    interactive
                  >
                    <WarningIcon style={{ color: theme.palette.warning.main }} />
                  </Tooltip>
                </Box>
              )}

              <Typography variant="h3">{props.name}</Typography>
            </Box>
            {props.extraDisplays &&
              props.extraDisplays.map((node: React.ReactNode, index: number) => (
                <Box
                  key={index}
                  display="flex"
                  marginRight={2}
                  alignItems="center"
                  justifyContent="flex-start"
                  width="130px"
                >
                  {node}
                </Box>
              ))}
            <Box
              display="flex"
              marginRight={2}
              alignItems="center"
              justifyContent="flex-start"
              width="130px"
            >
              <Avatar
                style={{
                  backgroundColor: theme.palette.blueGreen.main,
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                }}
              >
                <FontAwesomeIcon icon={faAddressCard} color="#fff" size="sm" />
              </Avatar>
              <Box display="flex" alignItems="baseline">
                <Box marginRight="4px" marginLeft="4px" width="48px">
                  <Typography variant="body1">Roles</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {props.nRoles}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              marginRight={2}
              alignItems="center"
              justifyContent="flex-start"
              width="130px"
            >
              <Avatar
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                }}
              >
                <FontAwesomeIcon icon={faAddressBook} color="#fff" size="sm" />
              </Avatar>

              <Box display="flex" alignItems="baseline">
                <Box marginRight="4px" marginLeft="4px" width="72px">
                  <Typography variant="body1">Contacts</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {props.nContacts}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              marginRight={2}
              alignItems="center"
              justifyContent="flex-start"
              width="130px"
            >
              <Avatar
                style={{
                  backgroundColor: theme.palette.orange.main,
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                }}
              >
                <FontAwesomeIcon icon={faBuilding} color="#fff" size="sm" />
              </Avatar>

              <Box display="flex" alignItems="baseline">
                <Box marginRight="4px" marginLeft="4px" width="72px">
                  <Typography variant="body1">Locations</Typography>
                </Box>
                <Box>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {props.nLocations}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" height="100%" justifyContent="flex-end">
              {props.onManageClicked && (
                <Button
                  onClick={() => {
                    props.onManageClicked && props.onManageClicked();
                  }}
                  style={{
                    width: '136px',
                  }}
                  color="secondary"
                  variant="contained"
                >
                  Manage
                </Button>
              )}
              {props.onRemoveClicked && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    props.onRemoveClicked && props.onRemoveClicked();
                  }}
                  style={{
                    color: '#E6001F',
                    marginLeft: '16px',
                    padding: '0 5px',
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </ListCard>
    </Box>
  );
}
