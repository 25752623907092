import React from 'react';
import { useRecoilValue } from 'recoil';
import { AlertButton } from '../components/AlertButton';
import {
  ContainersInTransitDisplay,
  DashboardPageStates,
  transformContainerToShipmentInTransitDisplay,
} from '../states';
import { InTransitTablePopover } from './InTransitTablePopover';

export default function DueToArriveButton() {
  const inTransit = useRecoilValue(DashboardPageStates.inTransit);
  const [containers, setContainers] = React.useState<ContainersInTransitDisplay[]>([]);
  const [openPopover, setOpenPopover] = React.useState(false);
  const refAnchor = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (inTransit) {
      const dueToArriveShipments = inTransit
        .filter((transit) => transit.isDueToArrive)
        .map((shipment) => shipment.container);
      setContainers(transformContainerToShipmentInTransitDisplay(dueToArriveShipments));
    }
  }, [inTransit]);

  return (
    <>
      <AlertButton
        innerRef={refAnchor}
        label="Due To Arrive"
        quantity={containers.length}
        onClick={() => setOpenPopover(true)}
      />
      <InTransitTablePopover
        title="Containers Due to Arrive"
        emptyMessage="No Containers Due to Arrive"
        containers={containers}
        anchorEl={refAnchor.current}
        open={openPopover}
        onClose={() => setOpenPopover(false)}
      />
    </>
  );
}
