import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import { Line } from 'components/StyledComponents';
import React from 'react';
import AddProfileContactBtn from './AddProfileContactBtn';
import ProfileContactCards from './ProfileContactCards';
import ProfileContactsHeader from './ProfileContactsHeader';

interface Props extends RouteComponentProps {}
export default function ProfileContacts(props: Props) {
  const { userContext } = auth.useAuthState();
  return (
    <div>
      <ProfileContactsHeader />
      <Line height={1} />
      <ProfileContactCards />
      {userContext?.permissionCodes.has(PermissionCode.ProfileContactCreate) && (
        <Box position="absolute" bottom={50} right={40}>
          <AddProfileContactBtn />
        </Box>
      )}
    </div>
  );
}
