import { Button } from '@material-ui/core';
import { consolableBookingColumns } from 'app/consolidations/states';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NewConsolidationStates } from '../states';
import BookingsFiltersPopover from './filters/BookingsFiltersPopover';

export default function BookingsTable() {
  const filteredBookings = useRecoilValue(NewConsolidationStates.step1.filteredBookings);
  const [bookingsChecked, setBookingsChecked] = useRecoilState(
    NewConsolidationStates.step1.bookingsChecked
  );
  const setSelectedBookings = useSetRecoilState(NewConsolidationStates.step1.selectedBookings);
  const filters = useRecoilValue(NewConsolidationStates.filters.filtersLength);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [openPopover, setOpenPopover] = React.useState(false);

  React.useEffect(() => {
    if (bookingsChecked) {
      setSelectedBookings(filteredBookings.filter((select) => bookingsChecked.includes(select.id)));
    } else {
      setSelectedBookings([]);
    }
  }, [bookingsChecked]);

  return (
    <Panel
      title="Bookings"
      topRight={
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setOpenPopover(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          Filters ({filters})
        </Button>
      }
    >
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={consolableBookingColumns}
          rows={filteredBookings}
          emptyMessage="- No Bookings -"
          virtualize="single-line-cells"
          checkboxes={{
            checked: bookingsChecked,
            setChecked: setBookingsChecked,
          }}
        />
      </PanelBodyTable>
      <BookingsFiltersPopover
        anchorEl={anchorEl}
        openPopover={openPopover}
        onClose={() => {
          setAnchorEl(null);
          setOpenPopover(false);
        }}
      />
    </Panel>
  );
}
