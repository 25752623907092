import { Box } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PartyType, usePurchaseOrdersQuery } from 'api/GQL_Types';
import { PanelBase, PanelHeader } from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import SearchBar from 'components/SearchBar';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import {
  STANDARD_HEADER_OVERALL_HEIGHT,
  STANDARD_NO_ROWS_HEIGHT,
  STANDARD_ROW_INNER_HEIGHT,
  STANDARD_ROW_OVERALL_HEIGHT,
  UWLTable,
} from 'components/UWLTable/UWLTable';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'lib/RecoilUtils';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { formatDate } from 'types/Date';
import { oneOrVarious } from './page/states';

const poSearchState = newAtom('');
const poListState = newAtom<PoFields[]>([]);

export const filteredPoListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(poSearchState);
    const pos = get(poListState);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return pos;
    } else {
      return pos.filter((po) => po.filterKey.includes(lowerField));
    }
  },
});

interface PoFields {
  id: string;
  poNumber: { to: string; value: string };
  poDate: Date | null | undefined;
  supplierName: string;
  expectedCargoReadyDate: string;
  revisedCargoReadyDate: string;
  firstShipDate: string;
  lastShipDate: string;
  shipToLocation: string;
  orderLines: OrderLineFields[];

  filterKey: string; // The text rows can be filtered by with the quick search
}

const poColumns: UWLTableColumn<PoFields>[] = [
  { id: 'poNumber', label: 'PO', type: 'link' },
  { id: 'poDate', label: 'PO Date', type: 'date' },
  { id: 'supplierName', label: 'Supplier', type: 'string' },
  { id: 'expectedCargoReadyDate', label: 'Cgo Rdy Date', type: 'string' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'string' },
  { id: 'firstShipDate', label: 'First Ship', type: 'string' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'string' },
  { id: 'shipToLocation', label: 'Ship to Loc', type: 'string' },
];

const lineColumns: UWLTableColumn<OrderLineFields>[] = [
  { id: 'itemNumber', label: 'Item / SKU', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'shipToLocation', label: 'Ship To Loc', type: 'string' },
  { id: 'orderQty', label: 'Order Qty', type: 'number' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number' },
];

interface PoBrowserProps extends RouteComponentProps {}
export default function PoBrowser(props: PoBrowserProps) {
  const setPurchaseOrders = useSetRecoilState(poListState);
  const [searchField, setSearchField] = useRecoilState(poSearchState);
  const filteredPos = useRecoilValue(filteredPoListState);

  const { loading, error } = usePurchaseOrdersQuery({
    fetchPolicy: 'no-cache',
    onCompleted(posData) {
      const poList = posData.purchaseOrders
        .map((po): PoFields => {
          return {
            id: po.id,
            poNumber: { to: '/purchase-orders/' + po.id, value: po.poNumber },
            poDate: po.poDate,
            supplierName: relatedPartyNameOrVarious(po.relatedParties, PartyType.Supplier),
            expectedCargoReadyDate:
              formatDate(po.expectedCargoReadyDate) ||
              oneOrVarious(po.orderLines.map((ol) => formatDate(ol.expectedCargoReadyDate))),
            revisedCargoReadyDate:
              formatDate(po.revisedCargoReadyDate) ||
              oneOrVarious(po.orderLines.map((ol) => formatDate(ol.revisedCargoReadyDate))),
            firstShipDate:
              formatDate(po.firstShipDate) ||
              oneOrVarious(po.orderLines.map((ol) => formatDate(ol.firstShipDate))),
            lastShipDate:
              formatDate(po.lastShipDate) ||
              oneOrVarious(po.orderLines.map((ol) => formatDate(ol.lastShipDate))),

            shipToLocation: oneOrVarious(po.orderLines.map((ol) => ol.shipToLocation?.name || '')),
            orderLines: po.orderLines.map((line): OrderLineFields => {
              return {
                id: line.id,
                itemNumber: line.itemNumber,
                description: line.itemDescription ?? '',
                shipToLocation: line.shipToLocation?.name ?? '',
                orderQty: line.openOrder.orderQty,
                balanceQty: line.openOrder.balanceQty,
              };
            }),
            filterKey: '', // will get set in the next pass
          };
        })
        .map((row) => {
          return {
            ...row,
            filterKey: [
              // Include all the text they can search by
              row.poNumber.value,
              formatDate(row.poDate),
              row.supplierName,
              row.expectedCargoReadyDate,
              row.revisedCargoReadyDate,
              row.firstShipDate,
              row.lastShipDate,
              row.shipToLocation,
            ]
              .join('||||')
              .toLowerCase(),
          };
        });

      setPurchaseOrders(poList);
    },
  });

  return (
    <PanelBase>
      <RemainingHeightLayout
        top={
          <PanelHeader
            title="PO Browser"
            titleDecorator={
              <TableExportWindowGroup
                label="PurchaseOrders"
                rows={filteredPos}
                columns={poColumns}
              />
            }
            topRight={
              <Box width="25%" bgcolor="#F8F8F8" padding={1}>
                <SearchBar
                  placeholder="Search by Purchase Order #"
                  field={searchField}
                  updateField={setSearchField}
                />
              </Box>
            }
          />
        }
        bottomMinHeight={300}
        bottom={
          <PanelBodyTable>
            <UWLTable
              rowId="id"
              rows={filteredPos}
              isLoading={loading}
              error={error ? error + '' : null}
              columns={poColumns}
              emptyMessage="No Purchase Orders"
              rowExpand={(row) => {
                return <TableContent rowId={row.id} orderLines={row.orderLines} />;
              }}
              noWrapAllCells
              virtualize={{
                rowHeight(row) {
                  return STANDARD_ROW_INNER_HEIGHT;
                },
                rowExpandedHeight(row) {
                  const paddingHeight = 1.5 * 8 * 2;
                  const tbodyHeight =
                    row.orderLines.length === 0
                      ? STANDARD_NO_ROWS_HEIGHT
                      : row.orderLines.length * STANDARD_ROW_OVERALL_HEIGHT;
                  return Math.min(
                    300,
                    STANDARD_HEADER_OVERALL_HEIGHT + tbodyHeight + paddingHeight
                  );
                },
                bufferHeight: 400,
              }}
            />
          </PanelBodyTable>
        }
      />
    </PanelBase>
  );
}

interface OrderLineFields {
  id: string;
  itemNumber: string;
  description: string;
  shipToLocation: string;
  orderQty: number;
  balanceQty: number;
}

interface TCProps {
  rowId: string;
  orderLines: OrderLineFields[];
}

function TableContent(props: TCProps) {
  return (
    // NOTE: if you change the size of this component, update the virtualize rowExpandedHeight calculation
    <Box display="flex" flexDirection="column" width="100%" maxHeight="300px" padding={1.5}>
      <UWLTable rowId="id" columns={lineColumns} rows={props.orderLines} noWrapAllCells />
    </Box>
  );
}
