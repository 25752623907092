import { EquipmentSelectionPanel } from 'components/EquipmentSelectionPanel';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NewConsolidationStates } from '../states';

export default function EquipmentSelecting() {
  const [equipmentList, setEquipmentList] = useRecoilState(
    NewConsolidationStates.step1.equipmentList
  );

  return <EquipmentSelectionPanel value={equipmentList} onValue={setEquipmentList} showMinMax />;
}
