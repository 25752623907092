import * as FileSaver from 'file-saver';
import { formatDate } from 'types/Date';
import { formatCurrency } from 'types/Monetary';
import { UWLTableColumn } from 'types/UWLTable';
import * as XLSX from 'xlsx';

export default function arrayToExcel(
  tableData: any[],
  fileName: string,
  columns: UWLTableColumn<any>[]
) {
  let clonedArray: any[] = [];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  tableData.map((row: any) => {
    let tempRow: any = Object.assign({}, { ...row });
    Object.keys(tempRow).forEach((key) => {
      const cellType = columns.find((col) => col.id === key);
      if (cellType) {
        tempRow[key] = renderCell(tempRow[key], cellType);
      }
      if (columns.some((e: any) => e.id === key) === false) {
        delete tempRow[key];
      }
      const label = columns.find((col) => col.id === key)?.label || '';
      if (label) {
        tempRow = { ...tempRow, [label]: tempRow[key] };
        delete tempRow[key];
      }
    });
    clonedArray.push(tempRow);
    return row;
  });
  exportToExcel(clonedArray, fileName);

  function exportToExcel(clonedArray: any, fileName: string) {
    const ws = XLSX.utils.json_to_sheet(clonedArray);
    ws['!cols'] = fitToColumn(clonedArray);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  function fitToColumn(tableArray: any) {
    const columnWidths = [];
    for (const property in tableArray[0]) {
      columnWidths.push({
        wch: Math.max(
          property ? property.toString().length : 0,
          ...tableArray.map((obj: any) => (obj[property] ? obj[property].toString().length : 0))
        ),
      });
    }
    return columnWidths;
  }
}

function renderCell(value: any, type: UWLTableColumn<any>) {
  switch (type.type) {
    case 'date':
      if (Array.isArray(value)) {
        let fmt = formatDate(value[0]);
        for (let i = 1; i < value.length; i++) {
          if (fmt !== formatDate(value[i])) {
            return 'Various';
          }
        }
        return fmt;
      }
      return formatDate(value);

    case 'link':
      if (Array.isArray(value)) {
        return value
          .map((v) => v?.value || '')
          .filter((v) => typeof v === 'string' && v.length > 0)
          .join(', ');
      }
      return value.value;

    case 'currency':
      return formatCurrency(value);
  }
  if (value === null || value === undefined) {
    return '';
  }
  return value + '';
}
