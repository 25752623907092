import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';
import { UserNameEmail } from 'types/UserNameEmail';
import { getWeekDayFull } from 'types/Weekday';

const Group: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h3">{label}</Typography>
      </Box>
      <Box marginLeft={2} marginBottom={2}>
        {children}
      </Box>
    </>
  );
};

interface Props {
  availableContacts: UserNameEmail[];
  saveAndPublish(): void;
}

export default function Step5({ availableContacts, saveAndPublish }: Props) {
  const { values } = reportFormStore.use();

  let freq = 'not scheduled';
  if (values.scheduled) {
    switch (values.period) {
      case 'weekly':
        freq = 'Weekly - ' + values.weekDays.map(getWeekDayFull).join(', ');
        break;

      case 'monthly':
        freq = 'monthly - ' + values.days.join(', ');
        break;
    }
  }

  const recipients = [];
  let usersNotFound = 0;
  for (const userId of values.recipientContactIds) {
    const user = availableContacts.find((u) => u.id === userId);
    if (!user) usersNotFound++;
    else recipients.push(user.email);
  }
  for (let email of values.recipientEmails) {
    email = email.trim();
    if (email.length > 0) {
      recipients.push(email);
    }
  }

  const displayables = reportCategoriesStore.useDisplayables(values.datasetId || '');

  return (
    <div>
      <Group label="Report Name">{values.name}</Group>
      <Group label="Frequency">{freq}</Group>
      <Group label="Category">{values.datasetId}</Group>
      <Group label="Columns">
        {values.fields
          .map((col) => {
            const disp = displayables.find((d) => d.field === col);
            return disp ? disp.label : col;
          })
          .join(', ')}
      </Group>
      <Group label="Recipients">
        {usersNotFound > 0
          ? usersNotFound +
            ' user' +
            (usersNotFound > 1 ? 's' : '') +
            (recipients.length > 0 ? ' and ' : '')
          : ''}
        {recipients.join(', ')}
      </Group>
      <Divider />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" size="large" onClick={(e) => saveAndPublish()}>
          Save and Publish
        </Button>
      </Box>
    </div>
  );
}
