import { Grid } from '@material-ui/core';
import { GUStates } from 'app/global-update/states';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';
import GlobalUpdateStep2TransportationSection from '../GlobalUpdateStep2TransportationSection';

export default function GlobalUpdateStep2TransportationLoadingPort() {
  const initPolEtd = useRecoilValue(GUStates.initialState.initPolEtd);
  const polEtd = useRecoilValue(GUStates.form.polEtd);
  const polEtdLabels = useRecoilValue(GUStates.form.polEtdLabels);

  const initPolAtd = useRecoilValue(GUStates.initialState.initPolAtd);
  const polAtd = useRecoilValue(GUStates.form.polAtd);
  const polAtdLabels = useRecoilValue(GUStates.form.polAtdLabels);

  return (
    <GlobalUpdateStep2TransportationSection title="Loading Port">
      <Grid container>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.polEtd} label="POL ETD" />
          <TextFieldWarnDecorator
            list={polEtdLabels}
            hasChanged={polEtd !== initPolEtd}
            defaultValue={initPolEtd}
            formState={GUStates.form.polEtd}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.polAtd} label="POL ATD" />
          <TextFieldWarnDecorator
            list={polAtdLabels}
            hasChanged={polAtd !== initPolAtd}
            defaultValue={initPolAtd}
            formState={GUStates.form.polAtd}
          />
        </Grid>
      </Grid>
    </GlobalUpdateStep2TransportationSection>
  );
}
