import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { GqlRevokeApiWebhookMutation, GqlRevokeApiWebhookMutationVariables } from 'api/GQL_Types';
import { ApiWebhooksQuery, revokeApiWebhook } from 'api/queries/apiWebhookQueries';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { SecretReadOnlyField } from 'components/SecretReadOnlyField';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { ApiWebhook, ApiWebhookPageStates } from './states';

interface Props {
  canEdit: boolean;
}

const columns: UWLTableColumn<ApiWebhook>[] = [
  { id: 'url', label: 'WebHook URL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'secret', label: 'Secret', type: 'string', whiteSpace: 'nowrap' },
  { id: 'createDate', label: 'Create Date', type: 'date', align: 'left' },
  { id: 'lastSent', label: 'Last Sent', type: 'date', align: 'left' },
];

export const ApiWebhookTableForm: React.FC<Props> = ({ canEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [confirmWebhookRevoke, setConfirmRevokeWebhook] = React.useState<ApiWebhook | null>(null);
  const apiWebhooks = useRecoilValue(ApiWebhookPageStates.apiWebhooks);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ApiWebhooksQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const revokeApiWebhookAction = useAsyncAction<
    GqlRevokeApiWebhookMutationVariables,
    GqlRevokeApiWebhookMutation
  >((input) => revokeApiWebhook(input, { refetchQueries }), {
    onData(data) {
      setConfirmRevokeWebhook(null);
      enqueueSnackbar('API webhook deleted!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to delete API webhook: ' + error, { variant: 'error' });
    },
  });

  return (
    <div>
      <UWLTable
        rowId="id"
        columns={columns}
        rows={apiWebhooks}
        emptyMessage="- No API Webhooks -"
        renderCell={{
          url(row) {
            return <SecretReadOnlyField value={row.url} alwaysVisible />;
          },
          secret(row) {
            return (
              <div style={{ width: '100%', maxWidth: 450 }}>
                <SecretReadOnlyField value={row.secret} />
              </div>
            );
          },
        }}
        rowAction={
          canEdit
            ? (row) => {
                return (
                  <IconButton
                    title="Delete"
                    aria-label="Delete"
                    style={{ width: '10px', height: '10px' }}
                    onClick={() => {
                      setConfirmRevokeWebhook(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              }
            : undefined
        }
      />
      {confirmWebhookRevoke && (
        <DeleteConfirmationDialog
          open
          onClose={() => {
            setConfirmRevokeWebhook(null);
          }}
          title="Delete Webhook"
          message={`Do you want to delete webhook ${confirmWebhookRevoke.url}?`}
          onClickDelete={() => {
            if (!confirmWebhookRevoke) return;
            revokeApiWebhookAction.act({
              input: {
                apiWebhookId: confirmWebhookRevoke.id,
              },
            });
          }}
          deleteBtnText="Delete"
          error={revokeApiWebhookAction.error}
          waiting={revokeApiWebhookAction.waiting}
        />
      )}
    </div>
  );
};
