import { Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  title: {
    ...theme.typography.body1,
    userSelect: 'none',
    cursor: 'pointer',
  },
  summary: {
    ...theme.typography.caption,
    color: '#777',
    marginLeft: theme.spacing(1),
  },
  body: {
    padding: `0 0 8px 30px`, // left padding is width of the expander icon
  },
  expandIcon: {
    transform: 'rotate(270deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&$expanded': {
      transform: 'rotate(360deg)',
    },
  },
  expanded: {},
});

interface Props {
  title: string;
  summary?: string | null;
  defaultExpanded?: boolean;
}

export const ExpanderSection: React.FC<Props> = ({ title, summary, defaultExpanded, children }) => {
  const [expanded, setExpanded] = React.useState<boolean>(!!defaultExpanded);

  return (
    <div>
      <div className={classes.title} onClick={(e) => setExpanded(!expanded)}>
        <IconButton className={clsx(classes.expandIcon, expanded && classes.expanded)} size="small">
          <ExpandMoreIcon />
        </IconButton>
        {title}
        {!expanded && <span className={classes.summary}>{summary}</span>}
      </div>

      <Collapse in={expanded}>
        <div className={classes.body}>{children}</div>
      </Collapse>
    </div>
  );
};
