import { Button, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { VolumeUnit, WeightUnit } from 'api/GQL_Types';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewBookingPageStates } from '../../states';

export default function AddButton() {
  const allLines = useRecoilValue(NewBookingPageStates.step1.allLines);
  const [selectedLines, setSelectedLines] = useRecoilState(
    NewBookingPageStates.step1.selectedLines
  );
  const [availableLines, setAvailableLines] = useRecoilState(
    NewBookingPageStates.step1.availableLines
  );
  const [availableLinesChecked, setAvailableLinesChecked] = useRecoilState(
    NewBookingPageStates.step1.availableLinesChecked
  );

  function addToBooking() {
    const newSelectedLines = [...selectedLines];

    for (const lineId of availableLinesChecked) {
      const line = availableLines.find((line) => line.id === lineId);
      if (line) {
        const alreadyExists = newSelectedLines.find((line) => line.id === lineId);
        if (!alreadyExists) {
          newSelectedLines.push({
            ...line,
            balanceQty: 0,
            shippedQty: line.balanceQty,
            shippedCartons: 0,
            weight: 0,
            weightUnit: WeightUnit.Kg,
            volume: 0,
            volumeUnit: VolumeUnit.Cbm,
          });
        }
      }
    }

    setAvailableLinesChecked([]);
    const selectedIds = newSelectedLines.map((line) => line.id);
    setAvailableLines(allLines.filter((line) => !selectedIds.includes(line.id)));
    setSelectedLines(newSelectedLines);
  }

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      disabled={availableLinesChecked.length === 0}
      onClick={addToBooking}
      style={{
        display: 'flex',
        width: '224px',
        justifyContent: 'space-evenly',
      }}
    >
      <ExpandLessIcon style={{ color: '#FFFFFF', marginRight: '8px' }} />
      <Typography variant="h3" color="textSecondary">
        ADD TO BOOKING
      </Typography>
    </Button>
  );
}
