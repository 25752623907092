import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { navigate, RouteComponentProps } from '@reach/router';
import { uploadPoFile } from 'api/queries/poUploadQueries';
import { auth } from 'app';
import Panel from 'components/Panel';
import { StatusDialog } from 'components/StatusDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#ccc';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  user-select: none;
`;

interface Props extends RouteComponentProps {}

export default function UploadPOPage(props: Props) {
  const { userContext } = auth.useAuthState();

  const actionUploadFile = useAsyncAction<File, string>(async (file) => {
    const res = await uploadPoFile({ input: { file } });
    const fileId = res?.uploadPoFile?.file?.id;
    if (!fileId) {
      throw Error('Failed to get file id');
    }
    return fileId;
  });
  const [uploadedPoFileId, setUploadedPoFileId] = React.useState<string | null>(null);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    acceptedFiles,
  } = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel', // .xls
      'text/csv', // .csv
      'text/plain', // fallback for .csv
    ],
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  React.useEffect(() => {
    let file = acceptedFiles.length > 0 ? acceptedFiles[0] : null;
    if (file && /\.xlsx?$|\.csv$/i.test(file.name)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  }, [acceptedFiles]);

  return (
    <Panel title="Upload Purchase Orders">
      <Box padding={2}>
        <Box margin={3} padding={2}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '20px',
              marginBottom: '8px',
            }}
          >
            <Typography variant="h4" style={{ lineHeight: '20px' }}>
              Network:
            </Typography>
            <div style={{ marginLeft: '8px', lineHeight: '20px' }}>
              {userContext?.activeContact?.profile?.network?.name}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', lineHeight: '20px' }}>
            <Typography variant="h4" style={{ lineHeight: '20px' }}>
              Controlling Client:
            </Typography>
            <div style={{ marginLeft: '8px', lineHeight: '20px' }}>
              {userContext?.activeContact?.profile?.network?.controllingClient?.name}
            </div>
          </div>
        </Box>

        <Box margin={3} padding={2}>
          {selectedFile ? (
            <Box width={'50%'}>
              <Typography variant="h3" style={{ marginBottom: '8px' }}>
                Select File
              </Typography>
              <Grid
                container
                key={selectedFile.name}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">{selectedFile.name}</Typography>
                </Grid>
                <Grid item>
                  <IconButton aria-label="delete" onClick={() => setSelectedFile(null)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box width={'50%'}>
              <Container
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
                onClick={open}
              >
                <input {...getInputProps()} />
                <CloudUploadIcon style={{ fontSize: 50, color: '#0288d1' }} />
                <Box fontSize="34px" fontWeight={300}>
                  Drag file to upload
                </Box>
                <Box margin={1} fontSize="24px" display="flex" width="50%">
                  <hr
                    style={{
                      borderColor: '#EDEDED',
                      width: '33%',
                      height: 0,
                      alignSelf: 'center',
                    }}
                  />
                  or
                  <hr
                    style={{
                      borderColor: '#EDEDED',
                      width: '33%',
                      height: 0,
                      alignSelf: 'center',
                    }}
                  />
                </Box>
                <Button variant="contained" color="secondary" size="large">
                  <Typography variant="h4" color="textSecondary">
                    Browse
                  </Typography>
                </Button>
              </Container>
            </Box>
          )}

          <Box marginTop={5}>
            <hr style={{ border: '0.5px solid #ededed' }} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!selectedFile}
                onClick={() => {
                  if (selectedFile) {
                    actionUploadFile.act(selectedFile).then((res) => {
                      setUploadedPoFileId(res.type === 'data' ? res.data : null);
                    });
                  }
                }}
              >
                <Typography variant="h3" color="textSecondary">
                  upload
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <StatusDialog
        onClose={() => {
          actionUploadFile.reset();
          setUploadedPoFileId(null);
        }}
        error={
          actionUploadFile.error
            ? {
                title: 'UPLOAD FAILED',
                message: actionUploadFile.error,
              }
            : null
        }
        finished={
          actionUploadFile.called && actionUploadFile.finished
            ? {
                title: 'UPLOAD COMPLETE',
                subtitle: 'File is now available to review.',
                buttons: (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      onClick={() => {
                        actionUploadFile.reset();
                        setUploadedPoFileId(null);
                        setSelectedFile(null);
                      }}
                    >
                      New Upload
                    </Button>
                    <Button
                      onClick={() => {
                        if (uploadedPoFileId) {
                          navigate('/purchase-orders/uploads/' + uploadedPoFileId);
                        }
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Review Now
                    </Button>
                  </>
                ),
              }
            : null
        }
        waiting={
          actionUploadFile.waiting
            ? {
                title: 'UPLOADING...',
                subtitle: 'Upload time depends on file size.',
              }
            : null
        }
      />
    </Panel>
  );
}
