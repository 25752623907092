import { Box } from '@material-ui/core';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { MblPageStates, RelatedHBL } from '../states';

const columns: UWLTableColumn<RelatedHBL>[] = [
  { id: 'hbl', label: 'HBL', type: 'link' },
  { id: 'type', label: 'Type', type: 'string' },
  { id: 'booking', label: 'Booking #', type: 'link' },
];

export default function RelatedHbls() {
  const rows = useRecoilValue(MblPageStates.relatedHBLs);

  return (
    <Box height="100%" display="flex">
      <Panel title="related hbl">
        <Box margin={2}>
          <UWLTable
            rowId="id"
            columns={columns}
            rows={rows}
            emptyMessage="No Related HBLs"
            noWrapAllCells
          />
        </Box>
      </Panel>
    </Box>
  );
}
