import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { GqlRevokeApiTokenMutation, GqlRevokeApiTokenMutationVariables } from 'api/GQL_Types';
import { ApiTokensQuery, revokeApiToken } from 'api/queries/apiQueries';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { SecretReadOnlyField } from 'components/SecretReadOnlyField';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { ApiPageStates, ApiToken } from './states';

interface Props {
  canEdit: boolean;
}

const columns: UWLTableColumn<ApiToken>[] = [
  { id: 'token', label: 'Token', type: 'string', whiteSpace: 'nowrap' },
  { id: 'createDate', label: 'Create Date', type: 'date', align: 'left' },
  { id: 'lastUsedDate', label: 'Last Used Date', type: 'date', align: 'left' },
];

export const ApiTokenTableForm: React.FC<Props> = ({ canEdit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [confirmTokenRevoke, setConfirmRevokeToken] = React.useState<ApiToken | null>(null);
  const apiTokens = useRecoilValue(ApiPageStates.apiTokens);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ApiTokensQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const revokeApiTokenAction = useAsyncAction<
    GqlRevokeApiTokenMutationVariables,
    GqlRevokeApiTokenMutation
  >((input) => revokeApiToken(input, { refetchQueries }), {
    onData(data) {
      setConfirmRevokeToken(null);
      enqueueSnackbar('API Token Revoked!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to revoke API token: ' + error, { variant: 'error' });
    },
  });

  return (
    <div>
      <UWLTable
        rowId="token"
        columns={columns}
        rows={apiTokens}
        emptyMessage="- No API Tokens -"
        renderCell={{
          token(row) {
            return (
              <div style={{ width: '100%', maxWidth: 450 }}>
                <SecretReadOnlyField value={row.token} />
              </div>
            );
          },
        }}
        rowAction={
          canEdit
            ? (row) => {
                return (
                  <IconButton
                    title="Revoke"
                    aria-label="delete"
                    style={{ width: '10px', height: '10px' }}
                    onClick={() => {
                      setConfirmRevokeToken(row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              }
            : undefined
        }
      />
      {confirmTokenRevoke && (
        <DeleteConfirmationDialog
          open
          onClose={() => {
            setConfirmRevokeToken(null);
          }}
          title="Revoke Token"
          message={`Do you want to revoke token ${confirmTokenRevoke.token}?`}
          onClickDelete={() => {
            if (!confirmTokenRevoke) return;
            revokeApiTokenAction.act({
              input: {
                token: confirmTokenRevoke.token,
              },
            });
          }}
          deleteBtnText="Revoke"
          error={revokeApiTokenAction.error}
          waiting={revokeApiTokenAction.waiting}
        />
      )}
    </div>
  );
};
