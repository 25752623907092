import { IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { makeCss } from 'styles';

const classes = makeCss({
  default: {
    '&:hover $clearButtonDirty, & .Mui-focused $clearButtonDirty': {
      visibility: 'visible',
    },
  },
  clearButtonDirty: {},
  clearButton: {
    padding: '2px',
    visibility: 'hidden',
  },
});

export interface Props {
  state: RecoilState<number | null>;

  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;

  // Adornments at the start or end of the input
  startAdornment?: string | React.ReactNode;
  endAdornment?: string | React.ReactNode;
  onChange?: (number: number | null) => void;
}

export default function AtomicNumberV2(props: Props) {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputNumber
        className={classes.default}
        value={state}
        onValue={(value) => {
          setState(value);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        label={props.label}
        disabled={props.disabled}
        required={props.required}
        placeholder={props.placeholder}
        minimumFractionDigits={props.minimumFractionDigits}
        maximumFractionDigits={props.maximumFractionDigits}
        startAdornment={props.startAdornment}
        endAdornment={
          <InputAdornment position="end" style={{ marginRight: '-8px' }}>
            <IconButton
              title="Clear"
              className={clsx(classes.clearButton, {
                [classes.clearButtonDirty]: state !== 0,
              })}
              onClick={() => {
                setState(0);
                if (props.onChange) {
                  props.onChange(0);
                }
              }}
              tabIndex={-1}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormItem>
  );
}
