import { Grid } from '@material-ui/core';
import { GqlVessel, GqlVoyage } from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

export interface VesselOption {
  id: string | null; // null if it's a new vessel
  name: string;
  voyages: VoyageOption[];
}

export interface VoyageOption {
  id: string | null; // null if it's a new voyage
  name: string;
}

export function fromGQL_VesselOption(
  vessel: Pick<GqlVessel, 'id' | 'name'> & { voyages: Pick<GqlVoyage, 'id' | 'name'>[] }
): VesselOption {
  return {
    id: vessel.id || '',
    name: vessel.name || '',
    voyages: vessel.voyages.map(fromGQL_VoyageOption),
  };
}

export function fromGQL_VoyageOption(vessel: Pick<GqlVoyage, 'id' | 'name'>): VoyageOption {
  return {
    id: vessel.id || '',
    name: vessel.name || '',
  };
}

interface Props {
  vessels: VesselOption[];
  setVessels: React.Dispatch<React.SetStateAction<VesselOption[]>>;
  adjective: string;
  vesselAtom: RecoilState<VesselOption | null>;
  voyageAtom: RecoilState<VoyageOption | null>;
}

export const VesselVoyageSelector: React.FC<Props> = (props) => {
  const [vessel, setVessel] = useRecoilState(props.vesselAtom);
  const [voyage, setVoyage] = useRecoilState(props.voyageAtom);
  const [voyages, setVoyages] = React.useState<VoyageOption[]>([]);
  const refVoyageInput = React.useRef<HTMLInputElement>(null);
  const [focusVoyage, setFocusVoyage] = React.useState<boolean>(true); // This needs to be `true` initial for the voyage focus logic to work - OMS-101

  React.useEffect(() => {
    const voyages = vessel?.voyages || [];
    setVoyages(voyages);
    setVoyage(
      (voyage && voyages.find((v) => v.id === voyage.id && v.name === voyage.name)) || null
    );
  }, [vessel]);

  React.useEffect(() => {
    if (focusVoyage && vessel) {
      // going through this rigamarole so that when the user hits tab to create a new vessel they go the voyage input AFTER it has been enabled - OMS-101
      if (refVoyageInput.current) {
        refVoyageInput.current.focus();
      }
      setFocusVoyage(false);
    }
  }, [focusVoyage, vessel]);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6}>
        <AtomicAutocompleteV2<VesselOption>
          state={props.vesselAtom}
          label={props.adjective + ' Vessel'}
          optionsList={props.vessels}
          onAddNew={(text, event) => {
            event.preventDefault(); // going through this rigamarole so that when the user hits tab to create a new vessel they go the voyage input AFTER it has been enabled - OMS-101
            setFocusVoyage(true);

            const newVessel: VesselOption = { id: null, name: text, voyages: [] };
            setVessel(newVessel);
            props.setVessels([newVessel, ...props.vessels]);
          }}
          isOptionNew={(value) => !value.id}
          displayResolver={(value) => value.name}
        />
      </Grid>
      <Grid item xs={6}>
        <AtomicAutocompleteV2<VoyageOption>
          inputRef={refVoyageInput}
          state={props.voyageAtom}
          label={props.adjective + ' Voyage'}
          optionsList={voyages}
          disabled={!Boolean(vessel)}
          onAddNew={(text) => {
            const newVoyage: VoyageOption = { id: null, name: text };
            setVoyage(newVoyage);
            setVoyages([newVoyage, ...voyages]);
          }}
          isOptionNew={(value) => !value.id}
          displayResolver={(value) => value.name}
        />
      </Grid>
    </Grid>
  );
};
