import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { theme } from 'styles';
import RolesCardList from '../../components/RolesCardList';
import { ProfilePageStates } from './states';

const profileRolesSearchState = newAtom('');

const filteredProfileRolesListState = selector({
  key: 'filteredProfileRolesListState',
  get: ({ get }) => {
    const searchField = get<string>(profileRolesSearchState);
    const profileRoles = get(ProfilePageStates.profile)?.rolesList || [];
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return profileRoles;
    } else {
      return profileRoles.filter((profileRole) =>
        profileRole.name.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface Props extends RouteComponentProps {}

export default function ProfileRoles(props: Props) {
  const { userContext } = auth.useAuthState();
  const profile = useRecoilValue(ProfilePageStates.profile);
  const [searchField, setSearchField] = useRecoilState(profileRolesSearchState);
  const filteredProfileRoles = useRecoilValue(filteredProfileRolesListState);

  if (!profile) {
    return null;
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Profile Roles</Typography>
          <Typography variant="body1">List of roles that are connected to this profile</Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Profile Roles"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={1} />
      {filteredProfileRoles.length === 0 && (
        <div>
          {searchField.length > 0 ? (
            <div>No roles matching search: "{searchField}"</div>
          ) : (
            <div>- none -</div>
          )}
        </div>
      )}
      <RolesCardList
        filteredList={filteredProfileRoles}
        iconList={[
          {
            name: 'Permissions',
            key: 'permissionLength',
            icon: faShieldAlt,
            color: theme.palette.dodgerBlue.main,
          },
          {
            key: 'description',
          },
        ]}
        type="Profile"
        id={profile.id}
        canCreate={!!userContext?.permissionCodes.has(PermissionCode.ProfileRoleCreate)}
        canEdit={!!userContext?.permissionCodes.has(PermissionCode.ProfileRoleUpdate)}
      />
    </div>
  );
}
