import { Link } from '@reach/router';
import { PartyType } from 'api/GQL_Types';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import { MarkAsReadCell } from 'app/dashboard/components/MarkAsReadCell';
import { PopoverTable } from 'app/dashboard/components/PopoverTable';
import { DashboardPageStates, TableLink } from 'app/dashboard/states';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import {
  STANDARD_ROW_INNER_HEIGHT,
  STANDARD_ROW_PADDING_HEIGHT,
  UWLTable,
} from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { AlertButton } from '../../components/AlertButton';
import { BaseAlert, NotificationResult, useMarkNotificationsAsRead } from '../states';

export interface NewBookingNotificationDisplay extends BaseAlert {
  id: string;
  unread: boolean;
  bookingNumber: TableLink;
  bookingDate: Date | null | undefined;
  status: string;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  expectedCargoReadyDate: Date | null | undefined;
  pos: TableLink[];
  items: string;
  equipment: string;
  shipperName: string;
  origin: string;
  destination: string;
  confirmationDate: Date | null | undefined;
}

const columns: UWLTableColumn<NewBookingNotificationDisplay>[] = [
  { id: 'unread', label: '!', type: 'string' },
  { id: 'bookingNumber', label: 'Booking #', type: 'link' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date' },
  { id: 'expectedCargoReadyDate', label: 'EXP CGO RDY Date', type: 'date' },
  { id: 'pos', label: 'PO #', type: 'link' },
  { id: 'items', label: 'Items', type: 'string' },
  { id: 'equipment', label: 'Equipment', type: 'string' },
  { id: 'shipperName', label: 'Shipper', type: 'string' },
  { id: 'origin', label: 'Origin', type: 'string' },
  { id: 'destination', label: 'Destination', type: 'string' },
  { id: 'confirmationDate', label: 'Confirmation Date', type: 'date' },
];

const exportColumns = columns.filter((c) => c.id !== 'unread');

// Used for both virtualization and the popover size
function rowInnerHeight(row: NewBookingNotificationDisplay): number {
  return Math.max(STANDARD_ROW_INNER_HEIGHT, row.pos.length * STANDARD_ROW_INNER_HEIGHT);
}

export default function NewBookingsButton() {
  const newBookingAlerts = useRecoilValue(DashboardPageStates.notifications.newBookings);
  const refAnchor = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const unreadCount = newBookingAlerts.filter((row) => row.unread).length;
  const windowSize = useWindowSize();
  const markAsRead = useMarkNotificationsAsRead();

  return (
    <>
      <AlertButton
        innerRef={refAnchor}
        label="New Bookings"
        quantity={newBookingAlerts.length}
        onClick={(e) => setOpen(true)}
      />
      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="New Bookings"
        titleRight={
          <>
            <TableExportWindowGroup
              label="New Bookings"
              rows={newBookingAlerts}
              columns={exportColumns}
            />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of newBookingAlerts) {
                    if (row.unread && row.id) {
                      unreadAlertIds.push(row.id);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total New Bookings"
        totalValue={formatNumber(newBookingAlerts.length)}
        width={windowSize.width * 0.8}
        bodyHeight={newBookingAlerts.reduce(
          (sum, row) => sum + rowInnerHeight(row) + STANDARD_ROW_PADDING_HEIGHT,
          0
        )}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={newBookingAlerts}
            columns={columns}
            emptyMessage="No New Bookings"
            noWrapAllCells
            virtualize={{
              rowHeight: rowInnerHeight,
              bufferHeight: STANDARD_ROW_INNER_HEIGHT * 5,
            }}
            renderCell={{
              unread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.unread}
                    notificationId={row.id}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
              pos(row) {
                return (
                  <div>
                    {row.pos.map((po) => {
                      return (
                        <div key={po.to}>
                          <Link to={po.to}>{po.value}</Link>
                        </div>
                      );
                    })}
                  </div>
                );
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
}

export function NewBookingNotificationDisplay_toRow(
  alert: NotificationResult
): NewBookingNotificationDisplay | null {
  if (alert.__typename !== 'NewBookingNotification') {
    return null;
  }

  let containers = '';
  if (alert.booking.containers) {
    const nContainersByType = new Map<string, number>();
    alert.booking.containers.forEach((container) => {
      const n = nContainersByType.get(container.containerType) || 0;
      nContainersByType.set(container.containerType, n + 1);
    });
    const lines: string[] = [];
    nContainersByType.forEach((nContainers, type) => {
      lines.push(`${type} (QTY ${formatNumber(nContainers)})`);
    });
    containers = lines.join(',\n');
  }

  let expectedCargoReadyDate: Date | null | undefined;
  for (const pl of alert.booking.packLines) {
    const ecrd = pl.orderLine.expectedCargoReadyDate;
    if (ecrd) {
      if (!expectedCargoReadyDate || ecrd < expectedCargoReadyDate) {
        expectedCargoReadyDate = ecrd;
      }
    }
  }

  return {
    id: alert.id,
    unread: true,
    bookingNumber: {
      to: '/bookings/' + alert.id,
      value: alert.booking.referenceNumber ?? '',
    },
    bookingDate: alert.booking.createDate,
    status: alert.booking.status,
    cargoReadyDate: alert.booking.cargoReadyDate,
    revisedCargoReadyDate: alert.booking.revisedCargoReadyDate,
    expectedCargoReadyDate,
    pos: alert.booking.packLines.map((pl) => {
      return {
        to: '/purchase-orders/' + pl.orderLine.purchaseOrder.id,
        value: pl.orderLine.purchaseOrder.poNumber,
      };
    }),
    items: alert.booking.packLines.map((pl) => pl.orderLine.itemNumber).join(', '),
    equipment: containers,
    shipperName: relatedPartyNameOrVarious(alert.booking.relatedParties, PartyType.Shipper),
    origin: alert.booking.logistics.pol?.name ?? '',
    destination: alert.booking.logistics.pod?.name ?? '',
    confirmationDate: alert.booking.logistics.confirmationDate,
  };
}
