import { Box, Button, Typography } from '@material-ui/core';
import { useMergeLocationMutation, useUpdateLocationMutation } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomCleaner, newAtom } from 'lib/RecoilUtils';
import { ProfilePageLocation, ProfilePageStates } from '../states';
import { LocationDialog_Data, ProfileLocationsDialog_Open } from './ProfileLocationsDialog';

export const ReviewProfileLocationDialog_Open = newAtom(false);
//data coming in from the location cards. If location is null, it will be treated as a new location
export const ReviewProfileLocationDialog_Data = newAtom<ProfilePageLocation | null>(null);

const selectedMergeLocation = newAtom({
  id: '',
  name: '',
});

export default function ReviewProfileLocationDialog() {
  const profile = useRecoilValue(ProfilePageStates.profile);
  const [openDialog, setOpenDialog] = useRecoilState(ReviewProfileLocationDialog_Open);
  const location = useRecoilValue(ReviewProfileLocationDialog_Data);
  const selectedMergeLoc = useRecoilValue(selectedMergeLocation);
  const setEditDialogOpen = useSetRecoilState(ProfileLocationsDialog_Open);
  const setEditDialogData = useSetRecoilState(LocationDialog_Data);

  const { enqueueSnackbar } = useSnackbar();

  const [updateLocation] = useUpdateLocationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profile?.id },
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted(data) {
      if (data.updateLocation.success) {
        enqueueSnackbar('Location Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
        setEditDialogOpen(true);
        setEditDialogData(location);
      } else {
        enqueueSnackbar(data.updateLocation.message || 'Failed to mark as new location.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to mark as new location.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const [mergeLocation] = useMergeLocationMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profile?.id },
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted(data) {
      if (data.mergeLocation.success) {
        enqueueSnackbar('Locations Merged!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      } else {
        enqueueSnackbar(data.mergeLocation.message || 'Failed to merge locations.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to merge locations.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  if (!location || !profile) {
    return null;
  }

  return (
    <UniversalDialog
      title="Review Location"
      open={openDialog}
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <AtomCleaner atoms={[selectedMergeLocation, ReviewProfileLocationDialog_Data]} />
      <Box width={700}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <AtomicSelectorV2
              label="Merge to Location"
              state={selectedMergeLocation}
              optionsList={profile.locationList.filter((loc: any) => loc.id !== location.id)}
              controllingField="id"
              displayField="name"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={selectedMergeLoc.id === ''}
              onClick={() => {
                mergeLocation({
                  variables: {
                    input: {
                      parentLocationId: selectedMergeLoc.id,
                      mergedLocationId: location.id,
                    },
                  },
                });
              }}
            >
              Merge Location
            </Button>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" margin={3}>
          <Typography variant="h2">OR</Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              updateLocation({
                variables: {
                  input: {
                    location: {
                      id: location.id,
                    },
                  },
                },
              });
            }}
          >
            Keep As New Location
          </Button>
        </Box>
      </Box>
    </UniversalDialog>
  );
}
