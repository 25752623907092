import { FormInputText } from 'components/form/FormInputText';
import * as React from 'react';
import { DatasetFilterType } from '..';

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function has(obj: any, key: string): obj is { [key: string]: unknown } {
  return obj != null && hasOwnProperty.call(obj, key);
}
function getStringProp(obj: unknown, key: string): string {
  if (has(obj, key) && typeof obj[key] === 'string') {
    const val = obj[key];
    if (typeof val === 'string') {
      return val;
    }
  }
  return '';
}

export const CityStateCountryFilter: DatasetFilterType<{
  city: string;
  state: string;
  country: string;
}> = {
  deserializeValue(filterable, jsonString) {
    try {
      const val = JSON.parse(jsonString as any);
      return {
        city: getStringProp(val, 'city'),
        state: getStringProp(val, 'state'),
        country: getStringProp(val, 'country'),
      };
    } catch (err) {}
    return {
      city: '',
      state: '',
      country: '',
    };
  },
  serializeValue(filterable, value) {
    return JSON.stringify(value);
  },

  defaultValue(filterable) {
    return { city: '', state: '', country: '' };
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput(props) {
    return (
      <div>
        <FormInputText
          value={props.value.city}
          onValue={(str) => props.onValue({ ...props.value, city: str })}
          label="City"
          disabled={props.disabled}
          notFullWidth
        />{' '}
        <FormInputText
          value={props.value.state}
          onValue={(str) => props.onValue({ ...props.value, state: str })}
          label="State"
          disabled={props.disabled}
          notFullWidth
        />{' '}
        <FormInputText
          value={props.value.country}
          onValue={(str) => props.onValue({ ...props.value, country: str })}
          label="Country"
          disabled={props.disabled}
          notFullWidth
        />
      </div>
    );
  },
};
