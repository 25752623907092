import { Box } from '@material-ui/core';
import { Link } from '@reach/router';
import { PanelBase, PanelHeader } from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import SearchBar from 'components/SearchBar';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { genKey, newAtom } from 'lib/RecoilUtils';
import React from 'react';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { formatDate } from 'types/Date';
import { UWLTableColumn } from 'types/UWLTable';
import { NewBookingPageStates, OpenOrderLine } from '../../states';
import AddButton from './AddButton';
import RemoveButton from './RemoveButton';

const orderLineSearchState = newAtom('');

const filteredOrderLineListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get(orderLineSearchState);
    const orderLines = get(NewBookingPageStates.step1.availableLines);
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return orderLines;
    } else {
      return orderLines.filter(
        (line) =>
          line.orderNumber.toLowerCase().includes(lowerField) ||
          line.itemNumber.toLowerCase().includes(lowerField) ||
          line.lineNumber.toLowerCase().includes(lowerField) ||
          formatDate(line.expectedCargoReadyDate).includes(lowerField) ||
          formatDate(line.revisedCargoReadyDate).includes(lowerField) ||
          formatDate(line.firstShipDate).includes(lowerField) ||
          formatDate(line.lastShipDate).includes(lowerField) ||
          line.shipToLocationName.toLowerCase().includes(lowerField)
      );
    }
  },
});

const columns: UWLTableColumn<OpenOrderLine>[] = [
  { id: 'orderNumber', label: 'PO', type: 'string', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'itemNumber', label: 'Item/SKU', type: 'string', whiteSpace: 'nowrap' },
  { id: 'lineNumber', label: 'Line', type: 'string', whiteSpace: 'nowrap' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number' },
  { id: 'orderQty', label: 'Ordered Qty', type: 'number' },
  { id: 'expectedCargoReadyDate', label: 'Exp Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'date' },
  { id: 'firstShipDate', label: 'First Ship', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship to Loc', type: 'string', whiteSpace: 'nowrap' },
];

export default function BookingOrderLinesTables() {
  const [availableLinesChecked, setAvailableLinesChecked] = useRecoilState(
    NewBookingPageStates.step1.availableLinesChecked
  );
  const [searchField, setSearchField] = useRecoilState(orderLineSearchState);
  const filteredPos = useRecoilValue(filteredOrderLineListState);

  if (!filteredPos) {
    return null;
  }

  return (
    <PanelBase>
      <PanelHeader
        title="Order Lines"
        topCenter={
          <Box display="flex" marginLeft="auto" marginRight="auto">
            <RemoveButton />
            <AddButton />
          </Box>
        }
        topRight={
          <Box width="25%" bgcolor="#F8F8F8" padding={1}>
            <SearchBar
              placeholder="Search by Purchase Order #"
              field={searchField}
              updateField={setSearchField}
            />
          </Box>
        }
      />
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          rows={filteredPos}
          emptyMessage="- No Open POs -"
          virtualize="single-line-cells"
          checkboxes={{
            checked: availableLinesChecked,
            setChecked: setAvailableLinesChecked,
          }}
          renderCell={{
            orderNumber(row) {
              return <Link to={'/purchase-orders/' + row.poId}>{row.orderNumber}</Link>;
            },
          }}
        />
      </PanelBodyTable>
    </PanelBase>
  );
}
