import { Box, Grid } from "@material-ui/core";
import ConsolidationFields from "./ConsolidationFields";
import ConsolidationRemarks from "./ConsolidationRemarks";
import BookingsTable from "./BookingsTable";

export default function NewConsolidationStep2() {
  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ConsolidationFields />
        </Grid>
        <Grid item xs={12}>
          <ConsolidationRemarks />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', height: 400 }}>
          <BookingsTable />
        </Grid>
      </Grid>
    </Box>
  );
}
