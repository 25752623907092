export function isNumber(num: unknown): num is number {
  // eslint-disable-next-line no-self-compare
  return typeof num === 'number' && num === num; // NaN check
}

export function formatNumber(num: number): string {
  if (isNumber(num)) {
    return num.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }
  return '';
}

export function formatNumberDecimalPlaces(
  num: number,
  minimumFractionDigits: number,
  maximumFractionDigits: number
): string {
  if (isNumber(num)) {
    return num.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  }
  return '';
}

export function roundToDecimalPlaces(num: number, decimalPlaces: number): number {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
  );
}

/**
 * Similar to python's `range()` https://docs.python.org/3/library/functions.html#func-range
 */
export function range(start: number, end: number, step: number = 1): number[] {
  const out: number[] = [];
  for (let i = start; i < end; i += step) {
    out.push(i);
  }
  return out;
}
