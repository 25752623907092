import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import { DocumentTypesListPage } from './list';
import { ManageDocumentTypePage } from './manage';

interface Props extends RouteComponentProps {}

export const ManageDocumentTypesPage: React.FC<Props> = (props) => {
  return (
    <FlexGrowRouter>
      <DocumentTypesListPage path="/" />
      <ManageDocumentTypePage path="/:code/*" />
    </FlexGrowRouter>
  );
};
