import { Box, IconButton, Typography } from '@material-ui/core';
import { Link, navigate, RouteComponentProps, useLocation } from '@reach/router';
import clsx from 'clsx';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { makeCss, theme } from 'styles';
import { LinkItem } from 'types/LinkItem';
import { isUUID } from 'types/UUID';

// Atom that holds the stack for the back button. Each item in the stack has a display label and the path to navigate to.
export const ManagePageHeader_BackStack = newAtom<{ label: string; path: string }[]>([]);

const classes = makeCss({
  root: {
    padding: theme.spacing(1, 2),
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
  },

  title: {
    ...theme.typography.h1,
    // color: theme.palette.primary.main,
    // letterSpacing: '.5px',
    userSelect: 'none',
    minWidth: 'fit-content',
  },

  backBtn: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  selected: {
    color: theme.palette.blueGreen.main,
    '&:hover': {
      background: '#FFFFFF',
    },
  },

  unSelected: {
    color: theme.palette.grayscale.main,
  },

  manageLink: {
    width: '100%',
    borderBottom: 'solid',
    textAlign: 'center',
    color: theme.palette.grayscale.main,
    textDecoration: 'none',
  },
  manageLinkSelected: {
    color: theme.palette.blueGreen.main,
  },
});

interface Props extends RouteComponentProps {
  title: string | null | undefined;
  links: LinkItem[];
}

export default function ManagePageHeader(props: Props) {
  const [backStack, setBackStack] = useRecoilState(ManagePageHeader_BackStack);
  const location = useLocation();

  React.useEffect(() => {
    // This should handle when browser back is used.
    if (
      backStack.length > 1 &&
      (location.pathname.startsWith(backStack[backStack.length - 1].path) ||
        location.pathname.startsWith('/' + backStack[backStack.length - 1].path))
    ) {
      const copy = [...backStack];
      copy.pop();
      setBackStack(copy);
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>{props.title}</div>
        {backStack.length > 0 && (
          <div
            className={classes.backBtn}
            onClick={() => {
              if (backStack.length > 0) {
                const copy = [...backStack];
                const to = copy.pop() ?? { label: '', path: '' };
                setBackStack(copy);
                navigate(to.path);
              }
            }}
          >
            <IconButton
              style={{ padding: 0, marginRight: '8px', color: theme.palette.blueGreen.main }}
            >
              <i className="material-icons">{'keyboard_backspace'}</i>
            </IconButton>
            <Typography
              variant="subtitle2"
              style={{
                minWidth: 'fit-content',
                marginRight: '16px',
                textAlign: 'end',
                color: theme.palette.blueGreen.main,
              }}
            >
              Back to {backStack[backStack.length - 1].label}
            </Typography>
          </div>
        )}
      </div>

      <Box display="flex" alignItems="flex-start" width={1} marginRight={2}>
        {props.links.map((item, index) => {
          const selected = isSelected(item.path, location.pathname);
          return (
            <Box display="flex" flexDirection="column" width="100%" key={index}>
              <ManageLink to={item.path} selected={selected}>
                <IconButton
                  className={selected ? classes.selected : classes.unSelected}
                  style={{ padding: 0, marginBottom: '4px' }}
                >
                  <i className="material-icons">{item.icon}</i>
                </IconButton>
                <Box style={{ marginBottom: '4px' }}>{item.name}</Box>
              </ManageLink>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

function isSelected(tabPath: string, currentPath: string): boolean {
  const splitPath = currentPath.split('/');
  return (
    (tabPath !== '' && currentPath.endsWith(tabPath)) ||
    (tabPath === '' && isUUID(splitPath[splitPath.length - 1]))
  );
}

const ManageLink: React.FC<{ to: string; selected: boolean }> = ({ to, selected, children }) => {
  return (
    <Link to={to} className={clsx(classes.manageLink, selected && classes.manageLinkSelected)}>
      {children}
    </Link>
  );
};
