import { RouteComponentProps, useLocation } from '@reach/router';
import Admin from 'app/admin';
import Api from 'app/admin/api';
import { ManageDocumentTypesPage } from 'app/admin/document-types';
import NetworkList from 'app/admin/networks/list';
import ProfilesTypes from 'app/admin/profile-types';
import Users from 'app/admin/users';
import Bookings from 'app/bookings';
import Consolidations from 'app/consolidations';
import Dashboard from 'app/dashboard';
import Documents from 'app/documents';
import Equipment from 'app/equipment';
import GlobalUpdatePage from 'app/global-update';
import HouseBills from 'app/house-bills';
import MasterBills from 'app/master-bills';
import MyAccount from 'app/my-account';
import PurchaseOrders from 'app/purchase-orders';
import Reports from 'app/reports';
import SearchResults from 'app/search-results';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import Header from './header';
import SideNavigation from './side-nav';
import { classes } from './styles';

interface Props extends RouteComponentProps {}

export default function Layout(props: Props) {
  const refContent = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  React.useEffect(() => {
    if (refContent.current) {
      refContent.current.scroll(0, 0);
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <SideNavigation />
      <main className={classes.main}>
        <Header />
        <div className={classes.content} ref={refContent}>
          <FlexGrowRouter>
            <Dashboard path="/" />
            <GlobalUpdatePage path="/admin/global-updates/*" />
            <Admin path="/admin/*" />
            <PurchaseOrders path="/purchase-orders/*" />
            <Bookings path="/bookings/*" />
            <Consolidations path="/consolidations/*" />
            <Equipment path="/equipment/*" />
            <HouseBills path="/hbl/*" />
            <MasterBills path="/mbl/*" />
            <Documents path="/documents/*" />
            <Reports path="/reports/*" />
            <SearchResults path="/search/:searchType/:searchId" />
            <MyAccount path="/my-account/*" />
          </FlexGrowRouter>
        </div>
      </main>
    </div>
  );
}

export const SysAdminLayout: React.FC<Props> = () => {
  return (
    <div className={classes.root}>
      <SideNavigation isSysAdmin />
      <main className={classes.main}>
        <div className={classes.content}>
          <FlexGrowRouter>
            <ProfilesTypes path="profile-types/*" />
            <ManageDocumentTypesPage path="document-types/*" />
            <Users path="users/*" />
            <NetworkList path="networks" />
            <Api path="api/*" />
          </FlexGrowRouter>
        </div>
      </main>
    </div>
  );
};
