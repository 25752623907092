import { Currency, GqlPortFragment, IncoTerm, MoveType } from 'api/GQL_Types';
import { HotState } from 'components/HotToggleSwitch';
import { newAtom } from 'lib/RecoilUtils';

export interface ContainerPageData {
  hot: HotState;
  lineItems: EquipmentLineItem[];

  id: string;
  containerNumber: string;
  containerType: string;
  sealNumber: string;
  pickupLocationName: string;
  supplierName: string;
  consigneeName: string;
  motherVesselName: string;
  motherVoyageName: string;
  carrierName: string;
  moveType: MoveType | null;
  incoTerm: IncoTerm | null;

  mbl: { id: string; referenceNumber: string } | null;

  shipFromFactoryDate: Date | null | undefined;
  terminalReceivedDate: Date | null | undefined;
  portOutGateDate: Date | null | undefined;
  emptyReturnedDate: Date | null | undefined;

  pol: GqlPortFragment | null | undefined;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;

  pod: GqlPortFragment | null | undefined;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;

  ramp: GqlPortFragment | null | undefined;
  rampAta: Date | null | undefined;
  rampEta: Date | null | undefined;

  finalDestinationName: string;
  deliveryAta: Date | null | undefined;
  deliveryEta: Date | null | undefined;

  demurrageLastFreeDayDate: Date | null | undefined;
  detentionLastFreeDayDate: Date | null | undefined;
  rampOutGateDate: Date | null | undefined;
  emptyNotifyDate: Date | null | undefined;
}

export const EquipmentPageStates = {
  container: newAtom<ContainerPageData | null>(null),
};

export interface EquipmentLineItem {
  id: string;
  poId: string;
  hbl: string;
  hblId: string;
  supplier: string;
  orderNumber: string;
  itemNumber: string;
  description: string;
  quantity: number;
  shippedQty: number;
  shippedCtns: number;
  unitPrice: number;
  unitPriceCurrencyCode: Currency | null | undefined;
  weightKG: number;
  volumeM3: number;
  commInvTotal: number;
  freightCost: number;
}
