import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import ManageApi from './manage';

interface ApiProps extends RouteComponentProps {}
function Api(props: ApiProps) {
  return (
    <FlexGrowRouter>
      <ManageApi path="/" />
    </FlexGrowRouter>
  );
}

export default Api;
