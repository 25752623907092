import { LocationType } from 'api/GQL_Types';
import { AppWideStates, useLocationTypeNameLookup } from 'app/states';
import AtomicCountryV2 from 'components/atomic/AtomicCountryV2';
import AtomicMultiSelectorV2 from 'components/atomic/AtomicMultiSelectorV2';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { AtomicTextWithAliasesV2 } from 'components/atomic/AtomicTextWithAliasesV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import FormInputTimezone from 'components/form/FormInputTimezone';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { LocationFormStates, locationTypeWarning } from './states';

interface Props {}

export const LocationForm: React.FC<Props> = () => {
  const [relatedPortCode, setUnloco] = useRecoilState(LocationFormStates.relatedPortCode);
  const [timeZone, setTimeZone] = useRecoilState(LocationFormStates.timeZone);
  const [hasSetTimeZone, setHasSetTimeZone] = useRecoilState(LocationFormStates.hasSetTimeZone);
  const locationTypeNameLookup = useLocationTypeNameLookup();
  const allLocationTypes = useRecoilValue(AppWideStates.locationTypes).all;
  const locTypeWarning = useRecoilValue(locationTypeWarning);

  return (
    <div>
      <FormRow>
        <AtomicTextWithAliasesV2
          required
          label="Location Name"
          textState={LocationFormStates.name}
          aliasesState={LocationFormStates.nameAliases}
        />
        <AtomicMultiSelectorV2<LocationType>
          state={LocationFormStates.locationTypes}
          label="Location Types"
          optionsList={allLocationTypes.map((l) => l.code)}
          valueResolver={(value) => value}
          displayResolver={locationTypeNameLookup}
          warningMessage={locTypeWarning}
        />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={LocationFormStates.addressLine1} label="Address 1" />
        <AtomicTextFieldV2 state={LocationFormStates.addressLine2} label="Address 2" />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={LocationFormStates.city} label="City" />
        <AtomicTextFieldV2 state={LocationFormStates.state} label="State" />
        <AtomicTextFieldV2 state={LocationFormStates.postalCode} label="Postal Code" />
        <AtomicCountryV2 required state={LocationFormStates.country} label="Country" />
      </FormRow>
      <FormRow>
        <AtomicTextWithAliasesV2
          label="Location Code"
          textState={LocationFormStates.code}
          aliasesState={LocationFormStates.codeAliases}
        />
        <FormItem>
          <FormInputPortAny
            label="UNLOCO"
            value={relatedPortCode}
            onValue={(v) => {
              if (v?.timeZone && !hasSetTimeZone) {
                setTimeZone(v.timeZone);
              }
              setUnloco(v);
            }}
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <AtomicPhoneNumberV2 state={LocationFormStates.companyPhone} label="Company Phone" />
        <FormItem>
          <FormInputTimezone
            label="Time Zone"
            value={timeZone}
            onValue={(selectedTimeZone) => {
              if (selectedTimeZone) {
                setTimeZone(selectedTimeZone);
                setHasSetTimeZone(true);
              } else {
                setTimeZone('');
                setHasSetTimeZone(false);
              }
            }}
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={LocationFormStates.companyUrl} label="Company URL" />
        <FormItem></FormItem>
      </FormRow>
    </div>
  );
};
