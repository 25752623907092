import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { PopoverTable } from '../components/PopoverTable';
import { ContainersInTransitDisplay } from '../states';

const columns: UWLTableColumn<ContainersInTransitDisplay>[] = [
  // TODO Hot Flag
  { id: 'consignee', label: 'Consignee', type: 'string' },
  { id: 'bl', label: 'BOL', type: 'link' },
  { id: 'cargoReadyDate', label: 'CRG RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CRG RDY Date', type: 'date' },
  { id: 'shipToLocation', label: 'Ship To Location', type: 'string' },
  { id: 'equipment', label: 'Equipment', type: 'link' },
  { id: 'invoice', label: 'COMM INV Total', type: 'currency' }, // COMM INV TOTAL (THIS IS THE SUM TOTAL OF ALL PO/LINES IN THE CONTAINER).
  { id: 'carrier', label: 'Carrier', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
  { id: 'ramp', label: 'Rail Ramp', type: 'string' },
  { id: 'rampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'rampAta', label: 'Rail Ramp ATA', type: 'date' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'deliveryEta', label: 'Final ETA', type: 'date' },
  { id: 'deliveryAta', label: 'Final ATA', type: 'date' },
  // TODO In DC Date
];

const exportColumns = columns;

interface Props {
  title: string;
  emptyMessage: string;
  containers: ContainersInTransitDisplay[];

  anchorEl: any;
  open: boolean;
  onClose(): void;
}

export const InTransitTablePopover: React.FC<Props> = ({
  title,
  emptyMessage,
  containers,
  anchorEl,
  open,
  onClose,
}) => {
  const windowSize = useWindowSize();

  return (
    <PopoverTable
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      title={title}
      titleRight={
        <TableExportWindowGroup label={title} rows={containers} columns={exportColumns} />
      }
      totalLabel="Total Shipments"
      totalValue={formatNumber(containers.length)}
      width={windowSize.width * 0.8}
      bodyHeight={containers.length * STANDARD_ROW_OVERALL_HEIGHT}
    >
      {open && (
        <UWLTable
          rowId={'id'}
          rows={containers}
          columns={columns}
          emptyMessage={emptyMessage}
          virtualize={'single-line-cells'}
        />
      )}
    </PopoverTable>
  );
};
