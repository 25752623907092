import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import { DatasetFilterType } from '..';
import { makeCss } from '../../../styles';

const classes = makeCss({
  input: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  value: {
    position: 'relative',
    marginRight: '.5rem',
  },
  removeBtn: { position: 'absolute', top: -9, right: -9 },
});

export const SelectOneOfFilter: DatasetFilterType<string[]> = {
  deserializeValue(filterable, jsonString) {
    try {
      let val = JSON.parse(jsonString as any);
      if (Array.isArray(val)) {
        val = val.filter((v) => typeof v === 'string');
        if (val.length > 0) {
          return val;
        }
      }
    } catch (err) {}
    return [''];
  },
  serializeValue(filterable, value) {
    return JSON.stringify(value);
  },

  defaultValue(filterable) {
    return [''];
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput(props) {
    const values = Array.isArray(props.value) && props.value.length > 0 ? props.value : [''];
    const options = props.filterable.options || [];

    return (
      <div className={classes.input}>
        {values.map((value, i) => (
          <div key={i} className={classes.value}>
            <FormControl variant="outlined" disabled={props.disabled}>
              <Select
                value={value || '-none-'}
                onChange={(e) => {
                  const selected = typeof e.target.value === 'string' ? e.target.value : '';
                  props.onValue(
                    values.map((v, j) => {
                      if (i === j) {
                        return selected;
                      }
                      return v;
                    })
                  );
                  const aEl = document.activeElement as any;
                  if (aEl && typeof aEl.blur === 'function') {
                    aEl.blur();
                  }
                }}
                disabled={props.disabled}
                fullWidth
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {(values.length > 1 || values[0] !== '') && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={classes.removeBtn}
                  onClick={() => props.onValue(values.filter((v, j) => i !== j))}
                  disabled={props.disabled}
                >
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              )}
            </FormControl>
          </div>
        ))}

        <IconButton
          aria-label="add"
          onClick={(e) => props.onValue(values.concat(['']))}
          disabled={props.disabled}
        >
          <AddIcon />
        </IconButton>
      </div>
    );
  },
};
