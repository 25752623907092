import { Button, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { GqlContainerInput, GqlNewBookingInput, useSaveBookingMutation } from 'api/GQL_Types';
import { OpenOrdersQuery } from 'api/queries/openOrdersQueries';
import { userContextAtom } from 'app';
import { StatusDialog } from 'components/StatusDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, useResetAtomGroupState } from 'lib/RecoilUtils';
import { NewBookingPageStates, step2Validation } from '../states';

export default function CompleteBookingButton() {
  const setActiveStep = useSetRecoilState(NewBookingPageStates.activeStep);
  const newBooking = useRecoilValue(NewBookingPageStates.newBooking);
  const resetState = useResetAtomGroupState(NewBookingPageStates);

  return (
    <>
      <Button
        onClick={() => {
          setActiveStep(0);
        }}
        style={{
          marginRight: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          color: '#043c5f',
          padding: '8px 32px',
        }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '112px' }}>
          Back
        </Typography>
      </Button>

      <SubmitButton />

      {newBooking && (
        /* This should clean the form when the dialog closes */
        <AtomGroupCleaner atomGroup={NewBookingPageStates} />
      )}
      {newBooking && (
        <StatusDialog
          onClose={() => {
            resetState();
          }}
          finished={{
            title: `Booking ${newBooking.referenceNumber} Created`,
            buttons: (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setActiveStep(0);
                    resetState();
                  }}
                >
                  New Booking
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    resetState();
                    navigate('/bookings/' + newBooking.bookingId);
                  }}
                >
                  View Booking
                </Button>
              </>
            ),
          }}
        />
      )}
    </>
  );
}

function SubmitButton() {
  const userContext = useRecoilValue(userContextAtom);
  const cargoReadyDate = useRecoilValue(NewBookingPageStates.step2.formCargoReadyDate);
  const incoTerm = useRecoilValue(NewBookingPageStates.step2.formIncoTerm);
  const moveType = useRecoilValue(NewBookingPageStates.step2.formMoveType);
  const containerMode = useRecoilValue(NewBookingPageStates.step2.formContainerMode);
  const transportationMode = useRecoilValue(NewBookingPageStates.step2.formTransportationMode);
  const remark = useRecoilValue(NewBookingPageStates.step2.bookingRemark);
  const contractType = useRecoilValue(NewBookingPageStates.step2.formContractType);
  const contract = useRecoilValue(NewBookingPageStates.step2.formContract);
  const carrier = useRecoilValue(NewBookingPageStates.step2.formCarrier);
  const pickupLocation = useRecoilValue(NewBookingPageStates.step2.formPickupLocation);
  const deliveryLocation = useRecoilValue(NewBookingPageStates.step2.formDeliveryLocation);
  const etd = useRecoilValue(NewBookingPageStates.step2.formEtd);
  const eta = useRecoilValue(NewBookingPageStates.step2.formEta);
  const pod = useRecoilValue(NewBookingPageStates.step2.formPod);
  const pol = useRecoilValue(NewBookingPageStates.step2.formPol);
  const equipmentList = useRecoilValue(NewBookingPageStates.step2.equipmentList);
  const packLines = useRecoilValue(NewBookingPageStates.step2.packLines);
  const consignee = useRecoilValue(NewBookingPageStates.step2.formConsignee);
  const supplier = useRecoilValue(NewBookingPageStates.step2.formSupplier);
  const manufacturer = useRecoilValue(NewBookingPageStates.step2.formManufacturer);
  const { enqueueSnackbar } = useSnackbar();
  const validation = useRecoilValue(step2Validation);

  const setNewBooking = useSetRecoilState(NewBookingPageStates.newBooking);

  const [saveBooking, { loading }] = useSaveBookingMutation({
    refetchQueries: () => [
      {
        query: OpenOrdersQuery,
        variables: { profileId: userContext?.activeContact?.profile.id },
      },
    ],
    onCompleted(data) {
      const newBooking = data.newBooking?.booking;
      if (data.newBooking.success && newBooking) {
        setNewBooking({
          bookingId: newBooking.id,
          referenceNumber: newBooking.referenceNumber,
        });
      } else {
        enqueueSnackbar(
          'Failed to create booking: ' + (data.newBooking.message || 'Unexpected Error'),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create booking: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <span title={validation.whyCantCreate}>
      <Button
        disabled={!validation.canCreate || loading}
        variant="contained"
        color="primary"
        onClick={() => {
          if (!validation.canCreate || !consignee || !supplier || !manufacturer) {
            return;
          }

          const containers: GqlContainerInput[] = [];
          for (const item of equipmentList) {
            for (let i = 0; i < item.qty; i++) {
              containers.push({ containerType: item.containerType });
            }
          }

          const input: GqlNewBookingInput = {
            booking: {
              incoTerm: incoTerm,
              cargoReadyDate: cargoReadyDate,
            },
            logistics: {
              moveType: moveType,
              transportationMode: transportationMode,
              containerMode: containerMode,
              contractType: contractType,
              contractNumber: contract,
              carrierId: carrier ? carrier.id : undefined,
              pickupLocationId: pickupLocation?.id,
              deliveryLocationId: deliveryLocation?.id,
              finalDestinationId: deliveryLocation?.id,
              podCode: pod?.code,
              polCode: pol?.code,
              polEtd: etd,
              podEta: eta,
            },
            packLines: packLines.map((pl) => {
              return {
                requestedDeliveryDate: pl.requestedDeliveryDate,
                shippedQty: pl.shippedQty,
                shippedCartons: pl.shippedCartons,
                volumeM3: pl.volume,
                weightKg: pl.weight,
                orderLineId: pl.id,
              };
            }),
            containers: containers,
            remark: remark
              ? {
                  text: remark,
                }
              : undefined,
            consigneeId: consignee.party.id,
            supplierId: supplier.party.id,
            manufacturerId: manufacturer.party.id,
          };
          saveBooking({
            variables: {
              input: input,
            },
          });
        }}
        style={{ padding: '8px 32px' }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
          Finish
        </Typography>
      </Button>
    </span>
  );
}
