import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import LineItem from 'components/LineItem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { theme } from 'styles';
import { ManageUserPageStates } from '../states';
import UserSecurityDialog, { UserSecurityDialog_Data } from './UserSecurityDialog';

const Wrapper = styled.div`
  margin: '16px';
`;

const Line = styled.hr`
  border-color: #043c5f;
  margin: 8px 0 16px 0;
  width: 100%;
`;

interface Props extends RouteComponentProps {}
export default function UserSecurity(props: Props) {
  const user = useRecoilValue(ManageUserPageStates.user);
  const setUserSecurityDialog = useSetRecoilState(UserSecurityDialog_Data);

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <Box alignSelf="flex-end">
          <Typography variant="h4" style={{ color: theme.palette.primary.main, fontWeight: 900 }}>
            User Password & Security Details
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setUserSecurityDialog(user);
            }}
            style={{ width: '150px' }}
          >
            Update
          </Button>
        </Box>
      </Box>
      <Line />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={user?.email} label="Email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem label="Status" value={user?.isActive ? 'Active' : 'Inactive'} />
          </Grid>
        </Grid>
      </Box>
      <UserSecurityDialog />
    </Wrapper>
  );
}
