import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { PermissionCode, useConsolidationLazyQuery, useConsolidationQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { DocumentsPanel } from 'app/components/DocumentsPanel';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { newAtom } from 'lib/RecoilUtils';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { makeCss, theme } from 'styles';
import { formatDate } from 'types/Date';
import ConsolidationBookingsPanel from './bookings';
import { CancelConsolButton } from './CancelConsolButton';
import ConsolidationDetailsPanel from './consolidation-details';
import { ConsolidationPartyOverrideDialog } from './ConsolidationPartyOverrideDialog';
import ConsolidationEquipment from './equipment';
import LogisticDetailsPanel from './logistics';
import ConsolidationRemarks from './remarks';
import { ConsolidationPageStates, useSetupConsolidationPage } from './states';

const classes = makeCss({
  topRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(1.5),
    },
  },
});

export const bookingRelatedPartiesState = newAtom([]);
const openRelatedPartiesDialog = newAtom(false);

export interface ConsolidationDetailProps extends RouteComponentProps {
  consolidationId?: string;
}

export default function ConsolidationDetails(props: ConsolidationDetailProps) {
  const userContext = useRecoilValue(userContextAtom);
  const consolidationId = props.consolidationId ?? '';
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);
  const allDocuments = useRecoilValue(ConsolidationPageStates.allDocuments);
  const setOpenDialog = useSetRecoilState(openRelatedPartiesDialog);
  const [isHot, setIsHot] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [refetchConsolidationQuery] = useConsolidationLazyQuery({
    variables: { consolId: consolidation?.id ?? '' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.consolidation) {
        enqueueSnackbar('Consolidation Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      }
    },
  });

  const {
    data: consolidationData,
    loading,
    error,
  } = useConsolidationQuery({
    variables: { consolId: props.consolidationId ?? '' },
    fetchPolicy: 'network-only',
  });

  const setupConsolidationPage = useSetupConsolidationPage();

  React.useEffect(() => {
    setupConsolidationPage(consolidationData);
  }, [consolidationData]);

  React.useEffect(() => {
    if (consolidation?.containers) {
      for (const con of consolidation?.containers) {
        if (con.isHot) {
          setIsHot(true);
          return;
        }
      }
    }
  }, [consolidation]);

  const consolNotFound =
    !loading && !error && consolidationData && !consolidationData.consolidation;

  React.useEffect(() => {
    if (consolNotFound) {
      navigate('/bookings');
    }
  }, [consolNotFound]);

  if (error && !consolidation) {
    return <ErrorMessage error={error + ''} />;
  }

  if (!consolidation || loading || !userContext) {
    return <Loading />;
  }

  return (
    <Box>
      <ErrorMessage error={error ? error + '' : null} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginLeft={1}
        marginTop={1}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            Consolidation: {consolidation?.referenceNumber}
          </Typography>
          {isHot && (
            <FontAwesomeIcon
              icon={faFire}
              style={{ color: 'orange', marginLeft: '12px' }}
              size="lg"
            />
          )}
          {consolidation?.cancelledDate && (
            <div style={{ marginLeft: theme.spacing(1), display: 'flex' }}>
              <Typography variant="h2" color="error" style={{ marginRight: theme.spacing(0.5) }}>
                CANCELLED
              </Typography>
              {consolidation.cancelledBy ? ` by ${consolidation.cancelledBy} ` : ''} on{' '}
              {formatDate(consolidation.cancelledDate)}
            </div>
          )}
        </Box>
        <div className={classes.topRight}>
          {!consolidation?.cancelledDate && <CancelConsolButton />}
          {userContext?.permissionCodes.has(PermissionCode.ConsolidationRelatedPartiesOverride) && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Consolidation Party Override
              </Button>
              <ConsolidationPartyOverrideDialog
                openState={openRelatedPartiesDialog}
                data={consolidation}
                onSaved={() => {
                  refetchConsolidationQuery();
                }}
              />
            </>
          )}
        </div>
      </Box>
      <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item xs={12} md={6}>
          <ConsolidationDetailsPanel />
        </Grid>
        <Grid item xs={12} md={6}>
          <LogisticDetailsPanel consolidationId={consolidationId} />
        </Grid>
      </Grid>
      <ConsolidationRemarks />
      <ConsolidationEquipment consolidationId={consolidationId} />
      <ConsolidationBookingsPanel consolidationId={consolidationId} />
      <DocumentsPanel documents={allDocuments} showBookingNumber showHblNumber />
    </Box>
  );
}
