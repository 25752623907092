import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';

interface Props {
  options: { id: string; label: string }[];
  value: string[];
  onValue(v: string[]): void;

  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;
}

export const FormInputMultiSelect: React.FC<Props> = ({
  options,
  value,
  onValue,
  label,
  required,
  disabled,
  error,
  helperText,
}) => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl
      variant="outlined"
      error={!!error}
      fullWidth
      required={required}
      disabled={disabled}
      size="small"
    >
      <InputLabel
        ref={inputLabel}
        disabled={disabled}
        shrink // See #OMS-54
      >
        {label}
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={(e) => {
          const values = e.target.value as string[];
          onValue(options.filter((o) => values.includes(o.id)).map((o) => o.id));
        }}
        renderValue={(selected: any) =>
          options
            .filter((o) => selected.includes(o.id))
            .map((o) => o.label)
            .join(', ')
        }
        labelWidth={labelWidth}
        disabled={disabled}
        fullWidth
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox color="primary" checked={!!value.find((id) => id === option.id)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
      </Select>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
