import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  PermissionCode,
  useDeleteContainerMutation,
  useNewBookingContainersMutation,
} from 'api/GQL_Types';
import { BookingQuery } from 'api/queries/bookingQueries';
import { userContextAtom } from 'app';
import AtomicContainerType from 'components/atomic/AtomicContainerType';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BookingPageStates } from '../../../states';
import ContainerItemDropdown from './ContainerItemDropdown';

export default function EquipmentList() {
  const userContext = useRecoilValue(userContextAtom);

  const setSelectedContainer = useSetRecoilState(
    BookingPageStates.containerAssignment.selectedContainer
  );
  const setContainerNumber = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.containerNumber
  );
  const setContainerType = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.containerType
  );
  const setSealNumber = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.sealNumber
  );
  const setShipFromFactory = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.shipFromFactory
  );
  const setTerminalReceived = useSetRecoilState(
    BookingPageStates.containerAssignment.editContainer.terminalReceived
  );

  const booking = useRecoilValue(BookingPageStates.booking);
  const [equipmentType, setEquipmentType] = useRecoilState(
    BookingPageStates.containerAssignment.selectedContainerType
  );
  const [equipmentQty, setEquipmentQty] = React.useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const [newBookingContainers] = useNewBookingContainersMutation({
    onCompleted: (data) => {
      if (data.newBookingContainers.success) {
        enqueueSnackbar('New Booking Container/s Added.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id },
      },
    ],
  });

  const [deleteContainer] = useDeleteContainerMutation({
    onCompleted: (data) => {
      if (data.deleteContainer.success) {
        enqueueSnackbar('Container Removed.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id },
      },
    ],
  });

  return (
    <>
      <Box flexGrow={1}>
        {booking?.containers &&
          booking?.containers.map((container, index) => {
            return (
              <ContainerItemDropdown
                key={index}
                container={container}
                onContainerSelected={(container) => {
                  setSelectedContainer(container);
                  setContainerNumber(container.containerNumber ?? '');
                  setContainerType(container.containerType);
                  setSealNumber(container.sealNumber ?? '');
                  setShipFromFactory(container.shipFromFactoryDate);
                  setTerminalReceived(container.terminalReceivedDate);
                  // setContainerItems()
                }}
                onContainerDelete={(container) => {
                  deleteContainer({
                    variables: {
                      input: {
                        containerId: container.id,
                      },
                    },
                  });
                }}
              />
            );
          })}
      </Box>
      {userContext?.permissionCodes.has(PermissionCode.BookingContainerCreate) && (
        <Box marginLeft={2} marginRight={2} marginBottom={1}>
          <Box display="flex" flexGrow={1} marginBottom={1}>
            <Typography variant="h3" color="secondary">
              Add Equipment
            </Typography>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <AtomicContainerType
                state={BookingPageStates.containerAssignment.selectedContainerType}
              />
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box marginRight={2}>
                  <Button
                    disabled={equipmentQty === 0 || !equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty - 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <RemoveCircleIcon
                      color={!equipmentQty || !equipmentType ? 'disabled' : 'secondary'}
                      fontSize="medium"
                    />
                  </Button>
                </Box>
                <Box>
                  <TextField
                    required
                    id="qty"
                    disabled={!equipmentType}
                    InputProps={{ inputProps: { min: 0 } }}
                    label="Qty"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={equipmentQty}
                    onChange={(e) => {
                      parseInt(e.target.value) >= 0
                        ? setEquipmentQty(+e.target.value)
                        : setEquipmentQty(0);
                    }}
                  />
                </Box>
                <Box marginLeft={2}>
                  <Button
                    disabled={!equipmentType}
                    onClick={() => {
                      setEquipmentQty(equipmentQty + 1);
                    }}
                    style={{ padding: '0', minWidth: 'auto' }}
                  >
                    <AddCircleIcon
                      color={!equipmentType ? 'disabled' : 'secondary'}
                      fontSize="medium"
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '96px' }}
                  onClick={() => {
                    const list = [];
                    for (let i = 0; i < equipmentQty; i++) {
                      list.push({ containerType: equipmentType });
                    }
                    newBookingContainers({
                      variables: {
                        input: {
                          bookingId: booking?.id || '',
                          containers: [...list],
                        },
                      },
                    });
                    setEquipmentType(null);
                    setEquipmentQty(0);
                  }}
                  disabled={equipmentQty < 1}
                >
                  <Typography variant="h3" color="textSecondary">
                    ADD
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
