import clsx from 'clsx';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { DatasetFilterType } from '..';

const borderRadius = theme.shape.borderRadius;

const classes = makeCss({
  root: {
    display: 'flex',
    '&$disabled': {
      '& $btn': {
        cursor: 'default',
        borderColor: 'rgba(0, 0, 0, 0.26)',
        color: 'rgba(0, 0, 0, 0.38)',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$active': {
          color: '#f0f0f0',
          backgroundColor: '#a6c7e2',
          fontWeight: 'normal',
        },
      },
    },
  },
  btn: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: borderRadius,
    padding: theme.spacing(1, 0),
    textAlign: 'center',
    width: 40,
    lineHeight: 1,
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& + &': {
      borderLeft: 'none',
    },
    '&:first-child': {
      borderRadius: `${borderRadius}px 0px 0px ${borderRadius}px`,
    },
    '&:last-child': {
      borderRadius: `0px ${borderRadius}px ${borderRadius}px 0px`,
    },
    '&$active': {
      color: '#fff',
      backgroundColor: '#3980b9',
      fontWeight: 'bold',
    },
  },
  active: {},
  disabled: {},
});

export const BooleanFilter: DatasetFilterType<boolean> = {
  deserializeValue(filterable, str) {
    return (
      str === 'true' ||
      str === 'True' ||
      str === '1' ||
      (str as any) ===
        // B/c client may pass in a raw boolean
        true
    );
  },
  serializeValue(filterable, value) {
    return value ? 'true' : 'false';
  },

  defaultValue(filterable) {
    return true;
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput: ({ value, onValue, disabled }) => {
    return (
      <div className={clsx(classes.root, disabled && classes.disabled)}>
        <div
          className={clsx(classes.btn, value && classes.active)}
          onClick={disabled ? undefined : () => onValue(true)}
        >
          Yes
        </div>
        <div
          className={clsx(classes.btn, !value && classes.active)}
          onClick={disabled ? undefined : () => onValue(false)}
        >
          No
        </div>
      </div>
    );
  },
};
