import { Button, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import {
  GqlContainerInput,
  GqlNewConsolidationInput,
  TransportMode,
  useSaveConsolidationMutation,
} from 'api/GQL_Types';
import { AvailableConsolidationBookingListQuery } from 'api/queries/consolidationQueries';
import { userContextAtom } from 'app';
import { StatusDialog } from 'components/StatusDialog';
import { useSnackbar } from 'notistack';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomGroupCleaner, useResetAtomGroupState } from 'lib/RecoilUtils';
import { NewConsolidationStates } from '../states';

export default function CompleteConsolidationButton() {
  const setActiveStep = useSetRecoilState(NewConsolidationStates.activeStep);
  const newConsolidation = useRecoilValue(NewConsolidationStates.newConsolidation);
  const resetState = useResetAtomGroupState(NewConsolidationStates);

  return (
    <>
      <Button
        onClick={() => {
          setActiveStep(0);
        }}
        style={{
          marginRight: '24px',
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          color: '#043c5f',
          padding: '8px 32px',
        }}
      >
        <Typography variant="h3" color="inherit" style={{ width: '112px' }}>
          Back
        </Typography>
      </Button>
      <SubmitButton />
      {newConsolidation && <AtomGroupCleaner atomGroup={NewConsolidationStates} />}
      {newConsolidation && (
        <StatusDialog
          onClose={() => {
            resetState();
          }}
          finished={{
            title: `Consolidation ${newConsolidation.referenceNumber} Created`,
            buttons: (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setActiveStep(0);
                    resetState();
                  }}
                >
                  New Consolidation
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    resetState();
                    navigate('/consolidations/' + newConsolidation.consolidationId);
                  }}
                >
                  View Consolidation
                </Button>
              </>
            ),
          }}
        />
      )}
    </>
  );
}

function SubmitButton() {
  const userContext = useRecoilValue(userContextAtom);
  const cfsOpenDate = useRecoilValue(NewConsolidationStates.step2.formCfsOpenDate);
  const cfsCutoffDate = useRecoilValue(NewConsolidationStates.step2.formCfsCutoffDate);
  const moveType = useRecoilValue(NewConsolidationStates.step2.formMoveType);
  const containerMode = useRecoilValue(NewConsolidationStates.step2.formContainerMode);
  const remark = useRecoilValue(NewConsolidationStates.step2.consolidationRemark);
  const carrier = useRecoilValue(NewConsolidationStates.step2.formCarrier);
  const cfsLocation = useRecoilValue(NewConsolidationStates.step2.formCFSLocation);
  const deliveryLocation = useRecoilValue(NewConsolidationStates.step2.formDeliveryLocation);
  const etd = useRecoilValue(NewConsolidationStates.step2.formEtd);
  const eta = useRecoilValue(NewConsolidationStates.step2.formEta);
  const pod = useRecoilValue(NewConsolidationStates.step2.formPod);
  const pol = useRecoilValue(NewConsolidationStates.step2.formPol);
  const equipmentList = useRecoilValue(NewConsolidationStates.step1.equipmentList);
  const selectedBookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);
  const setNewConsolidation = useSetRecoilState(NewConsolidationStates.newConsolidation);
  const { enqueueSnackbar } = useSnackbar();

  const [saveConsolidation, { loading }] = useSaveConsolidationMutation({
    refetchQueries: () => [
      {
        query: AvailableConsolidationBookingListQuery,
        variables: { profileId: userContext?.activeContact?.profile.id },
      },
    ],
    onCompleted(data) {
      const newConsolidation = data.newConsolidation?.consolidation;
      if (data.newConsolidation.success && newConsolidation) {
        setNewConsolidation({
          consolidationId: newConsolidation.id,
          referenceNumber: newConsolidation.referenceNumber,
        });
      } else {
        enqueueSnackbar(
          'Failed to create consolidation: ' +
            (data.newConsolidation.message || 'Unexpected Error'),
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to create consolidation: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      disabled={cfsOpenDate === null || cfsCutoffDate === null || loading}
      variant="contained"
      color="primary"
      onClick={() => {
        if (cfsOpenDate === null || cfsCutoffDate === null) {
          return;
        }

        const containers: GqlContainerInput[] = [];
        for (const item of equipmentList) {
          for (let i = 0; i < item.qty; i++) {
            containers.push({ containerType: item.containerType });
          }
        }

        const input: GqlNewConsolidationInput = {
          logistics: {
            cfsOpenDate: cfsOpenDate,
            cfsCutoffDate: cfsCutoffDate,
            transportationMode: TransportMode.Sea,
            moveType: moveType,
            containerMode: containerMode,
            carrierId: carrier ? carrier.id : undefined,
            cfsLocationId: cfsLocation?.id,
            deliveryLocationId: deliveryLocation?.id,
            finalDestinationId: deliveryLocation?.id,
            podCode: pod?.code,
            polCode: pol?.code,
            polEtd: etd,
            podEta: eta,
          },
          bookingIds: selectedBookings.map((booking) => booking.id),
          containers: containers,
          remark: remark
            ? {
                text: remark,
              }
            : undefined,
        };
        saveConsolidation({
          variables: {
            input: input,
          },
        });
      }}
      style={{ padding: '8px 32px' }}
    >
      <Typography variant="h3" color="inherit" style={{ width: '100px' }}>
        Consolidate
      </Typography>
    </Button>
  );
}
