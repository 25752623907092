import { NotificationCode, GqlProfileTypeTemplatesQuery, PermissionCode } from 'api/GQL_Types';
import { newAtom } from 'lib/RecoilUtils';

export interface AvailableProfileTemplateType {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
  permissionCodes: PermissionCode[];
  notificationCodes: NotificationCode[];
  defaultRoles: {
    id: string;
    name: string;
    description: string;
    isDefaultRole: boolean;
    permissionCodes: PermissionCode[];
    notificationCodes: NotificationCode[];
  }[];
}

export const NewNetworkPageStates = {
  newNetworkDialog_Open: newAtom(false),
  availableProfileTypeTemplates: newAtom<AvailableProfileTemplateType[]>([]),
  fields: {
    formNetworkName: newAtom<string>(''),
    selectedProfileTypes: newAtom<string[]>([]),
  },
};

export function fromGqlProfileTypeTemplatesQuery(
  data: GqlProfileTypeTemplatesQuery
): AvailableProfileTemplateType[] {
  if (!data) {
    return [];
  }
  return data.profileTypeTemplates.map((profileTemplate): AvailableProfileTemplateType => {
    return {
      id: profileTemplate.id,
      name: profileTemplate.name,
      description: profileTemplate.description || '',
      isTemplate: profileTemplate.isTemplate,
      notificationCodes: profileTemplate.notificationCodes,
      defaultRoles: profileTemplate.defaultRoles.map((defaultRole) => {
        return {
          id: defaultRole.id,
          name: defaultRole.name,
          description: defaultRole.description || '',
          isDefaultRole: true,
          permissionCodes: defaultRole.permissionCodes,
          notificationCodes: defaultRole.notificationCodes,
        };
      }),
      permissionCodes: profileTemplate.permissionCodes,
    };
  });
}
