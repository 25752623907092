import React from 'react';
import { Box } from '@material-ui/core';
import { theme } from 'styles';

interface Props {
  title: string;
}

export default function SectionTitle(props: Props) {
  return (
    <Box
      marginTop={2}
      marginBottom={2}
      fontSize="h4"
      fontWeight={900}
      color={theme.palette.primary.main}
    >
      {props.title}
      <Box
        width={1}
        bgcolor={theme.palette.primary.main}
        height={2}
        marginTop={1}
        marginBottom={1}
      />
    </Box>
  );
}
