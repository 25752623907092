import { Box, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { PartyType, useContainerQuery } from 'api/GQL_Types';
import { HotContainerSwitch } from 'app/components/HotContainerSwitch';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { HotState } from 'components/HotToggleSwitch';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import { AtomGroupCleaner } from 'lib/RecoilUtils';
import React from 'react';
import { useRecoilState } from 'recoil';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import EquipmentDetailsPanel from './DetailsPanel';
import EquipmentLineItems from './LineItemsPanel';
import { ContainerPageData, EquipmentLineItem, EquipmentPageStates } from './states';
import TransitDetailsPanel from './TransitDetailsPanel';

interface EquipmentDetailProps extends RouteComponentProps {
  equipmentId?: string;
}
export default function EquipmentDetails(props: EquipmentDetailProps) {
  const [container, setContainer] = useRecoilState(EquipmentPageStates.container);

  const {
    data: containerData,
    loading,
    error,
  } = useContainerQuery({
    variables: { containerId: props.equipmentId || '' },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    const container = containerData?.container;
    if (container) {
      const hotContainer: HotState = {
        isHot: container.isHot,
        hotMarkedBy: container.hotMarkedBy,
        hotMarkedTimestamp: container.hotMarkedTimestamp,
      };

      const supplierNames = new Set<string>();
      const consigneeNames = new Set<string>();

      const lineItems = (container?.vanPositions ?? []).map((vanPosition): EquipmentLineItem => {
        for (const rp of vanPosition.packLine.booking.relatedParties) {
          if (rp.partyType === PartyType.Supplier) {
            supplierNames.add(rp.party.name);
          }
          if (rp.partyType === PartyType.Consignee) {
            consigneeNames.add(rp.party.name);
          }
        }
        return {
          id: vanPosition.id,
          poId: vanPosition.packLine.orderLine.purchaseOrder.id,
          hbl: vanPosition.packLine.booking.hbl?.referenceNumber ?? '',
          hblId: vanPosition.packLine.booking.hbl?.id ?? '',
          supplier: relatedPartyNameOrVarious(
            vanPosition.packLine.booking.relatedParties,
            PartyType.Supplier
          ),
          orderNumber: vanPosition.packLine.orderLine.purchaseOrder.poNumber,
          itemNumber: vanPosition.packLine.orderLine.itemNumber,
          description: vanPosition.packLine.orderLine.itemDescription,
          quantity: vanPosition.packLine.orderLine.openOrder.orderQty,
          shippedQty: vanPosition.piecesQty,
          shippedCtns: vanPosition.cartonsQty,
          unitPrice: vanPosition.packLine.orderLine.unitPrice ?? 0,
          unitPriceCurrencyCode: vanPosition.packLine.orderLine.unitPriceCurrencyCode,
          weightKG: vanPosition.weightKg ?? 0,
          volumeM3: vanPosition.volumeM3 ?? 0,
          commInvTotal: vanPosition.commercialInvoiceTotal ?? 0,
          freightCost: 0, // tbd
        };
      });

      const containerObj: ContainerPageData = {
        hot: hotContainer,
        lineItems,

        id: container.id,
        containerNumber: container.containerNumber || '',
        containerType: container.containerType,
        sealNumber: container.sealNumber || '',
        pickupLocationName: container.shipment.logistics.pickupLocation?.name || '',
        supplierName:
          supplierNames.size === 0
            ? ''
            : supplierNames.size === 1
            ? Array.from(supplierNames)[0]
            : 'Various',
        consigneeName:
          consigneeNames.size === 0
            ? ''
            : consigneeNames.size === 1
            ? Array.from(consigneeNames)[0]
            : 'Various',
        motherVesselName: container.shipment.logistics.motherVessel?.name || '',
        motherVoyageName: container.shipment.logistics.motherVoyage?.name || '',
        carrierName: container.shipment.logistics.carrier?.name || '',
        moveType: container.shipment.logistics.moveType || null,
        incoTerm:
          container.shipment.__typename === 'Booking' ? container.shipment.incoTerm || null : null,

        mbl: container.shipment.mbl
          ? {
              id: container.shipment.mbl.id,
              referenceNumber: container.shipment.mbl.referenceNumber,
            }
          : null,

        shipFromFactoryDate: container.shipFromFactoryDate,
        terminalReceivedDate: container.terminalReceivedDate,
        portOutGateDate: container.portOutGateDate,
        emptyReturnedDate: container.emptyReturnedDate,

        pol: container.shipment.logistics.pol,
        polEtd: container.shipment.logistics.polEtd,
        polAtd: container.shipment.logistics.polAtd,

        pod: container.shipment.logistics.pod,
        podEta: container.shipment.logistics.podEta,
        podAta: container.shipment.logistics.podAta,

        ramp: container.ramp,
        rampAta: container.rampAta,
        rampEta: container.rampEta,

        finalDestinationName: container.shipment.logistics.finalDestination?.name ?? '',
        deliveryAta: container.deliveryAta,
        deliveryEta: container.deliveryEta,

        demurrageLastFreeDayDate: container.demurrageLastFreeDayDate,
        detentionLastFreeDayDate: container.detentionLastFreeDayDate,
        rampOutGateDate: container.rampOutGateDate,
        emptyNotifyDate: container.emptyNotifyDate,
      };
      setContainer(containerObj);
    } else {
      setContainer(null);
    }
  }, [containerData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <RemainingHeightLayout
      top={
        <>
          <AtomGroupCleaner atomGroup={EquipmentPageStates} />
          <ErrorMessage error={error ? error + '' : null} />
          <Box marginBottom={3} marginLeft={1} marginTop={1} display="flex" alignItems="center">
            <Typography variant="h2" color="primary">
              Equipment: {container?.containerNumber}
            </Typography>
            {container ? (
              <HotContainerSwitch
                containerId={container.id}
                initialState={container.hot}
                showDetailsOnRight={true}
              />
            ) : null}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <EquipmentDetailsPanel />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TransitDetailsPanel />
            </Grid>
          </Grid>
        </>
      }
      bottomMinHeight={300}
      bottom={<EquipmentLineItems />}
    />
  );
}
