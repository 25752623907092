import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import GlobalUpdateStep2TransportationSection from '../GlobalUpdateStep2TransportationSection';
import { GUStates } from 'app/global-update/states';
import { useRecoilValue } from 'recoil';

export default function GlobalUpdateStep2TransportationDischarge() {
  const initPodEta = useRecoilValue(GUStates.initialState.initPodEta);
  const podEta = useRecoilValue(GUStates.form.podEta);
  const podEtaLabels = useRecoilValue(GUStates.form.podEtaLabels);

  const initPodAta = useRecoilValue(GUStates.initialState.initPodAta);
  const podAta = useRecoilValue(GUStates.form.podAta);
  const podAtaLabels = useRecoilValue(GUStates.form.podAtaLabels);

  const initRampEta = useRecoilValue(GUStates.initialState.initRampEta);
  const rampEta = useRecoilValue(GUStates.form.rampEta);
  const rampEtaLabels = useRecoilValue(GUStates.form.rampEtaLabels);

  const initRampAta = useRecoilValue(GUStates.initialState.initRampAta);
  const rampAta = useRecoilValue(GUStates.form.rampAta);
  const rampAtaLabels = useRecoilValue(GUStates.form.rampAtaLabels);

  const initPortOutGateDate = useRecoilValue(GUStates.initialState.initPortOutGateDate);
  const portOutGateDate = useRecoilValue(GUStates.form.portOutGateDate);
  const portOutGateDateLabels = useRecoilValue(GUStates.form.portOutGateDateLabels);

  const initRampOutGateDate = useRecoilValue(GUStates.initialState.initRampOutGateDate);
  const rampOutGateDate = useRecoilValue(GUStates.form.rampOutGateDate);
  const rampOutGateDateLabels = useRecoilValue(GUStates.form.rampOutGateDateLabels);

  const initDeliveryEta = useRecoilValue(GUStates.initialState.initDeliveryEta);
  const deliveryEta = useRecoilValue(GUStates.form.deliveryEta);
  const deliveryEtaLabels = useRecoilValue(GUStates.form.deliveryEtaLabels);

  const initDeliveryAta = useRecoilValue(GUStates.initialState.initDeliveryAta);
  const deliveryAta = useRecoilValue(GUStates.form.deliveryAta);
  const deliveryAtaLabels = useRecoilValue(GUStates.form.deliveryAtaLabels);

  return (
    <GlobalUpdateStep2TransportationSection title="Discharge">
      <Grid container>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.podEta} label="POD ETA" />
          <TextFieldWarnDecorator
            list={podEtaLabels}
            hasChanged={podEta !== initPodEta}
            defaultValue={initPodEta}
            formState={GUStates.form.podEta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.podAta} label="POD ATA" />
          <TextFieldWarnDecorator
            list={podAtaLabels}
            hasChanged={podAta !== initPodAta}
            defaultValue={initPodAta}
            formState={GUStates.form.podAta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.rampEta} label="Rail Ramp ETA" />
          <TextFieldWarnDecorator
            list={rampEtaLabels}
            hasChanged={rampEta !== initRampEta}
            defaultValue={initRampEta}
            formState={GUStates.form.rampEta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.rampAta} label="Rail Ramp ATA" />
          <TextFieldWarnDecorator
            list={rampAtaLabels}
            hasChanged={rampAta !== initRampAta}
            defaultValue={initRampAta}
            formState={GUStates.form.rampAta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.portOutGateDate} label="Port Outgate" />
          <TextFieldWarnDecorator
            list={portOutGateDateLabels}
            hasChanged={portOutGateDate !== initPortOutGateDate}
            defaultValue={initPortOutGateDate}
            formState={GUStates.form.portOutGateDate}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.rampOutGateDate} label="Rail Outgate" />
          <TextFieldWarnDecorator
            list={rampOutGateDateLabels}
            hasChanged={rampOutGateDate !== initRampOutGateDate}
            defaultValue={initRampOutGateDate}
            formState={GUStates.form.rampOutGateDate}
          />
        </Grid>

        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.deliveryEta} label="Delivery ETA" />
          <TextFieldWarnDecorator
            list={deliveryEtaLabels}
            hasChanged={deliveryEta !== initDeliveryEta}
            defaultValue={initDeliveryEta}
            formState={GUStates.form.deliveryEta}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.deliveryAta} label="Delivery ATA" />
          <TextFieldWarnDecorator
            list={deliveryAtaLabels}
            hasChanged={deliveryAta !== initDeliveryAta}
            defaultValue={initDeliveryAta}
            formState={GUStates.form.deliveryAta}
          />
        </Grid>
      </Grid>
    </GlobalUpdateStep2TransportationSection>
  );
}
