import {
  GqlSetPoRelatedPartiesMutation,
  GqlSetPoRelatedPartiesMutationVariables,
} from 'api/GQL_Types';
import { SetPoRelatedPartiesMutation } from 'api/queries/poQueries';
import { wrapMutation } from 'api/wrapMutation';
import { OverrideRelatedPartiesDialog } from 'components/OverrideRelatedPartiesDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { PoPageData } from './states';

interface Props {
  data: PoPageData;
  onClose(): void;
  reloadPoPageData(): void;
}

export const PoPartyOverride: React.FC<Props> = ({ data, onClose, reloadPoPageData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const actionSetPoRelatedPartiesMutation = useAsyncAction(setPoRelatedParties, {
    onData(data) {
      reloadPoPageData();
      onClose();
      enqueueSnackbar('Related parties updated.', { variant: 'success' });
    },
  });

  return (
    <OverrideRelatedPartiesDialog
      onClose={onClose}
      isSaving={actionSetPoRelatedPartiesMutation.waiting}
      saveError={actionSetPoRelatedPartiesMutation.error}
      value={data.relatedParties}
      onValue={(value) => {
        actionSetPoRelatedPartiesMutation.act({
          input: {
            poId: data.id,
            parties: value.map((v) => {
              return {
                profileId: v.profileId,
                partyType: v.partyType,
              };
            }),
          },
        });
      }}
    />
  );
};

const setPoRelatedParties = wrapMutation<
  GqlSetPoRelatedPartiesMutation,
  GqlSetPoRelatedPartiesMutationVariables
>({
  mutation: SetPoRelatedPartiesMutation,
  errorMessage(data, error) {
    if (!data?.setPoRelatedParties.success || error) {
      return data?.setPoRelatedParties.message || 'Failed to override parties';
    }
  },
});
