import { Link } from '@reach/router';
import { GqlPackLine } from 'api/GQL_Types';
import { PopoverTable } from 'app/dashboard/components/PopoverTable';
import { DashboardInvoiceItem, transformPackLineToInvoiceItemDisplay } from 'app/dashboard/states';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import {
  STANDARD_ROW_INNER_HEIGHT,
  STANDARD_ROW_PADDING_HEIGHT,
  UWLTable,
} from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import { formatCurrency } from 'types/Monetary';
import { addPackLineTotals } from '../dashboard-bookings-details';

const columns: UWLTableColumn<DashboardInvoiceItem>[] = [
  { id: 'cost', label: 'Value', type: 'currency' },
  { id: 'sku', label: 'SKU', type: 'string' },
  { id: 'shippedQty', label: 'Shipped Qty', type: 'number' },
  { id: 'pos', label: 'PO #', type: 'link' },
  { id: 'booking', label: 'Booking #', type: 'link' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date' },
  { id: 'equipment', label: 'Equipment', type: 'link' },
  { id: 'shipper', label: 'Shipper', type: 'string' },
  { id: 'origin', label: 'Origin', type: 'string' },
  { id: 'destination', label: 'Destination', type: 'string' },
];

const exportColumns = columns;

// Used for both virtualization and the popover size
function rowInnerHeight(row: DashboardInvoiceItem): number {
  return Math.max(STANDARD_ROW_INNER_HEIGHT, row.equipment.length * STANDARD_ROW_INNER_HEIGHT);
}

interface Props {
  title: string;
  packLines: GqlPackLine[];

  anchorEl: any;
  open: boolean;
  onClose(): void;
}

export const InvoicePopover: React.FC<Props> = ({ title, packLines, anchorEl, open, onClose }) => {
  const windowSize = useWindowSize();

  const rows = transformPackLineToInvoiceItemDisplay(packLines);

  return (
    <PopoverTable
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      title={title}
      titleRight={<TableExportWindowGroup label={title} rows={rows} columns={exportColumns} />}
      totalLabel="Total Invoice Value"
      totalValue={formatCurrency(addPackLineTotals(packLines))}
      width={windowSize.width * 0.8}
      bodyHeight={rows.reduce(
        (sum, row) => sum + rowInnerHeight(row) + STANDARD_ROW_PADDING_HEIGHT,
        0
      )}
    >
      {open && (
        <UWLTable
          rowId={'id'}
          rows={rows}
          columns={columns}
          emptyMessage="No Invoice Data"
          noWrapAllCells
          virtualize={{
            rowHeight: rowInnerHeight,
            bufferHeight: STANDARD_ROW_INNER_HEIGHT * 5,
          }}
          renderCell={{
            equipment(row) {
              return (
                <div>
                  {row.equipment.map((eqp) => {
                    return (
                      <div key={eqp.to}>
                        <Link to={eqp.to}>{eqp.value}</Link>
                      </div>
                    );
                  })}
                </div>
              );
            },
          }}
        />
      )}
    </PopoverTable>
  );
};
