import { Button } from '@material-ui/core';
import React from 'react';

interface Props {
  onClick: () => void;
}

export const MarkAllAsReadButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button variant="text" color="primary" onClick={onClick}>
      Mark all as read
    </Button>
  );
};
