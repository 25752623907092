import { withStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import React from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { theme } from 'styles';

const CustomButton = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}))(ToggleButton);

interface Props {
  state: RecoilState<'Bookings' | 'Consolidations'>;
}
export const BookingConsolidationToggler = (props: Props) => {
  const userContext = useRecoilValue(userContextAtom);
  const [value, setValue] = useRecoilState(props.state);

  return (
    <>
      {userContext?.permissionCodes.has(PermissionCode.ConsolidationRead) && (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={(e, value) => {
            if (value) {
              setValue(value);
            }
          }}
          aria-label="text alignment"
          style={{ margin: theme.spacing(0, 2, 3, 2) }}
        >
          <CustomButton value="Bookings" selected={value === 'Bookings'}>
            Bookings
          </CustomButton>
          <CustomButton value="Consolidations"> Consolidations</CustomButton>
        </ToggleButtonGroup>
      )}
    </>
  );
};
