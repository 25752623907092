import { gql } from '@apollo/client/core';

export const CarriersQuery = gql`
  query Carriers {
    carriers {
      id
      scac
      name
    }
  }
`;

export const VesselsQuery = gql`
  query Vessels {
    vessels {
      id
      name
      voyages {
        id
        name
      }
    }
  }
`;
