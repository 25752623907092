import { FormInputText } from 'components/form/FormInputText';
import * as React from 'react';
import { DatasetFilterType } from '..';

export const StringFilter: DatasetFilterType<string> = {
  deserializeValue(filterable, str) {
    if (typeof str === 'string') {
      return str;
    }
    return '';
  },
  serializeValue(filterable, value) {
    return value;
  },

  defaultValue(filterable) {
    return '';
  },

  validate(filterable, value) {
    return null;
  },

  RenderInput: (props) => {
    return (
      <div style={{ maxWidth: 250 }}>
        <FormInputText
          value={props.value ? props.value + '' : ''}
          onValue={(str) => props.onValue(str)}
          label=""
          disabled={props.disabled}
        />
      </div>
    );
  },
};
