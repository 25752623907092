import { NotificationCode, DashboardFeatureCode, PermissionCode } from 'api/GQL_Types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';

interface DialogState {
  networkId: string;
  name: string;
  description: string;
  permissionCodes: PermissionCode[];
  notificationCodes: NotificationCode[];
  dashFeatureCodes: DashboardFeatureCode[];
}

export const NewProfileTypeDialogStates = {
  dialog: newAtom<DialogState | null>(null),
  form: {
    name: newAtom<string>(''),
    description: newAtom<string>(''),
    permissionCodes: newAtom<PermissionCode[]>([]),
    notificationCodes: newAtom<NotificationCode[]>([]),
    dashFeatureCodes: newAtom<DashboardFeatureCode[]>([]),
  },
};

export function useSetupNewProfileTypeDialog() {
  const setDialog = useSetRecoilState(NewProfileTypeDialogStates.dialog);
  const setName = useSetRecoilState(NewProfileTypeDialogStates.form.name);
  const setDescription = useSetRecoilState(NewProfileTypeDialogStates.form.description);
  const setPermissionCodes = useSetRecoilState(NewProfileTypeDialogStates.form.permissionCodes);
  const setNotificationCodes = useSetRecoilState(NewProfileTypeDialogStates.form.notificationCodes);
  const setDashFeatureCodes = useSetRecoilState(NewProfileTypeDialogStates.form.dashFeatureCodes);

  return (init: DialogState | null) => {
    setDialog(init);
    setName(init?.name || '');
    setDescription(init?.description || '');
    setPermissionCodes(init?.permissionCodes || []);
    setNotificationCodes(init?.notificationCodes || []);
    setDashFeatureCodes(init?.dashFeatureCodes || []);
  };
}

export function useNewProfileTypeFormValues() {
  return {
    name: useRecoilValue(NewProfileTypeDialogStates.form.name),
    description: useRecoilValue(NewProfileTypeDialogStates.form.description),
    permissionCodes: useRecoilValue(NewProfileTypeDialogStates.form.permissionCodes),
    notificationCodes: useRecoilValue(NewProfileTypeDialogStates.form.notificationCodes),
    dashFeatureCodes: useRecoilValue(NewProfileTypeDialogStates.form.dashFeatureCodes),
  };
}
