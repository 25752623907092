import { Box, Typography } from '@material-ui/core';
import { HotPoLineSwitch } from 'app/components/HotPoLineSwitch';
import Panel from 'components/Panel';
import { Prepend } from 'components/Prepend';
import {
  STANDARD_HEADER_OVERALL_HEIGHT,
  STANDARD_ROW_INNER_HEIGHT,
  STANDARD_ROW_OVERALL_HEIGHT,
  UWLTable,
} from 'components/UWLTable/UWLTable';
import React from 'react';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { PoPageData, PoPageDataLineRow } from './states';

const lineHeaders: UWLTableColumn<PoPageDataLineRow>[] = [
  { id: 'itemNumber', label: 'Item/SKU', type: 'string', whiteSpace: 'nowrap' },
  { id: 'lineNumber', label: 'Line', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'totalQty', label: 'Order Qty', type: 'number' },
  { id: 'outerQty', label: 'Cartons', type: 'number' },
  { id: 'approvedQty', label: 'Appr Qty', type: 'number' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number' },
  { id: 'shippedQty', label: 'Shipped Qty', type: 'number' },
  { id: 'weight', label: 'Weight', type: 'weight', unitField: 'weightUnit' },
  { id: 'volume', label: 'Volume', type: 'volume', unitField: 'volumeUnit' },
  { id: 'expectedCargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'Rev Cgo Rdy Date', type: 'date' },
  { id: 'shipToLocationName', label: 'Ship To Loc', type: 'string' },
  { id: 'shipToLocationCode', label: 'Ship To Loc Code', type: 'string' },
  { id: 'indcDate', label: 'INDC Date', type: 'date' },
];

const expandedLineHeader: UWLTableColumn<PoPageDataLineRow>[] = [
  { id: 'htsCode', label: 'HTS Code', type: 'string' },
  { id: 'bookByDate', label: 'Booked by Date', type: 'date' },
  { id: 'lineStatus', label: 'Line Status', type: 'string' },
  { id: 'style', label: 'Style', type: 'string' },
  { id: 'size', label: 'Size', type: 'string' },
  { id: 'color', label: 'Color', type: 'string' },
  { id: 'length', label: 'Length', type: 'distance', unitField: 'lengthUnit' },
  { id: 'width', label: 'Width', type: 'distance', unitField: 'widthUnit' },
  { id: 'height', label: 'Height', type: 'distance', unitField: 'heightUnit' },
  { id: 'material', label: 'Material', type: 'string' },
  { id: 'department', label: 'Department', type: 'string' },
  { id: 'categoryCode', label: 'Category Code', type: 'string' },
  {
    id: 'unitPrice',
    label: 'Unit Cost',
    type: 'currency',
    currencyField: 'unitCurrencyCode',
  },
  {
    id: 'dutyPrice',
    label: 'Duty',
    type: 'currency',
    currencyField: 'dutyCurrencyCode',
  },
  { id: 'firstShipDate', label: 'First Ship Date', type: 'date' },
  { id: 'lastShipDate', label: 'Last Ship Date', type: 'date' },
];

interface Props {
  data: PoPageData;
}

export const PoOrderLines: React.FC<Props> = (props) => {
  return (
    <Panel title="Order Lines">
      <Box paddingX={2} paddingY={1} height={'100%'} width={'100%'}>
        <UWLTable
          rowId="lineId"
          columns={lineHeaders}
          rows={props.data.orderLineRows}
          emptyMessage="No Orders Lines"
          renderCell={{
            itemNumber(row) {
              return (
                <Prepend
                  item={<HotPoLineSwitch orderLineId={row.lineId} initialState={row.hot} small />}
                >
                  <Typography variant="body1">
                    <Box component="span" fontWeight="caption.fontWeight" fontStyle="italic">
                      {row.itemNumber}
                    </Box>
                  </Typography>
                </Prepend>
              );
            },
          }}
          rowExpand={(row) => {
            return (
              <Box paddingX={2} paddingY={1} width="100%" bgcolor={theme.palette.background.paper}>
                <UWLTable
                  rowId="lineId"
                  columns={expandedLineHeader}
                  rows={[row]}
                  emptyMessage="No Orders Lines"
                />
              </Box>
            );
          }}
          noWrapAllCells
          virtualize={{
            rowHeight(row) {
              return STANDARD_ROW_INNER_HEIGHT;
            },
            rowExpandedHeight(row) {
              return 8 + STANDARD_HEADER_OVERALL_HEIGHT + STANDARD_ROW_OVERALL_HEIGHT + 8;
            },
            bufferHeight: 300,
          }}
        />
      </Box>
    </Panel>
  );
};
