import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';
import GlobalUpdateStep2TransportationSection from '../GlobalUpdateStep2TransportationSection';
import { GUStates } from 'app/global-update/states';

export default function GlobalUpdateStep2TransportationFreeTime() {
  const initConsolidatorLfd = useRecoilValue(GUStates.initialState.initConsolidatorLfd);
  const consolidatorLfd = useRecoilValue(GUStates.form.consolidatorLfd);
  const consolidatorLfdLabels = useRecoilValue(GUStates.form.consolidatorLfdLabels);

  const initDemurrageLfd = useRecoilValue(GUStates.initialState.initDemurrageLfd);
  const demurrageLfd = useRecoilValue(GUStates.form.demurrageLfd);
  const demurrageLfdLabels = useRecoilValue(GUStates.form.demurrageLfdLabels);

  const initDetentionLfd = useRecoilValue(GUStates.initialState.initDetentionLfd);
  const detentionLfd = useRecoilValue(GUStates.form.detentionLfd);
  const detentionLfdLabels = useRecoilValue(GUStates.form.detentionLfdLabels);

  return (
    <GlobalUpdateStep2TransportationSection title="Free Time">
      <Grid container>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.consolidatorLfd} label="Consolidator LFD" />
          <TextFieldWarnDecorator
            list={consolidatorLfdLabels}
            hasChanged={consolidatorLfd !== initConsolidatorLfd}
            defaultValue={initConsolidatorLfd}
            formState={GUStates.form.consolidatorLfd}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.demurrageLfd} label="Demurrage LFD" />
          <TextFieldWarnDecorator
            list={demurrageLfdLabels}
            hasChanged={demurrageLfd !== initDemurrageLfd}
            defaultValue={initDemurrageLfd}
            formState={GUStates.form.demurrageLfd}
          />
        </Grid>
        <Grid item xs={3}>
          <AtomicDatePickerV2 state={GUStates.form.detentionLfd} label="Detention LFD" />
          <TextFieldWarnDecorator
            list={detentionLfdLabels}
            hasChanged={detentionLfd !== initDetentionLfd}
            defaultValue={initDetentionLfd}
            formState={GUStates.form.detentionLfd}
          />
        </Grid>
      </Grid>
    </GlobalUpdateStep2TransportationSection>
  );
}
