import { Box, Button, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PanelBase, PanelHeader } from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import SearchBar from 'components/SearchBar';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { newAtom } from 'lib/RecoilUtils';
import { useRecoilState } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { OpenLineItem } from 'types/VanPosition';

const lineSearchState = newAtom('');

interface Props {
  availableLines: OpenLineItem[];
  selectedLinesChecked: string[];
  availableLinesChecked: string[];
  setAvailableLinesChecked: React.Dispatch<React.SetStateAction<string[]>>;
  columns: UWLTableColumn<OpenLineItem>[];
  onClickAdd(): void;
  onClickRemove(): void;
}

export const BottomHalf: React.FC<Props> = ({
  availableLines,
  selectedLinesChecked,
  availableLinesChecked,
  setAvailableLinesChecked,
  columns,
  onClickAdd,
  onClickRemove,
}) => {
  const [searchField, setSearchField] = useRecoilState(lineSearchState);
  const lowerField = searchField.toLowerCase();

  if (searchField !== '') {
    availableLines = availableLines.filter(
      (line) =>
        line.poNumber.toLowerCase().includes(lowerField) ||
        line.itemNumber.toLowerCase().includes(lowerField) ||
        (columns.some((c) => c.id === 'itemDescription')
          ? line.itemDescription.toLowerCase().includes(lowerField)
          : false) ||
        (columns.some((c) => c.id === 'supplierName')
          ? line.supplierName.toLowerCase().includes(lowerField)
          : false) ||
        (columns.some((c) => c.id === 'bookingNumber')
          ? line.bookingNumber.toLowerCase().includes(lowerField)
          : false)
    );
  }

  return (
    <PanelBase>
      <PanelHeader
        title="OPEN ITEMS"
        topCenter={
          <Box display="flex" marginLeft="auto" marginRight="auto">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={selectedLinesChecked.length === 0}
              onClick={onClickRemove}
              style={{
                display: 'flex',
                marginRight: theme.spacing(3),
                width: '224px',
                justifyContent: 'space-evenly',
              }}
            >
              <ExpandMoreIcon style={{ color: '#FFFFFF', marginRight: '8px' }} />
              <Typography variant="h3" color="textSecondary">
                REMOVE
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              disabled={availableLinesChecked.length === 0}
              onClick={onClickAdd}
              style={{
                display: 'flex',
                width: '224px',
                justifyContent: 'space-evenly',
              }}
            >
              <ExpandLessIcon style={{ color: '#FFFFFF', marginRight: '8px' }} />
              <Typography variant="h3" color="textSecondary">
                ADD TO ITEMS
              </Typography>
            </Button>
          </Box>
        }
        topRight={
          <Box width="25%" bgcolor="#F8F8F8" padding={1}>
            <SearchBar placeholder="Search" field={searchField} updateField={setSearchField} />
          </Box>
        }
      />
      <PanelBodyTable>
        <UWLTable
          rowId="packLineId"
          columns={columns}
          rows={availableLines}
          emptyMessage="No Items Available"
          virtualize="single-line-cells"
          checkboxes={{
            checked: availableLinesChecked,
            setChecked: setAvailableLinesChecked,
          }}
        />
      </PanelBodyTable>
    </PanelBase>
  );
};
