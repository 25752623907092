import { RuleFieldType } from 'api/GQL_Types';
import React from 'react';
import { InputItem, InputRow } from './InputLayout';
import { RuleCard } from './RuleCard';
import { RuleFieldInput } from './RuleFieldInput';
import { RuleNumDaysInput } from './RuleNumDaysInput';

interface Props {
  name: string;
  description: string;
  enabled: boolean;
  onEnabled(enabled: boolean): void;
  days: number;
  onDays(days: number): void;
  fieldOptions: RuleFieldType[];
  field: RuleFieldType;
  onField(days: RuleFieldType): void;
  notYetSupported?: boolean;
}

export const RuleCardDayField: React.FC<Props> = ({
  name,
  description,
  enabled,
  onEnabled,
  days,
  onDays,
  fieldOptions,
  field,
  onField,
  notYetSupported,
}) => {
  return (
    <RuleCard
      name={name}
      description={description}
      enabled={enabled}
      onEnabled={onEnabled}
      notYetSupported={notYetSupported}
    >
      <InputRow>
        <InputItem small>
          <RuleNumDaysInput value={days} onValue={onDays} />
        </InputItem>
        <InputItem>
          <RuleFieldInput options={fieldOptions} value={field} onValue={onField} />
        </InputItem>
      </InputRow>
    </RuleCard>
  );
};
