import { SwitchCard } from 'components/SwitchCard';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    marginBottom: theme.spacing(2),
  },
});

interface Props {
  name: string;
  description: string;
  enabled: boolean;
  onEnabled(enabled: boolean): void;
  notYetSupported?: boolean;
}

export const RuleCard: React.FC<Props> = ({
  name,
  description,
  enabled,
  onEnabled,
  notYetSupported,
  children,
}) => {
  return (
    <div className={classes.root}>
      <SwitchCard
        title={name}
        description={description}
        checked={enabled}
        onChange={onEnabled}
        warningText={notYetSupported ? 'Not Yet Supported' : undefined}
      >
        {children}
      </SwitchCard>
    </div>
  );
};
