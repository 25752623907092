import ReportColumnsPicker from 'components/ReportColumnsPicker';
import * as React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';

interface Props {}

export default function Step2(props: Props) {
  const { values, validation } = reportFormStore.use();

  const dataset = reportCategoriesStore.useDataset(values.datasetId || '');

  const displayables = dataset?.displayables || [];

  // filter out columns that were saved, but no longer in the displayable list from the backend
  let validColumns: string[] = [];
  for (const col of values.fields) {
    if (!dataset || dataset.displayableByField[col]) {
      validColumns.push(col);
    }
  }

  const outdatedColsWereRemoved =
    values.fields.length > 0 &&
    displayables.length > 0 &&
    values.fields.length !== validColumns.length;

  React.useEffect(() => {
    if (outdatedColsWereRemoved) {
      reportFormStore.put({ fields: validColumns });
    }
  }, [outdatedColsWereRemoved]);

  return (
    <ReportColumnsPicker
      displayables={displayables}
      columns={validColumns}
      setColumns={(fields) => {
        reportFormStore.put({ fields });
      }}
      fieldAliases={values.fieldAliases}
      setFieldAliases={(fieldAliases) => {
        reportFormStore.put({ fieldAliases });
      }}
      error={validation.fields}
    />
  );
}
