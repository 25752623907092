import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormInputText } from './FormInputText';

export interface Props {
  value: string;
  onValue(value: string): void;

  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const FormInputPhoneNumber: React.FC<Props> = (props) => {
  return (
    <FormInputText
      value={props.value}
      onValue={props.onValue}
      onBlur={(e) => {
        props.onValue(formatPhoneNumber(e.currentTarget.value));
      }}
      label={props.label}
      disabled={props.disabled}
      required={props.required}
      endAdornment={
        !isValidPhoneNumber(props.value) && (
          <Typography
            variant="caption"
            style={{ lineHeight: '1.5', color: '#dd090a', whiteSpace: 'nowrap' }}
          >
            Invalid Phone Number
          </Typography>
        )
      }
    />
  );
};

const validPhoneNumberRegex = /^[0-9-+().,#]+$/;

function formatPhoneNumber(str: string): string {
  const digits = str.replace(/[^\d]/g, '');

  if (digits.length === 10 && str.indexOf('+') < 0) {
    // USA formatting
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  }

  return str;
}

function isValidPhoneNumber(str: string): boolean {
  str = str.replace(/\s+/g, '');
  if (str.length === 0) {
    return true;
  }
  if (!validPhoneNumberRegex.test(str)) {
    return false;
  }
  const digits = str.replace(/[^\d]/g, '');
  if (
    digits.length < 4 // Within some countries, 4 digits is enough.
  ) {
    return false;
  }
  return true;
}
