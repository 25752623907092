import CompositePanel from 'components/CompositePanel';
import React from 'react';
import BookingTabs from './booking-tabs';
import DashboardBookingsHeading from './heading';

interface Props {}

export default function DashboardBookings(props: Props) {
  return (
    <CompositePanel>
      <DashboardBookingsHeading />
      <BookingTabs />
    </CompositePanel>
  );
}
