import { Box, Grid, Typography } from '@material-ui/core';
import { GqlLocation, ShipmentStatus, useVesselsQuery } from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import SectionTitle from 'components/SectionTitle';
import {
  fromGQL_VesselOption,
  VesselOption,
  VesselVoyageSelector,
} from 'components/VesselVoyageSelector';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BookingPageStates } from '../../states';
import { confirmBookingValidationState } from './states';

export default function ShippingLogistics() {
  const booking = useRecoilValue(BookingPageStates.booking);
  const validation = useRecoilValue(confirmBookingValidationState);
  const [pod, setPod] = useRecoilState(BookingPageStates.editLogistics.pod);
  const [pol, setPol] = useRecoilState(BookingPageStates.editLogistics.pol);
  const [transitPort, setTransitPort] = useRecoilState(BookingPageStates.editLogistics.transitPort);
  const [ramp, setRamp] = useRecoilState(BookingPageStates.editLogistics.ramp);
  const [vessels, setVessels] = React.useState<VesselOption[]>([]);

  useVesselsQuery({
    onCompleted(data) {
      setVessels(data.vessels.map(fromGQL_VesselOption));
    },
  });

  const locationMap =
    booking?.relatedParties.reduce((locationMap: { [key: string]: GqlLocation }, rp) => {
      for (const loc of rp.party.locations as GqlLocation[]) {
        locationMap[loc.id] = loc;
      }
      return locationMap;
    }, {}) ?? {};

  return (
    <>
      <SectionTitle title="Shipping Logistics" />
      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Load Port
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start" direction="row">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny
              label="POL"
              value={pol}
              onValue={setPol}
              required={validation.polError}
            />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.polEtd}
            label="POL ETD"
            required={validation.polEtdError}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.polAtd}
            label="POL ATD"
            disabled={
              booking?.status === ShipmentStatus.Booked ||
              booking?.status === ShipmentStatus.Confirmed
            }
          />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Transshipment
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny
              label="Transshipment Port"
              value={transitPort}
              onValue={setTransitPort}
            />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.transitPortEta}
            label="Transship ETA"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.transitPortAta}
            label="Transship ATA"
            disabled={booking?.status !== ShipmentStatus.Shipped}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.transitPortEtd}
            label="Transship ETD"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.transitPortAtd}
            label="Transship ATD"
            disabled={booking?.status !== ShipmentStatus.Shipped}
          />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Discharge Port
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny
              label="POD"
              value={pod}
              onValue={setPod}
              required={validation.podError}
            />
          </FormItem>
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.podEta}
            label="POD ETA"
            required={validation.podEtaError}
          />
        </Grid>
        <Grid item xs={4}>
          <FormItem>
            <FormInputPortAny label="Ramp" value={ramp} onValue={setRamp} />
          </FormItem>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2 state={BookingPageStates.editLogistics.rampEta} label="Ramp ETA" />
        </Grid>
        <Grid item xs={4}>
          <AtomicAutocompleteV2<GqlLocation>
            state={BookingPageStates.editLogistics.finalDestination}
            label={'Final Destination'}
            optionsList={Object.values(locationMap)}
            displayResolver={(value: GqlLocation) => {
              return value.name ?? 'N/A';
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.deliveryEta}
            label="Delivery ETA"
          />
        </Grid>
      </Grid>
      <hr />
      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Vessel
        </Typography>
      </Box>

      <VesselVoyageSelector
        vessels={vessels}
        setVessels={setVessels}
        adjective={'Mother'}
        vesselAtom={BookingPageStates.editLogistics.motherVessel}
        voyageAtom={BookingPageStates.editLogistics.motherVoyage}
      />
      <VesselVoyageSelector
        vessels={vessels}
        setVessels={setVessels}
        adjective={'Feeder'}
        vesselAtom={BookingPageStates.editLogistics.feederVessel}
        voyageAtom={BookingPageStates.editLogistics.feederVoyage}
      />
    </>
  );
}
