import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  GqlRelatedProfileRuleInput,
  GqlUpdateRelatedProfileRulesMutation,
  GqlUpdateRelatedProfileRulesMutationVariables,
  PartyType,
  RelatedProfileCondition,
} from 'api/GQL_Types';
import { updateRelatedProfileRules } from 'api/queries/networkQueries';
import { DeleteButton } from 'components/DeleteButton';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import ErrorMessage from 'components/ErrorMessage';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { genKey } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { mapPartyType } from 'types/OMSEnums';
import { NetworkAdminPageStates } from '../states';
import { RelationRule } from './RelationRule';
import {
  fromGQL_RelatedProfileRuleGroups,
  RelatedProfileRuleGroup,
  validateRelatedProfileRuleGroup,
} from './states';

interface Props {
  group: RelatedProfileRuleGroup;
  onChange(group: RelatedProfileRuleGroup): void;
  onRemove(): void;
}

export const RelationGroup: React.FC<Props> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const profiles = useRecoilValue(NetworkAdminPageStates.network)?.connectedProfiles || [];
  const profileOptions = profiles.map((p) => ({ id: p.id, label: p.name }));
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);

  const profileName =
    profileOptions.find((o) => o.id === props.group.profileId)?.label ||
    props.group.profileId ||
    '- none -';

  const updateAction = useAsyncAction<
    GqlUpdateRelatedProfileRulesMutationVariables,
    GqlUpdateRelatedProfileRulesMutation
  >((input) => updateRelatedProfileRules(input), {
    onData(data) {
      enqueueSnackbar('Rules Saved!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to save rules: ' + error, { variant: 'error' });
    },
  });

  const deleteAction = useAsyncAction<
    { profileId: string; partyType: PartyType },
    GqlUpdateRelatedProfileRulesMutation
  >(
    ({ profileId, partyType }) =>
      updateRelatedProfileRules({
        input: {
          whenProfileId: profileId,
          whenPartyType: partyType,
          rules: [],
        },
      }),
    {
      onData(data) {
        enqueueSnackbar('Removed Rules!', { variant: 'success' });
        props.onRemove();
      },
      onError(error) {
        enqueueSnackbar('Failed to remove rules: ' + error, { variant: 'error' });
      },
    }
  );

  const validation = validateRelatedProfileRuleGroup(props.group);

  return (
    <Accordion expanded={props.group.isExpanded}>
      <AccordionSummary
        style={props.group.isExpanded ? { cursor: 'default' } : {}}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          props.onChange({ ...props.group, isExpanded: true });
        }}
      >
        <div>
          When <b>{profileName}</b> is{' '}
          <b>{props.group.partyType ? mapPartyType(props.group.partyType) : '- none -'}</b>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div style={{ paddingLeft: theme.spacing(2), paddingBottom: theme.spacing(1.5) }}>
            {props.group.rules.map((rule) => {
              return (
                <RelationRule
                  key={rule.id}
                  rule={rule}
                  disabled={updateAction.waiting}
                  onChange={(r) => {
                    props.onChange({
                      ...props.group,
                      rules: props.group.rules.map((rule) => {
                        if (rule.id === r.id) {
                          return r;
                        }
                        return rule;
                      }),
                    });
                  }}
                  onRemove={() =>
                    props.onChange({
                      ...props.group,
                      rules: props.group.rules.filter((r) => rule.id !== r.id),
                    })
                  }
                />
              );
            })}
            <Button
              color="primary"
              size="small"
              disabled={updateAction.waiting}
              onClick={() => {
                props.onChange({
                  ...props.group,
                  rules: props.group.rules.concat([
                    {
                      id: genKey(),
                      partyType: null,
                      profileId: null,
                      condition: RelatedProfileCondition.Always,
                      port: null,
                      country: '',
                      category: null,
                      department: null,
                      material: null,
                    },
                  ]),
                });
              }}
            >
              + Add Rule
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              title={validation.whyCantSave || undefined}
              disabled={updateAction.waiting || !!validation.whyCantSave}
              onClick={() => {
                if (validation.whyCantSave) return;
                if (!props.group.profileId || !props.group.partyType) return;

                const rules: GqlRelatedProfileRuleInput[] = [];
                for (const rule of props.group.rules) {
                  if (!rule.partyType || !rule.profileId || !rule.condition) continue;

                  rules.push({
                    setPartyType: rule.partyType,
                    setProfileId: rule.profileId,
                    whenCondition: rule.condition,
                    whenCountryCode: rule.country,
                    whenPortCode: rule.port?.code || null,
                    whenCategoryCode: rule.category,
                    whenDepartment: rule.department,
                    whenMaterial: rule.material,
                  });
                }

                updateAction.act({
                  input: {
                    whenProfileId: props.group.profileId,
                    whenPartyType: props.group.partyType,
                    rules,
                  },
                });
              }}
            >
              Save
            </Button>{' '}
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={updateAction.waiting}
              onClick={() => {
                const original = fromGQL_RelatedProfileRuleGroups(props.group.originalRules);
                if (original.length === 0) {
                  // remove b/c they are canceling a group that was never saved
                  props.onRemove();
                  return;
                }
                props.onChange({
                  id: props.group.id,
                  isExpanded: false,
                  profileId: original[0].profileId,
                  partyType: original[0].partyType,
                  rules: original[0].rules,
                  originalRules: original[0].originalRules,
                });
              }}
            >
              Cancel
            </Button>{' '}
            <DeleteButton
              size="small"
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
              disabled={updateAction.waiting || !!validation.whyCantDelete}
              title={validation.whyCantDelete || undefined}
            >
              Delete
            </DeleteButton>
          </div>
          <ErrorMessage error={updateAction.error} />
        </div>

        {showDeleteConfirm && (
          <DeleteConfirmationDialog
            open
            onClose={() => {
              setShowDeleteConfirm(false);
            }}
            title="Delete Rule"
            message="Are you sure you want to remove this rule?"
            onClickDelete={() => {
              if (!props.group.profileId || !props.group.partyType) return;
              deleteAction.act({
                profileId: props.group.profileId,
                partyType: props.group.partyType,
              });
            }}
            error={deleteAction.error}
            waiting={deleteAction.waiting}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
