import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import EquipmentDetails from './details';

interface EquipmentProps extends RouteComponentProps {}
function Equipment(props: EquipmentProps) {
  return (
    <FlexGrowRouter>
      <EquipmentDetails path="/:equipmentId" />
    </FlexGrowRouter>
  );
}

export default Equipment;
