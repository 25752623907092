import { Box, Grid } from '@material-ui/core';
import { ContainerMode } from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import { EquipmentSelectionPanel } from 'components/EquipmentSelectionPanel';
import Panel from 'components/Panel';
import { UtilizationChart } from 'components/UtilizationChart';
import { WarningIconWithMessages } from 'components/WarningIconWithMessages';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewBookingPageStates, step2Validation } from '../states';

interface Props {}

export const EquipmentSection: React.FC<Props> = () => {
  const rules = useRecoilValue(AppWideStates.network.rules);
  const validation = useRecoilValue(step2Validation);

  const [equipmentList, setEquipmentList] = useRecoilState(
    NewBookingPageStates.step2.equipmentList
  );

  let isOnlyLCL = false;
  for (const eq of equipmentList) {
    if (eq.containerType === 'LCL') {
      isOnlyLCL = true;
    } else {
      isOnlyLCL = false;
      break;
    }
  }

  const containerMode = useRecoilValue(NewBookingPageStates.step2.formContainerMode);

  return (
    <Grid container spacing={2} style={{ maxWidth: '100%' }}>
      <Grid item sm={12} md={6}>
        <div
          style={{
            flexGrow: 1,
            width: '100%',
            height: 350,
            display: 'flex', // for the panel to grow into
          }}
        >
          <EquipmentSelectionPanel
            value={equipmentList}
            onValue={setEquipmentList}
            showMinMax
            disableAdd={ContainerMode.Lcl === containerMode} // See OMS-155
            disableRemove={ContainerMode.Lcl === containerMode} // See OMS-155
          />
        </div>
      </Grid>
      <Grid item sm={12} md={6}>
        <div
          style={{
            flexGrow: 1,
            width: '100%',
            height: 350,
            display: 'flex', // for the panel to grow into
          }}
        >
          <Panel
            title="Container Utilization"
            titleStickToTop
            titleDecorator={
              validation.utilizationWarnings.length && !isOnlyLCL ? (
                <WarningIconWithMessages
                  messages={validation.utilizationWarnings}
                  severity={rules.bookingContainerCapacityToleranceHardStop ? 'high' : 'mild'}
                />
              ) : undefined
            }
          >
            <Box display="flex" height={'100%'} alignItems="center" justifyContent="space-around">
              <UtilizationChart
                current={validation.bookedWeight}
                min={validation.eqpTypesSize.weightKg.min}
                max={
                  validation.eqpTypesSize.weightKg.max
                    ? validation.eqpTypesSize.weightKg.max
                    : validation.eqpTypesSize.weightKg.capacity
                }
                percentLabel="Weight"
                currentLabel="Booked Weight"
                maxLabel="Container Weight Capacity"
                minLabel="Container Minimum Weight"
                unitType="kg"
                grayOutChart={
                  equipmentList.length === 0 || // OMS-151
                  isOnlyLCL // OMS-240
                }
              />
              <UtilizationChart
                current={validation.bookedVolume}
                min={validation.eqpTypesSize.volumeM3.min}
                max={
                  validation.eqpTypesSize.volumeM3.max
                    ? validation.eqpTypesSize.volumeM3.max
                    : validation.eqpTypesSize.volumeM3.capacity
                }
                percentLabel="Volume"
                currentLabel="Booked Volume"
                maxLabel="Container Volume Capacity"
                minLabel="Container Minimum Volume"
                unitType="cbm"
                grayOutChart={
                  equipmentList.length === 0 || // OMS-151
                  isOnlyLCL // OMS-240
                }
              />
            </Box>
          </Panel>
        </div>
      </Grid>
    </Grid>
  );
};
