import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import UndoIcon from '@material-ui/icons/Undo';
import clsx from 'clsx';
import * as React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { makeCss, theme } from '../styles';
import { DatasetDisplayable } from '../types/Dataset';
import FormInputCheckbox from './form/FormInputCheckbox';
import { FormInputText } from './form/FormInputText';

const classes = makeCss({
  header: {
    minWidth: '200px',
    padding: theme.spacing(1, 1, 2, 1),
    display: 'flex',
    // justifyContent: "space-between",
    alignItems: 'center',
  },
  headerToggle: {
    marginLeft: theme.spacing(1),
    padding: 0,
    lineHeight: 1.235,
  },
  'column-list': {
    columnGap: '1em',
    gridColumnGap: '1em',
    listStyle: 'none',
    padding: theme.spacing(0, 0, 0, 2.5),
    [theme.breakpoints.up('md')]: {
      columnCount: 2,
    },
    '& li': {
      breakInside: 'avoid',
    },
  },
  columnCheckbox: {
    '&:hover $columnCheckboxCustomize': {
      opacity: 1,
    },
    '&$columnCheckboxIsCustomized $columnCheckboxRow': {
      textDecoration: 'line-through',
    },
  },
  columnCheckboxCustomize: {
    opacity: 0,
  },
  columnCheckboxRow: {
    userSelect: 'none',
  },
  columnCheckboxIsCustomized: {},
  customizeNameInputWrap: {
    marginLeft: theme.spacing(2),
    maxWidth: 200,
    marginBottom: theme.spacing(1.5),
  },
  dragElement: {
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.divider,
    background: theme.palette.background.paper,
    '& + $dragElement': {
      borderTop: 0,
    },
  },
  dragHandle: {
    cursor: 'grab',
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  dragHandleLabel: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
  },
  footnote: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0, 2, 1.5),
    '& svg': {
      margin: theme.spacing(0, 0.5),
    },
  },
});

const DragHandleBase: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className={classes.dragHandle}>
      <DragHandleIcon />
      <div className={classes.dragHandleLabel} title={label}>
        {label}
      </div>
    </div>
  );
};

const DragHandle = SortableHandle(DragHandleBase);

const SortElementBase: React.FC<{
  label: string;
  onClickRemove: () => void;
}> = ({ label, onClickRemove }) => {
  return (
    <div className={classes.dragElement}>
      <DragHandle label={label} />

      <IconButton onClick={onClickRemove} size="small">
        <ClearIcon />
      </IconButton>
    </div>
  );
};

const SortElement = SortableElement(SortElementBase);

const SortContainerBase: React.FC = (props) => {
  return <div>{props.children}</div>;
};

const SortContainer = SortableContainer(SortContainerBase);

interface Props {
  displayables: DatasetDisplayable[];
  columns: string[];
  setColumns(f: string[]): void;
  fieldAliases: { [field: string]: string };
  setFieldAliases(v: { [field: string]: string }): void;
  error?: string | null;
}

export default function ReportColumnsPicker({
  error,
  columns,
  setColumns,
  fieldAliases,
  setFieldAliases,
  displayables,
}: Props) {
  let isAllSelected = true;
  for (const df of displayables) {
    if (!columns.includes(df.field)) {
      isAllSelected = false;
      break;
    }
  }

  return (
    <Box display="flex" alignItems="stretch" overflow="hidden" minHeight={430}>
      <Box display="flex" flexDirection="column">
        <div className={classes.header}>
          <Typography variant="h3">Columns</Typography>

          <Button
            variant="text"
            size="small"
            color="primary"
            className={classes.headerToggle}
            onClick={(e) => {
              e.preventDefault();
              if (isAllSelected) {
                setColumns([]);
              } else {
                setColumns(displayables.map((d) => d.field));
              }
            }}
          >
            {isAllSelected ? 'select none' : 'select all'}
          </Button>
        </div>
        <Box overflow="auto" flex={1}>
          <ul className={classes['column-list']}>
            {displayables.map((displayable) => (
              <ColumnCheckBox
                key={displayable.field}
                displayable={displayable}
                columns={columns}
                setColumns={setColumns}
                fieldAliases={fieldAliases}
                setFieldAliases={setFieldAliases}
              />
            ))}
          </ul>

          <div className={classes.footnote}>
            To customize the column name, click <CreateIcon fontSize="small" /> to edit.
          </div>
        </Box>

        {error && (
          <FormHelperText error variant="outlined">
            {error}
          </FormHelperText>
        )}
      </Box>

      <Box paddingLeft={2} flexGrow={1} display="flex" flexDirection="column">
        <Box marginBottom={2}>
          <Typography variant="h3">Column Order</Typography>
        </Box>

        <Box padding={1} maxWidth={262}>
          <SortContainer
            onSortEnd={({ oldIndex, newIndex }) => {
              const newCols = columns.slice();
              newCols.splice(
                newIndex < 0 ? newCols.length + newIndex : newIndex,
                0,
                newCols.splice(oldIndex, 1)[0]
              );
              setColumns(newCols);
            }}
            useDragHandle
          >
            {columns.map((colId, index) => {
              let label: string = colId;
              if (fieldAliases[colId]) {
                label = fieldAliases[colId];
              } else {
                const displayable = displayables.find((c) => c.field === colId);
                if (displayable && typeof displayable.label === 'string') {
                  label = displayable.label;
                }
              }
              return (
                <SortElement
                  key={colId}
                  index={index}
                  label={label}
                  onClickRemove={() => {
                    setColumns(columns.filter((c) => c !== colId));
                  }}
                />
              );
            })}
          </SortContainer>
        </Box>
      </Box>
    </Box>
  );
}

const ColumnCheckBox: React.FC<{
  displayable: DatasetDisplayable;
  columns: string[];
  setColumns(f: string[]): void;
  fieldAliases: { [field: string]: string };
  setFieldAliases(v: { [field: string]: string }): void;
}> = ({ displayable, columns, setColumns, fieldAliases, setFieldAliases }) => {
  let label: string = displayable.field;
  let isCustomized = false;
  if (fieldAliases[displayable.field]) {
    label = fieldAliases[displayable.field];
    isCustomized = true;
  } else if (typeof displayable.label === 'string') {
    label = displayable.label;
  }

  const [showCustomizeName, setShowCustomizeName] = React.useState<boolean>(isCustomized);

  React.useEffect(() => {
    setShowCustomizeName(isCustomized);
  }, [isCustomized]);

  return (
    <li
      className={clsx(
        classes.columnCheckbox,
        showCustomizeName && classes.columnCheckboxIsCustomized
      )}
    >
      <div className={classes.columnCheckboxRow}>
        <FormInputCheckbox
          dense
          label={typeof displayable.label === 'string' ? displayable.label : displayable.field}
          value={columns.indexOf(displayable.field) >= 0}
          onValue={(b) => {
            if (columns.indexOf(displayable.field) >= 0) {
              setColumns(columns.filter((c) => c !== displayable.field));
            } else {
              setColumns(columns.concat([displayable.field]));
            }
          }}
        />
        <IconButton
          className={classes.columnCheckboxCustomize}
          size="small"
          onClick={() => {
            if (showCustomizeName) {
              const newRCols = { ...fieldAliases };
              delete newRCols[displayable.field];
              setFieldAliases(newRCols);
              setShowCustomizeName(false);
            } else {
              setShowCustomizeName(true);
            }
          }}
        >
          {showCustomizeName ? <UndoIcon fontSize="small" /> : <CreateIcon fontSize="small" />}
        </IconButton>
      </div>
      {showCustomizeName && (
        <div className={classes.customizeNameInputWrap}>
          <FormInputText
            size="xs"
            value={label}
            onValue={(name) => {
              setFieldAliases({
                ...fieldAliases,
                [displayable.field]: name,
              });
            }}
          />
        </div>
      )}
    </li>
  );
};
