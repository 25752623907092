import { Box } from '@material-ui/core';
import { FormInputSearch } from 'components/form/FormInputSearch';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { GUStates, useSearchGURecords } from '../../states';

export default function GlobalSearchBar() {
  const searchType = useRecoilValue(GUStates.searchType);
  const searchRecords = useSearchGURecords();
  const [searchResult, setSearchResult] = useRecoilState(GUStates.searchResult);

  React.useEffect(() => {
    searchRecords();
  }, [searchResult]);

  return (
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      marginLeft={theme.spacing(0.4)}
      maxWidth={500}
    >
      <FormItem>
        <FormInputSearch searchType={searchType} value={searchResult} onValue={setSearchResult} />
      </FormItem>
    </Box>
  );
}
