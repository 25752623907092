import { Box } from '@material-ui/core';
import SearchResultTable from './SearchResultTable';
import SelectRecordsBtn from './SelectRecordsBtn';
import { useRecoilValue } from 'recoil';
import { GUStates } from '../../states';

export default function GlobalSearchResults() {
  const searchResult = useRecoilValue(GUStates.searchResult);
  if (!searchResult) {
    return <Box marginX={3}>Search for records to update.</Box>;
  }
  return (
    <Box p={3}>
      <SearchResultTable />
      <SelectRecordsBtn />
    </Box>
  );
}
