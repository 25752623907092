import { Box, Button } from '@material-ui/core';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState } from 'recoil';
import { AtomGroupCleaner, newAtom } from 'lib/RecoilUtils';
import { BookingPageStates } from '../../states';
import CarrierInformation from './CarrierInformation';
import ConfirmationSection from './ConfirmationSection';
import EditLogisticsSaveButton from './EditLogisticsSaveButton';
import ShippingLogistics from './ShippingLogistics';

export const EditLogisticsDialog_Open = newAtom(false);

export default function EditLogisticsDialog() {
  const [open, setOpen] = useRecoilState(EditLogisticsDialog_Open);

  return (
    <UniversalDialog
      open={open}
      title="<Edit Logistics>"
      subTitle="Fill In fields and confirm this booking"
      setClose={() => {
        setOpen(false);
      }}
      large
    >
      <AtomGroupCleaner atomGroup={BookingPageStates.editLogistics} />
      <Box padding={1}>
        <ConfirmationSection />
        <CarrierInformation />
        <ShippingLogistics />

        <hr />

        <Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Box marginRight={3}></Box>
            <EditLogisticsSaveButton
              onClick={() => {
                setOpen(false);
              }}
            />
          </Box>
        </Box>
      </Box>
    </UniversalDialog>
  );
}
