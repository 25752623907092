import { Link } from '@reach/router';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { EquipmentLineItem, EquipmentPageStates } from './states';

const columns: UWLTableColumn<EquipmentLineItem>[] = [
  { id: 'hbl', label: 'HBL', type: 'string' },
  { id: 'supplier', label: 'Supplier', type: 'string' },
  { id: 'orderNumber', label: 'PO', type: 'string' },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'quantity', label: 'Order QTY', type: 'number' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'shippedCtns', label: 'Shipped CTNS', type: 'number' },
  {
    id: 'unitPrice',
    label: 'Unit Cost',
    type: 'currency',
    currencyField: 'unitPriceCurrencyCode',
  },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: 'CBM' },
  { id: 'weightKG', label: 'Weight', type: 'weight', unit: 'KG' },
  { id: 'commInvTotal', label: 'Comm Inv Total', type: 'currency' },
  { id: 'freightCost', label: 'Freight Cost', type: 'currency' },
];

export default function LineItemsPanel() {
  const container = useRecoilValue(EquipmentPageStates.container);

  return (
    <Panel title="Line Items">
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          sizeMedium
          isLoading={!container}
          rows={container?.lineItems || []}
          emptyMessage="No Items Available"
          noWrapAllCells
          renderCell={{
            orderNumber(row) {
              return <Link to={'/purchase-orders/' + row.poId}>{row.orderNumber}</Link>;
            },
            hbl(row) {
              if (row.hbl) {
                return <Link to={'/hbl/' + row.hblId}>{row.hbl}</Link>;
              } else {
                return <span>Not Assigned</span>;
              }
            },
          }}
        />
      </PanelBodyTable>
    </Panel>
  );
}
