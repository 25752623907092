import { selector } from 'recoil';
import { genKey } from 'lib/RecoilUtils';
import { BookingPageStates } from '../../states';
import { AppWideStates } from 'app/states';

interface ValidationState {
  isAllowedToSave: boolean;
  confirmationNumberError: boolean;
  confirmationDateError: boolean;
  carrierError: boolean;
  polError: boolean;
  polEtdError: boolean;
  podError: boolean;
  podEtaError: boolean;
  hblPaymentTypeError: boolean;
  hblPaymentTypeWarning: boolean;
  hblReleaseTypeError: boolean;
  hblReleaseTypeWarning: boolean;
  mblPaymentTypeError: boolean;
  mblPaymentTypeWarning: boolean;
  mblReleaseTypeError: boolean;
  mblReleaseTypeWarning: boolean;
}

export const confirmBookingValidationState = selector<ValidationState>({
  key: genKey(),
  get: ({ get }) => {
    const confirmationNumber = get(BookingPageStates.editLogistics.confirmationNumber);
    const confirmationDate = get(BookingPageStates.editLogistics.confirmationDate);
    const carrier = get(BookingPageStates.editLogistics.carrier);
    const pol = get(BookingPageStates.editLogistics.pol);
    const polEtd = get(BookingPageStates.editLogistics.polEtd);
    const pod = get(BookingPageStates.editLogistics.pod);
    const podEta = get(BookingPageStates.editLogistics.podEta);
    const hblNumber = get(BookingPageStates.editLogistics.hbl);
    const hblPaymentType = get(BookingPageStates.editLogistics.hblPaymentType);
    const hblReleaseType = get(BookingPageStates.editLogistics.hblReleaseType);
    const mblNumber = get(BookingPageStates.editLogistics.mbl);
    const mblPaymentType = get(BookingPageStates.editLogistics.mblPaymentType);
    const mblReleaseType = get(BookingPageStates.editLogistics.mblReleaseType);
    const networkRules = get(AppWideStates.network.rules);

    let validationState: ValidationState = {
      isAllowedToSave: true,
      confirmationNumberError: false,
      confirmationDateError: false,
      carrierError: false,
      polError: false,
      polEtdError: false,
      podError: false,
      podEtaError: false,
      hblPaymentTypeError: false,
      hblPaymentTypeWarning: false,
      hblReleaseTypeError: false,
      hblReleaseTypeWarning: false,
      mblPaymentTypeError: false,
      mblPaymentTypeWarning: false,
      mblReleaseTypeError: false,
      mblReleaseTypeWarning: false,
    };

    if (Boolean(confirmationDate) && !Boolean(confirmationNumber)) {
      validationState.isAllowedToSave = false;
      validationState.confirmationNumberError = true;
    }

    if (Boolean(confirmationNumber) && !Boolean(confirmationDate)) {
      validationState.isAllowedToSave = false;
      validationState.confirmationDateError = true;
    }

    if (Boolean(confirmationNumber) || Boolean(confirmationDate)) {
      if (!Boolean(carrier)) {
        validationState.isAllowedToSave = false;
        validationState.carrierError = true;
      }
      if (!Boolean(pol)) {
        validationState.isAllowedToSave = false;
        validationState.polError = true;
      }
      if (!Boolean(polEtd)) {
        validationState.isAllowedToSave = false;
        validationState.polEtdError = true;
      }
      if (!Boolean(pod)) {
        validationState.isAllowedToSave = false;
        validationState.podError = true;
      }
      if (!Boolean(podEta)) {
        validationState.isAllowedToSave = false;
        validationState.podEtaError = true;
      }
    }

    if (Boolean(hblNumber) && !Boolean(hblPaymentType)) {
      if (
        !networkRules.bolHblPaymentTypeEnabled ||
        (networkRules.bolHblPaymentTypeEnabled && networkRules.bolHblPaymentTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.hblPaymentTypeError = true;
      } else if (networkRules.bolHblPaymentTypeEnabled && !networkRules.bolHblPaymentTypeHardStop) {
        validationState.hblPaymentTypeWarning = true;
      }
    }

    if (Boolean(hblNumber) && !Boolean(hblReleaseType)) {
      if (
        !networkRules.bolHblReleaseTypeEnabled ||
        (networkRules.bolHblReleaseTypeEnabled && networkRules.bolHblReleaseTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.hblReleaseTypeError = true;
      } else if (networkRules.bolHblReleaseTypeEnabled && !networkRules.bolHblReleaseTypeHardStop) {
        validationState.hblReleaseTypeWarning = true;
      }
    }

    if (Boolean(mblNumber) && !Boolean(mblPaymentType)) {
      if (
        !networkRules.bolMblPaymentTypeEnabled ||
        (networkRules.bolMblPaymentTypeEnabled && networkRules.bolMblPaymentTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.mblPaymentTypeError = true;
      } else if (networkRules.bolMblPaymentTypeEnabled && !networkRules.bolMblPaymentTypeHardStop) {
        validationState.mblPaymentTypeWarning = true;
      }
    }

    if (Boolean(mblNumber) && !Boolean(mblReleaseType)) {
      if (
        !networkRules.bolMblReleaseTypeEnabled ||
        (networkRules.bolMblReleaseTypeEnabled && networkRules.bolMblReleaseTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.mblReleaseTypeError = true;
      } else if (networkRules.bolMblReleaseTypeEnabled && !networkRules.bolMblReleaseTypeHardStop) {
        validationState.mblReleaseTypeWarning = true;
      }
    }

    return validationState;
  },
});
