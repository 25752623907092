import { Grid } from '@material-ui/core';
import { PermissionCode } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import { auth } from 'app';
import ContactCard from 'app/admin/components/ContactCard';
import { contactFilter } from 'app/admin/filters';
import Loading from 'app/Loading';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { genKey, newAtom } from 'lib/RecoilUtils';
import ProfileContactDialog, {
  openContactDialog_Data,
} from '../../../components/dialogs/ProfileContactsDialog';
import { ProfilePageStates } from '../states';

export const profileContactSearchState = newAtom('');

const filteredContactsListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(profileContactSearchState);
    const contacts = get(ProfilePageStates.profile)?.contacts || [];

    if (searchField === '') {
      return contacts;
    } else {
      return contacts.filter(contactFilter(searchField));
    }
  },
});

export default function ProfileContactCards() {
  const { userContext } = auth.useAuthState();
  const profile = useRecoilValue(ProfilePageStates.profile);
  const profileId = profile?.id || '';

  const setContactDialog = useSetRecoilState(openContactDialog_Data);

  const searchField = useRecoilValue(profileContactSearchState);
  const filteredContacts = useRecoilValue(filteredContactsListState);

  if (!profile) {
    return <Loading />;
  }

  return (
    <Grid container spacing={3}>
      {filteredContacts.length === 0 && (
        <div>
          {searchField.length > 0 ? (
            <div>No contacts matching search: "{searchField}"</div>
          ) : (
            <div>- none -</div>
          )}
        </div>
      )}
      {filteredContacts.map((contact: any, index: number) => {
        return (
          <ContactCard
            key={index}
            contact={contact}
            onManageClicked={
              userContext?.permissionCodes.has(PermissionCode.ProfileContactUpdate)
                ? (contact: any) => {
                    setContactDialog({
                      profileId: profile.id,
                      contact: contact,
                      roles: profile.rolesList,
                      locations: profile.locationList,
                    });
                  }
                : undefined
            }
          />
        );
      })}
      <ProfileContactDialog
        refetchQuery={{
          query: ProfileQuery,
          variables: {
            profileId: profileId,
          },
          fetchPolicy: 'network-only',
        }}
      />
    </Grid>
  );
}
