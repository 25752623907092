import { Box, Button, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { navigate, RouteComponentProps } from '@reach/router';
import { FilterInputs } from 'components/dataset/FilterInputs';
import ErrorMessage from 'components/ErrorMessage';
import FormInputSelect from 'components/form/FormInputSelect';
import { PanelBase, PanelBody, PanelHeader } from 'components/Panel';
import ReportColumnsPicker from 'components/ReportColumnsPicker';
import React from 'react';
import reportCategoriesStore from 'stores/reportCategoriesStore';
import reportFormStore from 'stores/reportFormStore';
import { theme } from 'styles';
import { DatasetFilter } from 'types/Dataset';
import DownloadDynamicReportButton from '../report/DownloadDynamicReportButton';
import { getPresetReports, PresetReport } from './state';

interface Props extends RouteComponentProps {}

export const DynamicReportPage: React.FC<Props> = (props) => {
  const categories = reportCategoriesStore.use();
  const [presetReports, setPresetReports] = React.useState<PresetReport[]>([]);
  const [presetReport, setPresetReport] = React.useState<PresetReport | null>(null);
  const [filters, setFilters] = React.useState<DatasetFilter[]>([]);
  const [fields, setFields] = React.useState<string[]>([]);
  const [fieldAliases, setFieldAliases] = React.useState<{ [field: string]: string }>({});
  const [expandColumns, setExpandColumns] = React.useState<boolean>(false);
  const [expandFilterables, setExpandFilterables] = React.useState<boolean>(false);

  React.useEffect(() => {
    setPresetReports(getPresetReports(categories.data));
  }, [categories.data]);

  const dataset = presetReport?.dataset;
  const displayables = dataset?.displayables || [];
  const filterables = dataset?.filterables || [];

  let filtersValidation: string | null = null;
  if (dataset) {
    for (const f of filters) {
      const vf = reportCategoriesStore.validateFilterInput(dataset.id, f);
      if (vf) {
        filtersValidation = vf;
        break;
      }
    }
  }

  React.useEffect(() => {
    if (filtersValidation) {
      setExpandFilterables(true); // show the section so they see the error
    }
  }, [filtersValidation]);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" paddingBottom={4}>
        <Typography variant="h1">Dynamic Report</Typography>
      </Box>

      <PanelBase>
        <PanelHeader title="1. Report Type" />
        <PanelBody>
          <div style={{ maxWidth: '400px', margin: theme.spacing(2) }}>
            <FormInputSelect
              label="Report Type"
              required
              options={presetReports.map((c) => ({
                id: c.id,
                label: c.name,
                description: c.description,
              }))}
              value={presetReport?.id || null}
              onValue={(reportId) => {
                const presetReport = presetReports.find((c) => c.id === reportId);
                setPresetReport(presetReport || null);
                setFilters(presetReport?.filters || []);
                setFields(presetReport?.dataset.defaultDisplayables || []);
                setFieldAliases({});
                setExpandColumns(false);
                setExpandFilterables(false);
              }}
              disabled={categories.waiting}
              error={!!categories.error}
              helperText={categories.error}
            />
          </div>
        </PanelBody>
      </PanelBase>

      {dataset && (
        <Accordion expanded={expandColumns} onChange={(e, expanded) => setExpandColumns(expanded)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">
              2. Columns
              <span style={{ marginLeft: theme.spacing(1), color: '#777' }}>
                {fields.length} / {displayables.length}
              </span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReportColumnsPicker
              displayables={displayables}
              columns={fields}
              setColumns={setFields}
              fieldAliases={fieldAliases}
              setFieldAliases={setFieldAliases}
              // error={fields}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {dataset && filterables.length > 0 && (
        <Accordion
          expanded={expandFilterables}
          onChange={(e, expanded) => setExpandFilterables(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">3. Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ width: '100%' }}>
              <FilterInputs filterables={filterables} value={filters} onValue={setFilters} />
              <ErrorMessage error={filtersValidation} />
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <div
        style={{
          margin: theme.spacing(1.5, 0),
          display: 'flex',
        }}
      >
        <DownloadDynamicReportButton
          req={
            presetReport
              ? {
                  name: presetReport.name,
                  datasetId: presetReport.dataset.id,
                  fields: fields,
                  filters: filters,
                }
              : {
                  name: '',
                  datasetId: '',
                  fields: [],
                  filters: [],
                }
          }
          disabled={!presetReport || !!filtersValidation}
          color="primary"
          btnLabel="Download Report"
        />
        <Button
          style={{ marginLeft: theme.spacing(1) }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => {
            if (!presetReport) return;
            reportFormStore.init(null);
            reportFormStore.put({
              name: presetReport.name,
              datasetId: presetReport.dataset.id,
              fields: fields,
              filters: filters,
            });
            reportFormStore.startValidation();
            navigate(`/reports/new/1?preserve`);
          }}
          disabled={!presetReport || !!filtersValidation}
        >
          Save / Schedule
        </Button>
      </div>
    </div>
  );
};
