import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ManageNetwork from './manage';
import NetworkList from './list';
import { FlexGrowRouter } from 'components/StyledComponents';

interface NetworksProps extends RouteComponentProps {}
function Networks(props: NetworksProps) {
  return (
    <FlexGrowRouter>
      <NetworkList path="/" />
      <ManageNetwork path="/:networkId/*" />
    </FlexGrowRouter>
  );
}

export default Networks;
