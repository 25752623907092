interface ValidationResponse {
  passwordError: string | null;
  confirmPasswordError: string | null;
}

export function validatePassword(password: string, confirmPassword: string): ValidationResponse {
  let response: ValidationResponse = {
    passwordError: null,
    confirmPasswordError: null,
  };

  if (password.length < 1) {
    response.passwordError = 'Please enter a password.';
  } else if (password.length < 8) {
    response.passwordError = 'Password must be at least 8 characters long.';
  } else if (!/[0-9]/.test(password)) {
    response.passwordError = 'Password must contain at least one number.';
  } else if (password !== confirmPassword) {
    response.confirmPasswordError = 'Passwords do not match.';
  }

  return response;
}
