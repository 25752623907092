import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Button, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import { Link } from '@reach/router';
import {
  GqlDetachConsolidationBookingMutation,
  GqlDetachConsolidationBookingMutationVariables,
  PermissionCode,
} from 'api/GQL_Types';
import { ConsolidationQuery, detachConsolidationBooking } from 'api/queries/consolidationQueries';
import { auth } from 'app';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { MessageDialog } from 'components/MessageDialog';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { CancelConsolDialog } from '../CancelConsolDialog';
import { BookingTableRow, ConsolidationPageStates, useCanCancelConsol } from '../states';
import { AddBookingDialog } from './AddBookingDialog';

const columns: UWLTableColumn<BookingTableRow>[] = [
  { id: 'referenceNumber', label: 'Booking', type: 'link', whiteSpace: 'nowrap' },
  { id: 'containers', label: 'Container', type: 'string', whiteSpace: 'nowrap' },
  { id: 'hbl', label: 'HBL', type: 'link', whiteSpace: 'nowrap' },
  { id: 'supplierName', label: 'Supplier', type: 'string', whiteSpace: 'nowrap' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'weight', label: 'Wgt', type: 'weight', unit: 'KG' },
  { id: 'volume', label: 'CBM', type: 'volume', unit: 'CBM' },
  { id: 'bookingStatus', label: 'Booking Status', type: 'string', whiteSpace: 'nowrap' },
  { id: 'cargoReadyDate', label: 'Cgo Rdy Date', type: 'date' },
  { id: 'pol', label: 'POL', type: 'string', whiteSpace: 'nowrap' },
  { id: 'polEtd', label: 'ETD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string', whiteSpace: 'nowrap' },
  { id: 'finalDestinationName', label: 'Final Destination', type: 'string', whiteSpace: 'nowrap' },
  { id: 'moveType', label: 'Delivery Type', type: 'string', whiteSpace: 'nowrap' },
];

interface Props {
  consolidationId: string;
}

export default function ConsolidationBookingsPanel(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userContext } = auth.useAuthState();
  const canCancel = useCanCancelConsol();
  const [showAddBooking, setShowAddBooking] = React.useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState<boolean>(false);
  const [confirmDetachBooking, setConfirmDetachBooking] = React.useState<BookingTableRow | null>(
    null
  );
  const [showCannotDetachBooking, setCannotDetachBooking] = React.useState<boolean>(false);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ConsolidationQuery,
      variables: { consolId: consolidation?.id ?? '' },
      fetchPolicy: 'network-only',
    },
  ];

  const detachConsolidationBookingAction = useAsyncAction<
    GqlDetachConsolidationBookingMutationVariables,
    GqlDetachConsolidationBookingMutation
  >((input) => detachConsolidationBooking(input, { refetchQueries }), {
    onData(data) {
      setConfirmDetachBooking(null);
      enqueueSnackbar('Booking Detached!', { variant: 'success' });

      const nBookings = data.detachConsolidationBooking.consolidation?.bookings.length || 0;
      if (nBookings === 0) {
        setShowCancelDialog(true);
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to detach booking: ' + error, { variant: 'error' });
    },
  });

  React.useEffect(() => {
    setShowAddBooking(false);
    setShowCancelDialog(false);
  }, [consolidation?.id]);

  const canDetach = !!userContext?.permissionCodes.has(PermissionCode.ConsolidationDetachBooking);
  const canDetachOverride = !!userContext?.permissionCodes.has(
    PermissionCode.ConsolidationDetachBookingOverride
  );

  const hasDeparted = !!consolidation?.logistics.polAtd;
  const canAddBooking = !!userContext?.permissionCodes.has(PermissionCode.ConsolidationAddBooking);
  const canAddBookingOverride = !!userContext?.permissionCodes.has(
    PermissionCode.ConsolidationAddBookingOverride
  );

  return (
    <Panel
      title="Bookings"
      topRight={
        !consolidation?.cancelledDate &&
        (canAddBookingOverride || (canAddBooking && !hasDeparted)) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowAddBooking(true);
            }}
          >
            Add Booking
          </Button>
        )
      }
    >
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          rows={consolidation?.bookingTableRows || []}
          emptyMessage="- No Bookings -"
          renderCell={{
            containers(row) {
              return (
                <div>
                  {row.containers.map((eqp) => {
                    return (
                      <div key={eqp.to}>
                        <Link to={eqp.to}>{eqp.value}</Link>
                      </div>
                    );
                  })}
                </div>
              );
            },
            hbl(row) {
              if (!row.hbl) {
                return null;
              }
              return (
                <Link to={row.hbl.to} state={{ consolidationId: props.consolidationId }}>
                  {row.hbl.value}
                </Link>
              );
            },
          }}
          rowAction={(row) => {
            if (consolidation?.cancelledDate) {
              return null;
            }
            if (canDetachOverride || canDetach) {
              // can detach
            } else {
              return null; // cannot detach
            }
            return (
              <IconButton
                aria-label="delete"
                style={{ width: '10px', height: '10px' }}
                onClick={() => {
                  if (row.containers.length > 0) {
                    setCannotDetachBooking(true);
                  } else {
                    setConfirmDetachBooking(row);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            );
          }}
        />
      </PanelBodyTable>
      {confirmDetachBooking && (
        <DeleteConfirmationDialog
          open
          onClose={() => {
            setConfirmDetachBooking(null);
          }}
          title="Detach Booking"
          message={`Do you want to detach booking ${confirmDetachBooking.referenceNumber.value}?`}
          onClickDelete={() => {
            if (!consolidation) return;
            if (!confirmDetachBooking) return;
            detachConsolidationBookingAction.act({
              input: {
                consolidationId: consolidation.id,
                bookingShipmentId: confirmDetachBooking.id,
              },
            });
          }}
          deleteBtnText="Detach"
          error={detachConsolidationBookingAction.error}
          waiting={detachConsolidationBookingAction.waiting}
        />
      )}

      {showCannotDetachBooking && (
        <MessageDialog
          open
          onClose={() => {
            setCannotDetachBooking(false);
          }}
          title="Detach Booking"
          message="You cannot detach because the booking is packed in a container."
        ></MessageDialog>
      )}

      {showAddBooking && <AddBookingDialog onClose={() => setShowAddBooking(false)} />}
      {showCancelDialog && consolidation && canCancel && (
        <CancelConsolDialog
          consolidationId={consolidation.id}
          onClose={() => {
            setShowCancelDialog(false);
          }}
        />
      )}
    </Panel>
  );
}
