import { RouteComponentProps } from '@reach/router';
import Panel from 'components/Panel';
import GlobalUpdateSearch from './global-update-search';
import GlobalSearchResults from './search-results';

interface GlobalUpdateProps extends RouteComponentProps {}
export default function GlobalUpdateStep1(props: GlobalUpdateProps) {
  return (
    <Panel title="Global Update">
      <GlobalUpdateSearch />
      <GlobalSearchResults />
    </Panel>
  );
}
