import { SearchType } from 'api/GQL_Types';
import { FormInputSearch } from 'components/form/FormInputSearch';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { useRecoilState } from 'recoil';
import { DocumentBrowserStates } from '../states';

export default function ItemAutoComplete() {
  const [state, setState] = useRecoilState(DocumentBrowserStates.itemNumber);

  return (
    <FormItem>
      <FormInputSearch searchType={SearchType.Sku} value={state} onValue={setState} />
    </FormItem>
  );
}
