import { Box } from '@material-ui/core';
import { useOpenOrdersLazyQuery, VolumeUnit, WeightUnit } from 'api/GQL_Types';
import Loading from 'app/Loading';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { NewBookingPageStates, OpenOrderLine, useSetupStep1 } from '../states';
import BookingOrderLinesTable from './booking-order-lines-table';
import { LinesToBookTable } from './LinesToBookTable';

export default function NewBookingStep1() {
  const setupStep1 = useSetupStep1();
  const allLines = useRecoilValue(NewBookingPageStates.step1.allLines);

  const [getOpenOrders, { loading }] = useOpenOrdersLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const poList: OpenOrderLine[] = [];
      for (const oo of data.openOrders) {
        poList.push({
          id: oo.orderLine.id,
          poId: oo.orderLine.purchaseOrder.id,
          orderNumber: oo.orderLine.purchaseOrder.poNumber,
          itemNumber: oo.orderLine.itemNumber,
          lineNumber: oo.orderLine.lineNumber ?? '',
          orderQty: oo.orderQty,
          balanceQty: oo.balanceQty,
          shippedQty: oo.shippedQty,
          shippedCartons: oo.shippedCartons,
          weight: oo.orderLine.weight ?? 0,
          weightUnit: oo.orderLine.weightUnit ? oo.orderLine.weightUnit : WeightUnit.Kg,
          weightKg: oo.orderLine.weightKg ?? 0,
          volume: oo.orderLine.volume ?? 0,
          volumeUnit: oo.orderLine.volumeUnit ? oo.orderLine.volumeUnit : VolumeUnit.Cbm,
          volumeM3: oo.orderLine.volumeM3 ?? 0,
          expectedCargoReadyDate: oo.orderLine.expectedCargoReadyDate,
          revisedCargoReadyDate: oo.orderLine.revisedCargoReadyDate,
          firstShipDate: oo.orderLine.firstShipDate,
          lastShipDate: oo.orderLine.lastShipDate,
          requestedDeliveryDate: oo.orderLine.revisedCargoReadyDate, //check
          shipToLocationName: oo.orderLine.shipToLocation?.name ?? '',
          supplierName: oo.orderLine.purchaseOrder?.supplierName ?? '',
        });
      }
      setupStep1(poList);
    },
  });

  React.useEffect(() => {
    if (
      allLines.length === 0 // don't re-fetch if they used the back button
    ) {
      getOpenOrders();
    }
  }, [allLines.length]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column">
      <div style={{ maxHeight: 400, overflow: 'hidden', display: 'flex' }}>
        <Panel title="Line Items">
          <LinesToBookTable />
        </Panel>
      </div>
      <Box flex="1 1 300px" overflow="auto" display="flex">
        <BookingOrderLinesTable />
      </Box>
    </Box>
  );
}
