import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Box, Button, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import { RouteComponentProps } from '@reach/router';
import {
  GqlNewApiTokenMutation,
  GqlNewApiTokenMutationVariables,
  SystemPermissionCode,
  useApiTokensLazyQuery,
  useApiWebhooksLazyQuery,
} from 'api/GQL_Types';
import { ApiTokensQuery, newApiToken } from 'api/queries/apiQueries';
import { ApiWebhooksQuery } from 'api/queries/apiWebhookQueries';
import { auth } from 'app';
import { API_URL } from 'env';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { theme } from 'styles';
import { AddWebhookDialog } from './AddWebhookDialog';
import { ApiTokenTableForm } from './ApiTokenTableForm';
import { ApiWebhookTableForm } from './ApiWebhookTableForm';
import { useSetApiPageState, useSetApiWebhookPageState } from './states';

interface Props extends RouteComponentProps {}

export default function ManageApi(props: Props) {
  const { userContext } = auth.useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const canEdit = !!userContext?.systemPermissionCodes.has(SystemPermissionCode.ApiManageAccess);
  const setApiPage = useSetApiPageState();
  const setApiWebhookPage = useSetApiWebhookPageState();
  const [openWebhookDialog, setOpenWebhookDialog] = React.useState<boolean | null>(false);

  const [getApiTokens] = useApiTokensLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setApiPage(data);
    },
    onError(error) {
      enqueueSnackbar('Failed to retrieve API tokens: ' + error, { variant: 'error' });
    },
  });

  const [getApiWebhooks] = useApiWebhooksLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setApiWebhookPage(data);
    },
    onError(error) {
      enqueueSnackbar('Failed to retrieve API Webhook: ' + error, { variant: 'error' });
    },
  });

  React.useEffect(() => {
    setApiPage(null);
    getApiTokens();
    getApiWebhooks();
  }, []);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ApiTokensQuery,
      fetchPolicy: 'network-only',
    },
    {
      query: ApiWebhooksQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const newApiTokenAction = useAsyncAction<GqlNewApiTokenMutationVariables, GqlNewApiTokenMutation>(
    (input) => newApiToken(input, { refetchQueries }),
    {
      onData(data) {
        enqueueSnackbar('API token created!', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to create API token: ' + error, { variant: 'error' });
      },
    }
  );

  return (
    <div>
      <Box margin="16px 24px">
        <Box display="flex" justifyContent="space-between" marginBottom={3}>
          <Box>
            <Typography variant="h2">API</Typography>
            <Typography variant="body1">
              These are the active API tokens and webhooks for OMS.
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: theme.spacing(1) }}
              onClick={() => {
                navigator.clipboard.writeText(API_URL);
                enqueueSnackbar('Copied to clipboard!', { variant: 'success' });
              }}
            >
              <i className="material-icons" style={{ marginRight: theme.spacing(1) }}>
                {'content_copy'}
              </i>
              <Box component="span" marginLeft={1}>
                COPY API URL
              </Box>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              href={`${API_URL}/edi/documentation/index.html`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon />
              <Box component="span" marginLeft={1}>
                Documentation
              </Box>
            </Button>
          </Box>
        </Box>
        <Box paddingBottom={10}>
          <ApiTokenTableForm canEdit={canEdit} />
        </Box>
        <Box paddingBottom={10}>
          <ApiWebhookTableForm canEdit={canEdit} />
        </Box>
      </Box>

      <Box position="absolute" bottom={50} right={40}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            newApiTokenAction.act({});
          }}
        >
          <AddIcon />{' '}
          <Box component="span" marginLeft={1}>
            API Token
          </Box>
        </Fab>
      </Box>
      <Box position="absolute" bottom={50} right={200}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            setOpenWebhookDialog(true);
          }}
        >
          <AddIcon />{' '}
          <Box component="span" marginLeft={1}>
            Webhook
          </Box>
        </Fab>
      </Box>
      {openWebhookDialog && <AddWebhookDialog onClose={() => setOpenWebhookDialog(false)} />}
    </div>
  );
}
