import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { auth, userContextAtom } from 'app';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { sidenavStyle } from './navstyles';

export default function UserAvatar() {
  const userContext = useRecoilValue(userContextAtom);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = sidenavStyle();

  return (
    <Box className={classes.toolbar}>
      <ButtonBase className={classes.userButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box style={{ minWidth: '56px' }}>
          <Avatar className={classes.profilePicture}>
            {userContext?.user?.firstName?.substring(0, 1)}
            {userContext?.user?.lastName?.substring(0, 1)}
          </Avatar>
        </Box>
        <Hidden smDown>
          <Typography variant="h3" className={classes.userText}>
            {userContext?.user?.firstName + ' ' + userContext?.user?.lastName}
          </Typography>
        </Hidden>
      </ButtonBase>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate('/my-account');
          }}
        >
          My Account
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setAnchorEl(null);
            auth.signout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}
