import * as React from 'react';

export function NotifierHook() {
  // way to subscribe and notify react components of state change

  let clock: number = 0;

  let setters: React.Dispatch<React.SetStateAction<number>>[] = [];

  function notify() {
    clock++;
    for (let i = 0, len = setters.length; i < len; i++) {
      setters[i](clock);
    }
  }

  function use() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, set] = React.useState(clock);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (!setters.includes(set)) {
        setters.push(set);
      }
      return () => {
        setters = setters.filter((setter) => setter !== set);
      };
    }, []);
  }

  return { use, notify };
}
