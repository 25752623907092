import Panel from 'components/Panel';
import React from 'react';
import BookingFields from './BookingFields';
import BookingLineItemsTables from './BookingLineItemsTables';
import { EquipmentSection } from './EquipmentSection';
import NewBookingStep2Remarks from './NewBookingStep2Remarks';

export default function NewBookingStep2() {
  return (
    <div>
      <BookingFields />

      <EquipmentSection />

      <NewBookingStep2Remarks />

      <Panel title="Line Items">
        <BookingLineItemsTables />
      </Panel>
    </div>
  );
}
