import { gql } from '@apollo/client/core';
import {
  GqlNewProfileTypeDefaultRoleMutation,
  GqlNewProfileTypeDefaultRoleMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const NetworkProfileTypeQuery = gql`
  query NetworkProfileType {
    network {
      profileTypes {
        id
        name
      }
    }
  }
`;

export const ProfileTypeTemplatesQuery = gql`
  query ProfileTypeTemplates {
    profileTypeTemplates {
      id
      name
      description
      isTemplate
      defaultRoles {
        id
        name
        description
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
      }
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
    }
  }
`;

export const ProfileTypeQuery = gql`
  query ProfileType($profileTypeId: ID!) {
    profileType(profileTypeId: $profileTypeId) {
      id
      name
      description
      isTemplate
      defaultRoles {
        id
        name
        description
        isDefaultRole
        permissionCodes
        notificationCodes
        dashboardFeatureCodes
        documentTypesCanView
      }
      permissionCodes
      notificationCodes
      dashboardFeatureCodes
    }
  }
`;

export const NewProfileTypeMutation = gql`
  mutation NewProfileType($input: NewProfileTypeTemplateInput!) {
    newProfileTypeTemplate(input: $input) {
      success
      message
    }
  }
`;

export const ProfileTypeUpdateMutation = gql`
  mutation ProfileTypeUpdate($input: UpdateProfileTypeInput!) {
    updateProfileType(input: $input) {
      success
      message
    }
  }
`;

export const ProfileTypeDeleteMutation = gql`
  mutation ProfileTypeDelete($input: DeleteProfileTypeInput!) {
    deleteProfileType(input: $input) {
      success
      message
    }
  }
`;

export const NewProfileTypeDefaultRoleMutation = gql`
  mutation NewProfileTypeDefaultRole($input: NewProfileTypeDefaultRoleInput!) {
    newProfileTypeDefaultRole(input: $input) {
      success
      message
    }
  }
`;

export const newProfileTypeDefaultRole = wrapMutation<
  GqlNewProfileTypeDefaultRoleMutation,
  GqlNewProfileTypeDefaultRoleMutationVariables
>({
  mutation: NewProfileTypeDefaultRoleMutation,
  errorMessage(data, error) {
    if (!data?.newProfileTypeDefaultRole.success || error) {
      return data?.newProfileTypeDefaultRole.message || 'Unexpected Error';
    }
  },
});
