import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  start: boolean;
  finished: boolean;
  onFinished: () => void;
  loadingText?: string;
  finishText?: string;
}

let step = 0.1;

export default function FakeProgressBar(props: Props) {
  const [tick, setTick] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [c, setC] = React.useState(0);

  React.useEffect(() => {
    if (props.start && !props.finished) {
      const timeout = setTimeout(() => {
        if (props.start) {
          setC(c + step);
          setProgress(Math.round((Math.atan(c) / (Math.PI / 2)) * 100 * 1000) / 1000);
          if (progress >= 100) {
            setProgress(100);
          } else {
            setTick(!tick);
          }
        }
      }, 100);

      return function cleanup() {
        clearTimeout(timeout);
      };
    } else {
      setProgress(100);
      setTimeout(() => {
        props.onFinished();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tick]);

  return (
    <>
      <LinearProgress value={progress} variant="determinate" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop={1}
      >
        <Typography variant="body1">
          {props.finished ? props.finishText : props.loadingText}
        </Typography>
      </Box>
    </>
  );
}
