import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { openContactDialog_Data } from '../../../components/dialogs/ProfileContactsDialog';
import { ProfilePageStates } from '../states';

export default function AddProfileContactBtn() {
  const setContactDialog = useSetRecoilState(openContactDialog_Data);
  const profile = useRecoilValue(ProfilePageStates.profile);

  return (
    <Fab
      variant="extended"
      color="primary"
      onClick={() => {
        if (!profile) return;
        setContactDialog({
          profileId: profile.id,
          contact: null,
          roles: profile.rolesList,
          locations: profile.locationList,
        });
      }}
    >
      <AddIcon />{' '}
      <Box component="span" marginLeft={1}>
        Contact
      </Box>
    </Fab>
  );
}
