import { faCheck, faExclamation, faFire, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { formatDate } from 'types/Date';

const thumbBgColor = '#FFFFFF';
const disabledColor = '#A0A0A0';
const activeColor = '#FF9936';
const fireIconColor = '#FF9C31';

interface StyleProps {
  switchHeight: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: ({ switchHeight }: StyleProps) => switchHeight * 2.25,
      height: ({ switchHeight }: StyleProps) => switchHeight,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: ({ switchHeight }: StyleProps) => switchHeight * 0.1,
      '&$checked': {
        '& + $track': {
          opacity: 1,
          backgroundColor: activeColor,
          borderColor: activeColor,
        },
      },
    },
    thumb: {
      width: ({ switchHeight }: StyleProps) => switchHeight * 0.8,
      height: ({ switchHeight }: StyleProps) => switchHeight * 0.8,
    },
    track: {
      borderRadius: ({ switchHeight }: StyleProps) => switchHeight / 2,
      opacity: 1,
      backgroundColor: disabledColor,
    },
    checked: {
      transform: ({ switchHeight }: StyleProps) =>
        `translateX(${switchHeight * 1.25}px) !important`,
    },
    hot: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '8px',
    },
    info: {
      fontStyle: 'italic',
      lineHeight: '1em',
      margin: '0 .5em',
    },
  })
);

interface IconProps {
  icon: IconDefinition;
  active: boolean;
  isSaving?: boolean;
  switchHeight: number;
}

function ThumbIcon(props: IconProps) {
  const { switchHeight } = props;
  const cssfields = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    bgcolor: thumbBgColor,
    height: switchHeight * 0.8,
    width: switchHeight * 0.8,
  };
  return (
    <Box {...cssfields}>
      {props.isSaving ? (
        <CircularProgress size={switchHeight * 0.76} />
      ) : (
        <FontAwesomeIcon
          style={{ width: switchHeight * 0.6, height: switchHeight * 0.6 }}
          icon={props.icon}
          color={props.active ? activeColor : disabledColor}
        />
      )}
    </Box>
  );
}

export interface HotState {
  isHot: boolean;
  hotMarkedBy?: string | null;
  hotMarkedTimestamp?: Date | null;
}

interface Props {
  initialState: HotState;
  save(isHot: boolean): HotState | Promise<HotState>;
  canMarkHot: boolean;

  small?: boolean;
  showDetailsOnLeft?: boolean;
  showDetailsOnRight?: boolean;
}

export const HotToggleSwitch: React.FC<Props> = (props) => {
  const switchHeight = props.small ? 14 : 26;

  const classes = useStyles({ switchHeight });

  const { enqueueSnackbar } = useSnackbar();

  const [currentHotState, setCurrentHotState] = React.useState<HotState>(props.initialState);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);
  const [saved, setSaved] = React.useState<boolean>(false);
  const [savedTimeout, setSavedTimeout] = React.useState<any>();

  async function save(checked: boolean) {
    setIsSaving(true);
    setError(null);
    try {
      const newState = await props.save(checked);
      setCurrentHotState(newState);
      setSaved(true);
      setSavedTimeout(setTimeout(() => setSaved(false), 1000));
    } catch (err) {
      setError(err + '');
      enqueueSnackbar(err + '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    } finally {
      setIsSaving(false);
    }
  }

  React.useEffect(() => {
    setCurrentHotState(props.initialState);
    setIsSaving(false);
    setError(null);
    setSaved(false);
    clearTimeout(savedTimeout);

    return () => {
      clearTimeout(savedTimeout);
    };
  }, [
    // format as a string so it compares by value not by reference
    `${props.initialState.isHot} ${props.initialState.hotMarkedBy || ''} ${
      props.initialState.hotMarkedTimestamp + ''
    }`,
  ]);

  const isHot = currentHotState.isHot;

  const icon = isSaving ? (
    <ThumbIcon active={isHot} icon={faFire} isSaving={true} switchHeight={switchHeight} />
  ) : saved ? (
    <ThumbIcon active={isHot} icon={faCheck} switchHeight={switchHeight} />
  ) : error ? (
    <ThumbIcon active={isHot} icon={faExclamation} switchHeight={switchHeight} />
  ) : (
    <ThumbIcon active={isHot} icon={faFire} switchHeight={switchHeight} />
  );

  let mouseOverMessage: string = '';
  if (error) {
    mouseOverMessage = error;
  } else if (isSaving) {
    mouseOverMessage = 'Saving...';
  } else if (isHot) {
    if (currentHotState.hotMarkedBy && currentHotState.hotMarkedTimestamp) {
      mouseOverMessage = `Marked hot by ${currentHotState.hotMarkedBy} on ${formatDate(
        currentHotState.hotMarkedTimestamp
      )}`;
    } else if (currentHotState.hotMarkedBy) {
      mouseOverMessage = `Marked hot by ${currentHotState.hotMarkedBy}`;
    } else {
      mouseOverMessage = 'Marked hot';
    }
  } else {
    mouseOverMessage = 'Click to mark as Hot';
  }

  let detailsLeft: any = null;
  let detailsRight: any = null;
  if ((isSaving ? !isHot : isHot) && currentHotState.hotMarkedBy) {
    if (props.showDetailsOnLeft) {
      detailsLeft = (
        <div className={classes.info}>
          Marked Hot by {currentHotState.hotMarkedBy}
          {currentHotState.hotMarkedTimestamp
            ? ` on ${formatDate(currentHotState.hotMarkedTimestamp)}`
            : ''}
        </div>
      );
    }
    if (props.showDetailsOnRight) {
      detailsRight = (
        <div className={classes.info}>
          Marked Hot by {currentHotState.hotMarkedBy}
          {currentHotState.hotMarkedTimestamp
            ? ` on ${formatDate(currentHotState.hotMarkedTimestamp)}`
            : ''}
        </div>
      );
    }
  }

  if (!props.canMarkHot) {
    if (!isHot) {
      return null;
    }
    return (
      <div className={classes.hot}>
        {detailsLeft}
        <FontAwesomeIcon
          icon={faFire}
          color={fireIconColor}
          style={{ width: switchHeight, height: switchHeight }}
        />
        {detailsRight}
      </div>
    );
  }

  return (
    <div className={classes.hot} onClick={(e) => e.stopPropagation()}>
      {detailsLeft}
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        icon={icon}
        checkedIcon={icon}
        title={mouseOverMessage}
        disableRipple
        disabled={isSaving}
        checked={isSaving ? !isHot : isHot}
        onChange={(e, checked) => save(checked)}
      />
      {detailsRight}
    </div>
  );
};
