import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
  },
  icon: {
    height: '20px',
  },
  messages: {
    marginLeft: theme.spacing(1),
  },
  message: {
    color: theme.palette.warning.dark,
    fontWeight: 'bold',
  },
  messageHigh: {
    color: theme.palette.error.main,
  },
});

interface Props {
  messages: string[];
  severity?: 'mild' | 'high';
}

export const WarningIconWithMessages: React.FC<Props> = ({ messages, severity }) => {
  if (messages.length === 0) {
    return null;
  }
  return (
    <div className={classes.root}>
      <WarningIcon
        htmlColor={severity === 'high' ? '#ff4e4e' : '#ffd758'}
        className={classes.icon}
      />
      <div className={classes.messages}>
        {messages.map((w, i) => {
          return (
            <div
              key={i}
              className={clsx(classes.message, severity === 'high' && classes.messageHigh)}
            >
              {w}
            </div>
          );
        })}
      </div>
    </div>
  );
};
