import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Box, Button } from '@material-ui/core';
import { GqlContactInput, NotificationCode } from 'api/GQL_Types';
import { updateContact } from 'api/queries/contactQueries';
import { newProfileContact } from 'api/queries/profileQueries';
import { AdminPagesContact } from 'app/admin/states';
import { useNotificationCodeNameLookup } from 'app/states';
import AtomicMultiSelectorV2 from 'components/atomic/AtomicMultiSelectorV2';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import AtomicSwitchV2 from 'components/atomic/AtomicSwitchV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { isValidEmail } from 'types/Email';
import { SetupUserAccountDialog } from './SetupUserAccountDialog';

interface ContactDialogSetupData {
  profileId: string;
  contact: AdminPagesContact | null; // set to null if you are creating a new contact
  roles: ContactDialogRole[];
  locations: { id: string; name: string }[];
}

interface ContactDialogRole {
  id: string;
  name: string;
  notificationCodes: NotificationCode[];
}

export const openContactDialog_Data = newAtom<ContactDialogSetupData | null>(null);

const form = {
  firstName: newAtom(''),
  lastName: newAtom(''),
  title: newAtom(''),
  email: newAtom(''),
  mainPhoneNumber: newAtom(''),
  cellPhoneNumber: newAtom(''),
  altPhoneNumber: newAtom(''),
  extension: newAtom(''),
  location: newAtom<{ id: string; name: string }>({ id: '', name: '' }),
  role: newAtom<ContactDialogRole>({ id: '', name: '', notificationCodes: [] }),
  isUserEnabled: newAtom(false),
  notificationCodes: newAtom<NotificationCode[]>([]),
};

interface FormValidationState {
  hasChanged: boolean;
  isValid: boolean;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  role: string | null;
}

interface Props {
  refetchQuery: any;
}

export default function ProfileContactDialog(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [dialogData, setDialogData] = useRecoilState(openContactDialog_Data);

  const [firstName, setFirstName] = useRecoilState(form.firstName);
  const [lastName, setLastName] = useRecoilState(form.lastName);
  const [title, setTitle] = useRecoilState(form.title);
  const [email, setEmail] = useRecoilState(form.email);
  const [mainPhoneNumber, setMainPhoneNumber] = useRecoilState(form.mainPhoneNumber);
  const [cellPhoneNumber, setCellPhoneNumber] = useRecoilState(form.cellPhoneNumber);
  const [altPhoneNumber, setAltPhoneNumber] = useRecoilState(form.altPhoneNumber);
  const [extension, setExtension] = useRecoilState(form.extension);
  const [location, setLocation] = useRecoilState(form.location);
  const [role, setRole] = useRecoilState(form.role);
  const [isUserEnabled, setIsUserEnabled] = useRecoilState(form.isUserEnabled);
  const [notificationCodes, setNotificationCodes] = useRecoilState(form.notificationCodes);
  const [showSetupUserAccountDialog, setShowSetupUserAccountDialog] =
    React.useState<boolean>(false);

  const contact = dialogData?.contact;
  const profileId = dialogData?.profileId || '';
  const roles = dialogData?.roles || [];

  const notificationCodeNameLookup = useNotificationCodeNameLookup();

  React.useEffect(() => {
    setFirstName(contact?.firstName ?? '');
    setLastName(contact?.lastName ?? '');
    setTitle(contact?.title ?? '');
    setEmail(contact?.email ?? '');
    setMainPhoneNumber(contact?.phoneMain ?? '');
    setCellPhoneNumber(contact?.phoneCell ?? '');
    setAltPhoneNumber(contact?.phoneAlternative ?? '');
    setExtension(contact?.extension ?? '');
    setLocation(contact?.location ?? { id: '', name: '' });
    setRole(contact?.role ?? { id: '', name: '', notificationCodes: [] });
    setIsUserEnabled(!!contact?.isUserEnabled);
    setNotificationCodes(contact?.notificationCodes ?? []);
  }, [contact]);

  React.useEffect(() => {
    // When the role changes, reset the notification codes to match it
    if (contact?.role?.id === role.id && Array.isArray(contact.notificationCodes)) {
      // Restore their settings if they selected the same role
      setNotificationCodes(contact.notificationCodes);
      return;
    }
    // They selected a new role, reset the selection to the role settings
    setNotificationCodes(role.notificationCodes);
  }, [role.id, contact, isUserEnabled]);

  const putContactAction = useAsyncAction(
    (inp: GqlContactInput) => putContact(inp, profileId, [props.refetchQuery]),
    {
      onData(data) {
        if (data === 'pleaseProvideCredentials') {
          setShowSetupUserAccountDialog(true);
          return;
        }
        enqueueSnackbar('Saved!', { variant: 'success' });
        setDialogData(null);
      },
      onError(error) {
        enqueueSnackbar('Failed to save: ' + error, { variant: 'error' });
      },
    }
  );

  if (!dialogData) {
    return null;
  }

  const validation = validateForm();

  function doSave(password?: string | null) {
    const contactInput: GqlContactInput = {
      id: contact?.id,
      firstName: firstName,
      lastName: lastName,
      title: title,
      email: email,
      phoneMain: mainPhoneNumber,
      phoneCell: cellPhoneNumber,
      phoneAlternative: altPhoneNumber,
      extension: extension,
      locationId: location.id ? location.id : undefined,
      roleId: role.id ? role.id : undefined,

      isUserEnabled: isUserEnabled,
      credentials: password
        ? {
            email: email,
            username: email,
            password: password,
          }
        : undefined,
      notificationCodes: notificationCodes,
    };
    putContactAction.act(contactInput);
  }

  return (
    <div>
      <UniversalDialog
        open={true}
        title={contact ? 'Manage Contact' : 'New Contact'}
        small
        setClose={() => {
          setDialogData(null);
        }}
      >
        <Line height={1} />
        <Box marginY={1}>
          <FormRow>
            <AtomicTextFieldV2
              state={form.firstName}
              label="First Name"
              error={!!validation.firstName}
              helperText={validation.firstName}
              disabled={putContactAction.waiting}
            />
            <AtomicTextFieldV2
              state={form.lastName}
              label="Last Name"
              error={!!validation.lastName}
              helperText={validation.lastName}
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2
              state={form.title}
              label="Title"
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2
              state={form.email}
              label="Email"
              autoComplete="off"
              type="email"
              error={!!validation.email}
              helperText={validation.email}
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <FormRow>
            <AtomicPhoneNumberV2
              state={form.mainPhoneNumber}
              label="Main Phone Number"
              disabled={putContactAction.waiting}
            />
            <AtomicPhoneNumberV2
              state={form.cellPhoneNumber}
              label="Cell Phone Number"
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <FormRow>
            <AtomicPhoneNumberV2
              state={form.altPhoneNumber}
              label="Alt. Phone Number"
              disabled={putContactAction.waiting}
            />
            <AtomicTextFieldV2
              state={form.extension}
              label="Extension"
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <FormRow>
            <AtomicSelectorV2
              state={form.location}
              label="Location"
              optionsList={dialogData.locations}
              controllingField="id"
              displayField="name"
              disabled={putContactAction.waiting}
            />
          </FormRow>
          <Box marginX={1.5} marginBottom={2}>
            <AtomicSwitchV2
              state={form.isUserEnabled}
              label="User Enabled"
              disabled={putContactAction.waiting}
            />
          </Box>
          {isUserEnabled && (
            <FormRow>
              <AtomicSelectorV2
                state={form.role}
                label="Role"
                optionsList={dialogData.roles}
                controllingField="id"
                displayField="name"
                error={!!validation.role}
                helperText={validation.role}
                disabled={putContactAction.waiting}
              />
            </FormRow>
          )}
          {isUserEnabled && (
            <FormRow>
              <AtomicMultiSelectorV2<NotificationCode>
                state={form.notificationCodes}
                label="Notifications"
                optionsList={roles.find((x) => x.id === role.id)?.notificationCodes ?? []}
                valueResolver={(value) => value}
                displayResolver={notificationCodeNameLookup}
                disabled={putContactAction.waiting}
              />
            </FormRow>
          )}
        </Box>
        <UniversalDialogFooter error={putContactAction.error}>
          <Button
            variant="contained"
            color="default"
            size="large"
            onClick={() => {
              setDialogData(null);
            }}
            style={{ marginRight: '12px' }}
            disabled={putContactAction.waiting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!validation.hasChanged || !validation.isValid || putContactAction.waiting}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        </UniversalDialogFooter>
      </UniversalDialog>
      {showSetupUserAccountDialog && (
        <SetupUserAccountDialog
          email={email}
          onClose={() => setShowSetupUserAccountDialog(false)}
          onSave={(password) => {
            setShowSetupUserAccountDialog(false);
            doSave(password);
          }}
        />
      )}
    </div>
  );

  function validateForm(): FormValidationState {
    const validation: FormValidationState = {
      hasChanged: false,
      isValid: false,
      firstName: null,
      lastName: null,
      email: null,
      role: null,
    };

    const name = (firstName + ' ' + lastName).trim();
    if (name === '') {
      validation.firstName = 'Please provide a name';
      validation.lastName = 'Please provide a name';
    }

    if (contact) {
      const currLocationId = contact.location ? contact.location.id : '';
      const currRoleId = contact.role ? contact.role.id : '';

      validation.hasChanged =
        contact.firstName !== firstName ||
        contact.lastName !== lastName ||
        contact.email !== email ||
        contact.title !== title ||
        contact.phoneMain !== mainPhoneNumber ||
        contact.phoneCell !== cellPhoneNumber ||
        contact.phoneAlternative !== altPhoneNumber ||
        contact.extension !== extension ||
        contact.isUserEnabled !== isUserEnabled ||
        currLocationId !== location.id ||
        currRoleId !== role.id ||
        contact.notificationCodes !== notificationCodes;
    } else {
      // New contact
      validation.hasChanged = true;
    }

    if (isUserEnabled) {
      if (email.trim() === '') {
        validation.email = 'Required for user login.';
      } else if (!isValidEmail(email)) {
        validation.email = 'Invalid email.';
      }
      if (role.id === '') {
        validation.role = 'Required for user.';
      }
    }

    validation.isValid =
      validation.firstName == null &&
      validation.lastName == null &&
      validation.email == null &&
      validation.role == null;

    return validation;
  }
}

async function putContact(
  contactInput: GqlContactInput,
  profileId: string,
  refetchQueries: InternalRefetchQueriesInclude
): Promise<'success' | 'pleaseProvideCredentials'> {
  if (contactInput.id) {
    const res = await updateContact(
      {
        input: {
          contact: contactInput,
        },
      },
      { refetchQueries }
    );
    if (res.updateContact.pleaseProvideCredentials) {
      return 'pleaseProvideCredentials';
    }
  } else {
    const res = await newProfileContact(
      {
        input: {
          profileId: profileId,
          contact: contactInput,
        },
      },
      { refetchQueries }
    );
    if (res.newProfileContact.pleaseProvideCredentials) {
      return 'pleaseProvideCredentials';
    }
  }
  return 'success';
}
