import { gqlClient } from 'api/ApolloClient';
import {
  GqlMarkBookingAsHotMutation,
  GqlMarkBookingAsHotMutationVariables,
  MarkBookingAsHotDocument,
  PermissionCode,
} from 'api/GQL_Types';
import { auth } from 'app';
import { HotState, HotToggleSwitch } from 'components/HotToggleSwitch';
import * as React from 'react';

interface Props {
  bookingId: string;
  initialState: HotState;

  small?: boolean;
  showDetailsOnLeft?: boolean;
  showDetailsOnRight?: boolean;
}

export const HotBookingSwitch: React.FC<Props> = ({
  bookingId,
  initialState,
  small,
  showDetailsOnLeft,
  showDetailsOnRight,
}) => {
  const { userContext } = auth.useAuthState();

  return (
    <HotToggleSwitch
      initialState={initialState}
      save={(hot) => markBookingAsHot(bookingId, hot)}
      canMarkHot={!!userContext?.permissionCodes.has(PermissionCode.BookingMarkAsHot)}
      small={small}
      showDetailsOnLeft={showDetailsOnLeft}
      showDetailsOnRight={showDetailsOnRight}
    />
  );
};

async function markBookingAsHot(bookingId: string, hot: boolean): Promise<HotState> {
  const res = await gqlClient.mutate<
    GqlMarkBookingAsHotMutation,
    GqlMarkBookingAsHotMutationVariables
  >({
    mutation: MarkBookingAsHotDocument,
    variables: {
      input: {
        bookingId,
        hot,
      },
    },
  });
  const oo = res.data?.markBookingAsHot.booking;
  if (res.data?.markBookingAsHot.success && oo) {
    return oo;
  }
  throw new Error('Failed to mark Booking as hot. ' + (res.data?.markBookingAsHot.message || ''));
}
