import { Collapse, Switch, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';
import WarningIcon from '@material-ui/icons/Warning';
const classes = makeCss({
  root: {
    backgroundColor: '#F8F8F8',
    border: '1px solid ' + theme.palette.grayscale.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 2, 2, 2),
    '&$active': {
      borderColor: theme.palette.primary.main,
    },
    '&$active $title': {
      color: theme.palette.primary.main,
    },
    '&$active $description': {
      paddingBottom: theme.spacing(1),
      color: theme.typography.body1.color,
    },
  },
  active: {},
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
  title: {
    fontWeight: 900,
    userSelect: 'none',
    color: '#777',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: '#777',
    paddingBottom: 0, // needed for transition
    transition: theme.transitions.create('padding-bottom', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  body: {
    paddingTop: theme.spacing(0.5),
    overflow: 'auto',
  },
});

interface Props {
  title: string;
  warningText?: string;
  description?: string;
  checked: boolean;
  onChange(checked: boolean): void;
  disabled?: boolean;
}

export const SwitchCard: React.FC<Props> = ({
  title,
  warningText,
  description,
  checked,
  onChange,
  disabled,
  children,
}) => {
  return (
    <div className={clsx(classes.root, checked && classes.active)}>
      <div
        className={classes.titleRow}
        onClick={
          disabled
            ? undefined
            : (e) => {
                onChange(!checked);
              }
        }
      >
        <div className={classes.title}>
          {warningText && (
            <Tooltip title={warningText} interactive style={{ marginRight: theme.spacing(0.5) }}>
              <WarningIcon />
            </Tooltip>
          )}
          {title}
        </div>

        <Switch
          color="primary"
          checked={checked}
          onChange={
            disabled
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  onChange(e.target.checked);
                }
          }
          disabled={disabled}
        />
      </div>
      {description && <div className={classes.description}>{description}</div>}
      <Collapse in={checked}>
        <div className={classes.body}>{children}</div>
      </Collapse>
    </div>
  );
};
