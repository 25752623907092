import React from 'react';
import { atom, atomFamily, RecoilState, Resetter, useResetRecoilState } from 'recoil';

export function newAtom<T extends any>(defaultValue: T): RecoilState<T> {
  return atom({ key: genKey(), default: defaultValue });
}

export function newAtomFamily<T extends {}>(defaultValue: T) {
  return atomFamily({ key: genKey(), default: defaultValue });
}

let nextKey = 0;
export function genKey(): string {
  return `atom${nextKey++}`;
}

interface AtomCleanerProps {
  // List of atoms that need to be reset when the component un mounts.
  atoms: RecoilState<any>[];
}

export function AtomCleaner(props: AtomCleanerProps) {
  const resetters: Resetter[] = [];
  for (const atom of props.atoms) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const setter = useResetRecoilState(atom);
    resetters.push(setter);
  }

  React.useEffect(() => {
    return () => {
      for (const reset of resetters) {
        reset();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

type Molecule = {
  [key: string]: RecoilState<any> | Molecule;
};

export type DateAtomType = Date | null | undefined;

interface AtomGroupCleanerProps {
  // Data structure of atoms that need to be reset when the component un mounts. Atoms must be flat
  atomGroup: Molecule;
}

export function AtomGroupCleaner(props: AtomGroupCleanerProps) {
  const reset = useResetAtomGroupState(props.atomGroup);

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return null;
}

export function useResetAtomGroupState(atomGroup: Molecule): Resetter {
  const resetters = getResetters(atomGroup);
  return () => {
    for (const reset of resetters) {
      reset();
    }
  };
}

function getResetters(atomGroup: Molecule): Resetter[] {
  const resetters: Resetter[] = [];
  for (const atom of Object.values(atomGroup)) {
    if (atom.key) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const setter = useResetRecoilState(atom as RecoilState<any>);
      resetters.push(setter);
    } else {
      resetters.push(...getResetters(atom as Molecule));
    }
  }
  return resetters;
}
