import { PartyType } from 'api/GQL_Types';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import { MarkAsReadCell } from 'app/dashboard/components/MarkAsReadCell';
import { PopoverTable } from 'app/dashboard/components/PopoverTable';
import { DashboardPageStates, TableLink } from 'app/dashboard/states';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { AlertButton } from '../../components/AlertButton';
import { BaseAlert, NotificationResult, useMarkNotificationsAsRead } from '../states';

export interface NewDocumentNotificationDisplay extends BaseAlert {
  id: string;
  unread: boolean;
  bookingNumber: TableLink;
  documentType: string;
  uploadDate: Date | null | undefined;
  shipperName: string;
  hblNumber: TableLink;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  deliveryLocation: string;
}

const columns: UWLTableColumn<NewDocumentNotificationDisplay>[] = [
  { id: 'unread', label: '!', type: 'string' },
  { id: 'bookingNumber', label: 'Booking #', type: 'link' },
  { id: 'documentType', label: 'Doc Type', type: 'string' },
  { id: 'uploadDate', label: 'Upload Date', type: 'date' },
  { id: 'shipperName', label: 'Shipper Name', type: 'string' },
  { id: 'hblNumber', label: 'HBL #', type: 'link' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'deliveryLocation', label: 'Place of Delivery', type: 'string' },
];

const exportColumns = columns.filter((c) => c.id !== 'unread');

export default function NewDocumentsButton() {
  const newDocumentAlerts = useRecoilValue(DashboardPageStates.notifications.newDocuments);

  const refAnchor = React.useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const unreadCount = newDocumentAlerts.filter((row) => row.unread).length;
  const windowSize = useWindowSize();
  const markAsRead = useMarkNotificationsAsRead();

  return (
    <>
      <AlertButton
        innerRef={refAnchor}
        label="New Documents"
        quantity={newDocumentAlerts.length}
        onClick={(e) => setOpen(true)}
      />
      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="New Documents"
        titleRight={
          <>
            <TableExportWindowGroup
              label="New Documents"
              rows={newDocumentAlerts}
              columns={exportColumns}
            />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of newDocumentAlerts) {
                    if (row.unread && row.id) {
                      unreadAlertIds.push(row.id);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total New Documents"
        totalValue={formatNumber(newDocumentAlerts.length)}
        width={windowSize.width * 0.8}
        bodyHeight={newDocumentAlerts.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={newDocumentAlerts}
            columns={columns}
            emptyMessage="No New Documents"
            virtualize={'single-line-cells'}
            renderCell={{
              unread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.unread}
                    notificationId={row.id}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
}

export function NewDocumentNotificationDisplay_toRow(
  alert: NotificationResult
): NewDocumentNotificationDisplay | null {
  if (alert.__typename !== 'NewDocumentNotification') {
    return null;
  }
  return {
    id: alert.id,
    unread: true,
    bookingNumber: {
      to: '/bookings/' + alert.booking.id,
      value: alert.booking.referenceNumber ?? '',
    },
    documentType: alert.document.documentType,
    uploadDate: alert.document.uploadDate,
    shipperName: relatedPartyNameOrVarious(alert.booking.relatedParties, PartyType.Shipper),
    hblNumber: {
      to: '/hbl/' + alert.booking.hbl?.id,
      value: alert.booking.hbl?.referenceNumber ?? '',
    },
    pol: portToStringMaybe(alert.booking.logistics.pol),
    polEtd: alert.booking.logistics.polEtd,
    polAtd: alert.booking.logistics.polAtd,
    pod: portToStringMaybe(alert.booking.logistics.pod),
    podEta: alert.booking.logistics.podEta,
    deliveryLocation: alert.booking.logistics.deliveryLocation?.name ?? '',
  };
}
