import { Box, Button, Popover, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useAllUsersQuery } from 'api/GQL_Types';
import { ManagePageHeader_BackStack } from 'app/admin/components/ManagePageHeader';
import { CheckOnOffIcon } from 'components/CheckOnOffIcon';
import ErrorMessage from 'components/ErrorMessage';
import { PanelBase } from 'components/Panel';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import SearchBar from 'components/SearchBar';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';

interface UserRow {
  id: string;
  name: string;
  email: string;
  network: string;
  contexts: string[];
  isActive: boolean;
  isAdmin: boolean;

  filterKey: string; // The text rows can be filtered by with the quick search
}

const columns: UWLTableColumn<UserRow>[] = [
  { id: 'name', label: 'Name', type: 'string' },
  { id: 'email', label: 'Email', type: 'string' },
  { id: 'network', label: 'Network', type: 'string' },
  { id: 'isActive', label: 'Active', type: 'string' },
  { id: 'isAdmin', label: 'Admin', type: 'string' },
];

interface UserListProps extends RouteComponentProps {}
export default function UserList(props: UserListProps) {
  const setBackStack = useSetRecoilState(ManagePageHeader_BackStack);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<string[]>([]);

  const [users, setUsers] = React.useState<UserRow[]>([]);
  const [searchField, setSearchField] = React.useState<string>('');

  const { loading, error } = useAllUsersQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setUsers(
        data.users.map((user): UserRow => {
          const contexts: string[] = [];
          for (const cnt of user.profileContacts) {
            if (cnt.isUserEnabled) {
              const name = `${cnt.profile.network.name}: ${cnt.profile.name}`;
              if (!contexts.includes(name)) {
                contexts.push(name);
              }
            }
          }
          contexts.sort();

          const name = `${user.title ?? ''} ${user.fullName ?? ''}`.trim();
          const network = user.defaultContact.profile.network.name;

          return {
            id: user.id,
            name,
            email: user.email,
            network,
            contexts: contexts,
            isActive: user.isActive,
            isAdmin: user.systemPermissionCodes.length > 0,
            filterKey: `${name}|${user.email}|${network}`.toLowerCase(),
          };
        })
      );
    },
  });

  const searchFieldLc = searchField.toLowerCase();
  const filteredUsers = users.filter((u) => u.filterKey.includes(searchFieldLc));

  return (
    <div
      style={{
        padding: theme.spacing(2, 3, 0, 3),
        height: '100%',
      }}
    >
      <RemainingHeightLayout
        top={
          <div>
            <Box display="flex" justifyContent="space-between" marginBottom={3}>
              <Box>
                <Typography variant="h2">Users</Typography>
                <Typography variant="body1">
                  {loading ? 'loading...' : `${filteredUsers.length} Total`}
                </Typography>
              </Box>
              <Box width="25%" bgcolor="#FFFFFF" padding={1} alignSelf="center" borderRadius="4px">
                <SearchBar
                  placeholder="Search Users"
                  field={searchField}
                  updateField={setSearchField}
                />
              </Box>
            </Box>
            <ErrorMessage error={error ? error + '' : null} />
          </div>
        }
        bottomMinHeight={300}
        bottom={
          <PanelBase>
            <div style={{ height: theme.spacing(1) }} />
            <UWLTable
              rowId="id"
              columns={columns}
              rows={filteredUsers}
              emptyMessage="- No Users -"
              noWrapAllCells
              virtualize={{
                rowHeight(row) {
                  return 32;
                },
                bufferHeight: 150,
              }}
              renderCell={{
                network(row) {
                  return (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setPopoverContent(row.contexts);
                      }}
                    >
                      {row.network} ({row.contexts.length})
                    </Button>
                  );
                },
                isActive(row) {
                  return <CheckOnOffIcon on={row.isActive} />;
                },
                isAdmin(row) {
                  return <CheckOnOffIcon on={row.isAdmin} />;
                },
              }}
              rowAction={(row) => {
                return (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setBackStack([{ label: 'User List', path: '/sys-admin/users' }]);
                      navigate('sys-admin/users/' + row.id);
                    }}
                  >
                    Manage
                  </Button>
                );
              }}
            />
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
            >
              <div style={{ padding: theme.spacing(1, 1.5, 0, 1.5) }}>
                {popoverContent.map((c, i) => {
                  return (
                    <div key={i} style={{ padding: theme.spacing(0, 0, 1, 0) }}>
                      {c}
                    </div>
                  );
                })}
              </div>
            </Popover>
          </PanelBase>
        }
      />
    </div>
  );
}
