import { InternalRefetchQueriesInclude } from '@apollo/client';
import { Box, Button, Container } from '@material-ui/core';
import {
  DashboardFeatureCode,
  GqlRoleInput,
  GqlUpdateRoleMutation,
  GqlUpdateRoleMutationVariables,
  NotificationCode,
  PermissionCode,
  SystemPermissionCode,
} from 'api/GQL_Types';
import { newProfileRole, ProfileQuery } from 'api/queries/profileQueries';
import { newProfileTypeDefaultRole, ProfileTypeQuery } from 'api/queries/profileTypeQueries';
import { updateRole } from 'api/queries/roleQueries';
import { auth } from 'app';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { DeleteButton } from 'components/DeleteButton';
import { DialogForm, Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { AsyncAction, useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AtomCleaner, genKey, newAtom } from 'lib/RecoilUtils';
import { ProfileTypePageStates } from '../profile-types/manage/states';
import { ProfilePageStates } from '../profiles/manage/states';
import { DashboardFeatureSwitches } from './DashboardFeatureSwitches';
import { DocumentTypeSwitches } from './DocumentTypeSwitches';
import NotificationCodeSwitches from './NotificationCodeSwitches';
import PermissionsComponent from './PermissionsComponent';
import { RoleDeleteDialog, RoleDeleteDialog_Data } from './RoleDeleteDialog';

interface RoleDialogSetupData {
  id: string | null; // null if it's a new Role
  name: string;
  description: string;
  permissions: PermissionCode[];
  notificationCodes: NotificationCode[];
  dashboardFeatureCodes: DashboardFeatureCode[];
  documentTypesCanView: string[];

  title: string;
  subtitle: string;
}

export const RoleDialog_Data = newAtom<RoleDialogSetupData | null>(null);

const roleNameState = newAtom('');
const roleDescriptionState = newAtom('');
const rolePermissionsState = newAtom<PermissionCode[]>([]);
const roleDocumentTypesCanView = newAtom<string[]>([]);
const roleAlertsState = newAtom<NotificationCode[]>([]);
const roleDashFeaturesState = newAtom<DashboardFeatureCode[]>([]);

const formValidationState = selector<string | null>({
  key: genKey(),
  get: ({ get }) => {
    const name = get(roleNameState);
    const permissions = get(rolePermissionsState);
    const notificationCodes = get(roleAlertsState);
    const dashFeatures = get(roleDashFeaturesState);

    if (name.trim() === '') {
      return 'Please enter a name';
    }
    if (permissions.length === 0) {
      return 'Please select permissions.';
    }
    if (notificationCodes.length === 0) {
      return 'Please select notifications.';
    }
    if (dashFeatures.length === 0) {
      return 'Please select dashboard features.';
    }
    return null;
  },
});

interface Props {
  id: string;
  type: 'Profile' | 'Profile Type';
}

export default function RoleDialogComponent(props: Props) {
  const { userContext } = auth.useAuthState();
  const { enqueueSnackbar } = useSnackbar();
  const [role, setRoleDialog] = useRecoilState(RoleDialog_Data);
  const setRoleNameState = useSetRecoilState(roleNameState);
  const setRoleDescriptionState = useSetRecoilState(roleDescriptionState);
  const [rolePermissions, setRolePermissions] = useRecoilState(rolePermissionsState);
  const [roleAlerts, setRoleAlerts] = useRecoilState(roleAlertsState);
  const [dashFeatureCodes, setDashFeatureCodes] = useRecoilState(roleDashFeaturesState);
  const [roleDocTypesViewable, setRoleDocTypesViewable] = useRecoilState(roleDocumentTypesCanView);

  const profileType = useRecoilValue(ProfileTypePageStates.profileType);
  const profileTypePermissions = profileType?.permissionCodes || [];
  const profileTypeAlerts = profileType?.notificationCodes || [];
  const profileTypeDashFeatureCodes = profileType?.dashboardFeatureCodes || [];

  const profile = useRecoilValue(ProfilePageStates.profile);
  const profilePermissions = profile?.profileType?.permissionCodes || [];
  const profileAlerts = profile?.profileType?.notificationCodes || [];
  const profileDashFeatureCodes = profile?.profileType?.dashboardFeatureCodes || [];

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: props.type === 'Profile' ? ProfileQuery : ProfileTypeQuery,
      variables: props.type === 'Profile' ? { profileId: props.id } : { profileTypeId: props.id },
    },
  ];

  const newRoleAction = useAsyncAction<GqlRoleInput, void>(
    async (role: GqlRoleInput) => {
      if (props.type === 'Profile') {
        await newProfileRole({ input: { profileId: props.id, role } }, { refetchQueries });
      } else {
        await newProfileTypeDefaultRole(
          { input: { profileTypeId: props.id, role } },
          { refetchQueries }
        );
      }
    },
    {
      onData(data) {
        setRoleDialog(null);
        enqueueSnackbar('Role Created.', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to create role: ' + error, { variant: 'error' });
      },
    }
  );

  const updateRoleAction = useAsyncAction(
    (variables: GqlUpdateRoleMutationVariables) => updateRole(variables, { refetchQueries }),
    {
      onData(data) {
        setRoleDialog(null);
        enqueueSnackbar('Role Updated.', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to update role: ' + error, { variant: 'error' });
      },
    }
  );

  React.useEffect(() => {
    setRoleNameState(role?.name || '');
    setRoleDescriptionState(role?.description || '');
    setRolePermissions(role?.permissions || []);
    setRoleAlerts(role?.notificationCodes || []);
    setDashFeatureCodes(role?.dashboardFeatureCodes || []);
    setRoleDocTypesViewable(role?.documentTypesCanView || []);

    newRoleAction.reset();
    updateRoleAction.reset();
  }, [role]);

  let canDelete = false;
  if (!role?.id) {
    // obviously, you can't delete a role that is being created
  } else {
    if (props.type === 'Profile Type') {
      canDelete = profileType?.isTemplate
        ? !!userContext?.systemPermissionCodes.has(
            SystemPermissionCode.ProfileTypeTemplatesRoleDelete
          )
        : !!userContext?.permissionCodes.has(PermissionCode.ProfileTypeRoleDelete) && false; // can't currently do this
    } else {
      canDelete = !!userContext?.permissionCodes.has(PermissionCode.ProfileRoleDelete) && false; // can't currently do this
    }
  }

  return (
    <UniversalDialog
      open={!!role}
      title={role?.title || ''}
      subTitle={role?.subtitle || ''}
      large
      setClose={() => {
        setRoleDialog(null);
      }}
    >
      <AtomCleaner
        atoms={[
          RoleDialog_Data,
          roleNameState,
          roleDescriptionState,
          rolePermissionsState,
          roleAlertsState,
          roleDashFeaturesState,
        ]}
      />
      <Line height={1} />
      <Container>
        <Box marginBottom={2}>
          <DialogForm>
            <AtomicTextFieldV2 state={roleNameState} label="Role Name" />
          </DialogForm>
          <DialogForm>
            <AtomicTextFieldV2
              state={roleDescriptionState}
              label="Role Description"
              multiline
              rows={5}
            />
          </DialogForm>
          <DialogForm>
            <PermissionsComponent
              permissionsToDisplay={
                props.type === 'Profile' ? profilePermissions : profileTypePermissions
              }
              permissionsSet={rolePermissions}
              onPermissionToggle={(perm, checked) => {
                let newPermissions: PermissionCode[];
                if (rolePermissions) {
                  newPermissions = [...rolePermissions];
                } else {
                  newPermissions = [];
                }
                if (checked) {
                  newPermissions.push(perm);
                } else {
                  newPermissions = rolePermissions.filter((p) => p !== perm);
                }
                setRolePermissions(newPermissions);
              }}
            />
          </DialogForm>
          <DialogForm>
            <DocumentTypeSwitches
              values={roleDocTypesViewable}
              onValues={setRoleDocTypesViewable}
            />
          </DialogForm>
          <DialogForm>
            <NotificationCodeSwitches
              options={props.type === 'Profile' ? profileAlerts : profileTypeAlerts}
              values={roleAlerts}
              onValues={setRoleAlerts}
            />
          </DialogForm>
          <DialogForm>
            <DashboardFeatureSwitches
              options={
                props.type === 'Profile' ? profileDashFeatureCodes : profileTypeDashFeatureCodes
              }
              values={dashFeatureCodes}
              onValues={setDashFeatureCodes}
            />
          </DialogForm>
        </Box>
      </Container>
      <UniversalDialogFooter
        error={newRoleAction.error || updateRoleAction.error}
        leftBtns={
          canDelete &&
          role?.id &&
          profileType && <DeleteRoleButton roleId={role.id} profileTypeId={profileType.id} />
        }
      >
        <CancelButton />
        {role?.id ? (
          <UpdateRoleButton updateRoleAction={updateRoleAction} />
        ) : (
          <SaveRoleButton newRoleAction={newRoleAction} />
        )}
      </UniversalDialogFooter>
      <RoleDeleteDialog />
    </UniversalDialog>
  );
}

function SaveRoleButton({ newRoleAction }: { newRoleAction: AsyncAction<GqlRoleInput, void> }) {
  const roleName = useRecoilValue(roleNameState);
  const roleDescription = useRecoilValue(roleDescriptionState);
  const rolePermissions = useRecoilValue(rolePermissionsState);
  const roleAlerts = useRecoilValue(roleAlertsState);
  const dashFeatureCodes = useRecoilValue(roleDashFeaturesState);
  const documentTypesCanView = useRecoilValue(roleDocumentTypesCanView);
  const validation = useRecoilValue(formValidationState);

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={validation !== null}
      onClick={() => {
        newRoleAction.act({
          id: null,
          name: roleName,
          description: roleDescription,
          permissionCodes: rolePermissions,
          notificationCodes: roleAlerts,
          dashboardFeatureCodes: dashFeatureCodes,
          documentTypesCanView,
        });
      }}
    >
      Create Role
    </Button>
  );
}

function UpdateRoleButton({
  updateRoleAction,
}: {
  updateRoleAction: AsyncAction<GqlUpdateRoleMutationVariables, GqlUpdateRoleMutation>;
}) {
  const role = useRecoilValue(RoleDialog_Data);
  const roleName = useRecoilValue(roleNameState);
  const roleDescription = useRecoilValue(roleDescriptionState);
  const rolePermissions = useRecoilValue(rolePermissionsState);
  const roleAlerts = useRecoilValue(roleAlertsState);
  const dashFeatureCodes = useRecoilValue(roleDashFeaturesState);
  const documentTypesCanView = useRecoilValue(roleDocumentTypesCanView);
  const validation = useRecoilValue(formValidationState);

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={validation !== null}
      onClick={() => {
        if (!role) return;
        updateRoleAction.act({
          input: {
            role: {
              id: role.id,
              name: roleName,
              description: roleDescription,
              permissionCodes: rolePermissions,
              notificationCodes: roleAlerts,
              dashboardFeatureCodes: dashFeatureCodes,
              documentTypesCanView,
            },
          },
        });
      }}
    >
      Update Role
    </Button>
  );
}

function CancelButton() {
  const setRoleDialog = useSetRecoilState(RoleDialog_Data);

  return (
    <Button
      variant="contained"
      color="default"
      size="large"
      onClick={() => {
        setRoleDialog(null);
      }}
    >
      Cancel
    </Button>
  );
}

const DeleteRoleButton: React.FC<{ roleId: string; profileTypeId: string }> = ({
  roleId,
  profileTypeId,
}) => {
  const setRoleDeleteDialog = useSetRecoilState(RoleDeleteDialog_Data);

  return (
    <DeleteButton
      size="large"
      onClick={() => {
        setRoleDeleteDialog({
          roleId,
          profileTypeId,
        });
      }}
    >
      Delete Role
    </DeleteButton>
  );
};
