import { faClock } from '@fortawesome/free-solid-svg-icons';
import { markNotificationsAsRead } from 'api/queries/dashboardQueries';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { ExceptionQueryResult } from '.';
import { MarkAsReadCell } from '../../components/MarkAsReadCell';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';
import { Link } from '@reach/router';

export interface PodEtaException {
  id: string;
  notificationId: string | null;
  isUnread: boolean;
  shipmentNumber: TableLink;
  bolNumber: TableLink;
  newPodEta: Date | null | undefined;
  oldPodEta: Date | null | undefined;
  pod: string;
  deliveryLocation: string;

  container: TableLink;
}

export interface PodEtaExceptionGrouped {
  id: string;
  notificationId: string | null;
  isUnread: boolean;
  shipmentNumber: TableLink;
  bolNumber: TableLink;
  newPodEta: Date | null | undefined;
  oldPodEta: Date | null | undefined;
  pod: string;
  deliveryLocation: string;

  containers: TableLink[];
}

export function PodEtaException_toRow(exception: ExceptionQueryResult): PodEtaException[] | null {
  if (exception.__typename !== 'PodEtaException') {
    return null;
  }
  const shipment = exception.shipment;
  const logistics = shipment.logistics;
  let rows: PodEtaException[] = [];

  for (const container of shipment.containers) {
    rows.push({
      id: shipment.id,
      notificationId: exception.notificationId || null,
      isUnread: exception.isUnread,

      shipmentNumber: { to: '/bookings/' + shipment.id, value: shipment.referenceNumber },
      bolNumber:
        shipment.__typename === 'Booking' && shipment.hbl
          ? { to: `/hbl/${shipment.hbl.id ?? ''}`, value: shipment.hbl.referenceNumber ?? '' }
          : shipment.mbl
          ? { to: `/mbl/${shipment.mbl.id ?? ''}`, value: shipment.mbl.referenceNumber ?? '' }
          : { to: '', value: '' },
      container: { to: '/equipment/' + container.id, value: container.containerNumber ?? '' },
      newPodEta: logistics.podEta,
      oldPodEta: exception.oldPodEta,
      pod: logistics.pod?.name ?? '',
      deliveryLocation: logistics.deliveryLocation?.name ?? '',
    });
  }

  return rows;
}

export function PodEtaException_toGroupedRow(
  exception: ExceptionQueryResult
): PodEtaExceptionGrouped | null {
  if (exception.__typename !== 'PodEtaException') {
    return null;
  }
  const shipment = exception.shipment;
  const logistics = shipment.logistics;

  return {
    id: shipment.id,
    notificationId: exception.notificationId || null,
    isUnread: exception.isUnread,

    shipmentNumber: { to: '/bookings/' + shipment.id, value: shipment.referenceNumber },
    bolNumber:
      shipment.__typename === 'Booking' && shipment.hbl
        ? { to: `/hbl/${shipment.hbl.id ?? ''}`, value: shipment.hbl.referenceNumber ?? '' }
        : shipment.mbl
        ? { to: `/mbl/${shipment.mbl.id ?? ''}`, value: shipment.mbl.referenceNumber ?? '' }
        : { to: '', value: '' },
    containers: shipment.containers.map((container) => {
      return { to: '/equipment/' + container.id, value: container.containerNumber ?? '' };
    }),
    newPodEta: logistics.podEta,
    oldPodEta: exception.oldPodEta,
    pod: logistics.pod?.name ?? '',
    deliveryLocation: logistics.deliveryLocation?.name ?? '',
  };
}

const columns: UWLTableColumn<PodEtaExceptionGrouped>[] = [
  { id: 'isUnread', label: '!', type: 'bool' },
  { id: 'shipmentNumber', label: 'Shipment', type: 'link' },
  { id: 'bolNumber', label: 'BOL', type: 'link' },
  { id: 'containers', label: 'Equipment', type: 'link' },
  { id: 'newPodEta', label: 'New POD ETA', type: 'date' },
  { id: 'oldPodEta', label: 'Prior POD ETA', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
];

const displayColumnsUnread = columns.map((c) => c.id);
const displayColumnsAllRead = displayColumnsUnread.filter((id) => id !== 'isUnread');

const exportColumns: UWLTableColumn<PodEtaException>[] = [
  { id: 'shipmentNumber', label: 'Shipment', type: 'link' },
  { id: 'bolNumber', label: 'BOL', type: 'link' },
  { id: 'container', label: 'Equipment', type: 'link' },
  { id: 'newPodEta', label: 'New POD ETA', type: 'date' },
  { id: 'oldPodEta', label: 'Prior POD ETA', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
];

interface Props {}

export const PodEtaExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const [exceptions, setExceptions] = useRecoilState(DashboardPageStates.exceptions);
  const unreadCount = exceptions.podEtaGrouped.filter((row) => row.isUnread).length;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="ETA Change"
        unreadCount={unreadCount}
        resultCount={exceptions.podEtaGrouped.length}
        faIcon={faClock}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="ETA Change"
        titleRight={
          <>
            <TableExportWindowGroup
              label="ETA Change"
              rows={exceptions.podEta}
              columns={exportColumns}
            />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of exceptions.podEta) {
                    if (row.isUnread && row.notificationId) {
                      unreadAlertIds.push(row.notificationId);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total ETA Changes"
        totalValue={formatNumber(exceptions.podEtaGrouped.length)}
        width={windowSize.width * 0.8}
        bodyHeight={exceptions.podEtaGrouped.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={exceptions.podEtaGrouped}
            columns={columns}
            columnsDisplay={unreadCount > 0 ? displayColumnsUnread : displayColumnsAllRead}
            emptyMessage="No POD ETA"
            virtualize={'single-line-cells'}
            renderCell={{
              isUnread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.isUnread}
                    notificationId={row.notificationId}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
              containers(row) {
                switch (row.containers.length) {
                  case 0:
                    return '';
                  case 1:
                    return <Link to={row.containers[0].to}>{row.containers[0].value}</Link>;
                }
                return 'Various';
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );

  function markAsRead(notificationIds: string[]) {
    markNotificationsAsRead(notificationIds);
    setExceptions((e) => {
      return {
        ...e,
        podEta: e.podEta.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
        podEtaGrouped: e.podEtaGrouped.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
      };
    });
  }
};
