import { RouteComponentProps } from '@reach/router';
import NewConsolidationHeader from './header';
import NewConsolidationStep1 from './step-1';
import NewConsolidationStep2 from './step-2';
import { NewConsolidationStates } from './states';
import { useRecoilValue } from 'recoil';
import { AtomGroupCleaner } from 'lib/RecoilUtils';
import { Box } from '@material-ui/core';

export interface NewConsolidationProps extends RouteComponentProps {}

export default function NewConsolidation(props: NewConsolidationProps) {
  return (
    <Box height={1} display="flex" flexDirection="column">
      <AtomGroupCleaner atomGroup={NewConsolidationStates} />
      <NewConsolidationHeader />
      <Box display="flex" flexGrow={1}>
        <ViewSwitcher />
      </Box>
    </Box>
  );
}

function ViewSwitcher() {
  const activeStep = useRecoilValue(NewConsolidationStates.activeStep);
  return (
    <>
      {activeStep === 0 && <NewConsolidationStep1 />}
      {activeStep === 1 && <NewConsolidationStep2 />}
    </>
  );
}
