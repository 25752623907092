import { Box, Typography } from '@material-ui/core';
import { DashboardFeatureCode, GqlPackLine } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import {
  DashboardBooking,
  DashboardContainer,
  DashboardPageStates,
  DashboardSkuItem,
  transformBookingToDashboardBookingDisplay,
  transformContainerToDashboardContainerDisplay,
  transformPackLineToSkuItemsDisplay,
} from 'app/dashboard/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { formatTime } from 'types/Date';
import { formatCurrency } from 'types/Monetary';
import { formatNumber } from 'types/Number';
import { addPackLineTotals } from '.';
import { BookingsPopover } from '../dashboard-bookings-popovers/BookingsPopover';
import { ContainersPopover } from '../dashboard-bookings-popovers/ContainersPopover';
import { InvoicePopover } from '../dashboard-bookings-popovers/InvoicePopover';
import { SkuLineItemPopover } from '../dashboard-bookings-popovers/SkuLineItemPopover';

interface Props {
  rowHeight: number;
  colWidth: number;
}

export default function BookingBarLabels(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const weeks = useRecoilValue(DashboardPageStates.booking.bookingSummaries);

  return (
    <div
      style={{
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
        minWidth: 0, // NOTE: Don't remove this. This is a silly hack that tells flexbox to not not let this div overflow the panel when the children have their own scroll area.
        flex: '1 1 300px',
      }}
    >
      {weeks &&
        weeks.map((week, index) => {
          const year1 = formatTime(week.start, 'yyyy');
          const year2 = formatTime(week.end, 'yyyy');
          const yearLabel: string = year1 === year2 ? year1 : year1 + ' - ' + year2;
          const weekLabel = 'WK' + week.weekNumber;
          const popoverLabel = `WEEK ${week.weekNumber}: (${
            formatTime(week.start, 'LLL dd') + ' - ' + formatTime(week.end, 'LLL dd')
          }) ${yearLabel}`;

          return (
            <Box flexGrow={1} key={index}>
              {userContext?.dashboardFeatureCodes.has(
                DashboardFeatureCode.ChartBookingsByWeekDetailsContainers
              ) && (
                <ContainerCell
                  rowHeight={props.rowHeight}
                  colWidth={props.colWidth}
                  index={index}
                  label={formatNumber(week.containers.length)}
                  popoverLabel={popoverLabel}
                  containers={transformContainerToDashboardContainerDisplay(week.containers)}
                />
              )}
              {userContext?.dashboardFeatureCodes.has(
                DashboardFeatureCode.ChartBookingsByWeekDetailsSku
              ) && (
                <SkuLineItemCell
                  rowHeight={props.rowHeight}
                  colWidth={props.colWidth}
                  index={index}
                  label={formatNumber(week.skuPackLines.length)}
                  popoverLabel={popoverLabel}
                  rows={transformPackLineToSkuItemsDisplay(week.skuPackLines)}
                />
              )}
              {userContext?.dashboardFeatureCodes.has(
                DashboardFeatureCode.ChartBookingsByWeekDetailsBookings
              ) && (
                <BookingsCell
                  rowHeight={props.rowHeight}
                  colWidth={props.colWidth}
                  index={index}
                  label={formatNumber(week.bookings.length)}
                  popoverLabel={popoverLabel}
                  rows={transformBookingToDashboardBookingDisplay(week.bookings as any)}
                />
              )}
              {userContext?.dashboardFeatureCodes.has(
                DashboardFeatureCode.ChartBookingsByWeekDetailsCommercialValue
              ) && (
                <InvoiceCell
                  rowHeight={props.rowHeight}
                  colWidth={props.colWidth}
                  index={index}
                  label={formatCurrency(addPackLineTotals(week.invoicePackLines))}
                  popoverLabel={popoverLabel}
                  rows={week.invoicePackLines}
                />
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: props.colWidth + 'px',
                  height: '80px',
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <span
                    style={{
                      color: '#63636d',
                      fontSize: 16,
                      fontWeight: 800,
                    }}
                  >
                    {weekLabel}
                  </span>
                  <span
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {formatTime(week.start, 'LL/dd') + ' - ' + formatTime(week.end, 'LL/dd')}
                  </span>
                  <span
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {yearLabel}
                  </span>
                </Box>
              </Box>
            </Box>
          );
        })}
    </div>
  );
}

interface CenterLabelBoxBaseProps {
  label: string;
  index: number;
  rowHeight: number;
  colWidth: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CenterLabelBoxBase: React.FC<CenterLabelBoxBaseProps> = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: props.index % 2 === 0 ? 1 : 0.8,
        backgroundColor: theme.palette.secondary.main,
        height: props.rowHeight + 'px',
        minWidth: props.colWidth + 'px',
        marginTop: theme.spacing(0.75),
        marginBottom: theme.spacing(0.75),
        cursor: 'pointer',
      }}
    >
      <Typography variant="h2" color="textSecondary">
        {props.label}
      </Typography>
    </div>
  );
};

interface ContainerCellProps extends CenterLabelBoxBaseProps {
  containers: DashboardContainer[];
  popoverLabel: string;
}

const ContainerCell: React.FC<ContainerCellProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <CenterLabelBoxBase
        {...props}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      />
      <ContainersPopover
        title={props.popoverLabel}
        containers={props.containers}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

interface SkuLineItemCellProps extends CenterLabelBoxBaseProps {
  rows: DashboardSkuItem[];
  popoverLabel: string;
}

const SkuLineItemCell: React.FC<SkuLineItemCellProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <CenterLabelBoxBase
        {...props}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      />
      <SkuLineItemPopover
        title={props.popoverLabel}
        rows={props.rows}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

interface BookingsCellProps extends CenterLabelBoxBaseProps {
  rows: DashboardBooking[];
  popoverLabel: string;
}

const BookingsCell: React.FC<BookingsCellProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <CenterLabelBoxBase
        {...props}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      />
      <BookingsPopover
        title={props.popoverLabel}
        rows={props.rows}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

interface InvoiceCellProps extends CenterLabelBoxBaseProps {
  rows: GqlPackLine[];
  popoverLabel: string;
}

const InvoiceCell: React.FC<InvoiceCellProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <CenterLabelBoxBase
        {...props}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      />
      <InvoicePopover
        title={props.popoverLabel}
        packLines={props.rows}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
