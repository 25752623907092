import { ContainerMode, GqlCarrier, GqlPort, GqlRelatedParty, MoveType } from 'api/GQL_Types';
import { EquipmentSelection } from 'components/EquipmentSelectionPanel';
import { DateRangeValue } from 'components/form/FormInputDateRange';
import { selector, useSetRecoilState } from 'recoil';
import { DateAtomType, genKey, newAtom } from 'lib/RecoilUtils';
import { ConsolableBookingRow } from '../states';

export const NewConsolidationStates = {
  activeStep: newAtom<0 | 1>(0),
  newConsolidation: newAtom<{
    consolidationId: string;
    referenceNumber: string;
  } | null>(null),

  step1: {
    bookings: newAtom<ConsolableBookingRow[]>([]),
    filteredBookings: newAtom<ConsolableBookingRow[]>([]),
    bookingsChecked: newAtom<string[]>([]),
    selectedBookings: newAtom<ConsolableBookingRow[]>([]),
    equipmentList: newAtom<EquipmentSelection[]>([]),
    cfsLocations: newAtom<LocationOption[]>([]),
  },

  step2: {
    formBookingParty: newAtom<string>(''),
    formConsignee: newAtom<GqlRelatedParty | null>(null),
    formConsigneeField: newAtom<string>(''),
    formSupplier: newAtom<GqlRelatedParty | null>(null),
    formSupplierField: newAtom<string>(''),
    formManufacturer: newAtom<GqlRelatedParty | null>(null),
    formManufacturerField: newAtom<string>(''),
    formMoveType: newAtom<MoveType | null>(null),
    formContainerMode: newAtom<ContainerMode | null>(ContainerMode.Fcl),
    formCFSLocation: newAtom<LocationOption | null>(null),
    formCFSLocationOptions: newAtom<LocationOption[]>([]),
    formDeliveryLocation: newAtom<LocationOption | null>(null),
    formDeliveryLocationOptions: newAtom<LocationOption[]>([]),
    formPol: newAtom<GqlPort | null>(null),
    formCarrier: newAtom<GqlCarrier | null>(null),
    formCfsOpenDate: newAtom<DateAtomType>(null),
    formCfsCutoffDate: newAtom<DateAtomType>(null),
    formEtd: newAtom<DateAtomType>(null),
    formPod: newAtom<GqlPort | null>(null),
    formEta: newAtom<DateAtomType>(null),
    consolidationRemark: newAtom(''),
  },

  filters: {
    filterDestination: newAtom<LocationOption | null>(null),
    filterCargoReadyDate: newAtom<DateRangeValue | null>(null),
    filterPol: newAtom<string | null>(null),
    filterEtd: newAtom<DateRangeValue | null>(null),
    filterEqptType: newAtom<string | null>(null),
    filterDeliveryType: newAtom<string | null>(null),
    filterSupplier: newAtom<GqlRelatedParty | null>(null),
    filtersLength: newAtom(0),
  },
};

export function useSetupStep1() {
  const setBookings = useSetRecoilState(NewConsolidationStates.step1.bookings);
  const setFilteredBookings = useSetRecoilState(NewConsolidationStates.step1.filteredBookings);
  const setBookingChecked = useSetRecoilState(NewConsolidationStates.step1.bookingsChecked);
  const setSelectedBooking = useSetRecoilState(NewConsolidationStates.step1.selectedBookings);

  return (bookings: ConsolableBookingRow[]) => {
    setBookings(bookings);
    setFilteredBookings(bookings);
    setBookingChecked([]);
    setSelectedBooking([]);
  };
}

export interface Step1Validation {
  canGoToStep2: boolean;
  whyCantGoToStep2?: string;
}

export const step1Validation = selector<Step1Validation>({
  key: genKey(),
  get: ({ get }) => {
    const bookings = get(NewConsolidationStates.step1.selectedBookings);

    if (bookings.length < 1) {
      return {
        canGoToStep2: false,
        whyCantGoToStep2: 'You need to select at least one booking',
      };
    }

    return { canGoToStep2: true };
  },
});

export interface LocationOption {
  id: string | null; // null if it's a new location the user typed in
  name: string;
  isNew?: boolean;
}
