import { LocationType } from 'api/GQL_Types';
import { AppWideStates, useLocationTypeNameLookup } from 'app/states';
import AtomicCountryV2 from 'components/atomic/AtomicCountryV2';
import AtomicMultiSelectorV2 from 'components/atomic/AtomicMultiSelectorV2';
import { AtomicPhoneNumberV2 } from 'components/atomic/AtomicPhoneNumberV2';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormInputLogo } from 'components/form/FormInputLogo';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import FormInputTimezone from 'components/form/FormInputTimezone';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NewProfileFormStates } from './states';

interface Props {
  profileTypes: {
    id: string;
    name: string;
  }[];
}

export const NewProfileForm: React.FC<Props> = ({ profileTypes }) => {
  const [logo, setLogo] = useRecoilState(NewProfileFormStates.profile.logo);
  const [unloco, setUnloco] = useRecoilState(NewProfileFormStates.location.relatedPortCode);
  const [timeZone, setTimeZone] = useRecoilState(NewProfileFormStates.location.timeZone);
  const [hasSetTimeZone, setHasSetTimeZone] = useRecoilState(
    NewProfileFormStates.location.hasSetTimeZone
  );
  const locationTypeNameLookup = useLocationTypeNameLookup();
  const allLocationTypes = useRecoilValue(AppWideStates.locationTypes).all;

  return (
    <div>
      <FormInputLogo value={logo} onValue={setLogo} label="Profile Logo" />
      <FormRow>
        <AtomicTextFieldV2
          required
          state={NewProfileFormStates.profile.name}
          label="Profile Name"
        />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2
          required
          state={NewProfileFormStates.profile.code}
          label="Profile Code"
        />
        <AtomicSelectorV2
          required
          state={NewProfileFormStates.profile.type}
          label="Profile Type"
          optionsList={profileTypes}
          controllingField="id"
          displayField="name"
        />
      </FormRow>
      <SectionTitle title="Main Location" />
      <FormRow>
        <AtomicTextFieldV2
          required
          state={NewProfileFormStates.location.name}
          label="Location Name"
        />
        <AtomicMultiSelectorV2<LocationType>
          required
          state={NewProfileFormStates.location.locationTypes}
          label="Location Types"
          optionsList={allLocationTypes.map((l) => l.code)}
          valueResolver={(value) => value}
          displayResolver={locationTypeNameLookup}
        />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.location.addressLine1} label="Address 1" />
        <AtomicTextFieldV2 state={NewProfileFormStates.location.addressLine2} label="Address 2" />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.location.city} label="City" />
        <AtomicTextFieldV2 state={NewProfileFormStates.location.state} label="State" />
        <AtomicTextFieldV2 state={NewProfileFormStates.location.postalCode} label="Postal Code" />
        <AtomicCountryV2 required state={NewProfileFormStates.location.country} label="Country" />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.location.code} label="Location Code" />
        <FormItem>
          <FormInputPortAny
            label="UNLOCO"
            value={unloco}
            onValue={(v) => {
              if (v?.timeZone && !hasSetTimeZone) {
                setTimeZone(v.timeZone);
              }
              setUnloco(v);
            }}
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <AtomicPhoneNumberV2
          state={NewProfileFormStates.location.companyPhone}
          label="Company Phone"
        />
        <FormItem>
          <FormInputTimezone
            label="Time Zone"
            value={timeZone}
            onValue={(selectedTimeZone) => {
              if (selectedTimeZone) {
                setTimeZone(selectedTimeZone);
                setHasSetTimeZone(true);
              } else {
                setTimeZone('');
                setHasSetTimeZone(false);
              }
            }}
          />
        </FormItem>
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.location.companyUrl} label="Company URL" />
        <FormItem></FormItem>
      </FormRow>
      <SectionTitle title="Main Contact" />

      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.contact.firstName} label="First Name" />
        <AtomicTextFieldV2 state={NewProfileFormStates.contact.lastName} label="Last Name" />
      </FormRow>
      <FormRow>
        <AtomicTextFieldV2 state={NewProfileFormStates.contact.title} label="Title" />
        <AtomicTextFieldV2 state={NewProfileFormStates.contact.email} label="Email" />
      </FormRow>
      <FormRow>
        <AtomicPhoneNumberV2 state={NewProfileFormStates.contact.phoneMain} label="Main Phone" />
        <AtomicTextFieldV2 state={NewProfileFormStates.contact.extension} label="Extension" />
      </FormRow>
      <FormRow>
        <AtomicPhoneNumberV2 state={NewProfileFormStates.contact.phoneCell} label="Cell Phone" />
        <AtomicPhoneNumberV2
          state={NewProfileFormStates.contact.phoneAlternative}
          label="Alt Phone"
        />
      </FormRow>
    </div>
  );
};
