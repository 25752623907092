import { navigate, RouteComponentProps, Router } from '@reach/router';
import { PermissionCode, useProfileQuery } from 'api/GQL_Types';
import { auth } from 'app';
import ManagePageHeader from 'app/admin/components/ManagePageHeader';
import { ManageBody, ManageContainer, ManageContent } from 'components/StyledComponents';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LinkItem } from 'types/LinkItem';
import ProfileContacts from './contacts';
import ProfileDetails from './details';
import { ReviewProfileDialog, ReviewProfileDialog_Open } from './details/ReviewProfileDialog';
import ProfileLocations from './locations';
import ProfileRoles from './ProfileRoles';
import RelatedProfiles from './related-profiles';
import { ProfilePageStates, useSetProfilePageState } from './states';

interface Props extends RouteComponentProps {
  profileId?: string;
}

export default function ManageProfile(props: Props) {
  const { userContext } = auth.useAuthState();
  const setProfilePage = useSetProfilePageState();

  const { data: profileData } = useProfileQuery({
    variables: { profileId: props.profileId ?? '' },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setProfilePage(data);
      if (!data.profile) {
        navigate('/admin/profiles');
      }
    },
  });

  const navLinks: LinkItem[] = [];
  if (userContext?.permissionCodes.has(PermissionCode.ProfileRead)) {
    navLinks.push({
      name: 'Details',
      path: '',
      icon: 'list_alt',
      subNav: [],
    });
  }
  if (userContext?.permissionCodes.has(PermissionCode.ProfileRoleRead)) {
    navLinks.push({
      name: 'Roles',
      path: 'roles',
      icon: 'account_box',
      subNav: [],
    });
  }
  if (userContext?.permissionCodes.has(PermissionCode.ProfileLocationRead)) {
    navLinks.push({
      name: 'Locations',
      path: 'locations',
      icon: 'domain',
      subNav: [],
    });
  }
  if (userContext?.permissionCodes.has(PermissionCode.ProfileRelatedProfilesRead)) {
    navLinks.push({
      name: 'Related Profiles',
      path: 'related-profiles',
      icon: 'device_hub',
      subNav: [],
    });
  }
  if (userContext?.permissionCodes.has(PermissionCode.ProfileContactRead)) {
    navLinks.push({
      name: 'Contacts',
      path: 'contacts',
      icon: 'contact_phone',
      subNav: [],
    });
  }

  return (
    <ManageContainer>
      <ManagePageHeader title={profileData?.profile?.name} links={navLinks} />
      <ManageContent>
        <ManageBody>
          <Router>
            <ProfileDetails default path="/" />
            <ProfileContacts path="contacts" />
            <ProfileLocations path="locations" />
            <ProfileRoles path="roles" />
            <RelatedProfiles path="related-profiles" />
          </Router>
          <ProfileDialogs />
        </ManageBody>
      </ManageContent>
    </ManageContainer>
  );
}

const ProfileDialogs: React.FC = (props) => {
  const { userContext } = auth.useAuthState();
  const profile = useRecoilValue(ProfilePageStates.profile);
  const setOpenReviewDialog = useSetRecoilState(ReviewProfileDialog_Open);

  const canEdit = userContext?.permissionCodes.has(PermissionCode.ProfileUpdate);
  const showReviewDialog = profile && profile.needsUpdate && canEdit;

  React.useEffect(() => {
    if (showReviewDialog) {
      setOpenReviewDialog(true);
    }
    return () => {
      setOpenReviewDialog(false);
    };
  }, [showReviewDialog]);

  if (!profile) {
    return null;
  }
  return (
    <ReviewProfileDialog
      profile={profile}
      mergeToProfileOptions={profile.network.connectedProfiles}
      profileTypeOptions={profile.profileTypeOptions}
    />
  );
};
