import { Box, Button } from '@material-ui/core';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState } from 'recoil';
import { NetworkAdminPageStates } from '../states';

interface Props {
  onClose(): void;
}

export const AddEquipmentTypeDialog: React.FC<Props> = ({ onClose }) => {
  const [containerTypes, setContainerTypes] = useRecoilState(
    NetworkAdminPageStates.containerTypeForm
  );
  const [code, setCode] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [capacityWeightKg, setCapacityWeightKg] = React.useState<number>(0);
  const [capacityVolumeM3, setCapacityVolumeM3] = React.useState<number>(0);

  const codeNormalized = code.replace(/\s+/g, '').toUpperCase();

  let codeAlreadyExists = containerTypes.map((ct) => ct.code).includes(codeNormalized);

  return (
    <UniversalDialog
      open
      title="Add Equipment Type"
      small
      setClose={() => {
        onClose();
      }}
    >
      <FormRow>
        <FormItem maxWidth={120}>
          <FormInputText
            label="Code"
            required
            value={code}
            onValue={setCode}
            onBlur={() => {
              setCode(codeNormalized);
            }}
            error={codeAlreadyExists}
            helperText={codeAlreadyExists ? 'Already Exists' : null}
          />
        </FormItem>
        <FormItem>
          <FormInputText label="Name" value={name} onValue={setName} />
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem>
          <FormInputNumber
            label="Weight Capacity"
            value={capacityWeightKg}
            onValue={(v) => setCapacityWeightKg(Math.abs(v || 0))}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            endAdornment="kg"
            step={1000}
          />
        </FormItem>
        <FormItem>
          <FormInputNumber
            label="Volume Capacity"
            value={capacityVolumeM3}
            onValue={(v) => setCapacityVolumeM3(Math.abs(v || 0))}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            endAdornment="cbm"
            step={1}
          />
        </FormItem>
      </FormRow>
      <Box display="flex" alignItems="center" justifyContent="flex-end" paddingRight={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={codeNormalized.length === 0 || codeAlreadyExists}
          onClick={() => {
            setContainerTypes([
              ...containerTypes,
              {
                code,
                name,
                capacityVolumeM3,
                capacityWeightKg,
                ruleMinVolumeM3: 0.7 * capacityVolumeM3,
                ruleMaxVolumeM3: capacityVolumeM3,
                ruleMinWeightKg: 0.7 * capacityWeightKg,
                ruleMaxWeightKg: capacityWeightKg,
              },
            ]);
            onClose();
          }}
        >
          Add
        </Button>
      </Box>
    </UniversalDialog>
  );
};
