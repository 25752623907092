import { RouteComponentProps } from '@reach/router';
import { FlexGrowRouter } from 'components/StyledComponents';
import React from 'react';
import ManageProfile from './manage';

interface Props extends RouteComponentProps {}
export default function Profiles(props: Props) {
  return (
    <FlexGrowRouter>
      <ManageProfile path="/:profileId/*" />
    </FlexGrowRouter>
  );
}
