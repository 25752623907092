import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { GqlContainer, VolumeUnit, WeightUnit } from 'api/GQL_Types';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { MblPageStates } from '../states';

const columns: UWLTableColumn<GqlContainer>[] = [
  { id: 'isHot', label: '', type: 'string' },
  { id: 'containerNumber', label: 'Container', type: 'link' },
  { id: 'containerType', label: 'Container Type', type: 'string' },
  { id: 'sealNumber', label: 'Seal', type: 'string' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: VolumeUnit.Cbm },
  { id: 'weightKg', label: 'Weight', type: 'weight', unit: WeightUnit.Kg },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
];

const columnsAlt: UWLTableColumn<GqlContainer>[] = [
  { id: 'containerNumber', label: 'Container', type: 'link' },
  { id: 'containerType', label: 'Container Type', type: 'string' },
  { id: 'sealNumber', label: 'Seal', type: 'string' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: VolumeUnit.Cbm },
  { id: 'weightKg', label: 'Weight', type: 'weight', unit: WeightUnit.Kg },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
];

export default function MBLEquipmentPanel() {
  const mbl = useRecoilValue(MblPageStates.mbl);

  return (
    <Panel title="Equipment">
      <Box maxHeight={300} overflow="auto" margin={2}>
        <UWLTable
          rowId="id"
          columns={hasHotContainers(mbl?.shipment.containers ?? []) ? columns : columnsAlt}
          rows={mbl?.shipment.containers ?? []}
          emptyMessage="No Containers Available"
          renderCell={{
            isHot(row) {
              return row.isHot ? (
                <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
              ) : null;
            },
            containerNumber(row) {
              return (
                <>
                  {row.containerNumber ? (
                    <Link to={'/equipment/' + row.id}>{row.containerNumber}</Link>
                  ) : (
                    <Typography>Not Assigned</Typography>
                  )}
                </>
              );
            },
          }}
        />
      </Box>
    </Panel>
  );
}

function hasHotContainers(containers: GqlContainer[]) {
  return Boolean(containers.find((container) => container.isHot));
}
