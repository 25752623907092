import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import FormInputCheckbox from 'components/form/FormInputCheckbox';
import * as React from 'react';
import reportFormStore from 'stores/reportFormStore';
import { isValidEmail } from 'types/Email';
import { UserNameEmail } from 'types/UserNameEmail';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    'user-list': {
      columnGap: '1em',
      gridColumnGap: '1em',
      listStyle: 'none',
      [theme.breakpoints.up('md')]: {
        columnCount: 2,
      },
    },
  })
);

function emailInputId(i: number): string {
  return 'report-email-' + i;
}

interface Props {
  availableContacts: UserNameEmail[];
}

export default function Step4({ availableContacts }: Props) {
  const { values, validation } = reportFormStore.use();
  const [thenFocusId, setThenFocusId] = React.useState<string | null>(null);
  const classes = useStyle();

  React.useEffect(() => {
    if (thenFocusId) {
      const elm = document.getElementById(thenFocusId);
      if (elm) {
        elm.focus();
      }
      setThenFocusId(null);
    }
  }, [thenFocusId]);

  React.useEffect(() => {
    if (values.recipientEmails.length === 0) {
      setEmails(['']);
    }
  }, [values.recipientEmails.length === 0]);

  function setUserIDs(recipientContactIds: string[]) {
    reportFormStore.put({ recipientContactIds });
  }

  function setEmails(recipientEmails: string[]) {
    reportFormStore.put({ recipientEmails });
  }

  function setEmail(i: number, email: string) {
    setEmails(values.recipientEmails.map((e, j) => (j === i ? email : e)));
  }

  function removeEmail(i: number) {
    if (values.recipientEmails.length === 1) {
      setEmails(['']);
      setThenFocusId(emailInputId(0));
    } else {
      setEmails(values.recipientEmails.filter((e, j) => j !== i));
      setThenFocusId(emailInputId(Math.min(i, values.recipientEmails.length - 2)));
    }
  }

  function addEmail() {
    setEmails(values.recipientEmails.concat(['']));
    setThenFocusId(emailInputId(values.recipientEmails.length));
  }

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Typography variant="h3">Users</Typography>

        <Button size="small" onClick={() => setUserIDs(availableContacts.map((user) => user.id))}>
          all
        </Button>
        <Button size="small" onClick={() => setUserIDs([])}>
          none
        </Button>
      </Box>

      <ul className={classes['user-list']}>
        {availableContacts.map((user) => {
          return (
            <li key={user.id}>
              <FormInputCheckbox
                dense
                label={`${user.firstName} ${user.lastName} <${user.email}>`}
                value={values.recipientContactIds.includes(user.id)}
                onValue={(b) => {
                  if (values.recipientContactIds.includes(user.id)) {
                    setUserIDs(values.recipientContactIds.filter((id) => id !== user.id));
                  } else {
                    setUserIDs(values.recipientContactIds.concat([user.id]));
                  }
                }}
              />
            </li>
          );
        })}
      </ul>

      <Box marginTop={3} marginBottom={2}>
        <Typography variant="h3">Other Emails</Typography>
      </Box>

      {values.recipientEmails.map((email, emailIndex) => {
        return (
          <Box key={'email-' + emailIndex} display="flex" alignItems="start" marginY={1}>
            <TextField
              id={emailInputId(emailIndex)}
              type="email"
              variant="outlined"
              margin="dense"
              fullWidth={true}
              value={email}
              onChange={(e) => {
                setEmail(emailIndex, e.currentTarget.value);
              }}
              error={email.trim().length !== 0 && !isValidEmail(email)}
              onKeyDown={(e) => {
                switch (e.keyCode) {
                  case 13: {
                    // enter key
                    if (emailIndex === values.recipientEmails.length - 1) {
                      return addEmail();
                    } else {
                      return setThenFocusId(emailInputId(emailIndex + 1));
                    }
                  }
                  case 38: // up arrow
                    return setThenFocusId(emailInputId(emailIndex - 1));
                  case 40: // down arrow
                    return setThenFocusId(emailInputId(emailIndex + 1));
                  case 46: // delete key
                    return removeEmail(emailIndex);
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {values.recipientEmails.length > 1 && (
              <IconButton onClick={() => removeEmail(emailIndex)} tabIndex={-1}>
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        );
      })}
      <Button onClick={addEmail}>+ add email</Button>

      {validation.recipients && (
        <FormHelperText error variant="outlined">
          {validation.recipients}
        </FormHelperText>
      )}
    </div>
  );
}
