import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { NewBookingPageStates } from '../states';
import CompleteBookingButton from './CompleteBookingButton';
import { Step2Button } from './Step2Button';

export default function NewBookingHeader() {
  const activeStep = useRecoilValue(NewBookingPageStates.activeStep);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1}>
      <Typography variant="h2" color="primary">
        {activeStep === 0 ? 'PO Selection' : ''}
      </Typography>
      <Box>{activeStep === 0 ? <Step2Button /> : <CompleteBookingButton />}</Box>
    </Box>
  );
}
