import { gql } from '@apollo/client/core';
import {
  GqlNewProfileLocationMutation,
  GqlNewProfileLocationMutationVariables,
  GqlUpdateLocationMutation,
  GqlUpdateLocationMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const UpdateLocationMutation = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      success
      message
    }
  }
`;

export const updateLocation = wrapMutation<
  GqlUpdateLocationMutation,
  GqlUpdateLocationMutationVariables
>({
  mutation: UpdateLocationMutation,
  errorMessage(data, error) {
    if (!data?.updateLocation.success || error) {
      return data?.updateLocation.message || 'Unexpected Error';
    }
  },
});

export const MergeLocationMutation = gql`
  mutation MergeLocation($input: MergeLocationInput!) {
    mergeLocation(input: $input) {
      success
      message
    }
  }
`;

export const NewProfileLocationMutation = gql`
  mutation NewProfileLocation($input: NewProfileLocationInput!) {
    newProfileLocation(input: $input) {
      success
      message
      location {
        id
        name
      }
    }
  }
`;

export const newProfileLocation = wrapMutation<
  GqlNewProfileLocationMutation,
  GqlNewProfileLocationMutationVariables
>({
  mutation: NewProfileLocationMutation,
  errorMessage(data, error) {
    if (!data?.newProfileLocation.success || error) {
      return data?.newProfileLocation.message || 'Unexpected Error';
    }
  },
});
