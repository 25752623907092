import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import { Button } from '@material-ui/core';
import {
  GqlAssignBookingContainerMutation,
  GqlAssignBookingContainerMutationVariables,
  GqlUpdateContainerMutation,
  GqlUpdateContainerMutationVariables,
  GqlVanPositionInput,
} from 'api/GQL_Types';
import { assignBookingContainer, BookingQuery } from 'api/queries/bookingQueries';
import { updateContainer } from 'api/queries/containerQueries';
import { BookingPageStates } from 'app/bookings/details/states';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ItemPosition } from 'types/VanPosition';

export default function SubmitFooter() {
  const booking = useRecoilValue(BookingPageStates.booking);
  const [selectedContainer, setSelectedContainer] = useRecoilState(
    BookingPageStates.containerAssignment.selectedContainer
  );
  const selectedItems = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.selectedBookingPackLines
  );
  const containerNumber = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.containerNumber
  );
  const containerType = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.containerType
  );
  const sealNumber = useRecoilValue(BookingPageStates.containerAssignment.editContainer.sealNumber);
  const shipFromFactory = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.shipFromFactory
  );
  const terminalReceived = useRecoilValue(
    BookingPageStates.containerAssignment.editContainer.terminalReceived
  );

  const { enqueueSnackbar } = useSnackbar();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: BookingQuery,
      variables: { bookingId: booking?.id },
    },
  ];

  const updateContainerAction = useAsyncAction<
    GqlUpdateContainerMutationVariables,
    GqlUpdateContainerMutation
  >((input) => updateContainer(input, { refetchQueries }), {
    onData(data) {
      setSelectedContainer(null);
      enqueueSnackbar('Container Updated!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to update container: ' + error, { variant: 'error' });
    },
  });

  const assignBookingContainerAction = useAsyncAction<
    GqlAssignBookingContainerMutationVariables,
    GqlAssignBookingContainerMutation
  >((input) => assignBookingContainer(input, { refetchQueries }), {
    onData(data) {
      setSelectedContainer(null);
      enqueueSnackbar('Container Assigned!', { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to assign container: ' + error, { variant: 'error' });
    },
  });

  React.useEffect(() => {
    updateContainerAction.reset();
    assignBookingContainerAction.reset();
  }, [booking?.id]);

  return (
    <UniversalDialogFooter
      error={updateContainerAction.error || assignBookingContainerAction.error}
    >
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => {
          setSelectedContainer(null);
        }}
        disabled={updateContainerAction.waiting || assignBookingContainerAction.waiting}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={
          hasIncompleteItems(selectedItems) ||
          updateContainerAction.waiting ||
          assignBookingContainerAction.waiting
        }
        onClick={() => {
          const vanPos: GqlVanPositionInput[] = [];
          for (const itemPos of selectedItems) {
            vanPos.push({
              piecesQty: itemPos.piecesQty,
              cartonsQty: itemPos.cartonsQty,
              volumeM3: itemPos.volumeM3,
              weightKg: itemPos.weightKg,
              vanCode: itemPos.vanCode,
              packLineId: itemPos.packLineId,
            });
          }
          if (selectedContainer?.containerNumber) {
            updateContainerAction.act({
              input: {
                container: {
                  id: selectedContainer?.id,
                  containerNumber: containerNumber,
                  containerType: containerType,
                  sealNumber: sealNumber,
                  shipFromFactoryDate: shipFromFactory,
                  terminalReceivedDate: terminalReceived,
                },
                vanPositions: vanPos,
              },
            });
          } else {
            assignBookingContainerAction.act({
              input: {
                bookingId: booking?.id || '',
                container: {
                  id: selectedContainer?.id,
                  containerNumber: containerNumber,
                  containerType: containerType,
                  sealNumber: sealNumber,
                  shipFromFactoryDate: shipFromFactory,
                  terminalReceivedDate: terminalReceived,
                },
                vanPositions: vanPos,
              },
            });
          }
        }}
      >
        Save
      </Button>
    </UniversalDialogFooter>
  );
}

function hasIncompleteItems(selectedItems: ItemPosition[]) {
  for (const item of selectedItems) {
    if (!item.packLineId) {
      return true;
    }
  }
  return false;
}
