import { Button } from '@material-ui/core';
import { PartyType } from 'api/GQL_Types';
import FormInputSelect from 'components/form/FormInputSelect';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { makeCss, theme } from 'styles';
import { NetworkAdminPageStates } from '../states';
import { partyTypeOptions } from './states';

const classes = makeCss({
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 3, 0),
  },
  inputWrap: {
    width: 200,
    margin: theme.spacing(0, 1),
  },
});

interface Props {
  onClose(): void;
  onAdd(profileId: string, partyType: PartyType): void;
}

export const AddRuleDialog: React.FC<Props> = (props) => {
  const profiles = useRecoilValue(NetworkAdminPageStates.network)?.connectedProfiles || [];
  const profileOptions = profiles.map((p) => ({ id: p.id, label: p.name }));

  const [profileId, setProfileId] = React.useState<string | null>(null);
  const [partyType, setPartyType] = React.useState<PartyType | null>(null);

  return (
    <UniversalDialog
      open
      setClose={() => {
        props.onClose();
      }}
      title="Add Rule"
    >
      <div className={classes.row}>
        When
        <div className={classes.inputWrap}>
          <FormInputSelect
            label="Profile"
            value={profileId}
            onValue={setProfileId}
            options={profileOptions}
          />
        </div>
        is
        <div className={classes.inputWrap}>
          <FormInputSelect
            label="Type"
            value={partyType}
            onValue={(partyType: any) => {
              setPartyType(partyType);
            }}
            options={partyTypeOptions}
            disabled={!profileId}
          />
        </div>
      </div>
      <UniversalDialogFooter>
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (!profileId) return;
            if (!partyType) return;
            props.onAdd(profileId, partyType);
          }}
        >
          Add
        </Button>
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};
