import { Box, Typography } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { profileContactSearchState } from './ProfileContactCards';

export default function ProfileContactsHeader() {
  const setSearchField = useSetRecoilState(profileContactSearchState);
  return (
    <Box display="flex" justifyContent="space-between">
      <div>
        <Typography variant="h2">Contacts</Typography>
        <Typography variant="body1">List of contacts associated with this profile</Typography>
      </div>
      <Box marginRight={1} height={1} bgcolor="#efeded" padding={1} width={300} borderRadius={4}>
        <SearchBar
          placeholder="Search by Contact Name"
          updateField={(field: any) => {
            setSearchField(field);
          }}
        />
      </Box>
    </Box>
  );
}
