import { Box, Grid } from '@material-ui/core';
import { LocationType, useAvailableConsolidationBookingListLazyQuery } from 'api/GQL_Types';
import { availableConsolBookingsToRows } from 'app/consolidations/states';
import Loading from 'app/Loading';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import { useDivDimensions } from 'lib/useDivDimensions';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LocationOption, NewConsolidationStates, useSetupStep1 } from '../states';
import BookingsTable from './BookingsTable';
import EquipmentUtilization from './equipment-utilization';
import EquipmentSelecting from './EquipmentSelection';

export default function NewConsolidationStep1() {
  const setupStep1 = useSetupStep1();
  const bookings = useRecoilValue(NewConsolidationStates.step1.bookings);
  const setCfsLocations = useSetRecoilState(NewConsolidationStates.step1.cfsLocations);
  const { divEl, height } = useDivDimensions();

  const [getBookings, { loading }] = useAvailableConsolidationBookingListLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(bookingsData) {
      let bookingsList = availableConsolBookingsToRows(bookingsData);

      const cfsLocations: LocationOption[] = [];
      for (const loc of bookingsData.userContext?.activeContact?.profile.locations || []) {
        if (loc.locationTypes.includes(LocationType.Cfs)) {
          cfsLocations.push(loc);
        }
      }
      setCfsLocations(cfsLocations);

      setupStep1(bookingsList);
    },
  });

  React.useEffect(() => {
    if (bookings.length === 0) {
      getBookings();
    }
  }, [bookings.length]);

  if (loading) {
    return <Loading />;
  }

  return (
    <RemainingHeightLayout
      top={
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5} ref={divEl}>
            <Box display="flex" height={height}>
              <EquipmentSelecting />
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Box display="flex" height={height}>
              <EquipmentUtilization />
            </Box>
          </Grid>
        </Grid>
      }
      bottom={<BookingsTable />}
      bottomMinHeight={300}
    />
  );
}
