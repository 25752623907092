import { Box, Button, Container, Typography } from '@material-ui/core';
import { useUpdateUserMutation } from 'api/GQL_Types';
import { UserQuery } from 'api/queries/userQueries';
import AtomicSwitchV2 from 'components/atomic/AtomicSwitchV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { ManageUserPageData } from '../states';

// user data, user email, username, password, isActive, from pressing update button on password & security page
export const UserSecurityDialog_Data = newAtom<ManageUserPageData | null>(null);

const UserSecurityPasswordState = newAtom('');
const UserSecurityEmailState = newAtom('');
const UserSecurityIsActiveState = newAtom(false);

export default function UserSecurityDialog() {
  const [userSecurity, setUserSecurity] = useRecoilState(UserSecurityDialog_Data);

  const setUserSecurityIsActiveState = useSetRecoilState(UserSecurityIsActiveState);
  const setUserSecurityEmailState = useSetRecoilState(UserSecurityEmailState);
  const setUserSecurityPasswordState = useSetRecoilState(UserSecurityPasswordState);

  React.useEffect(() => {
    setUserSecurityPasswordState('');
    setUserSecurityEmailState(userSecurity?.email || '');
    setUserSecurityIsActiveState(!!userSecurity?.isActive);
  }, [userSecurity]);

  return (
    <UniversalDialog
      open={!!userSecurity}
      title="Update User Log In"
      small
      setClose={() => {
        setUserSecurity(null);
      }}
    >
      <Line height={1} />
      <Container>
        <Box marginBottom={2}>
          <FormRow>
            <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">
                  This user is currently {userSecurity?.isActive ? 'active' : 'inactive'}
                </Typography>
                <Typography variant="caption">
                  Use this toggle to active/deactivate this user
                </Typography>
              </Box>
              <AtomicSwitchV2 state={UserSecurityIsActiveState} />
            </Box>
          </FormRow>
          <hr />
          <FormRow>
            <AtomicTextFieldV2
              label="Email / Username"
              state={UserSecurityEmailState}
              autoComplete="off"
            />
          </FormRow>
          <FormRow>
            <AtomicTextFieldV2
              label="Password"
              state={UserSecurityPasswordState}
              type="password"
              autoComplete="off"
            />
          </FormRow>
        </Box>
      </Container>
      <Line height={1} />
      <DialogFooter />
    </UniversalDialog>
  );
}

function DialogFooter() {
  const [userSecurity, setUserSecurity] = useRecoilState(UserSecurityDialog_Data);
  const isActive = useRecoilValue(UserSecurityIsActiveState);
  const email = useRecoilValue(UserSecurityEmailState);
  const password = useRecoilValue(UserSecurityPasswordState);
  const { enqueueSnackbar } = useSnackbar();

  const [updateUser, { data: userData }] = useUpdateUserMutation({
    refetchQueries: () => [
      {
        query: UserQuery,
        variables: { userId: userSecurity?.id || '' },
        fetchPolicy: 'network-only',
      },
    ],
  });

  React.useEffect(() => {
    if (userData) {
      if (userData.updateUser.success) {
        setUserSecurity(null);
        enqueueSnackbar('User Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button
        variant="contained"
        color="default"
        size="large"
        onClick={() => setUserSecurity(null)}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          if (!userSecurity) return;
          updateUser({
            variables: {
              input: {
                user: {
                  id: userSecurity.id,
                  email: email,
                  isActive: isActive,
                  credentials:
                    password.length > 0
                      ? {
                          email: email,
                          username: email,
                          password: password,
                        }
                      : undefined,
                },
              },
            },
          });
        }}
        style={{ height: '40px', marginRight: '12px' }}
      >
        Update User Log In
      </Button>
    </Box>
  );
}
