import SectionTitle from 'components/SectionTitle';
import GlobalUpdateStep2TransportationDischarge from './discharge';
import GlobalUpdateStep2TransportationLoadingPort from './loading-port';
import GlobalUpdateStep2TransportationTransshipment from './transshipment';
import GlobalUpdateStep2TransportationEquipmentReturn from './equipment-return';
import GlobalUpdateStep2TransportationFreeTime from './free-time';

export default function GlobalUpdateStep2Transportation() {
  return (
    <>
      <SectionTitle title="Transportation Details" />
      <GlobalUpdateStep2TransportationLoadingPort />
      <hr />
      <GlobalUpdateStep2TransportationTransshipment />
      <hr />
      <GlobalUpdateStep2TransportationDischarge />
      <hr />
      <GlobalUpdateStep2TransportationEquipmentReturn />
      <hr />
      <GlobalUpdateStep2TransportationFreeTime />
    </>
  );
}
