import { Divider, Drawer, List } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import DrawerBtn from './DrawerBtn';
import NavItems from './NavItems';
import NavLogo from './NavLogo';
import { sidenavStyle } from './navstyles';
import UserAvatar from './UserAvatar';

interface Props {
  isSysAdmin?: boolean;
}

export default function SideNavigation(props: Props) {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(getDrawerState());
  function getDrawerState(): boolean {
    return localStorage.getItem('drawerOpen') === 'true';
  }

  const classes = sidenavStyle();

  React.useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(drawerOpen));

    // let components know the page layout changed by signaling the resize event
    const handle = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
    return () => {
      clearTimeout(handle);
    };
  }, [drawerOpen]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, classes.drawerBackground, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
      open={drawerOpen}
    >
      <NavLogo
        open={drawerOpen}
        onChange={(newValue: boolean) => {
          setDrawerOpen(newValue);
        }}
      />
      <NavItems open={drawerOpen} isSysAdmin={props.isSysAdmin} />

      <Divider />
      <List className={classes.drawerSpacer} />
      <DrawerBtn
        open={drawerOpen}
        onChange={(newValue: boolean) => {
          setDrawerOpen(newValue);
        }}
      />
      <UserAvatar />
    </Drawer>
  );
}
