import { Box } from '@material-ui/core';
import Loading from 'app/Loading';
import { UWLTable } from 'components/UWLTable/UWLTable';
import VanCodeCell from 'components/VanCodeCell';
import { theme } from 'styles';
import { formatNumber } from 'types/Number';
import { UWLTableColumn } from 'types/UWLTable';
import { ItemPosition } from 'types/VanPosition';
import { FooterCell } from './FooterCell';
import { NumberInputCell } from './NumberInputCell';

interface Props {
  selectedLines: ItemPosition[];
  setSelectedLines: React.Dispatch<React.SetStateAction<ItemPosition[]>>;
  selectedLinesChecked: string[];
  setSelectedLinesChecked: React.Dispatch<React.SetStateAction<string[]>>;
  columns: UWLTableColumn<ItemPosition>[];
}

export const TopHalf: React.FC<Props> = ({
  selectedLines,
  setSelectedLines,
  selectedLinesChecked,
  setSelectedLinesChecked,
  columns,
}) => {
  if (!selectedLines) {
    return <Loading />;
  }

  let totalPiecesQty: number = 0;
  let totalCartons: number = 0;
  let totalVolume: number = 0;
  let totalWeight: number = 0;
  for (const line of selectedLines) {
    totalPiecesQty += line.piecesQty;
    totalCartons += line.cartonsQty;
    totalVolume += line.volumeM3;
    totalWeight += line.weightKg;
  }

  return (
    <Box paddingX={1} paddingBottom={1} display="flex" maxHeight="100%">
      <div
        style={{
          // This inner div is needed to give the table a reference point for it's scroll container b/c it can't be done directly in a maxHeight div
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <UWLTable
          rowId="packLineId"
          columns={columns}
          rows={selectedLines}
          emptyMessage="No Items Selected"
          renderFooterCell={{
            packLineId() {
              return <FooterCell>Summary Total</FooterCell>;
            },
            piecesQty() {
              return <FooterCell>{formatNumber(totalPiecesQty)} pcs</FooterCell>;
            },
            cartonsQty() {
              return <FooterCell>{formatNumber(totalCartons)} ctns</FooterCell>;
            },
            volumeM3() {
              return <FooterCell>{formatNumber(totalVolume)} cbm</FooterCell>;
            },
            weightKg() {
              return <FooterCell>{formatNumber(totalWeight)} kg</FooterCell>;
            },
          }}
          checkboxes={{
            checked: selectedLinesChecked,
            setChecked: setSelectedLinesChecked,
          }}
          renderCell={{
            packLineId(row) {
              return (
                <div style={{ marginRight: theme.spacing(2) }}>
                  {row.poNumber}: {row.itemNumber}
                </div>
              );
            },
            piecesQty(row) {
              return (
                <NumberInputCell
                  value={row.piecesQty}
                  onValue={(v) => {
                    const newItems: ItemPosition[] = [];
                    for (const currentItem of selectedLines) {
                      if (currentItem.packLineId === row.packLineId) {
                        newItems.push({ ...row, piecesQty: v });
                      } else {
                        newItems.push(currentItem);
                      }
                    }
                    setSelectedLines(newItems);
                  }}
                  unit="pcs"
                  maximumFractionDigits={0}
                />
              );
            },
            cartonsQty(row) {
              return (
                <NumberInputCell
                  value={row.cartonsQty}
                  onValue={(v) => {
                    const newItems: ItemPosition[] = [];
                    for (const currentItem of selectedLines) {
                      if (currentItem.packLineId === row.packLineId) {
                        newItems.push({ ...row, cartonsQty: v });
                      } else {
                        newItems.push(currentItem);
                      }
                    }
                    setSelectedLines(newItems);
                  }}
                  unit="ctns"
                  maximumFractionDigits={0}
                />
              );
            },
            volumeM3(row) {
              return (
                <NumberInputCell
                  value={row.volumeM3}
                  onValue={(v) => {
                    const newItems: ItemPosition[] = [];
                    for (const currentItem of selectedLines) {
                      if (currentItem.packLineId === row.packLineId) {
                        newItems.push({ ...row, volumeM3: v });
                      } else {
                        newItems.push(currentItem);
                      }
                    }
                    setSelectedLines(newItems);
                  }}
                  unit="cbm"
                  maximumFractionDigits={2}
                />
              );
            },
            weightKg(row) {
              return (
                <NumberInputCell
                  value={row.weightKg}
                  onValue={(v) => {
                    const newItems: ItemPosition[] = [];
                    for (const currentItem of selectedLines) {
                      if (currentItem.packLineId === row.packLineId) {
                        newItems.push({ ...row, weightKg: v });
                      } else {
                        newItems.push(currentItem);
                      }
                    }
                    setSelectedLines(newItems);
                  }}
                  unit="kg"
                  maximumFractionDigits={2}
                />
              );
            },
            vanCode(row) {
              return (
                <VanCodeCell
                  row={row}
                  selectedLines={selectedLines}
                  setSelectedLines={setSelectedLines}
                ></VanCodeCell>
              );
            },
          }}
        />
      </div>
    </Box>
  );
};
