import { RouteComponentProps } from '@reach/router';
import { ManagePageTabHeader } from 'app/admin/components/ManagePageTabHeader';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ProfilePageStates } from '../states';

interface Props extends RouteComponentProps {}

export default function RelatedProfiles(props: Props) {
  const profile = useRecoilValue(ProfilePageStates.profile);

  if (!profile) {
    return null;
  }

  return (
    <div>
      <ManagePageTabHeader title="Related Profiles" subTitle="Related profile rules." />
      <div style={{ whiteSpace: 'pre-wrap' }}>{profile.relatedProfileRulesText}</div>
    </div>
  );
}
