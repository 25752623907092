import { Link } from '@reach/router';
import { PopoverTable } from 'app/dashboard/components/PopoverTable';
import { DashboardContainer } from 'app/dashboard/states';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import {
  STANDARD_ROW_INNER_HEIGHT,
  STANDARD_ROW_PADDING_HEIGHT,
  UWLTable,
} from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';

const columns: UWLTableColumn<DashboardContainer>[] = [
  { id: 'equipmentNumber', label: 'Equipment #', type: 'link' },
  { id: 'equipmentType', label: 'Equipment Type', type: 'string' },
  { id: 'bookingNumber', label: 'Booking #', type: 'link' },
  { id: 'bookingDate', label: 'Booking Date', type: 'date' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'cargoReadyDate', label: 'CGO RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CGO RDY Date', type: 'date' },
  { id: 'pos', label: 'PO #', type: 'link' },
  { id: 'shipper', label: 'Shipper', type: 'string' },
  { id: 'origin', label: 'Origin', type: 'string' },
  { id: 'destination', label: 'Destination', type: 'string' },
];

const exportColumns = columns;

// Used for both virtualization and the popover size
function rowInnerHeight(row: DashboardContainer): number {
  return Math.max(STANDARD_ROW_INNER_HEIGHT, row.pos.length * STANDARD_ROW_INNER_HEIGHT);
}

interface Props {
  title: string;
  containers: DashboardContainer[];

  anchorEl: any;
  open: boolean;
  onClose(): void;
}

export const ContainersPopover: React.FC<Props> = ({
  title,
  containers,
  anchorEl,
  open,
  onClose,
}) => {
  const windowSize = useWindowSize();

  return (
    <PopoverTable
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      title={title}
      titleRight={
        <TableExportWindowGroup label={title} rows={containers} columns={exportColumns} />
      }
      totalLabel="Total Containers"
      totalValue={formatNumber(containers.length)}
      width={windowSize.width * 0.8}
      bodyHeight={containers.reduce(
        (sum, row) => sum + rowInnerHeight(row) + STANDARD_ROW_PADDING_HEIGHT,
        0
      )}
    >
      {open && (
        <UWLTable
          rowId={'id'}
          rows={containers}
          columns={columns}
          emptyMessage="No Containers"
          noWrapAllCells
          virtualize={{
            rowHeight: rowInnerHeight,
            bufferHeight: STANDARD_ROW_INNER_HEIGHT * 5,
          }}
          renderCell={{
            pos(row) {
              return (
                <div>
                  {row.pos.map((po) => {
                    return (
                      <div key={po.to}>
                        <Link to={po.to}>{po.value}</Link>
                      </div>
                    );
                  })}
                </div>
              );
            },
          }}
        />
      )}
    </PopoverTable>
  );
};
