import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { PartyType, useNetworkProfilesQuery } from 'api/GQL_Types';
import ErrorMessage from 'components/ErrorMessage';
import FormInputSelect from 'components/form/FormInputSelect';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { theme } from 'styles';
import { mapPartyType } from 'types/OMSEnums';

let nextId = 0;
function genNextId() {
  return `new-${nextId++}`;
}

const relationOptions: { id: PartyType; label: string }[] = Object.values(PartyType)
  .filter((type) => type !== PartyType.Admin)
  .map((type) => {
    return {
      id: type,
      label: mapPartyType(type),
    };
  });

export interface OverrideRelatedPartyValue {
  id: string;
  profileId: string;
  partyType: PartyType;
}

interface Props {
  onClose(): void;
  value: OverrideRelatedPartyValue[];
  onValue(newValue: OverrideRelatedPartyValue[]): void;
  isSaving?: boolean;
  saveError?: string | null;
}

export const OverrideRelatedPartiesDialog: React.FC<Props> = ({
  onClose,
  value,
  onValue,
  isSaving,
  saveError,
}) => {
  const [relatedParties, setRelatedParties] = React.useState<
    {
      id: string;
      profileId: string | null;
      partyType: PartyType | null;
    }[]
  >([]);

  const [profileOptions, setProfileOptions] = React.useState<{ id: string; label: string }[]>([]);

  const profilesQuery = useNetworkProfilesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setProfileOptions(
        (data.network?.connectedProfiles || []).map((p) => ({ id: p.id, label: p.name }))
      );
    },
  });

  React.useEffect(() => {
    if (value.length > 0) {
      setRelatedParties(value);
    } else {
      setRelatedParties([
        {
          id: genNextId(),
          profileId: null,
          partyType: PartyType.Related,
        },
      ]);
    }
  }, [value]);

  let allFieldsAreSet = true;
  for (const rp of relatedParties) {
    if (!rp.profileId || !rp.partyType) {
      allFieldsAreSet = false;
      break;
    }
  }

  return (
    <UniversalDialog
      open
      title="Related Parties"
      small
      setClose={() => {
        onClose();
      }}
    >
      {relatedParties.map((relatedParty) => {
        return (
          <Box key={relatedParty.id} display="flex" alignItems="flex-start" marginBottom={2}>
            <FormInputSelect
              options={profileOptions}
              value={relatedParty.profileId}
              onValue={(profileId) => {
                setRelatedParties(
                  relatedParties.map((rp) =>
                    rp.id === relatedParty.id ? { ...rp, profileId } : rp
                  )
                );
              }}
              label="Party"
              disabled={profilesQuery.loading || isSaving}
              error={!relatedParty.profileId || !!profilesQuery.error}
              helperText={
                !relatedParty.profileId
                  ? 'Required'
                  : profilesQuery.error
                  ? profilesQuery.error + ''
                  : null
              }
            />
            <Box marginX={1} width={200} flex="none">
              <FormInputSelect
                options={relationOptions}
                value={relatedParty.partyType}
                onValue={(partyType: PartyType | null) => {
                  setRelatedParties(
                    relatedParties.map((rp) =>
                      rp.id === relatedParty.id ? { ...rp, partyType } : rp
                    )
                  );
                }}
                label="Party Type"
                error={!relatedParty.partyType}
                helperText={!relatedParty.partyType ? 'Required' : null}
                disabled={isSaving}
              />
            </Box>
            <div>
              <Button
                onClick={() => {
                  setRelatedParties(relatedParties.filter((rp) => rp.id !== relatedParty.id));
                }}
                disabled={isSaving}
              >
                Remove
              </Button>
            </div>
          </Box>
        );
      })}

      <Box display="flex" justifyContent="center" marginBottom={2}>
        <Button
          variant="contained"
          color="secondary"
          disabled={isSaving}
          onClick={() => {
            setRelatedParties(
              relatedParties.concat([
                {
                  id: genNextId(),
                  profileId: null,
                  partyType: PartyType.Related,
                },
              ])
            );
          }}
        >
          Add New Party
        </Button>
      </Box>

      <hr />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <ErrorMessage error={saveError} />

        <Button
          variant="contained"
          color="default"
          size="large"
          style={{ height: '40px', margin: theme.spacing(0, 1.5) }}
          onClick={() => {
            onClose();
          }}
          disabled={isSaving}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ height: '40px' }}
          disabled={!allFieldsAreSet || isSaving}
          onClick={() => {
            const newValue: OverrideRelatedPartyValue[] = [];

            for (const rp of relatedParties) {
              if (!rp.profileId || !rp.partyType) {
                return;
              }
              newValue.push({
                id: rp.id,
                profileId: rp.profileId,
                partyType: rp.partyType,
              });
            }

            onValue(newValue);
          }}
        >
          Override Parties
        </Button>
      </Box>
    </UniversalDialog>
  );
};
