import { Box, Button, Container, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { DeleteButton } from './DeleteButton';
import ErrorMessage from './ErrorMessage';
import { DialogForm, Line } from './StyledComponents';

interface Props {
  open: boolean;
  onClose(): void;
  title: string;
  message: string;
  onClickDelete(): void;

  cancelBtnText?: string;
  deleteBtnText?: string;
  error?: string | null;
  waiting?: boolean;
}

export const DeleteConfirmationDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  message,
  cancelBtnText,
  deleteBtnText,
  onClickDelete,
  error,
  waiting,
}) => {
  return (
    <UniversalDialog open={open} setClose={onClose}>
      <Container>
        <Box width={500} marginBottom={2}>
          <DialogForm>
            <Box display="flex" justifyContent="center">
              <WarningIcon style={{ fontSize: 90, color: '#E6001F' }} />
            </Box>
          </DialogForm>
          <Box display="flex" flexDirection="column" textAlign="center">
            <Typography variant="h1">{title}</Typography>
            <Typography variant="body1">{message}</Typography>
          </Box>
        </Box>
      </Container>
      <Line height={1} />
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="default"
          size="large"
          style={{ marginRight: '12px' }}
          onClick={onClose}
          disabled={waiting}
        >
          {cancelBtnText || 'Cancel'}
        </Button>
        <DeleteButton size="large" onClick={onClickDelete} disabled={waiting}>
          {deleteBtnText || 'Delete'}
        </DeleteButton>
      </Box>
      <div style={{ textAlign: 'center' }}>
        <ErrorMessage error={error} />
      </div>
    </UniversalDialog>
  );
};
