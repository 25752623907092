import React from 'react';
import { PermissionCode, useNewBookingRemarkMutation } from 'api/GQL_Types';
import { BookingQuery } from 'api/queries/bookingQueries';
import { userContextAtom } from 'app';
import Remarks from 'components/Remarks';
import { useRecoilValue } from 'recoil';
import { BookingPageStates } from '../states';
import { useSnackbar } from 'notistack';

export default function BookingRemarks() {
  const userContext = useRecoilValue(userContextAtom);
  const booking = useRecoilValue(BookingPageStates.booking);
  const allRemarks = useRecoilValue(BookingPageStates.allRemarks);
  const { enqueueSnackbar } = useSnackbar();

  const [saveBookingRemark] = useNewBookingRemarkMutation({
    onCompleted: (data) => {
      if (data.newBookingRemark) {
        enqueueSnackbar('Booking Remark Added.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    refetchQueries: () => [
      {
        query: BookingQuery,
        variables: { bookingId: booking?.id ?? '' },
        fetchPolicy: 'network-only',
      },
    ],
  });

  return (
    <>
      {userContext?.permissionCodes.has(PermissionCode.BookingRemarkRead) && (
        <Remarks
          title="Remarks"
          allowComments={userContext?.permissionCodes.has(PermissionCode.BookingRemarkCreate)}
          remarks={allRemarks}
          makeRemark={(text) => {
            saveBookingRemark({
              variables: {
                input: {
                  bookingId: booking?.id ?? '',
                  remark: {
                    text: text,
                  },
                },
              },
            });
          }}
        />
      )}
    </>
  );
}
