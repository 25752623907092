import React from 'react';
import { makeCss, theme } from 'styles';
import ErrorMessage from './ErrorMessage';
import { Line } from './StyledComponents';

const classes = makeCss({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(1.5),
    },
  },
});

interface Props {
  error?: string | null;
  leftBtns?: any;
}

export const UniversalDialogFooter: React.FC<Props> = ({ leftBtns, error, children }) => {
  return (
    <>
      <Line height={1} />
      <div className={classes.footer}>
        <div className={classes.buttons}>{leftBtns}</div>
        <div className={classes.buttons}>
          <ErrorMessage error={error} />
          {children}
        </div>
      </div>
    </>
  );
};
