import { Box, Button } from '@material-ui/core';
import { useUpdateProfileMutation } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import AtomicSelectorV2 from 'components/atomic/AtomicSelectorV2';
import { AtomicTextWithAliasesV2 } from 'components/atomic/AtomicTextWithAliasesV2';
import ErrorMessage from 'components/ErrorMessage';
import { FormRow } from 'components/form/FormRow';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { ProfilePageStates } from '../states';

export const EditProfileDetailsDialog_Open = newAtom(false);

interface Props {}

export default function EditProfileDetailsDialog(props: Props) {
  const profile = useRecoilValue(ProfilePageStates.profile);

  const [profileName, setProfileName] = useRecoilState(ProfilePageStates.profileName);
  const [profileNameAliases, setProfileNameAliases] = useRecoilState(
    ProfilePageStates.profileNameAliases
  );
  const [profileCode, setProfileCode] = useRecoilState(ProfilePageStates.profileCode);
  const [profileCodeAliases, setProfileCodeAliases] = useRecoilState(
    ProfilePageStates.profileCodeAliases
  );
  const [profileType, setProfileType] = useRecoilState(ProfilePageStates.profileType);
  const [mainLocation, setMainLocation] = useRecoilState(ProfilePageStates.mainLocation);
  const [mainContact, setMainContact] = useRecoilState(ProfilePageStates.mainContact);

  const [openEditDialog, setOpenEditDialog] = useRecoilState(EditProfileDetailsDialog_Open);

  const [updateProfile, { loading, error }] = useUpdateProfileMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profile?.id },
      },
    ],
    onCompleted() {
      setOpenEditDialog(false);
    },
  });

  React.useEffect(() => {
    setProfileName(profile?.name || '');
    setProfileNameAliases(profile?.nameAliases || []);
    setProfileCode(profile?.profileCode || '');
    setProfileCodeAliases(profile?.profileCodeAliases || []);
    setProfileType(profile?.profileType || { id: '', name: '' });
    setMainLocation(
      profile?.mainLocation
        ? { id: profile.mainLocation.id, name: profile.mainLocation.name }
        : { id: '', name: '' }
    );
    setMainContact(
      profile?.mainContact
        ? {
            id: profile.mainContact.id,
            name: `${profile.mainContact.firstName} ${profile.mainContact.lastName}`.trim(),
          }
        : { id: '', name: '' }
    );
  }, [openEditDialog, profile]);

  const profileTypeChangeWarning = (profile?.profileType?.id || '') !== profileType.id;

  if (!profile) {
    return null;
  }

  return (
    <UniversalDialog
      title="Edit Profile Details"
      open={openEditDialog}
      setClose={() => {
        setOpenEditDialog(false);
      }}
    >
      <Box width={700}>
        <FormRow>
          <AtomicTextWithAliasesV2
            label="Name"
            textState={ProfilePageStates.profileName}
            aliasesState={ProfilePageStates.profileNameAliases}
            disabled={loading}
          />
        </FormRow>
        <FormRow>
          <AtomicTextWithAliasesV2
            label="Profile Code"
            textState={ProfilePageStates.profileCode}
            aliasesState={ProfilePageStates.profileCodeAliases}
            disabled={loading}
          />
        </FormRow>
        <FormRow>
          <AtomicSelectorV2
            label="Profile Type"
            state={ProfilePageStates.profileType}
            optionsList={profile.profileTypeOptions}
            controllingField="id"
            displayField="name"
            disabled={loading}
            helperText={
              profileTypeChangeWarning
                ? 'WARNING you may need to update some contact user roles.'
                : null
            }
          />
        </FormRow>
        <FormRow>
          <AtomicSelectorV2
            label="Main Location"
            state={ProfilePageStates.mainLocation}
            optionsList={profile.locationList}
            controllingField="id"
            displayField="name"
            disabled={loading}
          />
        </FormRow>
        <FormRow>
          <AtomicSelectorV2
            label="Main Contact"
            state={ProfilePageStates.mainContact}
            optionsList={profile.contacts.map((c) => ({
              id: c.id,
              name: `${c.firstName} ${c.lastName}`.trim(),
            }))}
            controllingField="id"
            displayField="name"
            disabled={loading}
          />
        </FormRow>

        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <ErrorMessage error={error ? error + '' : null} />
          <Button
            size="large"
            color="default"
            style={{ marginRight: '16px' }}
            disabled={loading}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={loading}
            onClick={() => {
              updateProfile({
                variables: {
                  input: {
                    profile: {
                      id: profile.id,
                      name: profileName,
                      nameAliases: profileNameAliases,
                      profileCode: profileCode,
                      profileCodeAliases: profileCodeAliases,
                      profileTypeId: profileType?.id,
                    },
                    profileMainLocation: { id: mainLocation.id || null },
                    profileMainContact: { id: mainContact.id || null },
                  },
                },
              });
            }}
          >
            Update Details
          </Button>
        </Box>
      </Box>
    </UniversalDialog>
  );
}
