import React from 'react';
import SectionTitle from 'components/SectionTitle';
import { Box } from '@material-ui/core';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { UWLTableColumn } from 'types/UWLTable';
import { GlobalUpdateRecordRow, GUStates } from 'app/global-update/states';
import { useRecoilValue } from 'recoil';

const columns: UWLTableColumn<GlobalUpdateRecordRow>[] = [
  { id: 'referenceNumber', label: 'Booking ID', type: 'string' },
  { id: 'mblNumber', label: 'MBL #', type: 'string' },
  { id: 'hblNumber', label: 'HBL #', type: 'string' },
  { id: 'containerNumber', label: 'Container', type: 'string' },
  { id: 'poNumber', label: 'PO #', type: 'string' },
  { id: 'itemNumber', label: 'Sku/Item #', type: 'string' },
  { id: 'vesselName', label: 'Vessel', type: 'string' },
  { id: 'voyageName', label: 'Voyage', type: 'string' },
];

export default function GlobalUpdateStep2Records() {
  const selectedResults = useRecoilValue(GUStates.selectedGlobalUpdateRecords);

  return (
    <>
      <SectionTitle title={'Records To Update (' + selectedResults.length + ')'} />
      <Box marginTop={3}>
        <UWLTable
          rowId="rowId"
          columns={columns}
          isLoading={false}
          rows={selectedResults}
          emptyMessage="No Records"
        />
      </Box>
    </>
  );
}
