import { Button } from '@material-ui/core';
import React from 'react';
import { makeCss } from 'styles';

const classes = makeCss({
  outlined: {
    borderColor: '#E6001F',
    color: '#E6001F',
  },
});

interface Props {
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  onClick?: () => void;
  title?: string;
}

export const DeleteButton: React.FC<Props> = ({ size, disabled, onClick, title, children }) => {
  return (
    <Button
      variant="outlined"
      size={size}
      disabled={disabled}
      title={title}
      classes={{
        outlined: classes.outlined,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
