import { Box, Button } from '@material-ui/core';
import { FormInputText } from 'components/form/FormInputText';
import { FormItem } from 'components/form/FormItem';
import { FormRow } from 'components/form/FormRow';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import React from 'react';
import { validatePassword } from 'types/Password';

interface Props {
  email: string;
  onClose(): void;
  onSave(password: string): void;
}

export const SetupUserAccountDialog: React.FC<Props> = ({ email, onClose, onSave }) => {
  const [password1, setPassword1] = React.useState<string>('');
  const [password2, setPassword2] = React.useState<string>('');

  const validation = validatePassword(password1, password2);

  return (
    <UniversalDialog
      open={true}
      title="Create User Login"
      small
      setClose={() => {
        onClose();
      }}
    >
      <Line height={1} />
      <Box marginY={1}>
        <FormRow>
          <FormItem>
            Create a login for <b>{email}</b>
          </FormItem>
        </FormRow>
        <FormRow>
          <FormItem>
            {/* TODO - Discuss this with the UWL team b/c it would be much better if we sent an
            invite link to the user so they set their own password. At the very least, we could make
            the link the default option, but offer the password only for cases where it's not practical.
            */}
            <FormInputText
              value={password1}
              onValue={setPassword1}
              label="Enter Password"
              type="password"
              autoComplete="off"
              error={!!validation.passwordError}
              helperText={validation.passwordError}
            />
          </FormItem>
          <FormItem>
            <FormInputText
              value={password2}
              onValue={setPassword2}
              label="Re-enter Password"
              type="password"
              autoComplete="off"
              error={!!validation.confirmPasswordError}
              helperText={validation.confirmPasswordError}
            />
          </FormItem>
        </FormRow>
      </Box>

      <UniversalDialogFooter>
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!!validation.passwordError || !!validation.confirmPasswordError}
          onClick={() => {
            onSave(password1);
          }}
        >
          Create Login
        </Button>
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};
