import { PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { Document, DocumentsPanel } from 'app/components/DocumentsPanel';
import { UploadDocumentDialog, UploadDocumentDialog_Data } from 'components/UploadDocumentDialog';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BookingPageStates } from '../states';

interface Props {
  bookingId?: string;
}
export default function BookingDocuments(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const booking = useRecoilValue(BookingPageStates.booking);
  const setOpenDialog = useSetRecoilState(UploadDocumentDialog_Data);
  const [documents, setDocuments] = React.useState<Document[]>([]);

  React.useEffect(() => {
    if (booking) {
      let bookDocList: any = [];
      let hblDocList: any = [];
      if (booking.documents) {
        for (const doc of booking.documents) {
          bookDocList.push({
            id: doc?.id,
            hblId: booking.hbl?.id,
            hblNumber: booking.hbl?.referenceNumber ?? '',
            documentType: doc?.documentType,
            importFileName: doc?.importFileName,
            fileType: doc?.fileType,
            fileSize: doc?.fileSize,
            uploadDate: doc?.uploadDate,
            description: doc?.description,
          });
        }
      }
      for (const doc of booking.hbl?.documents ?? []) {
        hblDocList.push({
          id: doc?.id,
          blId: booking.hbl?.id,
          blNumber: booking.hbl?.referenceNumber,
          documentType: doc?.documentType,
          importFileName: doc?.importFileName,
          fileType: doc?.fileType,
          fileSize: doc?.fileSize,
          uploadDate: doc?.uploadDate,
          description: doc?.description,
        });
      }
      setDocuments([...bookDocList, ...hblDocList]);
    }
  }, [booking]);

  return (
    <div>
      <DocumentsPanel
        documents={documents}
        showHblNumber
        onUploadClicked={
          userContext?.permissionCodes.has(PermissionCode.DocumentUpload)
            ? () =>
                setOpenDialog({
                  bookingId: booking?.id ?? null,
                })
            : undefined
        }
      />
      <UploadDocumentDialog />
    </div>
  );
}
