import { FormInputNumber } from 'components/form/FormInputNumber';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  warning: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.warning.dark,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        borderColor: theme.palette.warning.dark,
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.warning.dark,
    },
    '& .MuiIconButton-edgeEnd': {
      color: theme.palette.warning.dark,
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.dark,
    },
  },
});

export const NumberInputCell: React.FC<{
  value: number;
  onValue: (value: number) => void;
  unit: string;
  maximumFractionDigits: number;
  errorMsg?: string;
  warning?: boolean;
  startAdornment?: string | React.ReactNode;
}> = ({ value, onValue, unit, maximumFractionDigits, errorMsg, warning, startAdornment }) => {
  const width = '130px';
  const [focused, setFocused] = React.useState(false);
  const [over, setOver] = React.useState(false);

  return (
    <div
      style={{
        width: width,
        margin: '1px',
      }}
      onClick={(e) => e.stopPropagation()}
      onMouseOver={(e) => {
        setOver(true);
      }}
      onMouseOut={(e) => {
        setOver(false);
      }}
    >
      <FormInputNumber
        value={value}
        onValue={(v) => {
          onValue(v || 0);
        }}
        startAdornment={startAdornment}
        endAdornment={unit}
        size="xs"
        selectAllOnFocus
        maximumFractionDigits={maximumFractionDigits}
        error={!!errorMsg}
        onFocusChange={(isFocused) => {
          setFocused(isFocused);
        }}
        className={warning && errorMsg ? classes.warning : undefined}
        // helperText={errorMsg}
      />
      {(focused || over) && (
        <div
          style={{
            position: 'absolute',
            zIndex: 3,
            backgroundColor: 'white',
            width: width,
            textAlign: 'center',
            color: warning ? theme.palette.warning.dark : theme.palette.error.main,
            marginTop: theme.spacing(1),
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
          }}
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
};
