import { gql } from '@apollo/client/core';
import {
  GqlNewApiTokenMutation,
  GqlNewApiTokenMutationVariables,
  GqlRevokeApiTokenMutation,
  GqlRevokeApiTokenMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const ApiTokensQuery = gql`
  query ApiTokens {
    apiTokens {
      token
      createDate
      lastUsedDate
    }
  }
`;

export const RevokeApiToken = gql`
  mutation RevokeApiToken($input: RevokeApiTokenInput!) {
    revokeApiToken(input: $input) {
      success
      message
    }
  }
`;

export const revokeApiToken = wrapMutation<
  GqlRevokeApiTokenMutation,
  GqlRevokeApiTokenMutationVariables
>({
  mutation: RevokeApiToken,
  errorMessage(data, error) {
    if (!data?.revokeApiToken.success || error) {
      return data?.revokeApiToken.message || 'Unexpected Error';
    }
  },
});

export const NewApiToken = gql`
  mutation NewApiToken {
    newApiToken {
      success
      message
    }
  }
`;

export const newApiToken = wrapMutation<GqlNewApiTokenMutation, GqlNewApiTokenMutationVariables>({
  mutation: NewApiToken,
  errorMessage(data, error) {
    if (!data?.newApiToken.success || error) {
      return data?.newApiToken.message || 'Unexpected Error';
    }
  },
});
