import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { theme } from 'styles';

interface Props {
  port: string;
  label: string;
  matIcon: string;
}

export default function PortDisplay(props: React.PropsWithChildren<Props>) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="#1f3f56"
      margin={'4px auto'}
      borderRadius="20px"
      paddingRight={1}
      paddingLeft={1}
      height={136}
      border="1px solid #eaeaea"
      flexGrow={1}
      maxWidth={350}
      boxShadow="0px 3px 6px rgb(0, 0, 0, .16)"
      overflow="hidden"
    >
      <Box display="flex" marginY={1} marginRight={0} alignItems="flex-start" width={1}>
        <Avatar
          style={{
            backgroundColor: '#27B9D4',
            color: '#fff',
            boxShadow: '4px 4px 6px 0px rgba(0,0,0,0.20)',
            padding: theme.spacing(2),
          }}
        >
          <Box component="span" className="material-icons" fontSize="32">
            {props.matIcon}
          </Box>
        </Avatar>
        <Box flexGrow={1} marginLeft={1} overflow="hidden">
          <Box
            fontSize="16px"
            fontWeight="body1.fontWeight"
            style={{ textTransform: 'capitalize' }}
            color="#27B9D4"
            whiteSpace="nowrap"
          >
            {props.label}
          </Box>
          <hr style={{ margin: '4px 0' }} />
          <Tooltip title={props.port || ''} interactive>
            <Typography variant="h3" color="textSecondary" noWrap>
              {props.port ? props.port : <p></p>}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box textAlign="center" marginTop={1} marginLeft={'4px'}>
        {props.children}
      </Box>
    </Box>
  );
}
