import { gql } from '@apollo/client/core';
import { GqlUpdateContainerMutation, GqlUpdateContainerMutationVariables } from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';
import { PortFragment } from './portQueries';

export const ContainerQuery = gql`
  query Container($containerId: ID!) {
    container(containerId: $containerId) {
      id
      containerNumber
      containerType
      sealNumber
      shipFromFactoryDate
      terminalReceivedDate
      portOutGateDate
      ramp {
        ...Port
      }
      rampEta
      rampAta
      deliveryEta
      deliveryAta
      emptyReturnedDate
      demurrageLastFreeDayDate
      detentionLastFreeDayDate
      rampOutGateDate
      emptyNotifyDate
      shipment {
        id
        ... on Booking {
          incoTerm
        }
        logistics {
          id
          moveType
          pickupLocation {
            name
          }
          pol {
            ...Port
          }
          pod {
            ...Port
          }
          carrier {
            name
          }
          motherVessel {
            name
          }
          motherVoyage {
            name
          }
          polEtd
          polAtd
          podEta
          podAta
          finalDestination {
            name
          }
        }
        ... on Booking {
          mbl {
            id
            referenceNumber
          }
        }
        ... on Consolidation {
          mbl {
            id
            referenceNumber
          }
        }
      }
      vanPositions {
        id
        piecesQty
        cartonsQty
        volumeM3
        weightKg
        commercialInvoiceTotal
        packLine {
          booking {
            relatedParties {
              party {
                name
              }
              partyType
            }
            hbl {
              id
              referenceNumber
            }
          }
          orderLine {
            unitPrice
            unitPriceCurrencyCode
            itemNumber
            itemDescription
            purchaseOrder {
              id
              poNumber
            }
            openOrder {
              orderQty
              isHot
            }
          }
        }
      }
      isHot
      hotMarkedBy
      hotMarkedTimestamp
    }
  }
  ${PortFragment}
`;

export const UpdateContainerMutation = gql`
  mutation UpdateContainer($input: UpdateContainerInput!) {
    updateContainer(input: $input) {
      success
      message
    }
  }
`;

export const updateContainer = wrapMutation<
  GqlUpdateContainerMutation,
  GqlUpdateContainerMutationVariables
>({
  mutation: UpdateContainerMutation,
  errorMessage(data, error) {
    if (!data?.updateContainer.success || error) {
      return data?.updateContainer.message || 'Unexpected Error';
    }
  },
});

export const MarkContainerAsHotMutation = gql`
  mutation MarkContainerAsHot($input: MarkContainerAsHotInput!) {
    markContainerAsHot(input: $input) {
      success
      message
      container {
        isHot
        hotMarkedBy
        hotMarkedTimestamp
      }
    }
  }
`;
