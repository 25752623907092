import * as React from 'react';
import { isNumber } from 'types/Number';
import useIsMountedRef from './useIsMountedRef';

export interface AsyncActionState {
  /**
   * True if the action has been attempted.
   */
  called: boolean;

  /**
   * True while the action is being performed.
   */
  waiting: boolean;

  /**
   * The error message to display to the user. Null when there is no error.
   */
  error: string | null;

  /**
   * True when the action has completed. This can be automatically cleared when the `finishedTimeout` option is provided.
   */
  finished: boolean;
}

export type AsyncActionResult<R> = { type: 'error'; error: string } | { type: 'data'; data: R };

export interface AsyncAction<P, R> extends AsyncActionState {
  /**
   * Perform the action
   * @param params
   */
  act(params: P): Promise<AsyncActionResult<R>>;

  /**
   * Reset the state i.e. clear the error, and waiting state.
   * This is useful if you want to clear the error when the user makes a change in order to make a second attempt.
   */
  reset(): void;
}

export interface UseAsyncActionOptions<R> {
  finishedTimeout?: number;
  onData?: (data: R) => void;
  onError?: (error: string) => void;
  onResult?: (result: AsyncActionResult<R>) => void;
}

/**
 * This hook provides state management async actions.
 * Keeping track of the action state makes it easy to indicate on the UI what is happening.
 * i.e. when the action is waiting, when it had an error, if was called, etc.
 *
 * @param action An async function that performs the action.
 * @param options Optional settings for how the hook should behave or report error/success
 * @returns
 */
export function useAsyncAction<P, R>(
  action: (params: P) => Promise<R>,
  options?: UseAsyncActionOptions<R>
): AsyncAction<P, R> {
  const isMountedRef = useIsMountedRef();

  const [state, setState] = React.useState<AsyncActionState>({
    called: false,
    waiting: false,
    error: null,
    finished: false,
  });

  return { ...state, act, reset };

  async function act(params: P): Promise<AsyncActionResult<R>> {
    try {
      setState({ called: true, waiting: true, error: null, finished: false });
      let ret = await action(params);
      if (isMountedRef.current) {
        setState({ called: true, waiting: false, error: null, finished: true });
        if (options && options.onData) {
          options.onData(ret);
        }
        if (options && options.onResult) {
          options.onResult({ type: 'data', data: ret });
        }
        if (options && isNumber(options.finishedTimeout) && options.finishedTimeout > 0) {
          setTimeout(() => {
            if (isMountedRef.current && !state.waiting && !state.error) {
              reset();
            }
          }, options.finishedTimeout);
        }
      }
      return { type: 'data', data: ret };
    } catch (err) {
      const error = err + '';
      if (isMountedRef.current) {
        setState({ called: true, waiting: false, error, finished: false });
        if (options && options.onError) {
          options.onError(error);
        }
        if (options && options.onResult) {
          options.onResult({ type: 'error', error });
        }
      }
      // NOTE: Not throwing an error.
      // b/c most times you don't want to `.act().catch(()=>null)` just to avoid Unhandled Promise Rejection error.
      return { type: 'error', error };
    }
  }

  async function reset() {
    if (isMountedRef.current) {
      setState({ called: false, waiting: false, error: null, finished: false });
    }
  }
}
