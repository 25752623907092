import { Box, Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorMessage from 'components/ErrorMessage';
import FakeProgressBar from 'components/FakeProgressBar';
import UniversalDialog from 'components/UniversalDialog';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  subtitle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > * + *': {
      marginLeft: theme.spacing(3),
    },
  },
});

interface Props {
  onClose(): void;

  waiting?: null | {
    title: string;
    subtitle: string;
  };

  error?: null | {
    title: string;
    message: string;
  };

  finished?: null | {
    title: string;
    subtitle?: string | React.ReactElement;
    buttons: React.ReactElement;
  };
}

export const StatusDialog: React.FC<Props> = (props) => {
  const open = !!(props.finished || props.error || props.waiting);

  return (
    <UniversalDialog open={open} small setClose={props.onClose}>
      <Box padding={3} textAlign="center">
        {props.waiting && (
          <>
            <Box marginBottom={3}>
              <Typography variant="h1">{props.waiting.title}</Typography>
            </Box>
            <Box marginBottom={2}>
              <FakeProgressBar start={true} finished={false} onFinished={() => {}} />
            </Box>
            <Typography variant="body1">{props.waiting.subtitle}</Typography>
          </>
        )}
        {props.error && (
          <>
            <Typography variant="h1">{props.error.title}</Typography>
            <Box marginBottom={3}>
              <ErrorMessage error={props.error.message} />
            </Box>
            <Button variant="contained" onClick={props.onClose}>
              Close
            </Button>
          </>
        )}
        {props.finished && (
          <>
            <Box display="flex" justifyContent="center" marginBottom={1}>
              <CheckCircleIcon style={{ fontSize: 90, color: '#009600' }} />
            </Box>
            <Typography variant="h1">{props.finished.title}</Typography>
            {props.finished.subtitle && (
              <Typography variant="body1" className={classes.subtitle}>
                {props.finished.subtitle}
              </Typography>
            )}
            <div className={classes.footer}>{props.finished.buttons}</div>
          </>
        )}
      </Box>
    </UniversalDialog>
  );
};
