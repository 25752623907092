import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { PartyType } from 'api/GQL_Types';
import { HotPoLineSwitch } from 'app/components/HotPoLineSwitch';
import { HotPoSwitch } from 'app/components/HotPoSwitch';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { HotState } from 'components/HotToggleSwitch';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { mapLineStatus } from 'types/OMSEnums';
import { formatNumber } from 'types/Number';
import { ExceptionQueryResult } from '.';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface HotPOException {
  id: string;
  hot: HotState;
  poNumber: TableLink;
  lineStatus: string;
  itemNumber: string | null;
  qty: number | null;
  vendor: string;
  shipToLocationName: string;
  purchaseOrderHot: HotState;
  purchaseOrderId: string;
  purchaseOrderStatus: string;
}

export interface HotPOExceptionGrouped {
  id: string;
  hot: HotState;
  poNumber: TableLink;
  lineStatus: string;
  itemNumber: string | null;
  qty: number | null;
  vendor: string;
  shipToLocationName: string;
  purchaseOrderHot: HotState;
  purchaseOrderId: string;

  rows: HotPOException[];
}

export function HotPOException_toRow(exception: ExceptionQueryResult): HotPOException | null {
  if (exception.__typename !== 'HotPurchaseOrder') {
    return null;
  }
  const openOrder = exception.openOrder;

  return {
    id: openOrder.orderLine.id,
    hot: {
      isHot: openOrder.isHot,
      hotMarkedBy: openOrder.hotMarkedBy,
      hotMarkedTimestamp: openOrder.hotMarkedTimestamp,
    },
    poNumber: {
      to: '/purchase-orders/' + openOrder.orderLine.purchaseOrder.id,
      value: openOrder.orderLine.purchaseOrder.poNumber,
    },
    lineStatus: mapLineStatus(openOrder.orderLine.lineStatus),
    itemNumber: openOrder.orderLine.item.itemNumber,
    qty: openOrder.orderLine.totalQty ?? 0,
    vendor: relatedPartyNameOrVarious(
      openOrder.orderLine.purchaseOrder.relatedParties,
      PartyType.Supplier
    ),
    shipToLocationName: openOrder.orderLine.shipToLocation?.name || '',
    purchaseOrderHot: {
      isHot: openOrder.orderLine.purchaseOrder.isHot,
      hotMarkedBy: openOrder.orderLine.purchaseOrder.hotMarkedBy,
      hotMarkedTimestamp: openOrder.orderLine.purchaseOrder.hotMarkedTimestamp,
    },
    purchaseOrderId: openOrder.orderLine.purchaseOrder.id,
    purchaseOrderStatus: mapLineStatus(openOrder.orderLine.purchaseOrder.poStatus),
  };
}

export function HotPOException_toGroupedRows(rows: HotPOException[]): HotPOExceptionGrouped[] {
  const byPurchaseOrder = new Map<string, HotPOExceptionGrouped>();
  for (const row of rows) {
    let poLines = rows.filter((x) => x.purchaseOrderId === row.purchaseOrderId);
    let groupBy = '';

    if (row.hot.isHot) {
      groupBy = row.poNumber.value + '|' + row.id;
    } else if (row.purchaseOrderHot.isHot) {
      groupBy = row.poNumber.value + '|' + row.purchaseOrderId;
    }

    let group = byPurchaseOrder.get(groupBy);

    // Create purchase order grouped row
    if (!group && !row.hot.isHot && row.purchaseOrderHot.isHot && poLines.length > 1) {
      const qtySum: number = poLines.reduce((prev, next) => prev + (next.qty ?? 0), 0);
      let itemNumber: string = 'Various';
      let itemNumbers: string[] = [];

      for (const line of poLines) {
        if (line.itemNumber && !itemNumbers.find((x) => x === line.itemNumber)) {
          itemNumbers.push(line.itemNumber);
        }
      }

      if (itemNumbers.length === 1) {
        itemNumber = itemNumbers[0];
      }

      group = {
        id: row.id,
        hot: row.hot,
        poNumber: row.poNumber,
        lineStatus: row.purchaseOrderStatus,
        itemNumber: itemNumber,
        qty: qtySum,
        vendor: row.vendor,
        shipToLocationName: row.shipToLocationName,
        purchaseOrderHot: row.purchaseOrderHot,
        purchaseOrderId: row.purchaseOrderId,

        rows: [],
      };
    } else if (!group) {
      group = {
        id: row.id,
        hot: row.hot,
        poNumber: row.poNumber,
        lineStatus: row.lineStatus,
        itemNumber: row.itemNumber,
        qty: row.qty,
        vendor: row.vendor,
        shipToLocationName: row.shipToLocationName,
        purchaseOrderHot: row.purchaseOrderHot,
        purchaseOrderId: row.purchaseOrderId,

        rows: [],
      };
    }
    group.rows.push(row);
    byPurchaseOrder.set(groupBy, group);
  }

  return Array.from(byPurchaseOrder.values());
}

const columns: UWLTableColumn<HotPOExceptionGrouped>[] = [
  { id: 'hot', label: '!', type: 'string' },
  { id: 'poNumber', label: 'PO #', type: 'link' },
  { id: 'lineStatus', label: 'Status', type: 'string' },
  { id: 'itemNumber', label: 'Item / SKU', type: 'string' },
  { id: 'qty', label: 'Total Qty', type: 'number' },
  { id: 'vendor', label: 'Vendor', type: 'string' },
  { id: 'shipToLocationName', label: 'Destination', type: 'string' },
];

const exportColumns = columns.filter((c) => c.id !== 'hot');

interface Props {}

export const HotPOExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const exceptions = useRecoilValue(DashboardPageStates.exceptions);
  const rows = exceptions.hotPurchaseOrdersGrouped;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Hot Purchase Orders"
        faIcon={faFileInvoiceDollar}
        addHotIcon
        unreadCount={rows.length}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Hot Purchase Orders"
        titleRight={
          <TableExportWindowGroup
            label="Hot Purchase Orders"
            rows={exceptions.hotPurchaseOrders}
            columns={exportColumns}
          />
        }
        totalLabel="Total Hot Purchase Orders"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={rows}
            columns={columns}
            emptyMessage="No Hot Purchase Orders"
            virtualize={'single-line-cells'}
            renderCell={{
              hot(row) {
                if (row.hot.isHot) {
                  return <HotPoLineSwitch orderLineId={row.id} initialState={row.hot} small />;
                } else if (row.purchaseOrderHot.isHot) {
                  return (
                    <HotPoSwitch
                      purchaseOrderId={row.purchaseOrderId}
                      initialState={row.purchaseOrderHot}
                      small
                    />
                  );
                }
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
};
