import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import TextFieldWarnDecorator from 'components/TextFieldWarnDecorator';
import { useRecoilValue } from 'recoil';
import { AtomGroupCleaner } from 'lib/RecoilUtils';
import { GUStates } from '../states';

export default function GlobalUpdateStep2Confirmation() {
  const initConfirmedDate = useRecoilValue(GUStates.initialState.initConfirmedDate);
  const confirmedDate = useRecoilValue(GUStates.form.confirmedDate);
  const confirmedDateLabels = useRecoilValue(GUStates.form.confirmedDateLabels);

  const initConfirmationNumber = useRecoilValue(GUStates.initialState.initConfirmationNumber);
  const confirmationNumber = useRecoilValue(GUStates.form.confirmationNumber);
  const confirmationLabels = useRecoilValue(GUStates.form.confirmationNumberLabels);

  return (
    <Grid container>
      <AtomGroupCleaner atomGroup={GUStates.form} />
      <Grid item xs={3}>
        <AtomicDatePickerV2 state={GUStates.form.confirmedDate} label="Confirmed Booking Date" />
        <TextFieldWarnDecorator
          list={confirmedDateLabels}
          hasChanged={confirmedDate !== initConfirmedDate}
          defaultValue={initConfirmedDate}
          formState={GUStates.form.confirmedDate}
        />
      </Grid>
      <Grid item xs={3}>
        <AtomicTextFieldV2
          state={GUStates.form.confirmationNumber}
          label="Booking Confirmation #"
        />
        <TextFieldWarnDecorator
          list={confirmationLabels}
          hasChanged={confirmationNumber !== initConfirmationNumber}
          defaultValue={initConfirmationNumber}
          formState={GUStates.form.confirmationNumber}
        />
      </Grid>
    </Grid>
  );
}
