import * as React from 'react';
import { useWindowSize } from './useWindowSize';

export function useDivClientWidthHeight(): {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  width: number;
  height: number;
} {
  useWindowSize(); // re-render when page size changes b/c it may update the element's hight
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const w = ref.current?.clientWidth;
    const h = ref.current?.clientHeight;
    if (typeof w === 'number') {
      setWidth(w);
    }
    if (typeof h === 'number') {
      setHeight(h);
    }
  }, [ref.current?.clientWidth, ref.current?.clientHeight]);

  return { ref, width, height };
}
