import { SwitchWithLabel } from 'components/SwitchWithLabel';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  state: RecoilState<boolean>;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
}

export default function AtomicSwitchV2(props: Props) {
  const [field, setField] = useRecoilState(props.state);

  return (
    <SwitchWithLabel
      label={props.label}
      tooltip={props.tooltip}
      checked={field}
      onChange={setField}
      disabled={props.disabled}
    />
  );
}
