export interface TimeOfDay {
  hour: number; // 24 hour clock
  minute: number;
}

export function parseTimeOfDay(text: string): TimeOfDay {
  const isPM = /p/i.test(text);

  const src = text
    .replace(/[^0-9:;.]+/g, '')
    .replace(/[:;.]+/g, ':')
    .trim();

  const parts = src.split(':');

  let hourStr: string = '';
  let minuteStr: string = '';
  if (parts.length < 2) {
    const part = parts[0] || '';
    if (part.length > 2) {
      hourStr = part.substring(0, part.length - 2).substring(0, 2);
      minuteStr = part.substring(part.length - 2);
    } else {
      hourStr = part;
    }
  } else {
    hourStr = parts[0].substring(0, 2);
    minuteStr = parts[1].substring(0, 2);
  }

  let hour = Math.abs(parseInt(hourStr, 10) || 0) % 24;
  if (hour === 12 && !isPM) {
    hour = 0;
  }
  if (isPM && hour <= 12) {
    hour = hour + 12;
  }
  const minute = Math.abs(parseInt(minuteStr, 10) || 0) % 60;
  return { hour, minute };
}

export function fmtTimeOfDay(value: TimeOfDay | null): string {
  if (!value) return '';

  let { hour, minute } = value;
  let isPM = hour >= 12;
  hour = hour % 12;
  if (hour === 0) {
    hour = 12;
  }

  return `${hour}:${minute.toString().padStart(2, '0')} ${isPM ? 'PM' : 'AM'}`;
}
