import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import SearchBar from 'components/SearchBar';
import { Line } from 'components/StyledComponents';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { genKey, newAtom } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { UWLTableColumn } from 'types/UWLTable';
import { NetworkAdminItem, NetworkAdminPageStates } from './states';

const columns: UWLTableColumn<NetworkAdminItem>[] = [
  { id: 'itemNumber', label: 'Item Number', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'htsCode', label: 'HTS Code', type: 'string' },
  { id: 'supplierName', label: 'Supplier', type: 'string' },
];

const networkItemsSearchState = newAtom('');

export const filteredNetworkItemsListState = selector({
  key: genKey(),
  get: ({ get }) => {
    const searchField = get<string>(networkItemsSearchState);
    const items = get(NetworkAdminPageStates.network)?.items || [];
    const lowerField = searchField.toLowerCase();

    if (searchField === '') {
      return items;
    } else {
      return items.filter(
        (item) =>
          item.description.toLowerCase().includes(lowerField) ||
          item.itemNumber.toLowerCase().includes(lowerField) ||
          item.supplierName.toLowerCase().includes(lowerField) ||
          item.htsCode.toLowerCase().includes(lowerField)
      );
    }
  },
});

interface ItemsProps extends RouteComponentProps {}
function NetworkItems(props: ItemsProps) {
  const [searchField, setSearchField] = useRecoilState(networkItemsSearchState);
  const filteredItems = useRecoilValue(filteredNetworkItemsListState);

  return (
    <div>
      <Box className="networkItemsTable" display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Network Items</Typography>
          <Typography variant="body1">Items in this network</Typography>
        </Box>
        <Box width="25%" bgcolor={theme.palette.background.default} padding={1} alignSelf="center">
          <SearchBar
            placeholder="Search Network Items"
            field={searchField}
            updateField={setSearchField}
          />
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />

      <Box paddingX={2} paddingBottom={2}>
        <UWLTable rowId="id" columns={columns} rows={filteredItems} emptyMessage="No Items" />
      </Box>
    </div>
  );
}

export default NetworkItems;
