import { InternalRefetchQueriesInclude } from '@apollo/client/core/types';
import {
  GqlCancelConsolidationMutation,
  GqlCancelConsolidationMutationVariables,
} from 'api/GQL_Types';
import { cancelConsolidation, ConsolidationQuery } from 'api/queries/consolidationQueries';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  consolidationId: string;
  onClose(): void;
}

export const CancelConsolDialog: React.FC<Props> = ({ consolidationId, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ConsolidationQuery,
      variables: { consolId: consolidationId },
      fetchPolicy: 'network-only',
    },
  ];

  const cancelConsolidationAction = useAsyncAction<
    GqlCancelConsolidationMutationVariables,
    GqlCancelConsolidationMutation
  >((input) => cancelConsolidation(input, { refetchQueries }), {
    onData(data) {
      onClose();
      enqueueSnackbar(data.cancelConsolidation.message, { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar('Failed to cancel: ' + error, { variant: 'error' });
    },
  });

  return (
    <DeleteConfirmationDialog
      open
      onClose={() => {
        onClose();
      }}
      title="Cancel Consolidation"
      message="Do you want to cancel this consolidation?"
      onClickDelete={() => {
        cancelConsolidationAction.act({ input: { consolidationId } });
      }}
      cancelBtnText="No"
      deleteBtnText="Yes"
      error={cancelConsolidationAction.error}
      waiting={cancelConsolidationAction.waiting}
    />
  );
};
