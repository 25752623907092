import { isNumber } from './Number';

const currencyFormats = new Map<string, (amount: number) => string>();

function getFmt_base(currency: string): (amount: number) => string {
  try {
    const intl = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency });
    return (amount) => intl.format(amount);
  } catch (err) {
    return (amount) => amount + '';
  }
}

function getFmt(currency: string): (amount: number) => string {
  let fmt = currencyFormats.get(currency);
  if (!fmt) {
    fmt = getFmt_base(currency);
    currencyFormats.set(currency, fmt);
  }
  return fmt;
}

export function formatCurrency(amount: number, currency?: string | null): string {
  if (!isNumber(amount)) {
    return '';
  }
  currency = currency || 'USD';
  return getFmt(currency)(amount);
}
