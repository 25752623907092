import { InternalRefetchQueriesInclude } from '@apollo/client';
import { Button } from '@material-ui/core';
import { GqlNewApiWebhookMutation, GqlNewApiWebhookMutationVariables } from 'api/GQL_Types';
import { ApiWebhooksQuery, newApiWebhook } from 'api/queries/apiWebhookQueries';
import { FormInputText } from 'components/form/FormInputText';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { theme } from 'styles';

interface Props {
  onClose(): void;
}

export const AddWebhookDialog: React.FC<Props> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = React.useState<string>('');

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ApiWebhooksQuery,
      fetchPolicy: 'network-only',
    },
  ];

  const newApiWebhookAction = useAsyncAction<
    GqlNewApiWebhookMutationVariables,
    GqlNewApiWebhookMutation
  >((input) => newApiWebhook(input, { refetchQueries }), {
    onData() {
      enqueueSnackbar('API webhook created!', { variant: 'success' });
      onClose();
    },
    onError(error) {
      enqueueSnackbar('Failed to create API webhook: ' + error, { variant: 'error' });
    },
  });

  return (
    <UniversalDialog open title="Add Webhook" small setClose={onClose}>
      <div style={{ margin: theme.spacing(2, 0, 3, 0) }}>
        <FormInputText
          label="Webhook URL"
          placeholder="https://..."
          required
          value={url}
          onValue={setUrl}
          disabled={newApiWebhookAction.waiting}
        />
      </div>
      <UniversalDialogFooter error={newApiWebhookAction.error}>
        <Button
          variant="contained"
          color="default"
          size="large"
          style={{ marginRight: '12px' }}
          onClick={onClose}
          disabled={newApiWebhookAction.waiting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            newApiWebhookAction.act({ input: { url } });
          }}
          disabled={newApiWebhookAction.waiting}
        >
          Add
        </Button>
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};
