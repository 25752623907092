import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
});

interface Props {
  cellValue: string;
  errors: string[] | undefined;
}

export const TableCell: React.FC<Props> = ({ cellValue, errors }) => {
  return (
    <div className={classes.root}>
      {cellValue}{' '}
      {errors && errors.length > 0 && (
        <Tooltip placement="top" arrow title={errors.join('\n')}>
          <WarningIcon htmlColor="#ff4e4e" style={{ height: '20px' }} />
        </Tooltip>
      )}
    </div>
  );
};
