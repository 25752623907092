import { Box, Button, Container } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const Thumb = styled.div`
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
`;

interface Props {
  value: File | null;
  onValue(v: File | null): void;

  label?: string;
}

export const FormInputLogo: React.FC<Props> = ({ value, onValue, label }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        onValue(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Container
      {...getRootProps({ className: 'dropzone' })}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <input {...getInputProps()} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          display="flex"
          maxWidth="275px"
          minWidth="60px"
          height="60px"
          justifyContent="center"
          alignItems="center"
        >
          {value ? (
            <Thumb key={value.name}>
              <Box style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                <img
                  alt=""
                  src={(value as any).preview}
                  style={{
                    display: 'block',
                    maxWidth: '275px',
                    minWidth: '60px',
                    height: '60px',
                  }}
                />
              </Box>
            </Thumb>
          ) : (
            <ImageIcon style={{ fontSize: 30, color: '#C4C4C4' }} />
          )}
        </Box>
        <Button
          color="secondary"
          onClick={open}
          size="small"
          style={{ width: 'fit-content', alignSelf: 'center' }}
        >
          + Profile Logo
        </Button>
      </Box>
    </Container>
  );
};
