import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { auth, userContextAtom } from 'app';
import { sortBy } from 'lib/sort';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { makeCss, theme } from 'styles';
import { appBarHeight } from '../styles';
import HeaderQueryDropdown from './HeaderQueryDropdown';
import HeaderSearchBar from './HeaderSearchBar';

export const classes = makeCss({
  toolbar: {
    display: 'flex',
    height: appBarHeight,
    background: '#fff',
    alignItems: 'center',
    width: '100%',
  },
  search: {
    flexGrow: 1,
    width: '100%',
  },
  userText: {
    margin: '0 10px 0 10px !important',
    whiteSpace: 'nowrap',
  },
  profilePicture: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main + '!important',
  },
  userButton: {
    height: '100%',
    padding: theme.spacing(0, 1),
  },
});

interface Props {}

export default function Header(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const userContext = useRecoilValue(userContextAtom);
  const [networkAnchorEl, setNetworkAnchorEl] = React.useState<null | HTMLElement>(null);

  const contextOptions = sortBy(
    (userContext?.user?.profileContacts || []).map((contact, index) => {
      return {
        contactId: contact.id,
        label: `${contact.profile.network.name}: ${contact.profile.name}`,
      };
    }),
    (opt) => opt.label.toLowerCase(),
    'asc'
  );

  const isSuperAdmin = userContext && userContext.systemPermissionCodes.size > 0;

  return (
    <Box display="flex" bgcolor="white" alignItems="stretch" height={appBarHeight}>
      <HeaderQueryDropdown />

      <HeaderSearchBar />

      <Box display="flex" justifyContent="flex-end">
        <ButtonBase
          className={classes.userButton}
          onClick={(e) => setNetworkAnchorEl(e.currentTarget)}
        >
          <Avatar className={classes.profilePicture}>
            {userContext?.activeContact?.profile.network.name
              .split(' ')
              .slice(0, 1)
              .toString()
              .substring(0, 1)}
            {userContext?.activeContact?.profile.network.name
              .split(' ')
              .slice(1, 2)
              .toString()
              .substring(0, 1)}
          </Avatar>
          <Hidden smDown>
            <Typography variant="h3" className={classes.userText}>
              {userContext?.activeContact?.profile.network.name + ': '}
              {userContext?.activeContact?.profile.name}
            </Typography>
            <span className="material-icons">keyboard_arrow_down</span>
          </Hidden>
        </ButtonBase>

        <Menu
          id="user-menu"
          anchorEl={networkAnchorEl}
          keepMounted
          open={!!networkAnchorEl}
          onClose={() => setNetworkAnchorEl(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          {contextOptions.map((option) => {
            return (
              <MenuItem
                key={option.contactId}
                onClick={(e) => {
                  auth.changeUserContext(option.contactId, '/').catch((error) => {
                    enqueueSnackbar('Failed to change context: ' + error, { variant: 'error' });
                  });
                  setNetworkAnchorEl(null);
                }}
              >
                {option.label}
              </MenuItem>
            );
          })}
          {isSuperAdmin && <Divider />}
          {isSuperAdmin && (
            <MenuItem
              onClick={(e) => {
                navigate('/sys-admin/networks');
              }}
            >
              System Administration
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  );
}
