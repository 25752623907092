import { RouteComponentProps } from '@reach/router';
import React from 'react';
import DocumentList from './DocumentList';
import Filters from './filters';

interface Props extends RouteComponentProps {}

export default function DocumentsBrowser(props: Props) {
  return (
    <div>
      <Filters />
      <DocumentList />
    </div>
  );
}
