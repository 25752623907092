import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import HBLPage from './details';

interface HouseBillProps extends RouteComponentProps {}

function HouseBills(props: HouseBillProps) {
  return (
    <Router>
      <HBLPage path="/:shipmentId" />
    </Router>
  );
}

export default HouseBills;
