import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

const dayWidth = '2rem';

const StyledButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1.2rem',
      padding: 0,
      minWidth: 'auto',
      width: dayWidth,
      height: '2rem',
      borderRadius: '100%',
      '&:not($textPrimary)': {
        color: '#ccc',
      },
    },
    textPrimary: {
      fontWeight: 'bold',
    },
  })
)(Button);

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    week: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: 40,
    },
  })
);

interface Props {
  value: number[];
  onValue(v: number[]): void;
  disabled?: boolean;
  error?: string | null;
}

export default function FormInputMonthDays({ value, onValue, disabled, error }: Props) {
  const classes = useStyle();

  return (
    <div>
      <Week days={[1, 2, 3, 4, 5, 6, 7]} />
      <Week days={[8, 9, 10, 11, 12, 13, 14]} />
      <Week days={[15, 16, 17, 18, 19, 20, 21]} />
      <Week days={[22, 23, 24, 25, 26, 27, 28]} />
      <Week days={[29, 30, 31]} />

      {error && (
        <FormHelperText error variant="outlined">
          {error}
        </FormHelperText>
      )}
    </div>
  );

  function Week({ days }: { days: number[] }) {
    const spacers: number[] = [];
    for (let i = days.length; i < 7; i++) {
      spacers.push(i);
    }
    return (
      <div className={classes.week}>
        {days.map((day) => (
          <Day key={day} day={day} />
        ))}
        {spacers.map((i) => (
          <div key={i} style={{ width: dayWidth }} />
        ))}
      </div>
    );
  }

  function Day({ day }: { day: number }) {
    const isChecked = value.indexOf(day) >= 0;
    return (
      <StyledButton
        color={isChecked ? 'primary' : 'default'}
        onClick={(e) => {
          if (isChecked) {
            onValue(value.filter((d) => d !== day));
          } else {
            onValue(value.concat([day]));
          }
        }}
        disabled={disabled}
      >
        {day}
      </StyledButton>
    );
  }
}
