import React from 'react';
import { Grid } from '@material-ui/core';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { useRecoilValue } from 'recoil';
import { ConsolidationPageStates } from '../../states';

export default function ConfirmationSection() {
  const confirmationNumber = useRecoilValue(ConsolidationPageStates.editLogistics.confirmationNumber);
  const confirmationDate = useRecoilValue(ConsolidationPageStates.editLogistics.confirmationDate);

  const warnConfirmationNumber =
    (Boolean(confirmationNumber) || Boolean(confirmationDate)) && !Boolean(confirmationNumber);
  const warnConfirmationDate =
    (Boolean(confirmationNumber) || Boolean(confirmationDate)) && !Boolean(confirmationDate);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6}>
        <AtomicTextFieldV2
          state={ConsolidationPageStates.editLogistics.confirmationNumber}
          label="Confirmation"
          required={warnConfirmationNumber}
          helperText={warnConfirmationNumber ? 'Field required' : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        <AtomicDatePickerV2
          state={ConsolidationPageStates.editLogistics.confirmationDate}
          label="Confirmation Date"
          error={warnConfirmationDate ? 'Date required' : undefined}
        />
      </Grid>
    </Grid>
  );
}
