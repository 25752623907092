import React from 'react';
import { RouteComponentProps } from '@reach/router';

interface NotFoundProps extends RouteComponentProps {}
function NotFound(props: NotFoundProps) {
  return (
    <div>
      <h1>Page Not Found</h1>
    </div>
  );
}

export default NotFound;
