import CompleteConsolidationButton from './CompleteConsolidationButton';
import { NewConsolidationStates } from '../states';
import { useRecoilValue } from 'recoil';
import { Box, Typography } from '@material-ui/core';
import { Step2Button } from './Step2Button';

export default function NewConsolidationHeader() {
  const activeStep = useRecoilValue(NewConsolidationStates.activeStep);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={1}>
      <Typography variant="h2" color="primary">
        {activeStep === 0 ? 'Booking Selection' : 'Complete Consolidation'}
      </Typography>
      <Box> {activeStep === 0 ? <Step2Button /> : <CompleteConsolidationButton />}</Box>
    </Box>
  );
}
