import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { auth } from 'app';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { mapSearchType } from 'types/OMSEnums';
import { GUStates } from '../../states';

export default function QueryDropdown() {
  const { userContext } = auth.useAuthState();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const [searchType, setSearchType] = useRecoilState(GUStates.searchType);
  const setSearchResult = useSetRecoilState(GUStates.searchResult);

  return (
    <>
      <ButtonGroup variant="contained" color="secondary" ref={anchorRef} aria-label="split button">
        <Button
          onClick={() => {
            setOpen(false);
          }}
          style={{ minWidth: 160 }}
        >
          <p>{mapSearchType(searchType)}</p>
        </Button>
        <Button
          color="secondary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          disableElevation
          onClick={() => {
            setOpen(true);
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              width: '164px',
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <MenuList id="split-button-menu-2">
                  {userContext?.globalUpdateSearchTypes.map((code) => {
                    return (
                      <MenuItem
                        key={code}
                        value={code}
                        onClick={(e) => {
                          setSearchType(code);
                          setSearchResult(null);
                          setOpen(false);
                        }}
                      >
                        {mapSearchType(code)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
