import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  PermissionCode,
  useMblLazyQuery,
  useMblQuery,
  useNewMblRemarkMutation,
} from 'api/GQL_Types';
import { MblQuery } from 'api/queries/mblQueries';
import { userContextAtom } from 'app';
import { DocumentsPanel } from 'app/components/DocumentsPanel';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import Remarks from 'components/Remarks';
import { AtomGroupCleaner, newAtom } from 'lib/RecoilUtils';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import EquipmentPanel from './equipment';
import MBLDetailsPanel from './mbl-details-panel';
import { MBLPartyOverrideDialog } from './MBLPartyOverrideDialog';
import RelatedHblPanel from './related-hbl';
import { MblPageStates, useSetupMblPage } from './states';

const openRelatedPartiesDialog = newAtom(false);

interface mblProps extends RouteComponentProps {
  mblId?: string;
}

export default function MblDetails(props: mblProps) {
  const mblId = props.mblId ?? '';
  const userContext = useRecoilValue(userContextAtom);
  const mbl = useRecoilValue(MblPageStates.mbl);
  const allRemarks = useRecoilValue(MblPageStates.allRemarks);
  const allDocuments = useRecoilValue(MblPageStates.allDocuments);
  const [isHot, setIsHot] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const setOpenOverrideDialog = useSetRecoilState(openRelatedPartiesDialog);

  const [refetchMblQuery] = useMblLazyQuery({
    variables: { mblId: mblId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.mbl) {
        enqueueSnackbar('MBL Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenOverrideDialog(false);
      }
    },
  });

  const {
    data: mblData,
    loading,
    error,
  } = useMblQuery({
    variables: { mblId: mblId },
    fetchPolicy: 'cache-and-network',
  });

  const [saveMblRemark] = useNewMblRemarkMutation({
    refetchQueries: () => [
      {
        query: MblQuery,
        variables: { mblId: mblId },
      },
    ],
    onCompleted(data) {
      if (data.newMblRemark.success) {
        enqueueSnackbar('New MBL Remark Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  const setupMblPage = useSetupMblPage();

  React.useEffect(() => {
    setupMblPage(mblData);

    if (mblData) {
      const mbl = mblData.mbl;
      if (!mbl) {
        navigate('/bookings');
      }
    }
  }, [mblData]);

  React.useEffect(() => {
    if (mbl?.shipment.containers) {
      for (const con of mbl?.shipment.containers) {
        if (con.isHot) {
          setIsHot(true);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mbl]);

  if (error && !mbl) {
    return <ErrorMessage error={error + ''} />;
  }

  if (!mbl || loading) {
    return <Loading />;
  }

  return (
    <>
      <AtomGroupCleaner atomGroup={MblPageStates} />
      <ErrorMessage error={error ? error + '' : null} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={1}
        marginTop={1}
        marginBottom={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            MBL: {mbl?.referenceNumber}
          </Typography>
          {isHot && (
            <FontAwesomeIcon
              icon={faFire}
              style={{ color: 'orange', marginLeft: '12px' }}
              size="lg"
            />
          )}
        </Box>
        {userContext?.permissionCodes.has(PermissionCode.MblRelatedPartiesOverride) && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenOverrideDialog(true);
              }}
            >
              Mbl Party Override
            </Button>
            <MBLPartyOverrideDialog
              openState={openRelatedPartiesDialog}
              data={mbl}
              onSaved={() => {
                refetchMblQuery();
              }}
            />
          </>
        )}
      </Box>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} lg={9}>
          <MBLDetailsPanel />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RelatedHblPanel />
        </Grid>
      </Grid>

      {userContext?.permissionCodes.has(PermissionCode.MblRemarkRead) && (
        <Remarks
          title="Remarks"
          allowComments={userContext?.permissionCodes.has(PermissionCode.MblRemarkCreate)}
          remarks={allRemarks}
          makeRemark={(text) => {
            saveMblRemark({
              variables: {
                input: {
                  mblId: mblId,
                  remark: {
                    text: text,
                  },
                },
              },
            });
          }}
        />
      )}

      <EquipmentPanel />

      <DocumentsPanel documents={allDocuments} showBookingNumber showHblNumber />
    </>
  );
}
