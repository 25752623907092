import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { PopoverTable } from '../components/PopoverTable';
import { PoTable } from '../states';

const columns: UWLTableColumn<PoTable>[] = [
  { id: 'poLink', label: 'PO #', type: 'link' },
  { id: 'lineStatus', label: 'Status', type: 'string' },
  { id: 'itemNumber', label: 'Item / SKU', type: 'string' },
  { id: 'qty', label: 'Total Qty', type: 'number' },
  { id: 'vendor', label: 'Vendor', type: 'string' },
  { id: 'shipToLocationName', label: 'Destination', type: 'string' },
  { id: 'expectedCargoReadyDate', label: 'Expected CRD', type: 'date' },
  { id: 'indcDate', label: 'Expected InDC', type: 'date' },
  {
    id: 'poValue',
    label: 'PO Value',
    type: 'currency',
    currencyField: 'poValueCurrencyCode',
  },
];

const exportColumns = columns;

interface Props {
  open: boolean;
  anchorEl: any;
  onClose(): void;
  isLoading: boolean;
  error: string | null;
  rows: PoTable[];
  title: string;
}

export default function POButtonsPopover({
  open,
  anchorEl,
  onClose,
  isLoading,
  error,
  rows,
  title,
}: Props) {
  const windowSize = useWindowSize();

  return (
    <PopoverTable
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      title={title}
      titleRight={
        isLoading && rows.length === 0 ? undefined : (
          <TableExportWindowGroup label={title} rows={rows} columns={exportColumns} />
        )
      }
      totalLabel="Total Purchase Orders"
      totalValue={isLoading && rows.length === 0 ? '...' : formatNumber(rows.length)}
      width={windowSize.width * 0.8}
      bodyHeight={isLoading ? 200 : rows.length * STANDARD_ROW_OVERALL_HEIGHT}
    >
      {open && (
        <UWLTable
          rowId={'id'}
          rows={rows}
          columns={columns}
          isLoading={isLoading}
          error={error}
          emptyMessage="No Purchase Orders"
          virtualize={'single-line-cells'}
        />
      )}
    </PopoverTable>
  );
}
