import { FormInputPhoneNumber } from 'components/form/FormInputPhoneNumber';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

export interface Props {
  state: RecoilState<string>;

  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const AtomicPhoneNumberV2: React.FC<Props> = (props) => {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputPhoneNumber
        value={state}
        onValue={setState}
        label={props.label}
        disabled={props.disabled}
        required={props.required}
      />
    </FormItem>
  );
};
