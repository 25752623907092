import { useDeleteRoleMutation } from 'api/GQL_Types';
import { ProfileTypeQuery } from 'api/queries/profileTypeQueries';
import { DeleteConfirmationDialog } from 'components/DeleteConfirmationDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { RoleDialog_Data } from './RoleDialogComponent';

interface RoleDeleteDialogSetupData {
  roleId: string;
  profileTypeId: string;
}

export const RoleDeleteDialog_Data = newAtom<RoleDeleteDialogSetupData | null>(null);

interface Props {}

export const RoleDeleteDialog: React.FC<Props> = (props) => {
  const [dialogData, setDialogData] = useRecoilState(RoleDeleteDialog_Data);
  const setRoleDialog = useSetRecoilState(RoleDialog_Data);
  const [error, setError] = React.useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteRole, { loading }] = useDeleteRoleMutation({
    refetchQueries: () => [
      {
        query: ProfileTypeQuery,
        variables: { profileTypeId: dialogData?.profileTypeId },
      },
    ],
    onCompleted(data) {
      if (data.deleteRole.success) {
        setDialogData(null);
        setRoleDialog(null);

        enqueueSnackbar('Profile Type Template Deleted!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        setError(data.deleteRole.message || 'Failed to delete role.');
      }
    },
    onError(error) {
      setError('Failed to delete role. ' + error);
    },
  });

  return (
    <DeleteConfirmationDialog
      open={!!dialogData}
      onClose={() => {
        setDialogData(null);
      }}
      title="Delete Role"
      message="Are you sure you want to delete this role?"
      deleteBtnText="Delete Role"
      error={error}
      waiting={loading}
      onClickDelete={() => {
        if (!dialogData) return;

        deleteRole({
          variables: {
            input: {
              roleId: dialogData.roleId,
            },
          },
        });
      }}
    />
  );
};
