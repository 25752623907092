import { gql } from '@apollo/client/core';

export const OpenOrdersQuery = gql`
  query OpenOrders {
    openOrders {
      id
      orderQty
      approvedQty
      balanceQty
      shippedQty
      shippedCartons
      isHot
      orderLine {
        id
        lineNumber
        itemNumber
        purchaseOrder {
          id
          poNumber
          supplierName
        }
        totalQty
        innerQty
        outerQty
        weight
        weightUnit
        weightKg
        volume
        volumeUnit
        volumeM3
        expectedCargoReadyDate
        revisedCargoReadyDate
        shipToLocation {
          id
          name
        }
        firstShipDate
        lastShipDate
      }
    }
  }
`;

export const OpenOrdersRelatedPartiesQuery = gql`
  query OpenOrdersRelatedParties($orderLineIds: [ID!]!) {
    openOrders(orderLineIds: $orderLineIds) {
      orderLine {
        id
        purchaseOrder {
          relatedParties {
            partyType
            party {
              id
              name
              locations {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
