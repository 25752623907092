import { Box, Button, Fab, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { RuleFieldType, useSystemDocumentTypesQuery } from 'api/GQL_Types';
import { CheckOnOffIcon } from 'components/CheckOnOffIcon';
import { PanelBase } from 'components/Panel';
import { RemainingHeightLayout } from 'components/RemainingHeightLayout';
import SearchBar from 'components/SearchBar';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { theme } from 'styles';
import { formatNumber } from 'types/Number';
import { UWLTableColumn } from 'types/UWLTable';
import { AddDocumentTypeDialog } from './AddDocumentTypeDialog';

interface DocumentTypeRow {
  code: string;
  name: string;
  activeOnNewNetworks: boolean;
  ruleMissingEnabled: boolean;
  ruleMissingDays: number;
  ruleMissingField: RuleFieldType;
  isDeactivated: boolean;
  networks: {
    id: string;
    name: string;
    ruleMissingEnabled: boolean;
    ruleMissingDays: number;
    ruleMissingField: RuleFieldType;
  }[];

  filterKey: string; // The text rows can be filtered by with the quick search
}

const columns: UWLTableColumn<DocumentTypeRow>[] = [
  { id: 'code', label: 'Code', type: 'string' },
  { id: 'name', label: 'Description', type: 'string' },
  { id: 'networks', label: 'Networks', type: 'string' },
  { id: 'activeOnNewNetworks', label: 'Active By Default', type: 'string' },
];

interface Props extends RouteComponentProps {}

export const DocumentTypesListPage: React.FC<Props> = (props) => {
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<DocumentTypeRow[]>([]);
  const [searchField, setSearchField] = React.useState<string>('');

  const { data, loading, error } = useSystemDocumentTypesQuery();

  React.useEffect(() => {
    const newRows: DocumentTypeRow[] = [];
    if (data) {
      for (const sdt of data.systemDocumentTypes) {
        const row: DocumentTypeRow = {
          code: sdt.code,
          name: sdt.name,
          activeOnNewNetworks: sdt.activeOnNewNetworks,
          ruleMissingEnabled: sdt.ruleMissingEnabled,
          ruleMissingDays: sdt.ruleMissingDays,
          ruleMissingField: sdt.ruleMissingField,
          isDeactivated: !!sdt.deleteDate,
          networks: [],
          filterKey: `${sdt.code}|${sdt.name}`,
        };
        for (const n of data.allNetworks) {
          for (const dt of n.documentTypes) {
            if (dt.code === row.code) {
              const rule = n.rules.documentTypes.find((i) => i.documentType === dt.code);
              row.networks.push({
                id: n.id,
                name: n.name,
                ruleMissingEnabled: rule ? rule.missingEnabled : sdt.ruleMissingEnabled,
                ruleMissingDays: rule ? rule.missingDays : sdt.ruleMissingDays,
                ruleMissingField: rule ? rule.missingField : sdt.ruleMissingField,
              });
            }
          }
        }
        newRows.push(row);
      }
    }
    setRows(newRows);
  }, [data]);

  const searchFieldQ = searchField.trim().toLowerCase();
  const filteredRows = rows.filter((u) => u.filterKey.includes(searchFieldQ));

  function goToManagePage(code: string) {
    navigate(`/sys-admin/document-types/${code}`);
  }

  return (
    <div
      style={{
        padding: theme.spacing(2, 3, 0, 3),
        height: '100%',
      }}
    >
      <RemainingHeightLayout
        top={
          <div>
            <Box display="flex" justifyContent="space-between" marginBottom={3}>
              <Box>
                <Typography variant="h2">Document Types</Typography>
                <Typography variant="body1">
                  {loading
                    ? 'loading...'
                    : filteredRows.length !== rows.length
                    ? `${filteredRows.length}/${rows.length} Total`
                    : `${rows.length} Total`}
                </Typography>
              </Box>
              <Box width="25%" bgcolor="#FFFFFF" padding={1} alignSelf="center" borderRadius="4px">
                <SearchBar
                  placeholder="Search Document Types"
                  field={searchField}
                  updateField={setSearchField}
                />
              </Box>
            </Box>
          </div>
        }
        bottomMinHeight={300}
        bottom={
          <PanelBase>
            <div style={{ height: theme.spacing(1) }} />
            <UWLTable
              rowId="code"
              isLoading={loading}
              error={error ? error + '' : null}
              columns={columns}
              rows={filteredRows}
              virtualize="single-line-cells"
              renderCell={{
                code(row) {
                  if (row.isDeactivated) {
                    return <span style={{ color: theme.palette.text.disabled }}>{row.code}</span>;
                  }
                  return row.code;
                },
                name(row) {
                  if (row.isDeactivated) {
                    return <span style={{ color: theme.palette.text.disabled }}>{row.name}</span>;
                  }
                  return row.name;
                },
                networks(row) {
                  if (row.isDeactivated) {
                    return <i>Deactivated</i>;
                  }
                  const nNetworks = data?.allNetworks.length || 0;
                  return (
                    <span>
                      {formatNumber(row.networks.length)} / {formatNumber(nNetworks)}
                    </span>
                  );
                },
                activeOnNewNetworks(row) {
                  if (row.isDeactivated) {
                    return <i>Deactivated</i>;
                  }
                  return <CheckOnOffIcon on={row.activeOnNewNetworks} />;
                },
              }}
              onRowClick={(row) => {
                goToManagePage(row.code);
              }}
              rowAction={(row) => {
                return (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToManagePage(row.code);
                    }}
                  >
                    Manage
                  </Button>
                );
              }}
            />
          </PanelBase>
        }
      />
      <Box position="absolute" bottom="48px" right="36px">
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          onClick={() => {
            setShowAddDialog(true);
          }}
        >
          <i className="material-icons" style={{ marginRight: '8px' }}>
            {'add'}
          </i>
          Create New Document Type
        </Fab>
      </Box>
      {showAddDialog && (
        <AddDocumentTypeDialog
          onClose={() => {
            setShowAddDialog(false);
          }}
        />
      )}
    </div>
  );
};
