import { GqlMasterBill, GqlMblQuery, GqlMblRelatedHblFragment } from 'api/GQL_Types';
import { Document } from 'app/components/DocumentsPanel';
import { TableLink } from 'app/dashboard/states';
import { Remark, sortRemarks } from 'components/Remarks';
import { newAtom } from 'lib/RecoilUtils';
import { useSetRecoilState } from 'recoil';

export const MblPageStates = {
  mbl: newAtom<GqlMasterBill | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  allDocuments: newAtom<Document[]>([]),
  relatedHBLs: newAtom<RelatedHBL[]>([]),
};

export interface RelatedHBL {
  id: string;
  hbl: TableLink;
  type: string;
  booking: TableLink;
}

export function useSetupMblPage() {
  const setMbl = useSetRecoilState(MblPageStates.mbl);
  const setAllRemarks = useSetRecoilState(MblPageStates.allRemarks);
  const setAllDocuments = useSetRecoilState(MblPageStates.allDocuments);
  const setRelatedHBLs = useSetRecoilState(MblPageStates.relatedHBLs);

  return (init: GqlMblQuery | undefined) => {
    const mbl = init?.mbl;
    if (!mbl) {
      setMbl(null);
      setAllRemarks([]);
      setAllDocuments([]);
      setRelatedHBLs([]);
      return;
    }

    const relatedHbls: GqlMblRelatedHblFragment[] = [];

    if (mbl.shipment.__typename === 'Booking' && mbl.shipment.hbl) {
      relatedHbls.push(mbl.shipment.hbl);
    } else if (mbl.shipment.__typename === 'Consolidation') {
      for (const booking of mbl.shipment.bookings) {
        if (booking.hbl) {
          relatedHbls.push(booking.hbl);
        }
      }
    }

    let remarks: Remark[] = [];
    for (const hbl of relatedHbls) {
      remarks = remarks.concat(hbl.remarks);
    }
    remarks = remarks.concat(mbl?.shipment.remarks ?? []);
    remarks = remarks.concat(mbl?.remarks ?? []);
    remarks = sortRemarks(remarks);
    setAllRemarks(remarks);

    let hblDocuments: Document[] = [];
    let bookingDocuments: Document[] = [];
    let mblDocuments: Document[] = [];
    for (const doc of mbl.documents) {
      mblDocuments.push({
        id: doc.id,
        bookingId: undefined,
        bookingNumber: undefined,
        hblId: undefined,
        hblNumber: undefined,
        documentType: doc.documentType,
        fileName: doc.fileName,
        importFileName: doc.importFileName,
        fileType: doc.fileType,
        fileSize: doc.fileSize,
        uploadDate: doc.uploadDate,
        description: doc.description || '',
      });
    }
    for (const doc of mbl.shipment.documents) {
      bookingDocuments.push({
        id: doc.id,
        bookingId: mbl.shipment.id,
        bookingNumber: mbl.shipment.referenceNumber,
        hblId: (mbl.shipment.__typename === 'Booking' && mbl.shipment.hbl?.id) || undefined,
        hblNumber:
          (mbl.shipment.__typename === 'Booking' && mbl.shipment.hbl?.referenceNumber) || undefined,
        documentType: doc.documentType,
        fileName: doc.fileName,
        importFileName: doc.importFileName,
        fileType: doc.fileType,
        fileSize: doc.fileSize,
        uploadDate: doc.uploadDate,
        description: doc.description || '',
      });
    }
    for (const hbl of relatedHbls) {
      for (const doc of hbl.documents) {
        hblDocuments.push({
          id: doc.id,
          bookingId: undefined,
          bookingNumber: undefined,
          hblId: hbl.id,
          hblNumber: hbl.referenceNumber,
          documentType: doc.documentType,
          fileName: doc.fileName,
          importFileName: doc.importFileName,
          fileType: doc.fileType,
          fileSize: doc.fileSize,
          uploadDate: doc.uploadDate,
          description: doc.description || '',
        });
      }
    }
    setAllDocuments([...bookingDocuments, ...mblDocuments, ...hblDocuments]);
    setMbl(mbl as GqlMasterBill);

    setRelatedHBLs(
      relatedHbls.map((hbl): RelatedHBL => {
        return {
          id: hbl.id,
          hbl: {
            to: '/hbl/' + hbl.id,
            value: hbl.referenceNumber,
          },
          type: hbl.releaseType || '',
          booking: {
            to: '/bookings/' + hbl.booking.id,
            value: hbl.booking.referenceNumber,
          },
        };
      })
    );
  };
}
