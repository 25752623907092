import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import { useRecoilValue } from 'recoil';
import { mapIncoTerm, mapMoveType } from 'types/OMSEnums';
import { EquipmentPageStates } from './states';

export default function EquipmentDetailsPanel() {
  const userContext = useRecoilValue(userContextAtom);
  const container = useRecoilValue(EquipmentPageStates.container);

  if (!container) {
    return null;
  }

  return (
    <Box width="100%" height="100%" display="flex">
      <Panel title="Equipment Details">
        <Box padding={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LineItem value={container?.containerNumber} label="Container" />
              <LineItem value={container?.sealNumber} label="Seal" />
              <LineItem value={container?.supplierName} label="Supplier" />
              <LineItem value={container?.motherVesselName} label="Vessel" />
              <LineItem
                value={container?.mbl}
                label="MBL"
                customValue={(value) => {
                  if (!value) return <span />;
                  return userContext?.permissionCodes.has(PermissionCode.MblRead) ? (
                    <Link to={'/mbl/' + value.id}>{value.referenceNumber}</Link>
                  ) : (
                    <Typography variant="body1">{value.referenceNumber}</Typography>
                  );
                }}
              />
              <LineItem
                value={container?.moveType ? mapMoveType(container?.moveType) : ''}
                label="Delivery Type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LineItem value={container?.containerType} label="Equipment" />
              <LineItem value={container?.pickupLocationName} label="Place of Receipt" />
              <LineItem value={container?.consigneeName} label="Consignee" />
              <LineItem value={container?.carrierName} label="Carrier" />
              <LineItem value={container?.motherVoyageName} label="Voyage" />
              {container?.incoTerm && (
                <LineItem value={mapIncoTerm(container.incoTerm)} label="INCO Term" />
              )}
            </Grid>
          </Grid>
        </Box>
      </Panel>
    </Box>
  );
}
