import { Paper, Box } from '@material-ui/core';
import React from 'react';

interface Props {
  noHeightLimit?: boolean;
}

export default function CompositePanel(props: React.PropsWithChildren<Props>) {
  return (
    <Box width="100%" height="100%">
      <Paper
        style={{
          height: props.noHeightLimit ? undefined : '100%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          {props.children}
        </Box>
      </Paper>
    </Box>
  );
}
