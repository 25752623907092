import { Grid } from '@material-ui/core';
import { ConsolidationPageStates } from 'app/consolidations/details/states';
import AtomicContainerType from 'components/atomic/AtomicContainerType';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';

export default function EquipmentFormFields() {
  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={ConsolidationPageStates.containerAssignment.editContainer.containerNumber}
            label="Container"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicContainerType
            state={ConsolidationPageStates.containerAssignment.editContainer.containerType}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={ConsolidationPageStates.containerAssignment.editContainer.sealNumber}
            label="Seal #"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.containerAssignment.editContainer.cntrConsolidationDate}
            label="Cntr Consolidation Date"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.containerAssignment.editContainer.shipFromCfs}
            label="Ship From CFS"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={ConsolidationPageStates.containerAssignment.editContainer.terminalReceived}
            label="Terminal Received"
          />
        </Grid>
      </Grid>
    </>
  );
}
