import { Button } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { ManagePageTabHeader } from 'app/admin/components/ManagePageTabHeader';
import { FloatingAddButton } from 'components/FloatingAddButton';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { genKey } from 'lib/RecoilUtils';
import { theme } from 'styles';
import { formatNumber } from 'types/Number';
import { NetworkAdminPageStates } from '../states';
import { AddRuleDialog } from './AddRuleDialog';
import { RelationGroup } from './RelationGroup';
import { RelatedProfileRuleGroup } from './states';

interface Props extends RouteComponentProps {}

export const NetworkRelatedProfiles: React.FC<Props> = (props) => {
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const profiles = useRecoilValue(NetworkAdminPageStates.network)?.connectedProfiles || [];
  const [profileNameLookup, setProfileNameLookup] = React.useState<Map<string, string>>(
    () => new Map()
  );
  const [groups, setGroups] = React.useState<RelatedProfileRuleGroup[]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [addRuleDialog, setAddRuleDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    setGroups(network?.relatedProfileRuleGroups || []);
  }, [network?.relatedProfileRuleGroups]);

  const searchTextQuery = searchText.trim().toLowerCase();

  React.useEffect(() => {
    if (searchTextQuery.length > 0) {
      setGroups((groups) => groups.map((g) => (g.isExpanded ? g : { ...g, isExpanded: true })));
    } else {
      setGroups((groups) => groups.map((g) => (g.isExpanded ? { ...g, isExpanded: false } : g)));
    }
  }, [searchTextQuery.length > 0]);

  React.useEffect(() => {
    const lookup = new Map<string, string>();
    if (profiles) {
      for (const profile of profiles) {
        lookup.set(profile.id, profile.name);
      }
    }
    setProfileNameLookup(lookup);
  }, [profiles]);

  const groupsFiltered: RelatedProfileRuleGroup[] =
    searchTextQuery.length > 0
      ? groups.filter((g) => {
          const whenName = profileNameLookup.get(g.profileId) || '';
          let searchKey = whenName;
          for (const row of g.rules) {
            const sName = row.profileId ? profileNameLookup.get(row.profileId) || '' : '';
            searchKey += `|${sName}`;
          }
          return searchKey.toLowerCase().includes(searchTextQuery);
        })
      : groups;

  return (
    <div>
      <ManagePageTabHeader
        title="Related Profiles"
        subTitle="These rules setup how the profiles are related to each other."
        searchBar={{
          placeholder: 'Search by profile name',
          value: searchText,
          onValue: setSearchText,
        }}
      />

      {groups.length === 0 && (
        <div>
          <div>No related profile rules.</div>
          <div>Click the "Add Rule" button in the bottom right, to start creating rules.</div>
        </div>
      )}

      {groupsFiltered.map((group) => {
        return (
          <RelationGroup
            key={group.id}
            group={group}
            onChange={(g) => {
              setGroups((groups) => {
                return groups.map((group) => {
                  if (group.id === g.id) {
                    return g;
                  }
                  return group;
                });
              });
            }}
            onRemove={() => setGroups((groups) => groups.filter((g) => g.id !== group.id))}
          />
        );
      })}

      {groupsFiltered.length === 0 && groups.length > 0 && (
        <div>
          <div>No matches for "{searchText}"</div>
          <Button variant="text" color="primary" size="small" onClick={() => setSearchText('')}>
            clear
          </Button>
        </div>
      )}

      {groupsFiltered.length > 0 && groups.length > 0 && groupsFiltered.length < groups.length && (
        <div style={{ marginTop: theme.spacing(2) }}>
          <div>
            Showing {formatNumber(groupsFiltered.length)} of {formatNumber(groups.length)} rules for
            search "{searchText}"
          </div>
          <Button variant="text" color="primary" size="small" onClick={() => setSearchText('')}>
            clear
          </Button>
        </div>
      )}

      {/* adding space so you can scroll to see the full table without running into the add button */}
      <br />
      <br />
      <br />
      <br />

      <FloatingAddButton onClick={() => setAddRuleDialog(true)}>Add Rule</FloatingAddButton>

      {addRuleDialog && (
        <AddRuleDialog
          onClose={() => setAddRuleDialog(false)}
          onAdd={(profileId, partyType) => {
            setAddRuleDialog(false);

            let isFound = false;
            const newGroups: RelatedProfileRuleGroup[] = [];
            for (const group of groups) {
              if (group.profileId === profileId && group.partyType === partyType) {
                newGroups.push({ ...group, isExpanded: true });
                isFound = true;
              } else {
                newGroups.push({ ...group, isExpanded: false });
              }
            }
            if (!isFound) {
              newGroups.push({
                id: genKey(),
                profileId,
                partyType,
                isExpanded: true,
                rules: [
                  {
                    id: genKey(),
                    partyType: null,
                    profileId: null,
                    condition: null,
                    port: null,
                    country: null,
                    category: null,
                    department: null,
                    material: null,
                  },
                ],
                originalRules: [],
              });
            }

            setGroups(newGroups);
          }}
        />
      )}
    </div>
  );
};
