import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';
import ErrorMessage from './ErrorMessage';

const DeleteDialog: React.FC<{
  title: string;
  yesBtnText?: string;
  doDelete: () => Promise<void>;
  onClose: (deleted: boolean) => void;
}> = (props) => {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);

  return (
    <Dialog maxWidth="xs" open>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.children}

        <ErrorMessage error={error} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            if (isDeleting) return;
            props.onClose(false);
          }}
          color="primary"
          disabled={isDeleting}
        >
          No, cancel
        </Button>
        <Button
          onClick={() => {
            if (isDeleting) return;
            setIsDeleting(true);
            setError(null);
            props
              .doDelete()
              .then(() => props.onClose(true))
              .catch((err) => {
                setIsDeleting(false);
                setError(err + '');
              });
          }}
          variant="contained"
          color="primary"
          disabled={isDeleting}
        >
          {props.yesBtnText || 'Yes, delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
