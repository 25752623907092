import {
  GqlNetworkContainerTypeInput,
  GqlNetworkQuery,
  GqlNetworkRulesInput,
  LocationType,
  NotificationCode,
  PermissionCode,
} from 'api/GQL_Types';
import { AdminPagesContact, fromGQL_AdminPagesContact } from 'app/admin/states';
import { sortBy } from 'lib/sort';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import {
  fromGQL_RelatedProfileRuleGroups,
  RelatedProfileRuleGroup,
} from './related-profiles/states';

export interface NetworkAdminPageData {
  id: string;
  name: string;
  connectedProfiles: NetworkAdminConnectedProfiles[];
  profileTypes: NetworkAdminProfileType[];
  items: NetworkAdminItem[];
  rules: NetworkRules;
  containerTypes: NetworkContainerType[];
  relatedProfileRuleGroups: RelatedProfileRuleGroup[];
}

export type NetworkRules = GqlNetworkRulesInput;
export type NetworkContainerType = GqlNetworkContainerTypeInput;

export const NetworkAdminPageStates = {
  errorLoadingNetwork: newAtom<string | null>(null),
  network: newAtom<NetworkAdminPageData | null>(null),
  rulesForm: newAtom<NetworkRules | null>(null),
  containerTypeForm: newAtom<NetworkContainerType[]>([]),
};

export function useSetNetworkAdminPageState() {
  const setNetwork = useSetRecoilState(NetworkAdminPageStates.network);
  const setRulesForm = useSetRecoilState(NetworkAdminPageStates.rulesForm);
  const setContainerTypeForm = useSetRecoilState(NetworkAdminPageStates.containerTypeForm);

  return (data: GqlNetworkQuery | null) => {
    if (!data?.network) {
      setNetwork(null);
      setRulesForm(null);
      return;
    }

    const network: NetworkAdminPageData = {
      id: data.network.id,
      name: data.network.name,
      connectedProfiles: sortBy(
        data.network.connectedProfiles.map((profile): NetworkAdminConnectedProfiles => {
          return {
            id: profile.id,
            name: profile.name,
            profileType: profile.profileType || null,
            isControllingClient: profile.isControllingClient,
            locations: normalizeProfileLocations(profile.locations),
            logo: profile.logo ? { url: profile.logo.url } : null,
            needsUpdate: profile.needsUpdate,
            contacts: profile.contacts.map(fromGQL_AdminPagesContact),
            roles: normalizeProfileRole(profile.roles),
            network: profile.network,
          };
        }),
        (profile) => {
          return (profile.isControllingClient ? 'A' : 'Z') + profile.name.trim().toLowerCase();
        },
        'asc'
      ),
      profileTypes: data.network.profileTypes?.map((pt) => {
        return {
          id: pt.id,
          name: pt.name || '',
          description: pt.description || '',
          isTemplate: pt.isTemplate || false,
          permissionCodes: pt.permissionCodes || [],
          defaultRoles: pt.defaultRoles || [],
        };
      }),
      items: data.network.items?.map((item) => {
        return {
          id: item.id,
          itemNumber: item.itemNumber,
          description: item.description,
          htsCode: item.htsCode,
          supplierName: item.supplier?.name || '',
        };
      }),
      rules: data.network.rules,
      containerTypes: data.network.containerTypes.map(({ __typename, ...ct }) => ct),
      relatedProfileRuleGroups: fromGQL_RelatedProfileRuleGroups(data.network.relatedProfileRules),
    };
    setNetwork(network);
    const { __typename, ...rulesForm } = data.network.rules;
    // omit __typename
    setRulesForm({
      ...rulesForm,
      documentTypes: rulesForm.documentTypes.map(({ __typename, ...dt }) => dt),
    });
    setContainerTypeForm(network.containerTypes);
  };
}

export interface NetworkAdminConnectedProfiles {
  id: string;
  name: string;
  profileType: NetworkAdminProfile | null;
  isControllingClient: boolean;
  logo: NetworkAdminProfileLogo | null;
  needsUpdate: boolean;
  locations: NetworkAdminProfileLocation[];
  contacts: AdminPagesContact[];
  roles: NetworkAdminRole[];
  network: {
    id: string;
    name: string;
  };
}

export interface NetworkAdminProfileLocation {
  id: string;
  name: string;
  code: string;
  nameAliases: string[];
  codeAliases: string[];
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  timeZone: string;
  companyPhone: string;
  companyUrl: string;
  locationTypes: LocationType[];
  relatedPort: {
    code: string;
    name: string;
  };
}

export interface NetworkAdminRole {
  id: string;
  name: string;
  description: string;
  isDefaultRole: boolean;
  permissionCodes: PermissionCode[];
  notificationCodes: NotificationCode[];
}

export interface NetworkAdminProfile {
  id: string;
  name: string;
}

export interface NetworkAdminProfileType {
  id: string;
  name: string;
  description: string;
  isTemplate: boolean;
  permissionCodes: PermissionCode[];
  defaultRoles: {
    id: string;
    name: string;
  }[];
}

export interface NetworkAdminItem {
  id: string;
  itemNumber: string;
  description: string;
  htsCode: string;
  supplierName: string;
}

export interface NetworkAdminProfileLogo {
  url: string;
}

interface GqlPickLocation {
  id: string;
  name?: string | null;
  code?: string | null;
  nameAliases?: string[] | null;
  codeAliases?: string[] | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  timeZone?: string | null;
  companyPhone?: string | null;
  companyUrl?: string | null;
  locationTypes: LocationType[];
  relatedPort?: GqlPickPort | null;
}

interface GqlPickPort {
  code: string;
  name: string;
}

interface GqlPickRole {
  id: string;
  name?: string | null;
  description?: string | null;
  isDefaultRole?: boolean;
  permissionCodes?: PermissionCode[];
  notificationCodes?: NotificationCode[];
}

function normalizeProfileLocations(locations: GqlPickLocation[]): NetworkAdminProfileLocation[] {
  if (!locations) {
    return [];
  }

  return locations.map((loc) => {
    return {
      id: loc.id || '',
      name: loc.name || '',
      code: loc.code || '',
      nameAliases: loc.nameAliases || [],
      codeAliases: loc.codeAliases || [],
      addressLine1: loc.addressLine1 || '',
      addressLine2: loc.addressLine2 || '',
      city: loc.city || '',
      state: loc.state || '',
      postalCode: loc.postalCode || '',
      country: loc.country || '',
      timeZone: loc.timeZone || '',
      companyPhone: loc.companyPhone || '',
      companyUrl: loc.companyUrl || '',
      locationTypes: loc.locationTypes,
      relatedPort: {
        name: loc.relatedPort?.name || '',
        code: loc.relatedPort?.code || '',
      },
    };
  });
}

function normalizeProfileRole(roles: GqlPickRole[]): NetworkAdminRole[] {
  if (!roles) {
    return [];
  }

  return roles.map((role) => {
    return {
      id: role.id,
      name: role.name || '',
      description: role.description || '',
      isDefaultRole: role.isDefaultRole || false,
      permissionCodes: role.permissionCodes || [],
      notificationCodes: role.notificationCodes || [],
    };
  });
}
