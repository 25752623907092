import { Box } from '@material-ui/core';
import React from 'react';
import { theme } from 'styles';

interface CardProps {
  accentColor?: string; // If Card is need a side accent, the color would be passed by props
  width?: number; // If width is needing to be defined otherwise will be treated as 100%
}
export function ListCard(props: React.PropsWithChildren<CardProps>) {
  //stylized Card that takes in a side accent and width to be used throughout the site.
  return (
    <Box
      bgcolor={theme.palette.common.white}
      boxShadow={4}
      borderLeft={props.accentColor ? '4px solid' + props.accentColor : null}
      borderRadius="4px"
      width={props.width ? props.width : 1}
    >
      {props.children}
    </Box>
  );
}
