import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { DatasetFilter, DatasetFilterable } from 'types/Dataset';
import { getFilterType } from '.';
import { StyledTableCell } from '../UWLTable/UWLTable';

const classes = makeCss({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 2, 0, 2),
  },
});

interface Props {
  filterables: DatasetFilterable[];

  value: DatasetFilter[];
  onValue(v: DatasetFilter[]): void;

  disabled?: boolean;

  bottomRight?: any;
}

export function FilterInputs({
  filterables,
  value,
  onValue,

  disabled,

  bottomRight,
}: Props) {
  const anchorEl = React.useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const filterInputs: DatasetFilter[] = value.slice(0);

  const unusedFilterables: DatasetFilterable[] = [];
  let hasOptionalFilters = false;

  for (const f of filterables) {
    const filter = value.find((v) => v.field === f.field);
    if (!filter) {
      const datasetFilter = getFilterType(f.type);
      if (f.required && datasetFilter) {
        filterInputs.push({
          field: f.field,
          value: datasetFilter.serializeValue(f, datasetFilter.defaultValue(f)),
          applied: true,
        });
      } else {
        unusedFilterables.push(f);
      }
    }
    if (!f.required) {
      hasOptionalFilters = true;
    }
  }

  React.useEffect(() => {
    // When there are required fields, set them so the form can validate the inputs before going on
    onValue(filterInputs.slice(0));
  }, []);

  return (
    <div>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell variant="head" align="left" width={175}>
              Field
            </StyledTableCell>
            <StyledTableCell variant="head" align="center" />
            <StyledTableCell variant="head" align="center" width={1}>
              {hasOptionalFilters ? 'Applied' : ''}
            </StyledTableCell>
            <StyledTableCell variant="head" width={1} />
          </TableRow>
        </TableHead>
        <TableBody>
          {filterInputs.map((v, i) => {
            return (
              <FilterInputRow
                key={i}
                filterables={filterables}
                disabled={disabled}
                value={v}
                onValue={(v) => {
                  const newV = filterInputs.slice(0);
                  if (v) {
                    newV[i] = v;
                  } else {
                    newV.splice(i, 1);
                  }
                  onValue(newV);
                }}
              />
            );
          })}
          {filterInputs.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={5} align="center">
                - No Filters -
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className={classes.footer}>
        <div ref={anchorEl}>
          {unusedFilterables.length > 0 ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => {
                setMenuOpen(true);
              }}
              disabled={disabled}
            >
              Add Filter
            </Button>
          ) : null}
        </div>
        {bottomRight}
      </div>

      <Menu
        anchorEl={menuOpen ? anchorEl.current : null}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        getContentAnchorEl={null} // Needed so anchorOrigin.vertical will work
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {unusedFilterables.map((filterable) => {
          return (
            <MenuItem
              key={filterable.field}
              onClick={() => {
                const datasetFilter = getFilterType(filterable.type);
                if (datasetFilter) {
                  onValue(
                    filterInputs.concat([
                      {
                        field: filterable.field,
                        value: datasetFilter.serializeValue(
                          filterable,
                          datasetFilter.defaultValue(filterable)
                        ),
                        applied: true,
                      },
                    ])
                  );
                }
                setMenuOpen(false);
              }}
            >
              {filterable.label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

const FilterInputRow: React.FC<{
  filterables: DatasetFilterable[];
  value: DatasetFilter;
  onValue(v: DatasetFilter | null): void;
  disabled?: boolean;
}> = ({ filterables, value, onValue, disabled }) => {
  const filterable = filterables.find((f) => f.field === value.field);
  const datasetFilter = filterable ? getFilterType(filterable.type) : null;

  return (
    <TableRow>
      <StyledTableCell align="left">{filterable ? filterable.label : value.field}</StyledTableCell>
      <StyledTableCell align="left">
        {!filterable ? (
          `Error, missing "${value.field}" filterable.`
        ) : !datasetFilter ? (
          `Error, missing "${filterable.type}" filter component.`
        ) : (
          <datasetFilter.RenderInput
            filterable={filterable}
            disabled={disabled}
            value={datasetFilter.deserializeValue(filterable, value.value)}
            onValue={(inputValue) => {
              if (filterable && datasetFilter) {
                onValue({
                  ...value,
                  value: datasetFilter.serializeValue(filterable, inputValue),
                  applied: true, // automatically set to applied if they make a change to it, since they probably intend to use their change
                });
              }
            }}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        {filterable?.required ? null : (
          <Switch
            checked={value.applied}
            onChange={(e) => {
              const applied = e.target.checked;
              onValue({ ...value, applied });
            }}
            color="secondary"
            disabled={disabled}
          />
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        {filterable?.required ? null : (
          <IconButton onClick={() => onValue(null)} disabled={disabled}>
            <CloseIcon />
          </IconButton>
        )}
      </StyledTableCell>
    </TableRow>
  );
};
