import { Typography } from '@material-ui/core';
import { DashboardFeatureCode, GqlPackLine } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { useDivClientWidthHeight } from 'lib/useDivClientWidthHeight';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import BookingBarLabels from './BookingBarLabels';

interface Props {}

export function addPackLineTotals(totals: GqlPackLine[]): number {
  let total = 0;
  for (const t of totals) {
    total += t.linePrice ?? 0;
  }
  return total;
}

export default function DashboardBookingsDetails(props: Props) {
  const userContext = useRecoilValue(userContextAtom);
  const { ref: rootRef, height: divHeight } = useDivClientWidthHeight();
  const height = Math.max(40, (divHeight - 80) / 4 - 10);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
      }}
      ref={rootRef}
    >
      <div>
        {userContext?.dashboardFeatureCodes.has(
          DashboardFeatureCode.ChartBookingsByWeekDetailsContainers
        ) && <LeftLabelBox rowHeight={height}>Total Containers</LeftLabelBox>}
        {userContext?.dashboardFeatureCodes.has(
          DashboardFeatureCode.ChartBookingsByWeekDetailsSku
        ) && <LeftLabelBox rowHeight={height}>SKU/Line Items</LeftLabelBox>}
        {userContext?.dashboardFeatureCodes.has(
          DashboardFeatureCode.ChartBookingsByWeekDetailsBookings
        ) && <LeftLabelBox rowHeight={height}>Bookings</LeftLabelBox>}
        {userContext?.dashboardFeatureCodes.has(
          DashboardFeatureCode.ChartBookingsByWeekDetailsCommercialValue
        ) && <LeftLabelBox rowHeight={height}>Commercial Invoice Value</LeftLabelBox>}
      </div>
      <BookingBarLabels rowHeight={height} colWidth={40 * 5} />
    </div>
  );
}

interface LeftLabelBoxProps {
  children?: any;
  rowHeight: number;
}

function LeftLabelBox(props: LeftLabelBoxProps) {
  return (
    <div
      style={{
        display: 'flex',
        padding: theme.spacing(0, 2),
        alignItems: 'center',
        backgroundColor: theme.palette.grayscale.light,
        height: props.rowHeight + 'px',
        margin: theme.spacing(0.75, 0),
        whiteSpace: 'nowrap',
      }}
    >
      <Typography variant="h4">{props.children}</Typography>
    </div>
  );
}
