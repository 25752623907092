import { gql } from '@apollo/client/core';

export const RemarkFragment = gql`
  fragment Remark on Remark {
    id
    text
    createDate
    createdBy {
      id
      firstName
      lastName
      profile {
        id
        name
      }
    }
  }
`;
