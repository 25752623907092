import { Box } from '@material-ui/core';
import { consolableBookingColumns } from 'app/consolidations/states';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilValue } from 'recoil';
import { NewConsolidationStates } from '../states';

export default function BookingsTable() {
  const bookings = useRecoilValue(NewConsolidationStates.step1.selectedBookings);

  return (
    <Panel title="Bookings">
      <Box height="100%" paddingX={2} paddingBottom={2}>
        <UWLTable
          rowId="id"
          columns={consolableBookingColumns}
          rows={bookings}
          emptyMessage="- No Bookings -"
        />
      </Box>
    </Panel>
  );
}
