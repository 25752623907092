import { Box, Button, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  PermissionCode,
  useHblLazyQuery,
  useHblQuery,
  useNewHblRemarkMutation,
} from 'api/GQL_Types';
import { HblQuery } from 'api/queries/hblQueries';
import { userContextAtom } from 'app';
import { DocumentsPanel } from 'app/components/DocumentsPanel';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import Remarks, { Remark, sortRemarks } from 'components/Remarks';
import { AtomGroupCleaner, newAtom } from 'lib/RecoilUtils';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import HBLEquipment from './equipment';
import HBLDetailsPanel from './hbl-detail-panel';
import { HBLPackLines } from './HBLPackLines';
import { HBLPartyOverrideDialog } from './HBLPartyOverrideDialog';
import { HblPageStates, useSetupHblPage } from './states';

const openRelatedPartiesDialog = newAtom(false);

interface Props extends RouteComponentProps {
  shipmentId?: string;
}

export default function HBLPage(props: Props) {
  const hblId = props.shipmentId ?? '';
  const userContext = useRecoilValue(userContextAtom);
  const hbl = useRecoilValue(HblPageStates.hbl);
  const [allRemarks, setAllRemarks] = useRecoilState(HblPageStates.allRemarks);
  const { enqueueSnackbar } = useSnackbar();
  const setOpenOverrideDialog = useSetRecoilState(openRelatedPartiesDialog);
  const setupHblPage = useSetupHblPage();

  const [refetchHblQuery] = useHblLazyQuery({
    variables: { hblId: hblId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.hbl) {
        enqueueSnackbar('HBL Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenOverrideDialog(false);
      }
    },
  });

  const {
    data: hblData,
    loading,
    error,
  } = useHblQuery({
    variables: { hblId: hblId },
    fetchPolicy: 'cache-and-network',
  });

  const [saveHblRemark] = useNewHblRemarkMutation({
    refetchQueries: () => [
      {
        query: HblQuery,
        variables: { hblId: hblId },
      },
    ],
    onCompleted(data) {
      if (data.newHblRemark.success) {
        enqueueSnackbar('New HBL Remark Created.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
  });

  React.useEffect(() => {
    setupHblPage(hblData);
    if (hblData) {
      const hbl = hblData.hbl;
      if (!hbl) {
        navigate('/bookings');
      } else {
        if (hbl.id) {
          let remarks: Remark[] = [];
          remarks = remarks.concat(hbl?.booking.mbl?.remarks ?? []);
          remarks = remarks.concat(hbl?.booking.remarks ?? []);
          remarks = remarks.concat(hbl?.remarks ?? []);
          remarks = sortRemarks(remarks);
          setAllRemarks(remarks);
        }
      }
    }
  }, [hblData]);

  if (error && !hbl) {
    return <ErrorMessage error={error + ''} />;
  }

  if (!hbl || loading) {
    return <Loading />;
  }

  return (
    <>
      <AtomGroupCleaner atomGroup={HblPageStates} />
      <ErrorMessage error={error ? error + '' : null} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={1}
        marginTop={1}
        marginBottom={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            HBL: {hbl?.referenceNumber}
          </Typography>
          <HotBookingSwitch
            bookingId={hbl.booking.id}
            initialState={hbl.hot}
            showDetailsOnRight={true}
          />
        </Box>
        {userContext?.permissionCodes.has(PermissionCode.HblRelatedPartiesOverride) && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenOverrideDialog(true);
              }}
            >
              Hbl Party Override
            </Button>
            <HBLPartyOverrideDialog
              openState={openRelatedPartiesDialog}
              data={hbl}
              onSaved={() => {
                refetchHblQuery();
              }}
            />
          </>
        )}
      </Box>

      <HBLDetailsPanel />

      {userContext?.permissionCodes.has(PermissionCode.HblRemarkRead) && (
        <Remarks
          title="Remarks"
          allowComments={userContext?.permissionCodes.has(PermissionCode.HblRemarkCreate)}
          remarks={allRemarks}
          makeRemark={(text) => {
            saveHblRemark({
              variables: {
                input: {
                  hblId: hblId,
                  remark: {
                    text: text,
                  },
                },
              },
            });
          }}
        />
      )}

      <HBLEquipment />

      <HBLPackLines />

      <DocumentsPanel documents={hbl.allDocuments} showBookingNumber />
    </>
  );
}
