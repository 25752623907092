export type Weekday = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';

export function getWeekDayAbbr(day: Weekday) {
  switch (day) {
    case 'sun':
      return 'S';
    case 'mon':
      return 'M';
    case 'tue':
      return 'T';
    case 'wed':
      return 'W';
    case 'thu':
      return 'Th';
    case 'fri':
      return 'F';
    case 'sat':
      return 'Sa';
  }
  return day;
}

export function getWeekDayFull(day: Weekday) {
  switch (day) {
    case 'sun':
      return 'Sunday';
    case 'mon':
      return 'Monday';
    case 'tue':
      return 'Tuesday';
    case 'wed':
      return 'Wednesday';
    case 'thu':
      return 'Thursday';
    case 'fri':
      return 'Friday';
    case 'sat':
      return 'Saturday';
  }
  return day;
}
