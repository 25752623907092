import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import clsx from 'clsx';
import * as React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minWidth: '200px',
      padding: theme.spacing(1, 1, 2, 1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerToggle: {
      padding: 0,
      lineHeight: 1.235,
    },

    sortContainer: {
      background: theme.palette.grey[300],
    },
    sortElement: {
      background: theme.palette.background.paper,
      zIndex: 10000,
      display: 'flex',
      alignItems: 'stretch',
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    dragHandle: {
      flexGrow: 1,
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    disabled: {
      color: theme.palette.action.disabled,
    },
  })
);

const DragHandleBase: React.FC<{ col: FieldDisplayState }> = ({ col }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.dragHandle]: true,
        [classes.disabled]: !col.visible,
      })}
    >
      {col.label}
    </div>
  );
};

const DragHandle = SortableHandle(DragHandleBase);

const SortElementBase: React.FC<{
  col: FieldDisplayState;
  setVisible(id: string, visible: boolean): void;
}> = ({ col, setVisible }) => {
  const classes = useStyles();

  return (
    <div className={classes.sortElement}>
      <DragHandle col={col} />
      <Switch
        checked={col.visible}
        onChange={(e) => {
          setVisible(col.id, e.target.checked);
        }}
        color="secondary"
      />
    </div>
  );
};

const SortElement = SortableElement(SortElementBase);

const SortContainerBase: React.FC = (props) => {
  const classes = useStyles();
  return <div className={classes.sortContainer}>{props.children}</div>;
};

const SortContainer = SortableContainer(SortContainerBase);

interface FieldDisplayState {
  id: string;
  label: string;
  visible: boolean;
}

export interface DatasetDisplayable {
  field: string;
  label: string;
}

interface Props {
  disabled?: boolean;
  displayables: DatasetDisplayable[];
  fields: string[];
  defaultFieldSelection: string[];
  onChange(cols: string[]): void;
}

export const EditFields: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [displayFields, setDisplayFields] = React.useState<FieldDisplayState[]>([]);
  const [firstMount, setFirstMount] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!anchorEl && !firstMount) {
      props.onChange(displayFields.filter((col) => col.visible).map((col) => col.id));
    }
    setFirstMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!anchorEl]);

  React.useEffect(() => {
    const dfields: FieldDisplayState[] = [];
    for (let field of props.fields) {
      const displayable = props.displayables.find((disp) => disp.field === field);
      dfields.push({
        id: field,
        label: displayable ? displayable.label : field,
        visible: true,
      });
    }
    for (let displayable of props.displayables) {
      if (!dfields.find((d) => d.id === displayable.field)) {
        dfields.push({
          id: displayable.field,
          label: displayable.label,
          visible: false,
        });
      }
    }
    setDisplayFields(dfields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.displayables, props.fields]);

  const id = anchorEl ? 'edit-fields-popover' : undefined;

  let isAllSelected = true;
  for (const df of displayFields) {
    if (!df.visible) {
      isAllSelected = false;
      break;
    }
  }

  return (
    <>
      <IconButton
        size="small"
        title="Edit Columns"
        aria-describedby={id}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={props.disabled}
      >
        <ViewColumnIcon />
      </IconButton>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div className={classes.header}>
          <Typography variant="h4">Edit Columns</Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            className={classes.headerToggle}
            onClick={(e) => {
              e.preventDefault();
              if (isAllSelected) {
                setDisplayFields(
                  displayFields.map((col) => {
                    return { ...col, visible: props.defaultFieldSelection.includes(col.id) };
                  })
                );
              } else {
                setDisplayFields(
                  displayFields.map((col) => {
                    return { ...col, visible: true };
                  })
                );
              }
            }}
          >
            {isAllSelected ? 'select defaults' : 'select all'}
          </Button>
        </div>

        <SortContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            const newCols = displayFields.slice();
            newCols.splice(
              newIndex < 0 ? newCols.length + newIndex : newIndex,
              0,
              newCols.splice(oldIndex, 1)[0]
            );
            setDisplayFields(newCols);
          }}
          useDragHandle
        >
          {displayFields.map((col, index) => (
            <SortElement
              key={col.id}
              index={index}
              col={col}
              setVisible={(id, visible) => {
                setDisplayFields(
                  displayFields.map((col) => {
                    return col.id === id ? { ...col, visible } : col;
                  })
                );
              }}
            />
          ))}
        </SortContainer>
      </Popover>
    </>
  );
};
