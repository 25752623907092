import {
  Box,
  Button,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import { Line } from 'components/StyledComponents';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NewNetworkPageStates } from '../states';
import NewNetworkButton from './NewNetworkButton';
import Step1 from './step1';
import Step2 from './step2';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
    left: 'calc(-50% + 17px)',
    right: 'calc(50% + 17px)',
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(64, 233, 205) 0%,rgb(39, 185, 212) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(64, 233, 205) 0%,rgb(39, 185, 212) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(59, 255, 222) 0%, rgb(39, 185, 212) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(59, 255, 222) 0%, rgb(39, 185, 212) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <AccountTreeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export default function DialogStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Network Name & Profile Type', 'Profile Controlling Client'];
  const networkName = useRecoilValue(NewNetworkPageStates.fields.formNetworkName);
  const selectedProfileTypes = useRecoilValue(NewNetworkPageStates.fields.selectedProfileTypes);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {activeStep ? <Step2 /> : <Step1 />}
            <Line height={1} />
            <Box display="flex">
              {activeStep === 0 ? (
                <CancelButton />
              ) : (
                <Button onClick={handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <NewNetworkButton />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!networkName || selectedProfileTypes.length === 0}
                  onClick={handleNext}
                  className={classes.button}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
}

function CancelButton() {
  const setOpenDialog = useSetRecoilState(NewNetworkPageStates.newNetworkDialog_Open);

  return (
    <Button
      color="default"
      onClick={() => {
        setOpenDialog(false);
      }}
      style={{ marginRight: '12px' }}
    >
      Cancel
    </Button>
  );
}
