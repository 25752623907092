import { Box, Button, Container } from '@material-ui/core';
import { useUpdateProfileMutation } from 'api/GQL_Types';
import { ProfileQuery } from 'api/queries/profileQueries';
import { NewProfileForm } from 'app/admin/components/NewProfileForm';
import {
  useNewProfileFormState,
  useSetupNewProfileForm,
} from 'app/admin/components/NewProfileForm/states';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { ReviewProfileDialog_Open } from '.';

interface Props {
  profile: {
    id: string;
    name: string;
    profileCode: string;
  };

  mergeToProfileOptions: {
    id: string;
    name: string;
  }[];

  profileTypeOptions: {
    id: string;
    name: string;
  }[];

  onCancel(): void;
}

export const KeepAsNewProfile: React.FC<Props> = (props) => {
  const setupNewProfileForm = useSetupNewProfileForm();

  React.useEffect(() => {
    setupNewProfileForm({
      profileId: props.profile.id,
      profileName: props.profile.name,
      profileCode: props.profile.profileCode,
    });
  }, []);

  return (
    <div>
      <Container>
        <Box marginBottom={2}>
          <NewProfileForm profileTypes={props.profileTypeOptions} />
        </Box>
      </Container>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={props.onCancel}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <SaveButton profileId={props.profile.id} />
      </Box>
    </div>
  );
};

const SaveButton: React.FC<{ profileId: string }> = ({ profileId }) => {
  // SaveButton is a separate component so only the button re-renders every time the form state changes
  const formState = useNewProfileFormState();
  const setOpenDialog = useSetRecoilState(ReviewProfileDialog_Open);

  const { enqueueSnackbar } = useSnackbar();
  const [updateProfile, { loading: updateProfileWaiting }] = useUpdateProfileMutation({
    refetchQueries: () => [
      {
        query: ProfileQuery,
        variables: { profileId: profileId },
      },
    ],
    onCompleted(data) {
      if (data.updateProfile.success) {
        enqueueSnackbar('Profile Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      } else {
        enqueueSnackbar(data.updateProfile.message || 'Failed to update profile.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to update profile.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  const profileTypeId = formState.profile.type.id || null;

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        updateProfile({
          variables: {
            input: {
              profile: {
                id: profileId,
                name: formState.profile.name,
                profileCode: formState.profile.code,
                profileTypeId: profileTypeId,
                logo: formState.profile.logo,
              },
              profileMainLocation: {
                id: formState.location.id,
                name: formState.location.name,
                code: formState.location.code,
                addressLine1: formState.location.addressLine1,
                addressLine2: formState.location.addressLine2,
                city: formState.location.city,
                state: formState.location.state,
                postalCode: formState.location.postalCode,
                country: formState.location.country,
                timeZone: formState.location.timeZone,
                relatedPortCode: formState.location.relatedPortCode?.code,
                companyPhone: formState.location.companyPhone,
                companyUrl: formState.location.companyUrl,
                locationTypes: formState.location.locationTypes,
              },
              profileMainContact: {
                id: formState.contact.id,
                firstName: formState.contact.firstName,
                lastName: formState.contact.lastName,
                title: formState.contact.title,
                email: formState.contact.email,
                phoneMain: formState.contact.phoneMain,
                extension: formState.contact.extension,
                phoneCell: formState.contact.phoneCell,
                phoneAlternative: formState.contact.phoneAlternative,
                isMainContact: true,
                locationId: formState.location.id,
              },
            },
          },
        });
      }}
      disabled={
        updateProfileWaiting ||
        !formState.profile.name ||
        !formState.profile.code ||
        !profileTypeId ||
        !formState.location.name ||
        !formState.location.locationTypes.length ||
        !formState.location.country
      }
    >
      Save
    </Button>
  );
};
