import { Box, Button, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps } from '@reach/router';
import { PermissionCode, useUpdateNetworkContainerTypesMutation } from 'api/GQL_Types';
import { auth } from 'app';
import { Line } from 'components/StyledComponents';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { NetworkAdminPageStates } from '../states';
import { AddEquipmentTypeDialog } from './AddEquipmentTypeDialog';
import { EquipmentTypeTableForm } from './EquipmentTypeTableForm';

interface Props extends RouteComponentProps {}

export default function EquipmentTypesComponent(props: Props) {
  const { userContext } = auth.useAuthState();
  const canEdit = !!userContext?.permissionCodes.has(PermissionCode.NetworkUpdate);

  const [showAddEquipmentType, setShowAddEquipmentType] = React.useState(false);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Equipment Types</Typography>
          <Typography variant="body1">
            These are the Equipment/Container Types available within this network.
          </Typography>
        </Box>
        {canEdit && <SaveBtn />}
      </Box>
      <Line height={1} />
      <br />
      <EquipmentTypeTableForm canEdit={canEdit} />
      {/* adding space so you can scroll to see the full table without running into the add button */}
      <br />
      <br />
      <br />
      <br />

      <Box position="absolute" bottom={50} right={40}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            setShowAddEquipmentType(true);
          }}
        >
          <AddIcon />{' '}
          <Box component="span" marginLeft={1}>
            Equipment Type
          </Box>
        </Fab>
      </Box>
      {showAddEquipmentType && (
        <AddEquipmentTypeDialog onClose={() => setShowAddEquipmentType(false)} />
      )}
    </>
  );
}

const SaveBtn: React.FC = () => {
  const network = useRecoilValue(NetworkAdminPageStates.network);
  const containerTypes = useRecoilValue(NetworkAdminPageStates.containerTypeForm);
  const { enqueueSnackbar } = useSnackbar();
  const [updateContainerTypes, { loading }] = useUpdateNetworkContainerTypesMutation({
    onCompleted(data) {
      if (data.updateNetworkContainerTypes?.success) {
        enqueueSnackbar('Equipment Types Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar(
          data.updateNetworkContainerTypes?.message || 'Failed to update network Equipment Types.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }
        );
      }
    },
    onError(error) {
      enqueueSnackbar(error + '', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={() => {
        if (!network || containerTypes.length === 0) {
          return;
        }
        updateContainerTypes({
          variables: {
            input: {
              networkId: network.id,
              containerTypes: containerTypes,
            },
          },
        });
      }}
    >
      Update Equipment Types
    </Button>
  );
};
