import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import * as React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  root: {
    breakInside: 'avoid',
  },
  label: {
    fontWeight: 900,
    userSelect: 'none',
    color: '#777',
    '&$active': {
      color: theme.palette.primary.main,
    },
  },
  active: {},
});

export interface Props {
  checked: boolean;
  onChange(checked: boolean): void;
  label?: string;

  disabled?: boolean;
  tooltip?: string;

  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const SwitchWithLabel: React.FC<Props> = ({
  checked,
  onChange,
  label,
  disabled,
  tooltip,
  labelPlacement,
}) => {
  return (
    <div className={classes.root} title={tooltip}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={
              disabled
                ? undefined
                : (e) => {
                    onChange(e.target.checked);
                  }
            }
            disabled={disabled}
          />
        }
        label={
          label ? (
            <div className={clsx(classes.label, checked && !disabled && classes.active)}>
              {label}
            </div>
          ) : undefined
        }
        labelPlacement={label ? labelPlacement : undefined}
      />
    </div>
  );
};
