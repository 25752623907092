import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props<T> {
  // Array recoil state that should hold the selected options
  state: RecoilState<T[]>;
  // Label for the selector
  label: string;
  // List of options in the dropdown
  optionsList: T[];
  // How to resolve comparable value of item in list
  valueResolver: (value: T) => string;
  // How to render item in list
  displayResolver: (value: T) => string;
  // If selector should be disabled
  disabled?: boolean;
  // Display warning message on hover with warning icon end adornment
  warningMessage?: string;
  // If selector is a required field and should be highlighted
  required?: boolean;
}

export default function AtomicMultiSelectorV2<T>(props: Props<T>) {
  const [state, setState] = useRecoilState<T[]>(props.state);

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, [props.label]);

  return (
    <FormControl
      variant="outlined"
      size="small"
      error={props.required && !state.length}
      required={props.required}
      style={{ width: '-webkit-fill-available', margin: '8px 24px 8px 0', flexGrow: 1 }}
      fullWidth
    >
      <InputLabel
        ref={inputLabel}
        disabled={props.disabled}
        shrink // See #OMS-54
      >
        {props.label}
      </InputLabel>
      <Select
        multiple
        disabled={props.disabled}
        value={
          state.length === 0
            ? [''] // Ugly hack to fix the input border covering the label - #OMS-56
            : state.map(props.valueResolver)
        }
        onChange={(e: any) => {
          const values = e.target.value as string[];
          setState(props.optionsList.filter((o) => values.includes(props.valueResolver(o))));
        }}
        endAdornment={
          props.warningMessage ? (
            <InputAdornment position="end" style={{ marginLeft: '-63px', marginRight: '24px' }}>
              <Tooltip title={<h2>{props.warningMessage}</h2>} placement="top">
                <WarningIcon style={{ color: '#ffcb3e' }} />
              </Tooltip>
            </InputAdornment>
          ) : null
        }
        renderValue={(selected: any) => selected.map((s: T) => props.displayResolver(s)).join(', ')}
        fullWidth
        labelWidth={labelWidth}
      >
        {props.optionsList.map((item, index) => {
          return (
            <MenuItem key={index} value={props.valueResolver(item)}>
              <Checkbox
                color="primary"
                checked={Boolean(
                  state.find((o) => {
                    return props.valueResolver(item) === props.valueResolver(o);
                  })
                )}
              />
              <ListItemText primary={props.displayResolver(item)} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
