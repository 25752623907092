import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function Loading() {
  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
      <Box marginLeft={1}>Loading...</Box>
    </Box>
  );
}
