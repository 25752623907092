import { RouteComponentProps } from '@reach/router';
import ManageProfileTypes from './manage';
import ProfileTypeList from './list';
import { FlexGrowRouter } from 'components/StyledComponents';

interface ProfileTypesProps extends RouteComponentProps {}
function ProfileTypes(props: ProfileTypesProps) {
  return (
    <FlexGrowRouter>
      <ProfileTypeList path="/" />
      <ManageProfileTypes path="/:profileTypeId/*" />
    </FlexGrowRouter>
  );
}

export default ProfileTypes;
