import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';
import { RemarkFragment } from './remarkQueries';

const MBLRelatedHBLFragment = gql`
  fragment MBLRelatedHBL on HouseBill {
    id
    referenceNumber
    releaseType
    booking {
      id
      referenceNumber
    }
    remarks {
      ...Remark
    }
    documents {
      id
      documentType
      importFileName
      fileName
      fileType
      fileSize
      uploadDate
      description
    }
  }
`;

export const MblQuery = gql`
  query Mbl($mblId: ID!) {
    mbl(mblId: $mblId) {
      id
      referenceNumber
      containerCount
      totalPieces
      totalCartons
      releaseType
      paymentType
      shipment {
        ... on Booking {
          hbl {
            ...MBLRelatedHBL
          }
        }
        ... on Consolidation {
          bookings {
            hbl {
              ...MBLRelatedHBL
            }
          }
        }
        id
        status
        referenceNumber
        logistics {
          carrier {
            id
            name
          }
          motherVessel {
            id
            name
          }
          motherVoyage {
            id
            name
          }
          contractNumber
          contractType
          moveType
          pol {
            ...Port
          }
          polEtd
          polAtd
          pod {
            ...Port
          }
          podEta
          podAta
          ramp {
            ...Port
          }
          rampEta
          rampAta
          deliveryLocation {
            id
            name
          }
          deliveryEta
          deliveryAta
        }
        remarks {
          ...Remark
        }
        containers {
          id
          containerNumber
          containerType
          sealNumber
          volumeM3
          weightKg
          shipFromFactoryDate
          terminalReceivedDate
          isHot
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
      }
      remarks {
        ...Remark
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          profileCode
          profileType {
            name
          }
        }
      }
    }
  }
  ${PortFragment}
  ${RemarkFragment}
  ${MBLRelatedHBLFragment}
`;

export const NewMblRemarkMutation = gql`
  mutation NewMblRemark($input: NewMblRemarkInput!) {
    newMblRemark(input: $input) {
      success
    }
  }
`;

export const SetMblRelatedPartiesMutation = gql`
  mutation SetMblRelatedParties($input: SetMblRelatedPartiesInput!) {
    setMblRelatedParties(input: $input) {
      success
      message
    }
  }
`;
