import { PartyType } from 'api/GQL_Types';

export function relatedPartyNameOrVarious(
  parties: { partyType: PartyType; party: { name: string } }[] | undefined | null,
  type: PartyType
): string {
  if (!parties) {
    return '';
  }
  const names: string[] = [];
  for (const party of parties) {
    if (party.partyType === type && typeof party.party.name === 'string') {
      names.push(party.party.name);
    }
  }
  if (names.length === 0) {
    return '';
  }
  if (names.length === 1) {
    return names[0];
  }
  return 'Various';
}
