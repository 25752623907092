import React from 'react';
import { ExpanderSection } from './ExpanderSection';
import { Props as SwitchListProps, SwitchList } from './SwitchList';

interface Props<T> extends SwitchListProps<T> {
  title: string;
  defaultExpanded?: boolean;
}

export function SwitchListGroup<T>(props: Props<T>) {
  const nSelected = props.possibleValues.filter((g) => props.values.includes(g.value)).length;

  return (
    <ExpanderSection
      title={props.title}
      summary={`${nSelected} / ${props.possibleValues.length}`}
      defaultExpanded={props.defaultExpanded}
    >
      <SwitchList
        possibleValues={props.possibleValues}
        values={props.values}
        onToggle={props.onToggle}
        disabled={props.disabled}
      />
    </ExpanderSection>
  );
}
