import { FormInputContainerType } from 'components/form/FormInputContainerType';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props {
  // State that holds the selected value
  state: RecoilState<string | null>;
  showMinMax?: boolean;
}

export default function AtomicContainerType(props: Props) {
  const [state, setState] = useRecoilState(props.state);

  return (
    <FormItem>
      <FormInputContainerType value={state} onValue={setState} showMinMax={props.showMinMax} />
    </FormItem>
  );
}
