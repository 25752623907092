import { gqlClient } from 'api/ApolloClient';
import {
    GqlMarkPoAsHotMutation,
  GqlMarkPoAsHotMutationVariables,
  MarkPoAsHotDocument,
  PermissionCode,
} from 'api/GQL_Types';
import { auth } from 'app';
import { HotState, HotToggleSwitch } from 'components/HotToggleSwitch';
import * as React from 'react';

interface Props {
  purchaseOrderId: string;
  initialState: HotState;

  small?: boolean;
  showDetailsOnLeft?: boolean;
  showDetailsOnRight?: boolean;
}

export const HotPoSwitch: React.FC<Props> = ({
  purchaseOrderId,
  initialState,
  small,
  showDetailsOnLeft,
  showDetailsOnRight,
}) => {
  const { userContext } = auth.useAuthState();

  return (
    <HotToggleSwitch
      initialState={initialState}
      save={(hot) => markPoAsHot(purchaseOrderId, hot)}
      canMarkHot={!!userContext?.permissionCodes.has(PermissionCode.PoMarkAsHot)}
      small={small}
      showDetailsOnLeft={showDetailsOnLeft}
      showDetailsOnRight={showDetailsOnRight}
    />
  );
};

async function markPoAsHot(purchaseOrderId: string, hot: boolean): Promise<HotState> {
  const res = await gqlClient.mutate<
    GqlMarkPoAsHotMutation,
    GqlMarkPoAsHotMutationVariables
  >({
    mutation: MarkPoAsHotDocument,
    variables: {
      input: {
        purchaseOrderId,
        hot,
      },
    },
  });
  const po = res.data?.markPoAsHot.purchaseOrder;
  if (res.data?.markPoAsHot.success && po) {
    return po;
  }
  throw new Error('Failed to mark PO as hot. ' + (res.data?.markPoAsHot.message || ''));
}
