import { Button } from '@material-ui/core';
import { navigate } from '@reach/router';
import { StatusDialog } from 'components/StatusDialog';
import React from 'react';

interface Props {
  open: boolean;
  onClose(): void;
  finished: boolean;
  error: string | null;
}

export const CancelUploadDialog: React.FC<Props> = ({ open, onClose, finished, error }) => {
  return (
    <StatusDialog
      onClose={onClose}
      error={
        error
          ? {
              title: 'FAILED TO CANCEL',
              message: error,
            }
          : null
      }
      finished={
        !error && open && finished
          ? {
              title: 'FILE CANCELLATION COMPLETE',
              subtitle: 'Upload new Purchase Order file or view remaining Purchase Orders.',
              buttons: (
                <>
                  <Button
                    onClick={() => {
                      onClose();
                      navigate('/purchase-orders/uploads/new');
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Back To PO Upload
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                      navigate('/purchase-orders/uploads');
                    }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Go To Uploaded PO List
                  </Button>
                </>
              ),
            }
          : null
      }
      waiting={
        !error && open && !finished
          ? {
              title: 'Cancelling...',
              subtitle: 'Cancelling this PO file upload.',
            }
          : null
      }
    />
  );
};
