import { Box } from '@material-ui/core';
import { VolumeUnit, WeightUnit } from 'api/GQL_Types';
import Panel from 'components/Panel';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { BookingPageStates, LineItems } from './states';

const columns: UWLTableColumn<LineItems>[] = [
  { id: 'poNumber', label: 'PO', type: 'link' },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string' },
  { id: 'description', label: 'Description', type: 'string' },
  { id: 'lastShipDate', label: 'Last Ship', type: 'date' },
  { id: 'requestedDeliveryDate', label: 'Req Del Date', type: 'date' },
  { id: 'shipToLocation', label: 'Ship To Loc', type: 'string' },
  {
    id: 'unitPrice',
    label: 'Unit Cost',
    type: 'currency',
    currencyField: 'unitPriceCurrencyCode',
  },
  { id: 'htsCode', label: 'HTS Code', type: 'string' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'shippedCartons', label: 'Shipped CTNS', type: 'number' },
  { id: 'volume', label: 'Volume', type: 'volume', unitField: 'volumeUnit' },
  { id: 'weight', label: 'Weight', type: 'weight', unitField: 'weightUnit' },
];

interface Props {
  bookingId: string;
}

export default function BookingLineItemsPanel(props: Props) {
  const [lineItems, setLineItems] = React.useState<LineItems[]>([]);
  const booking = useRecoilValue(BookingPageStates.booking);

  React.useEffect(() => {
    if (booking) {
      setLineItems(
        booking.packLines.map((line): LineItems => {
          return {
            id: line.id,
            poId: line.orderLine.purchaseOrder.id,
            poNumber: {
              to: '/purchase-orders/' + line.orderLine.purchaseOrder.id,
              value: line.orderLine.purchaseOrder.poNumber,
            },
            itemNumber: line.orderLine.itemNumber,
            description: line.orderLine.itemDescription,
            lastShipDate: line.orderLine.lastShipDate,
            requestedDeliveryDate: line.requestedDeliveryDate, // TODO FIXME HACK ask why the old logic used `line.orderLine.indcDate` was it indented to be a fallback?
            shipToLocation: line.orderLine?.shipToLocation?.name || '',
            unitPrice: line.orderLine.unitPrice || 0,
            unitPriceCurrencyCode: line.orderLine.unitPriceCurrencyCode,
            htsCode: line.orderLine.itemHtsCode || '',
            shippedQty: line.shippedQty,
            shippedCartons: line.shippedCartons,
            volume: line.volumeM3,
            weight: line.weightKg,
            volumeUnit: VolumeUnit.Cbm,
            weightUnit: WeightUnit.Kg,
          };
        })
      );
    }
  }, [booking]);

  return (
    <Panel title="Line Items">
      <Box margin={2}>
        <UWLTable rowId="id" columns={columns} rows={lineItems} emptyMessage="No Items Available" />
      </Box>
    </Panel>
  );
}
