import { Button } from '@material-ui/core';
import { GqlLocationInput, LocationType } from 'api/GQL_Types';
import { newProfileLocation } from 'api/queries/locationQueries';
import { auth } from 'app';
import { Line } from 'components/StyledComponents';
import UniversalDialog from 'components/UniversalDialog';
import { UniversalDialogFooter } from 'components/UniversalDialogFooter';
import { AsyncAction, useAsyncAction } from 'lib/useAsyncAction';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { theme } from 'styles';
import { LocationForm } from './LocationForm';
import {
  LocationFormStates,
  useLocationFormState,
  useSetupLocationForm,
} from './LocationForm/states';

interface Props {
  initialState: {
    name: string;
    locationTypes: LocationType[];
  };
  onClose(): void;
  onCreated(location: { id: string; name: string }): void;
}

export const NewLocationOnTheFlyDialog: React.FC<Props> = ({
  initialState,
  onClose,
  onCreated,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userContext } = auth.useAuthState();
  const setupLocationForm = useSetupLocationForm();
  const setName = useSetRecoilState(LocationFormStates.name);
  const setLocationTypes = useSetRecoilState(LocationFormStates.locationTypes);

  const newLocationAction = useAsyncAction<GqlLocationInput, void>(
    async (location) => {
      const profileId = userContext?.activeContact?.profile.id;
      if (!profileId) return;
      const data = await newProfileLocation({ input: { profileId, location } });
      const loc = data.newProfileLocation.location;
      if (loc) {
        onCreated(loc);
      } else {
        throw new Error('Failed to get location in the response.');
      }
    },
    {
      onData(data) {
        onClose();
        enqueueSnackbar('Location Created!', { variant: 'success' });
      },
      onError(error) {
        enqueueSnackbar('Failed to create location: ' + error, { variant: 'error' });
      },
    }
  );

  React.useEffect(() => {
    setupLocationForm();
    setName(initialState.name);
    setLocationTypes(initialState.locationTypes);
    newLocationAction.reset();
  }, [initialState]);

  return (
    <UniversalDialog
      open
      title={'New Location'}
      large
      setClose={() => {
        onClose();
      }}
    >
      <Line height={2} color={theme.palette.primary.main} />
      <LocationForm />
      <UniversalDialogFooter error={newLocationAction.error}>
        <Button
          variant="contained"
          color="default"
          size="large"
          disabled={newLocationAction.waiting}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <SaveButton action={newLocationAction} />
      </UniversalDialogFooter>
    </UniversalDialog>
  );
};

const SaveButton: React.FC<{ action: AsyncAction<GqlLocationInput, void> }> = ({ action }) => {
  const locFormState = useLocationFormState();
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={action.waiting || !locFormState.name.trim() || !locFormState.country}
      onClick={() => {
        action.act({
          name: locFormState.name,
          nameAliases: locFormState.nameAliases,
          addressLine1: locFormState.addressLine1,
          addressLine2: locFormState.addressLine2,
          city: locFormState.city,
          state: locFormState.state,
          postalCode: locFormState.postalCode,
          country: locFormState.country,
          timeZone: locFormState.timeZone,
          code: locFormState.code,
          codeAliases: locFormState.codeAliases,
          relatedPortCode: locFormState.relatedPortCode?.code,
          companyPhone: locFormState.companyPhone,
          companyUrl: locFormState.companyUrl,
          locationTypes: locFormState.locationTypes,
        });
      }}
    >
      Create Location
    </Button>
  );
};
