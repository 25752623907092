import { GqlMyAccountQuery, NotificationCode } from 'api/GQL_Types';
import { useSetRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';

interface ProfileContact {
  id: string;
  notificationCodes: NotificationCode[];
  profile: Profile;
  role: Role;
}

interface Profile {
  id: string;
  name: string;
  network: Network;
}

interface Network {
  id: string;
  name: string;
}

interface Role {
  id: string;
  name: string;
  notificationCodes: NotificationCode[];
}

export const accountDetailsState = {
  firstName: newAtom<string>(''),
  lastName: newAtom<string>(''),
  email: newAtom<string>(''),
  title: newAtom<string>(''),
  phoneMain: newAtom<string>(''),
  extension: newAtom<string>(''),
  phoneCell: newAtom<string>(''),
  phoneAlternative: newAtom<string>(''),
  profileContacts: newAtom<ProfileContact[]>([]),
  editPasswordDialog_Open: newAtom<boolean>(false),
};

export function useSetupAccountDetailsData() {
  const setFirstName = useSetRecoilState(accountDetailsState.firstName);
  const setLastName = useSetRecoilState(accountDetailsState.lastName);
  const setTitle = useSetRecoilState(accountDetailsState.title);
  const setPhoneMain = useSetRecoilState(accountDetailsState.phoneMain);
  const setExtension = useSetRecoilState(accountDetailsState.extension);
  const setPhoneCell = useSetRecoilState(accountDetailsState.phoneCell);
  const setPhoneAlternative = useSetRecoilState(accountDetailsState.phoneAlternative);
  const setProfileContacts = useSetRecoilState(accountDetailsState.profileContacts);
  const setEmail = useSetRecoilState(accountDetailsState.email);

  return (init: GqlMyAccountQuery) => {
    const user = init.userContext?.user;

    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
    setTitle(user?.title || '');
    setPhoneMain(user?.phoneMain || '');
    setExtension(user?.extension || '');
    setPhoneCell(user?.phoneCell || '');
    setPhoneAlternative(user?.phoneAlternative || '');
    setProfileContacts(
      user?.profileContacts.map((profileContact): ProfileContact => {
        let notificationCodes: NotificationCode[] = [];
        if (Array.isArray(profileContact.notificationCodes)) {
          notificationCodes = profileContact.notificationCodes;
        } else {
          notificationCodes = profileContact.role?.notificationCodes ?? [];
        }
        return {
          id: profileContact.id,
          notificationCodes: notificationCodes,
          profile: {
            id: profileContact.profile.id,
            name: profileContact.profile.name,
            network: {
              id: profileContact.profile.network.id,
              name: profileContact.profile.network.name,
            },
          },
          role: {
            id: profileContact.role?.id ?? '',
            name: profileContact.role?.name ?? '',
            notificationCodes: profileContact.role?.notificationCodes ?? [],
          },
        };
      }) || []
    );
  };
}
