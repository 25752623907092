import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { openInNewTableWindow } from 'app/NewWindowTable';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';
import arrayToExcel from './ArrayToExcel';

interface Props {
  label: string;
  columns: UWLTableColumn<any>[];
  rows: any[];
}

export default function TableExportWindowGroup(props: Props) {
  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          arrayToExcel(props.rows, props.label, props.columns);
        }}
      >
        <GetAppIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          openInNewTableWindow(
            props.label.replace(/\s+/g, '-').toLowerCase(),
            props.columns,
            props.rows
          );
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    </>
  );
}
