import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const HblQuery = gql`
  query Hbl($hblId: ID!) {
    hbl(hblId: $hblId) {
      id
      referenceNumber
      description
      releaseType
      paymentType
      containerCount
      totalPieces
      totalCartons
      booking {
        id
        referenceNumber
        status
        logistics {
          contractNumber
          carrier {
            id
            name
          }
          motherVessel {
            id
            name
          }
          motherVoyage {
            id
            name
          }
          contractType
          moveType
          pol {
            ...Port
          }
          polEtd
          polAtd
          pod {
            ...Port
          }
          podEta
          podAta
          ramp {
            ...Port
          }
          rampEta
          rampAta
          deliveryLocation {
            id
            name
          }
          deliveryEta
          deliveryAta
        }
        mbl {
          id
          referenceNumber
          remarks {
            id
            text
            createDate
            createdBy {
              id
              firstName
              lastName
              profile {
                id
                name
              }
            }
          }
        }
        packLines {
          id
          requestedDeliveryDate
          shippedQty
          shippedCartons
          volumeM3
          weightKg
          orderLine {
            id
            lineNumber
            unitPrice
            unitPriceCurrencyCode
            itemNumber
            itemDescription
            itemHtsCode
            purchaseOrder {
              id
              poNumber
              htsCode
            }
            indcDate
            lastShipDate
            shipToLocation {
              id
              name
            }
          }
          vanPositions {
            volumeM3
            weightKg
            container {
              id
              containerNumber
              containerType
              sealNumber
              volumeM3
              weightKg
              shipFromFactoryDate
              terminalReceivedDate
              deliveryAta
              isHot
            }
          }
        }
        remarks {
          id
          text
          createDate
          createdBy {
            id
            firstName
            lastName
            profile {
              id
              name
            }
          }
        }
        containers {
          id
          containerNumber
          containerType
          sealNumber
          volumeM3
          weightKg
          shipFromFactoryDate
          terminalReceivedDate
          deliveryAta
          isHot
        }
        documents {
          id
          documentType
          importFileName
          fileName
          fileType
          fileSize
          uploadDate
          description
        }
        isHot
        hotMarkedBy
        hotMarkedTimestamp
        consolidation {
          id
          referenceNumber
        }
      }
      remarks {
        id
        text
        createDate
        createdBy {
          id
          firstName
          lastName
          profile {
            id
            name
          }
        }
      }
      documents {
        id
        documentType
        importFileName
        fileName
        fileType
        fileSize
        uploadDate
        description
      }
      relatedParties {
        id
        partyType
        party {
          id
          name
          locations {
            id
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
          }
        }
      }
    }
  }
  ${PortFragment}
`;

export const NewHblRemarkMutation = gql`
  mutation NewHblRemark($input: NewHblRemarkInput!) {
    newHblRemark(input: $input) {
      success
      message
      remark {
        id
        text
        createDate
        createdBy {
          firstName
          lastName
          title
          profile {
            name
          }
        }
      }
    }
  }
`;

export const SetHblRelatedPartiesMutation = gql`
  mutation SetHblRelatedParties($input: SetHblRelatedPartiesInput!) {
    setHblRelatedParties(input: $input) {
      success
      message
    }
  }
`;
