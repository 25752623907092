import FormInputSelect from 'components/form/FormInputSelect';
import { FormItem } from 'components/form/FormItem';
import React from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface Props<T extends Object> {
  state: RecoilState<any>;
  label: string;
  optionsList: T[];
  controllingField: Extract<keyof T, string>;
  disabled?: boolean;
  // If selector is a required field and should be higlighted
  required?: boolean;
  displayField?: Extract<keyof T, string>;
  // emptyValue: T;
  error?: boolean; // When true, indicate error status i.e. red border
  helperText?: string | null;
}

export default function AtomicSelectorV2<T>(props: Props<T>) {
  const [state, setState] = useRecoilState(props.state);

  function inRange(value: any): boolean {
    return props.optionsList.find((v) => v[props.controllingField] === value) ? true : false;
  }

  return (
    <FormItem>
      <FormInputSelect
        options={props.optionsList.map((v: any) => ({
          id: v[props.controllingField],
          label: props.displayField ? v[props.displayField] : v[props.controllingField],
        }))}
        value={inRange(state[props.controllingField]) ? state[props.controllingField] : null}
        onValue={(value) => {
          for (const option of props.optionsList) {
            if ((option[props.controllingField] as any) === value) {
              setState({ ...option });
              return;
            }
          }
        }}
        label={props.label}
        required={props.required}
        disabled={props.disabled}
        error={props.error || (props.required && !state[props.controllingField])}
        helperText={props.helperText}
      />
    </FormItem>
  );
}
