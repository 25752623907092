import { Popover, Typography } from '@material-ui/core';
import { HighlightMatches } from 'components/HighlightMatches';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { makeCss, theme } from 'styles';
import { PoImportFieldGroup } from './reviewUploadStore';

const classes = makeCss({
  body: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  search: {
    margin: theme.spacing(1),
  },
  groupTitle: {
    margin: theme.spacing(2, 1, 1, 1),
    padding: theme.spacing(0, 0, 0.5, 0),
    borderBottom: '2px solid ' + theme.palette.divider,
  },
  item: {
    padding: theme.spacing(0.5, 1, 0.5, 2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  emptyStatus: {
    textAlign: 'center',
  },
});

interface Props {
  popoverId?: string;
  open: boolean;
  anchorEl?: Element;
  onClose?: () => void;
  onSelected(omsKey: string): void;
  keysAlreadyMapped: string[];
  poImportFieldGroups: PoImportFieldGroup[];
}

export const ColMappingPopover: React.FC<Props> = ({
  anchorEl,
  popoverId,
  open,
  onClose,
  onSelected,
  keysAlreadyMapped,
  poImportFieldGroups,
}) => {
  const [searchField, setSearchField] = React.useState<string>('');

  let groups: { key: string; name: string; fields: { key: string; name: string }[] }[] = [];

  poImportFieldGroups.forEach((group) => {
    groups.push({
      key: group.key,
      name: group.name,
      fields: group.fields.filter((field: any) => !keysAlreadyMapped.includes(field.key)),
    });
  });

  const query = searchField.trim().toLowerCase();
  if (query.length > 0) {
    groups.forEach((group) => {
      group.fields = group.fields.filter((field) => {
        return field.name.toLowerCase().indexOf(query) >= 0;
      });
    });
  }

  groups = groups.filter((group) => group.fields.length > 0);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.body}>
          <Search text={searchField} setText={setSearchField} />
          {groups.length === 0 && (
            <div className={classes.emptyStatus}>
              {query.length > 0 ? '- no matches -' : 'No field left to map.'}
            </div>
          )}
          {groups.map((group) => {
            return (
              <div key={group.key}>
                <div className={classes.groupTitle}>
                  <Typography variant="h3">{group.name}</Typography>
                </div>

                {group.fields.map((field) => {
                  return (
                    <div
                      key={field.key}
                      className={classes.item}
                      onClick={() => {
                        onSelected(field.key);
                      }}
                    >
                      <Typography variant="h4">
                        <HighlightMatches str={field.name} searchStr={query} />
                      </Typography>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export const Search: React.FC<{
  text: string;
  setText(text: string): void;
}> = ({ text, setText }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={classes.search}>
      <SearchBar placeholder="Filter..." field={text} updateField={setText} inputRef={inputRef} />
    </div>
  );
};
