import { Box, Grid, Typography } from '@material-ui/core';
import {
  ContractType,
  GqlCarrier,
  MoveType,
  PaymentType,
  ReleaseType,
  useCarriersQuery,
} from 'api/GQL_Types';
import { AppWideStates } from 'app/states';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicRadioGroupV2 from 'components/atomic/AtomicRadioGroupV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import SectionTitle from 'components/SectionTitle';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mapContractType, mapMoveType, mapPaymentType, mapReleaseType } from 'types/OMSEnums';
import { BookingPageStates } from '../../states';
import { confirmBookingValidationState } from './states';

export default function CarrierInformation() {
  const booking = useRecoilValue(BookingPageStates.booking);
  const hbl = useRecoilValue(BookingPageStates.editLogistics.hbl);
  const mbl = useRecoilValue(BookingPageStates.editLogistics.mbl);
  const validation = useRecoilValue(confirmBookingValidationState);
  const { data: carriersData } = useCarriersQuery();
  const moveTypeOrigin = mapMoveType(booking?.logistics.moveType as MoveType).split(' ');
  const networkRules = useRecoilValue(AppWideStates.network.rules);

  const setHblPaymentType = useSetRecoilState(BookingPageStates.editLogistics.hblPaymentType);
  const setMblPaymentType = useSetRecoilState(BookingPageStates.editLogistics.mblPaymentType);
  const setHblReleaseType = useSetRecoilState(BookingPageStates.editLogistics.hblReleaseType);
  const setMblReleaseType = useSetRecoilState(BookingPageStates.editLogistics.mblReleaseType);

  React.useEffect(() => {
    if (hbl) {
      if (networkRules.bolHblPaymentTypeEnabled && !booking?.hbl?.paymentType) {
        setHblPaymentType(networkRules.bolHblPaymentTypeDefault);
      } else {
        setHblPaymentType(booking?.hbl?.paymentType || null);
      }

      if (networkRules.bolHblReleaseTypeEnabled && !booking?.hbl?.releaseType) {
        setHblReleaseType(networkRules.bolHblReleaseTypeDefault);
      } else {
        setHblReleaseType(booking?.hbl?.releaseType || null);
      }
    }

    if (mbl) {
      if (networkRules.bolMblPaymentTypeEnabled && !booking?.mbl?.paymentType) {
        setMblPaymentType(networkRules.bolMblPaymentTypeDefault);
      } else {
        setMblPaymentType(booking?.mbl?.paymentType || null);
      }

      if (networkRules.bolMblReleaseTypeEnabled && !booking?.mbl?.releaseType) {
        setMblReleaseType(networkRules.bolMblReleaseTypeDefault);
      } else {
        setMblReleaseType(booking?.mbl?.releaseType || null);
      }
    }
  }, [booking, hbl, mbl]);

  return (
    <>
      <SectionTitle title="Carrier Information" />
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicAutocompleteV2<GqlCarrier>
            state={BookingPageStates.editLogistics.carrier}
            label={'Carrier'}
            optionsList={carriersData ? (carriersData.carriers as GqlCarrier[]) : []}
            displayResolver={(value) => value.name}
            required={validation.carrierError}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={BookingPageStates.editLogistics.carrierConfirmationNumber}
            label="Carrier Confirmation"
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2
            state={BookingPageStates.editLogistics.contract}
            label="Contract No."
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicRadioGroupV2<ContractType>
            state={BookingPageStates.editLogistics.contractType}
            label="Contract Type"
            options={Object.values(ContractType)}
            displayResolver={(value) => mapContractType(value)}
          />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2 state={BookingPageStates.editLogistics.hbl} label="HBL" />
        </Grid>
        <Grid item xs={4}>
          <AtomicTextFieldV2 state={BookingPageStates.editLogistics.mbl} label="MBL" />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <HBLPaymentType />
        </Grid>
        <Grid item xs={4}>
          <MBLPaymentType />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <HBLReleaseType />
        </Grid>
        <Grid item xs={4}>
          <MBLReleaseType />
        </Grid>
      </Grid>
      <hr />

      <Box padding={1} color="#1897A0">
        <Typography variant="h4" color="inherit">
          Cutoff Dates
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.cfsCutoff}
            label="CFS Cutoff"
            disabled={moveTypeOrigin[0] === 'CY'}
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.cfsReceived}
            label="CFS Rec'd"
            disabled={moveTypeOrigin[0] === 'CY'}
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.cyCutoff}
            label="CY Cutoff"
            disabled={moveTypeOrigin[0] === 'CFS'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start" direction="row">
        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.vgmCutoff}
            label="VGM Cutoff"
          />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2 state={BookingPageStates.editLogistics.siCutoff} label="SI Cutoff" />
        </Grid>

        <Grid item xs={4}>
          <AtomicDatePickerV2
            state={BookingPageStates.editLogistics.isfCutoff}
            label="ISF Cutoff"
          />
        </Grid>
      </Grid>
    </>
  );
}

function HBLPaymentType() {
  const hbl = useRecoilValue(BookingPageStates.editLogistics.hbl);
  const validation = useRecoilValue(confirmBookingValidationState);

  return (
    <AtomicAutocompleteV2<PaymentType>
      state={BookingPageStates.editLogistics.hblPaymentType}
      label={'HBL Payment Type'}
      disabled={!Boolean(hbl)}
      optionsList={Object.values(PaymentType)}
      displayResolver={mapPaymentType}
      required={validation.hblPaymentTypeError}
      warning={validation.hblPaymentTypeWarning}
    />
  );
}

function MBLPaymentType() {
  const mbl = useRecoilValue(BookingPageStates.editLogistics.mbl);
  const validation = useRecoilValue(confirmBookingValidationState);

  return (
    <AtomicAutocompleteV2<PaymentType>
      state={BookingPageStates.editLogistics.mblPaymentType}
      label={'MBL Payment Type'}
      disabled={!Boolean(mbl)}
      optionsList={Object.values(PaymentType)}
      displayResolver={mapPaymentType}
      required={validation.mblPaymentTypeError}
      warning={validation.mblPaymentTypeWarning}
    />
  );
}

function HBLReleaseType() {
  const hbl = useRecoilValue(BookingPageStates.editLogistics.hbl);
  const validation = useRecoilValue(confirmBookingValidationState);

  return (
    <AtomicAutocompleteV2<ReleaseType>
      state={BookingPageStates.editLogistics.hblReleaseType}
      label={'HBL Release Type'}
      disabled={!Boolean(hbl)}
      optionsList={Object.values(ReleaseType)}
      displayResolver={mapReleaseType}
      required={validation.hblReleaseTypeError}
      warning={validation.hblReleaseTypeWarning}
    />
  );
}

function MBLReleaseType() {
  const mbl = useRecoilValue(BookingPageStates.editLogistics.mbl);
  const validation = useRecoilValue(confirmBookingValidationState);

  return (
    <AtomicAutocompleteV2<ReleaseType>
      state={BookingPageStates.editLogistics.mblReleaseType}
      label={'MBL Release Type'}
      disabled={!Boolean(mbl)}
      optionsList={Object.values(ReleaseType)}
      displayResolver={mapReleaseType}
      required={validation.mblReleaseTypeError}
      warning={validation.mblReleaseTypeWarning}
    />
  );
}
