import { Box, Grid } from '@material-ui/core';
import { PartyType, ShipmentStatus } from 'api/GQL_Types';
import IconButton from 'components/IconButton';
import MultiLineDetail from 'components/MultiLineDetail';
import { useRecoilValue } from 'recoil';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { HblPageStates } from '../states';

export default function HBLDetailsHeader() {
  const hbl = useRecoilValue(HblPageStates.hbl);

  function getBookingIcon(status: ShipmentStatus | undefined) {
    if (status === 'BOOKED') {
      return 'event_available';
    } else if (status === 'SHIPPED') {
      return 'local_shipping';
    } else if (status === 'ASSIGNED') {
      return 'assignment';
    } else return 'done';
  }

  return (
    <Box flexGrow={1} marginLeft={3}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center" margin="0 auto">
            <IconButton outlined color="#3A81B9">
              <Box component="span" fontSize="inherit" color="#3A81B9" className="material-icons">
                local_shipping
              </Box>
            </IconButton>
            <MultiLineDetail
              label="Shipper"
              line1={relatedPartyNameOrVarious(hbl?.relatedParties, PartyType.Shipper) || <br />}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#1CB3BE">
              <Box component="span" fontSize="inherit" color="#1CB3BE" className="material-icons">
                how_to_reg
              </Box>
            </IconButton>
            <MultiLineDetail
              label="Consignee"
              line1={relatedPartyNameOrVarious(hbl?.relatedParties, PartyType.Consignee) || <br />}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#F69941">
              <Box component="span" fontSize="inherit" color="#F69941" className="material-icons">
                contact_phone
              </Box>
            </IconButton>
            <MultiLineDetail
              label="Notify"
              line1={relatedPartyNameOrVarious(hbl?.relatedParties, PartyType.Notify) || <br />}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={3}>
          <Box display="flex" alignItems="center">
            <IconButton outlined color="#0DAA4F">
              <Box component="span" fontSize="inherit" color="#0DAA4F" className="material-icons">
                {getBookingIcon(hbl?.booking.status)}
              </Box>
            </IconButton>
            <MultiLineDetail label="Status" line1={hbl?.booking.status} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
