import { Box, Grid } from '@material-ui/core';
import {
  ContainerMode,
  ContractType,
  GqlCarrier,
  GqlLocation,
  GqlRelatedParty,
  IncoTerm,
  MoveType,
  TransportMode,
  useCarriersQuery,
} from 'api/GQL_Types';
import AtomicAutocompleteV2 from 'components/atomic/AtomicAutocompleteV2';
import AtomicDatePickerV2 from 'components/atomic/AtomicDatePickerV2';
import AtomicRadioGroupV2 from 'components/atomic/AtomicRadioGroupV2';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormInputPortAny } from 'components/form/FormInputPortAny';
import { FormItem } from 'components/form/FormItem';
import Panel from 'components/Panel';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  mapContainerMode,
  mapContractType,
  mapIncoTerm,
  mapMoveType,
  mapTransportMode,
} from 'types/OMSEnums';
import { NewBookingPageStates, step2ForceLCL } from '../states';

interface Props {}

export default function BookingFields(props: Props) {
  const packLines = useRecoilValue(NewBookingPageStates.step2.packLines);
  const supplier = useRecoilValue(NewBookingPageStates.step2.formSupplier);
  const manufacturer = useRecoilValue(NewBookingPageStates.step2.formManufacturer);
  const [containerMode, setContainerMode] = useRecoilState(
    NewBookingPageStates.step2.formContainerMode
  );
  const forceLCL = useRecoilValue(step2ForceLCL);
  const setEquipmentList = useSetRecoilState(NewBookingPageStates.step2.equipmentList);
  const [pickUpLocation, setPickUpLocation] = useRecoilState(
    NewBookingPageStates.step2.formPickupLocation
  );

  const [pod, setPod] = useRecoilState(NewBookingPageStates.step2.formPod);
  const [pol, setPol] = useRecoilState(NewBookingPageStates.step2.formPol);

  const relatedPartiesMap: { [key: string]: GqlRelatedParty } = {};
  const [locationsMap, setLocationMap] = React.useState<{ [key: string]: GqlLocation }>({});

  const { data: carriersData } = useCarriersQuery();

  React.useEffect(() => {
    const newMap: { [key: string]: GqlLocation } = {};
    if (supplier) {
      for (const loc of supplier.party.locations) {
        newMap[loc.id] = loc;
      }
    }
    if (manufacturer) {
      for (const loc of manufacturer.party.locations) {
        newMap[loc.id] = loc;
      }
    }
    setLocationMap(newMap);
    if (pickUpLocation && !Object.keys(newMap).includes(pickUpLocation.id)) {
      setPickUpLocation(null);
    }
  }, [supplier, manufacturer]);

  React.useEffect(() => {
    if (containerMode === ContainerMode.Lcl) {
      // When LCL is selected, set the equipment to LCL qty 1 - See OMS-155
      setEquipmentList([{ containerType: 'LCL', qty: 1 }]);
    }
  }, [containerMode]);

  React.useEffect(() => {
    if (forceLCL) {
      setContainerMode(ContainerMode.Lcl);
    }
  }, [forceLCL]);

  const locationNamesInPackLines: string[] = [];
  for (const packLine of packLines) {
    locationNamesInPackLines.push(packLine.shipToLocationName);
    for (const rp of packLine.relatedParties) {
      relatedPartiesMap[rp.party.id] = rp;
    }
  }

  const relatedParties = Object.values(relatedPartiesMap);

  const locations: GqlLocation[] = relatedParties.reduce((locationArray: GqlLocation[], rp) => {
    return locationArray.concat(rp.party.locations);
  }, []);

  return (
    <Panel title="Booking Fields">
      <form noValidate autoComplete="off">
        <Box paddingLeft={3} paddingY={2}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicTextFieldV2
                disabled
                state={NewBookingPageStates.step2.formBookingParty}
                label="Booking Party"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlRelatedParty>
                state={NewBookingPageStates.step2.formConsignee}
                label={'Consignee'}
                optionsList={relatedParties}
                displayResolver={(value: GqlRelatedParty) => {
                  return value.party.name;
                }}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlRelatedParty>
                state={NewBookingPageStates.step2.formSupplier}
                label={'Supplier'}
                optionsList={relatedParties}
                displayResolver={(value: GqlRelatedParty) => {
                  return value.party.name;
                }}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlRelatedParty>
                state={NewBookingPageStates.step2.formManufacturer}
                label={'Manufacturer'}
                optionsList={relatedParties}
                displayResolver={(value: GqlRelatedParty) => {
                  return value.party.name;
                }}
                required
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicRadioGroupV2<TransportMode>
                state={NewBookingPageStates.step2.formTransportationMode}
                label="Transportation Mode"
                options={Object.values(TransportMode).filter(
                  (mode) => mode === TransportMode.Sea || mode === TransportMode.Air
                )}
                displayResolver={(value: TransportMode) => {
                  return mapTransportMode(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<IncoTerm>
                state={NewBookingPageStates.step2.formIncoTerm}
                label={'Inco Term'}
                optionsList={Object.values(IncoTerm)}
                displayResolver={(value: IncoTerm) => {
                  return mapIncoTerm(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<MoveType>
                state={NewBookingPageStates.step2.formMoveType}
                label={'Move Type'}
                optionsList={Object.values(MoveType)}
                displayResolver={(value: MoveType) => {
                  return mapMoveType(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicRadioGroupV2<ContainerMode>
                state={NewBookingPageStates.step2.formContainerMode}
                label="Container Mode"
                options={[ContainerMode.Fcl, ContainerMode.Lcl]}
                disabledOptions={forceLCL ? [ContainerMode.Fcl] : undefined}
                displayResolver={(value: ContainerMode) => {
                  return mapContainerMode(value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlLocation>
                state={NewBookingPageStates.step2.formPickupLocation}
                label={'Pick Up Loc'}
                optionsList={Object.values(locationsMap)}
                displayResolver={(value: GqlLocation) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlLocation>
                state={NewBookingPageStates.step2.formDeliveryLocation}
                label={'Delivery Loc'}
                optionsList={locations.filter((loc) =>
                  locationNamesInPackLines.includes(loc.name ?? '')
                )}
                displayResolver={(value: GqlLocation) => {
                  return value.name ?? 'N/A';
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormItem>
                <FormInputPortAny label="Port of Load" value={pol} onValue={setPol} />
              </FormItem>
            </Grid>
            <Grid item xs={3}>
              <AtomicAutocompleteV2<GqlCarrier>
                state={NewBookingPageStates.step2.formCarrier}
                label={'Carrier'}
                optionsList={carriersData ? carriersData.carriers : []}
                displayResolver={(value) => value.name}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <AtomicRadioGroupV2<ContractType>
                state={NewBookingPageStates.step2.formContractType}
                label="Contract Type"
                options={Object.values(ContractType)}
                displayResolver={(value: ContractType) => {
                  return mapContractType(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicTextFieldV2 state={NewBookingPageStates.step2.formContract} label="Contract" />
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2
                state={NewBookingPageStates.step2.formCargoReadyDate}
                required
                label="Cgo Rdy Date"
              />
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2 state={NewBookingPageStates.step2.formEtd} label="ETD" />
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={3}>
              <FormItem>
                <FormInputPortAny label="Port of Discharge" value={pod} onValue={setPod} />
              </FormItem>
            </Grid>
            <Grid item xs={3}>
              <AtomicDatePickerV2 state={NewBookingPageStates.step2.formEta} label="ETA" />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Panel>
  );
}
