import { Box, Dialog, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { BookingPageStates } from '../../states';
import EditEquipment from './edit-equipment';
import EquipmentList from './equipment-list';

interface Props {
  open: boolean;
  onClose: Function;
}

export default function EquipmentAssignmentDialog(props: Props) {
  const [container, setContainer] = useRecoilState(
    BookingPageStates.containerAssignment.selectedContainer
  );

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="lg"
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setContainer(null);
        props.onClose();
      }}
    >
      <Box padding={3} max-height="90vh" display="flex" flexDirection="column">
        <Box marginBottom={1}>
          <Typography variant="h2">Edit Equipment</Typography>
          <AtomicIconButton onClose={props.onClose} />
        </Box>
        {container ? (
          // Basically step 2
          <EditEquipment />
        ) : (
          <EquipmentList />
        )}
      </Box>
    </Dialog>
  );
}

interface AtomicIconButtonProps {
  onClose: Function;
}

function AtomicIconButton(props: AtomicIconButtonProps) {
  const setContainer = useSetRecoilState(BookingPageStates.containerAssignment.selectedContainer);
  return (
    <IconButton
      aria-label="close"
      style={{
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: '#456f89',
      }}
      onClick={() => {
        setContainer(null);
        props.onClose();
      }}
    >
      <CloseIcon />
    </IconButton>
  );
}
