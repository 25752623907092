import { Box, Typography } from '@material-ui/core';
import { AppWideStates } from 'app/states';
import { SwitchListGroup } from 'components/SwitchListGroup';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';

interface Props {
  values: string[];
  onValues: (codes: string[]) => void;

  // when true, all switches will be disabled
  disabled?: boolean;
}

export const DocumentTypeSwitches: React.FC<Props> = (props) => {
  const documentTypes = useRecoilValue(AppWideStates.network.documentTypes).all;

  return (
    <Box width="100%">
      <Typography variant="h2">Document Types</Typography>
      <hr />
      <SwitchListGroup
        title="Allowed to view/download"
        possibleValues={documentTypes.map((dt) => {
          return {
            key: dt.code,
            value: dt.code,
            label: `${dt.code}${dt.active ? '' : '*'} - ${dt.name}`,
            tooltip: `${dt.code} - ${dt.name}`,
          };
        })}
        values={props.values}
        onToggle={(value, checked) => {
          let newValues = props.values.filter((v) => v !== value);
          if (checked) {
            newValues.push(value);
          }
          props.onValues(newValues);
        }}
        disabled={props.disabled}
        defaultExpanded
      />
      {documentTypes.find((dt) => !dt.active) && (
        <div style={{ margin: theme.spacing(2, 0, 2, 4) }}>
          * Obsolete document type. Visible for historical purposes.
        </div>
      )}
    </Box>
  );
};
