import { navigate, RouteComponentProps, Router } from '@reach/router';
import { useUserLazyQuery } from 'api/GQL_Types';
import ManagePageHeader from 'app/admin/components/ManagePageHeader';
import { ManageBody, ManageContainer, ManageContent } from 'components/StyledComponents';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import UserDetails from './details';
import UserProfiles from './profiles';
import UserSecurity from './security';
import { ManageUserPageStates } from './states';
import { SystemPermissions } from './system-permissions';

interface Props extends RouteComponentProps {
  userId?: string;
}

export default function ManageUser(props: Props) {
  const setUser = useSetRecoilState(ManageUserPageStates.user);
  const [getUser, { data: userData }] = useUserLazyQuery({
    variables: { userId: props.userId ?? '' },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data.user) {
        setUser(data.user);
      } else {
        navigate('/sys-admin/users');
      }
    },
  });

  const userId = props.userId ?? '';

  React.useEffect(() => {
    setUser(null);
    getUser({ variables: { userId } });
  }, [userId]);

  return (
    <ManageContainer>
      <ManagePageHeader
        title={userData?.user?.fullName}
        links={[
          {
            name: 'Details',
            path: '',
            icon: 'list_alt',
            subNav: [],
          },
          {
            name: 'Profiles',
            path: 'profiles',
            icon: 'account_box',
            subNav: [],
          },
          {
            name: 'Password & Security',
            path: 'security',
            icon: 'vpn_key',
            subNav: [],
          },
          {
            name: 'System Permissions',
            path: 'system-permissions',
            icon: 'lock',
            subNav: [],
          },
        ]}
      />
      <ManageContent>
        <ManageBody>
          <Router>
            <UserDetails default path="/" />
            <UserProfiles path="profiles" />
            <UserSecurity path="security" />
            <SystemPermissions path="system-permissions" />
          </Router>
        </ManageBody>
      </ManageContent>
    </ManageContainer>
  );
}
