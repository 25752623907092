import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, Fab } from '@material-ui/core';
import { useSetRecoilState } from 'recoil';
import { LocationDialog_Data, ProfileLocationsDialog_Open } from './ProfileLocationsDialog';

export default function AddProfileLocationBtn() {
  const setOpenDialog = useSetRecoilState(ProfileLocationsDialog_Open);
  const setLocationDialogData = useSetRecoilState(LocationDialog_Data);

  return (
    <Fab
      variant="extended"
      color="primary"
      onClick={() => {
        setOpenDialog(true);
        setLocationDialogData(null);
      }}
    >
      <AddIcon />{' '}
      <Box component="span" marginLeft={1}>
        Location
      </Box>
    </Fab>
  );
}
