import { navigate, RouteComponentProps, Router } from '@reach/router';
import { PermissionCode, SystemPermissionCode, useProfileTypeQuery } from 'api/GQL_Types';
import { auth } from 'app';
import ManagePageHeader from 'app/admin/components/ManagePageHeader';
import ErrorMessage from 'components/ErrorMessage';
import { ManageContainer, ManageContent, ManageBody } from 'components/StyledComponents';
import { LinkItem } from 'types/LinkItem';
import React from 'react';
import { useRecoilValue } from 'recoil';
import ProfileTypeDetails from './details';
import ProfileTypeRoles from './ProfileTypeRoles';
import { ProfileTypePageStates, useSetProfileTypePageState } from './states';

interface Props extends RouteComponentProps {
  profileTypeId?: string;
}

export default function ManageProfileTypes(props: Props) {
  const { userContext } = auth.useAuthState();
  const setProfileTypePageState = useSetProfileTypePageState();
  const profileType = useRecoilValue(ProfileTypePageStates.profileType);

  const { error } = useProfileTypeQuery({
    variables: { profileTypeId: props.profileTypeId ?? '' },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setProfileTypePageState(data);
      if (!data.profileType) {
        navigate('/admin/profiles-types');
      }
    },
  });

  const navLinks: LinkItem[] = [];
  navLinks.push({
    name: 'Details',
    path: '',
    icon: 'list_alt',
    subNav: [],
  });

  const canViewRoles = profileType?.isTemplate
    ? userContext?.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesRoleRead)
    : userContext?.permissionCodes.has(PermissionCode.ProfileTypeRoleRead);
  if (canViewRoles) {
    navLinks.push({
      name: 'Roles',
      path: 'roles',
      icon: 'account_box',
      subNav: [],
    });
  }

  return (
    <ManageContainer>
      <ManagePageHeader title={profileType?.name} links={navLinks} />
      <ManageContent>
        <ManageBody>
          <ErrorMessage error={error ? error + '' : null} />
          <Router>
            <ProfileTypeDetails default path="/" />
            <ProfileTypeRoles path="roles" />
          </Router>
        </ManageBody>
      </ManageContent>
    </ManageContainer>
  );
}
