export interface EnumInfo<T> {
  code: T;
  group: string;
  name: string;
  description: string;
}

export interface EnumInfoCollection<T> {
  all: EnumInfo<T>[];
  byCode: Map<T, EnumInfo<T>>;
  byGroup: Map<string, EnumInfo<T>[]>;
  groupOrder: string[]; // keep groups in the order the backend lays them out
}

export function makeEnumInfoCollection<T>(infos: EnumInfo<T>[]): EnumInfoCollection<T> {
  const byCode = new Map<T, EnumInfo<T>>();
  const byGroup = new Map<string, EnumInfo<T>[]>();
  const groupOrder: string[] = []; // keep groups in the order the backend lays them out

  for (const info of infos) {
    byCode.set(info.code, info);

    const groupInfos = byGroup.get(info.group) || [];
    groupInfos.push(info);
    byGroup.set(info.group, groupInfos);

    if (!groupOrder.includes(info.group)) {
      groupOrder.push(info.group);
    }
  }

  return {
    all: Array.from(byCode.values()),
    byCode,
    byGroup,
    groupOrder,
  };
}

export function makeEnumInfoCollectionFallback<T>(codes: T[]): EnumInfoCollection<T> {
  return makeEnumInfoCollection(
    codes.map((code) => {
      return {
        code: code,
        group: 'All',
        name: code + '',
        description: code + '',
      };
    })
  );
}
