import { gql } from '@apollo/client/core';

export const GlobalUpdateMutation = gql`
  mutation GlobalUpdateM($input: GlobalUpdateInput!) {
    globalUpdate(input: $input) {
      success
      message
    }
  }
`;

export const GlobalUpdateSearchQuery = gql`
  query GlobalUpdateSearch($searchType: SearchType!, $query: String!, $numResults: Int) {
    search(searchType: $searchType, query: $query, numResults: $numResults) {
      id
      value
    }
  }
`;

export const GlobalUpdateSearchResultsQuery = gql`
  query GlobalUpdateSearchResults($searchType: SearchType!, $searchId: ID!) {
    globalUpdateSearch(searchType: $searchType, searchId: $searchId) {
      recordId
      shipmentId
      referenceNumber
      hblId
      hblNumber
      mblId
      mblNumber
      containerId
      containerNumber
      poId
      poNumber
      itemNumber
      vesselName
      voyageName
    }
  }
`;

export const GlobalUpdateQuery = gql`
  query GlobalUpdate($recordIds: [ID!]!) {
    globalUpdate(recordIds: $recordIds) {
      logistics {
        logisticsId
        referenceNumber
        confirmationNumber
        confirmationDate
        polEtd
        polAtd
        transitPortEta
        transitPortAta
        transitPortEtd
        transitPortAtd
        podEta
        podAta
        rampEta
        rampAta
        isfCutoffDate
      }
      containers {
        containerId
        containerNumber
        rampEta
        rampAta
        rampOutGateDate
        portOutGateDate
        deliveryEta
        deliveryAta
        deliveryUnload
        emptyNotifyDate
        emptyPickupDate
        emptyReturnedDate
        demurrageLastFreeDayDate
        detentionLastFreeDayDate
        consolidatorLastFreeDayDate
      }
      customs {
        customsId
        customsBrokerRef
        isfResponseNumber
        isfFilingDate
        entryNumber
        entryDate
        entryValue
        mpf
        hmf
        duty
        customsExamType
        customsExamNotifyDate
        customsExamFee
        customsReleaseDate
        fdaExamType
        fdaExamNotifyDate
        fdaExamFee
        fdaReleaseDate
        entryFeeTotal
      }
      records {
        recordId
        shipmentId
        referenceNumber
        hblId
        hblNumber
        mblId
        mblNumber
        containerId
        containerNumber
        poId
        poNumber
        itemNumber
        vesselName
        voyageName
      }
    }
  }
`;
