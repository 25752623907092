import { selector } from 'recoil';
import { genKey } from 'lib/RecoilUtils';
import { ConsolidationPageStates } from '../../states';
import { AppWideStates } from 'app/states';

interface ValidationState {
  isAllowedToSave: boolean;
  mblPaymentTypeError: boolean;
  mblPaymentTypeWarning: boolean;
  mblReleaseTypeError: boolean;
  mblReleaseTypeWarning: boolean;

  hbls: { [bookingId: string]: HBLInputValidationState };
}

interface HBLInputValidationState {
  paymentTypeError: boolean;
  paymentTypeWarning: boolean;
  releaseTypeError: boolean;
  releaseTypeWarning: boolean;
}

export const confirmConsolidationValidationState = selector<ValidationState>({
  key: genKey(),
  get: ({ get }) => {
    const hbls = get(ConsolidationPageStates.editLogistics.hblPerBooking);
    const mblNumber = get(ConsolidationPageStates.editLogistics.mbl);
    const mblPaymentType = get(ConsolidationPageStates.editLogistics.mblPaymentType);
    const mblReleaseType = get(ConsolidationPageStates.editLogistics.mblReleaseType);
    const networkRules = get(AppWideStates.network.rules);

    let validationState: ValidationState = {
      isAllowedToSave: true,
      mblPaymentTypeError: false,
      mblPaymentTypeWarning: false,
      mblReleaseTypeError: false,
      mblReleaseTypeWarning: false,
      hbls: {},
    };

    for (const hbl of hbls) {
      const hblV: HBLInputValidationState = {
        paymentTypeError: false,
        paymentTypeWarning: false,
        releaseTypeError: false,
        releaseTypeWarning: false,
      };

      if (hbl.hblReferenceNumber && !hbl.hblPaymentType) {
        if (!networkRules.bolHblPaymentTypeEnabled || networkRules.bolHblPaymentTypeHardStop) {
          hblV.paymentTypeError = true;
          validationState.isAllowedToSave = false;
        } else {
          hblV.paymentTypeWarning = true;
        }
      }

      if (hbl.hblReferenceNumber && !hbl.hblReleaseType) {
        if (!networkRules.bolHblReleaseTypeEnabled || networkRules.bolHblReleaseTypeHardStop) {
          hblV.releaseTypeError = true;
          validationState.isAllowedToSave = false;
        } else {
          hblV.releaseTypeWarning = true;
        }
      }

      validationState.hbls[hbl.bookingId] = hblV;
    }

    if (Boolean(mblNumber) && !Boolean(mblPaymentType)) {
      if (
        !networkRules.bolMblPaymentTypeEnabled ||
        (networkRules.bolMblPaymentTypeEnabled && networkRules.bolMblPaymentTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.mblPaymentTypeError = true;
      } else if (networkRules.bolMblPaymentTypeEnabled && !networkRules.bolMblPaymentTypeHardStop) {
        validationState.mblPaymentTypeWarning = true;
      }
    }

    if (Boolean(mblNumber) && !Boolean(mblReleaseType)) {
      if (
        !networkRules.bolMblReleaseTypeEnabled ||
        (networkRules.bolMblReleaseTypeEnabled && networkRules.bolMblReleaseTypeHardStop)
      ) {
        validationState.isAllowedToSave = false;
        validationState.mblReleaseTypeError = true;
      } else if (networkRules.bolMblReleaseTypeEnabled && !networkRules.bolMblReleaseTypeHardStop) {
        validationState.mblReleaseTypeWarning = true;
      }
    }

    return validationState;
  },
});
