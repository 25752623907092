import Box from '@material-ui/core/Box';
import Panel from 'components/Panel';
import React from 'react';
import { formatNumber } from 'types/Number';

interface Props {
  title: string;
  count: number;
}

export const SearchResultGroup: React.FC<Props> = (props) => {
  return (
    <Panel title={`${props.title} (${formatNumber(props.count)})`}>
      <Box
        overflow="hidden"
        maxHeight={250}
        style={{ overflow: 'auto' }}
        marginX={1.5}
        marginTop={1}
      >
        {props.children}
      </Box>
    </Panel>
  );
};
