import { Box, Button } from '@material-ui/core';
import { useNewNetworkMutation } from 'api/GQL_Types';
import { AllNetworksQuery } from 'api/queries/networkQueries';
import { auth } from 'app';
import { useNewProfileFormState } from 'app/admin/components/NewProfileForm/states';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { NewNetworkPageStates } from '../states';

export default function NewNetworkButton() {
  const setOpen = useSetRecoilState(NewNetworkPageStates.newNetworkDialog_Open);
  const resetSelectedProfileTypes = useResetRecoilState(
    NewNetworkPageStates.fields.selectedProfileTypes
  );
  const { enqueueSnackbar } = useSnackbar();
  const formState = useNewProfileFormState();
  const networkName = useRecoilValue(NewNetworkPageStates.fields.formNetworkName);
  const selectedProfileTypes = useRecoilValue(NewNetworkPageStates.fields.selectedProfileTypes);

  const [saveNewNetwork, { data }] = useNewNetworkMutation({
    refetchQueries: () => [
      {
        query: AllNetworksQuery,
        fetchPolicy: 'network-only',
      },
    ],
    async onCompleted(data) {
      try {
        await auth.refreshUserContext();
      } catch (err) {
        document.location.reload();
      }
    },
  });

  React.useEffect(() => {
    if (data) {
      if (data.newNetwork.success) {
        setOpen(false);
        resetSelectedProfileTypes();

        enqueueSnackbar('Network Created!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const profileTypeId = formState.profile.type.id || null;

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          saveNewNetwork({
            variables: {
              input: {
                network: {
                  name: networkName,
                },
                controllingClient: {
                  id: null,
                  name: formState.profile.name,
                  profileCode: formState.profile.code,
                  profileTypeId: profileTypeId,
                  logo: formState.profile.logo,
                },
                controllingClientMainLocation: {
                  id: null,
                  name: formState.location.name,
                  code: formState.location.code,
                  addressLine1: formState.location.addressLine1,
                  addressLine2: formState.location.addressLine2,
                  city: formState.location.city,
                  state: formState.location.state,
                  postalCode: formState.location.postalCode,
                  country: formState.location.country,
                  timeZone: formState.location.timeZone,
                  relatedPortCode: formState.location.relatedPortCode?.code,
                  companyPhone: formState.location.companyPhone,
                  companyUrl: formState.location.companyUrl,
                  locationTypes: formState.location.locationTypes,
                },
                controllingClientMainContact: {
                  id: null,
                  firstName: formState.contact.firstName,
                  lastName: formState.contact.lastName,
                  title: formState.contact.title,
                  email: formState.contact.email,
                  phoneMain: formState.contact.phoneMain,
                  extension: formState.contact.extension,
                  phoneCell: formState.contact.phoneCell,
                  phoneAlternative: formState.contact.phoneAlternative,
                  isMainContact: true,
                  locationId: null,
                  roleId: null,
                },
                selectedTemplateIds: selectedProfileTypes,
              },
            },
          });
        }}
        disabled={
          !formState.profile.name ||
          !formState.profile.code ||
          !profileTypeId ||
          !formState.location.name ||
          !formState.location.locationTypes.length ||
          !formState.location.country
        }
      >
        Create Network
      </Button>
    </Box>
  );
}
