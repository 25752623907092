import { Box, Button, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import LineItem from 'components/LineItem';
import { Line } from 'components/StyledComponents';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { theme } from 'styles';
import { ManageUserPageStates } from '../states';
import EditUserDetailsDialog, {
  editUserDetailsDialog_Open,
  UserDetailsDialog_Data,
} from './EditUserDetailsDialog';

const Wrapper = styled.div`
  margin: '16px';
`;

interface Props extends RouteComponentProps {}

export default function UserDetails(props: Props) {
  const user = useRecoilValue(ManageUserPageStates.user);
  const setOpenEditDialog = useSetRecoilState(editUserDetailsDialog_Open);
  const setDialogUserDetails = useSetRecoilState(UserDetailsDialog_Data);

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <Box alignSelf="flex-end">
          <Typography variant="h4" style={{ color: theme.palette.primary.main, fontWeight: 900 }}>
            User Details
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenEditDialog(true);
              setDialogUserDetails(user);
            }}
            style={{ width: '150px' }}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Line height={2} color={theme.palette.primary.main} />
      <Box padding={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LineItem value={user?.firstName} label="First Name" />
            <LineItem value={user?.title} label="Title" />
            <LineItem value={user?.phoneMain} label="Phone (Main)" />
            <LineItem value={user?.phoneCell} label="Phone (Cell)" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineItem value={user?.lastName} label="Last Name" />
            <LineItem value={user?.email} label="Email" />
            <LineItem value={user?.extension} label="Extension" />
          </Grid>
        </Grid>
      </Box>
      <EditUserDetailsDialog />
    </Wrapper>
  );
}
