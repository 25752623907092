import { gqlClient } from 'api/ApolloClient';
import {
  GqlMarkContainerAsHotMutation,
  GqlMarkContainerAsHotMutationVariables,
  MarkContainerAsHotDocument,
  PermissionCode,
} from 'api/GQL_Types';
import { auth } from 'app';
import { HotState, HotToggleSwitch } from 'components/HotToggleSwitch';
import * as React from 'react';

interface Props {
  containerId: string;
  initialState: HotState;

  small?: boolean;
  showDetailsOnLeft?: boolean;
  showDetailsOnRight?: boolean;
}

export const HotContainerSwitch: React.FC<Props> = ({
  containerId,
  initialState,
  small,
  showDetailsOnLeft,
  showDetailsOnRight,
}) => {
  const { userContext } = auth.useAuthState();

  return (
    <HotToggleSwitch
      initialState={initialState}
      save={(hot) => markContainerAsHot(containerId, hot)}
      canMarkHot={!!userContext?.permissionCodes.has(PermissionCode.ContainerMarkAsHot)}
      small={small}
      showDetailsOnLeft={showDetailsOnLeft}
      showDetailsOnRight={showDetailsOnRight}
    />
  );
};

async function markContainerAsHot(containerId: string, hot: boolean): Promise<HotState> {
  const res = await gqlClient.mutate<
    GqlMarkContainerAsHotMutation,
    GqlMarkContainerAsHotMutationVariables
  >({
    mutation: MarkContainerAsHotDocument,
    variables: {
      input: {
        containerId,
        hot,
      },
    },
  });
  const po = res.data?.markContainerAsHot.container;
  if (res.data?.markContainerAsHot.success && po) {
    return po;
  }
  throw new Error('Failed to mark container as hot. ' + (res.data?.markContainerAsHot.message || ''));
}
