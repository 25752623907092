import { Box, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { formatDate } from 'types/Date';

interface Props {
  label: string;
  tooltip?: string | null;
  date: Date | null | undefined;
}

export const PortDisplayDate: React.FC<Props> = ({ label, tooltip, date, children }) => {
  const base = (
    <Typography variant="h4" color="textSecondary" align="left">
      {label}
      <Box component="span" fontWeight="body1.fontWeight" color="textPrimary" marginLeft={1}>
        {formatDate(date)}
        {children}
      </Box>
    </Typography>
  );
  if (tooltip) {
    return (
      <Tooltip title={tooltip} interactive>
        {base}
      </Tooltip>
    );
  }
  return base;
};
