import { gql } from '@apollo/client/core';
import {
  GqlDeactivateSystemDocumentTypeMutation,
  GqlDeactivateSystemDocumentTypeMutationVariables,
  GqlNewSystemDocumentTypeMutation,
  GqlNewSystemDocumentTypeMutationVariables,
  GqlUpdateSystemDocumentTypeMutation,
  GqlUpdateSystemDocumentTypeMutationVariables,
} from 'api/GQL_Types';
import { wrapMutation } from 'api/wrapMutation';

export const SystemDocumentTypesQuery = gql`
  query SystemDocumentTypes {
    systemDocumentTypes {
      code
      name
      activeOnNewNetworks
      ruleMissingEnabled
      ruleMissingDays
      ruleMissingField
      deleteDate
    }
    allNetworks {
      id
      name
      rules {
        documentTypes {
          documentType
          missingEnabled
          missingDays
          missingField
        }
      }
      documentTypes {
        code
        name
        active
      }
    }
  }
`;

export const SystemDocumentTypeQuery = gql`
  query SystemDocumentType($code: String!) {
    systemDocumentTypes(code: $code) {
      code
      name
      activeOnNewNetworks
      ruleMissingEnabled
      ruleMissingDays
      ruleMissingField
      deleteDate
    }
    allNetworks {
      id
      name
      documentTypes {
        code
        active
      }
    }
  }
`;

export const NewSystemDocumentTypeMutation = gql`
  mutation NewSystemDocumentType($input: NewSystemDocumentTypeInput!) {
    newSystemDocumentType(input: $input) {
      success
      message
      systemDocumentType {
        code
      }
    }
  }
`;

export const newSystemDocumentType = wrapMutation<
  GqlNewSystemDocumentTypeMutation,
  GqlNewSystemDocumentTypeMutationVariables
>({
  mutation: NewSystemDocumentTypeMutation,
  errorMessage(data, error) {
    if (!data?.newSystemDocumentType.success || error) {
      return data?.newSystemDocumentType.message || 'Unexpected Error';
    }
  },
});

export const DeactivateSystemDocumentTypeMutation = gql`
  mutation DeactivateSystemDocumentType($input: DeactivateSystemDocumentTypeInput!) {
    deactivateSystemDocumentType(input: $input) {
      success
      message
    }
  }
`;

export const deactivateSystemDocumentType = wrapMutation<
  GqlDeactivateSystemDocumentTypeMutation,
  GqlDeactivateSystemDocumentTypeMutationVariables
>({
  mutation: DeactivateSystemDocumentTypeMutation,
  errorMessage(data, error) {
    if (!data?.deactivateSystemDocumentType.success || error) {
      return data?.deactivateSystemDocumentType.message || 'Unexpected Error';
    }
  },
});

export const UpdateSystemDocumentTypeMutation = gql`
  mutation UpdateSystemDocumentType($input: UpdateSystemDocumentTypeInput!) {
    updateSystemDocumentType(input: $input) {
      success
      message
    }
  }
`;

export const updateSystemDocumentType = wrapMutation<
  GqlUpdateSystemDocumentTypeMutation,
  GqlUpdateSystemDocumentTypeMutationVariables
>({
  mutation: UpdateSystemDocumentTypeMutation,
  errorMessage(data, error) {
    if (!data?.updateSystemDocumentType.success || error) {
      return data?.updateSystemDocumentType.message || 'Unexpected Error';
    }
  },
});
