import { faCalendarXmark } from '@fortawesome/free-solid-svg-icons';
import { markNotificationsAsRead } from 'api/queries/dashboardQueries';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { MarkAllAsReadButton } from 'app/dashboard/components/MarkAllAsReadButton';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilState } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { formatNumber } from 'types/Number';
import { ExceptionQueryResult } from '.';
import { MarkAsReadCell } from '../../components/MarkAsReadCell';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface LateShipmentException {
  id: string;
  shipmentId: string;
  notificationId: string | null;
  isUnread: boolean;
  shipmentNumber: TableLink;
  equipmentNumber: TableLink;
  bolNumber: TableLink;
  deliveryLocation: string;
  indcDate: Date | null | undefined;
  finalEta: Date | null | undefined;
  lateSkus: string;
  totalQty: number;
  lateQty: number;
  carrier: string;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  railRamp: string;
  railRampEta: Date | null | undefined;
  railRampAta: Date | null | undefined;
}

export function LateShipmentException_toRows(
  exception: ExceptionQueryResult
): LateShipmentException[] | null {
  if (exception.__typename !== 'LateShipmentException') {
    return null;
  }

  const shipment = exception.shipment;
  const logistics = shipment.logistics;
  let rows: LateShipmentException[] = [];

  for (const container of shipment.containers) {
    const lateShipmentStatus = container.lateShipmentStatus ? container.lateShipmentStatus : null;

    // If container is not late (no late skus), don't display.
    if (!lateShipmentStatus || !lateShipmentStatus.lateSkus || lateShipmentStatus.lateSkus === 0) {
      continue;
    }

    rows.push({
      id: shipment.id + container.id,
      shipmentId: shipment.id,
      notificationId: exception.notificationId || null,
      isUnread: exception.isUnread,
      shipmentNumber:
        shipment.__typename === 'Booking'
          ? { to: '/bookings/' + shipment.id, value: shipment.referenceNumber }
          : { to: '/consolidations/' + shipment.id, value: shipment.referenceNumber },
      equipmentNumber: { to: '/equipment/' + container.id, value: container.containerNumber ?? '' },
      bolNumber:
        shipment.__typename === 'Booking' && shipment.hbl
          ? { to: `/hbl/${shipment.hbl.id ?? ''}`, value: shipment.hbl.referenceNumber ?? '' }
          : shipment.mbl
          ? { to: `/mbl/${shipment.mbl.id ?? ''}`, value: shipment.mbl.referenceNumber ?? '' }
          : { to: '', value: '' },
      deliveryLocation: logistics.deliveryLocation?.name ?? '',
      indcDate: lateShipmentStatus?.indcDate ?? null,
      finalEta: logistics.deliveryEta,
      lateSkus:
        (lateShipmentStatus?.lateSkus.toString() ?? '0') +
        ' of ' +
        (lateShipmentStatus?.totalSkus.toString() ?? '0'),
      totalQty: lateShipmentStatus?.totalQty ?? 0,
      lateQty: lateShipmentStatus?.lateQty ?? 0,
      carrier: logistics.carrier?.name ?? '',
      pod: logistics.pod?.name ?? '',
      podEta: logistics.podEta,
      podAta: logistics.podAta,
      railRamp: logistics.ramp?.name ?? '',
      railRampEta: logistics.rampEta,
      railRampAta: logistics.rampAta,
    });
  }
  return rows;
}

const columns: UWLTableColumn<LateShipmentException>[] = [
  { id: 'isUnread', label: '!', type: 'bool' },
  { id: 'shipmentNumber', label: 'Shipment', type: 'link' },
  { id: 'equipmentNumber', label: 'Equipment', type: 'link' },
  { id: 'bolNumber', label: 'BOL', type: 'link' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'indcDate', label: 'INDC Date', type: 'date' },
  { id: 'finalEta', label: 'Final ETA', type: 'date' },
  { id: 'lateSkus', label: 'Late SKU Count', type: 'string' },
  { id: 'totalQty', label: 'Total QTY', type: 'number' },
  { id: 'lateQty', label: 'Late QTY', type: 'number' },
  { id: 'carrier', label: 'Carrier', type: 'string' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
  { id: 'railRamp', label: 'Rail Ramp', type: 'string' },
  { id: 'railRampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'railRampAta', label: 'Rail Ramp ATA', type: 'date' },
];

const displayColumnsUnread = columns.map((c) => c.id);
const displayColumnsAllRead = displayColumnsUnread.filter((id) => id !== 'isUnread');

const exportColumns: UWLTableColumn<LateShipmentException>[] = [
  { id: 'shipmentNumber', label: 'Shipment', type: 'link' },
  { id: 'equipmentNumber', label: 'Equipment', type: 'link' },
  { id: 'bolNumber', label: 'BOL', type: 'link' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'indcDate', label: 'INDC Date', type: 'date' },
  { id: 'finalEta', label: 'Final ETA', type: 'date' },
  { id: 'lateSkus', label: 'Late SKU Count', type: 'string' },
  { id: 'totalQty', label: 'Total QTY', type: 'number' },
  { id: 'lateQty', label: 'Late QTY', type: 'number' },
  { id: 'carrier', label: 'Carrier', type: 'string' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
  { id: 'railRamp', label: 'Rail Ramp', type: 'string' },
  { id: 'railRampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'railRampAta', label: 'Rail Ramp ATA', type: 'date' },
];

interface Props {}

export const LateShipmentExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const [exceptions, setExceptions] = useRecoilState(DashboardPageStates.exceptions);
  const unreadCount = exceptions.lateShipment.filter((row) => row.isUnread).length;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Late Shipments"
        unreadCount={unreadCount}
        resultCount={exceptions.lateShipment.length}
        faIcon={faCalendarXmark}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Late Shipments"
        titleRight={
          <>
            <TableExportWindowGroup
              label="Late Shipments"
              rows={exceptions.lateShipment}
              columns={exportColumns}
            />
            {unreadCount > 0 && (
              <MarkAllAsReadButton
                onClick={() => {
                  const unreadAlertIds: string[] = [];
                  for (const row of exceptions.lateShipment) {
                    if (row.isUnread && row.notificationId) {
                      unreadAlertIds.push(row.notificationId);
                    }
                  }
                  markAsRead(unreadAlertIds);
                }}
              />
            )}
          </>
        }
        totalLabel="Total Late Shipments"
        totalValue={formatNumber(
          new Set(exceptions.lateShipment.map((row) => row.shipmentId)).size
        )}
        width={windowSize.width * 0.8}
        bodyHeight={exceptions.lateShipment.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'id'}
            rows={exceptions.lateShipment}
            columns={columns}
            columnsDisplay={unreadCount > 0 ? displayColumnsUnread : displayColumnsAllRead}
            emptyMessage="No Late Shipments"
            virtualize={'single-line-cells'}
            renderCell={{
              isUnread(row) {
                return (
                  <MarkAsReadCell
                    isUnread={row.isUnread}
                    notificationId={row.notificationId}
                    onClick={(notificationId) => {
                      markAsRead([notificationId]);
                    }}
                  />
                );
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );

  function markAsRead(notificationIds: string[]) {
    markNotificationsAsRead(notificationIds);
    setExceptions((e) => {
      return {
        ...e,
        lateShipment: e.lateShipment.map((d) => {
          if (notificationIds.includes(d.notificationId || '')) {
            return { ...d, isUnread: false };
          }
          return d;
        }),
      };
    });
  }
};
