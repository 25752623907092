import { Box, Typography } from '@material-ui/core';
import SearchBar from 'components/SearchBar';
import React from 'react';
import { atom, useRecoilState } from 'recoil';

export const profileLocationSearchState = atom({
  key: 'profileLocationSearchState',
  default: '',
});

export default function ProfileLocationsHeader() {
  const [searchField, setSearchField] = useRecoilState(profileLocationSearchState);
  return (
    <Box display="flex" justifyContent="space-between">
      <div>
        <Typography variant="h2">Locations</Typography>
        <Typography variant="body1">List of Locations associated with this profile</Typography>
      </div>
      <Box marginRight={1} height={1} bgcolor="#efeded" padding={1} width={300} borderRadius={4}>
        <SearchBar
          placeholder="Search by Location Name"
          field={searchField}
          updateField={setSearchField}
        />
      </Box>
    </Box>
  );
}
