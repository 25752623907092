import { Tooltip } from '@material-ui/core';
import React from 'react';
import { theme } from 'styles';

interface Props {
  isUnread: boolean;
  notificationId: string | null | undefined;
  onClick?: (notificationId: string) => void;
}

export const MarkAsReadCell: React.FC<Props> = ({ isUnread, notificationId, onClick }) => {
  if (!isUnread || !notificationId) {
    return null;
  }
  return (
    <Tooltip title={<h2>Click to mark as read</h2>} placement="right">
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: theme.palette.error.main,
          cursor: 'pointer',
        }}
        onClick={() => {
          if (onClick && notificationId) {
            onClick(notificationId);
          }
        }}
      ></div>
    </Tooltip>
  );
};
