import {
  AUTH_LOCAL_STORAGE_KEY,
  BASE_URL,
  SSO_CLIENT_ID,
  SSO_ISSUER,
  SSO_SCOPES,
  SSO_SIGNOUT_URL,
} from 'env';
import { Log, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { atom, SetterOrUpdater, useRecoilState } from 'recoil';
import { gqlClient } from './ApolloClient';
import {
  DashboardFeatureCode,
  GqlUserContextQuery,
  NotificationCode,
  PermissionCode,
  SearchType,
  SystemPermissionCode,
} from './GQL_Types';
import { setActiveUserContact, UserContextQuery } from './queries/userQueries';

const AUTH_LOCAL_STORAGE_RETURN_URL_KEY = 'AUTH_LOCAL_STORAGE_RETURN_URL_KEY';

export type UserContext = GqlUserContextQuery['userContext'] & {
  permissionCodes: Set<PermissionCode>;
  notificationCodes: Set<NotificationCode>;
  systemPermissionCodes: Set<SystemPermissionCode>;
  dashboardFeatureCodes: Set<DashboardFeatureCode>;
  headerSearchTypes: SearchType[];
  globalUpdateSearchTypes: SearchType[];
};

export interface AuthState {
  isWaitingForAuth: boolean;
  ssoError: string | null;
  userContextLoadError: string | null;

  userContext: UserContext | null;
}

const authAtom = atom<AuthState>({
  key: 'authentication',
  default: {
    isWaitingForAuth: true,
    ssoError: null,
    userContextLoadError: null,
    userContext: null,
  },
});

export function getAuthorizationHeader(): string {
  let token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  return token || '';
}

export class UWLAuth {
  private manager: UserManager;

  private authState: [AuthState, SetterOrUpdater<AuthState>] = [
    {
      isWaitingForAuth: true,
      ssoError: null,
      userContextLoadError: null,
      userContext: null,
    },
    () => undefined,
  ];

  constructor() {
    const settings: UserManagerSettings = {
      authority: SSO_ISSUER,
      client_id: SSO_CLIENT_ID,
      redirect_uri: `${BASE_URL}/sso/callback/signin`,
      silent_redirect_uri: `${BASE_URL}/sso/callback/silent-renew`,
      post_logout_redirect_uri: `${BASE_URL}`,
      response_type: 'code',
      scope: SSO_SCOPES,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true,
    };
    Log.logger = console;
    Log.level = Log.WARN;

    this.manager = new UserManager(settings);

    this.manager.events.addUserLoaded((user) => {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, user.access_token);
    });
    this.manager.events.addSilentRenewError((e) => {
      console.error('AUTH - silent renew error', e.message);
    });

    this.manager.startSilentRenew();
  }

  public async setupOnPageMount() {
    switch (document.location.pathname) {
      case '/sso/callback/signin':
        this.signinRedirectCallback();
        return;
      case '/sso/callback/silent-renew':
        this.signinSilentCallback();
        return;
    }

    const user = await this.manager.getUser();

    const token = user?.access_token || '';
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token);
    if (token) {
      await this.loadUserContext();
      return;
    }

    this.goToSignIn();
  }

  public async goToSignIn() {
    localStorage.setItem(AUTH_LOCAL_STORAGE_RETURN_URL_KEY, window.location.pathname);
    this.manager.signinRedirect({}).catch((error) => {
      this.setState({
        isWaitingForAuth: false,
        ssoError: 'Unable to connect to login service.',
        userContextLoadError: null,
        userContext: null,
      });
    });
  }

  public async signinRedirectCallback() {
    this.manager
      .signinRedirectCallback()
      .then((user) => {
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, user.access_token);
        const returnUrl = localStorage.getItem(AUTH_LOCAL_STORAGE_RETURN_URL_KEY) || '/';
        window.location.assign(returnUrl); // assign to reload the page and get the new user state
      })
      .catch((err) => {
        console.error('AUTH - signinRedirectCallback failed:', err);
        this.setState({
          isWaitingForAuth: false,
          ssoError: 'Sign in failed: ' + err,
          userContextLoadError: null,
          userContext: null,
        });
      });
  }

  public async signinSilentCallback() {
    const user = await this.manager.signinSilentCallback();
    const token = user?.access_token;
    if (token) {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, token);
    }
  }

  public useAuthState(): AuthState {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.authState = useRecoilState(authAtom);
    return this.authState[0];
  }

  public async signout(): Promise<void> {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    this.setState({
      isWaitingForAuth: true,
      ssoError: null,
      userContextLoadError: null,
      userContext: null,
    });

    const onSignout = () => {
      localStorage.clear();
      // reload the page clear any in-memory user state
      document.location.assign(SSO_SIGNOUT_URL); // this URL will ensure their session ends
    };

    setTimeout(onSignout, 1000); // in case the api has trouble

    await this.manager.signoutRedirect();
    await this.manager.clearStaleState();
    onSignout();
  }

  private async loadUserContext(): Promise<void> {
    this.setState({
      isWaitingForAuth: true,
      ssoError: null,
      userContextLoadError: null,
      userContext: null,
    });

    try {
      const userContext = await this.getUserContext();

      this.setState({
        isWaitingForAuth: false,
        ssoError: null,
        userContextLoadError: null,
        userContext: userContext,
      });
    } catch (error) {
      this.setState({
        isWaitingForAuth: false,
        ssoError: null,
        userContextLoadError: error + '',
        userContext: null,
      });
      console.error('AUTH - Loading user context failed', error);
    }
  }

  public async changeUserContext(contactId: string, goToUrl: string): Promise<void> {
    await setActiveUserContact({ input: { contactId } });
    // Reload the page clear any in-memory user state
    // and to make sure everything gets initialized with the new context
    window.location.assign(goToUrl);
  }

  private setState(state: AuthState) {
    if (!this.authState) {
      throw new Error('authState not initialized');
    }
    this.authState[1](state);
  }

  public async refreshUserContext() {
    const userContext = await this.getUserContext();

    this.setState({
      isWaitingForAuth: false,
      ssoError: null,
      userContextLoadError: null,
      userContext: userContext,
    });
  }

  private async getUserContext(): Promise<UserContext | null> {
    const res = await gqlClient.query<GqlUserContextQuery>({
      query: UserContextQuery,
      fetchPolicy: 'no-cache',
    });
    const userContext = res.data.userContext;

    if (!userContext) {
      return null;
    }

    const permissionCodes = new Set<PermissionCode>();
    for (const code of userContext.activeContact?.role?.permissionCodes || []) {
      permissionCodes.add(code);
    }

    const notificationCodes = new Set<NotificationCode>();
    for (const code of userContext.activeContact?.role?.notificationCodes || []) {
      notificationCodes.add(code);
    }

    const systemPermissionCodes = new Set<SystemPermissionCode>();
    for (const code of userContext.user?.systemPermissionCodes || []) {
      systemPermissionCodes.add(code);
    }

    const dashboardFeatureCodes = new Set<DashboardFeatureCode>();
    for (const code of userContext.activeContact?.role?.dashboardFeatureCodes || []) {
      dashboardFeatureCodes.add(code);
    }

    const headerSearchTypes: SearchType[] = [];
    const globalUpdateSearchTypes: SearchType[] = [];

    if (permissionCodes.has(PermissionCode.HblRead)) {
      headerSearchTypes.push(SearchType.Hbl);
      globalUpdateSearchTypes.push(SearchType.Hbl);
    }
    if (permissionCodes.has(PermissionCode.MblRead)) {
      headerSearchTypes.push(SearchType.Mbl);
      globalUpdateSearchTypes.push(SearchType.Mbl);
    }
    if (
      permissionCodes.has(PermissionCode.BookingLogisticsRead) ||
      permissionCodes.has(PermissionCode.ConsolidationLogisticsRead)
    ) {
      globalUpdateSearchTypes.push(SearchType.VesselVoyage);
    }
    if (permissionCodes.has(PermissionCode.ContainerRead)) {
      headerSearchTypes.push(SearchType.Container);
      globalUpdateSearchTypes.push(SearchType.Container);
    }
    if (permissionCodes.has(PermissionCode.BookingRead)) {
      headerSearchTypes.push(SearchType.Booking);
      globalUpdateSearchTypes.push(SearchType.Booking);
    }
    if (permissionCodes.has(PermissionCode.ConsolidationRead)) {
      headerSearchTypes.push(SearchType.Consolidation);
      globalUpdateSearchTypes.push(SearchType.Consolidation);
    }
    if (permissionCodes.has(PermissionCode.PoRead)) {
      headerSearchTypes.push(SearchType.Po);
      headerSearchTypes.push(SearchType.Sku);
      globalUpdateSearchTypes.push(SearchType.Po);
      globalUpdateSearchTypes.push(SearchType.Sku);
    }

    return {
      ...userContext,
      permissionCodes,
      notificationCodes,
      systemPermissionCodes,
      dashboardFeatureCodes,
      headerSearchTypes,
      globalUpdateSearchTypes,
    };
  }
}
