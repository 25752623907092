import HBLDetailsHeader from './HBLDetailsHeader';
import HBLLineItems from './LineItemsSection';
import HBLTransitDetails from './TransitDetails';
import Panel from 'components/Panel';
import { Box } from '@material-ui/core';
import { Line } from 'components/StyledComponents';

export default function HBLDetailsPanel() {
  return (
    <Panel title="HBL Details" topRight={<HBLDetailsHeader />}>
      <Box marginLeft={2} marginRight={2} marginBottom={2}>
        <Box margin={3}>
          <HBLTransitDetails />
        </Box>
        <Line height={1} />
        <HBLLineItems />
      </Box>
    </Panel>
  );
}
