import { theme } from 'styles';

const accentColors = [
  theme.palette.primary.light,
  theme.palette.dodgerBlue.main,
  theme.palette.secondary.main,
  theme.palette.orange.main,
  theme.palette.blueGreen.main,
];

export function getAccentColorFromUUID(id: string) {
  if (id.substring(0, 1).match(/[0-3]/i)) {
    return accentColors[0];
  } else if (id.substring(0, 1).match(/[4-6]/i)) {
    return accentColors[1];
  } else if (id.substring(0, 1).match(/[7-9]/i)) {
    return accentColors[2];
  } else if (id.substring(0, 1).match(/[a-c]/i)) {
    return accentColors[3];
  } else return accentColors[4];
}

export function getRandomAccentColor() {
  return accentColors[Math.floor(Math.random() * 5)];
}

export function getAccentColorFromIndex(index: number) {
  return accentColors[index % 5];
}
