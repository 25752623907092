import { Box, Typography } from '@material-ui/core';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { GlobalUpdateRecordRow, GUStates } from '../../states';

const columns: UWLTableColumn<GlobalUpdateRecordRow>[] = [
  { id: 'referenceNumber', label: 'Booking ID', type: 'string' },
  { id: 'mblNumber', label: 'MBL #', type: 'string' },
  { id: 'hblNumber', label: 'HBL #', type: 'string' },
  { id: 'containerNumber', label: 'Container', type: 'string' },
  { id: 'poNumber', label: 'PO #', type: 'string' },
  { id: 'itemNumber', label: 'Sku/Item #', type: 'string' },
  { id: 'vesselName', label: 'Vessel', type: 'string' },
  { id: 'voyageName', label: 'Voyage', type: 'string' },
];

export default function SearchResultTable() {
  const loading = useRecoilValue(GUStates.globalUpdateRecordsLoading);
  const error = useRecoilValue(GUStates.globalUpdateRecordsError);
  const results = useRecoilValue(GUStates.globalUpdateRecords);
  const [selectedResults, setSelectedResults] = useRecoilState(
    GUStates.selectedGlobalUpdateRecords
  );

  function handleSelectedItems(ids: string[]) {
    setSelectedResults(results.filter((r) => ids.includes(r.rowId)));
  }

  return (
    <Box>
      <Typography variant="h3">{results ? results.length : 0} Results Found</Typography>
      <Box marginTop={3} bgcolor="#fff" p={1}>
        <UWLTable
          rowId="rowId"
          columns={columns}
          isLoading={loading}
          error={error}
          rows={results}
          emptyMessage="No Records"
          renderCell={{}}
          checkboxes={{
            checked: selectedResults.map((r) => r.rowId) ?? [],
            setChecked: handleSelectedItems,
          }}
        />
      </Box>
    </Box>
  );
}
