import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ErrorMessage from 'components/ErrorMessage';
import * as React from 'react';
import { makeCss, theme } from 'styles';
import { HighlightMatches } from './HighlightMatches';

const classes = makeCss({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  paper: {
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
  },
  status: {
    padding: theme.spacing(2),
  },
});

interface AutocompleteResult {
  id: string;
  value: string;
}

interface Props {
  anchorEl?: null | HTMLElement;
  open: boolean;
  query: string;
  results: AutocompleteResult[];
  onSelect(result: AutocompleteResult): void;
  waiting?: boolean;
  error?: string | null;
}

export const AutocompletePopover: React.FC<Props> = ({
  anchorEl,
  open,
  query,
  results,
  onSelect,
  waiting,
  error,
}) => {
  if (!anchorEl || !open) {
    return null;
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl as any}
      role="presentation"
      style={{
        width: anchorEl ? anchorEl.clientWidth : undefined,
      }}
      className={classes.popper}
    >
      <Paper className={classes.paper}>
        {waiting ? (
          <div className={classes.status}>
            <i>Searching...</i>
          </div>
        ) : error ? (
          <div className={classes.status}>
            <ErrorMessage error={error} />
          </div>
        ) : results.length === 0 ? (
          <div className={classes.status}>
            <i>- none -</i>
          </div>
        ) : (
          <MenuList>
            {results.map((result) => {
              return (
                <MenuItem
                  key={result.id}
                  value={result.id}
                  onClick={(e) => {
                    onSelect(result);
                  }}
                >
                  <HighlightMatches str={result.value} searchStr={query} />
                </MenuItem>
              );
            })}
          </MenuList>
        )}
      </Paper>
    </Popper>
  );
};
