import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { appBarHeight, drawerWidth, drawerWidthClosed, drawerWidthClosedNarrow } from '../styles';

export const sidenavStyle = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },

    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawerPaper: {
      overflow: 'hidden',
    },

    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthClosedNarrow,
      [theme.breakpoints.up('sm')]: {
        width: drawerWidthClosed,
      },
    },

    drawerBackground: {
      background: theme.palette.primary.dark,
    },

    drawerLogoBackground: {
      background: '#132736',
      cursor: 'pointer',
    },
    drawerBtn: {
      padding: theme.spacing(1),
    },

    selected: {
      color: theme.palette.common.white,
      '&:hover': {
        background: '#FFFFFF22',
      },
    },

    unSelected: {
      color: theme.palette.common.white,
      opacity: 0.25,
      '&:hover': {
        background: '#FFFFFF22',
        opacity: 0.9,
      },
    },
    listMenu: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden',
    },

    listItems: {
      position: 'absolute',
      height: '100%',
      overflow: 'scroll',
    },

    logo: {
      display: 'block',
      height: '30px',
      width: drawerWidth,
    },

    bigLogo: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '42px',
      marginTop: '-10px',
      marginBottom: '10px',
      '&::before': {
        content: "'OMS'",
      },
    },

    tinyLogo: {
      background: 'url(' + require('../../res/img/white_globe.png').default + ') no-repeat center',
      backgroundSize: 'cover',
      transform: 'scale(1.4)',
    },

    drawerSpacer: {
      flex: 'auto',
    },

    center: {
      margin: '0 auto',
    },
    toolbar: {
      display: 'flex',
      height: appBarHeight,
      background: theme.palette.primary.dark,
      alignItems: 'center',
      width: '100%',
    },
    userText: {
      color: theme.palette.common.white,
      margin: '0 10px 0 10px !important',
      whiteSpace: 'nowrap',
    },
    profilePicture: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.blueGreen.main,
    },
    userButton: {
      height: '100%',
      width: '100%',
      justifyContent: 'flex-start',
      margin: theme.spacing(0, 2),
    },
  })
);
