import {
  GqlPoImportFileError,
  GqlPoImportFileHeadersFragment,
  GqlPoImportFileQuery,
  GqlPoImportFileStatusFragment,
  ImportFileStatus,
} from 'api/GQL_Types';
import { DatasetDisplayable } from 'components/EditFields';
import { NotifierHook } from 'lib/NotifierHook';
import { UWLTableColumn } from 'types/UWLTable';

interface TableRow {
  id: string;
  rowFilterIndex: string; // To quickly filter rows by
  [field: string]: string;
}

export interface Header extends GqlPoImportFileHeadersFragment {
  errors: GqlPoImportFileError[];
}

export interface State {
  status: GqlPoImportFileStatusFragment;
  rows: string[][];

  headersByColId: Map<string, Header>;

  tableColumns: UWLTableColumn<TableRow>[];
  tableRows: TableRow[];

  displayables: DatasetDisplayable[];
  fields: string[];
  defaultFieldSelection: string[];

  cellErrors: Map<string, string[]>;

  /**
   * When set, disable the "continue upload" button and display this message
   */
  preventingUploadErrorMessage: string | null;
}

const blankPoImportFileStatus: GqlPoImportFileStatusFragment = {
  totalPos: 0,
  totalLines: 0,
  updatedPos: 0,
  updatedLines: 0,
  poErrors: 0,
  lineErrors: 0,
  newPos: 0,
  newLines: 0,
  fileStatus: ImportFileStatus.Pending,
  headers: [],
  errors: [],
};

const state: State = {
  status: blankPoImportFileStatus,
  rows: [],
  headersByColId: new Map(),
  tableColumns: [],
  tableRows: [],
  displayables: [],
  fields: [],
  defaultFieldSelection: [],
  cellErrors: new Map(),
  preventingUploadErrorMessage: null,
};

const headerIndexToId = (i: number) => `header-${i}`;

const notifier = NotifierHook();

export const reviewUploadStore = {
  use(): State {
    notifier.use();
    return state;
  },

  setup(file: GqlPoImportFileQuery | undefined) {
    state.status = file?.poImportFile ?? blankPoImportFileStatus;
    state.fields = state.status.headers.map((h, i) => headerIndexToId(i));
    reviewUploadStore.setFileStatusData(state.status);

    state.rows = file?.poImportFile?.rows ?? [];

    state.tableRows = [];
    let rowIndex = 0;
    for (const row of state.rows ?? []) {
      const result: TableRow = {
        id: rowIndex + '', // NOTE important that the id is the same as the rowIndex for lining up errors
        rowFilterIndex: row.join('||||').toLowerCase(), // To quickly filter rows by
      };
      rowIndex++;
      let celI = 0;
      for (const cell of row) {
        result[headerIndexToId(celI++)] = cell;
      }
      state.tableRows.push(result);
    }

    notifier.notify();
  },

  setFileStatusData(data: GqlPoImportFileStatusFragment) {
    state.status = data;
    state.headersByColId = new Map();

    state.status.headers.forEach((header, i) => {
      state.headersByColId.set(headerIndexToId(i), {
        ...header,
        errors: state.status.errors.filter((error) => error.headerIndex === header.headerIndex),
      });
    });

    state.displayables = state.status.headers.map((h, i) => {
      return {
        field: headerIndexToId(i),
        label: h.name,
      };
    });

    state.defaultFieldSelection = state.status.headers.map((h, i) => headerIndexToId(i));

    state.tableColumns = state.fields.map((field) => {
      const header = state.headersByColId.get(field);

      return {
        id: field,
        label: header ? header.name : field,
        type: 'string',
      };
    });

    state.preventingUploadErrorMessage = null;
    state.cellErrors = new Map();
    for (const error of state.status.errors) {
      const key = `${error.headerIndex}-${error.rowIndex}`;
      const errors = state.cellErrors.get(key) || [];
      errors.push(error.error);
      state.cellErrors.set(key, errors);
      if (error.preventUpload) {
        state.preventingUploadErrorMessage = error.error;
      }
    }

    notifier.notify();
  },

  setSelectedFields(fields: string[]) {
    state.fields = fields;
    state.tableColumns = fields.map((field) => {
      const header = state.headersByColId.get(field);
      return {
        id: field,
        label: header ? header.name : field,
        type: 'string',
      };
    });

    notifier.notify();
  },
};

export interface PoImportFieldGroup {
  key: string;
  name: string;
  fields: {
    key: string;
    name: string;
  }[];
}
