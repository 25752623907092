import { Box, Button, Grid, Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { PermissionCode, useBookingLazyQuery, useBookingQuery } from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import Loading from 'app/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { HotState } from 'components/HotToggleSwitch';
import { Remark, sortRemarks } from 'components/Remarks';
import { newAtom } from 'lib/RecoilUtils';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import BookingDetailsPanel from './booking-details';
import { BookingPartyOverrideDialog } from './BookingPartyOverrideDialog';
import BookingDocuments from './documents';
import BookingEquipment from './equipment';
import BookingLineItemsPanel from './LineItems';
import LogisticDetailsPanel from './logistics';
import BookingRemarks from './remarks';
import { BookingPageData, BookingPageStates, ContainerRow } from './states';

export const bookingRelatedPartiesState = newAtom([]);
const openRelatedPartiesDialog = newAtom(false);

export interface BookingDetailProps extends RouteComponentProps {
  bookingId?: string;
}

export default function BookingDetails(props: BookingDetailProps) {
  const userContext = useRecoilValue(userContextAtom);
  const bookingId = props.bookingId ?? '';
  const [booking, setBooking] = useRecoilState(BookingPageStates.booking);
  const setAllRemarks = useSetRecoilState(BookingPageStates.allRemarks);
  const setRelatedParties = useSetRecoilState(bookingRelatedPartiesState);
  const setOpenDialog = useSetRecoilState(openRelatedPartiesDialog);
  const { enqueueSnackbar } = useSnackbar();

  const [refetchBookingQuery] = useBookingLazyQuery({
    variables: { bookingId: booking?.id ?? '' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.booking) {
        enqueueSnackbar('Booking Related Parties Updated!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      }
    },
  });

  const {
    data: bookingData,
    loading,
    error,
  } = useBookingQuery({
    variables: { bookingId: props.bookingId ?? '' },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    const booking = bookingData?.booking;
    if (!booking) {
      setBooking(null);
      setAllRemarks([]);
      setRelatedParties([]);
    } else {
      let remarks: Remark[] = [];
      remarks = remarks.concat(booking.mbl?.remarks ?? []);
      remarks = remarks.concat(booking.hbl?.remarks ?? []);
      remarks = remarks.concat(booking.remarks ?? []);
      remarks = sortRemarks(remarks);
      setAllRemarks(remarks);

      const hotBooking: HotState = {
        isHot: booking.isHot,
        hotMarkedBy: booking.hotMarkedBy,
        hotMarkedTimestamp: booking.hotMarkedTimestamp,
      };

      const containerRows: ContainerRow[] = [];
      for (const cnt of booking.containers ?? []) {
        containerRows.push({
          id: cnt.id,
          isHot: cnt.isHot,
          container: { to: `/equipment/${cnt.id}`, value: cnt.containerNumber || '' },
          containerType: cnt.containerType,
          sealNumber: cnt.sealNumber || '',
          volumeM3: cnt.volumeM3 || 0,
          weightKG: cnt.weightKg || 0,
          shipFromFactoryDate: cnt.shipFromFactoryDate,
          terminalReceivedDate: cnt.terminalReceivedDate,
        });
      }
      if (booking.consolidation) {
        for (const pl of booking.packLines) {
          for (const vp of pl.vanPositions) {
            let row: ContainerRow | undefined = containerRows.find((r) => r.id === vp.container.id);
            if (!row) {
              row = {
                id: vp.container.id,
                isHot: vp.container.isHot,
                container: {
                  to: `/equipment/${vp.container.id}`,
                  value: vp.container.containerNumber || '',
                },
                containerType: vp.container.containerType,
                sealNumber: vp.container.sealNumber || '',
                volumeM3: 0,
                weightKG: 0,
                shipFromFactoryDate: vp.container.shipFromFactoryDate,
                terminalReceivedDate: vp.container.terminalReceivedDate,
              };
              containerRows.push(row);
            }
            row.volumeM3 += vp.volumeM3 || 0;
            row.weightKG += vp.weightKg || 0;
          }
        }
      }

      const bookingObj = { ...booking, hot: hotBooking, containerRows };
      setBooking(bookingObj as BookingPageData);
      setRelatedParties(booking.relatedParties as any);
    }
  }, [bookingData]);

  const notFound = !loading && !error && bookingData && !bookingData.booking;

  React.useEffect(() => {
    if (notFound) {
      navigate('/bookings');
    }
  }, [notFound]);

  if (error && !booking && !loading) {
    return <ErrorMessage error={error + ''} />;
  }

  if (!booking || loading || !userContext) {
    return <Loading />;
  }

  return (
    <Box>
      <ErrorMessage error={error ? error + '' : null} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginLeft={1}
        marginTop={1}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h2" color="primary">
            Booking: {booking.referenceNumber}
          </Typography>
          <HotBookingSwitch
            bookingId={booking.id}
            initialState={booking.hot}
            showDetailsOnRight={true}
          />
        </Box>
        {userContext?.permissionCodes.has(PermissionCode.BookingRelatedPartiesOverride) && (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Booking Party Override
            </Button>
            <BookingPartyOverrideDialog
              openState={openRelatedPartiesDialog}
              data={booking}
              onSaved={() => {
                refetchBookingQuery();
              }}
            />
          </>
        )}
      </Box>

      <Grid container spacing={2} alignItems="stretch" direction="row">
        <Grid item xs={12} md={6}>
          <BookingDetailsPanel />
        </Grid>
        <Grid item xs={12} md={6}>
          <LogisticDetailsPanel bookingId={bookingId} />
        </Grid>
      </Grid>

      <BookingRemarks />
      <BookingEquipment bookingId={bookingId} />
      <BookingLineItemsPanel bookingId={bookingId} />
      <BookingDocuments bookingId={bookingId} />
    </Box>
  );
}
