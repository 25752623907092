import { List, ListItem } from '@material-ui/core';
import React from 'react';
import { sidenavStyle } from './navstyles';
import clsx from 'clsx';

interface Props {
  open: boolean;
  onChange: Function;
}

export default function NavLogo(props: Props) {
  const classes = sidenavStyle();
  return (
    <List className={classes.drawerLogoBackground}>
      <ListItem>
        <div
          className={clsx(classes.logo, {
            [classes.bigLogo]: props.open,
            [classes.tinyLogo]: !props.open,
          })}
        />
      </ListItem>
    </List>
  );
}
