import { Box, Grid } from '@material-ui/core';
import { PartyType } from 'api/GQL_Types';
import { ButtonSpacer } from 'components/ButtonSpacer';
import LineItem from 'components/LineItem';
import Panel from 'components/Panel';
import { useRecoilValue } from 'recoil';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { formatDate } from 'types/Date';
import { mapContractType, mapMoveType, mapShipmentStatus, mapTransportMode } from 'types/OMSEnums';
import { ConsolidationPageStates } from '../states';

interface Props {}

export default function ConsolidationDetailsPanel(props: Props) {
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  return (
    <Box width="100%" height="100%" display="flex">
      <Panel
        title="Consolidation Details"
        topRight={<ButtonSpacer />} // To stay level with the logistics panel - OMS-204
      >
        <Box padding={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LineItem label="Consolidation" value={consolidation?.referenceNumber} />
              <LineItem
                value={formatDate(consolidation?.createDate || null)}
                label="Consolidation Date"
              />
              <LineItem
                value={consolidation?.status}
                label="Consolidation Status"
                decorator={mapShipmentStatus}
              />
              <LineItem
                value={consolidation?.logistics.transportationMode}
                label="Transportation Mode"
                decorator={mapTransportMode}
              />
              <LineItem
                value={consolidation?.logistics.moveType}
                label="Move Type"
                decorator={mapMoveType}
              />
              <LineItem value={consolidation?.logistics?.cfsLocation?.name} label="CFS Location" />
              <LineItem
                value={consolidation?.logistics?.deliveryLocation?.name}
                label="Place of Delivery"
              />
              <LineItem
                value={consolidation?.logistics.contractType}
                label="Contract Type"
                decorator={mapContractType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LineItem value={consolidation?.bookingParty.firstName} label="Booking Party" />
              <LineItem
                label="Manufacturer"
                value={relatedPartyNameOrVarious(
                  consolidation?.relatedParties,
                  PartyType.Manufacturer
                )}
              />
              <LineItem
                label="Shipper"
                value={relatedPartyNameOrVarious(consolidation?.relatedParties, PartyType.Shipper)}
              />
              <LineItem
                label="Consignee"
                value={relatedPartyNameOrVarious(
                  consolidation?.relatedParties,
                  PartyType.Consignee
                )}
              />
              <LineItem
                value={consolidation?.logistics.cfsOpenDate}
                decorator={formatDate}
                label="CFS Open Date"
              />
              <LineItem
                value={consolidation?.logistics.cfsCutoffDate}
                decorator={formatDate}
                label="CFS Cutoff Date"
              />
              <LineItem value={consolidation?.logistics?.carrier?.name} label="Carrier" />
              <LineItem value={consolidation?.logistics.contractNumber} label="Contract" />
            </Grid>
          </Grid>
        </Box>
      </Panel>
    </Box>
  );
}
