import { ApolloError, FetchResult, MutationOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { gqlClient } from './ApolloClient';

/**
 * This helps standardize how mutations are called and how the errors are reported to the user.
 *
 * Mutations can fail in 2 ways.
 * 1. The mutation resolver fails i.e. raises an unhandled exception, the server is down etc.
 * 2. The mutation response payload indicates an error.
 *
 * This wrap helps make it easy to catch both classes of errors.
 *
 * Instead of this:
 * ```
 * const [...] = use...Mutation({
 *   onCompleted(data) {
 *     if (!data....success) {
 *       // HANDLE ERROR
 *     }
 *   },
 *   onError(error) {
 *     // HANDLE ERROR
 *   },
 * });
 * ```
 *
 * You just need to provide an `errorMessage` function that will return a user friendly error message.
 *
 * This wrapper makes it easy to integrate with `useAsyncAction` which has a few advantages over the built in Apollo useMutation
 * - It has the ability to reset the state. The Apollo useMutation currently doesn't let you reset the mutation status. See: https://github.com/apollographql/apollo-client/issues/8859 I.e. if the user attempts an action, it fails, they may want to make a change and re-try the action.
 * - It's the same api for non-graphql actions.
 *
 * @param config
 * @returns A simple function that calls the mutation
 */
export function wrapMutation<TData, TVariables>(
  conf: WrapMutationConfig<TData, TVariables>
): (
  variables: TVariables,
  options?: Omit<Omit<MutationOptions<TData, TVariables>, 'mutation'>, 'variables'>
) => Promise<TData> {
  return async (variables, options) => {
    let res: FetchResult<TData>;
    try {
      res = await gqlClient.mutate<TData, TVariables>({
        ...conf.options,
        ...options,
        mutation: conf.mutation,
        variables,
        errorPolicy: 'all',
      });
    } catch (error) {
      console.error(error);
      const errorMsg = conf.errorMessage(null, error);
      throw errorMsg || 'Unexpected Error';
    }

    const error =
      res.errors && res.errors.length > 0 ? new ApolloError({ graphQLErrors: res.errors }) : null;

    if (error) {
      console.error(error);
    }

    const errorMsg = conf.errorMessage(res.data, error);
    if (errorMsg) {
      throw errorMsg;
    }

    if (!res.data) {
      // In practice, this shouldn't happen because the data will be set when there is no error
      console.error('Empty Response');
      throw new Error('Empty Response');
    }

    return res.data;
  };
}

export interface WrapMutationConfig<TData, TVariables> {
  /**
   * The mutation query
   */
  mutation: DocumentNode;

  /**
   * This is used to check the mutation response to determine if there is an error message.
   *
   * @param data The mutation response
   * @returns An error message string to display to the user, or null if there is no error.
   */
  errorMessage(data: TData | null | undefined, error: any): string | null | undefined;

  /**
   * Provide some default Apollo mutation options
   */
  options?: Omit<Omit<MutationOptions<TData, TVariables>, 'mutation'>, 'variables'>;
}
