import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Panel from 'components/Panel';
import { PanelBodyTable } from 'components/PanelBodyTable';
import { UWLTable } from 'components/UWLTable/UWLTable';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { EquipmentRow, HblPageStates } from '../states';

const columns: UWLTableColumn<EquipmentRow>[] = [
  { id: 'isHot', label: '', type: 'string' },
  { id: 'containerNumber', label: 'Container', type: 'link' },
  { id: 'containerType', label: 'Container Type', type: 'string' },
  { id: 'sealNumber', label: 'Seal', type: 'string' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: 'CBM' },
  { id: 'weightKg', label: 'Weight', type: 'weight', unit: 'KG' },
  { id: 'shipFromFactoryDate', label: 'Ship From Factory', type: 'date' },
  { id: 'terminalReceivedDate', label: "Terminal Rec'd", type: 'date' },
  { id: 'deliveryAta', label: 'Dest Dlv ATA', type: 'date' },
];

const columnsDisplayHot = columns.map((c) => c.id);
const columnsDisplayNotHot = columnsDisplayHot.filter((id) => id !== 'isHot');

export default function HBLEquipment() {
  const hbl = useRecoilValue(HblPageStates.hbl);

  const rows = hbl?.equipmentRows ?? [];

  return (
    <Panel title="Equipment">
      <PanelBodyTable>
        <UWLTable
          rowId="id"
          columns={columns}
          columnsDisplay={rows.find((row) => row.isHot) ? columnsDisplayHot : columnsDisplayNotHot}
          rows={rows}
          emptyMessage="No Containers Available"
          renderCell={{
            isHot(row) {
              return row.isHot ? (
                <FontAwesomeIcon icon={faFire} style={{ color: 'orange' }} size="lg" />
              ) : null;
            },
          }}
        />
      </PanelBodyTable>
    </Panel>
  );
}
