import { faBox } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { PartyType } from 'api/GQL_Types';
import { HotBookingSwitch } from 'app/components/HotBookingSwitch';
import { HotContainerSwitch } from 'app/components/HotContainerSwitch';
import { AlertIconButton } from 'app/dashboard/components/AlertIconButton';
import { HotState } from 'components/HotToggleSwitch';
import { Prepend } from 'components/Prepend';
import TableExportWindowGroup from 'components/TableExportWindowGroup';
import { STANDARD_ROW_OVERALL_HEIGHT, UWLTable } from 'components/UWLTable/UWLTable';
import { useWindowSize } from 'lib/useWindowSize';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatNumber } from 'types/Number';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { UWLTableColumn } from 'types/UWLTable';
import { ExceptionQueryResult } from '.';
import { PopoverTable } from '../../components/PopoverTable';
import { DashboardPageStates, TableLink } from '../../states';

export interface HotShipmentException {
  key: string;
  shipmentId: string;
  hotShipment: HotState;
  hotContainer: HotState;
  shipmentNumber: string;
  containerId: string | null;
  containerNumber: string;
  isShipmentRow: boolean;
  consignee: string;
  bl: TableLink;
  cargoReadyDate: Date | null | undefined;
  revisedCargoReadyDate: Date | null | undefined;
  shipToLocation: string;
  equipment: TableLink;
  invoice: number;
  carrier: string;
  pol: string;
  polEtd: Date | null | undefined;
  polAtd: Date | null | undefined;
  pod: string;
  podEta: Date | null | undefined;
  podAta: Date | null | undefined;
  ramp: string;
  rampEta: Date | null | undefined;
  rampAta: Date | null | undefined;
  deliveryLocation: string;
  deliveryEta: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export function HotShipmentException_toRow(
  exception: ExceptionQueryResult
): HotShipmentException[] | null {
  if (exception.__typename !== 'HotShipment') {
    return null;
  }
  const shipment = exception.shipment;
  const logistics = shipment.logistics;

  let containers = shipment.containers;
  if (shipment.__typename === 'Booking') {
    for (const pl of shipment.packLines) {
      for (const vp of pl.vanPositions) {
        let row = containers.find((c) => c.id === vp.container.id);
        if (!row) {
          containers.push(vp.container);
        }
      }
    }
  }

  containers = containers.filter((c) => c.containerNumber !== null);

  let hotShipmentExceptions: HotShipmentException[] = [];

  // Create shipment row
  hotShipmentExceptions.push({
    key: shipment.id,
    shipmentId: shipment.id,
    isShipmentRow: true,
    shipmentNumber: shipment.referenceNumber,
    containerId: null,
    containerNumber: '',
    hotShipment: {
      isHot: shipment.__typename === 'Booking' ? shipment.isHot : false,
      hotMarkedBy: shipment.__typename === 'Booking' ? shipment.hotMarkedBy : '',
      hotMarkedTimestamp: shipment.__typename === 'Booking' ? shipment.hotMarkedTimestamp : null,
    },
    hotContainer: {
      isHot: false,
      hotMarkedBy: '',
      hotMarkedTimestamp: null,
    },
    consignee: relatedPartyNameOrVarious(shipment.relatedParties, PartyType.Consignee),
    bl:
      shipment.__typename === 'Consolidation' && shipment.mbl
        ? {
            to: `/mbl/${shipment.mbl.id ?? ''}`,
            value: shipment.mbl.referenceNumber ?? '',
          }
        : shipment.__typename === 'Booking' && shipment.hbl
        ? {
            to: `/hbl/${shipment.hbl.id ?? ''}`,
            value: shipment.hbl.referenceNumber ?? '',
          }
        : { to: '', value: '' },
    cargoReadyDate: shipment.__typename === 'Booking' ? shipment.cargoReadyDate : null,
    revisedCargoReadyDate:
      shipment.__typename === 'Booking' ? shipment.revisedCargoReadyDate : null,
    shipToLocation: logistics.finalDestination?.name ?? '',
    equipment: {
      to: '',
      value: '',
    },
    invoice: containers.reduce((containerValue, container) => {
      return container.vanPositions.reduce((vanValue, position) => {
        return vanValue + (position.commercialInvoiceTotal ?? 0);
      }, containerValue);
    }, 0),
    carrier: logistics.carrier?.name ?? '',
    pol: logistics.pol?.code ?? '',
    polEtd: logistics.polEtd,
    polAtd: logistics.polAtd,
    pod: logistics.pod?.code ?? '',
    podEta: logistics.podEta,
    podAta: logistics.podAta,
    ramp: logistics.ramp?.code ?? '',
    rampEta: logistics.rampEta,
    rampAta: logistics.rampAta,
    deliveryLocation: logistics.finalDestination?.name ?? '',
    deliveryEta: logistics.deliveryEta,
    deliveryAta: logistics.deliveryAta,
  });

  for (let i = 0; i < containers.length; i++) {
    hotShipmentExceptions.push({
      key: containers[i].id + shipment.id,
      shipmentId: shipment.id,
      isShipmentRow: false,
      shipmentNumber: shipment.referenceNumber,
      containerId: containers[i].id,
      containerNumber: containers[i].containerNumber ?? '',
      hotShipment: {
        isHot: shipment.__typename === 'Booking' ? shipment.isHot : false,
        hotMarkedBy: shipment.__typename === 'Booking' ? shipment.hotMarkedBy : '',
        hotMarkedTimestamp: shipment.__typename === 'Booking' ? shipment.hotMarkedTimestamp : null,
      },
      hotContainer: {
        isHot: containers[i].isHot,
        hotMarkedBy: containers[i].hotMarkedBy,
        hotMarkedTimestamp: containers[i].hotMarkedTimestamp,
      },
      consignee: relatedPartyNameOrVarious(shipment.relatedParties, PartyType.Consignee),
      bl:
        shipment.__typename === 'Consolidation' && shipment.mbl
          ? {
              to: `/mbl/${shipment.mbl.id ?? ''}`,
              value: shipment.mbl.referenceNumber ?? '',
            }
          : shipment.__typename === 'Booking' && shipment.hbl
          ? {
              to: `/hbl/${shipment.hbl.id ?? ''}`,
              value: shipment.hbl.referenceNumber ?? '',
            }
          : { to: '', value: '' },
      cargoReadyDate: shipment.__typename === 'Booking' ? shipment.cargoReadyDate : null,
      revisedCargoReadyDate:
        shipment.__typename === 'Booking' ? shipment.revisedCargoReadyDate : null,
      shipToLocation: logistics.finalDestination?.name ?? '',
      equipment: {
        to: `/equipment/${containers[i].id ?? ''}`,
        value: containers[i].containerNumber ?? '',
      },
      invoice: containers[i].vanPositions
        .map((p) => p.commercialInvoiceTotal ?? 0)
        .reduce((sum, val) => sum + val, 0),
      carrier: logistics.carrier?.name ?? '',
      pol: logistics.pol?.code ?? '',
      polEtd: logistics.polEtd,
      polAtd: logistics.polAtd,
      pod: logistics.pod?.code ?? '',
      podEta: logistics.podEta,
      podAta: logistics.podAta,
      ramp: containers[i].ramp?.code ?? '',
      rampEta: containers[i].rampEta,
      rampAta: containers[i].rampAta,
      deliveryLocation: logistics.finalDestination?.name ?? '',
      deliveryEta: logistics.deliveryEta,
      deliveryAta: logistics.deliveryAta,
    });
  }

  return hotShipmentExceptions;
}

export function HotShipmentException_toFilteredRows(
  exceptions: HotShipmentException[]
): HotShipmentException[] {
  let filteredExceptions: HotShipmentException[] = [];
  for (let i = 0; i < exceptions.length; i++) {
    if (exceptions[i].hotShipment.isHot && exceptions[i].polAtd) {
      if (exceptions[i].isShipmentRow) {
        let shipmentContainers = exceptions.filter(
          (x) => x.shipmentNumber === exceptions[i].shipmentNumber && x.isShipmentRow === false
        );
        if (shipmentContainers.length <= 0) {
          filteredExceptions.push(exceptions[i]);
        }
      } else {
        filteredExceptions.push(exceptions[i]);
      }
    } else if (exceptions[i].hotContainer.isHot) {
      filteredExceptions.push(exceptions[i]);
    }
  }

  return filteredExceptions;
}

const columns: UWLTableColumn<HotShipmentException>[] = [
  { id: 'shipmentNumber', label: 'Hot Shipment', type: 'string' },
  { id: 'containerNumber', label: 'Hot Container', type: 'string' },
  { id: 'consignee', label: 'Consignee', type: 'string' },
  { id: 'bl', label: 'BOL', type: 'link' },
  { id: 'cargoReadyDate', label: 'CRG RDY Date', type: 'date' },
  { id: 'revisedCargoReadyDate', label: 'REV CRG RDY Date', type: 'date' },
  { id: 'shipToLocation', label: 'Ship To Location', type: 'string' },
  { id: 'equipment', label: 'Equipment', type: 'link' },
  { id: 'invoice', label: 'COMM INV Total', type: 'currency' }, // COMM INV TOTAL (THIS IS THE SUM TOTAL OF ALL PO/LINES IN THE CONTAINER).
  { id: 'carrier', label: 'Carrier', type: 'string' },
  { id: 'pol', label: 'POL', type: 'string' },
  { id: 'polEtd', label: 'POL ETD', type: 'date' },
  { id: 'polAtd', label: 'POL ATD', type: 'date' },
  { id: 'pod', label: 'POD', type: 'string' },
  { id: 'podEta', label: 'POD ETA', type: 'date' },
  { id: 'podAta', label: 'POD ATA', type: 'date' },
  { id: 'ramp', label: 'Rail Ramp', type: 'string' },
  { id: 'rampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'rampAta', label: 'Rail Ramp ATA', type: 'date' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'deliveryEta', label: 'Final ETA', type: 'date' },
  { id: 'deliveryAta', label: 'Final ATA', type: 'date' },
  // TODO In DC Date
];

const exportColumns = columns.filter((c) => c.id !== 'hotShipment' && c.id !== 'hotContainer');

interface Props {}

export const HotShipmentExceptionCmpt: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const refAnchor = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const exceptions = useRecoilValue(DashboardPageStates.exceptions);
  const rows = exceptions.hotShipmentsFiltered;

  return (
    <>
      <AlertIconButton
        innerRef={refAnchor}
        name="Hot Shipments"
        faIcon={faBox}
        addHotIcon
        unreadCount={rows.length}
        onClick={() => setOpen(true)}
      />

      <PopoverTable
        anchorEl={refAnchor.current}
        open={open}
        onClose={() => setOpen(false)}
        title="Hot Shipments"
        titleRight={
          <TableExportWindowGroup label="Hot Shipments" rows={rows} columns={exportColumns} />
        }
        totalLabel="Total Hot Shipments"
        totalValue={formatNumber(rows.length)}
        width={windowSize.width * 0.8}
        bodyHeight={rows.length * STANDARD_ROW_OVERALL_HEIGHT}
      >
        {open && (
          <UWLTable
            rowId={'key'}
            rows={rows}
            columns={columns}
            emptyMessage="No Hot Shipments"
            virtualize={'single-line-cells'}
            renderCell={{
              shipmentNumber(row) {
                return (
                  <Prepend
                    item={
                      <HotBookingSwitch
                        bookingId={row.shipmentId}
                        initialState={row.hotShipment}
                        small
                      />
                    }
                  >
                    <Link to={'/bookings/' + row.shipmentId}>{row.shipmentNumber}</Link>
                  </Prepend>
                );
              },
              containerNumber(row) {
                if (row.containerId && row.containerNumber) {
                  return (
                    <Prepend
                      item={
                        <HotContainerSwitch
                          containerId={row.containerId}
                          initialState={row.hotContainer}
                          small
                        />
                      }
                    >
                      <Link to={'/equipment/' + row.containerId}>{row.containerNumber}</Link>
                    </Prepend>
                  );
                }
              },
            }}
          />
        )}
      </PopoverTable>
    </>
  );
};
