import { Box, Container } from '@material-ui/core';
import { NewProfileForm } from 'app/admin/components/NewProfileForm';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { AvailableProfileTemplateType, NewNetworkPageStates } from '../../states';

export default function Step2() {
  const selectedProfileTypes = useRecoilValue<string[]>(
    NewNetworkPageStates.fields.selectedProfileTypes
  );

  const availableProfileTypeTemplates = useRecoilValue<AvailableProfileTemplateType[]>(
    NewNetworkPageStates.availableProfileTypeTemplates
  );

  const [selectedProfileTypeTemplates, setSelectedProfileTypeTemplates] = React.useState<
    AvailableProfileTemplateType[]
  >([]);

  React.useEffect(() => {
    const profileTypeList: AvailableProfileTemplateType[] = availableProfileTypeTemplates
      ? availableProfileTypeTemplates.filter(
          (template) => selectedProfileTypes.indexOf(template.id) > -1
        )
      : [];

    setSelectedProfileTypeTemplates(profileTypeList);
  }, [selectedProfileTypes]);

  return (
    <Container>
      <Box marginBottom={2}>
        <NewProfileForm profileTypes={selectedProfileTypeTemplates} />
      </Box>
    </Container>
  );
}
