import { Box } from '@material-ui/core';
import ContainerInformation from './container-information';
import SubmitFooter from './SubmitFooter';
import { ContainerPacking } from 'app/components/ContainerPacking';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BookingPageStates } from 'app/bookings/details/states';
import { ItemPosition, OpenLineItem } from 'types/VanPosition';
import { PartyType, VolumeUnit, WeightUnit } from 'api/GQL_Types';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import React from 'react';
import { UWLTableColumn } from 'types/UWLTable';

const bottomHalfColumns: UWLTableColumn<OpenLineItem>[] = [
  { id: 'orderLineNumber', label: 'Line', type: 'string' },
  { id: 'poNumber', label: 'PO', type: 'string' },
  { id: 'itemNumber', label: 'Item / Sku', type: 'string' },
  { id: 'itemDescription', label: 'Description', type: 'string' },
  { id: 'shippedQty', label: 'Shipped QTY', type: 'number' },
  { id: 'balanceQty', label: 'Balance Qty', type: 'number' },
  { id: 'shippedCtns', label: 'Shipped CTNS', type: 'number' },
  { id: 'balanceCtns', label: 'Balance CTNS', type: 'number' },
  { id: 'volumeM3', label: 'Volume', type: 'volume', unit: VolumeUnit.Cbm },
  { id: 'weightKg', label: 'Weight', type: 'weight', unit: WeightUnit.Kg },
];

export default function EditEquipment() {
  const selectedContainer = useRecoilValue(BookingPageStates.containerAssignment.selectedContainer);
  const booking = useRecoilValue(BookingPageStates.booking);
  const allLines = booking?.packLines || [];
  const [selectedLines, setSelectedLines] = useRecoilState(
    BookingPageStates.containerAssignment.editContainer.selectedBookingPackLines
  );

  const openLineItems: OpenLineItem[] = allLines.map((line) => {
    return {
      packLineId: line.id,
      orderLineNumber: line.orderLine.lineNumber,
      poNumber: line.orderLine.purchaseOrder.poNumber,
      itemNumber: line.orderLine.itemNumber,
      itemDescription: line.orderLine.itemDescription,
      bookingNumber: line.booking?.referenceNumber,
      supplierName: relatedPartyNameOrVarious(line.booking?.relatedParties, PartyType.Supplier),
      shippedQty: line.shippedQty,
      balanceQty: 0,
      shippedCtns: line.shippedCartons,
      balanceCtns: 0,
      volumeM3: line.volumeM3,
      weightKg: line.weightKg,
    };
  });

  React.useEffect(() => {
    let itemPositions: ItemPosition[] = [];

    if (selectedContainer && selectedContainer.vanPositions) {
      for (const vp of selectedContainer.vanPositions) {
        itemPositions.push({
          packLineId: vp.packLine.id,
          poNumber: vp.packLine.orderLine.purchaseOrder.poNumber,
          itemNumber: vp.packLine.orderLine.itemNumber,
          piecesQty: vp.piecesQty,
          cartonsQty: vp.cartonsQty,
          volumeM3: vp.volumeM3 || 0,
          weightKg: vp.weightKg || 0,
          vanCode: vp.vanCode || null,
        });
      }
    }

    setSelectedLines(itemPositions);
  }, [booking]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="auto">
        <ContainerInformation />
        <ContainerPacking
          allLines={openLineItems}
          containers={booking?.containers || []}
          selectedContainerId={selectedContainer?.id || ''}
          selectedLines={selectedLines}
          setSelectedLines={setSelectedLines}
          bottomHalfColumns={bottomHalfColumns}
        />
      </Box>
      <SubmitFooter />
    </Box>
  );
}
