import {
  ContractType,
  GqlCarrier,
  GqlConsolidation,
  GqlConsolidationQuery,
  GqlContainer,
  GqlLocation,
  GqlPort,
  GqlRelatedParty,
  PartyType,
  PaymentType,
  PermissionCode,
  ReleaseType,
} from 'api/GQL_Types';
import { userContextAtom } from 'app';
import { Document } from 'app/components/DocumentsPanel';
import { TableLink } from 'app/dashboard/states';
import { Remark, sortRemarks } from 'components/Remarks';
import { VesselOption, VoyageOption } from 'components/VesselVoyageSelector';
import { DateAtomType, newAtom } from 'lib/RecoilUtils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mapMoveType, mapShipmentStatus } from 'types/OMSEnums';
import { relatedPartyNameOrVarious } from 'types/RelatedParty';
import { ItemPosition } from 'types/VanPosition';
import { bookingRelatedPartiesState } from '.';

export interface ConsolidationPageData extends GqlConsolidation {
  bookingTableRows: BookingTableRow[];
}

export interface BookingTableRow {
  id: string;
  referenceNumber: TableLink;
  hbl: TableLink | null;
  bookingDate: Date | null;
  weight: number;
  volume: number;
  bookingStatus: string;
  cargoReadyDate: Date | null | undefined;
  pol: string;
  polEtd: Date | null | undefined;
  pod: string;
  finalDestinationName: string;
  supplierName: string;
  moveType: string;
  containers: TableLink[];
  relatedParties: GqlRelatedParty[];
  hasTerminalReceived: boolean;
}

export interface Containers {
  id: string;
  isHot: boolean;
  containerNumber: { to: string; value: string };
  containerType: string;
  sealNumber: string;
  volume: number;
  weight: number;
  volumeUnit: string;
  weightUnit: string;
  terminalReceivedDate: Date | null | undefined;
  deliveryAta: Date | null | undefined;
}

export const ConsolidationPageStates = {
  consolidation: newAtom<ConsolidationPageData | null>(null),
  allRemarks: newAtom<Remark[]>([]),
  allDocuments: newAtom<Document[]>([]),
  editLogistics: {
    confirmationNumber: newAtom<string>(''),
    confirmationDate: newAtom<DateAtomType>(null),
    carrier: newAtom<GqlCarrier | null>(null),
    carrierConfirmationNumber: newAtom<string>(''),
    mbl: newAtom<string>(''),
    mblPaymentType: newAtom<PaymentType | null>(null),
    mblReleaseType: newAtom<ReleaseType | null>(null),
    hblPerBooking: newAtom<BookingPerHbl[]>([]),
    contractType: newAtom<ContractType | null>(null),
    contract: newAtom<string>(''),
    cfsOpen: newAtom<DateAtomType>(null),
    cyCutoff: newAtom<DateAtomType>(null),
    cfsCutoff: newAtom<DateAtomType>(null),
    cfsReceived: newAtom<DateAtomType>(null),
    vgmCutoff: newAtom<DateAtomType>(null),
    siCutoff: newAtom<DateAtomType>(null),
    isfCutoff: newAtom<DateAtomType>(null),
    motherVessel: newAtom<VesselOption | null>(null),
    motherVoyage: newAtom<VoyageOption | null>(null),
    voyage: newAtom<string>(''),
    feederVessel: newAtom<VesselOption | null>(null),
    feederVoyage: newAtom<VoyageOption | null>(null),
    transitPort: newAtom<GqlPort | null>(null),
    transitPortEtd: newAtom<DateAtomType>(null),
    transitPortAtd: newAtom<DateAtomType>(null),
    transitPortEta: newAtom<DateAtomType>(null),
    transitPortAta: newAtom<DateAtomType>(null),
    pol: newAtom<GqlPort | null>(null),
    polEtd: newAtom<DateAtomType>(null),
    polAtd: newAtom<DateAtomType>(null),
    pod: newAtom<GqlPort | null>(null),
    podEta: newAtom<DateAtomType>(null),
    ramp: newAtom<GqlPort | null>(null),
    rampEta: newAtom<DateAtomType>(null),
    finalDestination: newAtom<GqlLocation | null>(null),
    deliveryEta: newAtom<DateAtomType>(null),
  },
  containerAssignment: {
    selectedContainerType: newAtom<string | null>(null),
    selectedContainer: newAtom<GqlContainer | null>(null),
    editContainer: {
      containerNumber: newAtom<string>(''),
      containerType: newAtom<string | null>(null),
      sealNumber: newAtom<string>(''),
      cntrConsolidationDate: newAtom<DateAtomType>(null),
      shipFromCfs: newAtom<DateAtomType>(null),
      shipFromFactory: newAtom<DateAtomType>(null),
      terminalReceived: newAtom<DateAtomType>(null),
      selectedConsolidationPackLines: newAtom<ItemPosition[]>([]),
    },
  },
};

export interface BookingPerHbl {
  bookingId: string;
  bookingReferenceNumber: string;
  hblId: string | null;
  hblReferenceNumber: string;
  hblPaymentType: PaymentType | null;
  hblReleaseType: ReleaseType | null;
}

export function useSetupConsolidationPage() {
  const setConsolidation = useSetRecoilState(ConsolidationPageStates.consolidation);
  const setAllDocuments = useSetRecoilState(ConsolidationPageStates.allDocuments);
  const setAllRemarks = useSetRecoilState(ConsolidationPageStates.allRemarks);
  const setRelatedParties = useSetRecoilState(bookingRelatedPartiesState);

  return (init: GqlConsolidationQuery | undefined) => {
    const consolidation = init?.consolidation;

    if (!consolidation) {
      setAllRemarks([]);
      setAllDocuments([]);
      setConsolidation(null);
      setRelatedParties([]);
      return;
    }

    let remarks: Remark[] = [];
    remarks = remarks.concat(consolidation.remarks ?? []);
    remarks = sortRemarks(remarks);
    setAllRemarks(remarks);

    let bookDocList: any = [];
    let hblDocList: any = [];
    for (const book of consolidation.bookings) {
      for (const doc of book.documents) {
        bookDocList.push({
          id: doc?.id,
          hblId: book.hbl?.id,
          hblNumber: book.hbl?.referenceNumber ?? '',
          documentType: doc?.documentType,
          importFileName: doc?.importFileName,
          fileType: doc?.fileType,
          fileSize: doc?.fileSize,
          uploadDate: doc?.uploadDate,
          description: doc?.description,
        });
      }
      for (const doc of book.hbl?.documents ?? []) {
        hblDocList.push({
          id: doc?.id,
          blId: book.hbl?.id,
          blNumber: book.hbl?.referenceNumber,
          documentType: doc?.documentType,
          importFileName: doc?.importFileName,
          fileType: doc?.fileType,
          fileSize: doc?.fileSize,
          uploadDate: doc?.uploadDate,
          description: doc?.description,
        });
      }
    }
    setAllDocuments([...bookDocList, ...hblDocList]);

    const bookingTableRows: BookingTableRow[] = [];
    for (const booking of consolidation.bookings) {
      let containers: { to: string; value: string }[] = [];
      let weight = 0;
      let volume = 0;

      let hasTerminalReceived = false;

      for (const packLine of booking.packLines) {
        weight += packLine.weightKg;
        volume += packLine.volumeM3;
        for (const vp of packLine.vanPositions) {
          if (vp.container.containerNumber) {
            const to = '/equipment/' + vp.container.id;
            if (!containers.find((c) => c.to === to)) {
              containers.push({ to, value: vp.container.containerNumber });
            }
          }
          if (vp.container.terminalReceivedDate) {
            hasTerminalReceived = true;
          }
        }
      }

      bookingTableRows.push({
        id: booking.id,
        referenceNumber: { to: '/bookings/' + booking.id, value: booking.referenceNumber },
        hbl: booking.hbl
          ? {
              to: '/hbl/' + booking.hbl.id,
              value: booking.hbl.referenceNumber,
            }
          : null,
        bookingDate: booking.createDate,
        weight: weight,
        volume: volume,
        bookingStatus: mapShipmentStatus(booking.status),
        cargoReadyDate: booking.cargoReadyDate,
        pol: booking.logistics.pol ? booking.logistics.pol.code : '',
        polEtd: booking.logistics.polEtd,
        pod: booking.logistics.pod ? booking.logistics.pod.code : '',
        finalDestinationName: booking.logistics.finalDestination?.name ?? '',
        supplierName: relatedPartyNameOrVarious(booking.relatedParties, PartyType.Supplier),
        moveType: booking.logistics.moveType ? mapMoveType(booking.logistics.moveType) : '',
        containers: containers,
        relatedParties: booking.relatedParties as GqlRelatedParty[],
        hasTerminalReceived,
      });
    }

    setConsolidation({
      ...(consolidation as GqlConsolidation),
      bookingTableRows,
    });
    setRelatedParties(consolidation.relatedParties as any);
  };
}

export function useCanCancelConsol(): boolean {
  const userContext = useRecoilValue(userContextAtom);
  const consolidation = useRecoilValue(ConsolidationPageStates.consolidation);

  const hasDeparted = !!consolidation?.logistics.polAtd;
  const canCancel = !!userContext?.permissionCodes.has(PermissionCode.ConsolidationCancel);
  const canCancelOverride = !!userContext?.permissionCodes.has(
    PermissionCode.ConsolidationCancelOverride
  );

  if (canCancelOverride) {
    return true;
  }
  if (canCancel && !hasDeparted) {
    return true;
  }
  return false;
}
