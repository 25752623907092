import {
  GqlPortFragment,
  GqlRelatedProfileRuleFragment,
  PartyType,
  RelatedProfileCondition,
} from 'api/GQL_Types';
import { genKey } from 'lib/RecoilUtils';
import { mapPartyType } from 'types/OMSEnums';

export const partyTypeOptions: { id: string; label: string }[] = [
  // PartyType.Admin,
  // PartyType.Buyer,
  PartyType.BuyingAgent,
  PartyType.Consignee,
  PartyType.Customer,
  PartyType.DestinationAgent,
  PartyType.Manufacturer,
  PartyType.Notify,
  PartyType.OriginAgent,
  PartyType.Related,
  PartyType.Shipper,
  PartyType.Supplier,
  PartyType.Warehouse,
].map((type) => ({
  id: type,
  label: mapPartyType(type),
}));

export interface RelatedProfileRuleGroup {
  id: string;
  profileId: string;
  partyType: PartyType;
  isExpanded: boolean;
  rules: RelatedProfileRule[];

  originalRules: GqlRelatedProfileRuleFragment[];
}

export interface RelatedProfileRule {
  id: string;
  partyType: PartyType | null;
  profileId: string | null;
  condition: RelatedProfileCondition | null;
  port: GqlPortFragment | null;
  country: string | null;
  category: string | null;
  department: string | null;
  material: string | null;
}

interface ValidationState {
  whyCantSave: string | null;
  whyCantDelete: string | null;
}

export function validateRelatedProfileRuleGroup(group: RelatedProfileRuleGroup): ValidationState {
  const validation: ValidationState = {
    whyCantSave: validateWhyCantSave(group),
    whyCantDelete: validateWhyCantDelete(group),
  };
  return validation;
}

function validateWhyCantSave(group: RelatedProfileRuleGroup): string | null {
  if (!group.profileId) {
    return 'Missing profile';
  }
  if (!group.partyType) {
    return 'Missing party type';
  }
  if (group.rules.length === 0) {
    return 'Missing rules';
  }
  for (const rule of group.rules) {
    if (!rule.partyType) {
      return 'A rule is missing the party type';
    }
    if (!rule.profileId) {
      return 'A rule is missing the profile';
    }
    if (!rule.condition) {
      return 'A rule is missing the condition';
    }
  }
  return null;
}

function validateWhyCantDelete(group: RelatedProfileRuleGroup): string | null {
  if (!group.profileId) {
    return 'Missing profile';
  }
  if (!group.partyType) {
    return 'Missing party type';
  }
  return null;
}

export function fromGQL_RelatedProfileRuleGroups(
  rows: GqlRelatedProfileRuleFragment[]
): RelatedProfileRuleGroup[] {
  const grouped = new Map<string, RelatedProfileRuleGroup>();
  for (const row of rows) {
    const key = row.whenProfileId + '|' + row.whenPartyType;
    const group: RelatedProfileRuleGroup = grouped.get(key) || {
      id: genKey(),
      profileId: row.whenProfileId,
      partyType: row.whenPartyType,
      isExpanded: false,
      rules: [],
      originalRules: [],
    };
    grouped.set(key, group);

    group.rules.push({
      id: genKey(),
      partyType: row.setPartyType,
      profileId: row.setProfileId,
      condition: row.whenCondition,
      port: row.whenPort || null,
      country: row.whenCountryCode || null,
      category: row.whenCategoryCode || null,
      department: row.whenDepartment || null,
      material: row.whenMaterial || null,
    });
    group.originalRules.push(row);
  }

  return Array.from(grouped.values());
}
