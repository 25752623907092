import { gql } from '@apollo/client/core';
import { PortFragment } from './portQueries';

export const DocumentsQuery = gql`
  query Documents($filter: DocumentFilter!) {
    documentSearch(filter: $filter) {
      id
      bookingId
      bookingNumber
      importFileName
      fileType
      documentType
      uploadDate
      shipperName
      hblId
      hblNumber
      pol {
        ...Port
      }
      polEtd
      polAtd
      pod {
        ...Port
      }
      podEta
      deliveryLocation
    }
  }
  ${PortFragment}
`;

export const MarkDocumentsAsViewedMutation = gql`
  mutation MarkDocumentsAsViewed($input: MarkDocumentsAsViewedInput!) {
    markDocumentsAsViewed(input: $input) {
      success
      message
    }
  }
`;

export const UploadBookingDocumentMutation = gql`
  mutation UploadBookingDocument($input: UploadBookingDocumentInput!) {
    uploadBookingDocument(input: $input) {
      success
      message
    }
  }
`;
