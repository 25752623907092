import clsx from 'clsx';
import React from 'react';
import { makeCss, theme } from 'styles';

const classes = makeCss({
  inputRow: {
    display: 'flex',
    '& > $inputItem + $inputItem': {
      marginLeft: theme.spacing(2),
    },
    '& + $inputRow': {
      marginTop: theme.spacing(2),
    },
  },
  inputItem: {},
  small: {
    width: 125,
  },
  fill: {
    flex: 1,
  },
});

export const InputRow: React.FC<{}> = ({ children }) => {
  return <div className={classes.inputRow}>{children}</div>;
};

export const InputItem: React.FC<{ small?: boolean }> = ({ small, children }) => {
  return (
    <div className={clsx(classes.inputItem, small ? classes.small : classes.fill)}>{children}</div>
  );
};
