import { Box, Grid } from '@material-ui/core';
import MultiLineDetail from 'components/MultiLineDetail';
import Panel from 'components/Panel';
import PortDisplay from 'components/PortDisplay';
import { PortDisplayDate } from 'components/PortDisplayDate';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { portToStringMaybe } from 'types/Port';
import { formatDate } from 'types/Date';
import { EquipmentPageStates } from './states';

export default function TransitDetailsPanel() {
  const container = useRecoilValue(EquipmentPageStates.container);

  return (
    <Box height="100%" display="flex">
      <Panel title="Transit Details">
        <Box padding={1}>
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <Grid item xs={6} lg={3}>
              <Box paddingBottom={4}>
                <MultiLineDetail
                  label="Ship From Factory"
                  line1={formatDate(container?.shipFromFactoryDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingBottom={4}>
                <MultiLineDetail
                  label="Terminal Rec'd"
                  line1={formatDate(container?.terminalReceivedDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingBottom={4}>
                <MultiLineDetail
                  label="Port Out Gate"
                  line1={formatDate(container?.portOutGateDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingBottom={4}>
                <MultiLineDetail
                  label="Empty Return"
                  line1={formatDate(container?.emptyReturnedDate) || 'N/A'}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around" alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <PortDisplay matIcon="anchor" label="POL" port={portToStringMaybe(container?.pol)}>
                <PortDisplayDate label="ETD" date={container?.polEtd} />
                <PortDisplayDate label="ATD" date={container?.polAtd} />
              </PortDisplay>
            </Grid>
            <Grid item xs={3}>
              <PortDisplay matIcon="anchor" label="POD" port={portToStringMaybe(container?.pod)}>
                <PortDisplayDate label="ETA" date={container?.podEta} />
                <PortDisplayDate label="ATA" date={container?.podAta} />
              </PortDisplay>
            </Grid>
            <Grid item xs={3}>
              <PortDisplay
                matIcon="train"
                label="Rail Ramp"
                port={portToStringMaybe(container?.ramp)}
              >
                {container?.rampAta ? (
                  <PortDisplayDate
                    label="ATA"
                    date={container.rampAta}
                    tooltip={`ETA: ${formatDate(container.rampEta) || 'N/A'}`}
                  />
                ) : (
                  <PortDisplayDate label="ETA" date={container?.rampEta} />
                )}
              </PortDisplay>
            </Grid>
            <Grid item xs={3}>
              <PortDisplay
                matIcon="room"
                label="Delivery Location"
                port={container?.finalDestinationName ?? ''}
              >
                {container?.deliveryAta ? (
                  <PortDisplayDate
                    label="ATA"
                    date={container.deliveryAta}
                    tooltip={`ETA: ${formatDate(container.deliveryEta) || 'N/A'}`}
                  />
                ) : (
                  <PortDisplayDate label="ETA" date={container?.deliveryEta} />
                )}
              </PortDisplay>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <Grid item xs={6} lg={3}>
              <Box paddingTop={2} paddingBottom={2}>
                <MultiLineDetail
                  label="Demurrage LFD"
                  line1={formatDate(container?.demurrageLastFreeDayDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingTop={2} paddingBottom={2}>
                <MultiLineDetail
                  label="LFD Detention"
                  line1={formatDate(container?.detentionLastFreeDayDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingTop={2} paddingBottom={2}>
                <MultiLineDetail
                  label="Rail Outgate"
                  line1={formatDate(container?.rampOutGateDate) || 'N/A'}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box paddingTop={2} paddingBottom={2}>
                <MultiLineDetail
                  label="Empty Notify"
                  line1={formatDate(container?.emptyNotifyDate) || 'N/A'}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Panel>
    </Box>
  );
}
