import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  title: string;
  children: any;
}

export default function GlobalUpdateStep2TransportationSection(props: Props) {
  return (
    <Box>
      <Box marginBottom={1}>
        <Typography variant="h4" style={{ color: '#1CB3BE' }}>
          {props.title}
        </Typography>
      </Box>
      <Box display="flex">{props.children}</Box>
    </Box>
  );
}
