export type SortDirection = 'asc' | 'desc';
export type Comparable = string | number;

export type SortWithFn<T> = (a: T, b: T) => number;
export type SortByFn<T> = (item: T) => Comparable;

export function sortWithBy<T>(by: SortByFn<T>): SortWithFn<T> {
  return (a: T, b: T) => {
    const byA = by(a);
    const byB = by(b);
    return byA > byB ? -1 : byA < byB ? 1 : 0;
  };
}

export function stableSort<T>(array: T[], sortWith: SortWithFn<T>, sortDirection: SortDirection) {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    let order = sortWith(a.el, b.el);
    if (sortDirection === 'asc') {
      order = -order;
    }
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.el);
}

export function sortBy<T>(array: T[], by: SortByFn<T>, sortDirection: SortDirection) {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    const byA = by(a.el);
    const byB = by(b.el);
    let order = byA > byB ? -1 : byA < byB ? 1 : 0;
    if (sortDirection === 'asc') {
      order = -order;
    }
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.el);
}
