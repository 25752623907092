import { Box } from '@material-ui/core';
import React from 'react';

interface Props {
  color: string;
  outlined: boolean;
}

export default function IconButton(props: React.PropsWithChildren<Props>) {
  return (
    <Box
      height={32}
      width={32}
      bgcolor={props.outlined ? '#fff' : props.color}
      borderRadius="50%"
      border={'3px solid ' + props.color}
      padding="6px"
      marginRight={1}
    >
      {props.children}
    </Box>
  );
}
