import { Link } from '@reach/router';
import { PermissionCode } from 'api/GQL_Types';
import { auth } from 'app';
import { UWLTable } from 'components/UWLTable/UWLTable';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { UWLTableColumn } from 'types/UWLTable';
import { SearchResultGroup } from './SearchResultGroup';
import { QuickSearchContainerResult, SearchPageStates } from './states';

const columns: UWLTableColumn<QuickSearchContainerResult>[] = [
  { id: 'containerNumber', label: 'Container Number', type: 'string' },
  { id: 'railRamp', label: 'Rail Ramp', type: 'string' },
  { id: 'railRampEta', label: 'Rail Ramp ETA', type: 'date' },
  { id: 'railRampAta', label: 'Rail Ramp ATA', type: 'date' },
  { id: 'deliveryLocation', label: 'Delivery Location', type: 'string' },
  { id: 'finalEta', label: 'Final ETA', type: 'date' },
  { id: 'finalAta', label: 'Final ATA', type: 'date' },
];

interface Props {}

export default function SearchResultContainers(props: Props) {
  const { userContext } = auth.useAuthState();
  const searchPageData = useRecoilValue(SearchPageStates.data);

  return (
    <SearchResultGroup title="Containers" count={searchPageData.containerCount}>
      <UWLTable
        rowId="containerId"
        rows={searchPageData.containers}
        columns={columns}
        renderCell={{
          containerNumber(row) {
            const value = row.containerNumber ? row.containerNumber : <i>Not Assigned</i>;
            if (userContext?.permissionCodes.has(PermissionCode.ContainerRead)) {
              return <Link to={'/equipment/' + row.containerId}>{value}</Link>;
            }
            return value;
          },
        }}
      />
    </SearchResultGroup>
  );
}
