import { Box, Button } from '@material-ui/core';
import { useUpdatePasswordMutation } from 'api/GQL_Types';
import AtomicTextFieldV2 from 'components/atomic/AtomicTextFieldV2';
import { FormRow } from 'components/form/FormRow';
import UniversalDialog from 'components/UniversalDialog';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newAtom } from 'lib/RecoilUtils';
import { validatePassword } from 'types/Password';
import { accountDetailsState } from './states';

const form = {
  pass1: newAtom<string>(''),
  pass2: newAtom<string>(''),
};

interface FormValidationState {
  isValid: boolean;
  pass1: string | null;
  pass2: string | null;
}

export default function EditPasswordDialog() {
  const [pass1, setPass1] = useRecoilState(form.pass1);
  const [pass2, setPass2] = useRecoilState(form.pass2);
  const [openDialog, setOpenDialog] = useRecoilState(accountDetailsState.editPasswordDialog_Open);
  const { enqueueSnackbar } = useSnackbar();

  const [updatePassword, { loading }] = useUpdatePasswordMutation({
    onCompleted(data) {
      if (data.updatePassword.success) {
        enqueueSnackbar('Password Updated.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        setOpenDialog(false);
      } else {
        enqueueSnackbar(data.updatePassword.message || 'Failed to update password.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to update password: ' + error, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    },
  });

  React.useEffect(() => {
    setPass1('');
    setPass2('');
  }, [openDialog]);

  const validation = validateForm();

  return (
    <UniversalDialog
      open={openDialog}
      title={'Change Password'}
      small
      setClose={() => {
        setOpenDialog(false);
      }}
    >
      <hr />
      <Box marginY={1}>
        <FormRow>
          <AtomicTextFieldV2
            state={form.pass1}
            label="Enter Password"
            type="password"
            autoComplete="off"
            error={!!validation.pass1}
            helperText={validation.pass1}
          />
          <AtomicTextFieldV2
            state={form.pass2}
            label="Re-enter Password"
            type="password"
            autoComplete="off"
            error={!!validation.pass2}
            helperText={validation.pass2}
          />
        </FormRow>
      </Box>
      <hr />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="default"
          size="large"
          onClick={() => {
            setOpenDialog(false);
          }}
          style={{ marginRight: '12px' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={!validation.isValid || loading}
          onClick={() => {
            updatePassword({
              variables: {
                input: {
                  password: pass1,
                },
              },
            });
          }}
        >
          Change Password
        </Button>
      </Box>
    </UniversalDialog>
  );

  function validateForm(): FormValidationState {
    const validation: FormValidationState = {
      isValid: false,
      pass1: null,
      pass2: null,
    };

    const result = validatePassword(pass1, pass2);

    validation.isValid = result.passwordError == null && result.confirmPasswordError == null;

    if (!validation.isValid) {
      validation.pass1 = result.passwordError;
      validation.pass2 = result.confirmPasswordError;
    }

    return validation;
  }
}
