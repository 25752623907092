import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';

const StepIconSize = 34;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    steps: {
      display: 'flex',
      flexDirection: 'column',
      background: '#ddd',
      padding: theme.spacing(3),
    },
    step: {
      fontSize: '1rem',
      lineHeight: 1,
      fontWeight: 700,
      color: '#aaa',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    number: {
      width: StepIconSize,
      height: StepIconSize,
      minWidth: StepIconSize,
      minHeight: StepIconSize,
      borderRadius: StepIconSize,
      lineHeight: StepIconSize + 'px',
      textAlign: 'center',
      color: '#fff',
      background: '#aaaaaa',
      marginRight: theme.spacing(1),
    },
    active: {
      '&$step': {
        color: theme.typography.h4.color,
      },
      '&$step > $number': {
        background: '#3980b9',
      },
    },
    stepConnector: {
      borderLeft: '1px solid #bdbdbd',
      marginLeft: StepIconSize / 2,
      marginTop: theme.spacing(1),
      height: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    label: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

interface Props {
  steps: {
    id: string;
    label: string;
  }[];
  currentId: string;
  onClickStepId: (id: string) => void;
}

export default function StepNav({ steps, currentId, onClickStepId }: Props) {
  const classes = useStyle();

  function Step({ n, id, label }: { n: number; id: string; label: string }) {
    return (
      <div
        className={clsx({ [classes.step]: true, [classes.active]: currentId === id })}
        onClick={() => onClickStepId(id)}
      >
        <div className={classes.number}>{n}</div>
        <div className={classes.label}>{label}</div>
      </div>
    );
  }

  return (
    <div className={classes.steps}>
      {steps.map((step, i) => {
        return (
          <React.Fragment key={step.id}>
            <Step n={i + 1} id={step.id} label={step.label} />
            {i < steps.length - 1 && <div className={classes.stepConnector} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
