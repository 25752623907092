import { UserContext } from 'api/Auth';
import { PermissionCode, SystemPermissionCode } from 'api/GQL_Types';
import { LinkItem } from 'types/LinkItem';

export function availableLinks(userContext: UserContext | null, isSysAdmin: boolean): LinkItem[] {
  if (!userContext) {
    return [];
  }
  const links: LinkItem[] = [];

  if (isSysAdmin) {
    const adminModule: LinkItem = {
      name: 'System',
      path: '/sys-admin/networks',
      icon: 'settings',
      subNav: [],
    };
    if (userContext.systemPermissionCodes.has(SystemPermissionCode.NetworkManageAll)) {
      adminModule.subNav.push({
        name: 'All Networks',
        path: '/sys-admin/networks',
        icon: '',
        subNav: [],
      });
    }
    if (userContext.systemPermissionCodes.has(SystemPermissionCode.ProfileTypeTemplatesRead)) {
      adminModule.subNav.push({
        name: 'Profile Type Templates',
        path: '/sys-admin/profile-types',
        icon: '',
        subNav: [],
      });
    }
    if (userContext.systemPermissionCodes.has(SystemPermissionCode.DocumentTypesManage)) {
      adminModule.subNav.push({
        name: 'Document Types',
        path: '/sys-admin/document-types',
        icon: '',
        subNav: [],
      });
    }
    if (userContext.systemPermissionCodes.has(SystemPermissionCode.UsersManageAll)) {
      adminModule.subNav.push({ name: 'Users', path: '/sys-admin/users', icon: '', subNav: [] });
    }
    if (userContext.systemPermissionCodes.has(SystemPermissionCode.ApiManageAccess)) {
      adminModule.subNav.push({ name: 'API', path: '/sys-admin/api', icon: '', subNav: [] });
    }
    links.push(adminModule);
    return links;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Dashboard
  //
  links.push({ name: 'Dashboard', path: '/', icon: 'dashboard', subNav: [] });

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Purchase Order
  //
  const poModule: LinkItem = {
    name: 'Purchase Order',
    path: '/purchase-orders',
    icon: 'request_quote',
    subNav: [],
  };

  if (userContext.permissionCodes.has(PermissionCode.PoRead)) {
    poModule.subNav.push({ name: 'PO Browser', path: '/purchase-orders', icon: '', subNav: [] });
  }
  if (userContext.permissionCodes.has(PermissionCode.PoImportUpload)) {
    poModule.subNav.push({
      name: 'PO Upload',
      path: '/purchase-orders/uploads/new',
      icon: '',
      subNav: [],
    });
  }
  if (userContext.permissionCodes.has(PermissionCode.PoImportRead)) {
    poModule.subNav.push({
      name: 'PO Upload History',
      path: '/purchase-orders/uploads',
      icon: '',
      subNav: [],
    });
  }
  if (poModule.subNav.length === 1) {
    links.push({
      ...poModule.subNav[0],
      icon: poModule.icon,
    });
  } else if (poModule.subNav.length > 0) {
    links.push(poModule);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Bookings & Shipments
  //
  const bookingModule: LinkItem = {
    name: 'Bookings & Shipments',
    path: '/bookings',
    icon: 'today',
    subNav: [],
  };
  if (userContext.permissionCodes.has(PermissionCode.BookingCreate)) {
    bookingModule.subNav.push({ name: 'New Booking', path: '/bookings/new', icon: '', subNav: [] });
  }
  if (userContext.permissionCodes.has(PermissionCode.ConsolidationCreate)) {
    bookingModule.subNav.push({
      name: 'New Consolidation',
      path: '/consolidations/new',
      icon: '',
      subNav: [],
    });
  }
  if (userContext.permissionCodes.has(PermissionCode.BookingRead)) {
    bookingModule.subNav.push({ name: 'Booking List', path: '/bookings', icon: '', subNav: [] });
    bookingModule.subNav.push({
      name: 'Shipped List',
      path: '/bookings/shipped',
      icon: '',
      subNav: [],
    });
  }
  if (bookingModule.subNav.length === 1) {
    links.push({
      ...bookingModule.subNav[0],
      icon: bookingModule.icon,
    });
  } else if (bookingModule.subNav.length > 0) {
    links.push(bookingModule);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Documents
  //
  if (userContext.permissionCodes.has(PermissionCode.DocumentModule)) {
    links.push({
      name: 'Documents',
      path: '/documents',
      icon: 'description',
      subNav: [],
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Reports
  //
  if (userContext.permissionCodes.has(PermissionCode.ReportsRead)) {
    links.push({
      name: 'Reports',
      path: '/reports',
      icon: 'analytics',
      subNav: [
        {
          name: 'Dynamic Reports',
          path: '/reports/dynamic',
          icon: '',
          subNav: [],
        },
        {
          name: 'Scheduled Reports',
          path: '/reports',
          icon: '',
          subNav: [],
        },
      ],
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // Administration
  //
  const adminModule: LinkItem = {
    name: 'Administration',
    path: '/admin',
    icon: 'settings',
    subNav: [],
  };
  if (userContext.permissionCodes.has(PermissionCode.NetworkRead)) {
    const networkId = userContext.activeContact?.profile.network.id;
    if (networkId) {
      adminModule.subNav.push({
        name: 'Network',
        path: `/admin/networks/${networkId}`,
        icon: '',
        subNav: [],
      });
      // TODO FIXME - should this page exist? Or should they just rely on the "Connections" tab in the Network settings
      // adminModule.subNav.push({
      //   name: 'Network Profiles',
      //   path: '/admin/profiles',
      //   icon: '',
      //   subNav: [],
      // });
    }
  }
  if (userContext.permissionCodes.has(PermissionCode.ProfileRead)) {
    const profileId = userContext.activeContact?.profile.id;
    if (profileId) {
      adminModule.subNav.push({
        name: 'Profile',
        path: `/admin/profiles/${profileId}`,
        icon: '',
        subNav: [],
      });
    }
  }
  if (userContext.permissionCodes.has(PermissionCode.GlobalUpdate)) {
    adminModule.subNav.push({
      name: 'Global Update',
      path: '/admin/global-updates',
      icon: '',
      subNav: [],
    });
  }
  if (adminModule.subNav.length === 1) {
    links.push({
      ...adminModule.subNav[0],
      icon: adminModule.icon,
    });
  } else if (adminModule.subNav.length > 0) {
    links.push(adminModule);
  }

  return links;
}
