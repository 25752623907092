import { getFilterType } from 'components/dataset';
import { NotifierHook } from 'lib/NotifierHook';
import { AsyncLoaderState } from 'lib/useAsyncLoader';
import { Dataset, DatasetDisplayable, DatasetFilter, DatasetFilterable } from 'types/Dataset';

export default (function reportCategoriesStore() {
  let state: AsyncLoaderState<Dataset[]> = {
    waiting: true,
    error: null,
    data: [],
  };

  const notifier = NotifierHook();

  function use(): AsyncLoaderState<Dataset[]> {
    notifier.use();
    return state;
  }

  function setDatasets(datasets: Dataset[]) {
    state.data = datasets;
    state.waiting = false;
    state.error = null;
    notifier.notify();
  }

  function getDataset(id: string): Dataset | null {
    return state.data.find((d) => d.id === id) || null;
  }

  function useDataset(id: string): Dataset | null {
    use();
    return getDataset(id);
  }

  function useFilterables(id: string): DatasetFilterable[] {
    const dataset = useDataset(id);
    return dataset?.filterables || [];
  }

  function useDisplayables(id: string): DatasetDisplayable[] {
    const dataset = useDataset(id);
    return dataset?.displayables || [];
  }

  function validateFilterInput(datasetId: string, input: DatasetFilter): string | null {
    if (!input.applied) {
      return null;
    }
    const dataset = getDataset(datasetId);
    if (!dataset) {
      return 'Dataset not loaded: ' + datasetId;
    }
    const filterable = dataset.filterables.find((f) => f.field === input.field);
    if (!filterable) {
      return 'Filterable not found: ' + input.field;
    }
    const filterType = getFilterType(filterable.type);
    if (!filterType) {
      return 'Filter type not supported: ' + filterable.type;
    }
    return filterType.validate(filterable, filterType.deserializeValue(filterable, input.value));
  }

  return { use, useDataset, useFilterables, useDisplayables, validateFilterInput, setDatasets };
})();
