import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { newAtom } from 'lib/RecoilUtils';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'styles';
import { formatNumber, isNumber } from 'types/Number';

export const columnWidth = newAtom<string>('33%');

interface Props {
  name: string;

  unreadCount?: number;
  resultCount?: number;

  faIcon: any;
  addHotIcon?: boolean;

  onClick?: React.MouseEventHandler<HTMLDivElement>;

  innerRef?: React.Ref<HTMLDivElement>;
}

export const AlertIconButton: React.FC<Props> = (props) => {
  const hasUnread = isNumber(props.unreadCount) && props.unreadCount > 0;
  const calculatedColumnWidth = useRecoilValue(columnWidth);
  return (
    <ListItem
      ref={props.innerRef}
      button
      style={{
        padding: '8px 8px',
        backgroundColor: '#fff',
        width: calculatedColumnWidth,
        display: 'inline-flex',
      }}
      onClick={props.onClick}
    >
      <ListItemIcon style={{ alignItems: 'center' }}>
        <Box
          component="span"
          padding="13px"
          fontSize="15px"
          className="material-icons"
          color={theme.palette.common.white}
          bgcolor={theme.palette.indigoBlue.main}
          borderRadius="50%"
          position="relative"
        >
          <Box display="flex" style={{ flexFlow: 'wrap-reverse' }}>
            <FontAwesomeIcon icon={props.faIcon} style={{ verticalAlign: 0, width: '1em' }} />
            {props.addHotIcon && (
              <FontAwesomeIcon
                icon={faFire}
                color={theme.palette.error.main}
                style={{ fontSize: '10px', marginLeft: '-7px' }}
              />
            )}
          </Box>

          {hasUnread && (
            <Box
              component="span"
              padding="5px"
              fontSize="8px"
              className="material-icons"
              color={theme.palette.common.white}
              bgcolor={theme.palette.error.main}
              borderRadius="50%"
              position="absolute"
              top="-5px"
              right="-5px"
            >
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{
                  padding: '0 2px',
                  lineHeight: '0.9',
                  fontSize: '10px',
                  fontWeight: 800,
                }}
              >
                {props.unreadCount}
              </Typography>
            </Box>
          )}
        </Box>

        <Typography variant="h4" style={{ paddingLeft: '7px', fontSize: '13px' }} color="primary">
          {props.name}
          {isNumber(props.resultCount) &&
            props.resultCount > 0 &&
            ` (${formatNumber(props.resultCount)})`}
        </Typography>
      </ListItemIcon>
    </ListItem>
  );
};
