import React from 'react';
import { RouteComponentProps } from '@reach/router';

interface DashboardProps extends RouteComponentProps {}
function AdminDashboard(props: DashboardProps) {
  return (
    <div>
      <h1>Admin Dashboard</h1>
    </div>
  );
}

export default AdminDashboard;
